import { useCallback } from 'react'
import { CardTableRowConfig, Hardware, ICctv, IPec } from '@/context/types'
import { UserRole } from '@/config/roles'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import { incidentCreateModal } from '@/components/modals/incidents/modals'
import FolderCard, { Props as FolderCardProps } from '@/components/common/cards/FolderCard'
import HardwareCardIncidentList
  from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardware/HardwareCardIncidentList'
import ParamTable from '@/components/common/cards/ParamTable'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { faExclamationTriangle, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import HardwareCardParamTableRows from '@/components/common/cards/hardware/HardwareCardParamTableRows'
import { unbindItemHardwareModal } from '@/components/modals/cctv/modals'
import { getItemLabel } from '@/utils/tools'


type Props<T extends Hardware> = Pick<FolderCardProps, 'headerBgColor' | 'bodyBgColor'> & {
  rows: Record<string, CardTableRowConfig<T>>
  hardware: T
  editHardwareModal?: (hardware: T, reloadCctv: () => Promise<void>) => any
  item: ICctv | IPec
  reloadCard: () => Promise<void>
}

const HardwareCard = <T extends Hardware>({
  headerBgColor,
  bodyBgColor,
  rows,
  hardware,
  editHardwareModal,
  item,
  reloadCard,
}: Props<T>) => {
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()

  const handleEdit = useCallback(() => {
    if (!editHardwareModal)
      return

    showModalDialog(editHardwareModal(hardware, reloadCard))
  }, [hardware, reloadCard, showModalDialog])

  const handleCreateIncident = useCallback(() => {
    showModalDialog(incidentCreateModal(hardware, reloadCard))
  }, [hardware, reloadCard, showModalDialog])

  const handleRemoveHardware = useCallback(() => {
    showModalDialog(unbindItemHardwareModal(item, hardware, reloadCard))
  }, [showModalDialog, item, hardware, reloadCard])

  return (
    <FolderCard
      headerBgColor={headerBgColor}
      bodyBgColor={bodyBgColor}
      header={`${hardware.type.name} ${hardware.code}`}
      centerToolbar={
        <>
          <SmallRoundBtn
            icon={faExclamationTriangle}
            tooltip='Открыть инцидент'
            disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts])}
            onClick={handleCreateIncident}
          />
          {editHardwareModal &&
            <SmallRoundBtn
              icon={faPenToSquare}
              tooltip='Изменить статус'
              disabled={!hasRole([UserRole.admin, UserRole.mgts])}
              onClick={handleEdit}
            />
          }
        </>
      }
      rightToolbar={
        <SmallRoundBtn
          icon={faTrash}
          color='danger'
          tooltip={`Отвязать от ${getItemLabel(item)}`}
          disabled={!hasRole([UserRole.admin, UserRole.mgts])}
          onClick={handleRemoveHardware}
        />
      }
    >
      <ParamTable>
        <HardwareCardParamTableRows {...{ rows, hardware, reloadCard }} />
        <HardwareCardIncidentList hardware={hardware} reloadCctv={reloadCard} />
      </ParamTable>
    </FolderCard>
  )
}

export default HardwareCard

import { ReactNode, useCallback } from 'react'
import { Hardware, HardwareStateCode, IAccessPoint } from '@/context/types'
import { getHardwareLabel, getHardwareModelLabel } from '@/utils/hardware'
import { HardwareTypeCode } from '@/config/hardware'
import { useModal } from '@/context/ModalContext'
import HardwareLink from '@/components/common/cards/hardware/cells/HardwareLink'
import { HardwareLog } from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardwareList/HardwareLog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faWaveSquare } from '@fortawesome/free-solid-svg-icons'


type Props = {
  hardware: Hardware
  separator?: ReactNode
}


/** Строка в таблице карточки списка оборудования ТВН */
export default function HardwareListCardRow({ hardware, separator }: Props) {
  const { showModalDialog } = useModal()

  const isType = (type: HardwareTypeCode) => hardware.type.code === type
  
  const handleOpenLog = useCallback(() => {
    showModalDialog({
      type: 'ok',
      size: 'lg',
      header: `${getHardwareLabel(hardware)}: Журнал доступности`,
      content: <HardwareLog hardwareId={hardware.id} />,
    })
  }, [hardware, showModalDialog])

  return (
    <tr key={hardware.id}>
      <td>
        {!isType(HardwareTypeCode.VideoNetworkSwitch) && !isType(HardwareTypeCode.CommunicationNetworkSwitch) &&
          separator
        }
        {hardware.type.name}
      </td>
      <td>{getHardwareModelLabel(hardware)}</td>
      <td>{hardware.status.name}</td>
      <td>
        {!isType(HardwareTypeCode.AccessPoint) ? <HardwareLink hardware={hardware} /> : hardware.ipAddress}
      </td>
      <td>
        {isType(HardwareTypeCode.AccessPoint) &&
          <HardwareLink hardware={hardware}>
            {(hardware as IAccessPoint).macAddress}
          </HardwareLink>
        }
      </td>
      <td>
        {!hardware.openIncidents
          ? <FontAwesomeIcon icon={faCircle} className='text-muted' />
          : hardware.openIncidents.length
            ? <FontAwesomeIcon icon={faCircle} className='text-danger' />
            : <FontAwesomeIcon icon={faCircle} className='text-success' />}
      </td>
      <td>
        <button className={`btn px-1 py-0 btn-outline-${getPingColorName(hardware.state.code)}`}>
          <FontAwesomeIcon icon={faCircle} />
        </button>
      </td>
      <td>
        <button
          className='btn btn-link px-1 py-0'
          onClick={handleOpenLog}>
          <FontAwesomeIcon icon={faWaveSquare} />
        </button>
      </td>
    </tr>
  )
}

const getPingColorName = (hostState: string) => {
  switch (hostState) {
    case HardwareStateCode.available:
       return 'success'
    case HardwareStateCode.unavailable:
       return 'danger'
    default:
      return 'secondary'
  }
}

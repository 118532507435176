import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IChannelForm, IChannelWithPayments } from '@/context/types'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'
import {
  ChannelPaymentsTableColumnName, ChannelPaymentsTableColumns,
} from '@/config/tables/lists/channels/channelPayments'


/** Address list context */
const ChannelPaymentsListContext = createContext({} as TableApiResourceContextValue<IChannelWithPayments, ChannelPaymentsTableColumnName, IChannelForm>)


/** Address list context hook */
export const useChannelPaymentsListContext = () => useContext(ChannelPaymentsListContext)


type Props = PropsWithChildren<{}>

export function ChannelPaymentsListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IChannelWithPayments, ChannelPaymentsTableColumnName, IChannelForm>
      Context={ChannelPaymentsListContext}
      apiResource={ApiResource.Channel}
      loadIds
      recordIdKey='channelPaymentsId'
      tableColumns={ChannelPaymentsTableColumns}
      logName='channelPaymentsList'
      groups={['id:read', 'channel_payment:read']}
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}

import { IPec, IPecFileType, IPecStatusField } from '@/context/types'
import { useQuery } from '@tanstack/react-query'
import { fetchPecFileTypes } from '@/api/queries/audit'
import Loader from '@/components/common/Loader'
import { ChangeEvent, useState } from 'react'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import { useParams } from 'react-router-dom'
import PecAttachedFilesItem from '@/components/audit/Folder/statuses_info_card/PecAttachedFilesItem'
import { useAuthContext } from '@/context/auth/AuthContext'
import DeleteFileBtn from '@/components/common/btns/DeleteFileBtn'
import ImageResizor from 'image-resizor'



type Props = {
  pec: IPec
  reloadCard: () => Promise<void>
  pecStatusFieldId: IPecStatusField['id']
}

export default function PecAttachedFiles({ pec, reloadCard, pecStatusFieldId }: Props) {
  const { projectId } = useParams()
  
  const { hasPermission } = useAuthContext()
  const { isFetching: isFileTypesLoading, data: fileTypes } = useQuery({
    queryKey: ['pec-file-types', projectId],
    queryFn: () => fetchPecFileTypes(projectId!),
    initialData: [],
  })
  const [isUploading, setIsUploading] = useState(false)

  const accept = Object.values(ImageResizor.getSupportedTypes()).join(', ')

  const uploadInputFiles = async (e: Event | ChangeEvent<HTMLInputElement>, fileTypeId?: IPecFileType['id']) => {
    setIsUploading(true)

    await Promise.allSettled(
      [...(e.target as HTMLInputElement).files!].map(async file => {

        let resizedBlob: Blob | undefined

        try {
          const imageResizor = await new ImageResizor(file).init()

          resizedBlob = await imageResizor.toBlob()
        } catch (e) {
          console.error(e)
        }

        const formData = new FormData
        if (resizedBlob)
          formData.append('file', resizedBlob, file.name.split('.').slice(0, -1).join('.') + '.jpg')
        else
          formData.append('file', file)

        await axios.post(
          apiRoutes.uploadPecFile(pec.id, pecStatusFieldId!, fileTypeId),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      }),
    )

    await reloadCard()
  }

  const handleUploadTypeFileClick = (fileTypeId: IPecFileType['id']) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = accept

    document.body.appendChild(input) // must be in DOM to upload instant photo on iOS

    input.onchange = e => {
      uploadInputFiles(e, fileTypeId)

      document.body.removeChild(input)
    }

    input.click()
  }

  const additionalFiles = pec.files.filter(file => !file.type && file.pecStatusField.id === pecStatusFieldId)

  if (isFileTypesLoading)
    return <Loader />

  return (
    <>
      <div
        className='d-grid justify-content-center mt-2'
        style={{ gap: '50px 100px', gridTemplateColumns: 'repeat(auto-fit, 300px)' }}
      >
        {fileTypes
          .filter(ft => ft.pecStatusFields.some(pcf => pcf.id === pecStatusFieldId))
          .map(fileType => {
            const file = pec.files
              .find(file => file.type?.id === fileType.id && file.pecStatusField.id === pecStatusFieldId)

            const canDelete = hasPermission('pecFileDelete') && file

            return (
              <div key={fileType.id} className='d-flex flex-column align-items-center'>
                <div className={`d-flex ${canDelete ? 'justify-content-between' : 'justify-content-center'} w-100`}>
                  {canDelete &&
                    <div style={{ width: '2em' }} />
                  }
                  {isUploading
                    ? <Loader />
                    : <button
                      className='btn btn-outline-primary btn-sm'
                      type='button'
                      onClick={() => handleUploadTypeFileClick(fileType.id)}
                      disabled={!hasPermission('pecFileCreate')}
                    >
                      {fileType.name}
                    </button>
                  }
                  {canDelete &&
                    <DeleteFileBtn file={file} onDelete={reloadCard} disabled={isUploading} />
                  }
                </div>
                <PecAttachedFilesItem
                  file={file}
                  reloadCard={reloadCard}
                  pec={pec}
                />
              </div>
            )
          })
        }
      </div>
      <div className='d-flex flex-column align-items-center mt-2'>
        <div>
          <div className='mb-1'>Дополнительные фото:</div>
          {isUploading
            ? <Loader />
            : <input type='file' accept={accept} multiple onChange={uploadInputFiles} disabled={isUploading} />
          }
          <PecAttachedFilesItem
            file={additionalFiles[0]}
            reloadCard={reloadCard} 
            pec={pec}
          />
        </div>
      </div>
    </>
  )
}

import { IRecordWithId, TableFilter } from '@/context/types'
import filterToParams from '@/utils/paramConverters/filterToParams'


const searchToParams = <TColumnName extends string, TRecord extends IRecordWithId>(
  tableColumns: Parameters<typeof filterToParams<TColumnName, TRecord>>[1],
  column: TColumnName,
  searchString: string,
) => filterToParams(
  { [column]: searchString } as TableFilter<TColumnName>,
  tableColumns,
)

export default searchToParams

import { ChangeEvent, CSSProperties, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { useModal } from '@/context/ModalContext'
import Tooltip from '@/components/common/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faUpload, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Loader from '@/components/common/Loader'


type Props = {
	apiRoute: string
	accept?: string
	className?: string
	style?: CSSProperties
	icon?: IconDefinition
	label?: string
	tooltip?: string
	disabled?: boolean
	onSuccess?: () => void
}


/** Кнопка с функцией загрузки файла */
export default function UploadBtn({
  apiRoute,
  accept = '*.*',
  className,
  style,
  icon = faUpload,
  label = 'Загрузить',
  tooltip,
  disabled = false,
  onSuccess
}: Props) {
	const navigate = useNavigate()
	const { showModalDialog } = useModal()
	const fileInputRef = useRef<HTMLInputElement>(null)
	const [status, setStatus] = useState<'ready' | 'loading' | 'error' | 'loaded'>('ready')

	/** Открыть окно выбора файла */
	function handleClick() {
		if (!fileInputRef.current) return
		fileInputRef.current.click()
	}

	/** Отправить выбранный файл на apiRoute */
	async function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
		if (!event.target.files) return
		setStatus('loading')
		try {
			await axios.postForm(apiRoute, { file: event.target.files[0] })

			setStatus('loaded')

			setTimeout(() => {
				setStatus('ready')
				if (!onSuccess)
					navigate(0)
			}, 3_000)

			if (onSuccess)
				onSuccess()
			else
				showModalDialog({
					type: 'ok',
					header: 'Загрузка файла',
					content: 'Файл загружен'
				})
		} catch (e: any) {
			setStatus('error')

			setTimeout(() => {
				setStatus('ready')
			}, 3_000)

			showModalDialog({
				type: 'error',
				header: 'Ошибка при загрузке файла',
				content: await getErrorMessage(e),
			})
		}

		event.target.value = ''
	}

	return (
		<>
			<Tooltip title={tooltip}>
				<button
					className={className ?? 'btn btn-outline-primary text-nowrap btn-sm'}
					style={style}
					disabled={disabled}
					onClick={handleClick}>
					<div className={`d-inline-block ${label && 'me-1'}`} style={{ width: '16px' }}>
						{status === 'ready' &&
							<FontAwesomeIcon icon={icon} />}
						{status === 'loading' &&
							<div style={{ transform: 'translateY(2px)' }}><Loader size={15} /></div>}
						{status === 'error' &&
							<FontAwesomeIcon icon={faTimesCircle} />}
						{status === 'loaded' &&
							<FontAwesomeIcon icon={faCheckCircle} />}
					</div>
					{label}
				</button>
			</Tooltip>
			<input
				className='d-none'
				type='file'
				ref={fileInputRef}
				onChange={handleInputChange}
				accept={accept} />
		</>
	)
}
import FolderCard from '@/components/common/cards/FolderCard'
import { formatDate } from '@/utils/formatters'
import { IServiceWithBilling } from '@/context/types'


type Props = {
  service: IServiceWithBilling
}

const ServiceReportingPeriods = ({ service }: Props) => {
  return (
    <FolderCard
      headerBgColor='#C5D2E6'
      bodyBgColor='#EBE9E2'
      header={`Отчётные периоды  услуги-${service.number}`}>
      <table className='table table-sm table-borderless table-hover w-100 mb-0'>
        <thead className='text-muted'>
          <tr className='border-bottom'>
            <th>Номер периода</th>
            <th>Дата начала</th>
            <th>Дата окончания</th>
            <th>Продолжительность <br />отчётного периода</th>
            <th>Сколько дней проработала данная <br />услуга</th>
          </tr>
        </thead>
        <tbody className='align-middle'>
          <tr>
            <td>{service.billing!.firstPeriod?.name}</td>
            <td>{formatDate(service.orderBeginDate)}</td>
            <td>{formatDate(service.orderEndDate)}</td>
            <td>{service.billing!.firstPeriod?.days}</td>
            <td>{service.billing!.firstPeriod?.serviceDays}</td>
          </tr>
        </tbody>
      </table>
    </FolderCard>
  )
}
export default ServiceReportingPeriods

import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { fetchExploitationIncidents } from '@/api/queries/exploitation'
import ExploitationHeader from '@/components/exploitation/ExploitationHeader'
import { HoursType, hoursTypeLabels } from '@/config/incidents'
import { typedObjectEntries } from '@/utils/object'
import IncidentList from '@/components/CctvListPageContent/common/IncidentList'
import { useTabsContext } from '@/context/TabsContext'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Loader from '@/components/common/Loader'


const ExploitationIncidents = () => {
  const { setLabel } = useTabsContext()
  useDocumentTitle('Инциденты')
  setLabel('Инциденты', faExclamationTriangle)
  const [searchParams] = useSearchParams()
  const [hoursType, setHoursType] = useState(searchParams.get('hours_type') as HoursType)

  const { isFetching: isLoading, data: incidents, refetch } = useQuery({
    queryKey: ['exploitation_incidents', hoursType],
    queryFn: () => fetchExploitationIncidents(hoursType!),
    enabled: !!hoursType,
  })

  return (
    <>
      <ExploitationHeader />
      <div className='container-fluid'>
        <div className='d-flex gap-2 align-items-center my-3'>
          Инциденты по дате открытия:
          {typedObjectEntries(hoursTypeLabels).map(([type, label]) =>
            <button
              key={type}
              className='btn btn-sm btn-outline-primary'
              disabled={type === hoursType}
              onClick={() => setHoursType(type)}
            >{label}</button>,
          )}
        </div>
        {isLoading
          ? <Loader />
          : <table className='table table-sm table-bordered table-hover'>
            <thead className='table-primary'>
              <tr>
                <th>Проект</th>
                <th>Номер ТВН</th>
                <th>Округ</th>
                <th>Район</th>
                <th>Адрес</th>
                <th>Инциденты</th>
              </tr>
            </thead>
            <tbody>
              {incidents?.map(incident =>
                <tr key={incident.incidents_str}>
                  <td>{incident.project_name}</td>
                  <td>{incident.cctv_number}</td>
                  <td>{incident.area_name}</td>
                  <td>{incident.district_name}</td>
                  <td>{incident.address_name}</td>
                  <td><IncidentList incidents={incident.incidents} onUpdate={refetch} showStatus={false} /></td>
                </tr>,
              )}
            </tbody>
          </table>
        }
      </div>
    </>
  )
}

export default ExploitationIncidents
import { IReportSC, RecordTableColumnConfig } from '@/components/reports/types'
import { cctvStatusLabels } from '@/config/status/labels'


export const historyWorkStatusCctv: RecordTableColumnConfig<IReportSC>[] = [
  {
    label: 'Номер ТВН',
    value: reportRow => reportRow.cctv_number
  },
  {
    label: cctvStatusLabels.channelStatus,
    value: reportRow =>
      <>
        {reportRow.channel_status}
        {reportRow.actor &&
          <>
            <br />
            {new Date(reportRow.created_datetime).toLocaleString()}
            <br />
            {reportRow.actor}
          </>
        }
      </>
  },
  {
    label: cctvStatusLabels.installationStatus,
    value: reportRow =>
      <>
        {reportRow.installation_status}
        {reportRow.actor &&
          <>
            <br />
            {new Date(reportRow.created_datetime).toLocaleString()}
            <br />
            {reportRow.actor}
          </>
        }
      </>
  },
  {
    label: cctvStatusLabels.acceptanceStatus,
    value: reportRow =>
      <>
        {reportRow.acceptance_status}
        {reportRow.actor &&
          <>
            <br />
            {new Date(reportRow.created_datetime).toLocaleString()}
            <br />
            {reportRow.actor}
          </>
        }
      </>
  },
  {
    label: cctvStatusLabels.idAcceptanceStatus,
    value: reportRow =>
      <>
        {reportRow.id_acceptance_status_code}
        {reportRow.actor &&
          <>
            <br />
            {new Date(reportRow.created_datetime).toLocaleString()}
            <br />
            {reportRow.actor}
          </>
        }
      </>
  },
  {
    label: cctvStatusLabels.installationPassivePartStatus,
    value: reportRow =>
      <>
        {reportRow.installation_passive_part_status_code}
        {reportRow.actor &&
          <>
            <br />
            {new Date(reportRow.created_datetime).toLocaleString()}
            <br />
            {reportRow.actor}
          </>
        }
      </>
  },
]
import { colorsTable } from '@/components/reports/common/constants'
import { useQuery } from '@tanstack/react-query'
import { fetchPecsSliceStatusStatistic } from '@/api/queries/audit'
import { useParams } from 'react-router-dom'
import Loader from '@/components/common/Loader'
import { IPecStatusField } from '@/context/types'
import PecsStatusStatFilterCell from '@/components/audit/stats/cells/PecsStatusStatFilterCell'
import { useState } from 'react'
import { strIncludesAnyCase } from '@/utils/string'
import { usePecStatusListContext } from '@/context/statuses/PecStatusListContext'


type Props = {
  pecStatusFieldId: IPecStatusField['id']
}

const PecsSliceStatusStat = ({ pecStatusFieldId }: Props) => {
  const { projectId } = useParams()
  const { recordList: statusList, isLoading: isPecStatusListLoading } = usePecStatusListContext()
  const { isFetching: isLoading, data } = useQuery({
    queryKey: ['pec_slice_status_statistic', projectId, pecStatusFieldId],
    queryFn: () => fetchPecsSliceStatusStatistic(projectId!, pecStatusFieldId),
    initialData: [],
  })
  const [filter, setFilter] = useState({
    date: null as null | string,
    contractor: null as null | string,
    status: {} as Record<PecStatusCodes['any'], string>,
  })

  if (isLoading || isPecStatusListLoading)
    return <Loader />

  const filteredData = data.filter(row => {
    const checkField = (fieldVal: string, filterVal: string | null) =>
      !filterVal || strIncludesAnyCase(fieldVal, filterVal)

    if (!checkField(row.date, filter.date))
      return false
    if (!checkField(row.contractor?.name ?? 'Всего', filter.contractor))
      return false

    return !statusList.some(({ code }) =>
      !checkField(row.pec_status_counts[code]?.count.toString() ?? '0', filter.status[code]),
    )
  })

  return (
    <div style={{ overflowX: 'auto' }}>
      <table className='table table-bordered pecs-stat-table pecs-slice-status-stat-table'>
        <thead style={{ backgroundColor: colorsTable.main }}>
          <tr>
            <th>Дата</th>
            <th>ПО</th>
            <th>Кол-во адресов</th>
            {statusList?.map(status =>
              <th key={status.code}>{status.name}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <PecsStatusStatFilterCell
              onChange={val => setFilter(filter => {
                filter.date = val
                return { ...filter }
              })}
            />
            <PecsStatusStatFilterCell
              onChange={val => setFilter(filter => {
                filter.contractor = val
                return { ...filter }
              })}
            />
            <td style={{ backgroundColor: colorsTable.grey }} />
            {statusList.map(status =>
              <PecsStatusStatFilterCell
                key={status.code}
                onChange={val => setFilter(filter => {
                  filter.status[status.code] = val
                  return { ...filter }
                })}
              />,
            )}
          </tr>
          {filteredData.map(row =>
            <tr key={row.date + (row.contractor?.id ?? 'total')} className={row.contractor ? '' : 'total'}>
              <td>{row.date}</td>
              <td>{row.contractor?.name ?? 'Всего'}</td>
              <td>{row.addresses_count}</td>
              {statusList.map(status =>
                <td key={status.code}>{row.pec_status_counts[status.code]?.count ?? 0}</td>,
              )}
            </tr>,
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PecsSliceStatusStat

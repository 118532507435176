import { AxiosResponse } from 'axios'
import { IPaginationResponse } from '@/context/types'


/** Получение объекта пагинации `IPagination` из заголовков ответа `response` */
export default function getAxiosPagination(response: AxiosResponse<any, any>): IPaginationResponse {
	const currentPageNum = getPaginationHeaderValue(response, 'pagination-current-page')
	const itemsPerPageCount = getPaginationHeaderValue(response, 'pagination-items-per-page')
	const lastPageNum = getPaginationHeaderValue(response, 'pagination-last-page')
	const totalItemsCount = getPaginationHeaderValue(response, 'pagination-total-items')
	const isEnabled = itemsPerPageCount !== null
	return {
		pagination: isEnabled,
		page: currentPageNum,
		lastPage: lastPageNum,
		itemsPerPage: itemsPerPageCount,
		totalItems: totalItemsCount,
	}
}

/** Получение числа или undefined из заголовка `name` ответа `response` */
function getPaginationHeaderValue(response: AxiosResponse<any, any>, name: string): number | undefined {
	const value = response.headers[name]
	return value ? +value : undefined
}
import { Fragment, useCallback } from 'react'
import { IIncident, IProject } from '@/context/types'
import Stopwatch from '@/components/common/time/Stopwatch'
import Duration from '@/components/common/time/Duration'
import { getHardwareLabel, getHardwareModelLabel } from '@/utils/hardware'
import { apiRoutes, routes } from '@/config/routes'
import { UserRole } from '@/config/roles'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useModal } from '@/context/ModalContext'
import { incidentCloseModal, incidentDetailModal, incidentUpdateModal } from '@/components/modals/incidents/modals'
import IncidentFile from '@/components/modals/incidents/IncidentParamsFile'
import IncidentLink from '@/components/modals/incidents/IncidentLink'
import IncidentMessage from '@/components/modals/incidents/IncidentMessage'
import UploadBtn from '@/components/common/btns/UploadBtn'
import { NavLink } from 'react-router-dom'


type Props = {
	projectId?: IProject['id']
	incident: IIncident
	onUpdate: () => void
	loadIncident: () => void
}

export default function IncidentParams({ projectId, incident, loadIncident, onUpdate }: Props) {
	const { hasRole } = useAuthContext()
	const { showModalDialog, closeModalDialog } = useModal()

	const handleOpenIncident = useCallback(() => {
		showModalDialog(incidentDetailModal(projectId, incident.id, onUpdate))
	}, [incident.id, onUpdate, showModalDialog, projectId])

	const handleOpenAndLoadIncident = useCallback(() => {
		showModalDialog(incidentDetailModal(projectId, incident.id, onUpdate))
		loadIncident()
	}, [incident.id, onUpdate, loadIncident, showModalDialog, projectId])

	const handleUpdateIncident = useCallback(() => {
		showModalDialog(incidentUpdateModal(incident.id, onUpdate, handleOpenIncident))
	}, [incident.id, onUpdate, handleOpenIncident, showModalDialog])

	const handleCloseIncident = useCallback(() => {
		showModalDialog(incidentCloseModal(incident.id, onUpdate, handleOpenIncident))
	}, [incident.id, onUpdate, handleOpenIncident, showModalDialog])

	const cctvs = incident.hardware.cctvs.filter(c => projectId ? c.project.id === projectId : !c.project.isArchive)

	return (
		<table className='table table-sm table-hover' style={{ width: 'fit-content' }}>
			<tbody>
				<tr>
					<td className='text-muted'>Инцидент открыт</td>
					<td>
						{incident.openDatetime.toLocaleString()}
						{!incident.status && !incident.parent &&
							<button
								className='btn btn-link p-0 ms-3 float-end'
								disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts])}
								onClick={handleCloseIncident}>
								Закрыть
							</button>}
					</td>
				</tr>
				{incident.closedDatetime &&
					<tr>
						<td className='text-muted'>Инцидент закрыт</td>
						<td>{incident.closedDatetime.toLocaleString()}</td>
					</tr>}
				<tr>
					<td className='text-muted'>Длительность инцидента</td>
					<td>
						{incident.closedDatetime
							? <Duration from={incident.createdDatetime} to={incident.closedDatetime} />
							: <Stopwatch initialDatetime={incident.createdDatetime} />
						}
						{+incident.openDatetime !== +incident.createdDatetime &&
							<span className='text-muted'>
								{' ('}
								{incident.closedDatetime
									? <Duration from={incident.openDatetime} to={incident.closedDatetime} />
									: <Stopwatch initialDatetime={incident.openDatetime} />
								}
								{' в '}
								{incident.origin?.name ?? (incident.createdByUser ? 'Польз.' : 'СМО')}
								{')'}
							</span>}
					</td>
				</tr>
				<tr >
					<td className='text-muted'>Cистема</td>
					<td>{incident.origin?.name ?? <span className='text-muted'>СМО</span>}</td>
				</tr>
				{incident.originId &&
					<tr>
						<td className='text-muted'>ID в системе</td>
						<td>{incident.originId}</td>
					</tr>}
				<tr>
					<td className='text-muted'>Пользователь</td>
					<td>{incident.createdByUser
						? <>
							{incident.createdByUser.firstName}
							{' '}
							{incident.createdByUser.lastName}
						</>
						: <span className='text-muted'>Система</span>}
					</td>
				</tr>
				{!incident.status &&
					<>
						<tr>
							<td className='text-muted'>Текущий этап</td>
							<td>
								{incident.stage?.name ?? '—'}
								{!incident.parent &&
									<button
										className='btn btn-link p-0 ms-3 float-end'
										disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts, UserRole.contractor])}
										onClick={handleUpdateIncident}>
										Изменить
									</button>}
							</td>
						</tr>
						<tr>
							<td className='text-muted'>Время на этапе</td>
							<td>
								<Stopwatch initialDatetime={incident.updatedDatetime} />
								{' '}
								<span className='text-muted'>
									с {incident.updatedDatetime.toLocaleString()}
								</span>
							</td>
						</tr>
					</>}
				{incident.status &&
					<tr>
						<td className='text-muted'>Статус</td>
						<td>{incident.status.name}</td>
					</tr>}
				<tr>
					<td className='text-muted'>Файлы</td>
					<td>
						{incident.files.map(file =>
							<IncidentFile
								key={file.id}
								file={file}
								onDone={handleOpenIncident} />)}
						<UploadBtn
							className='btn btn-link p-0 text-nowrap'
							label='Прикрепить файл'
							apiRoute={apiRoutes.uploadIncidentFile(incident.id)}
							disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts])}
							onSuccess={handleOpenAndLoadIncident} />
					</td>
				</tr>
				<tr>
					<td className='text-muted'>Оборудование</td>
					<td>{getHardwareLabel(incident.hardware)}</td>
				</tr>
				<tr>
					<td className='text-muted'>Модель оборудования</td>
					<td>{getHardwareModelLabel(incident.hardware)}</td>
				</tr>
				<tr>
					<td className='text-muted'>Адрес</td>
					<td>{incident.hardware.address.name}</td>
				</tr>
				{!!cctvs.length &&
					<tr>
						<td className='text-muted'>ТВН</td>
						<td>
							{cctvs.map((cctv, index) =>
								<Fragment key={cctv.id}>
									{!!index && ', '}
									{cctv.type.name}
									<NavLink to={routes.projectCctv(cctv.project.id, cctv.id)} className='mx-1' onClick={closeModalDialog}>
										{cctv.number}
									</NavLink>
									{!projectId &&
										<>({cctv.project.name})</>
									}
								</Fragment>,
								)}
						</td>
					</tr>
				}
				<tr>
					<td className='text-muted'>Комментарий</td>
					<td style={{maxWidth: 500}} className='text-break'>
						<IncidentMessage height='288px'>
							{incident.description}
						</IncidentMessage>
					</td>
				</tr>
				{incident.parent &&
					<tr>
						<td className='text-muted'>Групповой инцидент</td>
						<td><IncidentLink projectId={projectId} incidentId={incident.parent.id} onUpdate={onUpdate} /></td>
					</tr>}
				{incident.children
					&& !!incident.children.length &&
					<tr>
						<td className='text-muted'>Дочерние инциденты</td>
						<td>
							{incident.children.map((childIncident, index) =>
								<Fragment key={childIncident.id}>
									{!!index && ', '}
									<IncidentLink projectId={projectId} incidentId={childIncident.id} onUpdate={onUpdate} />
								</Fragment>)}
						</td>
					</tr>}
			</tbody>
		</table>
	)
}
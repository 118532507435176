import { IServiceWithBilling, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { ServiceTableColumnNameList, ServiceTableColumns } from '@/config/tables/lists/commutication/service'
import { formatDate, formatPrice } from '@/utils/formatters'


export const BillingRevenueTableColumnNameList = ServiceTableColumnNameList

export type BillingRevenueTableColumnName = typeof BillingRevenueTableColumnNameList[number]

export const BillingRevenueTableColumns: Record<BillingRevenueTableColumnName, TableColumnConfig<IServiceWithBilling>> = {
  ...ServiceTableColumns,
  contractNumber: {
    ...ServiceTableColumns.contractNumber,
    hidden: true,
  },
}

export const prepareBillingRevenueAdditionalCols = (service: IServiceWithBilling) => {
  const { billing } = service
  if (!billing)
    return []

  const cols: TableAdditionalColumnConfig[] = []

  billing.reportingPeriods.map(rp => {
    cols.push(
      {
        label: 'Дата начала ОП',
        key: rp.id,
        value: formatDate(rp.beginDate),
      },
      {
        label: 'Дата окончания ОП',
        key: rp.id,
        value: formatDate(rp.endDate),
      },
      {
        label: 'Кол-во дней в ОП',
        key: rp.id,
        value: rp.days,
      },
      {
        label: 'Кол-во дней работы услуги в данном ОП',
        key: rp.id,
        value: rp.serviceDays,
      },
      {
        label: 'Доход за данный ОП',
        key: rp.id,
        value: formatPrice(rp.revenue),
      }
    )

    if (rp.lastForYear)
      cols.push({
        label: `Итого за ${rp.lastForYear} год`,
        value: formatPrice(billing.years[rp.lastForYear!].revenue),
      })
  })

  if (billing.reportingPeriods.length)
    cols.push({
      label: 'Итоговый доход за все ОП',
      value: formatPrice(billing.total.revenue),
    })

  return cols
}

import FolderCard from '@/components/common/cards/FolderCard'
import style from '@/assets/styles/card.module.scss'
import { ReactNode, useCallback, useMemo } from 'react'
import ParamTable from '@/components/common/cards/ParamTable'
import ParamTableRow from '@/components/common/cards/ParamTableRow'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useModal } from '@/context/ModalContext'
import { IPec } from '@/context/types'
import { PecCardRowName, PecCardRowNameList, PecCardRows } from '@/config/tables/cards/audit/pec'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, routes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import { useTabsContext } from '@/context/TabsContext'
import { UserRole } from '@/config/roles'
import { createEditPecInfoModal } from '@/components/modals/pec/modals'
import { useParams } from 'react-router-dom'
import ShowOnYaMap from '@/components/common/ShowOnYaMap'


type Props = {
  pec: IPec
  reloadPec: () => Promise<void>
}

const PecInfoCard = ({ pec, reloadPec }: Props) => {
  const { projectId } = useParams()
  const { showModalDialog } = useModal()
  const { hasPermission } = useAuthContext()
  const { closeCurrentTab } = useTabsContext()
  const availableCols = useTableAvailableCols(PecCardRowNameList, PecCardRows)

  const collapseTargetId = 'collapsePecInfoCard'

  const handleEditPec = useCallback(() => {
    showModalDialog(createEditPecInfoModal(pec.project.id, reloadPec, pec))
  }, [pec, reloadPec, showModalDialog])

  const handleDeletePec = useCallback(() => {
    showModalDialog({
      type: 'deleteCancel',
      header: `Удаление УИК-${pec.number}`,
      content: `Вы уверены, что хотите удалить УИК-${pec.number}`,
      primaryCallback: async () => {
        try {
          await axios.delete(apiRoutes.pec(pec.id))
          closeCurrentTab(routes.auditProject(projectId!))
        } catch (e: any) {
          showModalDialog({
            type: 'error',
            header: `Удаление УИК-${pec.number}: ошибка`,
            content: await getErrorMessage(e, `Произошла ошибка при удалении УИК-${pec.number}`),
          })
        }
      },
    })
  }, [pec, showModalDialog, closeCurrentTab])

  const customRows: Partial<Record<PecCardRowName, ReactNode>> = useMemo(() => ({
    address: <ShowOnYaMap item={pec} />,
  }), [pec, reloadPec])

  return (
    <FolderCard
      headerBgColor='#FFD8A8'
      bodyBgColor='#E1EBFA'
      header={
        <button
          className={`btn text-primary p-0 fw-bold ${style.cardHeaderArrow}`}
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={`#${collapseTargetId}`}
          aria-expanded='true'
          aria-controls={collapseTargetId}
        >
          Информация по УИК - {pec.number}
        </button>
      }
      centerToolbar={
        <SmallRoundBtn
          icon={faPenToSquare}
          tooltip='Редактировать сведения'
          disabled={!hasPermission(['pecEdit', 'pecBrigadierEdit'])}
          onClick={handleEditPec}
        />
      }
      rightToolbar={
        <SmallRoundBtn
          icon={faTrash}
          color='danger'
          tooltip='Удалить УИК'
          disabled={!hasPermission('pecDelete')}
          onClick={handleDeletePec}
        />
      }
    >
      <div className='collapse show' id={collapseTargetId}>
        <ParamTable>
          {availableCols.map(columnName =>
            <ParamTableRow key={columnName} label={PecCardRows[columnName].label!} width='33%'>
              {customRows[columnName] ?? PecCardRows[columnName].value?.(pec)}
            </ParamTableRow>,
          )}
        </ParamTable>
      </div>
    </FolderCard>
  )
}

export default PecInfoCard

import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'


export type Option<Id extends string, SubId extends string = string> = {
  id: Id,
  name: string,
  cctvsCount: number,
  ipCamerasCount: number,
  className?: string,
  subOptions: OptionsArray<SubId>,
}

type NewOptionData<Id extends string> = Omit<Option<Id>, 'cctvsCount' | 'ipCamerasCount' | 'subOptions'>

export class OptionsArray<Id extends string, SubId extends string = string> extends Array<Option<Id, SubId>> {
  constructor(...data: NewOptionData<Id>[]) {
    super()
    data.forEach(o => this.addOrUpdateOption(o))
  }

  addOrUpdateOption(data: NewOptionData<Id>, cctv?: MonitoringMapCctv) {
    let existOption = this.find(o => o.id === data.id)

    if (!existOption) {
      existOption = {
        ...data,
        cctvsCount: 0,
        ipCamerasCount: 0,
        subOptions: new OptionsArray(),
      }

      this.push(existOption)
    }

    if (cctv)
      this.updateOption(existOption.id, cctv)
  }

  updateOption(id: Id, cctv: MonitoringMapCctv) {
    let existOption = this.find(o => o.id === id)
    if (!existOption)
      return

    existOption.cctvsCount++
    existOption.ipCamerasCount += cctv.ipCameras.length
  }

  addOrUpdateSubOption(optionId: Id, data: NewOptionData<SubId>, cctv?: MonitoringMapCctv) {
    this.find(o => o.id === optionId)?.subOptions.addOrUpdateOption(data, cctv)
  }

  toArray() {
    return Array.from(this)
  }

  filter(
    predicate: (value: Option<Id, SubId>, index: number, array: Option<Id, SubId>[]) => unknown, thisArg?: any,
  ): Option<Id, SubId>[] {
    return this.toArray().filter(predicate, thisArg)
  }

  map<U>(callbackfn: (value: Option<Id, SubId>, index: number, array: Option<Id, SubId>[]) => U, thisArg?: any) {
    return this.toArray().map<U>(callbackfn, thisArg)
  }
}

import { PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '@/components/common/Footer'
import GuestNav from '@/components/nav/GuestNav'
import Main from '@/components/common/Main'


type Props = PropsWithChildren<{}>

export default function GuestPageContainer({ children }: Props) {

	return (
		<>
			<GuestNav />
			<Main>
				{children ?? <Outlet />}
			</Main>
			<Footer />
		</>
	)
}
import { ChangeEvent, KeyboardEvent, MouseEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


type Props = {
	onSearchSubmit?: (searchString: string) => void
}


/** Компонент поиска для справочников */
export default function SearchInputGroup({ onSearchSubmit }: Props) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [searchString, setSearchString] = useState<string>(searchParams.get('search') ?? '')

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setSearchString(event.target.value)
	}

	function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter')
			handleSearch(searchString)
	}

	function handleSubmit(event: MouseEvent<HTMLButtonElement>) {
		handleSearch(searchString)
	}

	/** Поиск */
	function handleSearch(searchString: string) {
		if (onSearchSubmit) return onSearchSubmit(searchString)
		setSearchParams({ search: searchString })
	}

	return (
		<div className='input-group'>
			<span className='input-group-text'>
				<FontAwesomeIcon icon={faMagnifyingGlass} />
			</span>
			<input type='text'
				className='form-control'
				value={searchString}
				placeholder='Поиск...'
				enterKeyHint='search'
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/>
			<button
				className='btn btn-outline-primary'
				onClick={handleSubmit}
			>
				Найти
			</button>
		</div>
	)
}

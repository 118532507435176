import { IIncident, IIPCamera } from '@/context/types'
import { btnClasses } from '@/components/ProjectsContent/monitoring/map/config/cctvBalloon'


type Handlers = {
  onCctvClick: () => any,
  onRtspClick: () => any,
  onIpCameraClick: (snapshotPath: IIPCamera['snapshotPath']) => any,
  onIncidentClick: (id: IIncident['id']) => any,
}

export const hydrateCctvBalloonContent = ({ onCctvClick, onRtspClick, onIpCameraClick, onIncidentClick }: Handlers) => {
  const addEventListener = (className: string, listener: (b: HTMLButtonElement) => any) =>
    Array.from(document.getElementsByClassName(className))?.forEach(b =>
      b.addEventListener('click', () => listener(b as HTMLButtonElement)),
    )

  addEventListener(btnClasses.cctv, () => onCctvClick())
  addEventListener(btnClasses.rtspBtn, () => onRtspClick())
  addEventListener(btnClasses.ipCamera, el => onIpCameraClick(el.getAttribute('data-snapshot-path')!))
  addEventListener(btnClasses.incident, el => onIncidentClick(+el.getAttribute('data-id')!))
}

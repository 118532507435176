import { useNavigate } from 'react-router-dom'
import { routes } from '@/config/routes'
import ToolbarMenu from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenu'
import ToolbarMenuButton from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenuButton'
import { faFileZipper } from '@fortawesome/free-solid-svg-icons'
import useProjectId from '@/hooks/useProjectId'
import AuditToolbarSearchGroup from '@/components/audit/toolbar/AuditToolbarSearchGroup'
import PermissionRequired from '@/components/auth/PermissionRequired'


export default function AuditToolbar() {
  const projectId = useProjectId()
  const navigate = useNavigate()

  return (
    <div style={{ width: '100%', height: 46 }}>
      <div
        className="container-fluid bg-light d-flex justify-content-center"
        style={{ position: 'fixed', width: '100%', zIndex: 200, height: 46 }}
      >
        <div className='btn-toolbar flex-nowrap gap-2 my-2'>
          <AuditToolbarSearchGroup />
          <PermissionRequired permission={'auditProjectPecFilesExport'}>
            <ToolbarMenu label='Проект'>
              <ToolbarMenuButton
                label='Экспорт графических файлов'
                icon={faFileZipper}
                onClick={() => navigate(routes.auditExportFiles(projectId))}
              />
            </ToolbarMenu>
          </PermissionRequired>
        </div>
      </div>
    </div>
  )
}

import { useState } from 'react'
import { ApiResource, IIncident, IIncidentUpdateForm, IStatusStage } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'
import { useFormContext } from '@/context/form/FormContext'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import Alert from '@/components/common/Alert'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import Loader from '@/components/common/Loader'
import TextAreaInput from '@/components/common/form/TextAreaInput'


type Props = {
	onDone: () => void
	onCancel: () => void
}


export default function IncidentUpdateForm({ onDone, onCancel }: Props) {
	const { showModalDialog, usePrimaryCallback, useSecondaryCallback } = useModal()
	const { isRecordLoading: isIncidentLoading, record: incident } = useApiResourceContext<IIncident>()
	const { form, setError } = useFormContext<IIncidentUpdateForm>()
	const [isLoading, setIsLoading] = useState(false)

	usePrimaryCallback(async () => {
		if (!incident) return
		if (!form.stage) setError('stage', 'Выберите этап')
		if (form.stage === incident.stage?.code) setError('stage', `Инцидент уже на этапе «${incident.stage.name}». Выберите другой этап.`)
		if (!form.message) setError('message', 'Введите комментарий')
		if (!form.stage || form.stage === incident.stage?.code || !form.message) return false
		try {
			setIsLoading(true)
			await axios.post(apiRoutes.updateIncident(incident.id), form)
			onDone()
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Ошибка',
				content: await getErrorMessage(e),
			})
		} finally {
			setIsLoading(false)
		}
	}, [form, incident, onDone, showModalDialog])

	useSecondaryCallback(onCancel, [onCancel])

	if (isIncidentLoading) return <Loader />

	if (!incident) return <Alert>Инцидент не найден</Alert>

	return (
		<div style={{minHeight: 400}}>
			<ApiResourceSelect<IStatusStage, IIncidentUpdateForm>
				apiResource={ApiResource.IncidentStage}
				label='Этап'
				name='stage'
				initValue={incident.stage}
				required
				disabled={isLoading} />
			<TextAreaInput<IIncidentUpdateForm>
				label='Комментарий'
				name='message'
				required
				disabled={isLoading} />
		</div>
	)
}
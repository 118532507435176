import { CSSProperties, useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ApiResource } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getApiResourceCreatePath, getApiResourceDetailsPath, getApiResourceEditPath, routes } from '@/config/routes'
import { ApiResources } from '@/config/apiResources'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useModal } from '@/context/ModalContext'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import Alert from '@/components/common/Alert'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import Loader from '@/components/common/Loader'
import Main from '@/components/common/Main'
import Pagination from '@/components/common/Pagination'
import SearchInputGroup from '@/components/common/SearchInputGroup'
import UploadBtn from '@/components/common/btns/UploadBtn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleInfo, faDownload, faExclamationTriangle, faInfoCircle, faPenToSquare, faPlus, faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { useAuthContext } from '@/context/auth/AuthContext'
import CatalogFilesModal from '@/components/modals/catalogs/CatalogFilesModal'
import { getBgColorClassName } from '@/utils/color'


/** Контент страницы справочника */
export default function RecordListPageContent() {
	const { hasPermission } = useAuthContext()
	const params = useParams()
	const apiResource = params.apiResource as ApiResource
	const { isRecordListLoading, recordList, lastPage, error } = useApiResourceContext<any>()
	const {
		title,
		immutable,
		fieldList,
		fluid,
		importApiUrl,
		bindApiUrl,
		exportApiUrl,
		infoModalProps,
		fileUploadRoute,
	} = ApiResources[apiResource]!
	const { showModalDialog } = useModal()
	const handleShowFilterInfo = useCallback(() => {
    if (infoModalProps)
      showModalDialog(infoModalProps)
  }, [showModalDialog, infoModalProps])


	useDocumentTitle(title)

	const thStyle: CSSProperties = useMemo(() => {
		if (fluid) return {}
		return { width: `${110 / fieldList.length}%` }
	}, [fieldList, fluid])

	return (
		<>
			<BreadcrumbsContainer>
				<BreadcrumbsItem path={routes.catalogs}>Справочники</BreadcrumbsItem>
				<BreadcrumbsItem>{title}</BreadcrumbsItem>
			</BreadcrumbsContainer>
			<Main fluid={fluid}>
				<h1>{title}</h1>
				<div className='btn-toolbar gap-3 my-3'>
					{!immutable && hasPermission('catalogEdit') &&
						<Link to={getApiResourceCreatePath(apiResource)} className='btn btn-outline-primary'>
							<FontAwesomeIcon icon={faPlus} className='me-2' />
							Добавить
						</Link>
					}
					{importApiUrl && hasPermission('importCatalogs') &&
						<UploadBtn label='Импорт' apiRoute={importApiUrl} accept='.csv' />
					}
					{bindApiUrl && hasPermission('catalogEdit') &&
						<UploadBtn label='Привязать' apiRoute={bindApiUrl} />
					}
					{exportApiUrl && hasPermission('exportCatalogs') &&
						<a href={exportApiUrl} className='btn btn-outline-primary' target={'_blank'}>
							<FontAwesomeIcon icon={faDownload} className='me-2' />
							Экспорт
						</a>
					}
					<SearchInputGroup />
					{infoModalProps && hasPermission('importCatalogs') &&
						<button
							className='btn btn-link p-0 ms-auto'
							onClick={handleShowFilterInfo}
						>
							<FontAwesomeIcon icon={faInfoCircle} className='mx-2' />Справка
						</button>
					}
				</div>
				{error
					? <Alert>
						<FontAwesomeIcon icon={faExclamationTriangle} className='me-2' />
						{error}
					</Alert>
					: isRecordListLoading
						? <Loader />
						: !recordList.length
							? <Alert colorName='secondary'>
								<FontAwesomeIcon icon={faCircleInfo} className='me-2' />
								Записей не найдено
							</Alert>
							: <table className={`table ${fluid && 'table-bordered'} table-hover`}>
								<thead className='table-primary' style={{ position: 'sticky', top: '96px'}} >
									<tr>
										{fieldList.map(({ label }, index) =>
											<th key={index} style={thStyle}>{label}</th>)}
										{fileUploadRoute && <th />}
										{!immutable && hasPermission('catalogEdit') &&
											<th style={{ width: '1%' }}></th>}
									</tr>
								</thead>
								<tbody>
									{recordList.map((record) =>
										<RecordTableRow
											key={record.id ?? record.code}
											apiResource={apiResource}
											record={record} />)}
								</tbody>
							</table>}
				<Pagination lastPage={lastPage} disabled={isRecordListLoading} />
			</Main>
		</>
	)
}


type RecordTableRowProps = {
	record: any
	apiResource: ApiResource
}


/** Строка в таблице записей справочника */
function RecordTableRow({ record, apiResource }: RecordTableRowProps) {
	const { showModalDialog } = useModal()
	const { loadRecordList, deleteRecord } = useApiResourceContext<any>()
  const { hasPermission } = useAuthContext()
	const { title, getRecordTitle, immutable, fieldList, fileUploadRoute } = ApiResources[apiResource]!
	const recordId = record.id ?? record.code

	const handleDelete = useCallback(() => {
		const recordTitle = getRecordTitle ? getRecordTitle(record) : record.name
		showModalDialog({
			type: 'deleteCancel',
			header: `${title}: удаление записи`,
			content: `Вы действительно хотите удалить запись «${recordTitle}» из справочника «${title}»?`,
			primaryCallback: async () => {
				try {
					await deleteRecord(recordId)
					loadRecordList()
				} catch (e: any) {
					showModalDialog({
						type: 'error',
						header: `${title}: удаление записи: ошибка`,
						content: await getErrorMessage(e),
					})
				}
			}
		})
	}, [record, loadRecordList, deleteRecord, title, getRecordTitle, recordId, showModalDialog])

	const showFilesModal = () => showModalDialog({
		type: 'ok',
		content: <CatalogFilesModal record={record} />,
		header: 'Прикреплённые файлы',
	})

	return (
		<tr>
			{fieldList.map(({ value, path, colorValue }, index) =>
				<td key={index} className={colorValue && getBgColorClassName(colorValue(record))}>
					{path && path(record)
						? <Link to={path(record)!}>{value(record)}</Link>
						: index === 0
							? <Link to={getApiResourceDetailsPath(apiResource, recordId)}>{value(record)}</Link>
							: value(record)
					}
				</td>)}
				{fileUploadRoute && 
					<td>
						{!!record.files.length && 
							<button className='btn btn-link p-0 ms-auto text-nowrap' onClick={showFilesModal}>
                Прикреплённые файлы
              </button>
						}
					</td>
				}
			{!immutable && hasPermission('catalogEdit') &&
				<td className='text-end text-nowrap'>
					<Link to={getApiResourceEditPath(apiResource, recordId)}>
						<FontAwesomeIcon icon={faPenToSquare} className='me-2' />
					</Link>
					<button
						className='btn btn-link link-danger p-0'
						onClick={handleDelete}>
						<FontAwesomeIcon icon={faTrash} />
					</button>
				</td>}
		</tr>
	)
}
import { routes } from '@/config/routes'
import NavContainer from '@/components/nav/common/NavContainer'
import NavItem from '@/components/nav/common/NavItem'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'


/** Главное меню гостя */
export default function GuestNav() {

	return (
		<NavContainer>
			<ul className='navbar-nav w-100'>
				<NavItem label='Вход' to={routes.signIn} icon={faRightToBracket} className='ms-sm-auto' />
			</ul>
		</NavContainer>
	)
}

import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{}>

const Control = ({ children }: Props) =>
  <div
    className='d-flex flex-column justify-content-sm-center border border-primary rounded'
    style={{ background: 'rgba(255, 255, 255, .9)', minHeight: 33 }}
  >
    <div>{children}</div>
  </div>

export default Control

import { useState } from 'react'
import { IIPCamera, IIpCameraVideoMarkerForm } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import TextInput from '@/components/common/form/TextInput'
import Loader from '@/components/common/Loader'
import { useMutation } from '@tanstack/react-query'
import { updateVideoMarker } from '@/api/queries/projects'


type Props = {
  ipCamera: IIPCamera
  reloadCctv: () => Promise<void>
}

export default function IpCameraVideoMarkerForm({ ipCamera, reloadCctv }: Props) {
  const { form, setError } = useFormContext<IIpCameraVideoMarkerForm>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [videoMarkerCode, setVideoMarkerCode] = useState(ipCamera.videoMarkerCode)
  const [isLoading, setIsLoading] = useState(false)

  const { mutateAsync } = useMutation({
    mutationFn: () => updateVideoMarker(ipCamera.id, videoMarkerCode),
    onError: async e =>
      showModalDialog({
        type: 'error',
        header: 'Ошибка',
        content: await getErrorMessage(e),
      }),
  })

  usePrimaryCallback(async () => {
    if (isLoading)
      return false
    if (!form.videoMarkerCode) {
      setError('videoMarkerCode', 'Обязательное поле')
      return false
    } else if (form.videoMarkerCode === ipCamera.videoMarkerCode) {
      setError('videoMarkerCode', 'Значение не изменено')
      return false
    }

    setIsLoading(true)

    await mutateAsync()

    reloadCctv()
    setIsLoading(false)
  }, [isLoading, setIsLoading, form.videoMarkerCode, ipCamera.videoMarkerCode, mutateAsync, reloadCctv])

  return (
    <>
      <TextInput
        label='Имя IP-камеры в пределах ТВН'
        initValue={ipCamera.codeOnCctv}
        name='codeOnCctv'
        disabled
      />
      <TextInput
        label='Видеомаркер'
        value={videoMarkerCode}
        onChange={setVideoMarkerCode}
        name='videoMarkerCode'
        disabled={isLoading}
      />
      <button
        className='btn btn-sm btn-outline-primary'
        disabled={isLoading}
        onClick={() => setVideoMarkerCode(ipCamera.codeOnCctv)}
      >
        Взять видеомаркер из имени
      </button>
      {isLoading && <Loader />}
    </>
  )
}
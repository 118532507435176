import { useRef, useState } from 'react'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { ApiResource, IAddress, IPec, IPecForm } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { apiRoutes } from '@/config/routes'
import { useModal } from '@/context/ModalContext'
import { useFormContext } from '@/context/form/FormContext'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import TextInput from '@/components/common/form/TextInput'
import { addressToSelectOption } from '@/utils/recordToSelectOption'
import { contractorOrganizationListFilter } from '@/config/listFilters'
import { UserRole } from '@/config/roles'
import CheckboxInput from '@/components/common/form/CheckboxInput'
import { useAuthContext } from '@/context/auth/AuthContext'
import PermissionRequired from '@/components/auth/PermissionRequired'
import NumberInput from '@/components/common/form/NumberInput'


export type Props = {
  projectId: string
  onSave: (pecId: IPec['id']) => any
  pec?: IPec
}

export default function PecCreateEditForm({ pec, onSave, projectId }: Props) {
  const { showModalDialog, usePrimaryCallback } = useModal()
  const { form, setError } = useFormContext<IPecForm>()
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef<HTMLDivElement>(null)
  const { hasPermission } = useAuthContext()

  usePrimaryCallback(async () => {
    if (!pec && !form.number) setError('number', 'Укажите номер УИК')
    if (hasPermission('pecEdit') && !form.cctvNumber) setError('cctvNumber', 'Укажите номер ТВН')
    if (hasPermission('pecEdit') && !form.address) setError('address', 'Укажите адрес УИК')

    if (hasPermission('pecEdit') && ((!pec && !form.number) || !form.cctvNumber || !form.address)) {
      formRef.current?.scrollIntoView()
      return false
    }

    try {
      setIsLoading(true)

      if (pec) {
        await axios.patch(
          apiRoutes.pec(pec.id),
          form,
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )
        onSave(pec.id)
      } else {
        const res = await axios.post(apiRoutes.pecs, {
          project: projectId,
          ...form,
          number: '' + form.number // non-numeric value will cause sort error on backend. Sending number as string.
        })
        onSave(res.data.id)
      }
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `${pec ? 'Обновление' : 'Добавление'} УИК: Ошибка`,
        content: await getErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }, [form, projectId, showModalDialog])

  return (
    <div ref={formRef} className='w-100 p-3'>
      {!pec &&
        <NumberInput<IPecForm>
          label='Номер УИК'
          name='number'
          required
          disabled={isLoading}
        />
      }
      <PermissionRequired permission={'pecEdit'}>
        <TextInput<IPecForm>
          label='Номер ТВН'
          name='cctvNumber'
          required
          initValue={pec?.cctvNumber}
          disabled={isLoading}
        />
        <ApiResourceSelect<IAddress, IPecForm>
          apiResource={ApiResource.Address}
          async
          label='Адрес'
          name='address'
          required
          initValue={pec?.address}
          recordToOption={addressToSelectOption}
          disabled={isLoading}
        />
        <CheckboxInput<IPecForm>
          label='Дострой'
          initValue={pec?.completion}
          name='completion'
          disabled={isLoading}
        />
      </PermissionRequired>
    </div>
  )
}

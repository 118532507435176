import { useState, useMemo } from 'react'
import { ApiResource, IPec, IPecStatusField, IUser } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import Loader from '@/components/common/Loader'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import { UserRole } from '@/config/roles'
import { patchPecBrigadier } from '@/api/queries/audit'


type Props = {
  pec: IPec
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>,
  selectedOrganizationId: string | undefined
}

type Form = {
  brigadier: IUser['id']
}

// Тип для фильтра
type ApiResourceFilter = {
  'userRoles.code'?: UserRole;
  'organization.id'?: string;
}

export default function PecEditBrigadierForm({ pec, statusFieldId, selectedOrganizationId, reloadCard }: Props) {
  const { form } = useFormContext<Form>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  const filter = useMemo<ApiResourceFilter | undefined>(() => {
    if (!selectedOrganizationId) {
      return undefined;
    }
    return {
      'userRoles.code': UserRole.brigadier,
      'organization.id': selectedOrganizationId
    }
  }, [selectedOrganizationId])

  usePrimaryCallback(async () => {
    if (isLoading) return false

    setIsLoading(true)

    try {
      await patchPecBrigadier(pec.id, statusFieldId, form.brigadier)
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении бригадира',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }, [isLoading, setIsLoading, form.brigadier, pec, statusFieldId, reloadCard])

  return (
    <div style={{minHeight: 375}}>
      {selectedOrganizationId ? (
        <ApiResourceSelect<IPec['brigadiers'][0], Form>
          apiResource={ApiResource.User}
          filter={filter}
          label='Бригадир'
          name='brigadier'
          initValue={pec.brigadiers.find(e => e.statusFieldId === statusFieldId)}
          disabled={isLoading}
        />
      ) : (
        <p>Подрядная организация (ПО) не выбрана. Пожалуйста, выберите организацию, чтобы отобразить доступных бригадиров.</p>
      )}
      {isLoading && <Loader />}
    </div>
  )
}

import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap'
import { ModalProvider } from '@/context/ModalContext'
import { AuthContextProvider } from '@/context/auth/AuthContext'
import '@/assets/styles/index.scss'
import App from '@/App'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 500,
    },
  },
  queryCache: new QueryCache({
    onError: console.error,
  }),
})

root.render(
	<BrowserRouter>
		<AuthContextProvider>
			<QueryClientProvider client={queryClient}>
				<ModalProvider>
					<App />
				</ModalProvider>
			</QueryClientProvider>
		</AuthContextProvider>
	</BrowserRouter>
)
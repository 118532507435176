import { cloneElement, ReactElement, useEffect, useRef } from 'react'
import { Tooltip as BootstrapTooltip } from 'bootstrap'


type Props = {
	title?: string
	placement?: 'top' | 'bottom' | 'right' | 'left'
	children: ReactElement
}


/** Компонент-обертка, добавляющая Bootstrap tooltip к дочернему компоненту */
export default function Tooltip({ title, placement = 'top', children }: Props) {
	const childRef = useRef<Element>()

	useEffect(() => {
		if (!childRef.current) return
		if (!title) return
		const tooltipObj = new BootstrapTooltip(childRef.current, { title, placement })
		return () => tooltipObj.dispose()
	}, [title, placement])

	return cloneElement(children, { ref: childRef })
}
import { HardwareStateCode } from '@/context/types'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'
import { getIcon } from '@/components/ProjectsContent/monitoring/map/config/icons'


type Props = {
  cctv: MonitoringMapCctv
}

const PlacemarkIconContent = ({ cctv }: Props) =>
  <>
    {!!cctv.incidents.length && getIcon('incident', HardwareStateCode.unavailable)} <span>{cctv.number}</span>
  </>

export default PlacemarkIconContent

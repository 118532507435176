import { useState } from 'react'
import useInterval from 'use-interval'


type Props = {
	className?: string
	timestamp: number
}


/** <span/> с таймером/секундомером */
export default function Timer({ className, timestamp }: Props) {
	const [nowTimestamp, setNowTimestamp] = useState(Date.now())
	const seconds = Math.floor(Math.abs(nowTimestamp - timestamp) / 1000)
	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(minutes / 60)
	const hh = hours ? (hours % 24).toString() : ''
	const mm = (minutes % 60).toString().padStart(2, hh ? '0' : '')
	const ss = (seconds % 60).toString().padStart(2, '0')

	useInterval(() => {
		setNowTimestamp(Date.now())
	}, 100)

	return (
		<span className={className}>
			{hh ? hh + ':' : ''}
			{mm}:
			{ss ? ss : ''}
		</span>
	)
}
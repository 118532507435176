import { usePecStatusListContext } from '@/context/statuses/PecStatusListContext'
import PecStatusControlMenu from '@/components/audit/Folder/statuses_info_card/statuses/PecStatusControlMenu'
import { IPec, IPecStatusField } from '@/context/types'


type Props = {
  pec: IPec
  reloadCard: () => Promise<void>
  pecStatusFieldId: IPecStatusField['id']
}

const PecStatus = ({ pec, reloadCard, pecStatusFieldId }: Props) => {
  const { recordList: statusList } = usePecStatusListContext()

  return (
    <div className='d-flex flex-row gap-2'>
      Текущий статус:
      <PecStatusControlMenu
        pec={pec}
        statusFieldName={pecStatusFieldId}
        statusList={statusList}
        statusName={pec.statusFields.find(sf => sf.id === pecStatusFieldId)?.valueName}
        reloadCard={reloadCard}
      />
    </div>
  )
}

export default PecStatus

import AuditToolbar from '@/components/audit/toolbar/AuditToolbar'
import AuditProjectNav from '@/components/audit/AuditProjectNav'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'


const AuditProjectHeader = () =>
  <>
    <BreadcrumbsContainer />
    <AuditToolbar />
    <AuditProjectNav />
  </>

export default AuditProjectHeader

import Tooltip from '@/components/common/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { IIPCamera } from '@/context/types'


type Props = {
  path: IIPCamera['snapshotPath']
}

const OpenSnapshotBtn = ({ path }: Props) =>
  <Tooltip title='Открыть снимок в новом окне'>
    <a href={path} target={'_blank'} rel='noreferrer'>
      <FontAwesomeIcon icon={faExternalLink} />
    </a>
  </Tooltip>

export default OpenSnapshotBtn

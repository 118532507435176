import { useEffect, useId, useState } from 'react'
import { useFormContext } from '@/context/form/FormContext'
import InputGroup from '@/components/common/form/InputGroup'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


type Props<TForm> = {
  label: string
  name: keyof TForm
  placeholder?: string
  required?: boolean
  helpText?: string
  disabled?: boolean
  isMulti?: boolean
  onChange?: (value: string, i: number) => any
} & ({
  initValue?: string | string[]
  value?: undefined
} | {
  initValue?: undefined
  value: string
})

/** Текстовое поле ввода для контекстной формы */
export default function TextInput<TFrom>({
  label,
  name,
  initValue,
  value,
  placeholder,
  required,
  helpText,
  disabled,
  isMulti,
  onChange,
}: Props<TFrom>) {
  const { setField, errors } = useFormContext<TFrom>()

  let initValues = ['']
  if (isMulti && initValue?.length)
    initValues = initValue as string[]
  else if (!isMulti && initValue)
    initValues = [initValue as string]
  
  const [values, setValues] = useState(initValues)
  const id = useId()

  useEffect(() => {
    value && setValues([value])
  }, [setValues, value])

  useEffect(() => {
    setField(name, isMulti ? values : values[0])
  }, [setField, name, isMulti, values])

  return (
    <InputGroup
      id={id}
      label={label}
      required={required}
      helpText={helpText}
      invalidMessage={name && errors[name]}>
      <div className='d-grid gap-2'>
        {values.map((value, i) =>
          <div className='d-flex align-items-center gap-2' key={i}>
            <input
              className={`form-control ${name && errors[name] && 'is-invalid'}`}
              type='text'
              id={i ? id + i : id /* //todo one label can't have two inputs */ }
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              onChange={e => {
                values[i] = e.target.value
                setValues([...values])
                onChange?.(e.target.value, i)
              }}
            />
            {isMulti &&
              <FontAwesomeIcon
                className='text-danger'
                icon={faTrash}
                style={{ cursor: 'pointer' }}
                onClick={() => setValues(values.filter((v, index) => index !== i))}
              />
            }
          </div>
        )}
      </div>
      {isMulti &&
        <button className='btn btn-link' onClick={() => setValues([...values, ''])}>+ добавить</button>
      }
    </InputGroup>
  )
}

import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import { useModal } from '@/context/ModalContext'
import { instanceAxios as axios } from '@/api/instanceAxios'
import {
  ApiResource, IChannelField, IProjectField, IRecordWithId, IService, IUser, TableColumnConfig, UserTableHiddenColsKey,
  userTableHiddenColsFieldsMap,
} from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getApiResourceUpdateRoute } from '@/config/routes'
import { useAuthContext } from '@/context/auth/AuthContext'
import useIsTableColDisplayed from '@/hooks/useIsTableColDisplayed'
import { typedObjectEntries } from '@/utils/object'


export type Props<TRecord extends IRecordWithId, TColumnName extends string> = {
  userTableHiddenColsKey: UserTableHiddenColsKey
  columns: Record<TColumnName, TableColumnConfig<TRecord>>
  customFields?: (IChannelField | IProjectField)[]
}

export const TableColsSelect = <TRecord extends IRecordWithId, TColumnName extends string>({
  userTableHiddenColsKey,
  columns,
  customFields,
}: Props<TRecord, TColumnName>) => {
  const { user, updateProfile } = useAuthContext()
  const isColDisplayed = useIsTableColDisplayed(userTableHiddenColsKey)
  const { usePrimaryCallback, showModalDialog } = useModal()
  const [uncheckedCols, setUncheckedCols] = useState<string[]>([])

  useEffect(() => {
    setUncheckedCols(
      [...Object.keys(columns), ...(customFields?.map(pf => pf.id) ?? [])]
        .filter(col => !isColDisplayed(col)),
    )
  }, [columns, customFields, isColDisplayed])

  usePrimaryCallback(async () => {
    try {
      const updateFields: Partial<IUser> = {
        [userTableHiddenColsKey]: uncheckedCols.map(colId => ({
          [userTableHiddenColsFieldsMap[userTableHiddenColsKey]]: colId
        }))
      }

      await axios.patch<IUser>(
        getApiResourceUpdateRoute(ApiResource.User, user!.id),
        updateFields,
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )

      updateProfile({ ...user!, ...updateFields })
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка',
        content: await getErrorMessage(e),
      })
    }
  }, [uncheckedCols])

  const handleCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUncheckedCols(
      e.target.checked
        ? uncheckedCols.filter(c => c !== e.target.value)
        : [...uncheckedCols, e.target.value],
    )
  }, [setUncheckedCols, uncheckedCols])

  return (
    <table className='table table-sm table-bordered table-hover mt-3' style={{ height: 1 }}>
      <thead className='table-primary'>
        <tr>
          {typedObjectEntries(columns).map(([key, col]) =>
            <Col key={key} id={key} name={col.label ?? ''} checked={isColDisplayed(key)} onChange={handleCheck} />,
          )}
          {customFields?.map(pf =>
            <Col key={pf.id} id={pf.id} name={pf.name} checked={isColDisplayed(pf.id)} onChange={handleCheck} />,
          )}
        </tr>
      </thead>
    </table>
  )
}

type ColProps = {
  id: string
  name: string
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

const Col = ({ id, name, checked, onChange }: ColProps) => {
  return (
    <th>
      {name}
      <div className='form-check form-switch m-0 d-flex justify-content-center'>
        <input
          className='form-check-input'
          type='checkbox'
          name={name}
          role='switch'
          value={id}
          onChange={onChange}
          defaultChecked={checked}
        />
      </div>
    </th>
  )
}
import { useModal } from '@/context/ModalContext'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { IChannel } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = {
  channelId: string
  reloadCard: () => Promise<void>
}

const CloneChannelBtn = ({ reloadCard, channelId }: Props) => {
  const { showModalDialog } = useModal()
  const { hasPermission } = useAuthContext()

  const handleCloneChannel = async () => {
    try {
      await axios.post<{ channels: IChannel[] }>(apiRoutes.cloneChannels(channelId))
      showModalDialog({
        type: 'ok',
        header: 'Канал клонирован',
        size: 'lg',
        content:
          <>
            <div>Канал успешно клонирован</div>
          </>,
      })
      await reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'ok',
        size: 'lg',
        header: 'Ошибка',
        content: await getErrorMessage(e),
      })
    }
  }

  return (
    <button
      className='btn btn-sm btn-primary py-0 rounded me-2'
      onClick={handleCloneChannel}
      disabled={!hasPermission('channelCreate')}
    >
      Клонировать
    </button>
  )
}

export default CloneChannelBtn

import { IPec, IPecStatusField } from '@/context/types'
import { usePecStatusFieldListContext } from '@/context/entityFields/PecStatusFieldListContext'
import { usePecStatusListContext } from '@/context/statuses/PecStatusListContext'
import { useEffect, useState } from 'react'
import FolderCard from '@/components/common/cards/FolderCard'
import Loader from '@/components/common/Loader'
import PecStatus from '@/components/audit/Folder/statuses_info_card/statuses/PecStatus'
import PecStatusHistory from '@/components/audit/Folder/statuses_info_card/PecStatusHistory'
import PecAttachedFiles from '@/components/audit/Folder/statuses_info_card/PecAttachedFiles'
import { useSearchParams } from 'react-router-dom'
import { useAuthContext } from '@/context/auth/AuthContext'
import PecExecutor from '@/components/audit/Folder/statuses_info_card/PecExecutor'
import PermissionRequired from '@/components/auth/PermissionRequired'
import PecWorkBeginDate from '@/components/audit/Folder/statuses_info_card/PecWorkBeginDate'
import PecBrigadier from '@/components/audit/Folder/statuses_info_card/PecBrigadier'
import PecResponsibleFromCustomer from '@/components/audit/Folder/statuses_info_card/PecResponsibleFromCustomer'
import PecContractor from '@/components/audit/Folder/statuses_info_card/PecContractor'


type Props = {
  pec: IPec
  reloadCard: () => Promise<void>
}

const PecStatusInfoCard = ({ pec, reloadCard }: Props) => {
  const { hasPermission } = useAuthContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const { recordList: pecStatusFields, isLoading: isPecStatusFieldListLoading } = usePecStatusFieldListContext()
  const { isLoading: isPecStatusListLoading } = usePecStatusListContext()
  const [activeStatusFieldId, setActiveStatusFieldId] = useState(null as IPecStatusField['id'] | null)

  const searchParamsStatusFieldId = searchParams.get('statusField') as IPecStatusField['id'] | undefined

  const filterAvailableStatusFields = () => {
    if (hasPermission('pecEdit')) {
      return pecStatusFields
    }
    if (hasPermission('pecExecutorEdit')) {
      return pecStatusFields.filter(sf => pec.brigadiers.some(e => e.statusFieldId === sf.id))
    }
    return pecStatusFields.filter(sf => pec.executors.some(e => e.statusFieldId === sf.id))
  }

  const availableStatusFields = filterAvailableStatusFields()

  useEffect(() => {
    if (availableStatusFields.length && !activeStatusFieldId) {
      setActiveStatusFieldId(
        searchParamsStatusFieldId && availableStatusFields.some(sf => sf.id === searchParamsStatusFieldId)
          ? searchParamsStatusFieldId
          : availableStatusFields[0].id,
      )
    }
  }, [availableStatusFields, searchParamsStatusFieldId, activeStatusFieldId])

  if (isPecStatusFieldListLoading || isPecStatusListLoading)
    return <Loader />

  if (!activeStatusFieldId)
    return <></>

  const selectedContractor = pec.contractors.find(contractor => contractor.statusFieldId === activeStatusFieldId)

  return (
    <FolderCard
      headerBgColor='#fff'
      bodyBgColor='#f4f4f4'
      header={
        <div className='btn-toolbar gap-2 bg-white'>
          {availableStatusFields.map(pecStatusField =>
            <button
              key={pecStatusField.id}
              className={`btn btn-sm btn-outline-primary ${pecStatusField.id === activeStatusFieldId ? 'active' : ''}`}
              style={{ width: '10em' }}
              onClick={() => {
                setActiveStatusFieldId(pecStatusField.id)
                setSearchParams({ statusField: pecStatusField.id })
              }}
            >
              {pecStatusField.name}
            </button>,
          )}
        </div>
      }
    >
      <div className='d-flex p-2 gap-4 flex-column'>
        Выполнение работ по: {availableStatusFields.find(sf => sf.id === activeStatusFieldId)?.name}
        <PermissionRequired permission='pecWorkBeginDateEdit'>
          <PecWorkBeginDate pec={pec} pecStatusFieldId={activeStatusFieldId} reloadCard={reloadCard} />
        </PermissionRequired>
        <PermissionRequired permission='pecEdit'>
          <PecContractor pec={pec} pecStatusFieldId={activeStatusFieldId} reloadCard={reloadCard} />
          <PecResponsibleFromCustomer pec={pec} pecStatusFieldId={activeStatusFieldId} reloadCard={reloadCard} />
        </PermissionRequired>
        <PermissionRequired permission='pecBrigadierEdit'>
          <PecBrigadier 
            pec={pec} 
            pecStatusFieldId={activeStatusFieldId} 
            reloadCard={reloadCard} 
            selectedOrganizationId={selectedContractor?.id}/>
        </PermissionRequired>
        <PermissionRequired permission='pecExecutorEdit'>
          <PecExecutor 
            pec={pec} 
            pecStatusFieldId={activeStatusFieldId} 
            reloadCard={reloadCard} 
            selectedOrganizationId={selectedContractor?.id}/>
        </PermissionRequired>
        <PecStatus pec={pec} reloadCard={reloadCard} pecStatusFieldId={activeStatusFieldId} />
        <PecStatusHistory pec={pec} pecStatusFieldId={activeStatusFieldId} />
        <PecAttachedFiles pec={pec} reloadCard={reloadCard} pecStatusFieldId={activeStatusFieldId} />
      </div>
    </FolderCard>
  )
}

export default PecStatusInfoCard

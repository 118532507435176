import { useParams } from 'react-router-dom'
import { faDesktop, faDolly, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'
import { fetchData } from '@/api/fetchData'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { errorHandle } from '@/utils/errorHandle'
import Loader from '@/components/common/Loader'
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import 'react-datepicker/dist/react-datepicker.css'
import { useTabsContext } from '@/context/TabsContext'
import ProjectHeader from '@/components/ProjectsContent/ProjectHeader'
import { allReportApiCodes, ReportCode, reportNames } from '@/config/reports'
import { getReportReadRoute } from '@/config/routes'
import ExploitationHeader from '@/components/exploitation/ExploitationHeader'
import { getReportFilter } from '@/config/listFilters'


type Props<T> = PropsWithChildren<{
  code: ReportCode
  dataRange?: boolean
  startDate?: Date
  endDate?: Date
  setStartDate?: Dispatch<SetStateAction<Date>>
  setEndDate?: Dispatch<SetStateAction<Date>>
  report: T
  setReport: Dispatch<SetStateAction<T>>
  setShowOpenTT?: Dispatch<SetStateAction<boolean>>
  setShowCloseTT?: Dispatch<SetStateAction<boolean>>
}>

export const ReportWrapper = <T = unknown>({
  code,
  dataRange,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  report,
  setReport,
  setShowCloseTT = () => null,
  setShowOpenTT = () => null,
  children,
}: Props<T>) => {
  useDocumentTitle(reportNames[code])
  registerLocale('ru', ru)
  const [showReport, setShowReport] = useState(false)
  const { projectId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  
  const { setLabel } = useTabsContext()

  setLabel('Отчет', faDolly)
  
  const date = new Date().toLocaleDateString()
  const time = new Date().toLocaleString([], { hour: '2-digit', minute: '2-digit' })

  const apiRouteParams = getReportFilter(projectId, startDate, endDate)
  const apiCode = allReportApiCodes[code]

  const handleShowReport = () => {
    setShowCloseTT(false)
    setShowOpenTT(false)
    fetchData({
      url: getReportReadRoute(apiCode, apiRouteParams),
      setData: (res) => {
        setReport(res)
        setShowReport(true)
      },
      setIsLoading,
      errorHandler: (err) => errorHandle(err),
    })
  }
  return (
    <>
    {projectId ? <ProjectHeader /> : <ExploitationHeader />}
    <div className='container-fluid pt-2 mt-2'>
      <div className='d-flex flex-column mt-4 ms-4'>
        <h5>
          <span className='text-muted'>Отчёт: </span>
          {reportNames[code]}
        </h5>
        {dataRange && (
          <div className='row'>
            <div className='col-md-auto'>
              <div className='input-group input-group-sm' style={{ zIndex: '2' }}>
                <div className='input-group-prepend'>
                  <span style={{ width: '6em' }}>Дата с:</span>
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate && setStartDate(date!)}
                  selectsStart
                  className='form-control form-control-sm'
                  locale={'ru'}
                  dateFormat='dd.MM.yyyy'
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={endDate}
                />
              </div>
            </div>
            <div className='col-md-auto'>
              <div className='input-group input-group-sm' style={{ zIndex: '2' }}>
                <div className='input-group-prepend'>
                  <span style={{ width: '6em' }}>Дата по:</span>
                </div>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate && setEndDate(date!)}
                  selectsEnd
                  locale={'ru'}
                  className='form-control form-control-sm'
                  dateFormat='dd.MM.yyyy'
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
        )}
        <div className='btn-toolbar gap-2 my-2 align-items-center mt-4'>
          <button
            className='btn btn-sm btn-outline-primary'
            onClick={handleShowReport}
            disabled={!projectId && code === 'cctvsStatusHistory' /* //todo-sem пока не понятно нужен ли вообще этот отчёт. В данный момент при нажатии страница крашится, так что ставим disabled */ }
          >
            <FontAwesomeIcon icon={faDesktop} className='me-2' />
            Показать
          </button>
          <a
            href={getReportReadRoute(apiCode, { ...apiRouteParams, format: 'xlsx' })}
            target={'_blank'}
            rel='noreferrer'
            className='btn btn-sm btn-outline-primary'
          >
            <FontAwesomeIcon icon={faFileExcel} className='me-2' />
            Excel
          </a>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        showReport && (
          <div className='mt-4'>
            <p>
              Дата создания отчёта: {date} {time}
            </p>
            {dataRange && (
              <p>
                Дата создания ТТ:
                {` c ${startDate?.toLocaleDateString()} по ${endDate?.toLocaleDateString()}`}
              </p>
            )}

            {children}

            {/* <PaginationWSP
              currentPage={report.page}
              setCurrentPage={setPage}
              lastPage={report.lastPage}
            /> */}
          </div>
        )
      )}
    </div>
    </>
  )
}
export const cDate = (date: Date) => {
  return date.toLocaleString('ru-Ru', { timeZone: 'Europe/Moscow' }).split(',')[0].split('.').reverse().join('-')
}
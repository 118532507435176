import { useCallback, useMemo, useState } from 'react'
import { TableSampler } from '@/context/types'


/** Хук для работы с формой сэмлера таблицы*/
export default function useTableSamplerForm(samplerGroupLabels: Record<string, string>) {
  // Форма сэмплера
  const [samplerForm, setSamplerForm] = useState<TableSampler>({})
  // Хранилище лейблов для выбранных позиций сэмплера
  // samplerLabels['channelsOperators/0bac815a-d6ad-41d0-bcd5-8fb875b6fddc'] => 'ПАО "МГТС"'
  // samplerLabels['channelStatus.code/not_started'] => 'Работы не начаты'
  const [samplerLabels, setSamplerLabels] = useState<Record<string, string>>({})

  // Текстовое представление сэмплера
  // '(Оператор канала связи: ПАО "МГТС") и (Работы по каналу: Работы не начаты)'
  const samplerLabel = useMemo(() => {
    return Object.entries(samplerForm)
      .filter(([name, set]) => set.size > 0)
      .map(([name, set]) =>
        '('
        + samplerGroupLabels[name]
        + ': '
        + Array.from(set)
          .map(value => value ? samplerLabels[`${name}/${value}`] ?? value : 'Нет')
          .join(' или ')
        + ')')
      .join(' и ')
  }, [samplerForm, samplerLabels, samplerGroupLabels])

  /** Обновление формы сэмлера */
  const updateSampler = useCallback((name: string, value: string | null, label: string) => {
    setSamplerForm(state => {
      const set = state[name] ?? new Set<string>()
      if (set.has(value))
        set.delete(value)
      else
        set.add(value)
      return { ...state, [name]: new Set(set) }
    })
    setSamplerLabels(state => ({ ...state, [`${name}/${value}`]: label }))
  }, [setSamplerForm, setSamplerLabels])

  return {
    samplerForm,
    samplerLabel,
    updateSampler
  }
}
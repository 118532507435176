import { channelStatusLabels } from '@/config/status/labels'
import { useAuthContext } from '@/context/auth/AuthContext'
import TableStatusMenu, { WrapperTableStatusMenuProps } from '@/components/common/table/TableStatusMenu'
import { apiRoutes } from '@/config/routes'
import { useChannelListContext } from '@/context/tables/ChannelListContext'


const ChannelTableStatusMenu = (props: WrapperTableStatusMenuProps<ChannelStatusKey>) => {
  const { hasPermission } = useAuthContext()
  const { selectedRecordIdSet, loadRecordList, deselectAllRecords } = useChannelListContext()

  return (
    <TableStatusMenu
      {...props}
      fieldLabelsRecord={channelStatusLabels}
      recordApiRoute={apiRoutes.channel}
      selectedRecordIdSet={selectedRecordIdSet}
      deselectAllRecords={deselectAllRecords}
      loadRecordList={loadRecordList}
      hasEditPermission={hasPermission('channelStageEdit')}
    />
  )
}

export default ChannelTableStatusMenu

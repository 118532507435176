import { MutableRefObject, useEffect } from 'react'


const useOuterClick = (ref: MutableRefObject<HTMLElement | null>, onOuterClick: Function) => {
  useEffect(() => {
    const eventListener: Parameters<Document['addEventListener']>[1] = e => {
      
      if (!ref.current?.contains(e.target as Node))
        onOuterClick()
    }

    document.addEventListener('mousedown', eventListener, true)
    return () => document.removeEventListener('mousedown', eventListener, true)
  }, [ref, onOuterClick])
}

export default useOuterClick

import { memo } from 'react'
import Control from '@/components/ProjectsContent/monitoring/map/controls/Control'
import { FullscreenControl as YFullscreenControl } from 'react-yandex-maps'
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { useYandexMapContext } from '@/context/YandexMapContext'
import { TwigIf } from '@/utils/twig'


type Props = YControlOptions

const FullscreenControl = memo(({ float, floatIndex }: Props) => {
  const { createLayout } = useYandexMapContext()

  const Icon = (props: Pick<FontAwesomeIconProps, 'icon' | 'title'>) =>
    <FontAwesomeIcon className='px-2' role='button' {...props} />

  return (
    <YFullscreenControl
      options={{
        float,
        floatIndex,
        layout: createLayout(
          <Control>
            <TwigIf
              condition='state.fullscreen'
              If={<Icon icon={faCompressAlt} title='Выход из полноэкранный режима' />}
              Else={<Icon icon={faExpandAlt} title='Полноэкранный режим' />}
            />
          </Control>,
        ),
      }}
    />
  )
})

export default FullscreenControl

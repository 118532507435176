import { HardwareTypeCode } from '@/config/hardware'
import { HasPermissionParam } from '@/context/auth/AuthContext'
import { ReactNode } from 'react'
import { OrganizationRole, UserRole } from '@/config/roles'
import { TableTotals } from '@/api/queries/table-totals'
import { HoursType } from '@/config/incidents'

/************************
 *   ОБЩИЕ ИНТЕРФЕЙСЫ   *
 ************************/

/** Объект пагинации api-запроса со списком объектов */
export interface IPaginationRequest {
  pagination: boolean
  page?: number
  itemsPerPage?: number
}
/** Объект пагинации api-ответа со списком объектов */
export interface IPaginationResponse {
  pagination: boolean
  page?: number
  lastPage?: number
  itemsPerPage?: number
  totalItems?: number
}
/** Список записей с пагинацией */
export interface IRecordListWithPagination<TRecord> {
  recordList: TRecord[]
  pagination: IPaginationResponse
}

/** Базовый тип записи.
 *  Первичный ключ: .id или .code.
 *  Возможно .name или .code.
 */
export type IRecordIdentifier<R> = R extends IRecordWithId
  ? R['id']
  : (
    R extends { code: string | number }
      ? R['code']
      : never
    )

export type IRecordWithId = { id: string | number }

export type IRecord = IIdRecord
  | ICodeRecord
  | ICodeOrderNumberRecord
  | (IRecordWithId & { code: string })
  | IRecordWithId
  | (IRecordWithId & { number: string | number })
export interface IIdRecord {
  id: string | number
  name: string
}
export interface ICodeRecord<T = string> {
  code: T
  name: string
}
export interface ICodeOrderNumberRecord<T = string> extends ICodeRecord<T> {
  orderNumber: number
}

/** Этап статуса */
export interface IStatusStage<T = string> extends ICodeOrderNumberRecord<T> {}

/** Опция селектора */
export interface ISelectOption<T = string | number | boolean | null> {
  value: T
  label: string
}

/** Сэмплер таблицы (требует обработки для использования в API) */
export type TableSampler = Record<string, Set<string | null>>

/** Фильтр таблицы (требует обработки для использования в API) */
export type TableFilter<TColumnName extends string> = Partial<Record<TColumnName, string>>

/** Фильтр ресурса API (используется в API) */
export type ApiResourceFilter<TFieldName extends string = string> = Record<TFieldName, string | string[]>

type TableColumnConfigFilterAndSort = {
  filterKey?: undefined
  filterExistsKey?: undefined
  filterAndSortViewKey?: undefined
  sortKey?: string
} | {
  filterKey: string
  filterExistsKey?: string // will take 'filterKey' if not defined
  filterAndSortViewKey?: undefined
  sortKey?: string
} | {
  filterKey?: undefined // will take 'filterAndSortViewKey'
  filterExistsKey?: undefined
  filterAndSortViewKey: `${string}:${string}` // `table:key`. used when backend has 'view' filter and sort for this column. 
  sortKey?: undefined // will take 'filterAndSortViewKey'
}

export type TableColumnConfig<TRecord extends IRecordWithId> = {
  label?: string // displays in table head, react 'key' value
  filterPlaceholder?: string
  labelColspan?: number
  filterColspan?: number
  valueColspan?: number
  value: (record: TRecord) => ReactNode | ReactNode[] // displays in table cell
  colorValue?: (record: TRecord) => string | string[] // color table cell base on colorValue(record)
  noWrap?: boolean
  hidden?: boolean
  permissions?: HasPermissionParam
  type?: 'bool' | 'boolExists' | 'ipAddressExists' | 'date'
  boolFilterLabels?: [string, string] //[label for true, label for false]
  totalsKey?: TableTotals<TRecord> extends never ? never : keyof TableTotals<TRecord>
} & TableColumnConfigFilterAndSort

export type TableAdditionalColumnConfig = Pick<
  TableColumnConfig<any>,
  'filterPlaceholder' | 'labelColspan' | 'filterColspan' | 'valueColspan' | 'noWrap' | 'type'
> & {
  label: string
  key?: string // react 'key' additional value (used when 'label' is not unique), used for pecs customColumnLabels
  value: ReactNode
  colorValue?: string
} & TableColumnConfigFilterAndSort

export type CardTableRowConfig<TRecord> = {
  label: string
  value: (record: TRecord, reloadItem: () => Promise<any>) => ReactNode
}

/*******************
 *   Все ресурсы   *
 *******************/

/**
 * Перечень ресурсов API.
 *
 * Значение = фрагмент url на фронте
 */
export enum ApiResource {
  // Адреса
  Area = 'areas',
  District = 'districts',
  Address = 'addresses',
  ChannelAddress = 'addresses/with-channels',
  // Контакты
  OrganizationRole = 'organization-roles',
  Organization = 'organizations',
  UserRole = 'user-roles',
  User = 'users',
  // Оборудование
  Vrf = 'vrfs',
  HardwareStatus = 'hardware-statuses',
  Incident = 'incidents',
  IncidentStage = 'incident-stages',
  IncidentStatus = 'incident-statuses',
  IncidentStageLog = 'incident-stage-logs',
  // Коммутаторы
  NetworkSwitchModel = 'network-switch-models',
  NetworkSwitchFirmware = 'network-switch-firmwares',
  NetworkSwitchPreset = 'network-switch-presets',
  NetworkSwitch = 'network-switches',
  //Маршрутизаторы
  RouterModel = 'router-models',
  RouterFirmware = 'router-firmwares',
  RouterPreset = 'router-presets',
  Router = 'routers',
  // IP-камеры
  IpCameraModel = 'ip-camera-models',
  IpCameraFirmware = 'ip-camera-firmwares',
  IpCameraPreset = 'ip-camera-presets',
  IpCamera = 'ip-cameras',
  // Видеосерверы
  VideoServerModel = 'video-server-models',
  VideoServerFirmware = 'video-server-firmwares',
  VideoServerPreset = 'video-server-presets',
  VideoServer = 'video-servers',
  // Точки доступа
  AccessPointModel = 'access-point-models',
  AccessPointFirmware = 'access-point-firmwares',
  AccessPoint = 'access-points',
  // Источники бесперебойного питания
  UpsModel = 'ups-models',
  UpsFirmware = "ups-firmware",
  UpsPreset = "ups-presets",
  Ups = "ups",
  // ТВНы
  Cctv = 'cctvs',
  MonitoringCctv = 'monitoringCctv',
  CctvChannelStatus = 'cctv-channel-statuses',
  CctvInstallationStatus = 'cctv-installation-statuses',
  CctvAcceptanceStatus = 'cctv-acceptance-statuses',
  CctvInstallationPassivePartStatus = 'cctv-installation-passive-part-statuses',
  CctvFileType = 'cctv-file-types',
  CctvType = 'cctv-types',
  ChannelTechnology = 'channel-technologies',
  RoomType = 'room-types',
  RoomSquare = 'room-squares',
  OutletAvailability = 'outlet-availabilities',
  StorageRoomType = 'storage-room-types',
  StorageRoomSquare = 'storage-room-squares',
  TelecomOperatorRoomType = 'telecom-operator-room-types',
  TelecomOperatorTechnology = 'telecom-operator-technologies',
  // Проекты
  Project = 'projects',
  ProjectType = 'project-types',
  ProjectField = 'project-fields',
  // Связь
  Contract = 'contracts',
  ServiceType = 'service-types',
  ContractServiceTypePrice = 'contract-service-type-prices',
  Department = 'departments',
  Consumer = 'consumers',
  ReportingPeriod = 'reporting-periods',
  CommunicationAddress = 'communication-addresses',
  Service = 'services',
  ConstructionObject = 'construction-objects',
  Channel = 'channels',
  ChannelStages = 'chanel-stages',
  ChannelField = 'channel-fields',
  Joint = 'joints',
  QualityClass = 'quality-classes',
  ConstructionObjectStatus = 'construction-object-statuses',
  PortOwner = 'port-owners',
  Agreement = 'agreements',
  OrderForm = 'order-forms',
  OrderFormSigningStatus = 'order-form-signing-statuses',
  Pec = 'pecs',
  PecFileType = 'pec-file-types',
  PecStatusFields = 'pec-status-fields',
}

/**
 * Интерфейсы записей ресурсов API
 *
 * ApiResourceInterfaces[ApiResource.User] => IUser
 */
export type ApiResourceRecordTypes = {
  [T in ApiResource]: {
    // Адреса
    [ApiResource.Area]: IArea
    [ApiResource.District]: IDistrict
    [ApiResource.Address]: IAddress
    [ApiResource.ChannelAddress]: IChannelAddress
    // Контакты
    [ApiResource.OrganizationRole]: IOrganizationRole
    [ApiResource.Organization]: IOrganization
    [ApiResource.UserRole]: IUserRole
    [ApiResource.User]: IUser
    // Оборудование
    [ApiResource.Vrf]: IVrf
    [ApiResource.HardwareStatus]: IStatusStage
    [ApiResource.Incident]: IIncident
    [ApiResource.IncidentStage]: IStatusStage
    [ApiResource.IncidentStatus]: IIncidentStatus
    [ApiResource.IncidentStageLog]: IIncidentStageLogRecord
    // Коммутаторы
    [ApiResource.NetworkSwitchModel]: IHardwareModel
    [ApiResource.NetworkSwitchFirmware]: IHardwareFirmware
    [ApiResource.NetworkSwitchPreset]: IHardwarePreset
    [ApiResource.NetworkSwitch]: INetworkSwitch
    //Маршрутизаторы
    [ApiResource.RouterModel]: IHardwareModel,
    [ApiResource.RouterFirmware]: IHardwareFirmware,
    [ApiResource.RouterPreset]: IHardwarePreset,
    [ApiResource.Router]: IRouter,
    // IP-камеры
    [ApiResource.IpCameraModel]: IHardwareModel
    [ApiResource.IpCameraFirmware]: IHardwareFirmware
    [ApiResource.IpCameraPreset]: IHardwarePreset
    [ApiResource.IpCamera]: IIPCamera
    // Видеосерверы
    [ApiResource.VideoServerModel]: IHardwareModel
    [ApiResource.VideoServerFirmware]: IHardwareFirmware
    [ApiResource.VideoServerPreset]: IHardwarePreset
    [ApiResource.VideoServer]: IVideoServer
    // Точки доступа
    [ApiResource.AccessPointModel]: IHardwareModel
    [ApiResource.AccessPointFirmware]: IHardwareFirmware
    [ApiResource.AccessPoint]: IAccessPoint
    // Источники бесперебойного питания
    [ApiResource.UpsModel]: IHardwareModel
    [ApiResource.UpsFirmware]: IHardwareFirmware
    [ApiResource.UpsPreset]: IHardwarePreset
    [ApiResource.Ups]: IUps
    // ТВНы
    [ApiResource.Cctv]: ICctv
    [ApiResource.MonitoringCctv]: IMonitoringCctv
    [ApiResource.CctvChannelStatus]: ICctv['channelStatus']
    [ApiResource.CctvInstallationStatus]: ICctv['installationStatus']
    [ApiResource.CctvAcceptanceStatus]: ICctv['acceptanceStatus']
    [ApiResource.CctvInstallationPassivePartStatus]: ICctv['installationPassivePartStatus']
    [ApiResource.CctvFileType]: ICctvFileType
    [ApiResource.CctvType]: ICctvType
    [ApiResource.ChannelTechnology]: IChannelTechnology
    [ApiResource.RoomType]: IRoomType
    [ApiResource.RoomSquare]: IRoomSquare
    [ApiResource.OutletAvailability]: IOutletAvailability
    [ApiResource.StorageRoomType]: IStorageRoomType
    [ApiResource.StorageRoomSquare]: IStorageRoomSquare
    [ApiResource.TelecomOperatorRoomType]: ITelecomOperatorRoomType
    [ApiResource.TelecomOperatorTechnology]: ITelecomOperatorTechnology
    // Проекты
    [ApiResource.Project]: IProject
    [ApiResource.ProjectType]: IProjectType
    [ApiResource.ProjectField]: IProjectField
    // Связь
    [ApiResource.Contract]: IContract
    [ApiResource.ServiceType]: IServiceType
    [ApiResource.ContractServiceTypePrice]: IContractServiceTypePrice
    [ApiResource.Department]: IDepartment
    [ApiResource.Consumer]: IConsumer
    [ApiResource.ReportingPeriod]: IReportPeriod
    [ApiResource.CommunicationAddress]: ICommunicationAddress
    [ApiResource.Service]: IService
    [ApiResource.ConstructionObject]: IConstructionObject
    [ApiResource.Channel]: IChannel
    [ApiResource.ChannelStages]: IStatusStage
    [ApiResource.ChannelField]: IChannelField
    [ApiResource.Joint]: IJoint
    [ApiResource.QualityClass]: IQualityClass
    [ApiResource.ConstructionObjectStatus]: IConstructionObjectStatus
    [ApiResource.PortOwner]: IPortOwner
    [ApiResource.Agreement]: IAgreement
    [ApiResource.OrderForm]: IOrderForm
    [ApiResource.OrderFormSigningStatus]: IStatusStage
    [ApiResource.Pec]: IPec
    [ApiResource.PecFileType]: IPecFileType
    [ApiResource.PecStatusFields]: IPecStatusField
  }[T]
}

/**
 * Интерфейсы форм (для справочников) записей ресурсов API
 *
 * ApiResourceInterfaces[ApiResource.User] => IUser
 */
export type ApiResourceRecordFormTypes = {
  [T in ApiResource]: {
    // Адреса
    [ApiResource.Area]: IAreaForm
    [ApiResource.District]: IDistrictForm
    [ApiResource.Address]: IAddressForm
    [ApiResource.ChannelAddress]: IChannelAddressForm
    // Контакты
    [ApiResource.OrganizationRole]: IOrganizationRole
    [ApiResource.Organization]: IOrganizationForm
    [ApiResource.UserRole]: IUserRole
    [ApiResource.User]: IUserForm
    // Оборудование
    [ApiResource.Vrf]: IVrfForm
    [ApiResource.HardwareStatus]: IStatusStage
    [ApiResource.Incident]: IIncident
    [ApiResource.IncidentStage]: IStatusStage
    [ApiResource.IncidentStatus]: IIncidentStatus
    [ApiResource.IncidentStageLog]: IIncidentStageLogRecord
    // Коммутаторы
    [ApiResource.NetworkSwitchModel]: IHardwareModelForm
    [ApiResource.NetworkSwitchFirmware]: IHardwareFirmware
    [ApiResource.NetworkSwitchPreset]: IHardwarePresetForm
    [ApiResource.NetworkSwitch]: INetworkSwitchForm
    //Маршрутизаторы
    [ApiResource.RouterModel]: IHardwareModelForm
    [ApiResource.RouterFirmware]: IHardwareFirmware
    [ApiResource.RouterPreset]: IHardwarePresetForm
    [ApiResource.Router]: IRouterForm
    // IP-камеры
    [ApiResource.IpCameraModel]: IHardwareModelForm
    [ApiResource.IpCameraFirmware]: IHardwareFirmware
    [ApiResource.IpCameraPreset]: IHardwarePresetForm
    [ApiResource.IpCamera]: IIpCameraForm
    // Видеосерверы
    [ApiResource.VideoServerModel]: IHardwareModelForm
    [ApiResource.VideoServerFirmware]: IHardwareFirmware
    [ApiResource.VideoServerPreset]: IHardwarePresetForm
    [ApiResource.VideoServer]: IVideoServerForm
    // Точки доступа
    [ApiResource.AccessPointModel]: IHardwareModelForm
    [ApiResource.AccessPointFirmware]: IHardwareFirmware
    [ApiResource.AccessPoint]: IAccessPointForm
    // Источники бесперебойного питания
    [ApiResource.UpsModel]: IHardwareModelForm
    [ApiResource.UpsFirmware]: IHardwareFirmware
    [ApiResource.UpsPreset]: IHardwarePresetForm
    [ApiResource.Ups]: IUpsForm
    // ТВНы
    [ApiResource.Cctv]: ICctvForm
    [ApiResource.MonitoringCctv]: ICctvForm
    [ApiResource.CctvChannelStatus]: ICctv['channelStatus']
    [ApiResource.CctvInstallationStatus]: ICctv['installationStatus']
    [ApiResource.CctvAcceptanceStatus]: ICctv['acceptanceStatus']
    [ApiResource.CctvInstallationPassivePartStatus]: ICctv['installationPassivePartStatus']
    [ApiResource.CctvFileType]: ICctvFileTypeForm
    [ApiResource.CctvType]: ICctvTypeForm
    [ApiResource.ChannelTechnology]: IChannelTechnologyForm
    [ApiResource.RoomType]: IRoomTypeForm
    [ApiResource.RoomSquare]: IRoomSquareForm
    [ApiResource.OutletAvailability]: IOutletAvailabilityForm
    [ApiResource.StorageRoomType]: IStorageRoomTypeForm
    [ApiResource.StorageRoomSquare]: IStorageRoomSquareForm
    [ApiResource.TelecomOperatorRoomType]: ITelecomOperatorRoomTypeForm
    [ApiResource.TelecomOperatorTechnology]: ITelecomOperatorTechnologyForm
    // Проекты
    [ApiResource.Project]: IProject
    [ApiResource.ProjectType]: IProjectTypeForm
    [ApiResource.ProjectField]: IProjectFieldForm
    // Связь
    [ApiResource.Contract]: IContractForm
    [ApiResource.ServiceType]: IServiceTypeForm
    [ApiResource.ContractServiceTypePrice]: IContractServiceTypePriceForm
    [ApiResource.Department]: IDepartmentForm
    [ApiResource.Consumer]: IConsumerForm
    [ApiResource.ReportingPeriod]: IReportPeriodForm
    [ApiResource.CommunicationAddress]: ICommunicationAddressForm
    [ApiResource.Service]: IServiceForm
    [ApiResource.ConstructionObject]: IConstructionObjectForm
    [ApiResource.Channel]: IChannelForm
    [ApiResource.ChannelStages]: IChannelForm
    [ApiResource.ChannelField]: IChannelFieldForm
    [ApiResource.Joint]: IJointForm
    [ApiResource.QualityClass]: IQualityClassForm
    [ApiResource.ConstructionObjectStatus]: IConstructionObjectStatusForm
    [ApiResource.PortOwner]: IPortOwnerForm
    [ApiResource.Agreement]: IAgreementForm
    [ApiResource.OrderForm]: IOrderFormForm
    [ApiResource.OrderFormSigningStatus]: IStatusStage
    [ApiResource.Pec]: IPecForm
    [ApiResource.PecFileType]: IPecFileTypeForm
    [ApiResource.PecStatusFields]: IPecStatusFieldForm
  }[T]
}

/******************************
 *   ОКРУГА. РАЙОНЫ. АДРЕСА   *
 ******************************/

/** Округ */
export interface IArea {
  id: string
  code: string
  name: string
  storageName: string
}
/** Форма создания/редактирования округа в справочнике */
export interface IAreaForm {
  code: string
  name: string
}

/** Район */
export interface IDistrict {
  id: string
  code: string
  name: string
  storageName: string
  area: IArea
}
/** Форма создания/редактирования района в справочнике */
export interface IDistrictForm {
  code: string
  name: string
  areaId: string
}

/** Адрес */
export interface IAddress {
  id: string
  name: string
  storageId: number
  storageName: string
  gpsLatitude: string
  gpsLongitude: string
  district: IDistrict
}

export interface IChannelAddress extends IAddress {
  countChannels: {
    code: IChannel['stage']['code'] | 'total'
    count: number
  }[]
  totalCountChannels: number
  channels: IChannel[]
  belonging: string
  comment: string
}
/** Форма создания/редактирования адреса в справочнике */
export interface IAddressForm {
  name: string
  storageId: number
  gpsLatitude: string
  gpsLongitude: string
  districtId: string
}

export interface IChannelAddressForm {
  belonging: string
  comment: string
}



/****************************************
 * ОРГАНИЗАЦИИ. КОНТАКТЫ. ПОЛЬЗОВАТЕЛИ. *
 ****************************************/

/** Роль */
interface IUserRole extends ICodeRecord<UserRole> {}
export interface IOrganizationRole extends ICodeRecord<OrganizationRole> {}

/** Организация */
enum OrganizationCalcTypes {
  month = 'month',
  reportingPeriod = 'reporting-period',
}
export const organizationCalcTypeNames: Record<OrganizationCalcTypes, string> = {
  [OrganizationCalcTypes.month]: 'Месяцы',
  [OrganizationCalcTypes.reportingPeriod]: 'ОП',
}
export interface IOrganization {
  id: string
  name: string
  telephoneNumber?: string
  email?: string
  addressName?: string
  roles?: IOrganizationRole[]
  calcType: OrganizationCalcTypes
  hasVat: boolean
  manager: string
  supportEmail: string
  supportTelephoneNumber: string
  files: ICatalogFiles
  inn: string
}
/** Форма создания/редактирования организации в справочнике */
export interface IOrganizationForm {
  name: string
  telephoneNumber?: string | null
  email?: string | null
  addressName?: string | null
  roles: string[]
  calcType: string
  hasVat?: boolean
  manager: string
  supportEmail: string
  supportTelephoneNumber: string
  inn: string
}

export const userTableHiddenColsFieldsMap = {
  userCctvFields: 'cctvField',
  userChannelFields: 'channelField',
  userServiceFields: 'serviceField',
} as const

export type UserTableHiddenColsKey = keyof typeof userTableHiddenColsFieldsMap 

type UserHiddenTableColsFields = {
  [UserFieldKey in UserTableHiddenColsKey]: {
    [K in typeof userTableHiddenColsFieldsMap[UserFieldKey]]: string
  }[]
}

/** Пользователь */
export interface IUser extends UserHiddenTableColsFields {
  id: string
  login: string
  isActive: boolean
  userRoles: IUserRole[]
  isContactPerson: boolean
  lastName: string
  firstName: string
  patronymic: string | null
  name: string
  telephoneNumber: string | null
  email: string | null
  organization: Pick<IOrganization, 'id' | 'name'>
  description: string | null
}
/** Форма создания/редактирования пользователя в справочнике */
export interface IUserForm {
  login: string
  password?: string
  isActive: boolean
  userRoles: IUserRole[]
  lastName: string
  firstName: string
  patronymic: string
  name: string
  telephoneNumber: string
  email: string
  organization: string
  isContactPerson: boolean
  description: string
}



/**************************************
 *   МОДЕЛИ, ПРЕСЕТЫ И ОБОРУДОВАНИЕ   *
 **************************************/

/** Модель оборудования */
export interface IHardwareModel { id: string, name: string }
/** Форма создания/редактирования модели оборудования */
export interface IHardwareModelForm { name: string }

/** Пресет оборудования */
export interface IHardwarePreset {
  id: string
  name: string
  model: IHardwareModel
  login: string
  password: string
  files: Pick<IFile, 'id' | 'name'>[]
}
/** Форма создания/редактирования пресета оборудования */
export interface IHardwarePresetForm {
  name: string
  model: string
  login: string
  password: string
}

export enum HardwareStateCode {
  available = 'available',
  unavailable = 'unavailable',
  partially = 'partially',
  none = '',
}
export interface IHardwareState extends ICodeRecord {
  code: HardwareStateCode
}

/** VRF */
export interface IVrf { id: string, name: string }
/** Форма создания/редактирования VRF */
export interface IVrfForm { name: string }

/** Прошивка оборудования */
export interface IHardwareFirmware {
  name: string
  isRecommended: boolean | null
  id: string
}

/** Хост */
export interface INetworkHost {
  state: ICodeRecord
  updatedDatetime: Date
  ipAddress: string
}

/** Тип оборудования */
export interface IHardwareType {
  code: HardwareTypeCode
  name: string
  tag: string
}

/** Базовый интерфейс оборудования */
export interface IBaseHardware {
  id: number
  name: string | null
  code: string
  ipAddress: string | null
  subnetwork: string
  description: string | null
  serialNumber: string | null
  inventoryNumber: string | null
  model: IHardwareModel
  locationDescription: string | null
  address: IAddress
  firmware: IHardwareFirmware | null
  networkSwitchPort?: INetworkSwitchPort
  status: IStatusStage
  hasIpHostReachable: boolean | null
  type: IHardwareType
  networkHost: INetworkHost
  openIncidents?: IIncident[]
  isSitronicsHardware: boolean
  state: IHardwareState
  lastStateDatetime: Date | null
  logModelNamesEntries: {
    modelName: IBaseHardware['model']['name']
    createdDatetime: Date
    id: string
  }[]
  logSerialNumbersEntries: {
    serialNumber: NonNullable<IBaseHardware['serialNumber']>
    createdDatetime: Date
    id: string
  }[]
}
/** Форма создания/редактирования оборудования в справочнике */
interface IBaseHardwareForm {
  status: string // hardwareStatus.id
  address: string // address.id
  locationDescription?: string
  ipAddress: string
  subnetwork: string
  hasParentNetworkSwitch?: boolean
  networkSwitch?: number | null // networkSwitch.id
  router?: number | null // router.id
  networkSwitchPortNumber?: string | null // networkSwitchPort.id
  serialNumber?: string
  description?: string
  isSitronicsHardware?: boolean
}

/** Связь оборудования с коммутатором */
export interface INetworkSwitchPort {
  id: string
  networkSwitch: INetworkSwitch
  hardware: IBaseHardware
  portNumber?: string
  isPortOpen: boolean
}

/** Коммутатор */
interface IBaseNetworkSwitch extends IBaseHardware {
  vrfs: IVrf[]
  preset: IHardwarePreset
  ports: INetworkSwitchPort[]
}
export interface IVideoNetworkSwitch extends IBaseNetworkSwitch {
  isVideo: true
}
export interface ICommunicationNetworkSwitch extends IBaseNetworkSwitch {
  isVideo: false
}
export type INetworkSwitch = IVideoNetworkSwitch | ICommunicationNetworkSwitch 

/** Форма создания/редактирования коммутатора в справочнике  */
export interface INetworkSwitchForm extends IBaseHardwareForm {
  vrfs: string[]
  preset: string // hardwarePreset.id
}
/** Форма редактирования коммутатора на странице ТВН */
export interface INetworkSwitchInfoForm {
  status: string
}

/** Маршрутизатор */
export interface IRouter extends IBaseHardware {
  vrf?: IVrf
  preset: IHardwarePreset
}

/** Форма создания/редактирования маршрутизатора в справочнике  */
export interface IRouterForm extends IBaseHardwareForm {
  vrf: string[]
  preset: string // hardwarePreset.id
}

/** IP-Камера */
export interface IIPCamera extends IBaseHardware {
  vrf?: IVrf
  preset: IHardwarePreset
  referenceSnapshot: string
  snapshotPath: string
  referenceSnapshotPath: string
  previousReferenceSnapshotPath: string
  videoMarkerCode: string
  hasSoundEnabled: boolean | null
  snapShotKeeper: string // уточнить так ли будет называться Хранитель снимка
  referenceSnapshotSetFromIpCamera: string // путь по которому забираем эталонный снимок
  referenceSnapshotSetFromFile: string // путь сохранения локального файла
  referenceSnapshotDelete: string // путь удаления эталонного снимка
  orderNumberOnCctv: number // порядковый номер камеры
  codeOnCctv: string
  cctvId: string
  storageIdOnProject: string
  storageVsmKeyOnProject: string
  hasCameraLocationChanged: boolean
  hasCameraAngleChanged: boolean
}
/** Форма создания/редактирования IP-камеры в справочнике  */
export interface IIpCameraForm extends IBaseHardwareForm {
  vrf: string
  preset: string // hardwarePreset.id
}
/** Форма редактирования инфо IP-камеры на странице ТВН */
export interface IIpCameraInfoForm {
  orderNumberOnCctv: number
  hasSoundEnabled: boolean
  status: string
  hasCameraLocationChanged: boolean
  hasCameraAngleChanged: boolean
}
/** Форма редактирования видеомаркера IP-камеры на странице ТВН */
export interface IIpCameraVideoMarkerForm {
  videoMarkerCode: string
}

/** Видеосервер */
export interface IVideoServer extends IBaseHardware {
  vrf?: IVrf
  preset: IHardwarePreset
}
/** Форма редактирования видеосервера в справочнике */
export interface IVideoServerForm extends IBaseHardwareForm {
  vrf: string
  preset: string // hardwarePreset.id
}
/** Форма редактирования видеосервера на странице ТВН */
export interface IVideoServerInfoForm {
  status: string
}
/** Видеосервер */
export interface IUps extends IBaseHardware {
  vrf?: IVrf
  preset: IHardwarePreset
}
/** Форма редактирования ИБП в справочнике */
export interface IUpsForm extends IBaseHardwareForm {
  vrf: string
  preset: string // hardwarePreset.id
}

/** Точка доступа */
export interface IAccessPoint extends IBaseHardware {
  vrf?: IVrf
  macAddress: string
}
/** Форма редактирования точки доступа в справочнике */
export interface IAccessPointForm extends IBaseHardwareForm {
  macAddress: string
  vrf: string
  model: string // hardwareModal.id
}

/** Набор оборудования */
export interface IHardwareSet {
  videoNetworkSwitches: IVideoNetworkSwitch[]
  communicationNetworkSwitches: ICommunicationNetworkSwitch[]
  routers: IRouter[]
  ipCameras: IIPCamera[]
  videoServers: IVideoServer[]
  upses: IUps[]
  accessPoints: IAccessPoint[]
}

/** Обобщенное оборудование */
export type Hardware = INetworkSwitch | IIPCamera | IVideoServer | IUps | IAccessPoint

export interface IHardwareStateLog {
  status: IHardwareState
  datetime: Date
}

/*****************
 *   ИНЦИДЕНТЫ   *
 *****************/

/** Инцидент */
export interface IIncident {
  id: number
  stage: IStatusStage | null
  status: IIncidentStatus | null
  description: string
  origin: ICodeRecord | null
  originId: string | null
  hardware: IIncidentHardware
  parent: Pick<IIncident, 'id'> | null
  children: Pick<IIncident, 'id'>[]
  files: IFile[]
  openDatetime: Date
  closedDatetime: Date | null
  createdByUser: IUser | null
  createdDatetime: Date
  updatedByUser: IUser | null
  updatedDatetime: Date
}
/** Форма создания инцидента */
export interface IIncidentCreateForm {
  stage: string
  description: string
  hardware: string
}
/** Форма обновления инцидента */
export interface IIncidentUpdateForm {
  stage: string
  message: string
}
/** Форма закрытия инцидента */
export interface IIncidentCloseForm {
  status: string
  message: string
}

export interface IIncidentHardware extends Pick<IBaseHardware, 'id' | 'type' | 'code' | 'ipAddress' | 'address'> {
  cctvs: Pick<ICctv, 'id' | 'type' | 'number' | 'project'>[]
}

/** Файл */
export interface IFile {
  id: string
  name: string
  size: string
  createdByUser: IUser
  createdDatetime: Date
}

/** Запись журнала этапов инцидента */
export interface IIncidentStageLogRecord {
  id: string
  fromStage: IStatusStage | null
  toStage: IStatusStage | null
  message: string
  createdByUser: IUser | null
  createdDatetime: Date
  updatedByUser: IUser | null
  updatedDatetime: Date
}
/** Форма редактирования сообщений в журнале этапов инцидента */
export interface IIncidentStageLogRecordForm {
  message: string
}

/** Статус инцидента */
export interface IIncidentStatus { id: string, name: string }
/** Форма создания/редактирования статуса инцидента */
export interface IIncidentStatus { name: string }



/*****************************************************************
 *   ТИПЫ ПРОЕКТОВ. ПРОЕКТЫ. ДОПОЛНИТЕЛЬНЫЕ ПОЛЯ ТВН В ПРОЕКТЕ   *
 *****************************************************************/

/** Тип проекта */
export interface IProjectType { id: string, name: string }
/** Форма создания/редактирования типа проекта в справочнике */
export interface IProjectTypeForm { name: string }

/** Проект */
export interface IProject {
  id: string
  name: string
  serialCode: string
  serialOrderNumber: number
  contracts: IContract[]
  description: string
  isArchive: boolean
  type: IProjectType
  responsible: IUser
  isAudit: boolean
  isAuditDefault: boolean
  auditMainProject: IProject
}
/** Форма создания/редактирования проекта */
export interface IProjectForm {
  name: string
  serialCode: string
  serialOrderNumber: number
  contracts: string[]
  description: string
  isArchive: boolean
  type: string
  responsible: string
  isAudit: boolean
  isAuditDefault: boolean
  auditMainProject: string
}

/** Дополнительное поле ТВН в проекте */
export interface IProjectField {
  id: string
  name: string
  projectsMapEntries: {
    project: Pick<IProject, 'id' | 'name'>
    sort: number
    onlyForSitronics: boolean
  }[]
}

export interface IChannelField {
  sort: number
  id: string
  name: string
}

/** Форма создания/редактирования дополнительного поля ТВН в проекте */
export interface IProjectFieldForm {
  name: string
  projectId: string
  sort: number
}

export interface IChannelFieldForm {
  name: string
  channelId: string
  sort: number
}



/***********
 *   ТВН   *
 ***********/

/** Тип ТВН */
export interface ICctvType {
  id: string
  code: string
  name: string
  videoMarkerBaseCode: string
  project: Pick<IProject, 'id' | 'name'>
  storageIpCameraPort: number
  capacityPerCctv: number
}
/** Форма создания/редактирования типа ТВН в справочнике */
export interface ICctvTypeForm {
  code: string
  name: string
  videoMarkerBaseCode: string
  projectId: string
  storageIpCameraPort: number | null
  capacityPerCctv: number
}

export interface ICctvFileType { id: string, name: string }
export interface ICctvFileTypeForm { name: string }

export interface IPecFileType {
  id: string,
  name: string
  projects: IProject[]
  pecStatusFields: IPecStatusField[]
}
export interface IPecFileTypeForm {
  name: string
  projects: string[]
  pecStatusFields: string[]
}

export type ICctvAttachFile = IFile & {
  type: ICctvFileType
  isExternal: false
  path: string
}
export type ICctvExternalFile = Omit<IFile, 'size'> & {
  type: ICctvFileType
  size: null
  isExternal: true
  url: string
  path: string
}

export type IPecAttachFile = Omit<ICctvAttachFile, 'type'> & {
  type?: Pick<IPecFileType, 'id' | 'name'>
  pecStatusField: Pick<IPecFileType, 'id' | 'name'>
}


/** Технология канала */
export interface IChannelTechnology { id: string, name: string }
export interface IChannelTechnologyForm { name: string }

interface IRecordCustomField {
  id: string
  name: string
  value: string
}

interface IComment {
  id: string
  author: IUser,
  content: string
  createdDatetime: string
}
export interface ICctvComment extends IComment{}
export interface IPecComment extends IComment{}
/** Форма создания комментария */
export interface ICommentForm { content: string }

/** ТВН */
export interface ICctvAudit {
  auditAddress: boolean
  auditRoomType: IRoomType
  auditRoomTypeDescription: string
  auditRoomFloor: number
  auditRoomSquare: IRoomSquare
  auditOutletAvailability: IOutletAvailability
  auditEquipmentStorage: boolean
  auditStorageRoomType: IStorageRoomType
  auditStorageRoomTypeDescription: string
  auditStorageRoomSquare: IStorageRoomSquare
  auditEquipmentStorageLockable: boolean
  auditLocation: boolean
  auditMonitor: boolean
  auditUps: boolean
  auditLocalsConsoles: boolean
  auditNvr: boolean
  auditNetworkSwitch: boolean
  auditNetworkRouter: boolean
  auditIpCamera1: boolean
  auditIpCamera2: boolean
  auditCamerasMovingAbility: boolean
  auditSurveillanceEnsureVoteSecrecy: boolean
  auditAbsentObjectsBlockingView: boolean
  auditPortableBallotBoxVisible: boolean
  auditPecWorkplaceVisible: boolean
  auditEnlargedFormInView: boolean
  auditApEvenlyCoversEntireArea: boolean
  auditExistingTelecomOperators: string
  auditTelecomOperatorRoomType: ITelecomOperatorRoomType
  auditTelecomOperatorRoomTypeDescription: string
  auditCabinetLocationApproved: boolean
  auditPowerRouteAndConnectionPointAgreed: boolean
  auditSignalLevelMts: string
  auditTechnologyMts: ITelecomOperatorTechnology
  auditTechnologyMtsDescription: string
  auditSpeedRxMts: string
  auditSpeedTxMts: string
  auditSignalLevelBeeline: string
  auditTechnologyBeeline: ITelecomOperatorTechnology
  auditTechnologyBeelineDescription: string
  auditSpeedRxBeeline: string
  auditSpeedTxBeeline: string
  auditSignalLevelMegafon: string
  auditTechnologyMegafon: ITelecomOperatorTechnology
  auditTechnologyMegafonDescription: string
  auditSpeedRxMegafon: string
  auditSpeedTxMegafon: string
  auditSignalLevelTele2: string
  auditTechnologyTele2: ITelecomOperatorTechnology
  auditTechnologyTele2Description: string
  auditSpeedRxTele2: string
  auditSpeedTxTele2: string
  auditConductedBy: string
  auditDate: string //todo-sem should be Date
  auditDistrictForemanCredentials: string
  auditAdditionalComments: string
}
export interface ICctvAuditForm {
  auditAddress: boolean
  auditRoomType: IRoomType['code']
  auditRoomTypeDescription: string
  auditRoomFloor: number
  auditRoomSquare: IRoomSquare['code']
  auditOutletAvailability: IOutletAvailability['code']
  auditEquipmentStorage: boolean
  auditStorageRoomType: IStorageRoomType['code']
  auditStorageRoomTypeDescription: string
  auditStorageRoomSquare: IStorageRoomSquare['code']
  auditEquipmentStorageLockable: boolean
  auditLocation: boolean
  auditMonitor: boolean
  auditUps: boolean
  auditLocalsConsoles: boolean
  auditNvr: boolean
  auditNetworkSwitch: boolean
  auditNetworkRouter: boolean
  auditIpCamera1: boolean
  auditIpCamera2: boolean
  auditCamerasMovingAbility: boolean
  auditSurveillanceEnsureVoteSecrecy: boolean
  auditAbsentObjectsBlockingView: boolean
  auditPortableBallotBoxVisible: boolean
  auditPecWorkplaceVisible: boolean
  auditEnlargedFormInView: boolean
  auditApEvenlyCoversEntireArea: boolean
  auditExistingTelecomOperators: string
  auditTelecomOperatorRoomType: ITelecomOperatorRoomType['code']
  auditTelecomOperatorRoomTypeDescription: string
  auditCabinetLocationApproved: boolean
  auditPowerRouteAndConnectionPointAgreed: boolean
  auditSignalLevelMts: string
  auditTechnologyMts: ITelecomOperatorTechnology['code']
  auditTechnologyMtsDescription: string
  auditSpeedRxMts: string
  auditSpeedTxMts: string
  auditSignalLevelBeeline: string
  auditTechnologyBeeline: ITelecomOperatorTechnology['code']
  auditTechnologyBeelineDescription: string
  auditSpeedRxBeeline: string
  auditSpeedTxBeeline: string
  auditSignalLevelMegafon: string
  auditTechnologyMegafon: ITelecomOperatorTechnology['code']
  auditTechnologyMegafonDescription: string
  auditSpeedRxMegafon: string
  auditSpeedTxMegafon: string
  auditSignalLevelTele2: string
  auditTechnologyTele2: ITelecomOperatorTechnology['code']
  auditTechnologyTele2Description: string
  auditSpeedRxTele2: string
  auditSpeedTxTele2: string
  auditConductedBy: string
  auditDate: string
  auditDistrictForemanCredentials: string
  auditAdditionalComments: string
}

export interface ILogHistoryEntry {
  actor: IUser
  content: string
  createdDatetime: Date
  id: string
  isImportUpdate: boolean
}

export interface IHistoryStatusType {
  code: string
  name: string
  orderNumber: number
  createdBy: string
}

export interface IHistoryStatusTypeOutput extends IHistoryStatusType {
  createdDatetime: string
}

export interface IStatusHistoryEntry {
  acceptanceStatus: Pick<IHistoryStatusType, 'code' | 'name' | 'orderNumber'>
  idAcceptanceStatus: Pick<IHistoryStatusType, 'code' | 'name' | 'orderNumber'>
  actor: IUser
  channelStatus: Pick<IHistoryStatusType, 'code' | 'name' | 'orderNumber'>
  content: string
  createdDatetime: Date
  id: string
  installationStatus: Pick<IHistoryStatusType, 'code' | 'name' | 'orderNumber'>
  installationPassivePartStatus: Pick<IHistoryStatusType, 'code' | 'name' | 'orderNumber'>
  createdDatetimeStr: string
}

export interface IStatusHistoryWorkEntry {
  actor: IUser
  stage: Pick<IHistoryStatusType, 'code' | 'name' | 'orderNumber'>
  idAcceptanceStatus: Pick<IHistoryStatusType, 'code' | 'name' | 'orderNumber'>
  createdDatetime: Date
  createdDatetimeStr: string
  id: string
}

export type CctvHardware<H extends IBaseHardware = IBaseHardware> = Pick<H, 'id' | 'code' | 'ipAddress' | 'state'>


export interface ICctv extends ICctvAudit {
  itemType: 'cctv'
  id: string
  number: string
  contract: IContract
  type: ICctvType
  project: Pick<IProject, 'id' | 'name' | 'isArchive'>
  projectFields: IRecordCustomField[]
  address: IAddress
  contractIpCamerasNumber: number
  channelStatus: IStatusStage<CctvStatusCodes['channelStatus']>
  installationStatus: IStatusStage<CctvStatusCodes['installationStatus']>
  acceptanceStatus: IStatusStage<CctvStatusCodes['acceptanceStatus']>
  channelTechnology: Pick<IOrganization, 'id' | 'name'> | null
  channelOperator: Pick<IOrganization, 'id' | 'name'> | null
  channelOperatorDescription: string
  constructionContractor: Pick<IOrganization, 'id' | 'name'> | null
  constructionContractorDescription: string
  maintenanceContractor: Pick<IOrganization, 'id' | 'name'> | null
  maintenanceContractorDescription: string
  networkSwitches: CctvHardware<INetworkSwitch>[]
  networkSwitchesState: IHardwareState
  videoNetworkSwitches: CctvHardware<IVideoNetworkSwitch>[]
  videoNetworkSwitchesState: IHardwareState
  communicationNetworkSwitches: CctvHardware<ICommunicationNetworkSwitch>[]
  communicationNetworkSwitchesState: IHardwareState
  routers: CctvHardware<IRouter>[]
  routersState: IHardwareState
  ipCameras: CctvHardware<IIPCamera>[]
  ipCamerasState: IHardwareState & { count: number }
  videoServers: CctvHardware<IVideoServer>[]
  videoServersState: IHardwareState
  accessPoints: CctvHardware<IAccessPoint>[]
  accessPointsState: IHardwareState
  upses: CctvHardware<IUps>[]
  upsesState: IHardwareState
  incidents: {
    incident: Pick<IIncident, 'id'>
  }[]
  incidentsState: ICodeRecord
  comments: ICctvComment[]
  files: (ICctvAttachFile | ICctvExternalFile)[]
  filesState: ICodeRecord
  referencesSnapshotsState: ICodeRecord
  logHistoryEntries: ILogHistoryEntry[]
  logStatusHistoryEntries: IStatusHistoryEntry[]
  idAcceptanceStatus: IStatusStage<CctvStatusCodes['idAcceptanceStatus']>
  installationPassivePartStatus: IStatusStage<CctvStatusCodes['installationPassivePartStatus']>
}

export interface ICctvForm {
  typeId?: string
  contract: string
  addressId?: string
  number: string
  contractIpCamerasNumber: number
  channelTechnologyId: string
  channelOperatorId?: string
  channelOperatorDescription?: string
  constructionContractorId?: string
  maintenanceContractorDescription?: string
  maintenanceContractorId?: string
  constructionContractorDescription?: string
  projectsFields: Record<string, string>
}

interface IRoomType extends ICodeOrderNumberRecord {}
interface IRoomTypeForm extends ICodeOrderNumberRecord {}

interface IRoomSquare extends ICodeOrderNumberRecord {}
interface IRoomSquareForm extends ICodeOrderNumberRecord {}

interface IOutletAvailability extends ICodeOrderNumberRecord {}
interface IOutletAvailabilityForm extends ICodeOrderNumberRecord {}

interface IStorageRoomType extends ICodeOrderNumberRecord {}
interface IStorageRoomTypeForm extends ICodeOrderNumberRecord {}

interface IStorageRoomSquare extends ICodeOrderNumberRecord {}
interface IStorageRoomSquareForm extends ICodeOrderNumberRecord {}

interface ITelecomOperatorRoomType extends ICodeOrderNumberRecord {}
interface ITelecomOperatorRoomTypeForm extends ICodeOrderNumberRecord {}

interface ITelecomOperatorTechnology extends ICodeOrderNumberRecord {}
interface ITelecomOperatorTechnologyForm extends ICodeOrderNumberRecord {}

/** Состояние оборудования для мониторинга */
export interface IHardwareMonitoringState extends Pick<IBaseHardware, 'id' | 'state' | 'ipAddress' | 'lastStateDatetime' | 'type'> {}

/** ТВН для мониторинга */
export interface IMonitoringCctv extends Pick<ICctv, 'id' | 'type' | 'number' | 'address' | 'channelOperator' | 'maintenanceContractor' | 'maintenanceContractorDescription'> {
  project: Pick<IProject, 'id' | 'name'>
  incidents: {
    incident: Pick<IIncident, 'id' | 'stage' | 'origin' | 'hardware' | 'children' | 'createdByUser' | 'updatedDatetime'>
  }[]
  videoNetworkSwitches: IHardwareMonitoringState[]
  communicationNetworkSwitches: IHardwareMonitoringState[]
  ipCameras: IHardwareMonitoringState[]
  videoServers: IHardwareMonitoringState[]
  upses: IHardwareMonitoringState[]
  accessPoints: IHardwareMonitoringState[]
}


/***********************
 *   ДАШБОРД ПРОЕКТА   *
 ***********************/

export interface IProjectDashboard {
  project: {
    cctvsCount: number
    incidentsOpenCount: number
    referencesSnapshotsCount: number
    filesCount: number
    //cctvsNotificationsCount: number
  },
  hardware: {
    networkSwitchesCount: number
    ipCamerasCount: number
    ipCamerasWithStorageIdCount: number
    ipCamerasWithoutStorageIdCount: number
    videoServersCount: number
  },
  audit: {
    completedCount: number
    partiallyCount: number
    notCompletedCount: number
  },
  channelsOperators: {
    id: string | null
    name: string
    cctvsCount: number
  }[],
  districts: {
    id: string
    name: string
    cctvsCount: number
  }[],
  constructionContractors: {
    id: string | null
    name: string
    cctvsCount: number
  }[],
  maintenanceContractors:
    {
      id: string | null
      name: string
      cctvsCount: number
    }[],
  channelsStatuses: {
    name: string
    code: string
    cctvsCount: number
  }[],
  installationStatuses: {
    name: string
    code: string
    cctvsCount: number
  }[],
  acceptanceStatuses: {
    name: string
    code: string
    cctvsCount: number
  }[],
  idAcceptanceStatuses: (ICodeRecord & { cctvsCount: number })[]
  installationPassivePartStatuses: (ICodeRecord & { cctvsCount: number })[]
}


export type ICatalogFiles = Pick<IFile, 'id' | 'name'>[]
export type ICatalogWithFiles = IHardwarePreset | IOrganization | IContract | IAgreement


/***************************************************************
 *   СВЯЗЬ: ДОГОВОР, ТИПЫ УСЛУГ, ПРАЙС-ЛИСТ, ОТЧЕТНЫй ПЕРИОД   *
 ***************************************************************/

/** Контракт */
export enum ContractIdentifierTypes {
  video = 'video',
  communication = 'communication',
}
export const contractIdentifierNames: Record<ContractIdentifierTypes, string> = {
  [ContractIdentifierTypes.video]: 'Видео',
  [ContractIdentifierTypes.communication]: 'Связь',
}
export interface IContract {
  id: string
  number: string
  name: string
  shortName: string
  period: string
  beginDate: Date
  endDate: Date
  reportingPeriods: IReportPeriod[]
  files: ICatalogFiles
  identifier: ContractIdentifierTypes
}
/** Форма создания/редактирования контракта в справочнике */
export interface IContractForm {
  number: string
  name: string
  shortName: string
  period: string
  beginDate: Date
  endDate: Date
  identifier: ContractIdentifierTypes
}

/** Тип услуги */
export enum ServiceTypeKindNames {
  basic = 'Базовая',
  port = 'Порт',
}
export interface IServiceType {
  id: string
  name: string
  typeKindName: ServiceTypeKindNames
}
/** Форма создания/редактирования типа услуги в справочнике */
export interface IServiceTypeForm {
  name: string
  typeKindName: ServiceTypeKindNames
}

/** Тип услуги в рамках контракта (прайс-лист) */
export interface IContractServiceTypePrice {
  id: string
  /** Номер прайс-листа*/
  number: number,
  /** Контракт */
  contract: IContract
  /** Тип услуги */
  serviceType: IServiceType
  /** Наименование услуги */
  serviceName: string
  /** Пропускная способность, Мбит/с */
  channelCapacity: number
  /** Цена услуги в контракте */
  price: number
}
/** Форма создания/редактирования прайс-листа в справочнике */
export interface IContractServiceTypePriceForm {
  contract: string // contract.id
  serviceType: string // serviceType.id
  serviceName: string
  channelCapacity: number
  price: number
}

/** Отчетный период */
export interface IReportPeriod {
  id: string
  name: string
  number: number
  contracts: IContract[]
  beginDate: Date
  endDate: Date
}
/** Форма создания/редактирования отчетного период в справочнике */
export interface IReportPeriodForm {
  name: string
  number: number
  contracts: string[]
  beginDate: string // Date ISO-string
  endDate: string // Date ISO-string
}

/** Отчетный период в рамках контракта */
export interface IContractReportPeriod {
  id: string
  /** Контракт */
  contract: IContract
  /** Отчетный период */
  reportPeriod: IReportPeriod
  /** Порядковый номер в рамках контракта */
  number: number
}



/**************************
 *   СВЯЗЬ: ПОТРЕБИТЕЛЬ   *
 **************************/

/** Департамент потребителя услуги */
export interface IDepartment {
  id: string,
  name: string,
  number?: number,
}
/** Форма создания/редактирования департамента потребителя услуги в справочнике */
export interface IDepartmentForm {
  name: string,
  number?: number,
}

/** Потребитель услуги */
export interface IConsumer {
  id: string
  name: string
  /** Департамент */
  departments: IDepartment[]
}
/** Форма создания/редактирования потребителя услуги в справочнике */
export interface IConsumerForm {
  name: string
  /** department.id */
  departments: string[]
}



/*******************************************
 *   СВЯЗЬ: АДРЕС, СТЫК, КЛАСС, ОПЕРАТОР   *
 *******************************************/

/** Адрес подключения канала связи */
export interface ICommunicationAddress {
  id: string
  /** Адрес */
  address: IAddress
  /** Синоним адреса */
  name: string
}
/** Форма создания/редактирования адреса подключения канала связи в справочнике */
export interface ICommunicationAddressForm {
  address: string // address.id
  name: string
}

/** Стык */
export interface IJoint { id: string, name: string}
/** Принадлежность порта */
export interface IPortOwner { id: string, name: string}
/** Форма создания/редактирования стыка в справочнике */
export interface IJointForm { name: string}

/** Класс качества */
export interface IQualityClass { id: string, name: string}
/** Форма создания/редактирования класса качества в справочнике */
export interface IQualityClassForm { name: string}

/** Класс качества */
export enum ConstructionObjectStatusTypes {
  inspected = 'inspected',
  constructionLines = 'construction-lines',
  constructionHardware = 'construction-hardware',
  commissioning = 'commissioning',
}
export const constructionObjectStatusTypesNames: Record<ConstructionObjectStatusTypes, string> = {
  [ConstructionObjectStatusTypes.inspected]: 'Обследовано',
  [ConstructionObjectStatusTypes.constructionLines]: 'СМР линий',
  [ConstructionObjectStatusTypes.constructionHardware]: 'СМР оборудования',
  [ConstructionObjectStatusTypes.commissioning]: 'ПНР',
}
export interface IConstructionObjectStatus {
  id: string,
  type: ConstructionObjectStatusTypes
  name: string
}
/** Форма создания/редактирования класса качества в справочнике */
export interface IConstructionObjectStatusForm {
  type: ConstructionObjectStatusTypes
  name: string
}

export interface IPortOwnerForm {
  name: string
}

export interface IAgreement {
  id: string
  number: string
  date: Date
  organization: IOrganization
  files: ICatalogFiles
}

export interface IAgreementForm {
  number: string
  date: string
  organization: string
}

export interface IOrderForm {
  id: string
  number: string
  organization: IOrganization
  agreements: IAgreement[]
  purchaseNumberLinks: string[]
  signingDate: Date | null
  signingStatus: IStatusStage | null
  files: ICatalogFiles
}

export interface IOrderFormForm {
  number: string
  organization: IOrganization['id']
  agreements: IAgreement['id'][]
  purchaseNumberLinks: string
  signingDate: Date
  signingStatus: IStatusStage['code']
}

/*************
 *   КАНАЛ   *
 *************/

/** Канал */
interface IBaseChannel {
  id: string
  number: number
  channelIdentifier: string
  stage: IStatusStage<ChannelStatusCodes['stage']>
  responsible: IUser | null
  lastYearTotalPayment: number
  channelCapacity: number | null
  subscriptionPayment: number | null
  installationPayment: number | null
  operator?: Pick<IOrganization, 'id' | 'name' | 'hasVat'>
  networks: string | null
  vlan: string | null
  processBeginDate: Date | null
  processEndDate: Date | null
  comment: string | null
  operatorContactInfo: string
  purchaseLink: string | null
  agreement: Pick<IAgreement, 'id' | 'number'> | null
  purchaseNumber: string | null
  operatorChannelId: string | null
  projectCode: string | null
  logStatusHistoryEntries: IStatusHistoryWorkEntry[]
  logHistoryEntries: ILogHistoryEntry[]
  reserve: boolean
  channelFields: IRecordCustomField[]
}

export interface IChannelWithCctv extends IBaseChannel {
  isCctvChannel: true
  cctv: {
    id: ICctv['id']
    address: Pick<IAddress, 'id' | 'name' | 'storageId'>
    project: Pick<IProject, 'id'>
    contract?: IContract
  }
}
export interface IChannelWithService extends IBaseChannel {
  isCctvChannel: false
  service: IService
}

export type IChannel = IChannelWithCctv | IChannelWithService

export type IChannelWithPayments = IChannel & {
  channelPayments: {
    channelPaymentPeriod: {
      id: string
      month: number
      year: number
      date: Date
    }
    channelPaymentStatus: ICodeOrderNumberRecord
    channelPaymentType: ICodeRecord<'subscription' | 'installation'>
    comment: string
    id: string
    number: number
    sum: number
  }[]
}

/** Форма создания/редактирования канала */
export interface IChannelForm {
  responsible: IUser['id']
  channelCapacity: number
  subscriptionPayment: number
  installationPayment: number
  lastYearTotalPayment: number
  operator: IOrganization['id']
  networks: string
  vlan: string
  processBeginDate: Date
  processEndDate: Date
  operatorContactInfo: string
  purchaseLink: string
  agreement: IAgreement['id']
  purchaseNumber: string
  operatorChannelId: string
  comment: string
  projectCode: string
}

/**************
 *   УСЛУГА   *
 **************/

/** Услуга */
export interface IService {
  /** GUID */
  id: string
  parentId: string | null
  /** Статус услуги */
  stage: IStatusStage<ServiceStatusCodes['stage']>
  /** № в рамках контракта */
  number: string
  /** Заказ от ДИТ */
  hasOrderFromDit: boolean | null
  /** Контракт и тип услуги */
  contractServiceTypePrice?: IContractServiceTypePrice
  aConsumer: IConsumer
  bConsumer: IConsumer
  department: IDepartment
  channels: IChannel[]
  /** Номер заказа на подключение услуги  */
  orderBeginNumber: string
  /** Дата заказа подключения услуги */
  orderBeginDate: Date | null
  /** Номер заказа на отключение услуги */
  orderEndNumber: string | null
  /** Дата заказа услуги */
  orderEndDate: Date | null
  /** Адрес объекта А  */
  aAddress: ICommunicationAddress | null
  /** Адрес объекта Б */
  bAddress: ICommunicationAddress | null
  /** Стык А */
  aJoint: IJoint | null
  /** Стык Б */
  bJoint: IJoint | null
  /** Область А */
  isAreaA: boolean | null
  /** Область Б */
  isAreaB: boolean | null
  /** Срочная услуга */
  isUrgent: boolean | null
  /** Криптозащита */
  hasCrypto: boolean | null
  /** Глубокий анализ трафика */
  hasDpi: boolean | null
  /** Контент-фильтрация */
  hasContentFilter: boolean | null
  /** Резервирование */
  hasReservation: boolean | null
  /** Класс качества */
  qualityClass: IQualityClass | null
  /** Причина */
  cause: string | null
  /** Принадлежность порта */
  portOwner: IPortOwner | null
  /** Объект строительства */
  constructionObjects: string | null
  /** ФИО ответственного */
  responsibleName: string | null
  /** Должность ответственного */
  responsiblePositionName: string | null
  /** Телефон ответственного */
  responsiblePhoneNumber: string | null
  /** Email ответственного */
  responsibleEmail: string | null
  /** Примечание */
  description: string | null
  /** Комментарий */
  comment: string | null
  serviceBeginDate: Date | null
  serviceEndDate: Date | null
  contract: IContract
  constructionObject: IConstructionObject | null
  resource1: IConsumer | null
  resource2: IConsumer | null
  resource3: IConsumer | null
  vpn1: IConsumer | null
  vpn2: IConsumer | null
  vpn3: IConsumer | null
  isSitronicsPort: boolean
  idAcceptanceStatus: IStatusStage<ServiceStatusCodes['idAcceptanceStatus']>
  logHistoryEntries: ILogHistoryEntry[]
  logStatusHistoryEntries: IStatusHistoryWorkEntry[]
  basicIdentifier: string
}
/**Создание услуги*/
export interface IServiceForm {
  /** GUID */
  id: string
  parentId: string | null
  /** Статус ДИТ */
  hasOrderFromDit: boolean
  /** № в рамках контракта */
  number: string
  /** Контракт и тип услуги */
  contractServiceTypePrice: IContractServiceTypePrice['id']
  /** 'Скорость потока данных, Мбит/с' */
  channelCapacity?: number
  aConsumer: IConsumer['id']
  bConsumer: IConsumer['id']
  //** Департамент */
  department: IDepartment['id']
  /** Каналы */
  channels: IChannel[]
  /** Номер заказа на подключение услуги  */
  orderBeginNumber: string
  /** Дата заказа подключения услуги */
  orderBeginDate: Date | null
  /** Номер заказа на отключение услуги */
  orderEndNumber: string | null
  /** Дата заказа услуги */
  orderEndDate: Date | null
  /** Адрес объекта А  */
  aAddress: ICommunicationAddress['id']
  /** Адрес объекта Б */
  bAddress: ICommunicationAddress['id']
  /** Стык А */
  aJoint: IJoint | null
  /** Стык Б */
  bJoint: IJoint | null
  /** Область А */
  isAreaA: boolean | null
  /** Область Б */
  isAreaB: boolean | null
  /** Срочная услуга */
  isUrgent: boolean | null
  /** Криптозащита */
  hasCrypto: boolean | null
  /** Глубокий анализ трафика */
  hasDpi: boolean | null
  /** Контент-фильтрация */
  hasContentFilter: boolean | null
  /** Резервирование */
  hasReservation: boolean | null
  /** Класс качества */
  qualityClass: IQualityClass | null
  /** Причина */
  cause: string | null
  /** Принадлежность порта */
  portOwner: string | null
  /** Объект строительства */
  constructionObjects: string | null,
  /** ФИО ответственного */
  responsibleName: string | null
  /** Должность ответственного */
  responsiblePositionName: string | null
  /** Телефон ответственного */
  responsiblePhoneNumber: string | null
  /** Email ответственного */
  responsibleEmail: string | null
  /** Примечание */
  description: string | null
  /** Комментарий */
  comment: string | null
  stage: string | null
  serviceBeginDate: Date | null
  serviceEndDate: Date | null
  contract: IContract['id']
  constructionObject: IConstructionObject['id']
  resource1: IConsumer['id']
  resource2: IConsumer['id']
  resource3: IConsumer['id']
  vpn1: IConsumer['id']
  vpn2: IConsumer['id']
  vpn3: IConsumer['id']
  basicIdentifier: string
}

export type IServiceWithBilling = IService & {
  billing: {
    reportingPeriods: (Pick<IReportPeriod, 'id' | 'name' | 'beginDate' | 'endDate'> & {
      days: number
      serviceDays: number
      revenue: number
      expenses: number
      constructionObjectExpenses: number
      channelExpenses: Record<IChannel['id'], number>,
      lastForYear: YearNumber | null //not null if next period have diff year
    })[]
    years: Record<number, {
      revenue: number,
      channelExpenses: Record<IChannel['id'], number>,
    }>
    channels: Record<IChannel['id'], {
      connectedDate: Date
      lastReportingPeriodDate: Date
      monthlyExpenses: Record<IBillingTotals['months'][0]['code'], number>
    }>
    constructionObject: {
      monthCode: IBillingTotals['months'][0]['code']
      expenses: number
    }
    total: {
      revenue: number
      expenses: number
      income: number
      payback: number
    }
    firstPeriod: {
      name: string
      days: number
      serviceDays: number
    }
  }
}


/**************
 *   ОБЪЕКТ СТРОИТЕЛЬСТВА   *
 **************/

/** Объект строительства */
export interface IConstructionObject {
  id: string
  name: string
  consumer: IConsumer
  department: IDepartment
  address: IAddress
  statedRequirementUniversalPorts: number
  servicesCount: number
  statedRequirementUniversalRadioPorts: number
  statedRequirementUniversalPhonePorts: number
  actualRequirementUniversalPorts: number
  actualRequirementUniversalRadioPorts: number
  actualRequirementUniversalPhonePorts: number
  orderFromHpsm: string
  contactFromObject: string
  comment: string
  note: string
  inspectedStatus: IConstructionObjectStatus
  constructionLinesStatus: IConstructionObjectStatus
  constructionHardwareStatus: IConstructionObjectStatus
  commissioningStatus: IConstructionObjectStatus
  organization: IOrganization
  orderWithContractor: string
  sum: number
}

export interface IConstructionObjectForm {
  name: string
  consumer: IConsumer['id']
  department: IDepartment['id']
  address: IAddress['id']
  statedRequirementUniversalPorts: number
  statedRequirementUniversalRadioPorts: number
  statedRequirementUniversalPhonePorts: number
  actualRequirementUniversalPorts: number
  actualRequirementUniversalRadioPorts: number
  actualRequirementUniversalPhonePorts: number
  orderFromHpsm: string
  contactFromObject: string
  comment: string
  note: string
  inspectedStatus: IConstructionObjectStatus['id']
  constructionLinesStatus: IConstructionObjectStatus['id']
  constructionHardwareStatus: IConstructionObjectStatus['id']
  commissioningStatus: IConstructionObjectStatus['id']
  organization: IOrganization['id']
  orderWithContractor: string
  sum: number
}


/**************
 *   БИЛЛИНГ   *
 **************/

type Billing = {
  revenue: number
  expenses: number
}

export type IBillingTotals = {
  months: {
    code: `${YearNumber}-${MonthNumber}`
    expenses: number
  }[],
  reportingPeriods: Record<IReportPeriod['id'], Billing>,
  years: Record<YearNumber, Billing>,
  total: Billing & {
    income: number
    payback: number
  }
}


/*********************
 *   ДАШБОРД СВЯЗИ   *
 *********************/

interface IDashboardContract {
  contractNumber: string
  serviceCount: number
}

interface IReportDashboardPeriod {
  id: string
  begin_date: string
  end_date: string
}

interface IDashboardOrderFromDit {
  hasOrderFromDit: boolean
  serviceCount: number
}

export interface ICommunicationDashboard {
  services: {
    disabled: number,
    enabled: number,
    total: number
  },
  hardware: {
    accessPoint: number,
    networkSwitch: number,
    router: number
  },
  serviceStatuses: (ICodeRecord & { serviceCount: number })[]
  communicationChannelsStatuses: (ICodeRecord & { channelsCount: number })[]
  reportPeriods: IReportDashboardPeriod[]
  serviceIdAcceptanceStatuses: (ICodeRecord & { serviceCount: number })[]
  contracts: IDashboardContract[]
  orderFromDit: IDashboardOrderFromDit[]
  serviceTypes: {
    name: IServiceType['name']
    serviceCount: number
  }[]
  serviceTypeKindNames: {
    name: IServiceType['typeKindName']
    serviceCount: number
  }[]
}

export interface IExploitationDashboard extends IProjectDashboard {
  incidentsByOpenDateTime: Record<HoursType, number>
}

export interface IActFormationDeviations {
  serviceNumber: string
  months: number[]
}

export type ExploitationIncidents = {
  address_name: string
  area_name: string
  cctv_number: string
  district_name: string
  incidents_str: string
  incidents: ICctv['incidents']
  project_name: string
}[]

export type IPecStatusStage = IStatusStage<PecStatusCodes['any']>

export interface IPec {
  itemType: 'pec'
  id: string
  number: string
  project: Pick<IProject, 'id' | 'name' | 'isArchive'>
  contractors: (Pick<IOrganization, 'id' | 'name'> & {
    statusFieldId: IPecStatusField['id']
  })[]
  cctvNumber: string
  address: IAddress
  responsibleFromCustomers: (Pick<IUser, 'id' | 'name' | 'telephoneNumber'> & {
    statusFieldId: IPecStatusField['id']
  })[]
  brigadiers: (Pick<IUser, 'id' | 'name' | 'telephoneNumber'> & {
    statusFieldId: IPecStatusField['id']
  })[]
  executors: (Pick<IUser, 'id' | 'name' | 'telephoneNumber'> & {
    statusFieldId: IPecStatusField['id']
  })[]
  files: IPecAttachFile[]
  statusFields: (Pick<IPecStatusField, 'id' | 'name'> & {
    value: IPecStatusStage['code']
    orderNumber: IPecStatusStage['orderNumber']
    valueName: IPecStatusStage['name']
  })[]
  statusFieldLogHistoryEntries: {
    id: string
    actor?: IUser
    createdDatetime: Date
    oldValue: IPecStatusStage | null
    newValue: IPecStatusStage
    statusField: IPecStatusField
  }[]
  completion: boolean
  networkSwitches: CctvHardware<INetworkSwitch>[]
  videoNetworkSwitches: CctvHardware<IVideoNetworkSwitch>[]
  communicationNetworkSwitches: CctvHardware<ICommunicationNetworkSwitch>[]
  routers: CctvHardware<IRouter>[]
  ipCameras: CctvHardware<IIPCamera>[]
  videoServers: CctvHardware<IVideoServer>[]
  accessPoints: CctvHardware<IAccessPoint>[]
  upses: CctvHardware<IUps>[]
  comments: IPecComment[]
  workBeginDates: {
    date: Date
    statusFieldId: IPecStatusField['id']
  }[]
}

export interface IPecForm {
  number: string
  cctvNumber: string
  address: IAddress['id']
  completion: boolean
}

export interface IPecStatusField {
  id: string
  name: string
  projects: IProject[]
}

export interface IPecStatusFieldForm {
  name: string
  projects: string[]
}

export type IPecsCommonStat = {
  contractor: Pick<IPec['contractors'][0], 'id' | 'name'> | null
  addresses_count: number
  pec_status_fields: Partial<
    Record<IPecStatusField['id'], (Pick<IPecStatusField, 'id' | 'name'> & {
      total: number
      not_completed: number
      completed: number
    })>
  >
  completions_count: number
}[]

export type IPecsStatusFieldStat = {
  contractor: Pick<IPec['contractors'][0], 'id' | 'name'> | null
  addresses_count: number
  pec_status_counts: Partial<
    Record<IPecStatusStage['code'], (IPecStatusStage & {
      count: number
    })>
  >
}[]

export type IPecsSliceFinishedStat = {
  date: string | null
  contractors: (Pick<IPec['contractors'][0], 'id' | 'name'> & {
    count: number
  })[]
  total_count: number
}[]


export type IPecsSliceStatusStat = {
  date: string
  contractor: Pick<IPec['contractors'][0], 'id' | 'name'>
  addresses_count: number
  pec_status_counts: Partial<
    Record<IPecStatusStage['code'], (IPecStatusStage & {
      count: number
    })>
  >
}[]

import { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UserRole } from '@/config/roles'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useProjectListContext } from '@/context/tables/ProjectListContext'
import Alert from '@/components/common/Alert'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import Loader from '@/components/common/Loader'
import Main from '@/components/common/Main'
import Pagination from '@/components/common/Pagination'
import RoleRequired from '@/components/auth/RoleRequired'
import SearchInputGroup from '@/components/common/SearchInputGroup'
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { routes } from '@/config/routes'
import { arrayUnique } from '@/utils/array'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = {
  isAudit?: boolean
}

export default function ProjectListPageContent({ isAudit }: Props) {
	const { hasRole, hasPermission } = useAuthContext()
  useDocumentTitle('Все проекты')
  const { isLoading, recordList: projects, lastPage, error } = useProjectListContext()
  const [showArchive, setShowArchive] = useState(false)
  const navigate = useNavigate()

  const types: {
    name:string,
    isArchive: boolean
  }[] = arrayUnique(projects.map(project => project.type.name)).map(name => ({ name, isArchive: false }))

  types.push({ name: 'Архив', isArchive: true })

  if (isAudit && projects.length && !hasPermission(['auditProjectEdit'])) {
    const defaultProject = projects.find(p => p.isAuditDefault)
    if (defaultProject)
      navigate(routes.auditProject(defaultProject.id))
  }

  return (
    <>
      {!isAudit &&
        <BreadcrumbsContainer>
          <BreadcrumbsItem>Проекты</BreadcrumbsItem>
        </BreadcrumbsContainer>
      }
      <Main>
        <h1>Проекты</h1>
        <div className='btn-toolbar gap-3 my-3'>
          <RoleRequired roleCodes={UserRole.admin}>
            <Link to={isAudit ? routes.auditProjectCreate : routes.projectCreate} className='btn btn-outline-primary'>
              <FontAwesomeIcon icon={faPlus} className='me-2' />
              Создать проект
            </Link>
          </RoleRequired>
          <SearchInputGroup />
        </div>
        <div className='d-flex gap-5'>
          <label>
            <input className='form-check-input' type='checkbox' defaultChecked disabled />
            <span className='ms-1'>Активный</span>
          </label>
          <label>
            <input
              className='form-check-input'
              type='checkbox'
              checked={showArchive}
              onChange={() =>
                setShowArchive(!showArchive)
              }
            />
            <span className='ms-1'>Архивный</span>
          </label>
        </div>
        <div className='mt-3'>
          <div className='list-group list-group-flush mb-5'>
            {error
              ? <Alert>{error}</Alert>
              : isLoading
                ? <Loader />
                : !projects.length
                  ? <Alert colorName='primary'>По поисковому запросу ничего не найдено</Alert>
                  : types
                    .filter(t => showArchive ? true : !t.isArchive)
                    .map(({ name, isArchive }) =>
                      <Fragment key={name}>
                        <h3 className='mt-3'>{name}</h3>
                        {projects
                          .filter(project => isArchive
                            ? project.isArchive
                            : !project.isArchive && project.type.name === name
                          )
                          .map(project =>
                            <div
                              key={project.id}
                              className='list-group-item list-group-item-action d-flex align-items-center'
                            >
                              <Link
                                className='text-decoration-none w-100'
                                to={isAudit ? routes.auditProject(project.id) : routes.project(project.id)}
                              >
                                <h5 className='text-dark'>{project.name}</h5>
                                <small className='text-muted'>{project.description}</small>
                              </Link>
                              {isAudit && hasRole(UserRole.admin) &&
                                <Link to={routes.auditProjectEdit(project.id)}>
                                  <FontAwesomeIcon icon={faPenToSquare} className="ms-3 fs-5" />
                                </Link>
                              }
                            </div>,
                          )}
                      </Fragment>,
                    )
            }
          </div>
        </div>
        <Pagination lastPage={lastPage} disabled={isLoading} />
      </Main>
    </>
  )
}

import { memo, useEffect, useMemo, useState } from 'react'
import ExpandableControl from '@/components/ProjectsContent/monitoring/map/controls/ExpandableControl'
import Btn from '@/components/ProjectsContent/monitoring/map/controls/common/Btn'
import { strIncludesAnyCase } from '@/utils/string'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'
import { generateCctvHardwareList } from '@/utils/hardware'
import { HardwareTypeCode } from '@/config/hardware'


type FoundCctvs = {
  id: MonitoringMapCctv['id']
  number: MonitoringMapCctv['number']
  matches: string[]
}[]

type Props = YControlOptions & {
  filteredCctvs: MonitoringMapCctv[]
  onDisplayCctvsChange: (displayCctvIds: MonitoringMapCctv['id'][]) => any
}

const SearchControl = memo(({ float, floatIndex, filteredCctvs, onDisplayCctvsChange }: Props) => {
  const [searchStr, setSearchStr] = useState('')
  const [displayCctvs, setDisplayCctvs] = useState([] as MonitoringMapCctv[])

  const [exactSearch, setExactSearch] = useState(false)

  useEffect(() => {
    setDisplayCctvs(filteredCctvs)
  }, [filteredCctvs])

  const foundCctvs = useMemo(() => {
    if (!searchStr)
      return []

    return (exactSearch ? displayCctvs : filteredCctvs).reduce((acc, cctv) => {
      const searchStrings = [cctv.number, cctv.address.name]
      for (const hardware of generateCctvHardwareList(cctv)) {
        if (hardware.ipAddress)
          searchStrings.push(hardware.ipAddress)
        if (hardware.type === HardwareTypeCode.IpCamera && hardware.videoMarkerCode)
          searchStrings.push(hardware.videoMarkerCode)
      }

      const matches = searchStrings.filter((str: string) =>
        exactSearch ? str === searchStr : strIncludesAnyCase(str, searchStr),
      )

      if (matches.length)
        acc.push({
          id: cctv.id,
          number: cctv.number,
          matches,
        })

      return acc
    }, [] as FoundCctvs)
  }, [searchStr, displayCctvs, filteredCctvs, exactSearch])

  useEffect(() => {
    onDisplayCctvsChange(displayCctvs.map(c => c.id))
  }, [onDisplayCctvsChange, displayCctvs])

  return (
    <ExpandableControl {...{ float, floatIndex }} label='Поиск' title='Применить поиск'>
      <div className='d-flex gap-1 py-2'>
        <Btn
          text='Поиск среди объектов на карте'
          disabled={exactSearch}
          onClick={() => {
            setSearchStr('')
            setExactSearch(true)
          }}
        />
        <Btn
          text='Показать на карте'
          disabled={!foundCctvs.length}
          onClick={() => {
            const foundCctvIds = foundCctvs.map(foundCctv => foundCctv.id)
            setDisplayCctvs(filteredCctvs.filter(cctv => foundCctvIds.includes(cctv.id)))
          }}
        />
        <Btn
          text='Сбросить поиск'
          onClick={() => {
            setSearchStr('')
            setDisplayCctvs(filteredCctvs)
            setExactSearch(false)
          }}
        />
      </div>
      <div className='py-2'>
        <input
          type='text'
          className='form-control'
          placeholder='№ ТВН, адрес, IP-адрес, видеомаркер'
          value={searchStr}
          onChange={e => setSearchStr(e.target.value)}
        />
      </div>
      <div className='pe-1' style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'scroll' }}>
        {foundCctvs.map(foundCctv =>
          <div key={foundCctv.id} className='border border-secondary rounded my-1 p-1'>
            ТВН {foundCctv.number}
            {foundCctv.matches.map((match, i) =>
              <div key={i}>{match}</div>,
            )}
          </div>,
        )}
      </div>
    </ExpandableControl>
  )
})

export default SearchControl

import { useCallback, useEffect, useState } from 'react'
import {
  ApiResource, IAccessPoint, ICctv, ICommunicationNetworkSwitch, IHardwareSet, IIPCamera, IRouter, IUps,
  IVideoNetworkSwitch, IVideoServer,
} from '@/context/types'
import { apiRoutes, getApiResourceListRoute, getApiResourceReadRoute } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { convertCctv, convertHardware, convertIncident } from '@/config/apiConverters'
import { StreamFolder } from '@/components/CctvListPageContent/Cctv/StreamFolder/StreamFolder'
import { Folder } from '@/components/CctvListPageContent/Cctv/Folder/Folder'
import Loader from '@/components/common/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTabsContext } from '@/context/TabsContext'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import ProjectHeader from '@/components/ProjectsContent/ProjectHeader'
import { CctvView, cctvViews } from '@/components/CctvListPageContent/Cctv/constants'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import ExploitationHeader from '@/components/exploitation/ExploitationHeader'
import { emptyHardwareSet } from '@/utils/hardware'


export const Cctv = () => {
  const { projectId, cctvId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedView, setSelectedView] = useState(searchParams.get('view') as CctvView || 'default')
  const [isLoading, setIsLoading] = useState(false)
  const [cctv, setCctv] = useState<ICctv>()
  const [hardwareSet, setHardwareSet] = useState<IHardwareSet>(emptyHardwareSet)
  const { setLabel } = useTabsContext()
  useDocumentTitle(cctv && `ТВН ${cctv.number}`)

  const reloadCctv = useCallback(async () => {
    try {
      setIsLoading(true)
      setHardwareSet(emptyHardwareSet)
      setCctv(undefined)
      const response = await axios.get(apiRoutes.cctv(cctvId!))
      const cctv: ICctv = convertCctv(response.data)
      setCctv(cctv)

      setLabel(cctv?.number, faEye)

      const videoNetworkSwitches = Array<IVideoNetworkSwitch>(cctv.videoNetworkSwitches.length)
      const communicationNetworkSwitches = Array<ICommunicationNetworkSwitch>(cctv.communicationNetworkSwitches.length)
      const routers = Array<IRouter>(cctv.routers.length)
      const ipCameras = Array<IIPCamera>(cctv.ipCameras.length)
      const videoServers = Array<IVideoServer>(cctv.videoServers.length)
      const accessPoints = Array<IAccessPoint>(cctv.accessPoints.length)
      const upses = Array<IUps>(cctv.upses.length)
      await Promise.allSettled([
        ...cctv.videoNetworkSwitches.map(async ({ id }, index) => {
          const nsResponse = await axios.get(getApiResourceReadRoute(ApiResource.NetworkSwitch, id))
          videoNetworkSwitches[index] = convertHardware(nsResponse.data)
          const oiResponse = await axios.get(
            getApiResourceListRoute(ApiResource.Incident),
            { params: { 'exists[status]': false, 'hardware.id': id } }
          )
          videoNetworkSwitches[index].openIncidents = oiResponse.data.map(convertIncident)
        }),
        ...cctv.communicationNetworkSwitches.map(async ({ id }, index) => {
          const nsResponse = await axios.get(getApiResourceReadRoute(ApiResource.NetworkSwitch, id))
          communicationNetworkSwitches[index] = convertHardware(nsResponse.data)
          const oiResponse = await axios.get(
            getApiResourceListRoute(ApiResource.Incident),
            { params: { 'exists[status]': false, 'hardware.id': id } }
          )
          communicationNetworkSwitches[index].openIncidents = oiResponse.data.map(convertIncident)
        }),
        ...cctv.routers.map(async ({ id }, index) => {
          const rResponse = await axios.get<IRouter>(getApiResourceReadRoute(ApiResource.Router, id))
          routers[index] = convertHardware(rResponse.data)
          const oiResponse = await axios.get(
            getApiResourceListRoute(ApiResource.Incident),
            { params: { 'exists[status]': false, 'hardware.id': id } }
          )
          routers[index].openIncidents = oiResponse.data.map(convertIncident)
        }),
        ...cctv.ipCameras.map(async ({ id }, index) => {
          const ipcResponse = await axios.get<IIPCamera>(
            getApiResourceReadRoute(ApiResource.IpCamera, id),
            { params: { context_cctv_id: cctvId } }
          )
          ipCameras[index] = convertHardware(ipcResponse.data)
          const oiResponse = await axios.get(
            getApiResourceListRoute(ApiResource.Incident),
            { params: { 'exists[status]': false, 'hardware.id': id } }
          )
          ipCameras[index].openIncidents = oiResponse.data.map(convertIncident)
        }),
        ...cctv.videoServers.map(async ({ id }, index) => {
          const vsResponse = await axios.get<IVideoServer>(getApiResourceReadRoute(ApiResource.VideoServer, id))
          videoServers[index] = convertHardware(vsResponse.data)
          const oiResponse = await axios.get(
            getApiResourceListRoute(ApiResource.Incident),
            { params: { 'exists[status]': false, 'hardware.id': id } }
          )
          videoServers[index].openIncidents = oiResponse.data.map(convertIncident)
        }),
        ...cctv.accessPoints.map(async ({ id }, index) => {
          const apResponse = await axios.get<IAccessPoint>(getApiResourceReadRoute(ApiResource.AccessPoint, id))
          accessPoints[index] = convertHardware(apResponse.data)
          const oiResponse = await axios.get(
            getApiResourceListRoute(ApiResource.Incident),
            { params: { 'exists[status]': false, 'hardware.id': id } }
          )
          accessPoints[index].openIncidents = oiResponse.data.map(convertIncident)
        }),
        ...cctv.upses.map(async ({ id }, index) => {
          const apResponse = await axios.get<IUps>(getApiResourceReadRoute(ApiResource.Ups, id))
          upses[index] = convertHardware(apResponse.data)
          const oiResponse = await axios.get(
            getApiResourceListRoute(ApiResource.Incident),
            { params: { 'exists[status]': false, 'hardware.id': id } }
          )
          upses[index].openIncidents = oiResponse.data.map(convertIncident)
        }),
      ])
      setHardwareSet({
        videoNetworkSwitches,
        communicationNetworkSwitches,
        routers,
        ipCameras,
        videoServers,
        accessPoints,
        upses,
      })
    } catch (e: any) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [cctvId])

  useEffect(() => {
    reloadCctv()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cctvId])

  useEffect(() => {
    setSearchParams(selectedView === 'default' ? {} : { view: selectedView })
  }, [selectedView])

  return (
    <>
      {projectId ? <ProjectHeader /> : <ExploitationHeader />}
      {isLoading || !cctv
        ? <div className='container-fluid pt-5'><Loader /></div>
        : <div className='container-fluid mt-2'>
          <div className='d-flex justify-content-between my-2'>
            <div style={{ width: '2em' }}></div>
            <div className='btn-toolbar gap-2'>
              {Array.from(cctvViews.entries()).map(([view, { icon, label }]) =>
                <button
                  key={view}
                  className={`btn btn-sm btn-outline-primary shadow-none ${selectedView === view ? 'active' : ''}`}
                  style={{ width: '10em' }}
                  onClick={() => setSelectedView(view)}
                >
                  <FontAwesomeIcon icon={icon} className='me-2' />{label}
                </button>,
              )}
            </div>
            <div className='text-end' style={{ width: '2em' }}>
              <ReloadBtn onClick={reloadCctv} />
            </div>
          </div>
          {selectedView === 'default' &&
            <Folder
              cctv={cctv}
              hardwareSet={hardwareSet}
              reloadCctv={reloadCctv} />}
          {selectedView === 'stream' &&
            <StreamFolder
              cctv={cctv}
              ipCameras={hardwareSet.ipCameras}
              videoServers={hardwareSet.videoServers}
              reloadCctv={reloadCctv} />}
        </div>
      }
    </>
  )
}

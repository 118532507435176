import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '@/config/routes'
import { useProjectContext } from '@/context/project/ProjectContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import RoleRequired from '@/components/auth/RoleRequired'
import { UserRole } from '@/config/roles'
import { useModal } from '@/context/ModalContext'
import { ProjectFieldsModal } from '@/components/modals/projectFields/ProjectFieldsModal'
import useProjectId from '@/hooks/useProjectId'


/** Инфо проекта */
export default function ProjectInfo() {
  const { project } = useProjectContext()
  const { showModalDialog } = useModal()
  const projectId = useProjectId()

  const showEditProjectFieldsModal = useCallback(() => {
    showModalDialog({
      type: 'saveCancel',
      header: 'Поля проектов',
      size: 'lg',
      content: <ProjectFieldsModal projectId={projectId} />,
    })
  }, [showModalDialog, projectId])

  if (!project) return null

  return (
    <div className='row'>
      <div className='col-md-8'>
        <small className='text-muted'>Описание</small>
        <p>{project.description}</p>
      </div>
      <div className='col-md-4'>
        <small className='text-muted'>Ответственный</small>
        <p>
          {project.responsible.name}
          <br />
          <small>
            {project.responsible.organization.name},
            <br />
            {project.responsible.login}
          </small>
        </p>
      </div>
      <div className='col-md-auto'>
        <small className='text-muted'>Код серии</small>
        <p>{project.serialCode}</p>
      </div>
      <div className='col-md-auto'>
        <small className='text-muted'>№ в серии</small>
        <p>{project.serialOrderNumber}</p>
      </div>
      <div className='col-md-auto'>
        <small className='text-muted'>Тип проекта</small>
        <p>{project.type.name}</p>
      </div>
      <div className='col-md-auto'>
        <small className='text-muted'>Статус</small>
        <p>{project.isArchive ? 'Архивный' : 'Активный'}</p>
      </div>
      <RoleRequired roleCodes={[UserRole.admin, UserRole.pm]}>
        <div className='col-md-auto'>
          <Link to={routes.projectEdit(project.id)}>
            <FontAwesomeIcon icon={faPenToSquare} className='me-2 mt-4' />
            Редактировать проект
          </Link>
          <a className='m-lg-3 bg-transparent border-0' onClick={showEditProjectFieldsModal} href='#'>
            <FontAwesomeIcon icon={faCog} className='me-2' style={{ color: '#0d6efd' }} />
            <span style={{ color: '#0d6efd' }}>Настроить поля проекта</span>
          </a>
        </div>
      </RoleRequired>
    </div>
  )
}

import { useState } from 'react'
import { ApiResource, IIncidentCloseForm, IIncidentStatus } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'
import { useFormContext } from '@/context/form/FormContext'
import TextAreaInput from '@/components/common/form/TextAreaInput'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'


type Props = {
	incidentId: number
	onDone: () => void
	onCancel: () => void
}


/** Форма закрытия инцидента */
export default function IncidentCloseForm({ incidentId, onDone, onCancel }: Props) {
	const { showModalDialog, usePrimaryCallback, useSecondaryCallback } = useModal()
	const { form, setError } = useFormContext<IIncidentCloseForm>()
	const [isLoading, setIsLoading] = useState(false)

	usePrimaryCallback(async () => {
		if (!form.status) setError('status', 'Выберите этап')
		if (!form.message) setError('message', 'Введите комментарий')
		if (!form.status || !form.message) return false
		try {
			setIsLoading(true)
			await axios.post(apiRoutes.closeIncident(incidentId), form)
			onDone()
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Ошибка',
				content: await getErrorMessage(e),
			})
		} finally {
			setIsLoading(false)
		}
	}, [form, incidentId, onDone, showModalDialog])

	useSecondaryCallback(onCancel, [onCancel])

	return (
		<>
			<ApiResourceSelect<IIncidentStatus, IIncidentCloseForm>
				apiResource={ApiResource.IncidentStatus}
				label='Финальный статус'
				name='status'
				required
				disabled={isLoading} />
			<TextAreaInput<IIncidentCloseForm>
				label='Комментарий'
				name='message'
				required
				disabled={isLoading} />
		</>
	)
}
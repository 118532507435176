import {
  ApiResource, IAddress, ICctv, ICctvComment, ICctvFileType, IChannel, IConstructionObject, IContract, IFile, IIncident,
  IIPCamera,
  IPec, IPecComment, IPecFileType, IPecStatusField, IPecStatusStage, IProject, IService, IServiceType, IUser,
} from '@/context/types'
import { CctvView } from '@/components/CctvListPageContent/Cctv/constants'
import { allReportApiCodes, projectReportApiCodes, subReportApiCodes } from '@/config/reports'


export type StringApiRoute = ValueOf<PickByType<typeof apiRoutes, string>>

export const apiURL = process.env.REACT_APP_API_ORIGIN! + process.env.REACT_APP_API_PATH!

export const routes = {
  // Аутентификация
  signIn: '/auth/sign-in',
  signOut: '/auth/sign-out',
  profile: '/auth/profile',
  // Раздел "Проекты"
  projects: '/projects',
  project: (id: string) => `/projects/${id}`,
  projectCreate: `/projects/create`,
  projectEdit: (id: string) => `/projects/${id}/edit`,
  projectCctvs: (projectId: string) => `/projects/${projectId}/cctvs`,
  projectCctv: (projectId: string, id: string) => `/projects/${projectId}/cctvs/${id}`,
  projectCctvStream: (projectId: string, id: string) => `${routes.projectCctv(projectId, id)}?view=${CctvView.stream}`,
  projectReport: (projectId: string, code: ValueOf<typeof projectReportApiCodes>) => `/projects/${projectId}/reports/${code}`,
  projectMonitoring: (projectId: string) => `/projects/${projectId}/monitoring`,
  projectMonitoringMap: (projectId: string) => `/projects/${projectId}/monitoring-map`,
  // Раздел "Связь"
  communication: '/communication',
  services: '/communication/services',
  service: (id: string) => `/communication/services/${id}`,
  constructionObjects: '/communication/construction-objects',
  billingCommon: '/communication/billing',
  billingRevenue: '/communication/billing/revenue',
  billingExpenses: '/communication/billing/expenses',
  billingPortExpenses: '/communication/billing/port-expenses',
  actFormation: '/communication/act-formation',
  // Раздел "Эксплуатация"
  exploitation: '/exploitation',
  exploitationCctvs: '/exploitation/cctvs',
  exploitationCctv: (id: string) => `/exploitation/cctvs/${id}`,
  exploitationCctvStream: (id: string) => `${routes.exploitationCctv(id)}?view=${CctvView.stream}`,
  exploitationReport: (code: ValueOf<typeof projectReportApiCodes>) => `/exploitation/reports/${code}`,
  exploitationMonitoring: '/exploitation/monitoring',
  exploitationMonitoringMap: '/exploitation/monitoring-map',
  exploitationIncidents: '/exploitation/incidents',
  // Раздел "Сопровождение" (бывший "Аудит")
  auditProjects: '/audit/projects',
  auditProject: (id: string) => `/audit/projects/${id}`,
  auditProjectCreate: '/audit/projects/create',
  auditProjectEdit: (id: string) => `/audit/projects/${id}/edit`,
  auditProjectPec: (projectId: string, id: string) => `/audit/projects/${projectId}/pecs/${id}`,
  auditExportFiles: (projectId: string) => `/audit/projects/${projectId}/export-files`,
  // Раздел "Справочники"
  catalogs: '/catalogs',
  catalog: (id: string) => `/catalogs/${id}`,
  // Раздел "Настройки"
  settings: '/settings',
  // Раздел "Каналы"
  channels: '/channels',
  channel: (id: string) => `/channels/${id}`,
  addresses: '/channels/addresses',
  address: (id: string) => `/channels/addresses/${id}`,
  channelPayments: '/channels/channel-payments'
} as const


/** Путь к странице списка записей ресурса API */
export function getApiResourceListPath(apiResource: ApiResource) {
  return `${routes.catalogs}/${apiResource}`
}

/** Путь к странице создания записи ресурса API */
export function getApiResourceCreatePath(apiResource: ApiResource) {
  return `${routes.catalogs}/${apiResource}/create`
}

/** Путь к странице деталей записи ресурса API */
export function getApiResourceDetailsPath(apiResource: ApiResource, resourceId: string | number) {
  return `${routes.catalogs}/${apiResource}/${resourceId}`
}

/** Путь к странице редактирования записи ресурса API */
export function getApiResourceEditPath(apiResource: ApiResource, resourceId: string | number) {
  return `${routes.catalogs}/${apiResource}/${resourceId}/edit`
}

const apiCatalogFileUploadResources = {
  uploadNetworkSwitchPresetFile: ApiResource.NetworkSwitchPreset,
  uploadRouterPresetFile: ApiResource.RouterPreset,
  uploadIpCameraPresetFile: ApiResource.IpCameraPreset,
  uploadVideoServerPresetFile: ApiResource.VideoServerPreset,
  uploadUpsPresetFile: ApiResource.UpsPreset,
  uploadAgreementFile: ApiResource.Agreement,
  uploadContractFile: ApiResource.Contract,
  uploadOrganizationFile: ApiResource.Organization,
  uploadOrderFormFile: ApiResource.OrderForm,
} as const

export type ApiCatalogUploadFileRouteKey = keyof typeof apiCatalogFileUploadResources

const apiCatalogFileUploadRoutes = Object.entries(apiCatalogFileUploadResources)
  .reduce((acc, [k, apiResource]) => ({
    ...acc,
    [k]: (id: string) => `${apiURL}${ApiResourceRoutes[apiResource]}/${id}/file`,
}), {} as Record<ApiCatalogUploadFileRouteKey, (id: string) => string>)

export const apiRoutes = {
  // Аутентификация
  auth: {
    signIn: `${apiURL}/auth/signin`,
    signOut: `${apiURL}/auth/signout`,
    status: `${apiURL}/auth/status`,
  },
  // Ресурсы OpenAPI
  // Ресурсы пользователей
  users: `${apiURL}/users`,
  user: (id: IUser['id']) => `${apiURL}/users/${id}`,
  // Ресурсы проектов
  projects: `${apiURL}/projects`,
  project: (id: IProject['id']) => `${apiURL}/projects/${id}`,
  projectDashboard: (id: IProject['id']) => `${apiURL}/projects/${id}/dashboard`,
  projectFieldsEdit: (id: IProject['id']) => `${apiURL}/projects/${id}/project-fields`,
  projectTypes: `${apiURL}/project-types`,
  projectType: (id: IProject['id']) => `${apiURL}/project-types/${id}`,
  projectFields: `${apiURL}/project-fields`,
  projectField: (id: IProject['id']) => `${apiURL}/project-fields/${id}`,
  channelFields: `${apiURL}/channel-fields`,
  channelField: (id: IChannel['id']) => `${apiURL}/channel-fields/${id}`,
  hardwareLog: `${apiURL}/monitoring/hardware/log`,
  projectMonitoringMap: (id: IProject['id']) => `${apiURL}/projects/${id}/cctvs-monitoring-map`,
  // Ресурсы ТВН
  cctvs: `${apiURL}/cctvs`,
  cctv: (id: ICctv['id']) => `${apiURL}/cctvs/${id}`,
  cctvAcceptanceStatuses: `${apiURL}/cctvs-acceptance-statuses`,
  cctvAcceptanceStatus: (code: ICctv['acceptanceStatus']['code']) => `${apiURL}/cctvs-acceptance-statuses/${code}`,
  cctvChannelStatuses: `${apiURL}/cctvs-channels-statuses`,
  cctvChannelStatus: (code: ICctv['channelStatus']['code']) => `${apiURL}/cctvs-channels-statuses/${code}`,
  cctvInstallationStatuses: `${apiURL}/cctvs-installation-statuses`,
  cctvInstallationStatus: (code: ICctv['installationStatus']['code']) => `${apiURL}/cctvs-installation-statuses/${code}`,
  idAcceptanceStatuses: `${apiURL}/id-acceptance-statuses`,
  idAcceptanceStatus: (code: ICctv['idAcceptanceStatus']['code']) => `${apiURL}/id-acceptance-statuses/${code}`,
  installationPassivePartStatuses: `${apiURL}/cctv-installation-passive-part-statuses`,
  installationPassivePartStatus: (code: ICctv['installationPassivePartStatus']['code']) => `${apiURL}/cctv-installation-passive-part-statuses/${code}`,
  cctvHardwareBinding: (id: ICctv['id']) => `${apiURL}/hardware/binding/cctv/${id}`,
  cctvComments: `${apiURL}/cctv-comments`,
  cctvComment: (id: ICctvComment['id']) => `${apiURL}/cctv-comments/${id}`,
  // Misc
  ipCameraCodeOnCctv: (cctvId: ICctv['id'], ipCameraOrderNumber: IIPCamera['orderNumberOnCctv']) => `${apiURL}/ip-camera-code-on-cctv?cctv_id=${cctvId}&ip_camera_order_number=${ipCameraOrderNumber}`,
  /** Файл */
  file: (id: IFile['id']) => `${apiURL}/files/${id}`,
  // Действия
  /** Прикрепить файл к инциденту */
  uploadIncidentFile: (id: IIncident['id']) => `${apiURL}/incidents/${id}/file`,
  /** Обновить этап инцидента */
  updateIncident: (id: IIncident['id']) => `${apiURL}/incidents/${id}/stage-update`,
  /** Закрыть инцидент */
  closeIncident: (id: IIncident['id']) => `${apiURL}/incidents/${id}/close`,
  dropProjectMarkers: (projectId: IProject['id']) => `${apiURL}/drop-video-markers/${projectId}`,
  updateCctvVideoMarkers: (cctvId: ICctv['id']) => `${apiURL}/update-video-markers/${cctvId}`,
  // Организации
  organizations: `${apiURL}/organizations`,
  // Импорты
  // Импорт организаций
  importOrganizations: `${apiURL}/import/organizations`,
  // Импорт пользователей
  importUsers: `${apiURL}/import/users`,
  // Импорт адресов
  importAddresses: `${apiURL}/import/addresses?from=storage`,
  // Импорт коммутаторов
  importNetworkSwitches: `${apiURL}/import/network-switches`,
  // Импорт маршрутизаторов
  importRouters: `${apiURL}/import/routers`,
  // Импорт ip-камер
  importIpCameras: `${apiURL}/import/ip-cameras`,
  // Импорт видеосерверов
  importVideoServers: `${apiURL}/import/video-servers`,
  // Импорт ИБП
  importUps: `${apiURL}/import/ups`,
  // Импорт точек доступа
  importAccessPoints: `${apiURL}/import/access-points`,
  // Привязать оборудование
  importHardwareBind: `${apiURL}/import/hardware/bind`,
  // Массовое обновление
  updateProjectCctvs: (projectId: IProject['id']) => `${apiURL}/import/cctvs/update?project_id=${projectId}`,
  // Массовые импорт ТВНов
  importProjectCctvs: (projectId: IProject['id']) => `${apiURL}/import/cctvs?project_id=${projectId}`,
  // Массовый импорт оборудования
  importProjectHardware: (projectId: IProject['id']) => `${apiURL}/import/hardware?project_id=${projectId}`,
  // Экспорт в БЭИ
  exportToRSB: (projectId: IProject['id']) => `${apiURL}/export/ip-cameras?project_id=${projectId}&to=storage`,
  // Импорт из БЭИ
  importFromRSB: (projectId: IProject['id']) => `${apiURL}/import/ip-cameras?project_id=${projectId}&from=storage`,
  // Экспорты
  // Экспорт архива эталонных снимков
  exportReferencesSnapshots: (projectId: IProject['id'], to: 'archive' | 'storage') => `${apiURL}/export/references-snapshots?project_id=${projectId}&to=${to}`,
  // Экспорт ТВНов
  exportProjectCctvs: (projectId: IProject['id']) => `${apiURL}/export/project/cctvs?project_id=${projectId}`,
  exportExploitationCctvs: `${apiURL}/export/exploitation/cctvs`,
  // Экспорт коммутаторов
  exportNetworkSwitches: `${apiURL}/export/hardware/network-switches`,
  // Экспорт пресетов коммутаторов
  exportNetworkSwitchPresets: `${apiURL}/export/hardware/network-switch-presets`,
  // Экспорт моделей коммутаторов
  exportNetworkSwitchModels: `${apiURL}/export/hardware/network-switch-models`,
  // Экспорт прошивки коммутаторов
  exportNetworkSwitchFirmwares: `${apiURL}/export/hardware/network-switch-firmwares`,
  // Экспорт маршрутизаторов
  exportRouter: `${apiURL}/export/hardware/routers`,
  // Экспорт пресетов маршрутизаторов
  exportRoutersPresets: `${apiURL}/export/hardware/router-presets`,
  // Экспорт моделей маршрутизаторов
  exportRouterModels: `${apiURL}/export/hardware/router-models`,
  // Экспорт прошивки маршрутизаторов
  exportRouterFirmwares: `${apiURL}/export/hardware/router-firmwares`,
  // Экспорт ip-камер
  exportIpCameras: `${apiURL}/export/hardware/ip-cameras`,
  // Экспорт видеосерверов
  exportVideoServers: `${apiURL}/export/hardware/video-servers`,
  // Экспорт ИБП
  exportUps: `${apiURL}/export/hardware/ups`,
  // Экспорт точек доступа
  exportAccessPoints: `${apiURL}/export/hardware/access-points`,
  // Экспорт типов проектов
  exportProjectTypes: `${apiURL}/export/project-types`,
  // Экспорт полей проектов
  exportProjectFields: `${apiURL}/export/project-fields`,
  // Экспорт Типов ТВН
  exportCctvTypes: `${apiURL}/export/cctv-types`,
  // Экспорт Типов файлов ТВН
  exportCctvFileTypes: `${apiURL}/export/cctv-file-types`,
  // Экспорт Технологии каналов
  exportChannelTechnologies: `${apiURL}/export/channel-technologies`,
  // Экспорт пресетов IP-камер
  exportIpCameraPresets: `${apiURL}/export/hardware/ip-camera-presets`,
  // Экспорт моделей IP-камер
  exportIpCameraModels: `${apiURL}/export/hardware/ip-camera-models`,
  // Экспорт прошивок IP-камер
  exportIpCameraFirmwares: `${apiURL}/export/hardware/ip-camera-firmwares`,
  // Экспорт моделей точек доступа
  exportAccessPointModels: `${apiURL}/export/hardware/access-point-models`,
  // Экспорт прошивок точек доступа
  exportAccessPointFirmwares: `${apiURL}/export/hardware/access-point-firmwares`,
  // Экспорт этапов инцидентов
  exportIncidentStages: `${apiURL}/export/incident-stages`,
  exportProjectIncidents: (projectId: string) => `${apiURL}/export/project/monitoring-incidents?project_id=${projectId}`,
  exportExploitationIncidents: `${apiURL}/export/exploitation/monitoring-incidents`,
  // Экспорт статусов инцидентов
  exportIncidentStatuses: `${apiURL}/export/incident-statuses`,
  // Экспорт пользователей
  exportUsers: `${apiURL}/export/users`,
  // Экспорт ролей пользователей
  exportUserRoles: `${apiURL}/export/user-roles`,
  // Экспорт организаций
  exportOrganizations: `${apiURL}/export/organizations`,
  // Экспорт ролей организаций
  exportOrganizationRoles: `${apiURL}/export/organization-roles`,
  // Экспорт адресов
  exportAddresses: `${apiURL}/export/addresses`,
  exportChannelAddresses: `${apiURL}/export/addresses-with-channels`,
  // Экспорт районов
  exportDistricts: `${apiURL}/export/districts`,
  // Экспорт округов
  exportAreas: `${apiURL}/export/areas`,

  // Связь
  importContracts: `${apiURL}/import/contracts`,
  exportContracts: `${apiURL}/export/contracts`,
  importServiceTypes: `${apiURL}/import/service-types`,
  exportServiceTypes: `${apiURL}/export/service-types`,
  importContractServiceTypePrices: `${apiURL}/import/contract-service-type-prices`,
  exportContractServiceTypePrices: `${apiURL}/export/contract-service-type-prices`,
  importDepartments: `${apiURL}/import/departments`,
  exportDepartments: `${apiURL}/export/departments`,
  importConsumers: `${apiURL}/import/consumers`,
  exportConsumers: `${apiURL}/export/consumers`,
  importReportingPeriods: `${apiURL}/import/reporting-periods`,
  exportReportingPeriods: `${apiURL}/export/reporting-periods`,
  importCommunicationAddresses: `${apiURL}/import/communication-addresses`,
  exportCommunicationAddresses: `${apiURL}/export/communication-addresses`,
  importJoints: `${apiURL}/import/joints`,
  exportJoints: `${apiURL}/export/joints`,
  importQualityClasses: `${apiURL}/import/quality-classes`,
  exportQualityClasses: `${apiURL}/export/quality-classes`,
  // Скачать файл импорта
  downloadCsvTemplate: (templateName: ApiResource | 'hardware' | 'audit-users', params?: Record<string, string>) => `${apiURL}/template/csv?template_name=${templateName}&${new URLSearchParams(params)}`,
  // Дашборд по связи
  communicationDashboard: `${apiURL}/communication/dashboard`,
  // Услуги
  services: `${apiURL}/communication/services`,
  service: (id: IService['id']) => `${apiURL}/communication/services/${id}`,
  serviceStatuses: `${apiURL}/communication/service-stages`,
  serviceStatus: (code: IService['stage']['code']) => `${apiURL}/communication/service-stages/${code}`,
  importServices: `${apiURL}/import/services`,
  exportServices: `${apiURL}/export/services`,
  // Объекты строительства
  constructionObjects: `${apiURL}/communication/construction-objects`,
  constructionObject: (id: IConstructionObject['id']) => `${apiURL}/communication/construction-objects/${id}`,
  importConstructionObjects: `${apiURL}/import/construction-objects`,
  exportConstructionObjects: `${apiURL}/export/construction-objects`,
  // Каналы
  channels: `${apiURL}/channels`,
  channel: (id: IChannel['id']) => `${apiURL}/channels/${id}`,
  cctvChannels: `${apiURL}/cctv-channels`,
  cloneChannels: (id: IChannel['id']) => `${apiURL}/channels/${id}/clone`,
  serviceChannels: `${apiURL}/service-channels`,
  channelStatuses: `${apiURL}/communication/chanel-stages`,
  channelStatus: (code: NonNullable<IChannel['stage']>['code']) => `${apiURL}/communication/chanel-stages/${code}`,
  importChannels: `${apiURL}/import/channels`,
  exportChannels: `${apiURL}/export/channels`,
  exportChannelPayments: `${apiURL}/export/channel-payments`,
  // Адреса
  addresses: `${apiURL}/addresses`,
  channelAddresses: `${apiURL}/addresses/with-channels`,
  channelAddress: (id: IAddress['id']) => `${apiURL}/addresses/with-channels/${id}`,
  address: (id: IAddress['id']) => `${apiURL}/addresses/${id}`,
  // Биллинг
  serviceBillingTotals: (contractNumber: IContract['number'], serviceTypeKindName?: IServiceType['typeKindName']) => `${apiURL}/communication/service-billing-totals?contract_number=${contractNumber}&service_type_kind_name=${serviceTypeKindName || ''}`,
  serviceBilling: (serviceId: IService['id'], endDate: Date) => `${apiURL}/communication/services/${serviceId}/billing?end_date=${endDate.toISOString()}`,
  exportBillingRevenue: `${apiURL}/export/billing-revenue`,
  // Акты
  exportAct: `${apiURL}/export/act`,
  exportActArchive: `${apiURL}/export/act-archive`,
  // Vrfs
  exportVrfs: `${apiURL}/export/vrfs`,
  // ОП контракта
  contractReportingPeriods: (contractNumber: IContract['number']) => `${apiURL}/reporting-periods?contracts.number=${contractNumber}`,
  channelTotals: `${apiURL}/channel-totals`,
  serviceTotals: `${apiURL}/service-totals`,
  
  exploitationDashboard: `${apiURL}/exploitation/dashboard`,
  exploitationMonitoringMap: `${apiURL}/exploitation/cctvs-monitoring-map`,
  exploitationIncidents: (hoursType: string) => `${apiURL}/exploitation/incidents-by-open-datetime?hours_type=${hoursType}`,
  
  pecs: `${apiURL}/pecs`,
  pec: (id: IPec['id']) => `${apiURL}/pecs/${id}`,
  updatePecStatus: (id: IPec['id']) => `${apiURL}/pecs/${id}/status`,
  updatePecContractor: (id: IPec['id']) => `${apiURL}/pecs/${id}/contractor`,
  updatePecResponsibleFromCustomer: (id: IPec['id']) => `${apiURL}/pecs/${id}/responsible-from-customer`,
  updatePecBrigadier: (id: IPec['id']) => `${apiURL}/pecs/${id}/brigadier`,
  updatePecExecutor: (id: IPec['id']) => `${apiURL}/pecs/${id}/executor`,
  updatePecWorkBeginDate: (id: IPec['id']) => `${apiURL}/pecs/${id}/work-begin-date`,
  pecStatuses: `${apiURL}/pec-statuses`,
  pecStatus: (code: IPecStatusStage['code']) => `${apiURL}/pec-statuses/${code}`,
  pecCommonStatistic: (projectId: IProject['id']) => `${apiURL}/pecs/statistic/${projectId}`,
  pecStatusFieldStatistic: (projectId: IProject['id'], pecStatusFieldId: IPecStatusField['id']) => `${apiURL}/pecs/statistic/${projectId}/pec-status-field/${pecStatusFieldId}`,
  pecSliceFinishedStatistic: (projectId: IProject['id'], pecStatusFieldId: IPecStatusField['id']) => `${apiURL}/pecs/slice-work-accepted/${projectId}/pec-status-field/${pecStatusFieldId}`,
  pecSliceStatusStatistic: (projectId: IProject['id'], pecStatusFieldId: IPecStatusField['id']) => `${apiURL}/pecs/slice-all-statuses/${projectId}/pec-status-field/${pecStatusFieldId}`,
  pecHardwareBinding: (id: ICctv['id']) => `${apiURL}/hardware/binding/pec/${id}`,
  pecComments: `${apiURL}/pec-comments`,
  pecComment: (id: IPecComment['id']) => `${apiURL}/pec-comments/${id}`,
  pecFiles: (number: IPec['number'], auditMainProjectId: IProject['id']) => `${apiURL}/pecs/${number}/files?audit_main_project_id=${auditMainProjectId}`,
  importPecs: (projectId: IProject['id']) => `${apiURL}/import/pecs?project_id=${projectId}`,
  updatePecs: (projectId: IProject['id']) => `${apiURL}/import/pecs/update?project_id=${projectId}`,
  importAuditUsers: () => `${apiURL}/import/audit/users`,
  exportPecFiles: `${apiURL}/export/pec-files`,
  exportProjectPecs: (projectId: IProject['id']) => `${apiURL}/export/pecs?project_id=${projectId}`,

  uploadCctvFile: (id: ICctv['id'], fileTypeId: ICctvFileType['id']) => `${apiURL}/cctvs/${id}/file?file_type_id=${fileTypeId}`,
  uploadPecFile: (id: ICctv['id'], pecStatusFieldId: IPecStatusField['id'], fileTypeId?: IPecFileType['id']) => `${apiURL}/pecs/${id}/file?status_field_id=${pecStatusFieldId}&file_type_id=${fileTypeId ?? ''}`,
  ...apiCatalogFileUploadRoutes,
} as const

export const extRoutes = {
  showOnYaMap: (item: ICctv | IPec) => `https://yandex.ru/maps/?text=${item.address.gpsLatitude.replace(',', '.')},${item.address.gpsLongitude.replace(',', '.')}`,
} as const

/** Маршруты к ресурсам API */
// @ts-ignore
const ApiResourceRoutes: Record<ApiResource, string> = {
  // Адреса
  [ApiResource.Area]: '/areas',
  [ApiResource.District]: '/districts',
  [ApiResource.Address]: '/addresses',
  [ApiResource.ChannelAddress]: '/addresses/with-channels',
  // Контакты
  [ApiResource.OrganizationRole]: '/organization-roles',
  [ApiResource.Organization]: '/organizations',
  [ApiResource.UserRole]: '/user-roles',
  [ApiResource.User]: '/users',
  // Оборудование
  [ApiResource.Vrf]: '/vrfs',
  [ApiResource.HardwareStatus]: '/hardware-statuses',
  [ApiResource.Incident]: '/incidents',
  [ApiResource.IncidentStage]: '/incident-stages',
  [ApiResource.IncidentStatus]: '/incident-statuses',
  [ApiResource.IncidentStageLog]: '/incidents-log-stages',
  // Коммутаторы
  [ApiResource.NetworkSwitchModel]: '/hardware/network-switch-models',
  [ApiResource.NetworkSwitchFirmware]: '/hardware/network-switch-firmware',
  [ApiResource.NetworkSwitchPreset]: '/hardware/network-switch-presets',
  [ApiResource.NetworkSwitch]: '/hardware/network-switches',
  // Маршрутизаторы
  [ApiResource.RouterModel]: '/hardware/router-models',
  [ApiResource.RouterFirmware]: '/hardware/router-firmware',
  [ApiResource.RouterPreset]: '/hardware/router-presets',
  [ApiResource.Router]: '/hardware/routers',
  // IP-камеры
  [ApiResource.IpCameraModel]: '/hardware/ip-camera-models',
  [ApiResource.IpCameraFirmware]: '/hardware/ip-camera-firmware',
  [ApiResource.IpCameraPreset]: '/hardware/ip-camera-presets',
  [ApiResource.IpCamera]: '/hardware/ip-cameras',
  // Видеосерверы
  [ApiResource.VideoServerModel]: '/hardware/video-server-models',
  [ApiResource.VideoServerFirmware]: '/hardware/video-server-firmware',
  [ApiResource.VideoServerPreset]: '/hardware/video-server-presets',
  [ApiResource.VideoServer]: '/hardware/video-servers',
  // Точки доступа
  [ApiResource.AccessPointModel]: '/hardware/access-point-models',
  [ApiResource.AccessPointFirmware]: '/hardware/access-point-firmware',
  [ApiResource.AccessPoint]: '/hardware/access-points',
  // Источники бесперебойного питания
  [ApiResource.UpsModel]: '/hardware/ups-models',
  [ApiResource.UpsFirmware]: '/hardware/ups-firmware',
  [ApiResource.UpsPreset]: '/hardware/ups-presets',
  [ApiResource.Ups]: '/hardware/ups',
  // ТВНы
  [ApiResource.Cctv]: '/cctvs',
  [ApiResource.MonitoringCctv]: '/cctvs',
  [ApiResource.CctvChannelStatus]: '/cctvs-channels-statuses',
  [ApiResource.CctvInstallationStatus]: '/cctvs-installation-statuses',
  [ApiResource.CctvAcceptanceStatus]: '/cctvs-acceptance-statuses',
  [ApiResource.CctvInstallationPassivePartStatus]: '/cctv-installation-passive-part-statuses',
  [ApiResource.CctvFileType]: '/cctv-file-types',
  [ApiResource.CctvType]: '/cctv-types',
  [ApiResource.ChannelTechnology]: '/channel-technologies',
  [ApiResource.RoomType]: '/room-types',
  [ApiResource.RoomSquare]: '/room-squares',
  [ApiResource.OutletAvailability]: '/outlet-availabilities',
  [ApiResource.StorageRoomType]: '/storage-room-types',
  [ApiResource.StorageRoomSquare]: '/storage-room-squares',
  [ApiResource.TelecomOperatorRoomType]: '/telecom-operator-room-types',
  [ApiResource.TelecomOperatorTechnology]: '/telecom-operator-technologies',
  // Проекты
  [ApiResource.Project]: '/projects',
  [ApiResource.ProjectType]: '/project-types',
  [ApiResource.ProjectField]: '/project-fields',
  // Связь
  [ApiResource.Contract]: '/contracts',
  [ApiResource.ServiceType]: '/service-types',
  [ApiResource.ContractServiceTypePrice]: '/contract-service-type-prices',
  [ApiResource.Department]: '/departments',
  [ApiResource.Consumer]: '/consumers',
  [ApiResource.ReportingPeriod]: '/reporting-periods',
  [ApiResource.CommunicationAddress]: '/communication-addresses',
  [ApiResource.Service]: '/communication/services',
  [ApiResource.ConstructionObject]: '/communication/construction-objects',
  [ApiResource.Channel]: '/channels',
  [ApiResource.ChannelField]: '/channel-fields',
  [ApiResource.ChannelStages]: '/communication/chanel-stages',
  [ApiResource.Joint]: '/joints',
  [ApiResource.QualityClass]: '/quality-classes',
  [ApiResource.ConstructionObjectStatus]: '/construction-object-statuses',
  [ApiResource.PortOwner]: '/port-owners',
  [ApiResource.Agreement]: '/agreements',
  [ApiResource.OrderForm]: '/order-forms',
  [ApiResource.OrderFormSigningStatus]: '/order-form-signing-statuses',
  [ApiResource.Pec]: '/pecs',
  [ApiResource.PecFileType]: '/pec-file-types',
  [ApiResource.PecStatusFields]: '/pec-status-fields',
}


/** Маршрут получения списка записей ресурса API */
export function getApiResourceListRoute(apiResource: ApiResource) {
  return `${apiURL}${ApiResourceRoutes[apiResource]}`
}

/** Маршрут создания записи ресурса API */
export function getApiResourceCreateRoute(apiResource: ApiResource) {
  return getApiResourceListRoute(apiResource)
}

/** Маршрут получения записи ресурса API */
export function getApiResourceReadRoute(apiResource: ApiResource, resourceId: string | number) {
  return `${apiURL}${ApiResourceRoutes[apiResource]}/${resourceId}`
}

/** Маршрут обновления записи ресурса API */
export function getApiResourceUpdateRoute(apiResource: ApiResource, resourceId: string | number) {
  return getApiResourceReadRoute(apiResource, resourceId)
}

/** Маршрут удаления записи ресурса API */
export function getApiResourceDeleteRoute(apiResource: ApiResource, resourceId: string | number) {
  return getApiResourceReadRoute(apiResource, resourceId)
}

export function getReportReadRoute(
  apiCode: ValueOf<typeof allReportApiCodes & typeof subReportApiCodes>,
  params: Record<string, string>
) {
  return `${apiURL}/export/reports/${apiCode}?${new URLSearchParams(params)}`
}

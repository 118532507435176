import { IPec } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { IPecAttachFile } from '@/context/types'
import { MouseEvent } from 'react'
import { imagesGalleryModal } from '@/components/modals/cctv/modals'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserRole } from '@/config/roles'


type Props = {
  pec: IPec
  file: IPecAttachFile | undefined
  reloadCard: () => Promise<void>
}

const PecAttachedFilesItem = ({ pec, file, reloadCard }: Props) => {
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()


  const canDelete = hasRole([UserRole.admin, UserRole.mgts, UserRole.contractor])

  const handleClickAdditionalFile = (
    e: MouseEvent<HTMLAnchorElement>,
    file: IPecAttachFile,
  ) => {
    const imageFiles = pec.files.filter(file => {
      const extension = (file.isExternal ? file.id : file.name).split('.').pop()
      return extension && ['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp'].includes(extension)
    })
    
    const params = imagesGalleryModal(pec, imageFiles, file, canDelete, reloadCard)
  
    if (!params) return
  
    e.preventDefault()
    showModalDialog(params)
  }


 return (
  <div>
      <div
      className='card border-secondary justify-content-center align-items-center my-2'
      style={{ width: 300, height: 300 }}
    >
      {file ? 
      <a
        href={file.path}
        className='w-100 h-100 d-flex justify-content-center align-items-center'
        target='_blank'
        rel='noreferrer'
        onClick={(e) => handleClickAdditionalFile(e, file)}
      >
          <img
            className='cam-online-picture card-img-top mw-100 mh-100 w-auto h-auto'
            src={file.path}
            alt={file.name}
          />
        </a>
        : 'Нет данных'
      }
    </div>
  </div>
 )
}

export default PecAttachedFilesItem


import AuditProjectHeader from '@/components/audit/AuditProjectHeader'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useModal } from '@/context/ModalContext'
import { useParams } from 'react-router-dom'
import { usePecStatusFieldListContext } from '@/context/entityFields/PecStatusFieldListContext'
import { useQuery } from '@tanstack/react-query'
import { ChangeEvent, useState } from 'react'
import useStringArrayState from '@/hooks/useStringArrayState'
import Loader from '@/components/common/Loader'
import { useTabsContext } from '@/context/TabsContext'
import Select, { MultiValue } from 'react-select'
import defaultRecordToOption, { defaultFilterOption } from '@/utils/recordToSelectOption'
import { fetchPecFileTypes } from '@/api/queries/audit'
import { IDepartment, IPecFileType, IPecStatusField, ISelectOption } from '@/context/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { downloadFile, getFileNameFromContentDisposition } from '@/utils/tools'


type IFileTypeSelectOption = ISelectOption<IDepartment['id']>

const PecListPageContent = () => {
  const { setLabel } = useTabsContext()
  const { projectId } = useParams()
  const [checkedOptions, checkOption, uncheckOption] = useStringArrayState()
  const {
    recordList: pecStatusFields,
    isLoading: isStatusFieldsLoading,
  } = usePecStatusFieldListContext()
  const { isFetching: isFileTypesLoading, data: fileTypes } = useQuery({
    queryKey: ['pec_file_types', projectId],
    queryFn: () => fetchPecFileTypes(projectId!),
    initialData: [],
  })
  const [selectedFileTypes, setSelectedFileTypes] = useState({} as Record<IPecStatusField['id'], MultiValue<IFileTypeSelectOption>>)
  const { showModalDialog } = useModal()

  useDocumentTitle('Экспорт графических файлов')
  setLabel('Экспорт графических файлов')
  
  const generateArchive = async () => {
    try {
      const res = await axios({
        url: apiRoutes.exportPecFiles,
        method: 'POST',
        responseType: 'blob',
        data: {
          project_id: projectId,
          data: checkedOptions.reduce((acc, pecStatusFieldId) => {
            const selectedStatusFieldFileTypes = selectedFileTypes[pecStatusFieldId]
            if (selectedStatusFieldFileTypes?.length)
              acc[pecStatusFieldId] = selectedStatusFieldFileTypes.map(ft => ft.value)

            return acc
          }, {} as Record<IPecStatusField['id'], IPecFileType['id'][]>),
        },
      })

      downloadFile(res.data, getFileNameFromContentDisposition(res.headers['content-disposition']) || 'Архив.zip')
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка формирования архива',
        content: await getErrorMessage(e),
      })
    }
  }

  return (
    <>
      <AuditProjectHeader />
      <div className='container-fluid py-3'>
        {isStatusFieldsLoading || isFileTypesLoading
          ? <Loader />
          : <>
            <table className='table table-bordered table-hover' style={{ width: 'fit-content' }}>
              <thead className='table-primary' style={{ position: 'sticky', top: '177px' }}>
                <tr>
                  <th />
                  <th className='text-center'>Вид работ</th>
                  <th className='text-center'>Название файла</th>
                </tr>
              </thead>
              <tbody>
                {pecStatusFields.map(sf => {
                  const checked = checkedOptions.includes(sf.id)
                  const fileTypeOptions = fileTypes
                    .filter(ft => ft.pecStatusFields.some(pcf => pcf.id === sf.id))
                    .map(defaultRecordToOption) as IFileTypeSelectOption[]

                  return (
                    <tr key={sf.id}>
                      <td className='align-middle'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={checked}
                          onChange={
                            (e: ChangeEvent<HTMLInputElement>) =>
                              e.target.checked ? checkOption(sf.id) : uncheckOption(sf.id)
                          }
                        />
                      </td>
                      <td className={`align-middle ${checked ? '' : 'text-secondary'}`}>{sf.name}</td>
                      <td style={{ width: 750 }}>
                        <Select<IFileTypeSelectOption, true>
                          value={selectedFileTypes[sf.id]}
                          isMulti={true}
                          placeholder={'Выберите названия файлов'}
                          options={fileTypeOptions}
                          filterOption={defaultFilterOption}
                          noOptionsMessage={() => 'Ничего не найдено'}
                          isClearable={true}
                          isDisabled={!checked}
                          onChange={values => setSelectedFileTypes(deps => ({ ...deps, [sf.id]: values }))}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <button
              className='btn btn-sm btn-outline-primary'
              onClick={generateArchive}
              disabled={!checkedOptions.some(o => selectedFileTypes[o]?.length)}
            >
              <FontAwesomeIcon icon={faDownload} /> Выгрузить архив
            </button>
          </>
        }
      </div>
    </>
  )
}

export default PecListPageContent

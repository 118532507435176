import { ReactNode, useCallback, useMemo, useState } from 'react'
import { ICctv } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { apiRoutes, routes } from '@/config/routes'
import { UserRole } from '@/config/roles'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import FolderCard from '@/components/common/cards/FolderCard'
import ParamTable from '@/components/common/cards/ParamTable'
import ParamTableRow from '@/components/common/cards/ParamTableRow'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import style from '@/assets/styles/card.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { createEditCctvInfoModal } from '@/components/modals/cctv/modals'
import {
  ProjectsCctvTableColumnName, ProjectsCctvTableColumnNameList, ProjectsCctvTableColumns,
} from '@/config/tables/lists/projects/projectsCctv'
import IncidentList from '@/components/CctvListPageContent/common/IncidentList'
import { useTabsContext } from '@/context/TabsContext'
import { cctvStatusLabels } from '@/config/status/labels'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import CreateChannelByReferenceBtn from '@/components/common/btns/CreateChannelByReferenceBtn'
import { useParams } from 'react-router-dom'
import {
  ExploitationCctvTableColumnNameList, ExploitationCctvTableColumns,
} from '@/config/tables/lists/exploitation/exploitationCctv'
import ShowOnYaMap from '@/components/common/ShowOnYaMap'


type Props = {
  cctv: ICctv
  reloadCctv: () => Promise<void>
}


/** Карточка деталей ТВН */
export default function CctvInfoCard({ cctv, reloadCctv }: Props) {
  const { projectId } = useParams()
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()
  const [isExpand, setIsExpand] = useState(false)
  const { closeCurrentTab } = useTabsContext()
  const availableCols = projectId 
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ? useTableAvailableCols(ProjectsCctvTableColumnNameList, ProjectsCctvTableColumns)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    : useTableAvailableCols(ExploitationCctvTableColumnNameList, ExploitationCctvTableColumns)

  const handleExpand = useCallback(() => {
    setIsExpand(prev => !prev)
  }, [])

  const handleEditCctv = useCallback(() => {
    showModalDialog(createEditCctvInfoModal(cctv.project.id, reloadCctv, cctv))
  }, [cctv, reloadCctv, showModalDialog])

  const handleDeleteCctv = useCallback(() => {
    showModalDialog({
      type: 'deleteCancel',
      header: `Удаление ТВН-${cctv.number}`,
      content: `Вы уверены, что хотите удалить ТВН-${cctv.number}`,
      primaryCallback: async () => {
        try {
          await axios.delete(apiRoutes.cctv(cctv.id))
          closeCurrentTab(projectId ? routes.project(projectId) : routes.exploitation)
        } catch (e: any) {
          showModalDialog({
            type: 'error',
            header: `Удаление ТВН-${cctv.number}: ошибка`,
            content: await getErrorMessage(e, `Произошла ошибка при удалении ТВН-${cctv.number}`),
          })
        }
      }
    })
  }, [cctv, showModalDialog, closeCurrentTab])

  const customRows: Partial<Record<ProjectsCctvTableColumnName, ReactNode>> = useMemo(() => ({
    number: cctv.number,
    address: <ShowOnYaMap item={cctv} />,
    incidents: <IncidentList incidents={cctv.incidents} onUpdate={reloadCctv} />,
  }), [cctv, reloadCctv])

  return (
    <FolderCard
      headerBgColor='#ffd8a8'
      bodyBgColor='#fff4e6'
      header={
        <button
          className='btn btn-link p-0 text-decoration-none fw-bold text-nowrap'
          onClick={handleExpand}>
          Информация по ТВН-{cctv.number}
          <FontAwesomeIcon icon={isExpand ? faCaretUp : faCaretDown} className='ms-2' />
        </button>}
      centerToolbar={<>
        <CreateChannelByReferenceBtn route={apiRoutes.cctvChannels} referenceId={cctv.id} reloadCard={reloadCctv} />
        <SmallRoundBtn
          icon={faPenToSquare}
          tooltip='Редактировать сведения'
          disabled={!hasRole([UserRole.admin, UserRole.mgts])}
          onClick={handleEditCctv} /></>}
      rightToolbar={
        <SmallRoundBtn
          icon={faTrash}
          color='danger'
          tooltip='Удалить ТВН'
          disabled={!hasRole(UserRole.admin)}
          onClick={handleDeleteCctv} />}>
      <div className={`${style.cctvInfoCollapse} ${isExpand && style.contentActive}`}>
        <ParamTable>
          {availableCols
            .filter(columnName => !Object.keys(cctvStatusLabels).includes(columnName))
            .map(columnName =>
              <ParamTableRow key={columnName} label={
                /* //todo-sem пофиксить вместе с таблицей мониторинга */
                //@ts-ignore
                (projectId ? ProjectsCctvTableColumns : ExploitationCctvTableColumns)[columnName].label!
              } width='33%'>
                {/*//@ts-ignore */}{/* //todo-sem пофиксить вместе с таблицей мониторинга */}
                {customRows[columnName] ?? (projectId ? ProjectsCctvTableColumns : ExploitationCctvTableColumns)[columnName].value?.(cctv)}
              </ParamTableRow>
            )
          }
          {!!cctv.projectFields.length &&
            <tr className='border-top'>
              <td className='text-muted fw-bold ps-2' colSpan={2}>Дополнительная информация</td>
            </tr>
          }
          {cctv.projectFields.map((projectField, index) =>
            <ParamTableRow key={index} label={projectField.name}>
              {projectField.value}
            </ParamTableRow>)}
        </ParamTable>
      </div>
    </FolderCard>
  )
}

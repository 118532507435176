import { CardTableRowConfig, IIPCamera } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useCallback } from 'react'
import { editIpCameraVideoMarkerModal } from '@/components/modals/hardware/modals'
import { UserRole } from '@/config/roles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'


type Props = {
  ipCamera: IIPCamera,
  reloadItem: Parameters<CardTableRowConfig<any>['value']>[1]
}

const HardwareCardVideoMarker = ({ ipCamera, reloadItem }: Props) => {
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()

  const handleEditVideoMarker = useCallback(() => {
    showModalDialog(editIpCameraVideoMarkerModal(ipCamera, reloadItem))
  }, [ipCamera, reloadItem, showModalDialog])

  return (
    <div className='d-flex justify-content-between'>
      <div>{ipCamera.videoMarkerCode}</div>
      <button
        className='btn btn-sm btn-link p-0'
        disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts])}
        onClick={handleEditVideoMarker}
      >
        <FontAwesomeIcon icon={faPenToSquare} className='me-2' />
        Изменить
      </button>
    </div>
  )
}

export default HardwareCardVideoMarker

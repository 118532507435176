import { ICctv } from '@/context/types'
import { useCctvChannelStatusListContext } from '@/context/statuses/CctvChannelStatusListContext'
import { useCctvInstallationStatusListContext } from '@/context/statuses/CctvInstallationStatusListContext'
import { useCctvAcceptanceStatusListContext } from '@/context/statuses/CctvAcceptanceStatusListContext'
import { useIdAcceptanceStatusListContext } from '@/context/statuses/IdAcceptanceStatusListContext'
import FolderCard from '@/components/common/cards/FolderCard'
import CctvStatusControlMenu from '@/components/CctvListPageContent/Cctv/Folder/blocks/statuses/CctvStatusControlMenu'
import StatusIndicator from '@/components/common/cards/StatusIndicator'
import { cctvStatusLabels } from '@/config/status/labels'
import { useInstallationPassivePartStatusListContext } from '@/context/installationPassivePartStatusListContext'


type Props = {
  cctv: ICctv
  reloadCard: () => void
}

export default function CctvStatusCard({ cctv, reloadCard }: Props) {
  const { recordList: cctvChannelStatusList } = useCctvChannelStatusListContext()
  const { recordList: cctvInstallationStatusList } = useCctvInstallationStatusListContext()
  const { recordList: cctvAcceptanceStatusList } = useCctvAcceptanceStatusListContext()
  const { recordList: idAcceptanceStatusList } = useIdAcceptanceStatusListContext()
  const { recordList: installationPassivePartStatusList } = useInstallationPassivePartStatusListContext()

  return (
    <FolderCard
      headerBgColor='#ffec99'
      bodyBgColor='#fff9db'
      header={`Статус работ по ТВН-${cctv.number}`}
    >
      <table className='table table-sm table-hover table-borderless mb-0'>
        <tbody>
          <tr>
            <td className='text-muted ps-2'>{cctvStatusLabels.channelStatus}</td>
            <td>
              <StatusIndicator statusList={cctvChannelStatusList} selectedStatusCode={cctv.channelStatus.code} />
            </td>
            <td className='text-end pe-2'>
              <CctvStatusControlMenu
                cctv={cctv}
                statusFieldName='channelStatus'
                statusName={cctv.channelStatus.name}
                statusList={cctvChannelStatusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
          <tr>
            <td className='text-muted ps-2'>{cctvStatusLabels.installationStatus}</td>
            <td>
              <StatusIndicator
                statusList={cctvInstallationStatusList}
                selectedStatusCode={cctv.installationStatus.code}
              />
            </td>
            <td className='text-end pe-2'>
              <CctvStatusControlMenu
                cctv={cctv}
                statusFieldName='installationStatus'
                statusName={cctv.installationStatus.name}
                statusList={cctvInstallationStatusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
          <tr>
            <td className='text-muted ps-2'>{cctvStatusLabels.acceptanceStatus}</td>
            <td>
              <StatusIndicator statusList={cctvAcceptanceStatusList} selectedStatusCode={cctv.acceptanceStatus.code} />
            </td>
            <td className='text-end pe-2'>
              <CctvStatusControlMenu
                cctv={cctv}
                statusFieldName='acceptanceStatus'
                statusName={cctv.acceptanceStatus.name}
                statusList={cctvAcceptanceStatusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
          <tr>
            <td className='text-muted ps-2'>{cctvStatusLabels.idAcceptanceStatus}</td>
            <td>
              <StatusIndicator statusList={idAcceptanceStatusList} selectedStatusCode={cctv.idAcceptanceStatus.code} />
            </td>
            <td className='text-end pe-2'>
              <CctvStatusControlMenu
                cctv={cctv}
                statusFieldName='idAcceptanceStatus'
                statusName={cctv.idAcceptanceStatus.name}
                statusList={idAcceptanceStatusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
          <tr>
            <td className='text-muted ps-2'>{cctvStatusLabels.installationPassivePartStatus}</td>
            <td>
              <StatusIndicator
                statusList={installationPassivePartStatusList}
                selectedStatusCode={cctv.installationPassivePartStatus.code}
              />
            </td>
            <td className='text-end pe-2'>
              <CctvStatusControlMenu
                cctv={cctv}
                statusFieldName='installationPassivePartStatus'
                statusName={cctv.installationPassivePartStatus.name}
                statusList={installationPassivePartStatusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </FolderCard>
  )
}
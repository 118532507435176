import { ICctv, IIPCamera, IVideoServer } from '@/context/types'
import { IpCameraPhotoCctv } from '@/components/CctvListPageContent/Cctv/StreamFolder/blocks/IpCameraPhotoCctv'
import { VideoServerShortCctv } from '@/components/CctvListPageContent/Cctv/StreamFolder/blocks/VideoServerShortCctv'


type Props = {
  cctv: ICctv
  videoServers?: IVideoServer[]
  ipCameras?: IIPCamera[]
  reloadCctv: () => Promise<void>
}

export const StreamFolder = ({
  cctv,
  videoServers = [],
  ipCameras = [],
  reloadCctv,
}: Props) => {
  return (
    <div className='row mx-0'>
      <div className='col-xl ps-0 pe-0'>
        {ipCameras.map((ipCamera, index) => (
          <IpCameraPhotoCctv
            key={ipCamera.id}
            {...{ cctv, ipCamera, reloadCctv }}
          />
        ))}
        {videoServers.map((videoServer, index) => (
          <VideoServerShortCctv key={videoServer.id} {...{ videoServer, reloadCctv }} />
        ))}
      </div>
    </div>
  )
}

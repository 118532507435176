import { Fragment, ReactNode } from 'react'
import { getIcon } from '@/components/ProjectsContent/monitoring/map/config/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faMapMarkerAlt, faTools, faTruck } from '@fortawesome/free-solid-svg-icons'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'
import { hardwareStateLabels } from '@/components/ProjectsContent/monitoring/map/config/index'


const _btnClasses = [
  'cctv',
  'rtspBtn',
  'ipCamera',
  'incident',
] as const

export const btnClasses = _btnClasses.reduce((acc, k) => {
  acc[k] = `cctv_balloon_${k}_btn`
  return acc
}, {} as Record<typeof _btnClasses[number], string>) 


type BalloonFieldConfig = {
  icon: ReactNode
  value: ReactNode
  hidden?: boolean
}

export const getBalloonFields = (cctv: MonitoringMapCctv): BalloonFieldConfig[] => ([
  {
    icon: getIcon('cctv'),
    value: <a className={`text-decoration-none ${btnClasses.cctv}`} role='button'>{cctv.number}</a>,
  },
  {
    icon: getIcon('hardware', cctv.hardwareState),
    value: hardwareStateLabels[cctv.hardwareState],
  },
  {
    icon: <FontAwesomeIcon icon={faTools} />,
    value: `Подрядчик СМР: ${cctv.constructionContractor}`,
  },
  {
    icon: <FontAwesomeIcon icon={faTruck} />,
    value: `Подрядчик экспл.: ${cctv.maintenanceContractor}`,
  },
  {
    icon: <FontAwesomeIcon icon={faExclamationTriangle} color='#fa5252' />,
    value: <>
      Есть открытые проблемы:
      {cctv.incidents.map(incident =>
        <Fragment key={incident.id}>
          <a
            className={btnClasses.incident}
            data-id={incident.id}
            href='#'
          >
            №&nbsp;{incident.id}
          </a>
          {' '}
        </Fragment>,
      )}
    </>,
    hidden: !cctv.incidents.length,
  },
  {
    icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
    value: cctv.address.name,
  },
].filter(f => !f.hidden))

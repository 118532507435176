import { Dispatch, Reducer, useReducer } from 'react'
import { IPaginationResponse } from '@/context/types'


/** Generic API resource store state interface */
export interface ApiResourceState<TRecord> {
  isRecordListLoading: boolean
  recordList: TRecord[]
  lastPage?: number
  totalItems?: number
  isRecordLoading: boolean
  record?: TRecord
  error: string | null
}

/** Generic API resource store actions */
type ApiResourceAction<TRecord> =
  | { type: 'recordListFetched' | 'recordFetched' }
  | { type: 'recordListFailed' | 'recordFailed', payload: string }
  | { type: 'recordListLoaded', payload: { recordList: TRecord[], pagination: IPaginationResponse } }
  | { type: 'recordLoaded', payload: TRecord }


/** Generic API resource store initial state */
const apiResourceInitState: ApiResourceState<any> = {
  isRecordListLoading: false,
  recordList: [],
  isRecordLoading: false,
  error: null,
}


/** Generic API resource srore reducer */
function apiResourceReducer<TRecord>(state: ApiResourceState<TRecord>, action: ApiResourceAction<TRecord>): ApiResourceState<TRecord> {
  switch (action.type) {
    case 'recordListFetched':
      return {
        ...state,
        isRecordListLoading: true,
        error: null,
      }
    case 'recordListLoaded':
      return {
        ...state,
        isRecordListLoading: false,
        recordList: action.payload.recordList,
        lastPage: action.payload.pagination.lastPage,
        totalItems: action.payload.pagination.totalItems,
        error: null,
      }
    case 'recordListFailed':
      return {
        ...state,
        isRecordListLoading: false,
        error: action.payload,
      }
    case 'recordFetched':
      return {
        ...state,
        record: undefined,
        isRecordLoading: true,
        error: null,
      }
    case 'recordLoaded':
      return {
        ...state,
        isRecordLoading: false,
        record: action.payload,
        error: null,
      }
    case 'recordFailed':
      return {
        ...state,
        isRecordLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}


/** Generic API resource store state & reducer hook */
export const useApiResourceReducer:
  <TRecord>() => [ApiResourceState<TRecord>, Dispatch<ApiResourceAction<TRecord>>] =
  <TRecord>() => useReducer<Reducer<ApiResourceState<TRecord>, ApiResourceAction<TRecord>>>(apiResourceReducer, apiResourceInitState)


// Generic API resource store action createers

/** Generic API resource record list fetched action creator */
export const recordListFetchedAction =
  <TRecord>(): ApiResourceAction<TRecord> =>
    ({ type: 'recordListFetched' })
/** Generic API resource record list loaded action creator */
export const recordListLoadedAction =
  <TRecord>(recordList: TRecord[], pagination: IPaginationResponse): ApiResourceAction<TRecord> =>
    ({ type: 'recordListLoaded', payload: { recordList, pagination } })
/** Generic API resource record list fetched action creator */
export const recordListFailedAction =
  <TRecord>(error: string): ApiResourceAction<TRecord> =>
    ({ type: 'recordListFailed', payload: error })
/** Generic API resource record fetched action creator */
export const recordFetchedAction =
  <TRecord>(): ApiResourceAction<TRecord> =>
    ({ type: 'recordFetched' })
/** Generic API resource record loaded action creator */
export const recordLoadedAction =
  <TRecord>(record: TRecord): ApiResourceAction<TRecord> =>
    ({ type: 'recordLoaded', payload: record })
/** Generic API resource record fetched action creator */
export const recordFailedAction =
  <TRecord>(error: string): ApiResourceAction<TRecord> =>
    ({ type: 'recordFailed', payload: error })
import { useCallback, useEffect, useMemo, useState } from 'react'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import useTableRecordDelete from '@/hooks/useTableRecordDelete'
import { ApiResource, IChannel } from '@/context/types'
import { apiRoutes, routes } from '@/config/routes'
import {
  ChannelTableColumnName, ChannelTableColumnNameList, ChannelTableColumns, prepareChannelAdditionalCols,
} from '@/config/tables/lists/channels/channel'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useModal } from '@/context/ModalContext'
import { useChannelListContext } from '@/context/tables/ChannelListContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import ChannelsNav from '@/components/channels/ChannelsNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import PaginationWSP from '@/components/common/PaginationWSP'
import TableHead from '@/components/common/table/TableHead'
import TableRow from '@/components/common/table/TableRow'
import TableToolbar from '@/components/common/table/TableToolbar'
import { FormContextProvider } from '@/context/form/FormContext'
import ChannelCreateEditForm from '@/components/modals/channel/ChannelCreateEditForm'
import { copyObjectByKeys, isObjectEmpty } from '@/utils/tools'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import { useAuthContext } from '@/context/auth/AuthContext'
import { NaturalNumberRegex } from '@/config/config'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { useNavigate } from 'react-router-dom'
import { useChannelStatusListContext } from '@/context/statuses/ChannelStatusListContext'
import ChannelTableStatusMenu from '@/components/channels/ChannelTableStatusMenu'
import TableUserMenu from '@/components/common/table/TableUserMenu'
import { ChannelFieldsModal } from '@/components/modals/channelFields/ChannelFieldsModal'
import {
  ChannelFieldListContextProvider, useChannelFieldListContext,
} from '@/context/entityFields/ChannelFieldListContext'
import { AnyFormContextProvider } from '@/context/anyForm/AnyFormContext'
import searchToParams from '@/utils/paramConverters/searchToParams'
import Loader from '@/components/common/Loader'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { fetchChannelTableTotals } from '@/api/queries/table-totals'
import useTableDisplayedCols from '@/hooks/useTableDisplayedCols'
import { TableColsSelect } from '@/components/modals/TableColsSelect'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import RoleRequired from '@/components/auth/RoleRequired'
import { UserRole } from '@/config/roles'
import { responsibleListFilter } from '@/config/listFilters'


export default function ChannelTablePageContent() {
  const { hasPermission } = useAuthContext()
  useDocumentTitle('Таблица каналов')
  const { showModalDialog } = useModal()
  const { recordList: channelFieldList, loadRecordList: loadChannelFieldList } = useChannelFieldListContext()
  const availableCols = useTableAvailableCols(ChannelTableColumnNameList, ChannelTableColumns)
  const {
    displayedColumnNames,
    displayedCustomFields,
  } = useTableDisplayedCols('userChannelFields', availableCols, channelFieldList)
  const showDeleteModalDialog = useTableRecordDelete<IChannel>(apiRoutes.channel, 'каналов', routes.channel)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    isAllRecordsSelected,
    selectedRecordIdSet,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    samplerLabel,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
    setRecordListSampler,
    selectRecord,
    deselectRecord,
    selectAllRecords,
    deselectAllRecords
  } = useChannelListContext()
  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter
  } = useTableFilterForm(filter, setRecordListFilter)

  const [isSearchLoading, setIsSearchLoading] = useState(false)

  const { isFetching: isTotalsLoading, data: totals } = useQuery({
    queryKey: ['channel_totals', [...fullRecordIdSet].join()],
    queryFn: () => fetchChannelTableTotals(fullRecordIdSet),
    placeholderData : keepPreviousData,
  })

  const navigate = useNavigate()

  /** Удалить выбранные каналы (с подтверждением) */
  const deleteRecords = useCallback(() => {
    showDeleteModalDialog(selectedRecordIdSet, deselectAllRecords, loadRecordList)
  }, [selectedRecordIdSet, deselectAllRecords, loadRecordList])

  const handleSelectCols = useCallback(() => {
    showModalDialog({
      type: 'okCancel',
      header: 'Настройка столбцов',
      size: 'xl',
      content:
        <ChannelFieldListContextProvider>
          <TableColsSelect
            userTableHiddenColsKey='userChannelFields'
            columns={copyObjectByKeys(ChannelTableColumns, availableCols)}
            customFields={channelFieldList}
          />
        </ChannelFieldListContextProvider>,
    })
  }, [showModalDialog, channelFieldList])

  /** Сбросить выборку */
  const handleClearSampler = useCallback(() => {
    setRecordListSampler({}, '')
  }, [setRecordListSampler])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('каналов', {
      showMassEditInfo: true,
      importApiResource: hasPermission('importChannels') ? ApiResource.Channel : undefined
    }))
  }, [showModalDialog, hasPermission])

  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [])

  /** Поиск по УНК */
  const handleSearchChannelNumber = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString || !NaturalNumberRegex.test(clearSearchString))
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректный номер канала'
      })
    try {
      setIsSearchLoading(true)
      const response = await axios.get(
        apiRoutes.channels, {
          params: {
            'number': clearSearchString,
          }
        })
      if (!Array.isArray(response.data) || response.data.length === 0)
        throw new Error(`УНК ${clearSearchString} не найден`)

      const channel = response.data[0]
      channel && navigate(routes.channel(channel.id))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск УНК: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске УНК ${clearSearchString}`),
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])

  /** Поиск по ЕЦХД */
  const handleSearchStorageId = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString || !NaturalNumberRegex.test(clearSearchString))
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректный ID ЕЦХД (только цифры)'
      })
    try {
      setIsSearchLoading(true)

      loadRecordList(searchToParams(ChannelTableColumns, 'storageId', clearSearchString ))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск ТВН: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске ID ЕЦХД ${clearSearchString}`),
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])

  /** Поиск по Address */
  const handleSearchAddressName = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString)
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректный Адрес'
      })
    try {
      setIsSearchLoading(true)

      loadRecordList(searchToParams(ChannelTableColumns, 'aAddress', clearSearchString ))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск адресу: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске адреса ${clearSearchString}`)
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])


  const createRecord = useCallback(() => {
    showModalDialog({
      type: 'saveCancel',
      header: 'Добавление канала',
      size: 'lg',
      content:
      <ChannelFieldListContextProvider>
        <FormContextProvider>
          <AnyFormContextProvider>
            <ChannelCreateEditForm onSave={loadRecordList} />
          </AnyFormContextProvider>
        </FormContextProvider>
      </ChannelFieldListContextProvider>,
    })
  }, [showModalDialog])

  const showEditChannelFieldsModal = useCallback(() => {
    showModalDialog({
      type: 'saveCancel',
      header: 'Поля каналов',
      size: 'lg',
      content: <ChannelFieldsModal loadChannelFieldList={loadChannelFieldList}  />,
    })
  }, [showModalDialog, loadChannelFieldList])

  const customColumnLabels = useMemo(() => ({
    stage: <ChannelTableStatusMenu fieldKey='stage' listContextHook={useChannelStatusListContext} />,
    responsible: <TableUserMenu
      label={ChannelTableColumns.responsible.label!}
      roles={responsibleListFilter['userRoles.code']}
      deselectAllRecords={deselectAllRecords}
      hasEditPermission={hasPermission('channelEdit')}
      loadRecordList={loadRecordList}
      recordApiRoute={apiRoutes.channel}
      fieldName='responsible'
      selectedRecordIdSet={selectedRecordIdSet}
    />,
  }), [selectedRecordIdSet])

  const isLoading = isRecordListLoading || isFullIdRecordListLoading || isTotalsLoading

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem>Каналы</BreadcrumbsItem>
        <BreadcrumbsItem>Список всех каналов</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar handleSearchStorageId={handleSearchStorageId} handleSearchAddressName={handleSearchAddressName} handleSearchChannelNumber={handleSearchChannelNumber} isLoading={isSearchLoading} />
      <ChannelsNav />
      <TableToolbar
        hasSampler
        samplerLabel={samplerLabel}
        recordListLength={recordList.length}
        recordListCount={count}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        selectedRecordIdSetSize={selectedRecordIdSet.size}
        fullRecordIdSet={fullRecordIdSet}
        clearFilter={clearFilter}
        onClearSampler={handleClearSampler}
        onReload={loadRecordList}
        exportBtnLabel='Массовый экспорт каналов'
        exportApiRoute={hasPermission('exportChannels') ? apiRoutes.exportChannels : ''}
        importBtnLabel='Массовый импорт каналов'
        importApiRoute={hasPermission('importChannels') ? apiRoutes.importChannels : ''}
        handleShowFilterInfo={handleShowFilterInfo}
        onClickCreate={createRecord}
        onClickDelete={deleteRecords}
        canDelete={hasPermission('channelDelete')}
        additionalBtns={
          <RoleRequired roleCodes={UserRole.admin}>
            <button
              className='btn btn-sm btn-outline-primary'
              onClick={showEditChannelFieldsModal}
            >
              <span>Настроить поля каналов</span>
            </button>
          </RoleRequired>
        }
        handleSelectCols={handleSelectCols}
      />
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={displayedColumnNames}
                columns={ChannelTableColumns}
                additionalColumns={prepareChannelAdditionalCols({ channelFields: [] } as unknown as IChannel /* //todo-sem костыль чтобы в шапке таблицы не пропадали столбцы с полями каналов */, displayedCustomFields)}
                customColumnLabels={customColumnLabels}
                isAllRecordsSelected={isAllRecordsSelected}
                selectAllRecords={selectAllRecords}
                deselectAllRecords={deselectAllRecords}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
                totals={totals}
              />
              <tbody>
                {!recordList.length
                  ? <EmptyTableRow columnCount={displayedColumnNames.length + 1}>
                    {isObjectEmpty(filter) ? 'Каналов нет' : 'Каналов не найдено'}
                  </EmptyTableRow>
                  : recordList.map(record =>
                    <TableRow<IChannel, ChannelTableColumnName>
                      key={record.id}
                      columnNameList={displayedColumnNames}
                      columns={ChannelTableColumns}
                      additionalColumns={prepareChannelAdditionalCols(record, displayedCustomFields)}
                      record={record}
                      selectRecord={selectRecord}
                      selectedRecordIdSet={selectedRecordIdSet}
                      deselectRecord={deselectRecord}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount} />
        </div>
      </div>
    </>
  )
}

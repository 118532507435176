import { Fragment } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { useQuery } from '@tanstack/react-query'
import { fetchPecsCommonStatistic } from '@/api/queries/audit'
import { useParams } from 'react-router-dom'
import Loader from '@/components/common/Loader'
import { usePecListContext } from '@/context/tables/PecListContext'
import { IPecStatusField, TableFilter } from '@/context/types'
import PecsStatNumberLinkCell from '@/components/audit/stats/cells/PecsStatNumberLinkCell'
import { usePecStatusListContext } from '@/context/statuses/PecStatusListContext'
import filterToSampler from '@/utils/paramConverters/filterToSampler'
import { usePecStatusFieldListContext } from '@/context/entityFields/PecStatusFieldListContext'


const PecsCommonStat = () => {
  const { projectId } = useParams()
  const { recordList: statusList, isLoading: isStatusesLoading } = usePecStatusListContext()
  const { recordList: pecStatusFields, isLoading: isStatusFieldsLoading } = usePecStatusFieldListContext()
  const { isFetching: isLoading, data } = useQuery({
    queryKey: ['pec_common_statistic', projectId],
    queryFn: () => fetchPecsCommonStatistic(projectId!),
    initialData: [],
  })
  const { setRecordListSampler } = usePecListContext()

  if (isLoading || isStatusesLoading || isStatusFieldsLoading)
    return <Loader />

  return (
    <div style={{ overflowX: 'auto' }}>
      <table className='table table-bordered pecs-stat-table'>
        <thead style={{ backgroundColor: colorsTable.main }}>
          <tr>
            <th rowSpan={2}>ПО</th>
            <th rowSpan={2}>Адресов<br />закреплено</th>
            {pecStatusFields.map(sf =>
              <th colSpan={3} key={sf.id}>{sf.name}</th>,
            )}
            <th rowSpan={2}>Дострой</th>
          </tr>
          <tr>
            {pecStatusFields.map(sf =>
              <Fragment key={sf.id}>
                <th>Всего</th>
                <th>Не готов</th>
                <th>Выполнен</th>
              </Fragment>,
            )}
          </tr>
        </thead>
        <tbody>
          {data.map(row => {
            const setSampler = (
              filter: TableFilter<string> = {},
              label = '',
              statusFieldId?: IPecStatusField['id'],
            ) => {
              const labelParts = [] as string[]

              if (row.contractor) {
                const filterKey = statusFieldId
                  ? `contractors/${statusFieldId}`
                  : `contractorsMapEntries.contractor.name`

                filter[filterKey] = `${row.contractor.name}!`
                filter[`existsContractors/${statusFieldId ?? ''}`] = 'true!'

                labelParts.push(`ПО: ${row.contractor.name}`)
              }

              if (label)
                labelParts.push(label)

              setRecordListSampler(
                filterToSampler(filter),
                labelParts.map(part => `(${part})`).join(' и '),
              )
            }

            return (
              <tr key={row.contractor?.id ?? 'total'}>
                <td>{row.contractor?.name ?? 'Итого'}</td>
                <PecsStatNumberLinkCell onClick={() => setSampler()} value={row.addresses_count} />
                {pecStatusFields.map(sf => {
                  const rowStatusField = row.pec_status_fields[sf.id]
                  const setStatusSampler = (filterVal: string, label: string) =>
                    setSampler(
                      filterVal ? { [`statusFields/${sf.id}`]: filterVal, [`existsContractors/${sf.id}`]: 'true!' } : {},
                      `${sf.name}: ${label}`,
                      sf.id,
                    )
                  const lastStatusName = statusList.at(-1)!.name

                  return (
                    <Fragment key={sf.id}>
                      <PecsStatNumberLinkCell
                        onClick={() => setStatusSampler(`${lastStatusName}!?`, 'Всего')}
                        value={rowStatusField?.total ?? 0}
                      />
                      <PecsStatNumberLinkCell
                        onClick={() => setStatusSampler(`${lastStatusName}?`, 'Не готов')}
                        value={rowStatusField?.not_completed ?? 0}
                      />
                      <PecsStatNumberLinkCell
                        onClick={() => setStatusSampler(`${lastStatusName}!`, 'Выполнен')}
                        value={rowStatusField?.completed ?? 0}
                      />
                    </Fragment>
                  )
                })}
                <PecsStatNumberLinkCell
                  onClick={() => setSampler({ completion: 'true!', 'existsContractors': 'true!' }, 'Дострой: Да')}
                  value={row.completions_count}
                />
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default PecsCommonStat

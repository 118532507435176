import { getIcon } from '@/components/ProjectsContent/monitoring/map/config/icons'
import { HardwareStateCode } from '@/context/types'
import { ReactNode } from 'react'
import { CctvHardwareKey, cctvHardwareKeys, HardwareTypeCode } from '@/config/hardware'
import { hardwareStateLabels } from '@/components/ProjectsContent/monitoring/map/config/index'


type Indicator = {
  icon: ReactNode
  title: string
  count: number
}

export type IndicatorKey =
  | 'cctvs'
  | CctvHardwareKey
  | 'hardwareUnavailable'
  | 'hardwarePartially'
  | 'hardwareAvailable'
  | 'hardwareNone'

const makeStateIndicator = (state: HardwareStateCode) => ({
  icon: getIcon('hardware', state),
  title: hardwareStateLabels[state],
})

const indicators: Record<IndicatorKey, Omit<Indicator, 'count'>> = {
  cctvs: {
    icon: getIcon('cctv'),
    title: 'Кол-во ТВН на карте',
  },
  videoNetworkSwitches: {
    icon: getIcon(HardwareTypeCode.VideoNetworkSwitch),
    title: 'Кол-во коммутаторов видео на карте',
  },
  communicationNetworkSwitches: {
    icon: getIcon(HardwareTypeCode.CommunicationNetworkSwitch),
    title: 'Кол-во коммутаторов связи на карте',
  },
  ipCameras: {
    icon: getIcon(HardwareTypeCode.IpCamera),
    title: 'Кол-во камер на карте',
  },
  videoServers: {
    icon: getIcon(HardwareTypeCode.VideoServer),
    title: 'Кол-во видеосерверов на карте',
  },
  upses: {
    icon: getIcon(HardwareTypeCode.Ups),
    title: 'Кол-во ИБП на карте',
  },
  accessPoints: {
    icon: getIcon(HardwareTypeCode.AccessPoint),
    title: 'Кол-во точек доступа на карте',
  },
  hardwareUnavailable: makeStateIndicator(HardwareStateCode.unavailable),
  hardwarePartially: makeStateIndicator(HardwareStateCode.partially),
  hardwareAvailable: makeStateIndicator(HardwareStateCode.available),
  hardwareNone: makeStateIndicator(HardwareStateCode.none),
}

export const getIndicators = (projectHardwareIndicators: CctvHardwareKey[]) =>
  (Object.keys(indicators) as IndicatorKey[])
    .reduce((acc, k) => {
      if (!cctvHardwareKeys.includes(k as CctvHardwareKey) || projectHardwareIndicators.includes(k as CctvHardwareKey))
        acc[k] = { ...indicators[k], count: 0 }
      return acc
    }, {} as Record<IndicatorKey, Indicator>)

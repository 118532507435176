import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IServiceWithBilling, IServiceForm } from '@/context/types'
import {
  BillingRevenueTableColumnName, BillingRevenueTableColumns,
} from '@/config/tables/lists/commutication/billingRevenue'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Billing revenue list context */
const BillingRevenueListContext = createContext({} as TableApiResourceContextValue<IServiceWithBilling, BillingRevenueTableColumnName, IServiceForm>)


/** Billing revenue list context hook */
export const useBillingRevenueListContext = () => useContext(BillingRevenueListContext)


type Props = PropsWithChildren<{}>

/** Billing revenue list context provider */
export function BillingRevenueListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IServiceWithBilling, BillingRevenueTableColumnName, IServiceForm>
      Context={BillingRevenueListContext}
      apiResource={ApiResource.Service}
      loadIds
      recordIdKey='billingRevenueId'
      tableColumns={BillingRevenueTableColumns}
      requiredFilterColumns={['contractNumber']}
      logName='billingRevenueList'
      groups={['id:read', 'list:read', 'billing:read']}
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}
import {
  createContext, PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useRef, useState,
} from 'react'
import { Outlet } from 'react-router-dom'
import Modal from 'bootstrap/js/dist/modal'
import useEventListener from '@/hooks/useEventListener'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


/** Modal type choices */
export type ModalType = 'ok' | 'close' | 'okCancel' | 'yesNo' | 'saveCancel' | 'deleteCancel' | 'error' | 'abort'

/** Modal size choices */
export type MidalSize = 'sm' | 'md' | 'lg' | 'xl'

/** Modal callback type */
export type ModalCallbackFunc = () => boolean | void | Promise<boolean | void>

/** Modal context state interface */
export interface IModalContextState {
	type: ModalType
	size?: MidalSize
	dismissable?: boolean
	header: string
	content: ReactNode
	primaryCallback?: ModalCallbackFunc
	secondaryCallback?: ModalCallbackFunc
}

export type ShowModalDialogParams = {
	type?: ModalType
	size?: MidalSize
	dismissable?: boolean
	header: string
	content: ReactNode
	primaryCallback?: ModalCallbackFunc
	secondaryCallback?: ModalCallbackFunc
	closeCallback?: EventListener
}

type UpdateStateParams = {
	type?: ModalType
	size?: MidalSize
	dismissable?: boolean
	header?: string
}

/** Modal context interface  */
export interface IModalContextValue {
	showModalDialog: (params: ShowModalDialogParams) => void
	updateState: (stateUpdate: UpdateStateParams) => void
	usePrimaryCallback: (callback: ModalCallbackFunc, deps: Array<any>) => void
	useSecondaryCallback: (callback: ModalCallbackFunc, deps: Array<any>) => void
  closeModalDialog: () => void
}

type Props = PropsWithChildren<{}>


/** Modal context */
const ModalContext = createContext({} as IModalContextValue)


/** Modal context hook */
export const useModal = () => useContext(ModalContext)


/** Modal context provider */
export function ModalProvider({ children }: Props) {
	const [state, setState] = useState<IModalContextState>({
		type: 'ok',
		header: '',
		content: '',
	})
	const [nextState, setNextState] = useState<IModalContextState>()
	const modalRef = useRef<HTMLDivElement>(null)
	const modelObjectRef = useRef<Modal>()

	// Освободить стейт после закрытия формы
	useEventListener('hidden.bs.modal', () => {
		if (nextState === undefined) {
			setState((state) => ({
				...state,
				header: '',
				content: '',
				primaryCallback: () => { },
				secondaryCallback: () => { },
			}))
			modelObjectRef.current = undefined
		} else {
			setState(nextState)
			setNextState(undefined)
			modelObjectRef.current?.dispose()
			modelObjectRef.current = new Modal(modalRef.current!, { backdrop: nextState.dismissable || 'static', keyboard: nextState.dismissable })
			modelObjectRef.current?.show()
		}
	}, modalRef.current)

	const showModalDialog = useCallback(({
		type = 'ok',
		size,
		dismissable = true,
		header,
		content,
		primaryCallback,
		secondaryCallback,
		closeCallback,
	}: ShowModalDialogParams) => {
		if (type === 'abort')
			dismissable = false
		if (modelObjectRef.current) {
			modelObjectRef.current.hide()
			setNextState({ type, size, dismissable, header, content, primaryCallback, secondaryCallback })
		} else {
			setState({ type, size, dismissable, header, content, primaryCallback, secondaryCallback })
			modelObjectRef.current = new Modal(modalRef.current!, { backdrop: dismissable || 'static', keyboard: dismissable })
			modelObjectRef.current.show()
		}
		if (closeCallback)
			modalRef.current!.addEventListener('hidden.bs.modal', closeCallback, { once: true })
	}, [])

	const updateState = useCallback((stateUpdate: UpdateStateParams) => {
		setState((state) => ({ ...state, ...stateUpdate }))
	}, [])

	const usePrimaryCallback = (primaryCallback: ModalCallbackFunc, deps: Array<any>) => {
		useEffect(() => {
			setState((state) => ({
				...state,
				primaryCallback
			}))
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, deps)
	}

	const useSecondaryCallback = (secondaryCallback: ModalCallbackFunc, deps: Array<any>) => {
		useEffect(() => {
			setState((state) => ({
				...state,
				secondaryCallback
			}))
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, deps)
	}

	const handlePrimaryClick = useCallback(async () => {
		if (await state.primaryCallback?.() === false) return
		modelObjectRef.current?.hide()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.primaryCallback, modelObjectRef])

	const handleSecondaryClick = useCallback(async () => {
		if (await state.secondaryCallback?.() === false) return
		modelObjectRef.current?.hide()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.secondaryCallback, modelObjectRef])

  const closeModalDialog = useCallback(() => {
    modelObjectRef.current?.hide()
  }, [modelObjectRef])

	const value: IModalContextValue = {
		showModalDialog,
		updateState,
		usePrimaryCallback,
		useSecondaryCallback,
    closeModalDialog
	}

	return (
		<ModalContext.Provider value={value}>
			<div
				aria-hidden={true}
				aria-labelledby='contextModalDialog'
				className='modal fade'
				data-bs-backdrop={true}
				ref={modalRef}
				style={{zIndex: 10001}}
				tabIndex={-1}>
				<div className={`modal-dialog modal-dialog-centered ${state.size && `modal-${state.size}`} ${state.size === 'xl' && 'modal-fullscreen-lg-down'} ${state.size === 'lg' && 'modal-fullscreen-md-down'}`}>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='contextModalDialog'>
								{state.type === 'error' &&
									<FontAwesomeIcon icon={faExclamationTriangle} className='me-2 text-danger' />}
								{state.header}
							</h5>
							{state.dismissable &&
								<button
									aria-label='Отмена'
									className='btn-close'
									data-bs-dismiss='modal'
									type='button' />}
						</div>
						<div className='modal-body' style={{maxHeight: '80vh', overflowY: 'auto'}}>
							{state.content}
						</div>
						<div className='modal-footer'>
							{ModalButtons[state.type].map(({ label, colorName }, index) =>
								<button
									key={index}
									className={`btn btn-outline-${colorName ?? 'primary'}`}
									type='button'
									onClick={[handlePrimaryClick, handleSecondaryClick][index]}>
									{label}
								</button>)}
						</div>
					</div>
				</div>
			</div>
			{children ?? <Outlet />}
		</ModalContext.Provider>
	)
}


const ModalButtons: Record<ModalType, { label: string, colorName?: string }[]> = {
	'ok': [{ label: 'ОК' }],
	'close': [{ label: 'Закрыть' }],
	'okCancel': [{ label: 'ОК' }, { label: 'Отмена', colorName: 'secondary' }],
	'yesNo': [{ label: 'Да' }, { label: 'Нет', colorName: 'secondary' }],
	'saveCancel': [{ label: 'Сохранить' }, { label: 'Отмена', colorName: 'secondary' }],
	'deleteCancel': [{ label: 'Удалить', colorName: 'danger' }, { label: 'Отмена', colorName: 'secondary' }],
	'error': [{ label: 'ОК', colorName: 'danger' }],
	'abort': [{ label: 'Прервать', colorName: 'danger' }]
}
import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { IService } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** ServiceStatus context */
const ServiceStatusListContext = createContext({} as IRecordListContext<IService['stage']>)


/** ServiceStatus context hook */
export const useServiceStatusListContext = () => useContext(ServiceStatusListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** ServiceStatus context provider */
export function ServiceStatusListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<IService['stage']>
      Context={ServiceStatusListContext}
      listApiRoute={apiRoutes.serviceStatuses}
      deleteApiRoute={apiRoutes.serviceStatus}
      paginate={paginate}
      logName='serviceStatusList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ICctv } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** installationPassivePartStatus context */
const InstallationPassivePartStatusListContext = createContext({} as IRecordListContext<ICctv['installationPassivePartStatus']>)


/** installationPassivePartStatus context hook */
export const useInstallationPassivePartStatusListContext = () => useContext(InstallationPassivePartStatusListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** installationPassivePartStatus context provider */
export function InstallationPassivePartStatusListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<ICctv['installationPassivePartStatus']>
      Context={InstallationPassivePartStatusListContext}
      listApiRoute={apiRoutes.installationPassivePartStatuses}
      deleteApiRoute={apiRoutes.installationPassivePartStatus}
      paginate={paginate}
      logName='installationPassivePartStatusList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}

import { useModal } from '@/context/ModalContext'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { ICctvAttachFile, ICctvExternalFile, IPecAttachFile } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'


type Props = {
  file: ICctvAttachFile | ICctvExternalFile | IPecAttachFile
  onDelete: () => any
  disabled?: boolean
}

const DeleteFileBtn = ({ file, onDelete, disabled }: Props) => {
  const { showModalDialog } = useModal()

  const handleClick = () =>
    showModalDialog({
      type: 'deleteCancel',
      header: 'Удаление файла',
      content: `Вы уверены, что хотите удалить файл ${file.name}?`,
      primaryCallback: async () => {
        try {
          await axios.delete(apiRoutes.file(file.id))
          onDelete()
        } catch (e: any) {
          showModalDialog({
            type: 'error',
            header: `Удаление файла-${file.name}: ошибка`,
            content: await getErrorMessage(e, `Произошла ошибка при удалении файла ${file.name}`),
          })
        }
      },
    })

  return (
    <button onClick={() => handleClick()} className='btn text-danger px-1 py-0' disabled={disabled}>
      <FontAwesomeIcon icon={faTrash} />
    </button>
  )
}

export default DeleteFileBtn

import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { apiRoutes, routes } from '@/config/routes'
import { useTabsContext } from '@/context/TabsContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import ChannelsNav from '@/components/channels/ChannelsNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import { instanceAxios as axios } from '@/api/instanceAxios'
import ChannelInfoCard from '@/components/channels/Folder/ChannelInfo/ChannelInfoCard'
import ChannelStatusCard from '@/components/channels/Folder/statuses/ChannelStatusCard'
import Loader from '@/components/common/Loader'
import { IChannel } from '@/context/types'
import { convertChannel } from '@/config/apiConverters'
import PermissionRequired from '@/components/auth/PermissionRequired'
import HistoryStatusChannel from '@/components/channels/Folder/HistoryStatusChannel'
import HistoryCard from '@/components/common/cards/HistoryCard'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'


// Контент страницы "Канал"
export default function ChannelPageContent() {
  useDocumentTitle('Таблица каналов')
  const { channelId } = useParams()
  const { setLabel } = useTabsContext()
  const [channel, setChannel] = useState({} as IChannel)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    reloadChannel()
  }, [channelId])

  const reloadChannel = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(apiRoutes.channel(channelId!))
      const channel: IChannel = response.data

      setChannel(convertChannel(channel))
      setLabel(`Канал ${channel.number}`)
    } catch (e: any) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [channelId])

  if (isLoading || !channel) return <div className='container-fluid pt-5'><Loader /></div>

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem>Каналы</BreadcrumbsItem>
        <BreadcrumbsItem path={routes.channels}>Список всех каналов</BreadcrumbsItem>
        <BreadcrumbsItem>Канал {channel?.number}</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <ChannelsNav />
      {!channel.id
        ? <div className='mt-5'><Loader /></div>
        : <>
          <div className='row g-2 p-3 w-100 m-0'>
            <div className='d-flex justify-content-end my-2'>
              <div className='btn-toolbar gap-2'>
                <ReloadBtn onClick={reloadChannel} />
              </div>
            </div>
            <div className='col-xl'>
              <PermissionRequired permission={'channelStageRead'}>
                <ChannelStatusCard channel={channel} reloadCard={reloadChannel} />
              </PermissionRequired>
              <HistoryCard logHistoryEntries={channel.logHistoryEntries} label='канала' />
              <HistoryStatusChannel channel={channel}/>
            </div>
            <div className='col-xl'>
              <ChannelInfoCard reloadCard={reloadChannel} channel={channel} />
            </div>
          </div>
        </>
      }
    </>
  )
}
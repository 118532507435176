import { CardTableRowConfig, IVideoServer } from '@/context/types'
import { hardwareCommonTableRows } from '@/config/tables/cards/hardware/common'
import HardwareCardPreset from '@/components/common/cards/hardware/cells/HardwareCardPreset'


export const videoServerTableRows: Record<string, CardTableRowConfig<IVideoServer>> = {
  ipAddress: hardwareCommonTableRows.ipAddress,
  subnetwork: hardwareCommonTableRows.subnetwork,
  model: hardwareCommonTableRows.model,
  preset: {
    label: 'Пресет',
    value: videoServer => <HardwareCardPreset hardwarePreset={videoServer.preset} />,
  },
  firmware: hardwareCommonTableRows.firmware,
  status: hardwareCommonTableRows.status,
  id: hardwareCommonTableRows.id,
  networkSwitch: hardwareCommonTableRows.networkSwitch,
  address: hardwareCommonTableRows.address,
  locationDescription: hardwareCommonTableRows.locationDescription,
}

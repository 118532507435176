export function getTextColorClassName(value: string) {
  return `text-${getValueColorName(value)}`
}

export function getBgColorClassName(value: string) {
  return `bg-${getValueColorName(value)} bg-opacity-10`
}

/** Получить имя цвета по значению поля */
export function getValueColorName(value: string) {
  switch (value) {
    case 'not_started':
    case 'unavailable':
    case 'no':
    case 'disabled':
    case null:
    case undefined:
    case 'draft':
    case 'elaboration':
    case 'has_incidents':
    case 'problem_solving':
    case 'more':
      return 'danger'

    case 'archived':
      return 'muted'

    case 'completed':
    case 'acceptance':
    case 'available':
    case 'yes':
    case 'operation':
    case 'organized':
    case 'no_incidents':
    case 'in_work':
    case 'new_request':
    case 'less':
    case 'assigned':
    case 'remarks':
    case 'remarks_resolved':
    case 'work_accepted':
      return 'success'
  }

  return 'warning'
}

import { useCallback } from 'react'
import { useModal } from '@/context/ModalContext'
import { useProjectContext } from '@/context/project/ProjectContext'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import IntervalTimer, { Props as IntervalTimerProps } from '@/components/common/time/IntervalTimer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import ExportBtn from '@/components/common/btns/ExportBtn'


type Props = Pick<IntervalTimerProps, 'onTimerInterval'> & {
  recordListLength?: number
  recordListCount?: number
  onReload: () => void
  clearFilter: () => void
  exportApiRoute?: string
  exportBtnLabel?: string
}


/** Таблица мониторинга ТВН */
export default function MonitoringCctvTableToolbar({
  recordListLength,
  recordListCount,
  onReload,
  clearFilter,
  onTimerInterval,
  exportBtnLabel,
  exportApiRoute
}: Props) {
	const { showModalDialog } = useModal()

	/** Показать справку по фильтрации ТВН */
	const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('мониторинга'))
	}, [showModalDialog])

	return (
		<div style={{ width: '100%', height: '48px' }}>
			<div
        className='container-fluid py-3'
        style={{ position: 'fixed', height: '100px', background: 'white', marginTop: '-8px' }}
      >
				<div className='btn-toolbar gap-2 align-items-center' >
					<IntervalTimer onTimerInterval={onTimerInterval} sessionStorageKey='monitoringTableReloadIntervalInMS' />
					<button
						className='btn btn-sm btn-outline-primary'
						onClick={clearFilter}>
						<FontAwesomeIcon icon={faEraser} className='me-2' />
						Сбросить фильтр
					</button>
          <div>
            Показано {recordListLength} {!!recordListCount && `из ${recordListCount}`} записей.
          </div>
          {exportApiRoute &&
            <div className='container-fluid bg-white' style={{ width: 800, marginRight: -22 }}>
              <div className='container-fluid d-flex justify-content-end'>
                {exportApiRoute && <ExportBtn label={exportBtnLabel} apiRoute={exportApiRoute} />}
              </div>
            </div>
          }
					<button
						className='btn btn-link p-0 ms-auto'
						onClick={handleShowFilterInfo}>
						<FontAwesomeIcon icon={faInfoCircle} className='mx-2' />
						Справка
					</button>
          <ReloadBtn onClick={onReload} />
				</div>
			</div>
		</div>
	)
}
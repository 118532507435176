import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { IProject, IProjectForm } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserRole } from '@/config/roles'


/** Project list context */
const ProjectListContext = createContext({} as IRecordListContext<IProject, IProjectForm>)


/** Project list context hook */
export const useProjectListContext = () => useContext(ProjectListContext)


type Props = PropsWithChildren<{
  isAudit?: boolean
}>

/** Project list context provider */
export function ProjectListContextProvider({ children, isAudit = false }: Props) {
  return (
    <RecordListContextProvider<IProject, IProjectForm>
      Context={ProjectListContext}
      listApiRoute={apiRoutes.projects}
      deleteApiRoute={apiRoutes.project}
      logName='projectList'
      filter={{ audit: '' + isAudit }}
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { fetchData } from '@/api/fetchData'
import { getReportReadRoute } from '@/config/routes'
import { errorHandle } from '@/utils/errorHandle'
import Loader from '@/components/common/Loader'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportTT, ISubReportClosedIncidents, ISubReportOpenIncidents } from '@/components/reports/types'
import { SubReportClosedIncidents } from '@/components/reports/subreports/SubReportClosedIncidents'
import { SubReportOpenIncidents } from '@/components/reports/subreports/SubReportOpenIncidents'
import { Dropdown } from '@/components/reports/common/Dropdown'
import { getReportFilter } from '@/config/listFilters'
import { subReportApiCodes } from '@/config/reports'
import { IIncident } from '@/context/types'


export const ReportIncidentsByStages = () => {
  const { projectId } = useParams()
  const [report, setReport] = useState<IReportTT[]>([])
  const [reportDetailOpenTT, setReportDetailOpenTT] = useState<ISubReportOpenIncidents[]>([])
  const [reportDetailCloseTT, setReportDetailCloseTT] = useState<ISubReportClosedIncidents[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isShowOpenedIncidents, setIsShowOpenedIncidents] = useState(false)
  const [isShowClosedIncidents, setIsShowClosedIncidents] = useState(false)
  const [reportDetailFilterLabel, setReportDetailFilterLabel] = useState('')

  const getApiRouteFunc = (apiCode: ValueOf<typeof subReportApiCodes>) =>
    (incidentStage?: NonNullable<IIncident['stage']>['code'], format = '') =>
      getReportReadRoute(apiCode, {
        ...getReportFilter(projectId, startDate, endDate),
        stage_code: incidentStage ?? '',
        format,
      })

  const getOpenedIncidentsApiRoute = getApiRouteFunc(subReportApiCodes.incidentsOpenedByStage)
  const getClosedIncidentsApiRoute = getApiRouteFunc(subReportApiCodes.incidentsClosedByStage)

  const showOpenedIncidents = (row?: IReportTT) => {
    setReportDetailFilterLabel(row ? `Этап: ${row.name}` : '')
    setIsShowClosedIncidents(false)
    setIsShowOpenedIncidents(false)
    fetchData({
      url: getOpenedIncidentsApiRoute(row?.code),
      setData: (res) => {
        setReportDetailOpenTT(res)
        setIsShowOpenedIncidents(true)
      },
      setIsLoading,
      errorHandler: (err) => errorHandle(err),
    })
  }
  const showClosedIncidents = (row?: IReportTT) => {
    setReportDetailFilterLabel(row ? `Этап: ${row.name}` : '')
    setIsShowOpenedIncidents(false)
    setIsShowClosedIncidents(false)
    fetchData({
      url: getClosedIncidentsApiRoute(row?.code),
      setData: (res) => {
        setReportDetailCloseTT(res)
        setIsShowClosedIncidents(true)
      },
      setIsLoading,
      errorHandler: (err) => errorHandle(err),
    })
  }
  
  const rows = report.slice(0)
  const totalRow = rows.pop()
  
  return (
    <ReportWrapper
      code='incidentsByStages'
      dataRange
      {...{ report, setReport, setEndDate, setStartDate, startDate, endDate, setIsShowClosedIncidents, setIsShowOpenedIncidents }}
    >
      <table className="table table-bordered table-sm mt-4 w-auto" >
        <thead className="text-muted" style={{
          backgroundColor: colorsTable.main, position: 'sticky', top: '176px', zIndex: '1'
        }}>
          <tr>
            <th>Этап ТТ</th>
            <th>Кол-во закрытых ТТ<br />попадавших на этап</th>
            <th>Среднее время<br />на этапе, ч</th>
            <th>Кол-во<br />открытых ТТ</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) =>
            <tr key={index}>
              <td>{row.name}</td>
              <td className='text-end'>
                <Dropdown
                  exportApiRoute={getClosedIncidentsApiRoute(row.code, 'xlsx')}
                  handleClick={() => showClosedIncidents(row)}
                  data={row.incidents_closed_count}
                />
              </td>
              <td className='text-end'>{row.average_time_hours_count}</td>
              <td className='text-end'>
                <Dropdown
                  exportApiRoute={getOpenedIncidentsApiRoute(row.code, 'xlsx')}
                  handleClick={() => showOpenedIncidents(row)}
                  data={row.incidents_open_count}
                />
              </td>
            </tr>,
          )}
        </tbody>
        {totalRow &&
          <tfoot style={{ backgroundColor: colorsTable.main }}>
            <tr>
              <td>
                {totalRow.name}
              </td>
              <td className='text-end'>
                <Dropdown
                  exportApiRoute={getClosedIncidentsApiRoute('', 'xlsx')}
                  handleClick={() => showClosedIncidents()}
                  data={totalRow.incidents_closed_count}
                />
              </td>
              <td>
                &nbsp;
              </td>
              <td className='text-end'>
                <Dropdown
                  exportApiRoute={getOpenedIncidentsApiRoute('', 'xlsx')}
                  handleClick={() => showOpenedIncidents()}
                  data={totalRow.incidents_open_count}
                />
              </td>
            </tr>
          </tfoot>
        }
      </table >
      {isLoading && <Loader />}
      {isShowOpenedIncidents &&
        <SubReportOpenIncidents
          filterLabel={reportDetailFilterLabel}
          {...{ reportDetailOpenTT, startDate, endDate }}
        />
      }
      {isShowClosedIncidents &&
        <SubReportClosedIncidents
          filterLabel={reportDetailFilterLabel}
          {...{ reportDetailCloseTT, startDate, endDate }}
        />
      }
    </ReportWrapper >
  )
}

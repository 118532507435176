import { btnClasses, getBalloonFields } from '@/components/ProjectsContent/monitoring/map/config/cctvBalloon'
import { HardwareTypeCode } from '@/config/hardware'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'
import { generateCctvHardwareList } from '@/utils/hardware'
import { getIcon } from '@/components/ProjectsContent/monitoring/map/config/icons'
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HardwareStateCode } from '@/context/types'
import { PropsWithChildren } from 'react'


type Props = {
  cctv: MonitoringMapCctv
}

const BalloonContent = ({ cctv }: Props) =>
  <>
    <Block>
      {getBalloonFields(cctv).map((field, i) =>
        <div key={i} className='d-grid' style={{ gridTemplateColumns: '15px auto' }}>
          <div className='mx-auto' style={{ inset: 0 }}>{field.icon}</div>
          <div className='ms-2'>{field.value}</div>
        </div>,
      )}
    </Block>
    {[...generateCctvHardwareList(cctv)].map(hardware =>
      <Block key={hardware.id}>
        <div className='d-flex justify-content-between'>
          <div>
            {getIcon(hardware.type, hardware.state)}&nbsp;
            {hardware.type === HardwareTypeCode.AccessPoint ? hardware.macAddress : hardware.ipAddress}
          </div>
          {hardware.type === HardwareTypeCode.IpCamera &&
            <div>
              <a title='RTSP' className={`mx-1 ${btnClasses.rtspBtn}`} role='button'>
                <FontAwesomeIcon icon={faVideo} />
              </a>
              <a
                title='Снимок с камеры'
                className={`mx-1 ${btnClasses.ipCamera}`}
                role='button'
                data-snapshot-path={hardware.snapshotPath}
              >
                <FontAwesomeIcon icon={faCamera} />
              </a>
            </div>
          }
        </div>
        {hardware.incidents.map(incident =>
          <div key={incident.id}>
            {getIcon('incident', HardwareStateCode.unavailable)}
            <a
              className={`${btnClasses.incident} mx-1`}
              data-id={incident.id}
              href='#'
            >
              №&nbsp;{incident.id}
            </a>
            от {incident.openDatetime.toLocaleString()}, <span className='text-secondary'>{incident.description}</span>
          </div>,
        )}
      </Block>,
    )}
  </>

const Block = ({ children }: PropsWithChildren<{}>) =>
  <div className='border border-secondary rounded p-1 mb-2 text-break'>{children}</div>

export default BalloonContent

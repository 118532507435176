import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'
import {
  videoServerStreamLeftTableRows, videoServerStreamRightTableRows,
} from '@/config/tables/cards/hardware/cctvStream/videoServer'
import { IVideoServer } from '@/context/types'
import ParamTable from '@/components/common/cards/ParamTable'
import HardwareCardParamTableRows from '@/components/common/cards/hardware/HardwareCardParamTableRows'


type Props = {
  videoServer: IVideoServer
  reloadCctv: () => Promise<void>
}

export const VideoServerShortCctv = ({ videoServer, reloadCctv }: Props) => {
  return (
    <div className='card border-0 mb-2'>
      <div
        className={`card-header border-0 ${cn(
          style.cardHeader,
          style.cardHeaderVideoServer,
        )} px-2 py-1`}
      >
        <div className='col'>
          <b>
            {videoServer?.type.name} {videoServer?.code}
          </b>
        </div>
      </div>

      <div className={`card-body p-1 ps-0 ${cn(style.cardBody, style.cardBodyVideoServer)}`}>
        <div className='row mb-2'>
          <div className='col-lg'>
            <ParamTable>
              <HardwareCardParamTableRows
                rows={videoServerStreamLeftTableRows}
                hardware={videoServer}
                reloadCard={reloadCctv}
              />
            </ParamTable>
          </div>
          <div className='col-lg'>
            <ParamTable>
              <HardwareCardParamTableRows
                rows={videoServerStreamRightTableRows}
                hardware={videoServer}
                reloadCard={reloadCctv}
              />
            </ParamTable>
          </div>
        </div>
      </div>
    </div>
  )
}

import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{
	header: string
}>


/** Карточка дашборда проекта */
export default function Card({ header, children }: Props) {
	return (
		<div className='card'>
			<div className='card-header bg-primary bg-opacity-10 px-2 py-1'>
				<div className='text-nowrap overflow-hidden'>
					{header}
				</div>
			</div>
			<div className='card-body px-0 py-1'>
				<div className='overflow-auto px-2' style={{ maxHeight: '160px' }}>
					{children}
				</div>
			</div>
		</div>
	)
}
import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportICA } from '@/components/reports/types'
import { ipCamerasAcceptance } from '@/components/reports/config/reportICA'
import { useParams } from 'react-router-dom'


export const ReportIpCamerasAcceptance = () => {
  const { projectId } = useParams()
  const [report, setReport] = useState<IReportICA[]>([])

  return (
    <ReportWrapper
      code='ipCamerasAcceptance'
      {...{ report, setReport }}
    >
      <table className='table table-bordered table-sm mt-4'>
        <thead
          className='text-muted'
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {!projectId && <th>Проект</th>}
            {ipCamerasAcceptance.map(column => <th key={column.label}>{column.label}</th>)}
          </tr>
        </thead>
        <tbody>
          {report.map(row => (
            <tr key={row.code_on_cctv}>
              {!projectId && <td>{row.project_name}</td>}
              {ipCamerasAcceptance.map(column => <td key={column.label}>{column.value(row)}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </ReportWrapper>
  )
}

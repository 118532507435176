import { IReportICA, RecordTableColumnConfig } from '@/components/reports/types'


export const ipCamerasAcceptance: RecordTableColumnConfig<IReportICA>[] = [
  {
    label: 'Имя камеры',
    value: reportRow => reportRow.code
  },
  {
    label: 'IP адрес камеры',
    value: reportRow => reportRow.ip_address
  },
  {
    label: 'Модель камеры',
    value: reportRow => reportRow.model
  },
  {
    label: 'Имя IP камеры в пределах ТВН',
    value: reportRow => reportRow.code_on_cctv
  },
  {
    label: 'Номер камеры',
    value: reportRow => reportRow.order_number_on_cctv
  },
  {
    label: 'Звук на камере',
    value: reportRow => convertBoolean(reportRow.has_sound_enabled)
  },
  {
    label: 'Статус камеры',
    value: reportRow => reportRow.status
  },
  {
    label: 'Месторасположение камеры изменено',
    value: reportRow => convertBoolean(reportRow.has_camera_location_changed)
  },
  {
    label: 'Ракурс камеры изменён',
    value: reportRow => convertBoolean(reportRow.has_camera_angle_changed)
  },
  {
    label: 'Описание месторасположения',
    value: reportRow => reportRow.location_description
  },
]

const convertBoolean = (item: boolean) => (item ? 'Да' : 'Нет')

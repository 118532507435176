import { PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '@/components/common/Footer'
import UserNav from '@/components/nav/UserNav'


type Props = PropsWithChildren<{}>

export default function UserPageContainer({ children }: Props) {

	return (
		<>
			<UserNav />
			{children ?? <Outlet />}
			<Footer />
		</>
	)
}
import { ISelectOption, IOrganization } from '@/context/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import recordNumberDeclension from '@/utils/recordNumberDecletion'
import { useModal } from '@/context/ModalContext'
import { AnyTableApiResourceContextValue } from '@/context/tableApiResource/TableApiResourceContext'
import Select, { SingleValue } from 'react-select'
import { defaultFilterOption, organizationToSelectOption } from '@/utils/recordToSelectOption'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import useOuterClick from '@/hooks/useOuterClick'
import UpdateAuditUserModalContent, {
  Props as UpdateAuditUserModalContentProps,
} from '@/components/modals/common/UpdateAuditUserModalContent'


type ModalProps = Pick<UpdateAuditUserModalContentProps, 'saveFunction' | 'selectedRecordIdSet'>

type Props = ModalProps & {
  label: string
  deselectAllRecords: AnyTableApiResourceContextValue['deselectAllRecords']
  loadRecordList: AnyTableApiResourceContextValue['loadRecordList']
  hasEditPermission: boolean
}

type IOrganizationSelectOption = ISelectOption<IOrganization['id']>

const PecsTableOrganizationMenu = ({
  label,
  saveFunction,
  selectedRecordIdSet,
  deselectAllRecords,
  loadRecordList,
  hasEditPermission,
}: Props) => {
  const { showModalDialog } = useModal()
  const popoverRef = useRef(null as HTMLDivElement | null)
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState([] as IOrganizationSelectOption[])
  const [selectedOption, setSelectedOption] = useState(null as SingleValue<IOrganizationSelectOption>)

  const close = useCallback(() => setIsOpen(false), [setIsOpen])
  useOuterClick(popoverRef, close)

  useEffect(() => {
    
    axios.get<IOrganization[]>(apiRoutes.organizations, {
      params: {
        pagination: false,
        groups: ['list_short:read'],
      },
    })
      .then(res => setOptions(res.data.map(organizationToSelectOption) as IOrganizationSelectOption[]))
  }, [setOptions])

  useEffect(() => {
    !isOpen && setSelectedOption(null)
  }, [isOpen])

  const onDone = useCallback(() => {
    deselectAllRecords()
    loadRecordList()
  }, [deselectAllRecords, loadRecordList])

  const handleSetStatus = useCallback(async () => {
    showModalDialog({
      type: 'okCancel',
      header: label,
      content: `Вы уверены, что хотите изменить ${recordNumberDeclension(selectedRecordIdSet.size)} на значение «${selectedOption!.label}»?`,
      primaryCallback: () => showModalDialog({
        type: 'abort',
        header: `${label}: ${selectedOption!.label}`,
        content: <UpdateAuditUserModalContent
          label={label}
          saveFunction={saveFunction}
          selectedRecordIdSet={selectedRecordIdSet}
          value={selectedOption!.value}
          onDone={onDone}
        />,
      }),
    })
  }, [showModalDialog, saveFunction, selectedRecordIdSet, selectedOption, onDone])

  if (!selectedRecordIdSet.size || !hasEditPermission)
    return <>{label}</>


  return (
    <div className='dropdown' style={{ 'fontWeight': 400 }}>
      <button className='btn btn-link p-0 text-start fw-bold text-decoration-none' onClick={() => setIsOpen(!isOpen)}>
        {label} <FontAwesomeIcon icon={faCaretDown} />
      </button>
      <div className={`dropdown-menu px-2 ${isOpen ? 'show' : ''}`} style={{ width: 300 }} ref={popoverRef}>
        <Select<IOrganizationSelectOption>
          placeholder='Выберите организацию'
          options={options}
          filterOption={defaultFilterOption}
          isClearable={true}
          isDisabled={false}
          onChange={option => setSelectedOption(option)}
          value={selectedOption}
        />
        <button
          className='btn btn-outline-primary float-end mt-2'
          type='button'
          disabled={!selectedOption}
          onClick={handleSetStatus}
        >
          ОК
        </button>
      </div>
    </div>
  )
}

export default PecsTableOrganizationMenu

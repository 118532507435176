import { MouseEventHandler } from 'react'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


type Props = {
	label: string
	icon?: IconDefinition
	disabled?: boolean
	onClick?: MouseEventHandler<HTMLButtonElement>
}


/** Элемент выпадающего меню. Кнопка */
export default function ToolbarMenuButton({ label, icon, onClick, disabled = false }: Props) {
	return (
		<li>
			<button
				className={`dropdown-item ${disabled && 'disabled'}`}
				disabled={disabled}
				onClick={onClick}
			>
				{icon &&
					<div className='d-inline-block me-2' style={{ width: '16px' }}>
						<FontAwesomeIcon icon={icon} />
					</div>
				}
				{label}
			</button>
		</li>
	)
}
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { IBaseHardware } from '@/context/types'
import { hardwareApiResources } from '@/config/hardware'
import { getApiResourceDetailsPath } from '@/config/routes'
import { UserRole } from '@/config/roles'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = PropsWithChildren<{
  hardware: IBaseHardware
}>


export default function HardwareLink({ hardware, children }: Props) {
  const { hasRole } = useAuthContext()

  if (!hasRole([UserRole.admin, UserRole.pm]))
    return <>{children ?? (hardware.ipAddress || 'н/д')}</>

  return (
    <Link
      to={getApiResourceDetailsPath(hardwareApiResources[hardware.type.code], hardware.id)}
      target='_blank'>
      {children ?? (hardware.ipAddress || 'н/д')}
    </Link>
  )
}
import { AxiosError, AxiosResponse } from 'axios'
import { instanceAxios as axios } from '@/api/instanceAxios'


interface IPostData {
  url: string
  errorHandler?: (arg1: AxiosError) => void
  successAction?: (arg1: AxiosResponse) => void
  data?: any
  config?: any
  setIsLoading?: (arg1: boolean) => void
  axiosMethod?: 'post' | 'postForm' | 'delete' | 'patch'
}

export const postData = ({
  url,
  data = {},
  config = {},
  setIsLoading = () => null,
  errorHandler = () => null,
  successAction = () => null,
  axiosMethod = 'post',
}: IPostData) => {
  setIsLoading(true)

  axios[axiosMethod](url, data, config)
    .then((data) => {
      successAction(data)
    })
    .catch((err) => {
      errorHandler(err)
    })
    .finally(() => setIsLoading(false))
}

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'


type DefaultValueType<ValueType> = ValueType | (() => ValueType)


export function useLocalStorage<ValueType>(key: string, defaultValue: DefaultValueType<ValueType>) {
  return useStorage(window.localStorage, key, defaultValue)
}

export function useSessionStorage<ValueType>(key: string, defaultValue: DefaultValueType<ValueType>) {
  return useStorage(window.sessionStorage, key, defaultValue)
}

function useStorage<ValueType>(storageObject: Storage, key: string, defaultValue: DefaultValueType<ValueType>): [ValueType, Dispatch<SetStateAction<ValueType>>, () => void] {
  const [value, setValue] = useState<ValueType>(() => {
    const jsonValue = storageObject.getItem(key)
    if (jsonValue != null) return JSON.parse(jsonValue)

    if (defaultValue instanceof Function) {
      return defaultValue()
    } else {
      return defaultValue
    }
  })

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key)
    storageObject.setItem(key, JSON.stringify(value))
  }, [key, value, storageObject])

  const remove = useCallback(() => {
    setValue(undefined!)
  }, [])

  return [value, setValue, remove]
}
import { IBaseHardware, ICctv, IPec } from '@/context/types'
import { ShowModalDialogParams } from '@/context/ModalContext'
import { AnyFormContextProvider } from '@/context/anyForm/AnyFormContext'
import { FormContextProvider } from '@/context/form/FormContext'
import { ProjectFieldListContextProvider } from '@/context/entityFields/ProjectFieldListContext'
import CctvCreateEditForm, { Props as CctvCreateEditFormProps } from '@/components/modals/cctv/CctvCreateEditForm'
import AttachedImagesGallery from '@/components/modals/cctv/AttachedImagesGallery'
import { changeItemHardwareBinding } from '@/api/queries/hardware'
import { getItemLabel } from '@/utils/tools'


export const createEditCctvInfoModal = (
  projectId: string,
  onSave: CctvCreateEditFormProps['onSave'],
  cctv?: ICctv
): ShowModalDialogParams => ({
  type: 'saveCancel',
  dismissable: false,
  size: 'lg',
  header: cctv ? `Редактирование информации ТВН-${cctv.number}` : `Создание ТВН`,
  content:
    <ProjectFieldListContextProvider projectId={projectId}>
      <FormContextProvider>
        <AnyFormContextProvider>
          <CctvCreateEditForm projectId={projectId} cctv={cctv} onSave={onSave} />
        </AnyFormContextProvider>
      </FormContextProvider>
    </ProjectFieldListContextProvider>,
})

export const unbindItemHardwareModal = (
  item: ICctv | IPec,
  hardware: IBaseHardware,
  reloadCard: () => Promise<void>,
): ShowModalDialogParams => ({
  type: 'deleteCancel',
  header: `Удаление оборудования из ${getItemLabel(item)}`,
  content: `Вы уверены, что хотите отвязать оборудование ${hardware.code} от ${getItemLabel(item)} ${item.number}?`,
  primaryCallback: async () => {
    await changeItemHardwareBinding(item, { unbind: [hardware.id] })
    reloadCard()
  },
})

export const imagesGalleryModal = <T extends ICctv | IPec, F extends T['files'][0]>(
  item: T,
  imageFiles: F[],
  initialFile: F,
  canDelete: boolean,
  reloadCard: () => any
): ShowModalDialogParams | false => {
  const initialIndex = imageFiles.indexOf(initialFile)
  if (!~initialIndex)
    return false

  return {
    size: 'lg',
    type: 'close',
    header: `Изображения ${getItemLabel(item)}-${item.number}`,
    content: <AttachedImagesGallery
      imageFiles={imageFiles}
      initialIndex={initialIndex}
      canDelete={canDelete}
      reloadCard={reloadCard}
    />,
  }
}

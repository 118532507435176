import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{
	label: string
}>

/** Выпадающее меню */
export default function ToolbarMenu({ label, children }: Props) {
	return (
		<div className='btn-group'>
			<button className='btn btn-sm btn-outline-primary dropdown-toggle' data-bs-toggle="dropdown">
				{label}
			</button>
			<ul className='dropdown-menu'>
				{children}
			</ul>
		</div>
	)
}

import { ICctv, TableColumnConfig } from '@/context/types'
import { ProjectsCctvTableColumnNameList, ProjectsCctvTableColumns } from '@/config/tables/lists/projects/projectsCctv'
import { NavLink } from 'react-router-dom'
import { routes } from '@/config/routes'


export const ExploitationCctvTableColumnNameList = [
  'project',
  ...ProjectsCctvTableColumnNameList,
] as const

export type ExploitationCctvTableColumnName = typeof ExploitationCctvTableColumnNameList[number]

export const ExploitationCctvTableColumns: Record<ExploitationCctvTableColumnName, TableColumnConfig<ICctv>> = {
  project: {
    label: 'Проект',
    value: cctv => cctv.project.name,
    filterKey: 'project.name',
    sortKey: 'project.name',
  },
  ...ProjectsCctvTableColumns,
  number: {
    ...ProjectsCctvTableColumns.number,
    value: cctv => <NavLink to={routes.exploitationCctv(cctv.id)}>{cctv.number}</NavLink>,
  }
}

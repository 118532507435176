import { useState } from 'react'
import Loader from '@/components/common/Loader'
import { createNumbersArray } from '@/utils/array'
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generateDaysRange, getEndOfDay, isSameDay, millisecondsToDateString } from '@/utils/date'
import { useQuery } from '@tanstack/react-query'
import { fetchHardwareLog } from '@/api/queries'
import { HardwareStateCode, IHardwareStateLog } from '@/context/types'
import Duration from '@/components/common/time/Duration'
import { round } from '@/utils/number'


const graphColors: Record<string, string> = {
  [HardwareStateCode.unavailable]: '#c92a2a',
  [HardwareStateCode.available]: '#b2f2bb',
  unknown: '#ffc107',
  [HardwareStateCode.none]: '#868e96',
}

const tableBgColorValueNames: Record<string, string> = {
  [HardwareStateCode.unavailable]: `danger`,
  [HardwareStateCode.available]: `success`,
  unknown: `warning`, //todo убрать костыль когда будет решаться задача со статусом unknown оборудования связи
  [HardwareStateCode.none]: `secondary`,
}

type Props = {
  hardwareId: number
}

type LogRow = IHardwareStateLog & {
  duration: number
}

const addDuration = (logsMap: Map<string, LogRow[]>, limitToLastDay = false) => {
  const logs = [...logsMap.values()].flat()

  for (let i = 0; i < logs.length; i++) {
    const log = logs[i]!
    const nextLog = logs[i + 1]

    let datetimeTo: number
    if (nextLog)
      datetimeTo = +nextLog.datetime
    else if (limitToLastDay)
      datetimeTo = Math.min(+getEndOfDay(log.datetime), +new Date)
    else
      datetimeTo = +new Date

    log.duration = datetimeTo - +log.datetime
  }
}

const addLog = (logsMap: Map<string, LogRow[]>, key: string, log: LogRow) => {
  const logs = logsMap.get(key)

  if (logs)
    logs.push(log)
  else
    logsMap.set(key, [log])
}

const formatNumber = (num: number) => ('' + num).padStart(2, '0')

export const HardwareLog = ({ hardwareId }: Props) => {
  const { isFetching: isLoading, data: logs } = useQuery({
    queryKey: ['hardware_log', hardwareId],
    queryFn: () => fetchHardwareLog(hardwareId),
    initialData: [],
  })

  const [activeMonth, setActiveMonth] = useState('')

  const logByMonth = new Map<string, LogRow[]>()
  const logsByDay = new Map<string, LogRow[]>()

  for (let i = 0; i < logs.length; i++) {
    const log = logs[i]!
    const nextLog = logs[i + 1]

    addLog(logByMonth, log.datetime.toLocaleDateString('ru').slice(3), { ...log, duration: 0 })

    for (const dayDate of generateDaysRange(log.datetime, nextLog?.datetime ?? log.datetime))
      addLog(logsByDay, dayDate.toLocaleDateString('ru'), {
        status: log.status,
        duration: 0,
        datetime: isSameDay(dayDate, log.datetime) ? log.datetime : dayDate,
      })
  }


  addDuration(logByMonth)
  addDuration(logsByDay, true)

  if (logByMonth.size && !activeMonth)
    setActiveMonth([...logByMonth.keys()].at(-1)!)

  const tabLogs = logByMonth.get(activeMonth)

  if (isLoading)
    return <Loader />

  return (
    <>
      <div className='wrapper text-center'>
        <div className='btn-group btn-group-sm mb-2'>
          {[...logByMonth.keys()].map(label =>
            <button
              key={label}
              onClick={() => setActiveMonth(label)}
              type='button'
              className={`btn btn-outline-secondary ${activeMonth === label ? 'active' : ''}`}
            >
              {label}
            </button>,
          )}
        </div>
      </div>
      <table
        className='mb-4 mx-auto border border-secondary'
        style={{ maxWidth: 670, width: '100%', borderCollapse: 'collapse', boxSizing: 'content-box' }}
      >
        <thead>
          <tr className='align-top' style={{ backgroundColor: '#e9ecef' }}>
            <th className='text-end border border-secondary py-0 ps-0 pe-1' style={{ width: 60 }}>
              <div>
                Час <FontAwesomeIcon icon={faArrowRight} />
              </div>
              <div>
                День <FontAwesomeIcon icon={faArrowDown} />
              </div>
            </th>
            {[...new Array(24)].map((_, hour) =>
              <th key={hour} className='border border-secondary text-center p-0'>
                {formatNumber(hour)}
              </th>,
            )}
          </tr>
        </thead>
        <tbody>
          {!!tabLogs?.length && createNumbersArray(tabLogs.at(-1)!.datetime.getDate()).map(day =>
            <tr key={day}>
              <td
                className='text-end border-bottom border-end border-secondary '
                style={{
                  lineHeight: '10px',
                  padding: '2px 5px 2px 0',
                  backgroundColor: '#e9ecef',
                  fontSize: 12,
                }}
              >
                <b>{formatNumber(day)}</b>
              </td>
              <td colSpan={24}>
                <div className='d-flex'>
                  {!!logsByDay.size && logsByDay.get(`${formatNumber(day)}.${activeMonth}`)!.map(log =>
                    <div
                      key={+log.datetime}
                      className='p-0 border-0'
                      style={{
                        width: `${round(100 / 86400000 * log.duration, 5)}%`,
                        height: 10,
                        backgroundColor: graphColors[log.status.code],
                      }}
                    />,
                  )}
                </div>
              </td>
            </tr>,
          )}
        </tbody>
      </table>
      <table className='table table-bordered table-sm mx-auto' style={{ maxWidth: 670 }}>
        <thead>
          <tr className='text-muted' style={{ backgroundColor: '#e9ecef' }}>
            <th className='w-25'>Начало</th>
            <th className='w-25'>Длительность</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {!!tabLogs?.length
            ? tabLogs.map(log =>
              <tr
                key={+log.datetime}
                className={`bg-${tableBgColorValueNames[log.status.code]} bg-opacity-10`}
              >
                <td>
                  {log.datetime.toLocaleString('ru')}
                </td>
                <td>
                  {log.duration
                    ? millisecondsToDateString(log.duration)
                    : <Duration from={log.datetime} />
                  }
                </td>
                <td>{log.status.name}</td>
              </tr>,
            )
            : <tr>
              <td colSpan={3} className='text-center'>
                Нет данных
              </td>
            </tr>
          }
        </tbody>
      </table>
    </>
  )
}

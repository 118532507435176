import { useParams } from 'react-router-dom'
import { ApiResourceContextProvider } from '@/context/apiResource/ApiResourceContext'
import { ApiResource } from '@/context/types'
import Error404PageContent from '@/components/common/Error404PageContent'


/** Контейнер каталога */
export default function CatalogPageContainer() {
	const params = useParams()
	const apiResource = params.apiResource as ApiResource

	if (!Object.values(ApiResource).includes(apiResource))
		return <Error404PageContent />

	return <ApiResourceContextProvider key={apiResource} />
}
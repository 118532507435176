import { memo, useMemo } from 'react'
import CustomControl from '@/components/ProjectsContent/monitoring/map/controls/CustomControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { HardwareStateCode } from '@/context/types'
import { getIndicators } from '@/components/ProjectsContent/monitoring/map/config/indicators'
import Loader from '@/components/common/Loader'
import { CctvHardwareKey, cctvHardwareKeys } from '@/config/hardware'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'


type Props = YControlOptions & {
  isLoading: boolean
  cctvs: MonitoringMapCctv[]
  filteredCctvs: MonitoringMapCctv[]
  onReloadClick: () => any
}

const IndicatorsControl = memo(({
  float,
  floatIndex,
  isLoading,
  cctvs,
  filteredCctvs,
  onReloadClick,
}: Props) => {
  const projectHardwareIndicators = useMemo(() => (
    cctvHardwareKeys.reduce((acc, hKey) => {
      cctvs.some(cctv => cctv[hKey].length) && acc.push(hKey)
      return acc
    }, [] as CctvHardwareKey[])
  ), [cctvs])

  const indicators = useMemo(() => {
    const indicators = getIndicators(projectHardwareIndicators)

    indicators.cctvs.count = filteredCctvs.length

    for (const cctv of filteredCctvs) {
      for (const hardwareIndicator of projectHardwareIndicators)
        indicators[hardwareIndicator].count += cctv[hardwareIndicator].length

      switch (cctv.hardwareState) {
        case HardwareStateCode.unavailable:
          indicators.hardwareUnavailable.count++
          break
        case HardwareStateCode.partially:
          indicators.hardwarePartially.count++
          break
        case HardwareStateCode.available:
          indicators.hardwareAvailable.count++
          break
        case HardwareStateCode.none:
          indicators.hardwareNone.count++
          break
      }
    }

    return indicators
  }, [filteredCctvs, projectHardwareIndicators])

  return (
    <CustomControl {...{ float, floatIndex }}>
      <div className='d-flex align-items-center gap-3 px-2' style={{ opacity: isLoading ? .5 : 1 }}>
        {isLoading
          ? <Loader size={20} />
          : <FontAwesomeIcon
            icon={faRedo}
            title='Обновить карту'
            className='fs-5'
            role='button'
            onClick={onReloadClick}
          />
        }
        {Object.entries(indicators).map(([k, { icon, title, count }]) =>
          <div key={k} className='d-flex align-items-center gap-2 border border-secondary rounded px-2' title={title}>
            {icon} {count}
          </div>,
        )}
      </div>
    </CustomControl>
  )
})

export default IndicatorsControl

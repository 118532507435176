import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchProjectFields, postProjectFields } from '@/api/queries/projects'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'
import { IProject, IProjectField, ISelectOption } from '@/context/types'
import defaultRecordToOption, { defaultFilterOption } from '@/utils/recordToSelectOption'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import Loader from '@/components/common/Loader'


type Row = {
  projectField: ISelectOption | null
  sort: number;
  onlyForSitronics: boolean
}

type Props = {
  projectId: IProject['id'];
}

export const ProjectFieldsModal = ({ projectId }: Props) => {
  const { usePrimaryCallback, showModalDialog } = useModal()
  const { isFetching: isLoading, data: projectFields } = useQuery({
    queryKey: ['project_fields'],
    queryFn: fetchProjectFields,
  })
  const [rows, setRows] = useState([] as Row[])

  const handleAddField = () => setRows([...rows, { projectField: null, sort: 0, onlyForSitronics: false }])

  const { mutate } = useMutation({
    mutationFn: () => postProjectFields(
      projectId,
      rows
        .filter(row => row.projectField)
        .map(({ projectField, sort, onlyForSitronics }) => ({
          projectFieldId: projectField!.value,
          sort,
          onlyForSitronics,
        }))
    ),
    onError: async e => 
      showModalDialog({
        type: 'error',
        header: 'Ошибка',
        content: await getErrorMessage(e),
      })
  })

  usePrimaryCallback(mutate, [mutate])

  useEffect(() => {
    const getMapEntry = ({ projectsMapEntries: mes }: IProjectField) => mes.find(me => me.project.id === projectId)

    if (projectFields)
      setRows(
        projectFields
          .filter(getMapEntry)
          .map(projectField => {
            const { sort, onlyForSitronics } = getMapEntry(projectField)!

            return {
              projectField: defaultRecordToOption(projectField),
              sort,
              onlyForSitronics,
            }
          })
          .sort((a, b) => a.sort - b.sort)
      )
  }, [projectFields, projectId])

  const handleChange = (index: number, projectField: ISelectOption | null, sort: number, onlyForSitronics: boolean) => {
    const newFields = [...rows]
    newFields[index] = { projectField, sort, onlyForSitronics }
    setRows(newFields)
  }

  if (isLoading || !projectFields)
    return <Loader />

  return (
    <>
      {rows.map((row, index) =>
        <div
          className='d-grid align-items-center mb-2 gap-2'
          style={{ gridTemplateColumns: '350px auto auto 15px' }}
          key={index}
        >
          <Select
            placeholder='Поле проекта'
            options={
              projectFields
                .filter(projectField => !rows.some(row => row.projectField?.value === projectField.id))
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map(defaultRecordToOption)
            }
            filterOption={defaultFilterOption}
            isClearable={true}
            isDisabled={false}
            onChange={(option) => handleChange(index, option, row.sort, row.onlyForSitronics)}
            value={row.projectField}
          />
          <input
            className='form-control'
            type='number'
            placeholder='п/п'
            onChange={e => handleChange(index, row.projectField, +e.target.value, row.onlyForSitronics)}
            value={row.sort || ''}
          />
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              onChange={e => handleChange(index, row.projectField, row.sort, e.target.checked)}
              checked={row.onlyForSitronics}
            />
            <span>Только для "АО Ситроникс"</span>
          </div>
          <div className='text-danger'>
            <FontAwesomeIcon
              icon={faTrash}
              style={{ cursor: 'pointer' }}
              onClick={() => setRows(rows => rows.filter(r => r !== row))}
            />
          </div>
        </div>
      )}
      <div className='mt-4'>
        <button onClick={handleAddField} className='btn btn-sm btn-outline-primary w-25'>
          <FontAwesomeIcon icon={faPlus} /> Добавить
        </button>
      </div>
    </>
  )
}

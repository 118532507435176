import { IChannel, IChannelForm } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { useFormContext } from '@/context/form/FormContext'
import TextAreaInput from '@/components/common/form/TextAreaInput'


type Props = {
  onDone: (comment: IChannel['comment']) => any
}

const AddChannelCommentModalContent = ({ onDone }: Props) => {
  const { usePrimaryCallback } = useModal()
  const { form, setError } = useFormContext<IChannelForm>()

  usePrimaryCallback(async () => {
    if (!form.comment) {
      setError('comment', 'Введите комментарий')
      return false
    }

    onDone(form.comment)
  }, [onDone, form])

  return <TextAreaInput<IChannelForm> label='Комментарий' name='comment' required />
}

export default AddChannelCommentModalContent

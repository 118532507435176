import { useRef, useState } from 'react'
import { IStatusStage } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import useEffectOnce from '@/hooks/useEffectOnce'
import { useModal } from '@/context/ModalContext'
import ProgressBar from '@/components/common/ProgressBar'
import getErrorMessage from '@/utils/getErrorMessage'


export type Props = {
  recordApiRoute: (id: string) => string
	selectedRecordIdSet: Set<string>
	fieldKey: StatusKey
	status: IStatusStage
	onDone: () => void
}


export default function UpdateWorkStatusesModalContent({
  recordApiRoute,
  selectedRecordIdSet,
  fieldKey,
  status,
  onDone
}: Props) {
	const { updateState, usePrimaryCallback } = useModal()
	const [responseCount, setResponseCount] = useState(0)
	const [updatedCount, setUpdatedCount] = useState(0)
	const [errors, setErrors] = useState([] as string[])
	const [isDone, setIsDone] = useState(false)
	const abortFlagRef = useRef(false)

	useEffectOnce(() => {
		(async () => {
			for (const id of Array.from(selectedRecordIdSet)) {
				if (abortFlagRef.current) break
				try {
					await axios.patch(
						recordApiRoute(id),
						{ [fieldKey]: status.code },
						{ headers: { 'Content-Type': 'application/merge-patch+json' } },
					)
					setUpdatedCount(prev => prev + 1)
				} catch (e: any) {
          const error = await getErrorMessage(e)
          setErrors(errors => [...errors, error])
        } finally {
					setResponseCount(prev => prev + 1)
				}
			}
			setIsDone(true)
			updateState({ type: 'ok', dismissable: true })
			onDone()
		})()
	})

	usePrimaryCallback(() => {
		abortFlagRef.current = true
	}, [])

	return (
		<>
			<p>Обработано {responseCount} из {selectedRecordIdSet.size} записей.</p>
			<ProgressBar now={responseCount} max={selectedRecordIdSet.size} isAnimated={!isDone} />
			<p className='mt-3 mb-0'>Статус обновлен в {updatedCount} записей.</p>
      {!!errors.length &&
        <>
          <div>Ошибки:</div>
          {errors.map((error, i) => <div key={i}>{error}</div>)}
        </>
      }
		</>
	)
}
import { ReportCode } from '@/config/reports'
import {
  ReportIpCamerasAvailableByMaintenanceContractor,
} from '@/components/reports/ReportIpCamerasAvailableByMaintenanceContractor'
import { ReportIncidents } from '@/components/reports/ReportIncidents'
import { ReportIncidentsByStages } from '@/components/reports/ReportIncidentsByStages'
import { ReportCctvsStatusHistory } from '@/components/reports/ReportCctvsStatusHistory'
import {
  ReportIpCamerasAvailableByChannelOperators,
} from '@/components/reports/ReportIpCamerasAvailableByChannelOperators'
import { ReportCctvsAudit } from '@/components/reports/ReportCctvsAudit'
import { ReportHardwareRfc35962 } from '@/components/reports/ReportHardwareRfc35962'
import { ReportIpCamerasAcceptance } from '@/components/reports/ReportIpCamerasAcceptance'
import { ReportHardware } from '@/components/reports/ReportHardware'


type Props = {
  code: ReportCode
}

const Report = ({ code }: Props) => {
  switch (code) {
    case 'ipCamerasAvailableByMaintenanceContractor':
      return <ReportIpCamerasAvailableByMaintenanceContractor />
    case 'incidents':
      return <ReportIncidents />
    case 'incidentsByStages':
      return <ReportIncidentsByStages />
    case 'cctvsStatusHistory':
      return <ReportCctvsStatusHistory />
    case 'ipCamerasAvailableByChannelOperators':
      return <ReportIpCamerasAvailableByChannelOperators />
    case 'cctvsAudit':
      return <ReportCctvsAudit />
    case 'hardwareRfc35962':
      return <ReportHardwareRfc35962 />
    case 'ipCamerasAcceptance':
      return <ReportIpCamerasAcceptance />
    case 'hardware':
      return <ReportHardware />
  }
}

export default Report

import { ILogHistoryEntry } from '@/context/types'
import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'


type Props = {
  logHistoryEntries: ILogHistoryEntry[]
  label: string
}

const HistoryCard = ({ logHistoryEntries, label }: Props) => {
  return (
    <div className='card border-0 mb-2'>
      <div
        className={`card-header border-0 px-2 py-1 justify-content-between ${cn(
          style.cardHeader,
          style.cardHeaderHistory,
        )} `}
      >
        <div>
          <button
            className={`btn text-primary p-0 ${style.cardHeaderArrow}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseHistory'
            aria-expanded='false'
            aria-controls='collapseHistory'
          >
            <b>История изменений {label}</b>
          </button>
        </div>
      </div>
      <div className='collapse' id='collapseHistory'>
        <div
          className={`card-body border-0 ${cn(
            style.cardBody,
            style.cardBodyHistory,
            style.cctvInfoCollapse,
          )} p-0`}
        >
          <table className={`table-hover table-sm mb-0 ${style.table}`}>
            <thead>
              <tr className='text-muted '>
                {['Дата', 'Пользователь', 'Действие', 'Организация'].map(header => (
                  <th key={header} className='pr-2 fw-normal'>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='align-middle'>
              {logHistoryEntries.map((logHistoryEntry) => (
                <tr key={logHistoryEntry.id}>
                  <th className='pr-2 fw-normal text-nowrap'>{logHistoryEntry.createdDatetime.toLocaleString()}</th>
                  <th className='pr-2 fw-normal'>{logHistoryEntry.actor.name}</th>
                  <th className='pr-2 fw-normal'>
                    {logHistoryEntry.content}{logHistoryEntry.isImportUpdate && ' через кнопку "Массовое обновление"'}
                  </th>
                  <th className='pr-2 fw-normal'>{logHistoryEntry.actor.organization.name}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default HistoryCard

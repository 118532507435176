import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'


export interface Tab {
	label?: string
	icon?: IconDefinition
	path: string
}

interface TabsContextValue {
	tabList: Tab[]
	openTab: (tab: Tab) => void
	closeTab: (tabId: string, redirect?: string) => void
	closeCurrentTab: (redirect: string) => void
	setLabel: (label: string, icon?: IconDefinition) => void
}

const tabsContextInitValue: TabsContextValue = {
	tabList: [],
	openTab: () => { },
	closeTab: () => { },
	closeCurrentTab: () => { },
	setLabel: () => { }
}

const TabsContext = createContext(tabsContextInitValue)

export const useTabsContext = () => useContext(TabsContext)

type Props = PropsWithChildren<{}>

export function TabsContextProvider({ children }: Props) {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const [tabList, setTabList] = useState<Tab[]>([])

	/** Открыть вкладку path */
	const openTab = useCallback(({ path }: Tab) => {
		if (tabList.find(tab => tab.path === path)) return
		setTabList(prev => [...prev, { path }])
		if (path === pathname) return
		navigate(path)
	}, [pathname, tabList, navigate])

	/** Закрыть вкладку path */
	const closeTab = useCallback((path: string, redirect?: string) => {
		const tab = tabList.find(tab => tab.path === path)
		if (!tab) return
		const tabIndex = tabList.indexOf(tab)
		setTabList(prev => prev.filter(tab => tab.path !== path))
		if (path === pathname) {
      const redirectPath = tabList[tabIndex + 1]?.path ?? tabList[tabIndex - 1]?.path ?? redirect
      redirectPath && navigate(redirectPath)
    }
	}, [pathname, tabList, navigate])

	const closeCurrentTab = useCallback((redirect: string) => {
    closeTab(pathname, redirect)
	}, [closeTab, pathname])

	const setLabel = useCallback((label: string, icon?: IconDefinition) => {
		const tab = tabList.find(tab => tab.path === pathname)
		if (tab?.label === label && tab.icon === icon)
			return
		
    setTimeout(() => {
		  setTabList(prev => prev.map(tab => tab.path !== pathname ? tab : { ...tab, label, icon }))
    })
	}, [pathname, tabList])

	const value: TabsContextValue = useMemo(() => ({
		tabList,
		openTab,
		closeTab,
		closeCurrentTab,
		setLabel
	}), [tabList, openTab, closeTab, closeCurrentTab, setLabel])


	return (
		<TabsContext.Provider value={value}>
			{children ?? <Outlet />}
		</TabsContext.Provider>
	)
}
import { memo, useEffect, useMemo, useState } from 'react'
import { HardwareStateCode, IArea, ICctvType, IDistrict } from '@/context/types'
import CheckboxList from '@/components/ProjectsContent/monitoring/map/controls/filter/CheckboxList'
import { OptionsArray } from '@/components/ProjectsContent/monitoring/map/controls/filter/OptionsArray'
import ExpandableControl from '@/components/ProjectsContent/monitoring/map/controls/ExpandableControl'
import Btn from '@/components/ProjectsContent/monitoring/map/controls/common/Btn'
import useFunctionsArrayRef from '@/hooks/useFunctionsArrayRef'
import Checkbox from '@/components/bootstrap/Checkbox'
import usePrevValueCheck from '@/hooks/usePrevValueCheck'
import {
  cctvAggregateHardwareColorClasses, hardwareStateLabels,
} from '@/components/ProjectsContent/monitoring/map/config'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'


type Props = YControlOptions & {
  cctvs: MonitoringMapCctv[]
  onDisplayAreasChange: (areas: IArea['code'][]) => any
  onFilteredCctvsChange: (cctvs: MonitoringMapCctv[]) => any
}

const FilterControl = memo(({
  float,
  floatIndex,
  cctvs,
  onDisplayAreasChange,
  onFilteredCctvsChange,
}: Props) => {
  const [districtNameFilter, setDistrictNameFilter] = useState('')
  const [showAreaBorders, setShowAreaBorders] = useState(false)
  const [checkedAreas, setCheckedAreas] = useState([] as IArea['code'][])
  const [checkedDistricts, setCheckedDistricts] = useState([] as IDistrict['id'][])
  const [checkedTypes, setCheckedTypes] = useState([] as ICctvType['id'][])
  const [checkedHardwareStates, setCheckedHardwareStates] = useState([] as HardwareStateCode[])

  const isDisplayAreasChanged = usePrevValueCheck([] as IArea['code'][])
  const isFilteredCctvsChanged = usePrevValueCheck([] as MonitoringMapCctv[])

  const resetFunctionsRef = useFunctionsArrayRef()
  const closeFunctionsRef = useFunctionsArrayRef()
  const checkAllFunctionsRef = useFunctionsArrayRef()
  const checkboxListRefs = { resetFunctionsRef, closeFunctionsRef, checkAllFunctionsRef }

  const { areaOptions, typeOptions, stateOptions } = useMemo(() => {
    const areaOptions = new OptionsArray()
    const typeOptions = new OptionsArray()
    const stateOptions = new OptionsArray(
      ...Object.values(HardwareStateCode).map(state => ({
        id: state,
        name: hardwareStateLabels[state],
        className: cctvAggregateHardwareColorClasses[state],
      })),
    )

    for (const cctv of cctvs) {
      const { address: { district, area }, type } = cctv

      areaOptions.addOrUpdateOption({
        id: area.code,
        name: area.name,
      }, cctv)

      areaOptions.addOrUpdateSubOption(area.code, {
        id: district.id,
        name: district.name,
      }, cctv)

      typeOptions.addOrUpdateOption({
        id: type.code,
        name: type.name,
      }, cctv)

      stateOptions.updateOption(cctv.hardwareState, cctv)
    }

    return { areaOptions, typeOptions, stateOptions }
  }, [cctvs])

  useEffect(() => {
    const displayAreas = showAreaBorders ? checkedAreas : []

    if (isDisplayAreasChanged(displayAreas))
      onDisplayAreasChange(displayAreas)
  }, [showAreaBorders, checkedAreas, isDisplayAreasChanged, onDisplayAreasChange])

  useEffect(() => {
    const filteredCctvs = checkedDistricts.length
      ? cctvs.filter(cctv =>
        checkedDistricts.includes(cctv.address.district.id)
        && (!checkedTypes.length || checkedTypes.includes(cctv.type.code))
        && (!checkedHardwareStates.length || checkedHardwareStates.includes(cctv.hardwareState)),
      )
      : []

    if (isFilteredCctvsChanged(filteredCctvs))
      onFilteredCctvsChange(filteredCctvs)
  }, [cctvs, checkedDistricts, checkedTypes, checkedHardwareStates, isFilteredCctvsChanged, onFilteredCctvsChange])

  return (
    <ExpandableControl {...{ float, floatIndex }} label='Фильтры' title='Применить фильтр'>
      <div className='d-flex gap-1 py-2'>
        <Btn
          text='Сбросить фильтры'
          onClick={() => {
            setDistrictNameFilter('')
            resetFunctionsRef.callAll()
          }}
        />
        <Btn text='Свернуть фильтры' onClick={() => closeFunctionsRef.callAll()} />
        <Btn
          text='Загрузить весь проект'
          onClick={() => {
            setDistrictNameFilter('')
            checkAllFunctionsRef.callAll()
          }}
        />
      </div>
      <div className='py-2'>
        <input
          type='text'
          className='form-control'
          placeholder='Район'
          value={districtNameFilter}
          onChange={e => setDistrictNameFilter(e.target.value)}
        />
      </div>
      <div className='d-flex flex-column gap-1 pe-1' style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'scroll' }}>
        <div className='d-flex justify-content-between'>
          <CheckboxList
            label='Административное деление'
            options={areaOptions}
            onChange={setCheckedAreas}
            onSubOptionsChange={setCheckedDistricts}
            subOptionsFilter={districtNameFilter}
            {...checkboxListRefs}
          />
          <label className='mt-2'>
            <Checkbox onChange={e => setShowAreaBorders(e.target.checked)} /> Показывать границы АО
          </label>
        </div>
        <CheckboxList
          label='Тип ТВН'
          options={typeOptions}
          onChange={setCheckedTypes}
          {...checkboxListRefs}
        />
        <CheckboxList
          label='Статус ТВН'
          options={stateOptions}
          onChange={setCheckedHardwareStates}
          {...checkboxListRefs}
        />
      </div>
    </ExpandableControl>
  )
})

export default FilterControl

import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{
	height?: string
}>


/** Обертка сообщения об инциденте */
export default function IncidentMessage({ children, height = '144px' }: Props) {
	return (
		<div className='overflow-auto' style={{ whiteSpace: 'pre-wrap', maxHeight: height }}>
			{children}
		</div>
	)
}
import { CardTableRowConfig, IIPCamera } from '@/context/types'
import { hardwareCommonTableRows } from '@/config/tables/cards/hardware/common'
import HardwareCardVideoMarker from '@/components/common/cards/hardware/cells/HardwareCardVideoMarker'
import HardwareCardPreset from '@/components/common/cards/hardware/cells/HardwareCardPreset'


export const ipCameraTableRows: Record<string, CardTableRowConfig<IIPCamera>> = {
  ipAddress: hardwareCommonTableRows.ipAddress,
  subnetwork: hardwareCommonTableRows.subnetwork,
  model: hardwareCommonTableRows.model,
  preset: {
    label: 'Пресет',
    value: ipCamera => <HardwareCardPreset hardwarePreset={ipCamera.preset} />,
  },
  orderNumberOnCctv: {
    label: 'Номер камеры',
    value: ipCamera => ipCamera.orderNumberOnCctv,
  },
  hasSoundEnabled: {
    label: 'Звук на камере',
    value: ipCamera => ipCamera.hasSoundEnabled == null ? 'н/д' : ipCamera.hasSoundEnabled ? 'Есть' : 'Нет',
  },
  firmware: hardwareCommonTableRows.firmware,
  status: hardwareCommonTableRows.status,
  hasCameraLocationChanged: {
    label: 'Месторасположение камеры изменено',
    value: ipCamera => ipCamera.hasCameraLocationChanged ? 'Да' : 'Нет',
  },
  hasCameraAngleChanged: {
    label: 'Ракурс камеры изменён',
    value: ipCamera => ipCamera.hasCameraAngleChanged ? 'Да' : 'Нет',
  },
  id: hardwareCommonTableRows.id,
  videoMarkerCode: {
    label: 'Видеомаркер',
    value: (ipCamera, reloadItem) => <HardwareCardVideoMarker ipCamera={ipCamera} reloadItem={reloadItem} />,
  },
  storageIdOnProject: {
    label: 'БЭИ ID',
    value: ipCamera => ipCamera.storageIdOnProject
  },
  storageVsmKeyOnProject: {
    label: 'VSM KEY',
    value: ipCamera => ipCamera.storageVsmKeyOnProject
  },
  networkSwitch: hardwareCommonTableRows.networkSwitch,
  codeOnCctv: {
    label: 'Имя камеры (в пределах ТВН)',
    value: ipCamera => ipCamera.codeOnCctv
  },
  address: hardwareCommonTableRows.address,
  locationDescription: hardwareCommonTableRows.locationDescription,
  snapShotKeeper: {
    label: 'Эталонный снимок сохранил',
    value: ipCamera => ipCamera.snapShotKeeper,
  },
}

import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { apiRoutes, routes } from '@/config/routes'
import { useTabsContext } from '@/context/TabsContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import ChannelsNav from '@/components/channels/ChannelsNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import Loader from '@/components/common/Loader'
import { IChannelAddress } from '@/context/types'
import AddressInfoCard from '@/components/channels/Folder/AddressInfo/AddressInfoCard'
import { instanceAxios as axios } from '@/api/instanceAxios'
import ChannelInfoCard from '@/components/channels/Folder/ChannelInfo/ChannelInfoCard'
import { convertAddress } from '@/config/apiConverters'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'


export default function AddressPageContent() {
  useDocumentTitle('Таблица Адресов')
  const { addressId } = useParams()
  const { setLabel } = useTabsContext()
  const [address, setAddress] = useState({} as IChannelAddress)
  const [isLoading, setIsLoading] = useState(false)

  const reloadItem = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(apiRoutes.channelAddress(addressId!))
      const address: IChannelAddress = response.data

      setAddress(convertAddress(address))
      setLabel(`Адрес ${address.name}`)
    } catch (e: any) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [addressId])

  useEffect(() => {
    reloadItem()
  }, [addressId])

  if (isLoading || !address) return <div className='container-fluid pt-5'><Loader /></div>

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem>Адреса</BreadcrumbsItem>
        <BreadcrumbsItem path={routes.addresses}>Список всех адресов</BreadcrumbsItem>
        <BreadcrumbsItem>Адрес {address?.name}</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <ChannelsNav />
      {!address.id
        ? <div className='mt-5'><Loader /></div>
        : <>
          <div className='row g-2 p-3 w-100 m-0'>
            <div className='d-flex justify-content-end my-2'>
              <div className='btn-toolbar gap-2'>
                <ReloadBtn onClick={reloadItem} />
              </div>
            </div>
            <div className='col-xl'>
              <AddressInfoCard address={address} reloadCard={reloadItem} />
            </div>
            <div className='col-xl'>
              <div>
                {address.channels.map(channel =>
                  <div key={channel.id}>
                    <ChannelInfoCard reloadCard={reloadItem} channel={channel} showCloneBtn />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { IUser } from '@/context/types'
import parseResponse from '@/utils/parseResponse'
import { apiRoutes } from '@/config/routes'
import { fetchOptions } from '@/config/fetchOptions'
import { UserPermissions } from '@/config/userPermissions'
import { UserRole } from '@/config/roles'
import useEffectOnce from '@/hooks/useEffectOnce'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  AuthContextState, loadingAction, profileUpdatedAction, signedInAction, signedOutAction, useAuthReducer,
} from '@/context/auth/authStore'
import { arrayIntersect } from '@/utils/array'


type PermissionCode = keyof typeof UserPermissions

// Auth context value interface
export interface AuthContextValue extends AuthContextState {
  signIn: (login: string, password: string) => void
  signOut: () => void
  updateProfile: (profile: IUser) => void
  hasRole: (roles: UserRole | UserRole[] | readonly UserRole[]) => boolean
  hasPermission: (permission: PermissionCode | PermissionCode[]) => boolean
}

export type HasPermissionParam = Parameters<AuthContextValue['hasPermission']>[0]

// Auth context
const AuthContext = createContext({} as AuthContextValue)


// Auth context hook
export const useAuthContext = () => useContext(AuthContext)

type Props = PropsWithChildren<{}>

// Auth context Provider
export function AuthContextProvider({ children }: Props) {
  const [state, dispatch] = useAuthReducer()

  /** Инициализация */
  const initAuth = useCallback(async () => {
    dispatch(loadingAction())
    try {
      const response = await fetch(apiRoutes.auth.status, {
        ...fetchOptions,
        method: 'POST',
      })
      const result: IUser = await parseResponse(response, 'Аутентификация не удалась.')
      dispatch(signedInAction(result))
    } catch {
      dispatch(signedOutAction())
    }
  }, [dispatch])

  /** Вход */
  const signIn: AuthContextValue['signIn'] = useCallback(async (login, password) => {
    dispatch(loadingAction())
    try {
      const response = await fetch(apiRoutes.auth.signIn, {
        ...fetchOptions,
        method: 'POST',
        body: JSON.stringify({ login, password })
      })
      const result: IUser = await parseResponse(response, 'Аутентификация не удалась.')
      dispatch(signedInAction(result))
    } catch (e: any) {
      dispatch(signedOutAction())
      throw e
    }
  }, [dispatch])

  /** Выход */
  const signOut: AuthContextValue['signOut'] = useCallback(async () => {
    dispatch(loadingAction())
    try {
      await fetch(apiRoutes.auth.signOut, {
        ...fetchOptions,
        method: 'POST',
      })
    } catch { }
    dispatch(signedOutAction())
  }, [dispatch])

  /** Обновить профиль */
  const updateProfile: AuthContextValue['updateProfile'] = useCallback(async profile => {
    dispatch(loadingAction())
    setTimeout(() => {
      dispatch(profileUpdatedAction(profile))
    }, 1000)
  }, [dispatch])

  /** Проверка наличия у пользователя роли */
  const hasRole: AuthContextValue['hasRole'] = useCallback(roles => {
    const userRoles = state.user?.userRoles.map(r => r.code)
    if (!userRoles)
      return false

    return Array.isArray(roles)
      ? !!arrayIntersect(userRoles, roles).length
      : userRoles.includes(roles)
  }, [state.user])

  const hasPermission: AuthContextValue['hasPermission'] = useCallback(permissions => {
    if (!Array.isArray(permissions))
      permissions = [permissions]

    return hasRole([UserRole.admin, ...permissions.map(p => UserPermissions[p]).flat()])
  }, [state.user])

  // Инициализация
  useEffectOnce(() => {
    initAuth()
  })

  const value: AuthContextValue = useMemo(() => ({
    ...state,
    signIn,
    signOut,
    updateProfile,
    hasRole,
    hasPermission,
  }), [state, signIn, signOut, updateProfile, hasRole, hasPermission])

  useStorageStateLog('auth', state)

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

import { OrganizationRole, UserRole } from '@/config/roles'
import { IProject } from '@/context/types'
import { cDate } from '@/components/reports/common/ReportWrapper'


export const responsibleListFilter = {
  'userRoles.code': [UserRole.admin, UserRole.pm, UserRole.pm_cost, UserRole.pm_income, UserRole.pm_connection]
}
export const channelOperatorOrganizationListFilter = { 'roles.code': OrganizationRole.channelOperator }
export const contractorOrganizationListFilter = { 'roles.code': OrganizationRole.contractor }

export const getCctvsListFilter = (projectId?: IProject['id']): Record<string, string> =>
  projectId
    ? { 'project.id': projectId } // /projects
    : { 'project.archive': 'false' } // /exploitation

export const getReportFilter = (
  projectId: IProject['id'] | undefined,
  startDate?: Date,
  endDate?: Date,
): Record<string, string> => {
  const res: Record<string, string> = projectId
    ? { 'project_id': projectId } // /projects
    : { 'project_archive': 'false' } // /exploitation

  if (startDate && endDate) {
    res.begin_date = cDate(startDate)
    res.end_date = cDate(endDate)
  }

  return res
}

import { useState } from 'react'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { hardwareApiResources } from '@/config/hardware'
import { getApiResourceUpdateRoute } from '@/config/routes'
import { useModal } from '@/context/ModalContext'
import { useFormContext } from '@/context/form/FormContext'
import { ApiResource, Hardware, IVideoServerInfoForm } from '@/context/types'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'


type Props = {
  hardware: Hardware
  reloadCctv: () => Promise<void>
}


/** Форма редактирования статуса оборудования в ТВН */
export default function HardwareStatusForm({ hardware, reloadCctv }: Props) {
  const { form, setError } = useFormContext<IVideoServerInfoForm>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsloading] = useState(false)

  usePrimaryCallback(async () => {
    if (!form.status) setError('status', 'Обязательное поле')
    if (!form.status) return false
    setIsloading(true)
    try {
      await axios.patch(
        getApiResourceUpdateRoute(hardwareApiResources[hardware.type.code], hardware.id),
        form,
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCctv()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при сохранении статуса оборудования',
        content: await getErrorMessage(e),
      })
    }
    setIsloading(false)
  }, [form, showModalDialog])

  return (
    <div className='w-100 p-3'>
      <ApiResourceSelect
        apiResource={ApiResource.HardwareStatus}
        label='Статус'
        name='status'
        initValue={hardware.status}
        required
        disabled={isLoading} />
    </div>
  )
}
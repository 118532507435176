type BoolLabelsReadonlyMap = Omit<ReadonlyMap<null | boolean, string>, 'get'> & {
  get(key: null | boolean): string
}

const makeTypedMap = (entries: [[null, string], [true, string], [false, string]]) =>
  new Map(entries) as BoolLabelsReadonlyMap

export const organizationHasVatLabels = makeTypedMap([
  [null, ''],
  [true, 'С учётом НДС'],
  [false, 'НДС не облагается'],
])

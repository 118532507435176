import { useCallback } from 'react'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useModal } from '@/context/ModalContext'
import { IPec, IPecStatusField } from '@/context/types'
import { editPecWorkBeginDate } from '@/components/modals/pec/modals'
import { formatDate } from '@/utils/formatters'


type Props = {
  pec: IPec
  reloadCard: () => Promise<void>
  pecStatusFieldId: IPecStatusField['id']
}

const PecWorkBeginDate = ({ pec, reloadCard, pecStatusFieldId }: Props) => {
  const { showModalDialog } = useModal()

  const handleEditExecutor = useCallback(() => {
    showModalDialog(editPecWorkBeginDate(pec, pecStatusFieldId, reloadCard))
  }, [showModalDialog, pec, pecStatusFieldId, reloadCard])

  return (
    <div className='d-flex flex-row gap-2'>
      Дата начала работ:
      <div>
        {formatDate(pec.workBeginDates.find(e => e.statusFieldId === pecStatusFieldId)?.date) || '-'}
      </div>
      <button
        className='btn btn-sm btn-link p-0'
        onClick={() => handleEditExecutor()}
      >
        <FontAwesomeIcon icon={faPenToSquare} className='me-1' />
        Изменить
      </button>
    </div>
  )
}

export default PecWorkBeginDate

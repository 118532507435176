import { useEffect, useState } from 'react'
import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'
import { getCctvAuditFields, ICctvAuditField } from '@/components/CctvListPageContent/Cctv/constants'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { ICctv, ICctvAudit, ICctvAuditForm, IRecord, ISelectOption } from '@/context/types'
import { postData } from '@/api/postData'
import { apiRoutes, apiURL, getApiResourceListRoute } from '@/config/routes'
import { errorHandle } from '@/utils/errorHandle'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserRole } from '@/config/roles'
import AsyncSelect from 'react-select/async'
import { instanceAxios as axios } from '@/api/instanceAxios'
import recordToSelectOption from '@/utils/recordToSelectOption'
import { AxiosResponse } from 'axios'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'


type Props = {
  cctv: ICctv
}

type ResourceField = Extract<ICctvAuditField, { type: 'apiResource' }>

export default function CctvAudit({ cctv }: Props) {
  const CctvAuditFields = getCctvAuditFields(cctv)

  const [dicts, setDicts] = useState(new Map<ResourceField['apiResource'], IRecord[]>())
  const { hasRole } = useAuthContext()
  const [cctvAudit, setCctvAudit] = useState(
    CctvAuditFields.reduce(
      (acc, item) => {
        acc[item.key] = cctv[item.key]

        if (item.type === 'apiResource' && item.descriptionFieldKey)
          acc[item.descriptionFieldKey] = cctv[item.descriptionFieldKey]

        return acc
      },
      {} as Record<keyof ICctvAudit, ValueOf<ICctvAudit>>
    ) as ICctvAudit,
  )

  useEffect(() => {
    const requests = new Map<ResourceField['apiResource'], Promise<AxiosResponse<IRecord[]>>>()

    const CctvAuditApiResources = CctvAuditFields.filter(item => item.type === 'apiResource') as ResourceField[]

    for (const item of CctvAuditApiResources)
      if (!requests.has(item.apiResource))
        requests.set(item.apiResource, axios.get(getApiResourceListRoute(item.apiResource)))

    Promise.all(Array.from(requests.values())).then(res => {
      setDicts(new Map(
        Array.from(requests.keys()).map((apiResource, i) => [apiResource, res[i].data])
      ))
    })
  }, [CctvAuditFields])

  const update = <T extends keyof ICctvAudit>(field: T, value: ICctvAuditForm[T] | null) =>
    postData({
      url: apiRoutes.cctv(cctv.id),
      axiosMethod: 'patch',
      config: {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
      data: { [field]: value },
      successAction: res => setCctvAudit(cctvAudit => ({ ...cctvAudit, [field]: res.data[field] })),
      errorHandler: (err) => errorHandle(err),
    })

  const canEdit = hasRole([UserRole.admin, UserRole.mgts])

  return (
    <div className='card border-0 mb-2'>
      <div
        className={`card-header border-0 px-2 py-1 justify-content-between ${cn(
          style.cardHeader,
          style.cardHeaderAudit,
        )} `}
      >
        <div>
          <button
            className={`btn text-primary p-0 ${style.cardHeaderArrow}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseAudit'
            aria-expanded='false'
            aria-controls='collapseAudit'
          >
            <b>Аудит ТВН</b>
          </button>
        </div>
        <div>
          <a
            href={`${apiURL}/cctvs/${cctv?.id}/audit/checklist`}
            target={'_blank'}
            rel='noreferrer'
          >
            <SmallRoundBtn icon={faTasks} tooltip='Скачать лист проверки' />
          </a>
        </div>
      </div>
      <div className='collapse' id='collapseAudit'>
        <div
          className={`card-body border-0 ${cn(
            style.cardBody,
            style.cardBodyAudit,
            style.cctvInfoCollapse,
          )} p-0`}
        >
          <ul className='list-group list-group-flush'>
            {CctvAuditFields.map(field =>
              <li key={field.key} className='list-group-item px-2 py-1 d-flex justify-content-between'>
                <p className='m-0 p-0'>
                  {field.title} {field.key === 'auditAddress' && cctv.address.name}
                </p>
                {(() => {
                  switch (field.type) {
                    case 'bool':
                      return (
                        <div className='form-check form-switch m-0'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            role='switch'
                            name={field.key}
                            onChange={e => update(field.key, e.target.checked)}
                            defaultChecked={cctvAudit[field.key]}
                            placeholder={field.placeholder}
                            disabled={!canEdit}
                          />
                        </div>
                      )
                    case 'string':
                      return (
                        <input
                          type='text'
                          className={style.auditInput}
                          defaultValue={cctvAudit[field.key] || ''}
                          onBlur={e => update(field.key, e.target.value)}
                          placeholder={field.placeholder}
                          disabled={!canEdit}
                        />
                      )
                    case 'int':
                      return (
                        <input
                          type='text'
                          className={style.auditInput}
                          defaultValue={cctvAudit[field.key] || ''}
                          onBlur={e => update(field.key, +e.target.value)}
                          placeholder={field.placeholder}
                          disabled={!canEdit}
                        />
                      )
                    case 'apiResource':
                      const hasDescription = (code: string) => ['parlor', 'other'].includes(code)

                      return (
                        <div className='d-flex flex-column' style={{ gap: '5px' }}>
                          <AsyncSelect<ISelectOption>
                            value={cctvAudit[field.key] ? recordToSelectOption(cctvAudit[field.key]) : undefined}
                            isMulti={false}
                            placeholder={field.placeholder || 'Выберите значение'}
                            defaultOptions={dicts.get(field.apiResource)?.map(recordToSelectOption)}
                            loadingMessage={() => 'Поиск...'}
                            noOptionsMessage={() => 'Ничего не найдено'}
                            isClearable={true}
                            onChange={value => {
                              const code = value?.value as string

                              update(field.key, code || null)

                              if (field.descriptionFieldKey && !hasDescription(code))
                                update(field.descriptionFieldKey, null)
                            }}
                            isDisabled={!canEdit}
                          />
                          {field.descriptionFieldKey && hasDescription(cctvAudit[field.key]?.code) &&
                            <input
                              type='text'
                              className={style.auditInput}
                              defaultValue={cctvAudit[field.descriptionFieldKey] || ''}
                              onBlur={e => update(field.descriptionFieldKey!, e.target.value)}
                              disabled={!canEdit}
                            />
                          }
                        </div>
                      )
                    case 'date':
                      return (
                        <input
                          className={style.auditInput}
                          type='date'
                          value={cctvAudit[field.key]?.slice(0, 10) || ''}
                          onChange={e => update(field.key, e.target.value)}
                        />
                      )
                  }
                })()}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

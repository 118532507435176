import TableConstructionObjectEditForm from '@/components/modals/constructionObject/TableConstructionObjectEditForm'
import { IConstructionObject, TableColumnConfig } from '@/context/types'


export const ConstructionObjectTableColumnNameList = [
  'name',
  'servicesCount',
  'consumerDepartmentName',
  'consumerName',
  'objectId',
  'addressName',
  'statedRequirementUniversalPorts',
  'statedRequirementUniversalRadioPorts',
  'statedRequirementUniversalPhonePorts',
  'actualRequirementUniversalPorts',
  'actualRequirementUniversalRadioPorts',
  'actualRequirementUniversalPhonePorts',
  'orderFromHpsm',
  'contactFromObject',
  'comment',
  'note',
  'inspectedStatus',
  'constructionLinesStatus',
  'constructionHardwareStatus',
  'commissioningStatus',
  'contractor',
  'order',
  'sum',
] as const

export type ConstructionObjectTableColumnName = typeof ConstructionObjectTableColumnNameList[number]

export const ConstructionObjectTableColumns: Record<ConstructionObjectTableColumnName, TableColumnConfig<IConstructionObject>> = {
  name: {
    label: 'Условное название проекта',
    value: constructionObject => <TableConstructionObjectEditForm constructionObject={constructionObject} />,
    filterKey: 'name',
    sortKey: 'name',
  },
  servicesCount: {
    label: 'Количество связанных услуг',
    value: constructionObject => constructionObject.servicesCount,
    filterAndSortViewKey: 'view_construction_object:servicesCount',
  },
  consumerDepartmentName: {
    label: 'Наименование клиента',
    value: constructionObject => constructionObject.department?.name,
    filterKey: 'department.name',
    filterExistsKey: 'department',
    sortKey: 'department.name',
  },
  consumerName: {
    label: 'Наименование объекта А',
    value: constructionObject => constructionObject.consumer?.name,
    filterKey: 'consumer.name',
    filterExistsKey: 'consumer',
    sortKey: 'consumer.name',
  },
  objectId: {
    label: 'ID ЕЦХД объекта А',
    value: constructionObject => constructionObject.address?.storageId,
    filterKey: 'address.storageId',
    filterExistsKey: 'address',
    sortKey: 'address.storageId',
  },
  addressName: {
    label: 'Адрес объекта',
    value: constructionObject => constructionObject.address?.name,
    filterKey: 'address.name',
    filterExistsKey: 'address',
    sortKey: 'address.name',
  },
  statedRequirementUniversalPorts: {
    label: 'УП',
    value: constructionObject => constructionObject.statedRequirementUniversalPorts,
    filterKey: 'statedRequirementUniversalPorts',
    sortKey: 'statedRequirementUniversalPorts',
  },
  statedRequirementUniversalRadioPorts: {
    label: 'УРП',
    value: constructionObject => constructionObject.statedRequirementUniversalRadioPorts,
    filterKey: 'statedRequirementUniversalRadioPorts',
    sortKey: 'statedRequirementUniversalRadioPorts',
  },
  statedRequirementUniversalPhonePorts: {
    label: 'УТП',
    value: constructionObject => constructionObject.statedRequirementUniversalPhonePorts,
    filterKey: 'statedRequirementUniversalPhonePorts',
    sortKey: 'statedRequirementUniversalPhonePorts',
  },
  actualRequirementUniversalPorts: {
    label: 'УП',
    value: constructionObject => constructionObject.actualRequirementUniversalPorts,
    filterKey: 'actualRequirementUniversalPorts',
    sortKey: 'actualRequirementUniversalPorts',
  },
  actualRequirementUniversalRadioPorts: {
    label: 'УРП',
    value: constructionObject => constructionObject.actualRequirementUniversalRadioPorts,
    filterKey: 'actualRequirementUniversalRadioPorts',
    sortKey: 'actualRequirementUniversalRadioPorts',
  },
  actualRequirementUniversalPhonePorts: {
    label: 'УТП',
    value: constructionObject => constructionObject.actualRequirementUniversalPhonePorts,
    filterKey: 'actualRequirementUniversalPhonePorts',
    sortKey: 'actualRequirementUniversalPhonePorts',
  },
  orderFromHpsm: {
    label: 'Заказ от ДИТ',
    value: constructionObject => constructionObject.orderFromHpsm,
    filterKey: 'orderFromHpsm',
    sortKey: 'orderFromHpsm',
  },
  contactFromObject: {
    label: 'Контакт с объекта',
    value: constructionObject => constructionObject.contactFromObject,
    filterKey: 'contactFromObject',
    sortKey: 'contactFromObject',
  },
  comment: {
    label: 'Комментарий',
    value: constructionObject => constructionObject.comment,
    filterKey: 'comment',
    sortKey: 'comment',
  },
  note: {
    label: 'Техническое примечание',
    value: constructionObject => constructionObject.note,
    filterKey: 'note',
    sortKey: 'note',
  },
  inspectedStatus: {
    label: 'Обследовано',
    value: constructionObject => constructionObject.inspectedStatus?.name,
    filterKey: 'inspectedStatus.name',
    filterExistsKey: 'inspectedStatus',
    sortKey: 'inspectedStatus.name',
  },
  constructionLinesStatus: {
    label: 'СМР Линий',
    value: constructionObject => constructionObject.constructionLinesStatus?.name,
    filterKey: 'constructionLinesStatus.name',
    filterExistsKey: 'constructionLinesStatus',
    sortKey: 'constructionLinesStatus.name',
  },
  constructionHardwareStatus: {
    label: 'СМР оборудования',
    value: constructionObject => constructionObject.constructionHardwareStatus?.name,
    filterKey: 'constructionHardwareStatus.name',
    filterExistsKey: 'constructionHardwareStatus',
    sortKey: 'constructionHardwareStatus.name',
  },
  commissioningStatus: {
    label: 'ПНР',
    value: constructionObject => constructionObject.commissioningStatus?.name,
    filterKey: 'commissioningStatus.name',
    filterExistsKey: 'commissioningStatus',
    sortKey: 'commissioningStatus.name',
  },
  contractor: {
    label: 'Подрядчик',
    value: constructionObject => constructionObject.organization?.name,
    filterKey: 'organization.name',
    filterExistsKey: 'organization',
    sortKey: 'organization.name',
  },
  order: {
    label: 'Заказ с ПО',
    value: constructionObject => constructionObject.orderWithContractor,
    filterKey: 'orderWithContractor',
    sortKey: 'orderWithContractor',
  },
  sum: {
    label: 'Сумма',
    value: constructionObject => constructionObject.sum,
    filterKey: 'sum',
    sortKey: 'sum',
    permissions: 'serviceBillingRead'
  },
}

import { ChangeEvent, PropsWithChildren, ReactNode, useCallback } from 'react'
import { IRecordWithId, TableAdditionalColumnConfig, TableColumnConfig, TableFilter } from '@/context/types'
import TableFilterInput from '@/components/common/table/TableFilterInput'
import TableFilterInputDisabled from '@/components/common/table/TableFilterInputDisabled'
import TableSortCell from '@/components/common/table/TableSortCell'
import { useAuthContext } from '@/context/auth/AuthContext'
import { TableTotals } from '@/api/queries/table-totals'
import { getAdditionalColKey } from '@/components/common/table/utils'


type Props<
  TRecord extends IRecordWithId,
  TColumnName extends string,
  TAdditionalColumns extends TableAdditionalColumnConfig[],
> = PropsWithChildren<{
  columnNameList: readonly TColumnName[]
  columns: Record<TColumnName, TableColumnConfig<TRecord>>
  customColumnLabels?: Partial<Record<TColumnName | NonNullable<TAdditionalColumns[number]['key']>, ReactNode>>
  additionalColumns?: TAdditionalColumns
  additionalColumnsSortKey?: string
  isAllRecordsSelected?: boolean
  selectAllRecords?: () => void
  deselectAllRecords?: () => void
  recordListSortDirection?: 'asc' | 'desc'
  recordListSortFieldName?: string
  setRecordListSort?: (fieldName?: string) => void
  filterForm: TableFilter<TColumnName>
  updateFilterForm: (name: TColumnName, value?: string, callSetFilter?: boolean) => void
  applyFilter: () => void
  totals?: TableTotals<TRecord>
  top?: number //todo-sem remove when table styles will be fixed
}>

const getSortKey = (
  col: Pick<TableColumnConfig<any> | TableAdditionalColumnConfig, 'filterAndSortViewKey' | 'sortKey'>,
) => {
  if (col.filterAndSortViewKey) {
    const [table, key] = col.filterAndSortViewKey.split(':')
    return `view[${table}][${key}][order]`
  }

  if (col.sortKey?.includes('/')) {
    const [key, value] = col.sortKey.split('/')
    return `${key}[${value}]`
  }

  return `order[${col.sortKey}]`
}

/** Контент головы таблицы */
export default function TableHead<
  TRecord extends IRecordWithId,
  TColumnName extends string,
  TAdditionalColumns extends TableAdditionalColumnConfig[],
>({
  children,
  columnNameList,
  columns,
  customColumnLabels,
  additionalColumns,
  isAllRecordsSelected,
  selectAllRecords,
  deselectAllRecords,
  recordListSortFieldName,
  recordListSortDirection,
  setRecordListSort,
  filterForm,
  updateFilterForm,
  applyFilter,
  totals,
  top = 300,  //todo-sem remove when table and table toolbar styles will be fixed
}: Props<TRecord, TColumnName, TAdditionalColumns>) {
  const handleSelectAll = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked)
      selectAllRecords!()
    else
      deselectAllRecords!()
  }, [selectAllRecords, deselectAllRecords])

  const hasSelectColumn = selectAllRecords && deselectAllRecords

  return (
    <thead className='table-primary' style={{ position: 'sticky', top }} >
      {children}
      {totals && !!columnNameList.filter(columnName => columns[columnName].totalsKey).length &&
        <tr>
          {hasSelectColumn && <td />}
          {columnNameList.map(columnName => {
            const totalsKey = columns[columnName].totalsKey
            return (
              <th key={columnName}>
                {totalsKey &&
                  <>∑={totals[totalsKey]}</>
                }
              </th>
            )
          })}
          {additionalColumns?.map(col => <th key={getAdditionalColKey(col)} />)}
        </tr>
      }
      <tr>
        {hasSelectColumn &&
          <th className='text-center'>
            Выбрать все<br />
            <input
              className='form-check-input'
              type='checkbox'
              checked={isAllRecordsSelected}
              onChange={handleSelectAll} />
          </th>
        }
        {columnNameList
          .filter(columnName => columns[columnName].labelColspan !== 0)
          .map(columnName =>
            <th key={columnName} colSpan={columns[columnName].labelColspan}>
              {customColumnLabels?.[columnName] ?? columns[columnName].label}
            </th>
          )
        }
        {additionalColumns
          ?.filter(col => col.labelColspan !== 0)
          ?.map(col => 
            <th key={getAdditionalColKey(col)} colSpan={col.labelColspan}>
              {(col.key ? customColumnLabels?.[col.key as keyof TAdditionalColumns] : null) ?? col.label}
            </th>
          )
        }
      </tr>
      {recordListSortDirection &&
        <tr className='text-center'>
          {hasSelectColumn && <TableSortCell disabled />}
          {columnNameList.map(columnName =>
            <TableSortCell
              key={columnName}
              fieldKey={getSortKey(columns[columnName])}
              setFieldKey={setRecordListSort}
              selectedFieldKey={recordListSortFieldName}
              selectedDirection={recordListSortDirection}
              disabled={!columns[columnName].filterAndSortViewKey && !columns[columnName].sortKey}
            />,
          )}
          {additionalColumns?.map(col =>
            <TableSortCell
              key={getAdditionalColKey(col)}
              fieldKey={getSortKey(col)}
              setFieldKey={setRecordListSort}
              selectedFieldKey={recordListSortFieldName}
              selectedDirection={recordListSortDirection}
              disabled={!col.filterAndSortViewKey && !col.sortKey}
            />,
          )}
        </tr>
      }
      <tr className='text-center p-0'>
        {hasSelectColumn &&
          <th className='p-0'>
            <TableFilterInputDisabled />
          </th>
        }
        {columnNameList
          .filter(columnName => columns[columnName].filterColspan !== 0)
          .map(columnName =>
            <th key={columnName} className='p-0' colSpan={columns[columnName].filterColspan}>
              {!columns[columnName].filterKey && !columns[columnName].filterAndSortViewKey && columns[columnName].type !== 'boolExists'
                ? <TableFilterInputDisabled />
                : <TableFilterInput
                  type={columns[columnName].type}
                  name={columnName}
                  values={columns[columnName].boolFilterLabels}
                  value={filterForm[columnName]}
                  updateFilterForm={updateFilterForm}
                  applyFilter={applyFilter}
                  placeholder={columns[columnName].filterPlaceholder}
                />
              }
            </th>
          )
        }
        {additionalColumns
          ?.filter(col => col.filterColspan !== 0)
          ?.map(col =>
            <th key={getAdditionalColKey(col)} className='p-0' colSpan={col.filterColspan}>
              {!col.filterAndSortViewKey && !col.filterKey
                ? <TableFilterInputDisabled />
                : <TableFilterInput
                  type={col.type}
                  name={(col.filterAndSortViewKey ?? col.filterKey) as TColumnName}
                  value={filterForm[(col.filterAndSortViewKey ?? col.filterKey) as TColumnName]}
                  updateFilterForm={updateFilterForm}
                  applyFilter={applyFilter}
                  placeholder={col.filterPlaceholder}
                />
              }
            </th>
          )
        }
      </tr>
    </thead>
  )
}

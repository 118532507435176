type Props = {
  onChange: (value: string) => any
}

const PecsStatusStatFilterCell = ({ onChange }: Props) => {

  return (
    <td className='p-0'>
      <input
        type='text'
        className='form-control form-control-sm border-0 shadow-none rounded-0 px-2'
        placeholder='Фильтр...'
        onChange={e => onChange(e.target.value)}
      />
    </td>
  )
}

export default PecsStatusStatFilterCell

import FolderCard from '@/components/common/cards/FolderCard'
import style from '@/assets/styles/card.module.scss'
import { useCallback, useState } from 'react'
import ParamTable from '@/components/common/cards/ParamTable'
import ParamTableRow from '@/components/common/cards/ParamTableRow'
import { useModal } from '@/context/ModalContext'
import { IService } from '@/context/types'
import { FormContextProvider } from '@/context/form/FormContext'
import ServiceCreateEditForm from '@/components/modals/service/ServiceCreateEditForm'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, routes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { useAuthContext } from '@/context/auth/AuthContext'
import { ServiceTableColumnNameList, ServiceTableColumns } from '@/config/tables/lists/commutication/service'
import { serviceStatusLabels } from '@/config/status/labels'
import CreateChannelByReferenceBtn from '@/components/common/btns/CreateChannelByReferenceBtn'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import { useTabsContext } from '@/context/TabsContext'


type Props = {
  service: IService
  reloadCard: any
  active?: number
  handleCloseTab?: (arg: any) => void
}

const ServiceInfoCard = ({ service, reloadCard }: Props) => {
  const { showModalDialog } = useModal()
  const { hasPermission } = useAuthContext()
  const [isExpand, setIsExpand] = useState(false)

  const handleExpand = useCallback(() => {
    setIsExpand(prev => !prev)
  }, [])
  const { closeCurrentTab } = useTabsContext()
  const availableCols = useTableAvailableCols(ServiceTableColumnNameList, ServiceTableColumns)

  const handleEditService = useCallback(() => {
    showModalDialog({
      type: 'saveCancel',
      dismissable: false,
      header: `Редактирование информации услуги-${service.number}`,
      size: 'lg',
      content:
        <FormContextProvider>
          <ServiceCreateEditForm service={service} onSave={reloadCard} />
        </FormContextProvider>,
    })
  }, [service, showModalDialog])

  const handleDeleteService = useCallback(() => {
    showModalDialog({
      type: 'deleteCancel',
      header: `Удаление Услуги-${service.number}`,
      content: `Вы уверены, что хотите удалить услугу-${service.number}`,
      primaryCallback: async () => {
        try {
          await axios.delete(apiRoutes.service(service.id))
          closeCurrentTab(routes.services)
        } catch (e: any) {
          showModalDialog({
            type: 'error',
            header: `Удаление Услуги-${service.number}: ошибка`,
            content: await getErrorMessage(e, `Произошла ошибка при удалении Услуги-${service.number}`),
          })
        }
      }
    })
  }, [service, showModalDialog, closeCurrentTab])

  return (
    <FolderCard headerBgColor='#FFD8A8'
      bodyBgColor='#FFF9DB'
      header={<button
        className='btn btn-link p-0 text-decoration-none fw-bold text-nowrap'
        onClick={handleExpand}>
        Информация по Услуге-{service.number}
        <FontAwesomeIcon icon={isExpand ? faCaretUp : faCaretDown} className='ms-2' />
      </button>}
      centerToolbar={<>
        <CreateChannelByReferenceBtn 
          route={apiRoutes.serviceChannels}
          referenceId={service.id} 
          reloadCard={reloadCard} 
          hasReservation={service.hasReservation} />
        <SmallRoundBtn
          icon={faPenToSquare}
          tooltip='Редактировать сведения'
          disabled={!hasPermission('serviceEdit')}
          onClick={handleEditService} /></>}
      rightToolbar={
        <SmallRoundBtn
          icon={faTrash}
          color='danger'
          tooltip='Удалить Услугу'
          disabled={!hasPermission('serviceDelete')}
          onClick={handleDeleteService} />}>
      <div className={`${style.cctvInfoCollapse} ${isExpand && style.contentActive}`}>
      <ParamTable>
            {availableCols
              .filter(columnName => !Object.keys(serviceStatusLabels).includes(columnName))
              .map(columnName =>
                <ParamTableRow key={columnName} label={ServiceTableColumns[columnName].label!} width='33%'>
                  {ServiceTableColumns[columnName].value?.(service)}
                </ParamTableRow>
              )
            }
          </ParamTable>
      </div>
    </FolderCard>
  )
}
export default ServiceInfoCard

import { useCallback, useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import {
  IReportACC, IReportACO, ISubReportOpenIncidents, ISubReportUnavailableHardware,
} from '@/components/reports/types'
import { formatReportPercent } from '@/utils/formatters'
import { subReportApiCodes } from '@/config/reports'
import { IOrganization } from '@/context/types'
import { getReportReadRoute } from '@/config/routes'
import { getReportFilter } from '@/config/listFilters'
import { useParams } from 'react-router-dom'
import { fetchData } from '@/api/fetchData'
import { errorHandle } from '@/utils/errorHandle'
import { Dropdown } from '@/components/reports/common/Dropdown'
import Loader from '@/components/common/Loader'
import { SubReportOpenIncidents } from '@/components/reports/subreports/SubReportOpenIncidents'
import { SubReportUnavailableHardware } from '@/components/reports/subreports/SubReportUnavailableHardware'


type IReportACONumber = Omit<IReportACO, 'channel_operator_name'>

export const ReportIpCamerasAvailableByChannelOperators = () => {
  const { projectId } = useParams()
  const [report, setReport] = useState<IReportACO[]>([])
  const [reportDetailOpenTT, setReportDetailOpenTT] = useState<ISubReportOpenIncidents[]>([])
  const [reportDetailUH, setReportDetailUH] = useState<ISubReportUnavailableHardware[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isShowOpenedIncidents, setIsShowOpenedIncidents] = useState(false)
  const [isShowUnavailableHardware, setIsShowUnavailableHardware] = useState(false)
  const [reportDetailFilterLabel, setReportDetailFilterLabel] = useState('')

  const reportGetSum: IReportACONumber[] = report.slice(1)
  const getSum = useCallback(
    (key: keyof IReportACONumber) => reportGetSum.reduce((acc, item) => acc + +item[key], 0),
    [reportGetSum],
  )

  const getApiRouteFunc = (apiCode: ValueOf<typeof subReportApiCodes>) =>
    (organizationId?: IOrganization['id'], format = '') =>
      getReportReadRoute(apiCode, {
        ...getReportFilter(projectId),
        operator_channel_id: organizationId ?? '',
        format,
      })

  const getOpenedIncidentsApiRoute = getApiRouteFunc(subReportApiCodes.ipCameraIncidentsOpenedByChannelOperators)
  const getUnavailableHardwareApiRoute = getApiRouteFunc(subReportApiCodes.ipCameraUnavailableByChannelOperators)

  const showOpenedIncidents = (row?: IReportACO) => {
    setReportDetailFilterLabel(row ? `Оператор канала связи: ${row.channel_operator_name}` : '')
    setIsShowUnavailableHardware(false)
    setIsShowOpenedIncidents(false)
    fetchData({
      url: getOpenedIncidentsApiRoute(row?.channel_operator_id),
      setData: (res) => {
        setReportDetailOpenTT(res)
        setIsShowOpenedIncidents(true)
      },
      setIsLoading,
      errorHandler: (err) => errorHandle(err),
    })
  }
  const showUnavailableHardware = (row?: IReportACO) => {
    setReportDetailFilterLabel(row ? `Оператор канала связи: ${row.channel_operator_name}` : '')
    setIsShowOpenedIncidents(false)
    setIsShowUnavailableHardware(false)
    fetchData({
      url: getUnavailableHardwareApiRoute(row?.channel_operator_id),
      setData: (res) => {
        setReportDetailUH(res)
        setIsShowUnavailableHardware(true)
      },
      setIsLoading,
      errorHandler: (err) => errorHandle(err),
    })
  }

  return (
    <ReportWrapper
      code='ipCamerasAvailableByChannelOperators'
      {...{ report, setReport }}
    >
      <table className='table table-bordered table-sm mt-4 w-auto' style={{ zIndex: 1 }}>
        <thead className='text-muted' style={{ position: 'sticky', top: '176px' }}>
          <tr>
            <th style={{ backgroundColor: colorsTable.lightGrey }}>Оператор канала связи</th>
            <th style={{ backgroundColor: colorsTable.lightGrey }}>
              Кол-во
              <br />
              камер по ГК
            </th>
            <th style={{ backgroundColor: colorsTable.lightGrey }}>
              Кол-во
              <br />
              камер установлено
            </th>
            <th style={{ backgroundColor: colorsTable.grey }}>
              В БЭИ
              <br />
              Кол-во
            </th>
            <th style={{ backgroundColor: colorsTable.grey }}>
              В БЭИ
              <br />% от ГК
            </th>
            <th style={{ backgroundColor: colorsTable.green }}>
              Работающие
              <br />
              Кол-во
            </th>
            <th style={{ backgroundColor: colorsTable.green }}>
              Работающие
              <br />% от ГК
            </th>
            <th style={{ backgroundColor: colorsTable.green }}>
              Работающие
              <br />
              TT
            </th>
            <th style={{ backgroundColor: colorsTable.red }}>
              Недоступны
              <br />
              Кол-во
            </th>
            <th style={{ backgroundColor: colorsTable.red }}>
              Недоступны
              <br />% от ГК
            </th>
            <th style={{ backgroundColor: colorsTable.red }}>
              Недоступны
              <br />
              TT
            </th>
          </tr>
        </thead>
        <tbody>
          {report.map((row, index) => (
            <tr key={index}>
              <td>{row.channel_operator_name}</td>
              <td className='text-right'>{row.contract_cameras_count}</td>
              <td className='text-right'>{row.installed_cameras_count}</td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightGrey }}>
                {row.storage_cameras_count}
              </td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightGrey }}>
                {formatReportPercent(row.storage_cameras_percent)}
              </td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightGreen }}>
                {row.available_cameras_count}
              </td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightGreen }}>
                {formatReportPercent(row.available_cameras_percent)}
              </td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightGreen }}>
                {row.available_incidents_count}
              </td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightRed }}>
                <Dropdown
                  exportApiRoute={projectId ? '' : getUnavailableHardwareApiRoute(row.channel_operator_id, 'xlsx')}
                  handleClick={() => showUnavailableHardware(row)}
                  data={row.unavailable_cameras_count}
                />
              </td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightRed }}>
                {formatReportPercent(row.unavailable_cameras_percent)}
              </td>
              <td className='text-right' style={{ backgroundColor: colorsTable.lightRed }}>
                <Dropdown
                  exportApiRoute={projectId ? '' : getOpenedIncidentsApiRoute(row.channel_operator_id, 'xlsx')}
                  handleClick={() => showOpenedIncidents(row)}
                  data={row.unavailable_incidents_count}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr style={{ backgroundColor: colorsTable.blue }}>
            <td>Итого</td>
            <td className='text-right'>{getSum('contract_cameras_count')}</td>
            <td className='text-right'>{getSum('installed_cameras_count')}</td>
            <td className='text-right'>{getSum('storage_cameras_count')}</td>
            <td className='text-right'>
              {((getSum('storage_cameras_count') / getSum('contract_cameras_count')) * 100 || 0).toLocaleString()}
            </td>
            <td className='text-right'>{getSum('available_cameras_count')}</td>
            <td className='text-right'>
              {((getSum('available_cameras_count') / getSum('contract_cameras_count')) * 100 || 0).toLocaleString()}
            </td>
            <td className='text-right'>{getSum('available_incidents_count')}</td>
            <td className='text-right'>
              <Dropdown
                exportApiRoute={projectId ? '' : getUnavailableHardwareApiRoute('', 'xlsx')}
                handleClick={() => showUnavailableHardware()}
                data={getSum('unavailable_cameras_count')}
              />
            </td>
            <td className='text-right'>
              {((getSum('unavailable_cameras_count') / getSum('contract_cameras_count')) * 100 || 0).toLocaleString()}
            </td>
            <td className='text-right'>
              <Dropdown
                exportApiRoute={projectId ? '' : getOpenedIncidentsApiRoute('', 'xlsx')}
                handleClick={() => showOpenedIncidents()}
                data={getSum('unavailable_incidents_count')}
              />
            </td>
          </tr>
        </tfoot>
      </table>
      {isLoading && <Loader />}
      {isShowOpenedIncidents &&
        <SubReportOpenIncidents
          filterLabel={reportDetailFilterLabel}
          {...{ reportDetailOpenTT }}
        />
      }
      {isShowUnavailableHardware &&
        <SubReportUnavailableHardware
          filterLabel={reportDetailFilterLabel}
          {...{ reportDetailUH }}
        />
      }
    </ReportWrapper>
  )
}

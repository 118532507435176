type AnyArray<T> = T[] | readonly T[]

export const arrayUnique = <T>(arr: AnyArray<T>): T[] => Array.from(new Set(arr))

export const arrayIntersect = <T>(arr1: AnyArray<T>, arr2: AnyArray<T>): T[] =>
  arrayUnique(arr1.filter(v => arr2.includes(v)))

export const arrayDiff = <T>(arr1: AnyArray<T>, arr2: AnyArray<T>): T[] => arr1.filter(v => !arr2.includes(v))

export const createNumbersArray = (length: number) => [...new Array(length)].map((_, i) => i + 1)

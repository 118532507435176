import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from '@/assets/styles/modal.module.scss'
import { PropsWithChildren, useCallback, useEffect } from 'react'
import cn from 'classnames'


type Props = PropsWithChildren<{
  title: string
  open: boolean
  setOpen: (arg: boolean) => void
  backGCTitle?: string
  formClean: any
}>

const ESCAPE = 'Escape'
export const Modal = ({ title, open, setOpen, children, backGCTitle, formClean }: Props) => {
  const handleClickClose = () => {
    setOpen(false)
    formClean.current && formClean.current.reset()
  }
  const handleKeyClose = useCallback(
    (e: any) => {
      if ((e as KeyboardEvent).key === ESCAPE) {
        setOpen(false)
        formClean.current && formClean.current.reset()
      }
    },
    [setOpen, formClean],
  )
  useEffect(() => {
    window.addEventListener('keydown', handleKeyClose, true)
    return () => {
      window.removeEventListener('keydown', handleKeyClose, true)
    }
  }, [handleKeyClose])
  const wrapperClassName = cn(style.wrapper, open && style.wrapper_open)
  const modalClassName = cn(style.modal, open && style.modal_open)
  return (
    <div className={wrapperClassName} onClick={handleClickClose}>
      <div className={modalClassName} onClick={(e) => e.stopPropagation()}>
        <div className={style.header} style={{ backgroundColor: backGCTitle }}>
          <b>{title}</b>
          <button type='button' className={style.modal_close} onClick={handleClickClose}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        {children}
      </div>
    </div>
  )
}

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IChannel, IChannelForm } from '@/context/types'
import { ChannelTableColumnName, ChannelTableColumns } from '@/config/tables/lists/channels/channel'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Channel list context */
const ChannelListContext = createContext({} as TableApiResourceContextValue<IChannel, ChannelTableColumnName, IChannelForm>)


/** Channel list context hook */
export const useChannelListContext = () => useContext(ChannelListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** Channel list context provider */
export function ChannelListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IChannel, ChannelTableColumnName, IChannelForm>
      Context={ChannelListContext}
      apiResource={ApiResource.Channel}
      loadIds
      recordIdKey='channelId'
      tableColumns={ChannelTableColumns}
      logName='channelList'
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}
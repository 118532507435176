import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { getApiResourceListPath } from '@/config/routes'
import { ApiResource } from '@/context/types'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import Main from '@/components/common/Main'


// Контент страницы с перечнем справочников
export default function CatalogListPageContent() {
  useDocumentTitle('Справочники')

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem>Справочники</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <Main>
        <h1>Справочники</h1>
        <h3>Общие</h3>
        <NavGroup>
          <NavList header='Контакты'>
            <NavItem label='Пользователи' apiResource={ApiResource.User} />
            <NavItem label='Роли пользователей' apiResource={ApiResource.UserRole} />
            <NavItem label='Организации' apiResource={ApiResource.Organization} />
            <NavItem label='Роли организаций' apiResource={ApiResource.OrganizationRole} />
          </NavList>
          <NavList header='Адреса'>
            <NavItem label='Адреса' apiResource={ApiResource.Address} />
            <NavItem label='Районы' apiResource={ApiResource.District} />
            <NavItem label='Округа' apiResource={ApiResource.Area} />
          </NavList>
          <NavList header='ГК'>
            <NavItem label='ГК' apiResource={ApiResource.Contract} />
            <NavItem label='Отчётные периоды' apiResource={ApiResource.ReportingPeriod} />
            <NavItem label='Прайс-лист' apiResource={ApiResource.ContractServiceTypePrice} />
            <NavItem label='Договоры' apiResource={ApiResource.Agreement} />
            <NavItem label='Бланк-заказы' apiResource={ApiResource.OrderForm} />
          </NavList>
          <NavList header='Инциденты'>
            <NavItem label='Этапы инцидентов' apiResource={ApiResource.IncidentStage} />
            <NavItem label='Финальные статусы инцидентов' apiResource={ApiResource.IncidentStatus} />
          </NavList>
        </NavGroup>
        <h3>Оборудование</h3>
        <NavGroup>
          <NavList header='Оборудование'>
            <NavItem label='VRF' apiResource={ApiResource.Vrf} />
          </NavList>
          <NavList header='Коммутаторы'>
            <NavItem label='Коммутаторы' apiResource={ApiResource.NetworkSwitch} />
            <NavItem label='Пресеты' apiResource={ApiResource.NetworkSwitchPreset} />
            <NavItem label='Модели' apiResource={ApiResource.NetworkSwitchModel} />
            <NavItem label='Прошивки' apiResource={ApiResource.NetworkSwitchFirmware} />
          </NavList>
          <NavList header='Маршрутизаторы'>
            <NavItem label='Маршрутизаторы' apiResource={ApiResource.Router} />
            <NavItem label='Пресеты' apiResource={ApiResource.RouterPreset} />
            <NavItem label='Модели' apiResource={ApiResource.RouterModel} />
            <NavItem label='Прошивки' apiResource={ApiResource.RouterFirmware} />
          </NavList>
          <NavList header='IP-камеры'>
            <NavItem label='IP-камеры' apiResource={ApiResource.IpCamera} />
            <NavItem label='Пресеты' apiResource={ApiResource.IpCameraPreset} />
            <NavItem label='Модели' apiResource={ApiResource.IpCameraModel} />
            <NavItem label='Прошивки' apiResource={ApiResource.IpCameraFirmware} />
          </NavList>
          <NavList header='Видеосерверы'>
            <NavItem label='Видеосерверы' apiResource={ApiResource.VideoServer} />
            <NavItem label='Пресеты' apiResource={ApiResource.VideoServerPreset} />
            <NavItem label='Модели' apiResource={ApiResource.VideoServerModel} />
            <NavItem label='Прошивки' apiResource={ApiResource.VideoServerFirmware} />
          </NavList>
          <NavList header='Точки доступа'>
            <NavItem label='Точки доступа' apiResource={ApiResource.AccessPoint} />
            <NavItem label='Модели' apiResource={ApiResource.AccessPointModel} />
            <NavItem label='Прошивки' apiResource={ApiResource.AccessPointFirmware} />
          </NavList>
          <NavList header='Источники бесперебойного питания'>
            <NavItem label='ИБП' apiResource={ApiResource.Ups} />
            <NavItem label='Пресеты' apiResource={ApiResource.UpsPreset} />
            <NavItem label='Модели' apiResource={ApiResource.UpsModel} />
            <NavItem label='Прошивки' apiResource={ApiResource.UpsFirmware} />
          </NavList>
        </NavGroup>
        <h3>Проекты</h3>
        <NavGroup>
          <NavList header='Проекты'>
            <NavItem label='Типы проектов' apiResource={ApiResource.ProjectType} />
            <NavItem label='Поля проектов' apiResource={ApiResource.ProjectField} />
          </NavList>
          <NavList header='ТВНы'>
            <NavItem label='Типы ТВН' apiResource={ApiResource.CctvType} />
            <NavItem label='Типы файлов ТВН' apiResource={ApiResource.CctvFileType} />
            <NavItem label='Технологии каналов' apiResource={ApiResource.ChannelTechnology} />
          </NavList>
        </NavGroup>
        <h3>Связь</h3>
        <NavGroup>
          <NavList header='Услуги'>
            <NavItem label='Типы услуг' apiResource={ApiResource.ServiceType} />
            <NavItem label='Статусы объектов строительства' apiResource={ApiResource.ConstructionObjectStatus} />
            <NavItem label='Адреса' apiResource={ApiResource.CommunicationAddress} />
            <NavItem label='Стыки' apiResource={ApiResource.Joint} />
            <NavItem label='Классы качества' apiResource={ApiResource.QualityClass} />
          </NavList>
          <NavList header='Потребители'>
            <NavItem label='Потребители' apiResource={ApiResource.Consumer} />
            <NavItem label='Департаменты' apiResource={ApiResource.Department} />
          </NavList>
        </NavGroup>
        <h3>Сопровождение</h3>
        <NavGroup>
          <NavList header='УИКи'>
            <NavItem label='Конструктор видов работ' apiResource={ApiResource.PecStatusFields} />
            <NavItem label='Конструктор графических файлов' apiResource={ApiResource.PecFileType} />
          </NavList>
        </NavGroup>
      </Main>
    </>
  )
}


function NavGroup({ children }: PropsWithChildren<{}>) {
  return (
    <table className='table'>
      <tbody>
        {children}
      </tbody>
    </table>
  )
}


function NavList({ header, children }: PropsWithChildren<{ header: string }>) {
  return (
    <tr>
      <td width='160px'>
        {header}
      </td>
      <td>
        <div className='row'>
          {children}
        </div>
      </td>
    </tr>
  )
}


function NavItem({ label, apiResource }: { label: string, apiResource: ApiResource }) {
  return (
    <div className='col-auto'>
      <Link to={getApiResourceListPath(apiResource)} className='text-nowrap'>
        {label}
      </Link>
    </div>
  )
}

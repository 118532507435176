/**
 * Возвращает размер файла строкой с тремя значащими цифрами и единицей измерения
 *
 * `1024` => `'1,00 КБ'`
 *
 * `128*1024` => `'128 КБ'`
 *
 * `1234*1024*1024` => `'1230 МБ'`
 */
export function fileSizeToLocaleString(size: number) {
	let power = 0
	while (size > 1024 && power < 4) {
		size = size / 1024
		power++
	}
	return `${size.toLocaleString('ru-RU',{ maximumSignificantDigits: 3 })} ${StorageUnits[power]}`
}

const StorageUnits: Record<number, string> = {
	0: 'Б',
	1: 'КБ',
	2: 'МБ',
	3: 'ГБ',
	4: 'ТБ',
}
import { AxiosError } from 'axios'
import { instanceAxios as axios } from '@/api/instanceAxios'


interface IFetchData {
  url: string
  // setNotification: (arg: INotification) => ISetNotificationAction
  setNotification?: any
  setData: (arg1: any) => void
  adapter?: any
  errorHandler?: (arg1: AxiosError) => void
  errorMessageHeader?: string
  errorMessage?: string
  setIsLoading?: (arg1: boolean) => void
  config?: any
}

export const fetchData = ({
  url,
  setData,
  adapter,
  errorMessageHeader,
  errorMessage,
  errorHandler = () => null,
  config,
  setNotification = () => null,
  setIsLoading = () => null,
}: IFetchData) => {
  setIsLoading(true)
  axios
    .get(url, config)
    .then(({ data }) => {
      setData(data)
    })
    .catch((err) => {
      errorHandler(err)
    })
    .finally(() => setIsLoading(false))
}

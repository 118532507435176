import { useCallback, useMemo } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { ApiResource } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getApiResourceEditPath, getApiResourceListPath, routes } from '@/config/routes'
import { ApiResources } from '@/config/apiResources'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useModal } from '@/context/ModalContext'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import Alert from '@/components/common/Alert'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import Loader from '@/components/common/Loader'
import Main from '@/components/common/Main'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import CatalogFilesModal from '@/components/modals/catalogs/CatalogFilesModal'
import { useAuthContext } from '@/context/auth/AuthContext'


/** Контент страницы записи справочника */
export default function RecordPageContent() {
	const params = useParams()
	const apiResource = params.apiResource as ApiResource
	const recordId: string = params.recordId!
	const navigate = useNavigate()
	const { showModalDialog } = useModal()
  const { hasPermission } = useAuthContext()
	const { isRecordLoading, loadRecordList, record, deleteRecord } = useApiResourceContext<any>()
	const { title, getRecordTitle, immutable, fieldList } = ApiResources[apiResource]!

	const recordTitle = useMemo(() => {
		if (!record) return recordId
		if (getRecordTitle) return getRecordTitle(record)
		return record.name
	}, [record, recordId, getRecordTitle])

	useDocumentTitle(`${title}: ${recordTitle}`)

	const handleDelete = useCallback(() => {
		showModalDialog({
			type: 'deleteCancel',
			header: `${title}: удаление записи`,
			content: `Вы действительно хотите удалить запись «${record.name ?? record.code ?? record.id}» из справочника «${title}»?`,
			primaryCallback: async () => {
				try {
					await deleteRecord(recordId)
					loadRecordList()
					navigate(getApiResourceListPath(apiResource))
				} catch (e: any) {
					showModalDialog({
						type: 'error',
						header: `${title}: удаление записи: ошибка`,
						content: await getErrorMessage(e),
					})
				}
			}
		})
	}, [record, loadRecordList, deleteRecord, title, recordId, apiResource, showModalDialog, navigate])

	if (isRecordLoading) return <Main><Loader /></Main>

	const showFilesModal = () => showModalDialog({
		type: 'ok',
		content: <CatalogFilesModal record={record} />,
		header: 'Прикреплённые файлы',
	})

	return (
		<>
			<BreadcrumbsContainer>
				<BreadcrumbsItem path={routes.catalogs}>Справочники</BreadcrumbsItem>
				<BreadcrumbsItem path={getApiResourceListPath(apiResource)}>{title}</BreadcrumbsItem>
				<BreadcrumbsItem>{recordTitle}</BreadcrumbsItem>
			</BreadcrumbsContainer>
			<Main>
				{!record
					? <Alert>
						<FontAwesomeIcon icon={faCircleInfo} className='me-2' />
						Запись {recordTitle} не найдена
					</Alert>
					:
					<>
						<h1>{recordTitle}</h1>

						<div className='btn-toolbar gap-3 my-3'>
							{!immutable && hasPermission('catalogEdit') &&
								<>
									<Link to={getApiResourceEditPath(apiResource, recordId)} className='btn btn-outline-primary'>
										<FontAwesomeIcon icon={faPenToSquare} className='me-2' />
										Редактировать
									</Link>
									<button
										className='btn btn-outline-danger'
										onClick={handleDelete}
									>
										<FontAwesomeIcon icon={faTrash} className='me-2' />
										Удалить
									</button>
								</>
							}
							{!!record.files?.length &&
								<button className='btn btn-outline-primary' onClick={showFilesModal}>
									Прикреплённые файлы
								</button>
							}
						</div>
						<table className='table table-hover mb-0' style={{ width: 'fit-content' }}>
							<tbody>
								{fieldList.map(({ label, value, path }, index) =>
									<tr key={index}>
										<td className='text-muted ps-2'>{label}</td>
										<td className='pe-2'>
											{path && path(record)
												? <NavLink to={path(record)!}>{value(record)}</NavLink>
												: value(record)}
										</td>
									</tr>)}
							</tbody>
						</table>
					</>
				}
			</Main>
		</>
	)
}
import { useEffect, useMemo } from 'react'
import { routes } from '@/config/routes'
import { useTabsContext } from '@/context/TabsContext'
import useProjectId from '@/hooks/useProjectId'
import TabsList, { DefaultTab } from '@/components/common/tabs/TabsList'


const ProjectNav = () => {
  const projectId = useProjectId()
  const { tabList, closeTab } = useTabsContext()

  const projectPath = routes.project(projectId)

  const defaultTabs: DefaultTab[] = useMemo(() => [
    {
      label: 'Дашборд',
      path: projectPath,
      end: true,
    },
  ], [projectPath])

  useEffect(() => {
    tabList
      .filter(t => !t.path.includes(projectPath))
      .forEach(t => closeTab(t.path, projectPath))
  }, [projectPath])

  return <TabsList defaultTabs={defaultTabs} redirect={projectPath} />
}

export default ProjectNav

import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IProjectForm } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { routes } from '@/config/routes'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useProjectListContext } from '@/context/tables/ProjectListContext'
import { FormContextProvider } from '@/context/form/FormContext'
import Alert from '@/components/common/Alert'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import Main from '@/components/common/Main'
import ProjectForm from '@/components/ProjectsContent/ProjectForm'


type Props = {
  isAudit?: boolean
}

/** Контент страницы создания проекта */
export default function CreateProjectPageContent({ isAudit = false }: Props) {
  const navigate = useNavigate()
  const { loadRecordList: loadProjectList, createRecord: createProject } = useProjectListContext()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  useDocumentTitle('Создать проект')

  const handleSubmit = useCallback(async (projectForm: IProjectForm) => {
    setIsLoading(true)
    try {
      await createProject({ ...projectForm, isAudit })
      await loadProjectList()
      navigate(isAudit ? routes.auditProjects : routes.projects)
    } catch (e: any) {
      setError(await getErrorMessage(e))
    }
    setIsLoading(false)
  }, [loadProjectList, createProject, navigate, isAudit])

  return (
    <>
      {!isAudit &&
        <BreadcrumbsContainer>
          <BreadcrumbsItem path={routes.projects}>Проекты</BreadcrumbsItem>
          <BreadcrumbsItem>Создать проект</BreadcrumbsItem>
        </BreadcrumbsContainer>
      }
      <Main>
        <h1>Создать проект</h1>
        {error && <Alert>{error}</Alert>}
        <FormContextProvider>
          <ProjectForm
            disabled={isLoading}
            onSubmit={handleSubmit}
            cancelRoute={isAudit ? routes.auditProjects : routes.projects}
            isAudit={isAudit}
          />
        </FormContextProvider>
      </Main>
    </>
  )
}
type Props = {
	min?: number
	now: number
	max: number
	isAnimated?: boolean
}

export default function ProgressBar({ min = 0, now, max, isAnimated = false }: Props) {
	return (
		<div className='progress'>
			<div className={`progress-bar progress-bar-striped ${isAnimated && ' progress-bar-animated'}`}
				role='progressbar'
				aria-valuemin={min}
				aria-valuenow={now}
				aria-valuemax={max}
				style={{ width: `${(now - min) / (max - min) * 100}%` }}></div>
		</div>
	)
}
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, getApiResourceListRoute, routes } from '@/config/routes'
import { UserRole } from '@/config/roles'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useCctvListContext } from '@/context/tables/CctvListContext'
import { useModal } from '@/context/ModalContext'
import UploadBtn from '@/components/common/btns/UploadBtn'
import ToolbarMenu from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenu'
import ToolbarMenuButton from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenuButton'
import ToolbarMenuDivider from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenuDivider'
import ToolbarMenuDownloadLink from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenuDownloadLink'
import {
  faCode, faDolly, faFileZipper, faHeart, faLocationDot, faRedo, faTable, faTrash,
} from '@fortawesome/free-solid-svg-icons'
import ToolbarSearchGroup from '@/components/ProjectsContent/ProjectToolbar/ToolbarSearchGroup'
import useProjectId from '@/hooks/useProjectId'
import getErrorMessage from '@/utils/getErrorMessage'
import { ApiResource, ICctv } from '@/context/types'
import UpdateVideoMarkerModalContent from '@/components/modals/common/UpdateVideoMarkerModalContent'
import { ipCameraTableRows } from '@/config/tables/cards/hardware/ipCamera'
import { projectReportApiCodes, reportNames } from '@/config/reports'
import { typedObjectEntries } from '@/utils/object'


/** Панель управления проектом со строкой поиска и меню Проект/Отчеты/Мониторинг */
export default function ProjectToolbar() {
  const projectId = useProjectId()
	const { showModalDialog } = useModal()
	const { hasRole } = useAuthContext()
  const { setRecordListSampler: setCctvListSampler } = useCctvListContext()
  const navigate = useNavigate()

	/** Открыть вкладку главной таблицы проекта (со сбросом сэмплера) */
	const openCctvTable = useCallback(() => {
    navigate(routes.projectCctvs(projectId))
		setCctvListSampler({}, '')
	}, [navigate, routes, projectId, setCctvListSampler])

  const handleClearVideoMarkers = useCallback(() => {
    const header = 'Очистка видеомаркеров'

    showModalDialog({
      type: 'okCancel',
      header,
      content:
        <>
          Вы уверены, что хотите очистить видеомаркеры всех камер проекта?<br />
          Видеомаркеры будут удалены только в системе СМО
        </>,
      primaryCallback: async () => {
        try {
          showModalDialog({
            type: 'close',
            header,
            content: 'Обновление...',
          })
          await axios.post(apiRoutes.dropProjectMarkers(projectId))
          showModalDialog({
            type: 'ok',
            header,
            content: 'Видеомаркеры очищены',
            primaryCallback: () => navigate(0),
          })
        } catch (e: any) {
          showModalDialog({
            type: 'error',
            header: `${header}: ошибка`,
            content: await getErrorMessage(e),
          })
        }
      },
    })
  }, [showModalDialog, projectId, navigate])

  const handleUpdateVideoMarkers = useCallback(() => {
    const header = 'Обновление видеомаркеров'

    showModalDialog({
      type: 'okCancel',
      header,
      content:
        <>
          Вы уверены, что хотите обновить видеомаркеры всех камер проекта?<br/>
          Камерам будет присвоено значение из поля "{ipCameraTableRows.codeOnCctv.label}"
        </>,
      primaryCallback: async () => {
        try {
          const res = await axios.get<ICctv[]>(
            getApiResourceListRoute(ApiResource.Cctv), {
              params: {
                pagination: false,
                groups: ['id:read'],
                'project.id': projectId,
              },
            })

          showModalDialog({
            type: 'abort',
            header,
            content:
              <UpdateVideoMarkerModalContent
                recordApiRoute={apiRoutes.updateCctvVideoMarkers}
                recordIdSet={new Set(res.data.map(c => c.id))}
                onClose={() => navigate(0)}
              />,
          })
        } catch (e: any) {
          showModalDialog({
            type: 'error',
            header: `${header}: ошибка`,
            content: await getErrorMessage(e),
          })
        }
      },
    })
  }, [showModalDialog, projectId, navigate])

	return (
		<div style={{ width: '100%', height: 46 }}>
			<div
        className='container-fluid bg-light d-flex justify-content-center'
        style={{ position: 'fixed', width: '100%', zIndex: 200, height: 46 }}
      >
				<div className='btn-toolbar flex-nowrap gap-2 my-2' >
					{/* Поиск */}
					<ToolbarSearchGroup />
					{/* Проект */}
					<ToolbarMenu label='Проект'>
						<ToolbarMenuButton
							label='Главная таблица проекта'
							icon={faTable}
							onClick={openCctvTable} />
						<ToolbarMenuDivider />
						<ToolbarMenuButton
							label='Очистить видеомаркеры всех камер'
							icon={faTrash}
							onClick={handleClearVideoMarkers}
							disabled={!hasRole(UserRole.admin)}
						/>
						<ToolbarMenuButton
							label='Обновить видеомаркеры всех камер'
							icon={faRedo}
							onClick={handleUpdateVideoMarkers}
							disabled={!hasRole(UserRole.admin)}
						/>
						<ToolbarMenuDivider />
						<ToolbarMenuDownloadLink
							label='Экспорт эталонных изображений для архива'
							icon={faFileZipper}
							to={apiRoutes.exportReferencesSnapshots(projectId, 'archive')}
							disabled={!hasRole(UserRole.admin)}
						/>
						<ToolbarMenuDivider />
						<UploadBtn
							className='dropdown-item'
							label='Импорт из БЭИ'
							accept=".csv"
							apiRoute={apiRoutes.importFromRSB(projectId)}
							disabled={!hasRole(UserRole.admin)}
						/>
					</ToolbarMenu>
					{/* Отчеты */}
					<ToolbarMenu label='Отчеты'>
            {typedObjectEntries(projectReportApiCodes).map(([code, apiCode]) =>
              <ToolbarMenuButton
                key={code}
                label={reportNames[code]}
                icon={faDolly}
                onClick={() => navigate(routes.projectReport(projectId, apiCode))}
              />,
            )}
						<ToolbarMenuDivider />
						<ToolbarMenuDownloadLink
							label='Экспорт эталонных изображений для БЭИ'
							icon={faFileZipper}
							to={apiRoutes.exportReferencesSnapshots(projectId, 'storage')}
							disabled={!hasRole(UserRole.admin)}
						/>
						<ToolbarMenuDownloadLink
							label='Экспорт в БЭИ'
							icon={faCode}
							to={apiRoutes.exportToRSB(projectId)}
							disabled={!hasRole(UserRole.admin)}
						/>
					</ToolbarMenu>
					{/* Мониторинг */}
					<ToolbarMenu label='Мониторинг'>
						<ToolbarMenuButton label='Оборудование' icon={faHeart} onClick={() => navigate(routes.projectMonitoring(projectId))} />
						<ToolbarMenuButton label='Карта' icon={faLocationDot} onClick={() => navigate(routes.projectMonitoringMap(projectId))} />
					</ToolbarMenu>
				</div>
			</div>
		</div>
	)
}
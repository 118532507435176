import { useState } from 'react'
import { ApiResource, IPec, IPecStatusField, IUser } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import Loader from '@/components/common/Loader'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'


type Props = {
  pec: IPec
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>
}

type Form = {
  contractor: IUser['id']
}

export default function PecEditContractorForm({ pec, statusFieldId, reloadCard }: Props) {
  const { form } = useFormContext<Form>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  usePrimaryCallback(async () => {
    if (isLoading)
      return false
    
    setIsLoading(true)

    try {
      await axios.patch(
        apiRoutes.updatePecContractor(pec.id),
        {
          status_field_id: statusFieldId,
          contractor_id: form.contractor,
        },
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении ПО',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }, [isLoading, setIsLoading, form.contractor, pec, statusFieldId, reloadCard])

  return (
    <div style={{minHeight: 375}}>
      <ApiResourceSelect<IPec['contractors'][0], Form>
        apiResource={ApiResource.Organization}
        label='ПО'
        name='contractor'
        initValue={pec.contractors.find(e => e.statusFieldId === statusFieldId)}
        disabled={isLoading}
      />
      {isLoading && <Loader />}
    </div>
  )
}

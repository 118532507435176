import { useCallback, useEffect, useId, useState } from 'react'
import Select, { SingleValue } from 'react-select'
import { ISelectOption } from '@/context/types'
import { useFormContext } from '@/context/form/FormContext'
import InputGroup from '@/components/common/form/InputGroup'
import { defaultFilterOption } from '@/utils/recordToSelectOption'


type Props<TForm> = {
  label: string
  name?: keyof TForm
  initValue?: ISelectOption['value']
  optionList: ISelectOption[]
  placeholder?: string
  required?: boolean
  helpText?: string
  disabled?: boolean
}


/** Селект по списку для контекстной формы */
export default function ListSelect<TForm>({
  label,
  name,
  initValue,
  optionList,
  placeholder = '',
  required,
  helpText,
  disabled,
}: Props<TForm>) {
  const { setField, errors } = useFormContext<TForm>()
  const [option, setOption] = useState<ISelectOption | null>(null)
  const id = useId()

  useEffect(() => {
    setOption(optionList.find(option => option.value === initValue) ?? null)
    if (name) setField(name, initValue)
  }, [name, initValue, optionList, setField])

  const handleChange = useCallback((option: SingleValue<ISelectOption>) => {
    setOption(option)
    if (name) setField(name, option ? option.value : null)
  }, [name, setField])

  return (
    <InputGroup
      id={id}
      label={label}
      required={required}
      helpText={helpText}
      invalidMessage={name && errors[name]}>
      <Select<ISelectOption>
        className={name && errors[name] && 'is-invalid'}
        id={id}
        value={option}
        filterOption={defaultFilterOption}
        placeholder={placeholder}
        options={optionList}
        isClearable={true}
        isDisabled={disabled}
        onChange={handleChange}
      />
    </InputGroup>
  )
}

import { IPec, IPecStatusField } from '@/context/types'


type Props = {
  pec: IPec
  pecStatusFieldId: IPecStatusField['id']
}

const PecStatusHistory = ({ pec, pecStatusFieldId }: Props) =>
  <div style={{overflowX: 'auto'}}>
    История изменений статусов:
    <table className='table table-sm table-hover'>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Пользователь</th>
          <th>Вид работ</th>
          <th>Старый статус</th>
          <th>Новый статус</th>
        </tr>
      </thead>
      <tbody>
        {pec.statusFieldLogHistoryEntries
          .filter(entry => entry.statusField.id === pecStatusFieldId)
          .map(entry =>
            <tr key={entry.id}>
              <td className='pe-2'>{entry.createdDatetime.toLocaleString('ru')}</td>
              <td className='pe-2'>{entry.actor?.name}</td>
              <td className='pe-2'>{entry.statusField.name}</td>
              <td className='pe-2'>{entry.oldValue?.name ?? '-'}</td>
              <td className='pe-2'>{entry.newValue.name}</td>
            </tr>,
          )
        }
      </tbody>
    </table>
  </div>

export default PecStatusHistory
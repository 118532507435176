import FolderCard from '@/components/common/cards/FolderCard'
import { useCallback } from 'react'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '@/context/auth/AuthContext'


const DitStatus = ({ data, reloadCard }:any) => {
  const { showModalDialog } = useModal()
  const { serviceId } = useParams()
  const { hasPermission } = useAuthContext()

  const handleCheck = useCallback(async () => {
    try {
      await axios.patch(
        apiRoutes.service(serviceId as string),
        { hasOrderFromDit: !data.hasOrderFromDit},
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении статуса',
        content: await getErrorMessage(e),
      })
    }
  }, [[data.hasOrderFromDit, reloadCard, showModalDialog]])


  return (
    <FolderCard
      headerBgColor='#E9ECEF'
      header={
        <>
          Заказ получен от ДИТ
          <input
            className='form-check-input mx-2'
            type='checkbox'
            checked={data.hasOrderFromDit}
            onChange={handleCheck}
            disabled={!hasPermission('channelCreate')}
          />
        </>
      }
    />
  )
}

export default DitStatus
import { copyObjectByKeys } from '@/utils/tools'


export const reportCodes = [
  'ipCamerasAvailableByMaintenanceContractor',
  'incidents',
  'incidentsByStages',
  'cctvsStatusHistory',
  'ipCamerasAvailableByChannelOperators',
  'cctvsAudit',
  'hardwareRfc35962',
  'ipCamerasAcceptance',
  'hardware',
] as const

export type ReportCode = typeof reportCodes[number]


export const reportNames = {
  ipCamerasAvailableByMaintenanceContractor: 'Доступность камер по подрядчикам эксплуатации',
  incidents: 'Аварийные ситуации',
  incidentsByStages: 'Сводный по ТТ',
  cctvsStatusHistory: 'История статусов работ по ТВН',
  ipCamerasAvailableByChannelOperators: 'Доступность камер по операторам связи',
  cctvsAudit: 'Статус по аудиту ТВН',
  hardwareRfc35962: 'Технический отчет (RFC-35962)',
  ipCamerasAcceptance: 'Приёмка камер',
  hardware: 'Оборудование',
} as const satisfies Record<ReportCode, string>


export const projectReportApiCodes = {
  ipCamerasAvailableByMaintenanceContractor: 'ip-cameras-available-by-maintenance-contractors',
  incidents: 'incidents',
  incidentsByStages: 'incidents-by-stages',
  cctvsStatusHistory: 'history-of-work-statuses-by-cctvs',
  ipCamerasAvailableByChannelOperators: 'ip-cameras-available-by-operators-channels',
  cctvsAudit: 'cctvs-audit-statuses',
  hardwareRfc35962: 'hardware-rfc-35962',
  ipCamerasAcceptance: 'ip-cameras-acceptance',
  hardware: 'hardware',
} as const satisfies Record<ReportCode, string>

export const exploitationReportApiCodes = copyObjectByKeys(
  projectReportApiCodes,
  reportCodes.filter(c => c !== 'cctvsAudit') as ExcludeFromArray<typeof reportCodes, 'cctvsAudit'>
)

export const allReportApiCodes = {
  ...projectReportApiCodes,
  ...exploitationReportApiCodes,
} as const

export const subReportApiCodes = {
  incidentsOpenedByStage: 'incidents-opened-by-stage',
  incidentsClosedByStage: 'incidents-closed-by-stage',
  ipCameraIncidentsOpenedByMaintenanceContractors: 'ip-camera-incidents-opened-by-maintenance-contractors',
  ipCameraUnavailableByMaintenanceContractor: 'ip-cameras-unavailable-by-maintenance-contractors',
  ipCameraIncidentsOpenedByChannelOperators: 'ip-camera-incidents-opened-by-operators-channels',
  ipCameraUnavailableByChannelOperators: 'ip-cameras-unavailable-by-operators-channels',
}

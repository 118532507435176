import { PropsWithChildren } from 'react'
import { ApiResource } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { hardwareApiResources } from '@/config/hardware'


type DownloadLinkProps = PropsWithChildren<{
  resource: Parameters<typeof apiRoutes.downloadCsvTemplate>[0]
  className?: string
  params?: Record<string, string>
}>

const TemplateLink = ({ resource, className = '', params, children }: DownloadLinkProps) =>
  <a href={apiRoutes.downloadCsvTemplate(resource, params)} target='_blank' className={className}>шаблон {children}</a>

export type Props = {
  importApiResource: ApiResource
  templateLinkParams?: DownloadLinkProps['params']
}

export const InfoModalImportText = ({ importApiResource, templateLinkParams }: Props) => (
  <>
    <h5 className='mt-4'>Импорт через файл</h5>
    {Object.values(hardwareApiResources).includes(importApiResource)
      ? <>
        <p className='mb-0'>
          1. a) Скачать
          <TemplateLink resource={importApiResource} params={{ action: 'create' }} className='ms-1'>
            для добавления нового оборудования
          </TemplateLink>
        </p>
        <p className='mb-2 ms-3'>
          б) Скачать
          <TemplateLink resource={importApiResource} className='ms-1'>
            для обновления существующего оборудования
          </TemplateLink>
        </p>
      </>
      : <p className='mb-2'>
        1. Скачать <TemplateLink resource={importApiResource} params={templateLinkParams}>файла импорта</TemplateLink>
        {importApiResource === ApiResource.Cctv && 
          <>
            <span className='ms-1'>или</span>
            <TemplateLink resource='hardware' params={templateLinkParams} className='ms-1'>
              файла привязки оборудования
            </TemplateLink>
          </>
        }
        {importApiResource === ApiResource.Pec && 
          <>
            <span className='ms-1'>или</span>
            <TemplateLink resource='audit-users' params={templateLinkParams} className='ms-1'>
              файла импорта пользователей
            </TemplateLink>
          </>
        }
      </p>
    }
    <p className='mb-2'>2. Заполнить, сохранить в формате "csv (разделитель — запятая)" в кодировке UTF-8</p>
    <p className='mb-2'>3. Импортировать</p>
  </>
)

import { useCallback, useEffect } from 'react'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { routes } from '@/config/routes'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { IContract, IIdRecord, ISelectOption, TableFilter } from '@/context/types'
import { ContractNumberSelect } from '@/components/communication/ContractNumberSelect'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import { Props as RecordsPerPageSelectProps, RecordsPerPageSelect } from '@/components/common/RecordsPerPageSelect'
import ExportBtn from '@/components/common/btns/ExportBtn'
import Select from 'react-select'
import { defaultFilterOption } from '@/utils/recordToSelectOption'


type BillingTypeOption = ISelectOption<string>

type Props<TColumnName extends string> = {
  recordListLength?: number
  recordListCount?: number
  fullRecordIdSet?: Set<IIdRecord['id']>
  onReload: () => void
  exportBtnLabel?: string
  exportApiRoute?: string
  filterForm: TableFilter<TColumnName>,
  updateFilterForm: (name: TColumnName, value?: string, callSetFilter?: boolean) => void
  applyFilter: () => void,
  handleShowFilterInfo: () => void
} & RecordsPerPageSelectProps

export default function BillingTableToolbar<TColumnName extends string>({ //todo-sem merge w/ TableToolbar
  fullRecordIdSet,
  onReload,
  exportBtnLabel,
  exportApiRoute,
  filterForm,
  recordListLength,
  recordListCount,
  updateFilterForm,
  handleShowFilterInfo,
  recordsPerPage,
  setRecordsPerPage,
}: Props<TColumnName>) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const contractNumber = filterForm['contractNumber' as TColumnName]

  const updateFilterContractNumber = useCallback((val: IContract['number']) => {
    updateFilterForm('contractNumber' as TColumnName, val || undefined, true)
  }, [updateFilterForm])

  useEffect(() => {
    const contractNumber = searchParams.get('contractNumber')
    if (contractNumber)
      updateFilterContractNumber(contractNumber)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const billingTypeOptions: BillingTypeOption[] = [
    { label: 'Общая часть', value: routes.billingCommon },
    { label: 'Доходная часть', value: routes.billingRevenue },
    { label: 'Затратная часть', value: routes.billingExpenses },
    { label: 'Затратная часть. Порты', value: routes.billingPortExpenses },
  ]

  return (
    <div className='w-100' style={{ height: 125, zIndex: 100 }}>
      <div className='container-fluid pt-2 position-fixed bg-white' style={{ height: 125 }}>
        <div className='btn-toolbar gap-2 align-items-center'>
          <div style={{ width: 500, height: 30 }} className='d-flex'>
            <Select<BillingTypeOption>
              className='w-100'
              options={billingTypeOptions}
              filterOption={defaultFilterOption}
              onChange={option => navigate(`${option!.value}?${new URLSearchParams(searchParams)}`)}
              value={billingTypeOptions.find(o => o.value === pathname)}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  transform: 'scale(0.79)',
                  fontSize: 18,
                  marginTop: -5,
                  marginLeft: -15,
                }),
              }}
            />
            <ContractNumberSelect
              value={contractNumber}
              onChange={contractNumber => {
                setSearchParams(contractNumber ? { contractNumber } : {})
                updateFilterContractNumber(contractNumber)
              }}
            />
          </div>
          <div>
            Показано {recordListLength} {!!recordListCount && `из ${recordListCount}`} записей.
          </div>
          <RecordsPerPageSelect recordsPerPage={recordsPerPage} setRecordsPerPage={setRecordsPerPage} />
          {exportApiRoute &&
            <div className='container-fluid bg-white' style={{ bottom: '120px', width: 670, marginRight: -22 }}>
              <div className='container-fluid mb-2 d-flex justify-content-end'>
                <ExportBtn label={exportBtnLabel} apiRoute={exportApiRoute} ids={fullRecordIdSet} />
              </div>
            </div>
          }
          <button
            className='btn btn-link p-0 ms-auto'
            onClick={handleShowFilterInfo}>
            <FontAwesomeIcon icon={faInfoCircle} className='mx-2' />
            Справка
          </button>
          <ReloadBtn onClick={onReload} />
        </div>
      </div>
    </div>
  )
}
import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'
import { IChannel, IHistoryStatusTypeOutput } from '@/context/types'
import { getBgColorClassName } from '@/utils/color'
import { typedObjectKeys } from '@/utils/object'
import { channelStatusLabels } from '@/config/status/labels'


type Props = {
  channel: IChannel
}

export default function HistoryStatusChannel({ channel }: Props) {
  let stage: IHistoryStatusTypeOutput
  const outputData = channel.logStatusHistoryEntries.map(item => {
    stage = item.stage
      ? {
        ...item.stage,
        ...{ createdBy: item.actor?.name, createdDatetime: item.createdDatetimeStr },
      }
      : stage
    return {
      ...item,
      ...{
        stage,
      },
    }
  })
  return (
    <div className='card border-0 mb-2'>
      <div
        className={`card-header border-0 px-2 py-1 justify-content-between ${cn(
          style.cardHeader,
          style.cardHeaderHistory,
        )} `}
      >
        <div>
          <button
            className={`btn text-primary p-0 ${style.cardHeaderArrow}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseHistoryStatus'
            aria-expanded='false'
            aria-controls='collapseHistoryStatus'
          >
            <b>История статусов канала</b>
          </button>
        </div>
      </div>
      <div className='collapse' id='collapseHistoryStatus'>
        <div className={`card-body border-0 ${style.cctvInfoCollapse} p-0`}>
          <table className={`table-hover table-bordered table-sm mb-0 ${style.table}`}>
            <tbody className='align-top'>
              {outputData.map(statusHistoryEntry => (
                <tr key={statusHistoryEntry.id}>
                  {typedObjectKeys(channelStatusLabels).map(key =>
                    <td key={key} className={getBgColorClassName(statusHistoryEntry[key].code)}>
                      <p className='m-0'>{statusHistoryEntry[key].name}</p>
                      <p className='m-0'>{statusHistoryEntry[key].createdDatetime}</p>
                      <p className='m-0'>{statusHistoryEntry[key].createdBy}</p>
                    </td>,
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { TableFilter } from '@/context/types'


/** Хук для работы с формой фильтров таблицы */
export default function useTableFilterForm<TColumnName extends string>(filter: TableFilter<TColumnName>, setFilter: (filter: TableFilter<TColumnName>) => void) {
  const [filterForm, setFilterForm] = useState<TableFilter<TColumnName>>({})

  // Обновить форму фильтра по самому фильтру
  useEffect(() => {
    setFilterForm(filter)
  }, [filter])

  /** Обновить поле формы фильтра */
  const updateFilterForm = useCallback((name: TColumnName, value?: string, callSetFilter = false) => {
    setFilterForm(filterForm => {
      filterForm = { ...filterForm }

      if (value)
        filterForm[name] = value
      else
        delete filterForm[name]

      if (callSetFilter)
        setTimeout(() => setFilter(filterForm))

      return filterForm
    })
  }, [setFilterForm])

  /** Применить фильтр */
  const applyFilter = useCallback(() => {
    setFilter(filterForm)
  }, [filterForm, setFilter])

  /** Сбросить фильтр */
  const clearFilter = useCallback(() => {
    setFilterForm({})
    setFilter({})
  }, [setFilterForm, setFilter])

  return {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter,
  }
}

import { ICctv, IHardwareSet } from '@/context/types'
import CctvAttachedFiles from '@/components/CctvListPageContent/Cctv/Folder/blocks/CctvAttachedFiles'
import CctvAudit from '@/components/CctvListPageContent/Cctv/Folder/blocks/CctvAudit'
import CctvInfoCard from '@/components/CctvListPageContent/Cctv/Folder/blocks/cctvInfo/CctvInfoCard'
import CommentsCard from '@/components/CctvListPageContent/Cctv/Folder/blocks/CommentsCard'
import HardwareListCard from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardwareList/HardwareListCard'
import HistoryCard from '@/components/common/cards/HistoryCard'
import HistoryStatusCctv from '@/components/CctvListPageContent/Cctv/Folder/blocks/HistoryStatusCctv'
import CctvStatusCard from '@/components/CctvListPageContent/Cctv/Folder/blocks/statuses/CctvStatusCard'
import HardwareCard from '@/components/common/cards/hardware/HardwareCard'
import { videoNetworkSwitchTableRows } from '@/config/tables/cards/hardware/videoNetworkSwitch'
import { editHardwareStatusModal, editIpCameraInfoModal } from '@/components/modals/hardware/modals'
import { communicationNetworkSwitchTableRows } from '@/config/tables/cards/hardware/communicationNetworkSwitch'
import { routerTableRows } from '@/config/tables/cards/hardware/router'
import { ipCameraTableRows } from '@/config/tables/cards/hardware/ipCamera'
import { videoServerTableRows } from '@/config/tables/cards/hardware/videoServer'
import { upsTableRows } from '@/config/tables/cards/hardware/ups'
import { accessPointTableRows } from '@/config/tables/cards/hardware/accessPoint'
import { UserRole } from '@/config/roles'
import { useAuthContext } from '@/context/auth/AuthContext'
import CctvPecAttachedFiles from '@/components/CctvListPageContent/Cctv/Folder/blocks/CctvPecAttachedFiles'


type Props = {
  cctv: ICctv
  hardwareSet: IHardwareSet
  reloadCctv: () => Promise<void>
}


/** Сведенья о ТВН */
export const Folder = ({ cctv, hardwareSet, reloadCctv }: Props) => {
  const { hasRole } = useAuthContext()
  return (
    <div className='row g-2'>
      <div className='col-xl'>
        <CctvStatusCard
          cctv={cctv}
          reloadCard={reloadCctv} />
        <CctvInfoCard
          cctv={cctv}
          reloadCctv={reloadCctv}
        />
        <HardwareListCard
          item={cctv}
          hardwareSet={hardwareSet}
          reloadCard={reloadCctv} />
        <CctvAudit cctv={cctv} />
        <CctvAttachedFiles
          cctv={cctv}
          reloadCctv={reloadCctv} />
        <CctvPecAttachedFiles
          pec={cctv}
          reloadCctv={reloadCctv} />
        <CommentsCard
          item={cctv}
          reloadCard={reloadCctv}
          canCreate={hasRole([UserRole.admin, UserRole.mgts, UserRole.contractor])}
          canDelete={hasRole([UserRole.admin])}
        />
        <HistoryCard logHistoryEntries={cctv.logHistoryEntries} label='ТВН' />
        <HistoryStatusCctv cctv={cctv} />
        <small className='text-muted ms-2'>
          ТВН ID: <span className='font-monospace'>{cctv.id}</span>
        </small>
      </div>
      <div className='col-xl'>
        {hardwareSet.videoNetworkSwitches.map(networkSwitch =>
          <HardwareCard
            key={networkSwitch.id}
            headerBgColor='#d0bfff'
            bodyBgColor='#f3f0ff'
            rows={videoNetworkSwitchTableRows}
            hardware={networkSwitch}
            editHardwareModal={editHardwareStatusModal}
            item={cctv}
            reloadCard={reloadCctv}
          />,
        )}
        {hardwareSet.communicationNetworkSwitches.map(networkSwitch =>
          <HardwareCard
            key={networkSwitch.id}
            headerBgColor='#d0bfff'
            bodyBgColor='#f3f0ff'
            rows={communicationNetworkSwitchTableRows}
            hardware={networkSwitch}
            editHardwareModal={editHardwareStatusModal}
            item={cctv}
            reloadCard={reloadCctv}
          />,
        )}
        {hardwareSet.routers.map(router =>
          <HardwareCard
            key={router.id}
            headerBgColor='#d0bfff'
            bodyBgColor='#f3f0ff'
            rows={routerTableRows}
            hardware={router}
            editHardwareModal={editHardwareStatusModal}
            item={cctv}
            reloadCard={reloadCctv}
          />,
        )}
        {hardwareSet.ipCameras.map(ipCamera =>
          <HardwareCard
            key={ipCamera.id}
            headerBgColor='#bac8ff'
            bodyBgColor='#edf2ff'
            rows={ipCameraTableRows}
            hardware={ipCamera}
            editHardwareModal={editIpCameraInfoModal}
            item={cctv}
            reloadCard={reloadCctv}
          />,
        )}
        {hardwareSet.videoServers.map(videoServer =>
          <HardwareCard
            key={videoServer.id}
            headerBgColor='#a5d8ff'
            bodyBgColor='#e7f5ff'
            rows={videoServerTableRows}
            hardware={videoServer}
            editHardwareModal={editHardwareStatusModal}
            item={cctv}
            reloadCard={reloadCctv}
          />,
        )}
        {hardwareSet.upses.map(ups =>
          <HardwareCard
            key={ups.id}
            headerBgColor='#d8c0ef'
            bodyBgColor='#f7f1fd'
            rows={upsTableRows}
            hardware={ups}
            editHardwareModal={editHardwareStatusModal}
            item={cctv}
            reloadCard={reloadCctv}
          />,
        )}
        {hardwareSet.accessPoints.map(accessPoint =>
          <HardwareCard
            key={accessPoint.id}
            headerBgColor='#9fc0fd'
            bodyBgColor='#e1ebfd'
            rows={accessPointTableRows}
            hardware={accessPoint}
            editHardwareModal={editHardwareStatusModal}
            item={cctv}
            reloadCard={reloadCctv}
          />,
        )}
      </div>
    </div>
  )
}

import { IIncidentStageLogRecord } from '@/context/types'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import Loader from '@/components/common/Loader'
import IncidentStageLogRecord from '@/components/modals/incidents/IncidentStageLogRecord'


/** Журнал этапов инцидента */
export default function IncidentStageLog() {
	const { isRecordListLoading, recordList } = useApiResourceContext<IIncidentStageLogRecord>()

	if (isRecordListLoading) return <Loader />

	return (
		<table className='table table-sm table-hover'>
			<thead className='table-secondary'>
				<tr>
					<th style={{ width: '5%' }}>№ <span className='text-nowrap'>п/п</span></th>
					<th style={{ width: '15%' }}>Дата</th>
					<th style={{ width: '15%' }}>Пользователь</th>
					<th style={{ width: '15%' }}>С этапа</th>
					<th style={{ width: '15%' }}>На этап</th>
					<th style={{ width: '40%' }}>Комментарий</th>
				</tr>
			</thead>
			<tbody>
				{recordList.map((record, index) =>
					<IncidentStageLogRecord
						key={record.id}
						index={index}
						logRecord={record}
						isEditable={index === recordList.length - 1} />)}
			</tbody>
		</table>
	)
}

import { Dispatch, useReducer } from 'react'
import { IProject } from '@/context/types'


/** Project store state interface */
export interface ProjectState {
  project?: IProject
  isProjectLoading: boolean
}

/** Project store actions */
type ProjectAction =
  | { type: 'projectFetched' }
  | { type: 'projectDetailsLoaded', payload: IProject }
  | { type: 'projectLoaded' }


/** Project store initial state */
const projectInitState: ProjectState = {
  isProjectLoading: false,
}


/** Project store reducer */
function projectReducer(state: ProjectState, action: ProjectAction): ProjectState {
  switch (action.type) {
    case 'projectFetched':
      return {
        ...state,
        isProjectLoading: true,
        project: undefined,
      }
    case 'projectDetailsLoaded':
      return {
        ...state,
        project: action.payload,
        isProjectLoading: false,
      }
    case 'projectLoaded':
      return {
        ...state,
        isProjectLoading: false,
      }
    default:
      return state
  }
}


/** Project store state & reducer hook */
export const useProjectReducer:
  () => [ProjectState, Dispatch<ProjectAction>] =
  () => useReducer(projectReducer, projectInitState)


// Project store action creators
export const projectFetchedAction =
  (): ProjectAction =>
    ({ type: 'projectFetched' })
export const projectDetailsLoadedAction =
  (project: IProject): ProjectAction =>
    ({ type: 'projectDetailsLoaded', payload: project })
export const projectLoadedAction =
  (): ProjectAction =>
    ({ type: 'projectLoaded' })

import {
  faBatteryThreeQuarters, faCamera, faCircle, faExclamationTriangle, faEye, faNetworkWired, faVideo, faWifi,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HardwareStateCode } from '@/context/types'
import { _HardwareStateCode, hardwareColorClasses } from '@/components/ProjectsContent/monitoring/map/config/index'
import { HardwareTypeCode } from '@/config/hardware'


export type IconType = 
  | 'cctv'
  | Exclude<HardwareTypeCode, HardwareTypeCode.Router>
  | 'hardware'
  | 'incident'
  | 'storage'

const icons: Record<IconType, IconDefinition> = {
  cctv: faEye,
  [HardwareTypeCode.VideoNetworkSwitch]: faNetworkWired,
  [HardwareTypeCode.CommunicationNetworkSwitch]: faNetworkWired,
  [HardwareTypeCode.IpCamera]: faCamera,
  [HardwareTypeCode.VideoServer]: faVideo,
  [HardwareTypeCode.Ups]: faBatteryThreeQuarters,
  [HardwareTypeCode.AccessPoint]: faWifi,
  hardware: faCircle,
  incident: faExclamationTriangle,
  storage: faExclamationTriangle,
}

export const getIcon = (type: IconType, state?: HardwareStateCode) =>
  <FontAwesomeIcon icon={icons[type]} className={state !== undefined ? hardwareColorClasses[state as _HardwareStateCode] : ''} />

export type DateRange = { from: Date, to: Date }

export const getMonthsDiff = (a: Date, b: Date): number =>
  12 * (b.getFullYear() - a.getFullYear()) + b.getMonth() - a.getMonth()

export const getDaysDiff = (a: number | string | Date, b: number | string | Date) => {
  const treatAsUTC = (date: number | string | Date) => {
    const result = new Date(date)
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
    return result
  }

  return (+treatAsUTC(a) - +treatAsUTC(b)) / (24 * 60 * 60 * 1000)
}

export function* generateMonthsRange(from: Date, to: Date) {
  if (to < from)
    return false

  const monthDate = new Date(from)
  while (monthDate <= to) {
    yield new Date(monthDate)

    monthDate.setMonth(monthDate.getMonth() + 1)
  }
}

export function* generateDaysRange(from: Date, to: Date) {
  if (to < from)
    return false

  const dayDate = getBeginningOfDay(from)
  while (dayDate <= to) {
    yield new Date(dayDate)

    dayDate.setDate(dayDate.getDate() + 1)
  }
}

export const millisecondsToDateString = (ms: number) => {
  const roundedMinutes = Math.round(ms / 1000 / 60)
  const minutes = Math.trunc(ms / 1000 / 60)
  const hours = Math.trunc(minutes / 60)

  const d = Math.trunc(hours / 24)
  const h = hours % 24
  const m = roundedMinutes % 60

  return `${d ? `${d}д` : ''}${h ? `${h}ч` : ''}${m}м`
}

export const isSameDay = (a: Date, b: Date) =>
  a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()

export const getBeginningOfMonth = (date: number | string | Date) => {
  const res = getBeginningOfDay(date)
  res.setDate(1)
  return res
}

export const getBeginningOfDay = (date: number | string | Date) => {
  const res = new Date(date)
  res.setHours(0, 0, 0, 0)
  return res
}

export const getEndOfDay = (date: number | string | Date) => {
  const res = getBeginningOfDay(date)
  res.setDate(res.getDate() + 1)
  res.setMilliseconds(-1)
  return res
}

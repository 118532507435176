import { ICctv } from '@/context/types'
import IncidentLink from '@/components/modals/incidents/IncidentLink'
import { useParams } from 'react-router-dom'


type Props = {
  incidents: ICctv['incidents']
  onUpdate: () => any
  showStatus?: boolean
}

const IncidentList = ({ incidents, onUpdate, showStatus = true }: Props) => {
  const { projectId } = useParams()
  
  if (!incidents.length)
    return showStatus ? <>Нет</> : <></>

  return (
    <>
    {showStatus ? 'Есть:' : ''}
      {incidents.map(({ incident }) =>
        <div key={incident.id} className='text-nowrap'>
          <IncidentLink projectId={projectId} incidentId={incident.id} onUpdate={onUpdate} />
        </div>)}
    </>
  )
}

export default IncidentList

import { IPec, IPecStatusField } from '@/context/types'
import { ShowModalDialogParams } from '@/context/ModalContext'
import { AnyFormContextProvider } from '@/context/anyForm/AnyFormContext'
import { FormContextProvider } from '@/context/form/FormContext'
import PecCreateEditForm, { Props as PecCreateEditFormProps } from '@/components/modals/pec/PecCreateEditForm'
import PecEditExecutorForm from '@/components/modals/pec/PecEditExecutorForm'
import PecEditWorkBeginDateForm from '@/components/modals/pec/PecEditWorkBeginDateForm'
import PecEditContractorForm from '@/components/modals/pec/PecEditContractorForm'
import PecEditBrigadierForm from '@/components/modals/pec/PecEditBrigadierForm'
import PecEditResponsibleFromCustomerForm from '@/components/modals/pec/PecEditResponsibleFromCustomerForm'


export const createEditPecInfoModal = (
  projectId: string,
  onSave: PecCreateEditFormProps['onSave'],
  pec?: IPec
): ShowModalDialogParams => ({
  type: 'saveCancel',
  dismissable: false,
  size: 'lg',
  header: pec ? `Редактирование информации УИК-${pec.number}` : `Создание УИК`,
  content:
    <FormContextProvider>
      <AnyFormContextProvider>
        <PecCreateEditForm projectId={projectId} pec={pec} onSave={onSave} />
      </AnyFormContextProvider>
    </FormContextProvider>
})

export const editPecContractor = (
  pec: IPec,
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>,
): ShowModalDialogParams => {
  return {
    type: 'saveCancel',
    dismissable: false,
    header: `Изменить ПО`,
    content:
      <FormContextProvider>
        <PecEditContractorForm
          pec={pec}
          statusFieldId={statusFieldId}
          reloadCard={reloadCard}
        />
      </FormContextProvider>,
  }
}

export const editPecResponsibleFromCustomer = (
  pec: IPec,
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>,
): ShowModalDialogParams => {
  return {
    type: 'saveCancel',
    dismissable: false,
    header: `Изменить модератора`,
    content:
      <FormContextProvider>
        <PecEditResponsibleFromCustomerForm
          pec={pec}
          statusFieldId={statusFieldId}
          reloadCard={reloadCard}
        />
      </FormContextProvider>,
  }
}

export const editPecBrigadier = (
  pec: IPec,
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>,
  selectedOrganizationId: string | undefined,
): ShowModalDialogParams => {
  return {
    type: 'saveCancel',
    dismissable: false,
    header: `Изменить бригадира`,
    content:
      <FormContextProvider>
        <PecEditBrigadierForm
          pec={pec}
          statusFieldId={statusFieldId}
          reloadCard={reloadCard}
          selectedOrganizationId={selectedOrganizationId}
        />
      </FormContextProvider>,
  }
}

export const editPecExecutor = (
  pec: IPec,
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>,
  selectedOrganizationId: string | undefined,
): ShowModalDialogParams => {
  return {
    type: 'saveCancel',
    dismissable: false,
    header: `Изменить исполнителя`,
    content:
      <FormContextProvider>
        <PecEditExecutorForm
          pec={pec}
          statusFieldId={statusFieldId}
          reloadCard={reloadCard}
          selectedOrganizationId={selectedOrganizationId}
        />
      </FormContextProvider>,
  }
}

export const editPecWorkBeginDate = (
  pec: IPec,
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>,
): ShowModalDialogParams => {
  return {
    type: 'saveCancel',
    dismissable: false,
    header: `Изменить дату начала работ`,
    content:
      <FormContextProvider>
        <PecEditWorkBeginDateForm
          pec={pec}
          statusFieldId={statusFieldId}
          reloadCard={reloadCard}
        />
      </FormContextProvider>,
  }
}

import { ApiResourceFilter, IRecordWithId, TableColumnConfig, TableFilter } from '@/context/types'
import { capitalize } from '@/utils/string'
import { typedObjectEntries } from '@/utils/object'


const filterToParams = <TColumnName extends string, TRecord extends IRecordWithId>(
  filter: TableFilter<TColumnName>,
  tableColumns?: Record<TColumnName, TableColumnConfig<TRecord>>,
) => {
  let viewTable = ''
  let additionalFilter = ''

  const params: ApiResourceFilter = {}

  for (const [key, val] of typedObjectEntries(filter)) {
    if (!val)
      continue

    const col = tableColumns?.[key]
    const isCustomCol = !col
    let customFieldParamsKey = ''

    let paramsField = col?.filterAndSortViewKey ?? col?.filterKey ?? key //for now custom cols 'key' contains 'col.filterAndSortViewKey'
    if (paramsField.includes(':')) // is view filter
      [viewTable, paramsField] = paramsField.split(':')
    if (paramsField.includes('&')) // pec list additional filter
      [additionalFilter, paramsField] = paramsField.split('&')
    if (paramsField.includes('/'))
      [customFieldParamsKey, paramsField] = paramsField.split('/')

    if (additionalFilter) {
      const [additionalParamsKey, additionalParamsField] = additionalFilter.split('=')
      params[additionalParamsKey] = additionalParamsField
    }

    const paramsExistsKey = col?.filterExistsKey ?? paramsField
    if (!paramsField)
      continue

    let filterType: 'empty' | 'exact' | 'not' | 'or' | 'and' | 'partial'
    if (val === '!')
      filterType = 'empty'
    else if (val.slice(-1) === '!')
      filterType = 'exact'
    else if (val.slice(-1) === '?')
      filterType = 'not'
    else if (val.includes('|'))
      filterType = 'or'
    else if (val.includes('&'))
      filterType = 'and'
    else
      filterType = 'partial'

    const addParamByFilterType = () => {
      let paramsKey
      let paramsVal

      switch (filterType) {
        case 'empty':
          paramsKey = 'exists'
          paramsVal = 'false'
          break
        case 'exact':
          paramsKey = viewTable ? 'eq' : ''
          paramsVal = val.slice(0, -1)
          break
        case 'not':
          paramsKey = 'notLike'
          paramsVal = val.slice(0, -1)
          break
        case 'or':
          paramsKey = 'or'
          paramsVal = val.split('|').filter(v => v).join('|')
          break
        case 'and':
          paramsKey = 'and'
          paramsVal = val.split('&').filter(v => v).join('|')
          break
        case 'partial':
          paramsKey = 'or'
          paramsVal = `${val}|`
          break
      }

      if (viewTable) { 
        params[`view[${viewTable}][${paramsField}][${paramsKey}]`] = paramsVal
        return
      }

      if (isCustomCol && customFieldParamsKey)
        paramsKey = paramsKey ? paramsKey + capitalize(customFieldParamsKey) : customFieldParamsKey

      if (paramsKey)
        params[`${paramsKey}[${filterType === 'empty' ? paramsExistsKey : paramsField}]`] = paramsVal
      else
        params[paramsField] = paramsVal
    }

    switch (col?.type) {
      case 'bool':
        params[paramsField] = val
        break
      case 'boolExists':
        params[`exists[${paramsExistsKey}]`] = val
        break
      case 'ipAddressExists':
        if (filterType === 'empty')
          params[`emptyIpAddress[${paramsExistsKey}]`] = 'true'
        else
          addParamByFilterType()
        break
      case 'date':
        params[`${paramsField}[before]`] = val
        params[`${paramsField}[after]`] = val
        break
      default:
        addParamByFilterType()
    }
  }

  return params
}

export default filterToParams

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { downloadFile, getFileNameFromContentDisposition } from '@/utils/tools'
import getErrorMessage from '@/utils/getErrorMessage'
import { IRecordWithId } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { useState } from 'react'
import Loader from '@/components/common/Loader'


type Props = {
  apiRoute: string
  label?: string
  ids?: Set<IRecordWithId['id']>
}

const ExportBtn = ({ apiRoute, label = 'Экспорт', ids }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showModalDialog } = useModal()

  const exportTable = async () => {
    setIsLoading(true)

    try {
      const res = await axios({
        url: apiRoute,
        method: 'POST',
        responseType: 'blob',
        data: { ids: ids?.size ? [...ids] : null },
      })

      downloadFile(res.data, getFileNameFromContentDisposition(res.headers['content-disposition']) || 'file.xlsx')
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка экспорта',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }

  return (
    <button
      className='btn btn-sm btn-outline-primary text-nowrap'
      disabled={isLoading || ids && !ids.size}
      onClick={exportTable}
    >
      <div className='d-inline-block me-1' style={{ width: 16 }}>
        {isLoading
          ? <div style={{ transform: 'translateY(2px)' }}><Loader size={15} /></div>
          : <FontAwesomeIcon icon={faDownload} />
        }
      </div>
      {label}
    </button>
  )
}

export default ExportBtn

import { Fragment, useEffect, useState } from 'react'
import { arrayDiff, arrayIntersect } from '@/utils/array'
import CheckboxListOption from '@/components/ProjectsContent/monitoring/map/controls/filter/CheckboxListOption'
import { Option, OptionsArray } from '@/components/ProjectsContent/monitoring/map/controls/filter/OptionsArray'
import { FunctionsArrayRef, useFunctionsArrayRefPush } from '@/hooks/useFunctionsArrayRef'
import useStringArrayState from '@/hooks/useStringArrayState'
import { strIncludesAnyCase } from '@/utils/string'


type Props<Id extends string, SubId extends string = string> = {
  label: string
  options: OptionsArray<Id, SubId>
  onChange: (options: Id[]) => any
  onSubOptionsChange?: (options: SubId[]) => any
  subOptionsFilter?: string
  resetFunctionsRef: FunctionsArrayRef
  closeFunctionsRef: FunctionsArrayRef
  checkAllFunctionsRef: FunctionsArrayRef
}

const CheckboxList = <Id extends string, SubId extends string = string>({
  label,
  options,
  onChange,
  onSubOptionsChange,
  subOptionsFilter,
  resetFunctionsRef,
  closeFunctionsRef,
  checkAllFunctionsRef,
}: Props<Id, SubId>) => {
  const [isOpen, setIsOpen] = useState(false)

  const [checkedOptions, checkOption, uncheckOption] = useStringArrayState<Id>()
  const [indeterminateOptions, indeterminateOption, determinateOption] = useStringArrayState<Id>()
  const [checkedSubOptions, checkSubOptions, uncheckSubOptions] = useStringArrayState<SubId>()

  const handleOptionChange = (option: Option<Id, SubId>, value: boolean) => {
    (value ? checkOption : uncheckOption)(option.id)

    if (option.subOptions.length) {
      const subOptions = (value ? arrayDiff : arrayIntersect)(option.subOptions.map(so => so.id), checkedSubOptions)

      if (subOptions.length)
        (value ? checkSubOptions : uncheckSubOptions)(...subOptions)

      determinateOption(option.id)
    }
  }

  const handleSubOptionChange = (option: Option<Id, SubId>, subOption: Option<SubId>, value: boolean) => {
    const newSubOptions = (value ? checkSubOptions : uncheckSubOptions)(subOption.id)

    if (value) {
      if (option.subOptions.every(so => newSubOptions.includes(so.id)))
        checkOption(option.id)
    } else if (checkedOptions.includes(option.id))
      uncheckOption(option.id)

    if (arrayIntersect(option.subOptions.map(so => so.id), newSubOptions).length !== option.subOptions.length)
      indeterminateOption(option.id)
    else
      determinateOption(option.id)
  }

  useEffect(() => { onChange(checkedOptions) }, [onChange, checkedOptions])
  useEffect(() => { onSubOptionsChange?.(checkedSubOptions) }, [onSubOptionsChange, checkedSubOptions])

  useFunctionsArrayRefPush(resetFunctionsRef, () => {
    uncheckOption(...checkedOptions)
    determinateOption(...indeterminateOptions)
    uncheckSubOptions(...checkedSubOptions)
  })

  useFunctionsArrayRefPush(closeFunctionsRef, () => setIsOpen(false))

  useFunctionsArrayRefPush(checkAllFunctionsRef, () => {
    checkOption(...options.map(o => o.id))
    determinateOption(...indeterminateOptions)
    checkSubOptions(...options.map(o => o.subOptions).flat().map(so => so.id))
  })

  return (
    <div className='mt-2'>
      <span className='text-primary' role='button' onClick={() => setIsOpen(!isOpen)}>{label}</span>
      {isOpen && options.map(option =>
        <Fragment key={option.id}>
          <CheckboxListOption
            className={option.className || ''}
            checkedOptions={checkedOptions}
            indeterminateOptions={indeterminateOptions}
            onChange={value => handleOptionChange(option, value)}
            option={option}
          />
          {subOptionsFilter && option.subOptions
            .filter(so => strIncludesAnyCase(so.name, subOptionsFilter))
            .map(subOption =>
              <CheckboxListOption
                key={option.id + subOption.id}
                className='ms-4'
                checkedOptions={checkedSubOptions}
                onChange={value => handleSubOptionChange(option, subOption, value)}
                option={subOption}
              />,
            )
          }
        </Fragment>,
      )}
    </div>
  )
}

export default CheckboxList

import { NavLink } from 'react-router-dom'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


type Props = {
	label: string
	labelSm?: string
	to: string
	icon?: IconDefinition
	className?: string
	disabled?: boolean
}


/** Элемент главного меню */
export default function NavItem({ label, labelSm, to, icon, className = '', disabled = false }: Props) {

	return (
		<li className={`nav-item ${className}`}>
			<NavLink to={to} className={`nav-link ${disabled ? 'disabled' : ''}`}>
        {[false, true].map(isMobile => 
          <div
            key={+isMobile}
            className={`w-100 ${isMobile ? 'd-block d-sm-none' : 'd-none d-sm-block'}`}
            data-bs-toggle={isMobile ? 'collapse' : undefined}
            data-bs-target={isMobile ? '#nav-bar' : undefined}
          >
            {icon && <FontAwesomeIcon icon={icon} className='me-2 d-sm-none d-lg-inline' />}
            <span className='d-sm-none d-lg-inline'>
              {label}
            </span>
            <span className='d-sm-inline d-lg-none'>
              {labelSm ?? label}
            </span>
          </div>
        )}
			</NavLink>
		</li>
	)
}
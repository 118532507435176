import { IPec, IPecStatusField, IStatusStage } from '@/context/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import PecStatusControlMenuItem from '@/components/audit/Folder/statuses_info_card/statuses/PecStatusControlMenuItem'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserRole } from '@/config/roles'


type Props = {
  pec: IPec
  statusFieldName: IPecStatusField['id']
  statusList: IStatusStage[]
  statusName?: string
  reloadCard: () => void
}

export default function PecStatusControlMenu({
  pec,
  statusFieldName,
  statusList,
  statusName,
  reloadCard,
}: Props) {
  const { hasPermission } = useAuthContext()

  if (!statusList.length || !hasPermission('pecStageEdit'))
    return <div>{statusName}</div>

  return (
    <div className='dropdown' style={{ position: 'static', zIndex: 1 }}>
      <button
        className='btn btn-link p-0 text-decoration-none'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        {statusName}
        <FontAwesomeIcon icon={faCaretDown} className='ms-2' />
      </button>
      <ul
        className='dropdown-menu dropdown-menu-end'
        aria-labelledby='dropdownMenuButton'
      >
        {statusList.map(status =>
          <li key={status.code}>
            <PecStatusControlMenuItem
              pec={pec}
              status={status}
              statusFieldId={statusFieldName}
              reloadCard={reloadCard}
            />
          </li>,
        )}
      </ul>
    </div>
  )
}

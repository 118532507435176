import { instanceAxios as axios } from '@/api/instanceAxios'
import { ICatalogFiles } from '@/context/types'
import { ApiResourceConfig } from '@/config/apiResources'


type FileUploadRoute = NonNullable<ApiResourceConfig<any>['fileUploadRoute']>

const attachFilesToCatalog = async (
  files: FileList,
  fileUploadRoute: FileUploadRoute,
  id: Parameters<FileUploadRoute>[0],
) => {
  const formData = new FormData()

  for (const file of Array.from(files))
    formData.append('files[]', file)

  const res = await axios.postForm<ICatalogFiles>(fileUploadRoute(id), formData)
  
  return res.data
}

export default attachFilesToCatalog

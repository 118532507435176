import { DependencyList, useCallback, useRef } from 'react'


const isEqual = (obj1: any, obj2: any): boolean => {
	if (obj1 === obj2) return true
	if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) return false
	if (obj1.constructor !== obj2.constructor) return false
	for (var p in obj1) {
	  if (!obj1.hasOwnProperty(p)) continue
	  if (!obj2.hasOwnProperty(p)) return false
	  if (obj1[p] === obj2[p]) continue
	  if (typeof obj1[p] !== 'object') return false
	  if (!isEqual(obj1[p], obj2[p])) return false
	}
	for (p in obj2) {
	  if (obj2.hasOwnProperty(p) && !obj1.hasOwnProperty(p)) return false
	}
	return true
  }

/** useCallback hook with deep compare in dependency list */
export default function useDeepCompareCallback<T extends Function>(callback: T, dependencies: DependencyList): T {
	const currentDependenciesRef = useRef<DependencyList>()

	if (!isEqual(currentDependenciesRef.current, dependencies)) {
		currentDependenciesRef.current = dependencies
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useCallback(callback, [currentDependenciesRef.current])
}
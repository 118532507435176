import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiResource } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getApiResourceDetailsPath, getApiResourceListPath, routes } from '@/config/routes'
import { ApiResources } from '@/config/apiResources'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useModal } from '@/context/ModalContext'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import { FormContextProvider } from '@/context/form/FormContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CatalogForm from '@/components/catalogs/CatalogForm'
import Main from '@/components/common/Main'
import attachFilesToCatalog from '@/utils/attachFilesToCatalog'


/** Контент страницы создания записи справочника */
export default function CreateRecordPageContent() {
	const params = useParams()
	const apiResource = params.apiResource as ApiResource
	const navigate = useNavigate()
	const { showModalDialog } = useModal()
	const { loadRecordList, createRecord } = useApiResourceContext()
	const [isLoading, setIsLoading] = useState(false)
	const { title, fileUploadRoute } = ApiResources[apiResource]!
	const [files, setFiles] = useState(null as FileList | null)

	useDocumentTitle(`${title}: добавить запись`)

	const handleSubmit = useCallback(async (form: any) => {
		setIsLoading(true)
		try {
			const recordId = await createRecord(form)

			if (files?.length)
				await attachFilesToCatalog(files, fileUploadRoute!, recordId as string)

			loadRecordList()
			navigate(getApiResourceDetailsPath(apiResource, recordId))
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: `${title}: ошибка`,
				content: await getErrorMessage(e),
			})
		}
		setIsLoading(false)
	}, [createRecord, loadRecordList, title, apiResource, navigate, showModalDialog, files, fileUploadRoute])

	return (
		<>
			<BreadcrumbsContainer>
				<BreadcrumbsItem path={routes.catalogs}>Справочники</BreadcrumbsItem>
				<BreadcrumbsItem path={getApiResourceListPath(apiResource)}>{title}</BreadcrumbsItem>
				<BreadcrumbsItem>Добавить запись</BreadcrumbsItem>
			</BreadcrumbsContainer>
			<Main>
				<h1 className='mb-4'>
					{title}:<br />
					<small>
						Добавить запись
					</small>
				</h1>
				{fileUploadRoute &&
					<div className='mb-3'>
						<label className='form-label'>Файлы</label>
						<input
							type='file'
							className='form-control'
							multiple
							onChange={e => setFiles(e.target.files)}
							accept='.pdf, .doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png, .iso, .vso, .tiff, .txt, .xml'
							disabled={isLoading}
						/>
					</div>
				}
				<FormContextProvider>
					<CatalogForm
						apiResource={apiResource}
						onSubmit={handleSubmit}
						disabled={isLoading} />
				</FormContextProvider>
			</Main>
		</>
	)
}
import { IIncident } from '@/context/types'
import Stopwatch from '@/components/common/time/Stopwatch'
import IncidentLink from '@/components/modals/incidents/IncidentLink'
import IncidentMessage from '@/components/modals/incidents/IncidentMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'


type Props = {
	incident: IIncident
	reloadCctv: () => Promise<void>
}

export default function HardwareCardIncidentListItem({ incident, reloadCctv }: Props) {
  const { projectId } = useParams()

	return (
		<div key={incident.id}>
			<table className='table table-sm table-borderless table-hover w-100'>
				<tbody>
					<tr className='border-bottom'>
						<td colSpan={2}>
							<FontAwesomeIcon icon={faTriangleExclamation} className='text-danger me-2' />
							<IncidentLink projectId={projectId} incidentId={incident.id} onUpdate={reloadCctv} />
							{' от '}
							{incident.openDatetime.toLocaleString()}
							{incident.parent && ' (дочерний)'}
							{incident.children && !!incident.children.length && ' (групповой)'}
						</td>
					</tr>
					<tr >
						<td className='text-muted' width='1%'>Cистема</td>
						<td>{incident.origin?.name ?? <span className='text-muted'>СМО</span>}</td>
					</tr>
					<tr>
						<td className='text-muted'>Пользователь</td>
						<td>{incident.createdByUser
							? <>
								{incident.createdByUser.firstName}
								{' '}
								{incident.createdByUser.lastName}
							</>
							: <span className='text-muted'>Система</span>}
						</td>
					</tr>
					<tr>
						<td className='text-muted text-nowrap'>Текущий этап</td>
						<td>{incident.stage?.name}</td>
					</tr>
					<tr>
						<td className='text-muted text-nowrap'>Время на этапе</td>
						<td>
              <Stopwatch initialDatetime={incident.updatedDatetime} />
							{' '}
							<span className='text-muted'>
								с {incident.updatedDatetime.toLocaleString()}
							</span>
						</td>
					</tr>
					<tr>
						<td className='text-muted'>Комментарий</td>
						<td>
							<IncidentMessage>
								{incident.description}
							</IncidentMessage>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

import { NavLink } from 'react-router-dom'
import { routes } from '@/config/routes'
import { useProjectContext } from '@/context/project/ProjectContext'
import { useProjectListContext } from '@/context/tables/ProjectListContext'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'


type Props = {
	end?: boolean
}

export default function ProjectBreadcrumbsItem({ end = false }: Props) {
	const { recordList: projectList } = useProjectListContext()
	const { project } = useProjectContext()
	const projectName = project?.name ?? 'Загрузка...'
	const projectPath = project ? routes.project(project.id) : undefined

	if (!projectList.length)
		return (
			<BreadcrumbsItem path={end ? undefined : projectPath}>
				{projectName}
			</BreadcrumbsItem>
		)

	return (
		<li className='breadcrumb-item'>
			{projectPath && !end
				?
				<>
					<NavLink to={projectPath}>
						{projectName}
					</NavLink>
					<span className='dropdown-toggle dropdown-toggle-split ps-2 pe-0'
						role='button'
						id='project-breadcrumbs-item-dropdown'
						data-bs-toggle='dropdown'
						aria-expanded='false'
						data-bs-reference='parent'
						data-bs-offset='21,0'
					>
					</span>
				</>
				:
				<span className='dropdown-toggle'
					role='button'
					id='project-breadcrumbs-item-dropdown'
					data-bs-toggle='dropdown'
					aria-expanded='false'
				>
					<span className='me-1'>{projectName}</span>
				</span>
			}
			<ul className='dropdown-menu' aria-labelledby='project-breadcrumbs-item-dropdown'>
				{projectList.map(project =>
					<li key={project.id}>
						<NavLink to={routes.project(project.id)} className='dropdown-item'>
							{project.name}
						</NavLink>
					</li>
				)}
			</ul>
		</li>
	)
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'


type Props = {
  onClick: () => void
}

export const ReloadBtn = ({ onClick }: Props) =>
  <button onClick={onClick} className='btn btn-sm btn-outline-primary rounded-circle'>
    <FontAwesomeIcon icon={faSync} />
  </button>

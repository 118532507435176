type Props = {
  size?: number
}

const Loader = ({ size = 32 }: Props) =>
  <div className='h-100 d-flex justify-content-center'>
    <div
      className={`align-self-center spinner-border ${size < 32 ? 'spinner-border-sm' : 'text-primary'}`}
      role='status'
      style={{ width: size, height: size }}
    />
  </div>

export default Loader

import ToolbarMenu from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenu'
import ToolbarMenuButton from '@/components/ProjectsContent/ProjectToolbar/ToolbarMenuButton'
import { faDolly, faHeart, faLocationDot, faTable } from '@fortawesome/free-solid-svg-icons'
import { routes } from '@/config/routes'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useCctvListContext } from '@/context/tables/CctvListContext'
import ToolbarSearchGroup from '@/components/ProjectsContent/ProjectToolbar/ToolbarSearchGroup'
import { typedObjectEntries } from '@/utils/object'
import { exploitationReportApiCodes, reportNames } from '@/config/reports'


export default function ExploitationToolbar() {
  const { setRecordListSampler: setCctvListSampler } = useCctvListContext()
  const navigate = useNavigate()

  const openCctvTable = useCallback(() => {
    navigate(routes.exploitationCctvs)
    setCctvListSampler({}, '')
  }, [navigate, setCctvListSampler])

  return (
    <div style={{ width: '100%', height: 46 }}>
      <div
        className='container-fluid bg-light d-flex justify-content-center'
        style={{ position: 'fixed', width: '100%', zIndex: 200, height: 46 }}
      >
        <div className='btn-toolbar flex-nowrap gap-2 my-2'>
          <ToolbarSearchGroup />
          <ToolbarMenu label='Эксплуатация'>
            <ToolbarMenuButton
              label='Главная таблица эксплуатации'
              icon={faTable}
              onClick={openCctvTable}
            />
          </ToolbarMenu>
          <ToolbarMenu label='Отчеты'>
            {typedObjectEntries(exploitationReportApiCodes).map(([code, apiCode]) =>
              <ToolbarMenuButton
                key={code}
                label={reportNames[code]}
                icon={faDolly}
                onClick={() => navigate(routes.exploitationReport(apiCode))}
              />,
            )}
					</ToolbarMenu>
          <ToolbarMenu label='Мониторинг'>
            <ToolbarMenuButton label='Оборудование' icon={faHeart} onClick={() => navigate(routes.exploitationMonitoring)} />
            <ToolbarMenuButton label='Карта' icon={faLocationDot} onClick={() => navigate(routes.exploitationMonitoringMap)} />
          </ToolbarMenu>
        </div>
      </div>
    </div>
  )
}

import { ReactNode } from 'react'


type Props = {
  condition: string,
  If: ReactNode,
  Else: ReactNode
}

export const TwigIf = ({ condition, If, Else }: Props) =>
  <>
    {`{% if ${condition} %}`}
    {If}
    {'{% else %}'}
    {Else}
    {'{% endif %}'}
  </>

import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { ApiResource } from '@/context/types'
import { ApiResources } from '@/config/apiResources'
import { getApiResourceListPath } from '@/config/routes'
import { useFormContext } from '@/context/form/FormContext'


type Props = {
	apiResource: ApiResource
	instance?: any
	onSubmit: (form: Record<string, any>) => void
	disabled: boolean
}


/** Форма ресурса API */
export default function CatalogForm({ apiResource, instance, onSubmit, disabled }: Props) {
	const { form, setError } = useFormContext<any>()
	const { formFieldList, cleanForm } = ApiResources[apiResource]!

	const handleSubmit = useCallback(() => {
		let valid = true
		for (const { required, disabled, name, isMulti } of formFieldList) {
			const value = form[name]
			if (
				required?.(form)
				&& !disabled?.(form)
				&& (isMulti ? !value || !(value as string[]).filter(v => v).length : !value && value !== 0)
			) {
				setError(name, 'Обязательное поле')
				valid = false
			}
		}
		if (!valid) return
		const cleanedForm = cleanForm ? cleanForm(form, setError, instance) : form
		if (!cleanedForm) return
		onSubmit(cleanedForm)
	}, [form, setError, instance, formFieldList, cleanForm, onSubmit])

	return (
		<>
			{formFieldList.map(field => {
				const Control = field.control
				const filter = typeof field.filter === 'function' ? field.filter?.(form) : field.filter
				const controlDisabled = disabled || field.disabled?.(form)
				const controlRequired = !controlDisabled && field.required?.(form)
				return (
					<Control
						key={field.name}
						label={field.label}
						name={field.name}
						optionList={field.optionList}
						initValue={field.initValue(instance)}
						required={controlRequired}
						helpText={field.helpText}
						disabled={controlDisabled}
						apiResource={field.apiResource}
						filter={filter}
						async={field.async}
						isMulti={field.isMulti}
						recordToOption={field.recordToOption} />
				)
			})}
			<div className='btn-toolbar gap-3 mt-5'>
				<button className='btn btn-outline-primary'
					onClick={handleSubmit}
					disabled={disabled}>
					{instance ? 'Сохранить' : 'Создать'}
				</button>
				<Link to={getApiResourceListPath(apiResource)}
					className='btn btn-outline-secondary'>
					Отмена
				</Link>
			</div>
		</>
	)
}
import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{}>

/** Таблица параметров */
export default function ParamTable({ children }: Props) {
	return (
		<table className='table table-sm table-borderless table-hover mb-0'>
			<tbody>
				{children}
			</tbody>
		</table>
	)
}
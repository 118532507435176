import { CardTableRowConfig, IAccessPoint } from '@/context/types'
import { hardwareCommonTableRows } from '@/config/tables/cards/hardware/common'
import HardwareSerial from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardwareList/HardwareSerial'


export const accessPointTableRows: Record<string, CardTableRowConfig<IAccessPoint>> = {
  name: {
    label: 'Имя устройства',
    value: accessPoint => accessPoint.name,
  },
  ipAddress: hardwareCommonTableRows.ipAddress,
  subnetwork: hardwareCommonTableRows.subnetwork,
  model: hardwareCommonTableRows.model,
  firmware: hardwareCommonTableRows.firmware,
  status: hardwareCommonTableRows.status,
  id: hardwareCommonTableRows.id,
  networkSwitch: hardwareCommonTableRows.networkSwitch,
  address: hardwareCommonTableRows.address,
  locationDescription: hardwareCommonTableRows.locationDescription,
  serialNumber: {
    label: 'Серийный номер',
    value: accessPoint => <HardwareSerial hardware={accessPoint} />,
  },
}

import { instanceAxios as axios } from '@/api/instanceAxios'
import { ApiResource, IIPCamera, IProject, IProjectDashboard, IProjectField } from '@/context/types'
import { apiRoutes, getApiResourceListRoute, getApiResourceUpdateRoute } from '@/config/routes'


type PostFields = {
  sort: number
  onlyForSitronics: boolean
  projectFieldId: string | number | boolean | null
}

export const fetchProjectDashboard = async (projectId: IProject['id']) => {
  const res = await axios.get<IProjectDashboard>(apiRoutes.projectDashboard(projectId))
  return res.data
}

export const fetchProjectFields = async () => {
  const res = await axios.get<IProjectField[]>(getApiResourceListRoute(ApiResource.ProjectField), {
    params: { pagination: false }
  })
  return res.data
}

export const postProjectFields = async (projectId: string, fields: PostFields[]) => {
  await axios.post(
    apiRoutes.projectFieldsEdit(projectId), { fields }
  )
}

export const updateVideoMarker = async (ipCameraId: IIPCamera['id'], videoMarkerCode: IIPCamera['videoMarkerCode']) => {
  await axios.patch(
    getApiResourceUpdateRoute(ApiResource.IpCamera, ipCameraId),
    { videoMarkerCode },
    { headers: { 'Content-Type': 'application/merge-patch+json' } },
  )
}

const idAcceptanceStatus = 'Приёмка ИД'

export const cctvStatusLabels = {
  channelStatus: 'Работы по каналу',
  installationStatus: 'Работы по монтажу',
  acceptanceStatus: 'Работы по приёмке объекта',
  idAcceptanceStatus,
  installationPassivePartStatus: 'Монтаж пассивной части',
} as const

export const serviceStatusLabels = {
  stage: 'Статус услуги',
  idAcceptanceStatus,
  channelStage: 'Статус канала',
} as const

export const channelStatusLabels = {
  stage: 'Статус канала',
} as const

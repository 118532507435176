import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ICctv, IService } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** idAcceptanceStatus context */
const IdAcceptanceStatusListContext = createContext({} as IRecordListContext<(ICctv | IService)['idAcceptanceStatus']>)


/** idAcceptanceStatus context hook */
export const useIdAcceptanceStatusListContext = () => useContext(IdAcceptanceStatusListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** idAcceptanceStatus context provider */
export function IdAcceptanceStatusListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<(ICctv | IService)['idAcceptanceStatus']>
      Context={IdAcceptanceStatusListContext}
      listApiRoute={apiRoutes.idAcceptanceStatuses}
      deleteApiRoute={apiRoutes.idAcceptanceStatus}
      paginate={paginate}
      logName='idAcceptanceStatusList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
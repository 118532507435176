import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{
	colorName?: 'primary' | 'secondary' | 'warning' | 'danger' | 'light'
}>

export default function Alert({ children, colorName = 'danger' }: Props) {
	return (
		<div className={`alert alert-${colorName} py-2 mb-0`} role='alert'>
			{children}
		</div>
	)
}
import Tooltip from '@/components/common/Tooltip'
import { extRoutes } from '@/config/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { ICctv, IPec } from '@/context/types'


type Props = {
  item: ICctv | IPec
}

const ShowOnYaMap = ({ item }: Props) =>
  <>
    {item.address.name}
    <Tooltip title='Показать на Яндекс картах'>
      <a className='ms-3' href={extRoutes.showOnYaMap(item)} target='_blank' rel='noreferrer'>
        <FontAwesomeIcon icon={faLocationDot} />
      </a>
    </Tooltip>
  </>

export default ShowOnYaMap

import { useEffect, useMemo, useState } from 'react'
import { Hardware, IBaseHardware, ICctv, IHardwareSet, IPec } from '@/context/types'
import { HardwareTypeCode } from '@/config/hardware'
import { apiURL } from '@/config/routes'
import { errorHandle } from '@/utils/errorHandle'
import { fetchData } from '@/api/fetchData'
import useEffectOnce from '@/hooks/useEffectOnce'
import Loader from '@/components/common/Loader'
import {  getHardwareModelLabel } from '@/utils/hardware'
import { changeItemHardwareBinding } from '@/api/queries/hardware'
import { useModal } from '@/context/ModalContext'
import { getItemLabel } from '@/utils/tools'


type Props = {
	item: ICctv | IPec
	hardwareSet: IHardwareSet
	reloadCard: () => Promise<void>
}


/** Контент диалогового окна добавления оборудования */
export default function BindHardwareTable({ item, hardwareSet, reloadCard }: Props) {
	const [isLoading, setIsLoading] = useState(false)
  const { usePrimaryCallback } = useModal()
	const [hardwareInAddress, setHardwareInAddress] = useState<Hardware[]>([])
	const [checkedHardware, setCheckedHardware] = useState([] as IBaseHardware['id'][])

	const videoNetworkSwitchesInAddress = useMemo(() => {
		return hardwareInAddress.filter(hardware => hardware.type.code === HardwareTypeCode.VideoNetworkSwitch)
	}, [hardwareInAddress])
	const communicationNetworkSwitchesInAddress = useMemo(() => {
		return hardwareInAddress.filter(hardware => hardware.type.code === HardwareTypeCode.CommunicationNetworkSwitch)
	}, [hardwareInAddress])
	const routersInAddress = useMemo(() => {
		return hardwareInAddress.filter(hardware => hardware.type.code === HardwareTypeCode.Router)
	}, [hardwareInAddress])
	const ipCamerasInAddress = useMemo(() => {
		return hardwareInAddress.filter(hardware => hardware.type.code === HardwareTypeCode.IpCamera)
	}, [hardwareInAddress])
	const videoServersInAddress = useMemo(() => {
		return hardwareInAddress.filter(hardware => hardware.type.code === HardwareTypeCode.VideoServer)
	}, [hardwareInAddress])
	const upsesInAddress = useMemo(() => {
		return hardwareInAddress.filter(hardware => hardware.type.code === HardwareTypeCode.Ups)
	}, [hardwareInAddress])
	const accessPointsInAddress = useMemo(() => {
		return hardwareInAddress.filter(hardware => hardware.type.code === HardwareTypeCode.AccessPoint)
	}, [hardwareInAddress])

	const connectedToSwitchHardwareInAddress = useMemo(() => {
		return [...videoNetworkSwitchesInAddress, ...communicationNetworkSwitchesInAddress]
			.map(networkSwitch => [
				networkSwitch,
				...[
					...hardwareSet.videoNetworkSwitches,
					...hardwareSet.communicationNetworkSwitches,
					...routersInAddress,
					...ipCamerasInAddress,
					...videoServersInAddress,
					...upsesInAddress,
					...accessPointsInAddress,
				].filter(h => h.networkSwitchPort?.networkSwitch.id === networkSwitch.id),
			])
			.reduce((acc, item) => [...acc, ...item], [])
	}, [
    hardwareSet,
    videoNetworkSwitchesInAddress,
    communicationNetworkSwitchesInAddress,
    routersInAddress,
    ipCamerasInAddress,
    videoServersInAddress,
    upsesInAddress,
    accessPointsInAddress,
  ])

	const notConnectedToSwitchHardwareInAddress: Hardware[] = useMemo(() => {
		return [
			...routersInAddress,
			...ipCamerasInAddress,
			...videoServersInAddress,
			...upsesInAddress,
			...accessPointsInAddress
		].filter(hardware => !connectedToSwitchHardwareInAddress.find(({ id }) => id === hardware.id))
	}, [
    routersInAddress,
    ipCamerasInAddress,
    videoServersInAddress,
    upsesInAddress,
    accessPointsInAddress,
    connectedToSwitchHardwareInAddress,
  ])

  const connectedToItemHardware = useMemo(() => [
    ...hardwareSet.videoNetworkSwitches,
    ...hardwareSet.communicationNetworkSwitches,
    ...hardwareSet.routers,
    ...hardwareSet.ipCameras,
    ...hardwareSet.videoServers,
    ...hardwareSet.upses,
    ...hardwareSet.accessPoints,
  ], [hardwareSet])

	useEffect(() => {
    setCheckedHardware(connectedToItemHardware.map(h => h.id))
	}, [connectedToSwitchHardwareInAddress, notConnectedToSwitchHardwareInAddress, hardwareSet])

	useEffectOnce(() => {
		fetchData({
			url: `${apiURL}/hardware/hardware`,
			config: {
				headers: { Accept: 'application/json' },
				params: {
					'address.id': item.address.id,
					pagination: false,
				},
			},
			setData: setHardwareInAddress,
			setIsLoading,
			errorHandler: errorHandle,
		})
	})

  const handleClickChange = (id: IBaseHardware['id'], checked: boolean) =>
    setCheckedHardware(checked ? [...checkedHardware, id] : checkedHardware.filter(_id => _id !== id))

  usePrimaryCallback(async () => {
    const notConnectedToItemHardware = [
      ...videoNetworkSwitchesInAddress,
      ...communicationNetworkSwitchesInAddress,
      ...routersInAddress,
      ...ipCamerasInAddress,
      ...videoServersInAddress,
      ...upsesInAddress,
      ...accessPointsInAddress,
    ].filter(h => !connectedToItemHardware.some(ch => ch.id === h.id))

    await changeItemHardwareBinding(item, {
      bind: notConnectedToItemHardware.filter(h => checkedHardware.includes(h.id)).map(h => h.id),
      unbind: connectedToItemHardware.filter(h => !checkedHardware.includes(h.id)).map(h => h.id),
    })

    reloadCard()
  }, [
    videoNetworkSwitchesInAddress,
    communicationNetworkSwitchesInAddress,
    routersInAddress,
    ipCamerasInAddress,
    videoServersInAddress,
    upsesInAddress,
    accessPointsInAddress,
    reloadCard,
    checkedHardware,
  ])

	if (isLoading) return <Loader />

	return (
		<div className='table-responsive'>
			<table className='table table-sm table-hover'>
				<thead className='table-primary'>
					<tr>
						<th className='text-center'>Установлено<br/> на {getItemLabel(item)}</th>
						<th>Тип</th>
						<th>Имя устройства</th>
						<th>Модель</th>
						<th>Статус</th>
						<th>IP-адрес </th>
						<th>MAC-адрес</th>
						<th>VRF</th>
					</tr>
				</thead>
				<tbody>
          {[...connectedToSwitchHardwareInAddress, ...notConnectedToSwitchHardwareInAddress].map(hardware => (
            <tr key={hardware.id}>
              <td>
                <div className='form-check form-switch m-0 d-flex justify-content-center'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    checked={checkedHardware.includes(hardware.id)}
                    onChange={e => handleClickChange(hardware.id, e.target.checked)}
                  />
                </div>
              </td>
              <td>{hardware.networkSwitchPort && <>↳&nbsp;</>} {hardware.type.name}</td>
              <td>{hardware.code}</td>
              <td>{getHardwareModelLabel(hardware)}</td>
              <td>{hardware.status.name}</td>
              <td>{hardware.ipAddress}</td>
              <td>{'macAddress' in hardware && hardware.macAddress}</td>
              <td>
                {'vrfs' in hardware && hardware.vrfs?.map(vrf => vrf.name).join(', ')}
                {'vrf' in hardware && hardware.vrf?.name}
              </td>
            </tr>
          ))}
        </tbody>
			</table>
		</div>
	)
}
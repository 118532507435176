import { useState } from 'react'
import { IChannelAddress, IChannelAddressForm, IChannelForm } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import TextInput from '@/components/common/form/TextInput'


type Props = {
  onSave: () => any
  address?: IChannelAddress
}

const AddressEditForm = ({ onSave, address }: Props) => {
  const { showModalDialog, usePrimaryCallback } = useModal()
  const { form, setError } = useFormContext<IChannelForm>()

  const [isLoading, setIsLoading] = useState(false)

  usePrimaryCallback(async () => {
    try {
      setIsLoading(true)

      if (address) {
        const response = await axios.patch(
          apiRoutes.address(address.id),
          { ...form },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )

        if (response.status === 200)
          onSave()
      }
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `${address && 'Обновление'} адреса: Ошибка`,
        content: await getErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }, [form, address, showModalDialog, setError, onSave])

  return (
    <div className='m-5 display-flex m-2'>
      <TextInput<IChannelAddressForm>
        label='Принадлежность здания'
        initValue={address?.belonging ?? undefined}
        name='belonging'
        disabled={isLoading}
      />
      <TextInput<IChannelAddressForm>
        label='Комментарии к адресу'
        initValue={address?.comment ?? undefined}
        name='comment'
        disabled={isLoading}
      />
    </div>
  )
}

export default AddressEditForm

import { Dispatch, SetStateAction, useState } from 'react'
import { arrayUnique } from '@/utils/array'


type AddFunc<T> = (...vals: T[]) => T[]
type RemoveFunc<T> = AddFunc<T>

const useStringArrayState = <T extends string>(): [T[], AddFunc<T>, RemoveFunc<T>, Dispatch<SetStateAction<T[]>>] => {
  const [arr, setArr] = useState([] as T[])

  const add: AddFunc<T> = (...vals) => {
    const newArr = arrayUnique([...arr, ...vals]) as T[]
    setArr(newArr)
    return newArr
  }
  
  const remove: AddFunc<T> = (...vals) => {
    const newArr = arr.filter(so => !vals.includes(so))
    setArr(newArr)
    return newArr
  }

  return [arr, add, remove, setArr]
}

export default useStringArrayState

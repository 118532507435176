import { useCallback, useMemo, useState } from 'react'
import { ApiResource, IIncident, IProject } from '@/context/types'
import Stopwatch from '@/components/common/time/Stopwatch'
import { useModal } from '@/context/ModalContext'
import { ApiResourceContextProvider } from '@/context/apiResource/ApiResourceContext'
import { incidentDetailModal } from '@/components/modals/incidents/modals'
import IncidentMessage from '@/components/modals/incidents/IncidentMessage'
import IncidentStageLog from '@/components/modals/incidents/IncidentStageLog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'


type Props = {
	projectId?: IProject['id']
	incident: IIncident
	onUpdate: () => void
}


/** Строка таблицы инцидентов оборудования */
export default function IncidentTableRow({ projectId, incident, onUpdate }: Props) {
	const { showModalDialog } = useModal()
	const [isLogOpen, setIsLogOpen] = useState(false)

	const incidentStageLogFilter = useMemo(() => {
		return { 'incident.id': incident.id.toString() }
	}, [incident.id])

	const handleOpenIncident = useCallback(() => {
		showModalDialog(incidentDetailModal(projectId, incident.id, onUpdate))
	}, [incident.id, onUpdate, showModalDialog, projectId])

	const handleToggleIncidentStageLog = useCallback(() => {
		setIsLogOpen(isLogOpen => !isLogOpen)
	}, [setIsLogOpen])

	return (
		<>
			<tr className={`bg-opacity-10 ${incident.status ? 'bg-success' : 'bg-danger'}`}>
				<td>
					<button
						className='btn btn-link p-0'
						onClick={handleOpenIncident}>
						{incident.id}
					</button>
					{!incident.status &&
						<>
							<FontAwesomeIcon icon={faTriangleExclamation} className='text-danger mx-2' />
							Открытый инцидент
						</>}
					<br />
					<button
						className='btn btn-link p-0'
						onClick={handleToggleIncidentStageLog}>
						Журнал
						<FontAwesomeIcon icon={isLogOpen ? faCaretUp : faCaretDown} className='ms-2' />
					</button>
				</td>
				<td>
					{incident.createdDatetime.toLocaleString()}<br />
					{incident.origin?.name ?? <span className='text-muted'>СМО</span>}<br />
					{incident.createdByUser
						? <>
							{incident.createdByUser.firstName}
							{' '}
							{incident.createdByUser.lastName}
						</>
						: <span className='text-muted'>Система</span>}
				</td>
				<td>
					{incident.stage
						? <>
							{incident.stage.name}<br />
              <Stopwatch initialDatetime={incident.updatedDatetime} />
							{' '}
							<span className='text-muted'>
								c {incident.updatedDatetime.toLocaleString()}
							</span>
						</>
						: <>
							Инцидент закрыт<br />
							<span className='text-muted'>
								{incident.updatedDatetime.toLocaleString()}
							</span>
						</>}
				</td>
				<td>{incident.status?.name}</td>
				<td>
					<IncidentMessage>
						{incident.description}
					</IncidentMessage>
				</td>
			</tr>
			{isLogOpen &&
				<tr>
					<td colSpan={5}>
						<ApiResourceContextProvider
							apiResource={ApiResource.IncidentStageLog}
							filter={incidentStageLogFilter}>
							<IncidentStageLog />
						</ApiResourceContextProvider>
					</td>
				</tr>}
		</>
	)
}
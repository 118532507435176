import { cctvStatusLabels } from '@/config/status/labels'

export const CommunicationSamplerGroupLabels: Record<string, string> = {
  'serviceStatus.code': 'Статус услуг',
  'channelStatus.code': 'Статус каналов',
  'channelStage.code': 'Кол-во каналов по статусу каналов связи',
  'reportPeriod.name': 'Статус отчетных периодов',
  'orderFromDit.number': 'Статус номера заказа от Дит',
  'serviceIdAcceptanceStatuses.code': 'Приёмка ИД',
}

export const ProjectsSamplerGroupLabels: Record<string, string> = {
  'address.district.id': 'Район',
  'channelOperator.id': 'Оператор канала связи',
  'constructionContractor.id': 'Подрядчик СМР',
  'maintenanceContractor.id': 'Подрядчик ПНР',
  'channelStatus.code': cctvStatusLabels.channelStatus,
  'installationStatus.code': cctvStatusLabels.installationStatus,
  'acceptanceStatus.code': cctvStatusLabels.acceptanceStatus,
  'idAcceptanceStatus.code': cctvStatusLabels.idAcceptanceStatus,
  'installationPassivePartStatus.code': cctvStatusLabels.installationPassivePartStatus,
}

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IChannelAddress, IChannelAddressForm } from '@/context/types'
import {
  ChannelAddressTableColumnName, ChannelAddressTableColumns,
} from '@/config/tables/lists/channels/channelAddress'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Address list context */
const AddressListContext = createContext(
  {} as TableApiResourceContextValue<IChannelAddress, ChannelAddressTableColumnName, IChannelAddressForm>
)


/** Address list context hook */
export const useAddressListContext = () => useContext(AddressListContext)


type Props = PropsWithChildren<{}>

/** Address list context provider */
export function AddressListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IChannelAddress, ChannelAddressTableColumnName, IChannelAddressForm>
      Context={AddressListContext}
      apiResource={ApiResource.ChannelAddress}
      loadIds
      recordIdKey='addressId'
      tableColumns={ChannelAddressTableColumns}
      logName='addressList'
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ICctv } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** CctvInstallationStatus context */
const CctvInstallationStatusListContext = createContext({} as IRecordListContext<ICctv['installationStatus']>)


/** CctvInstallationStatus context hook */
export const useCctvInstallationStatusListContext = () => useContext(CctvInstallationStatusListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** CctvInstallationStatus context provider */
export function CctvInstallationStatusListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<ICctv['installationStatus']>
      Context={CctvInstallationStatusListContext}
      listApiRoute={apiRoutes.cctvInstallationStatuses}
      deleteApiRoute={apiRoutes.cctvInstallationStatus}
      paginate={paginate}
      logName='cctvInstallationStatusList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
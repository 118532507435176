import { HardwareStateCode } from '@/context/types'


export const containerTopOffset = 185

export const cctvAggregateHardwareColorClasses: Record<HardwareStateCode, string> = {
  [HardwareStateCode.unavailable]: 'text-danger',
  [HardwareStateCode.partially]: 'text-warning',
  [HardwareStateCode.available]: 'text-success',
  [HardwareStateCode.none]: 'text-secondary',
}

export type _HardwareStateCode = Exclude<HardwareStateCode, HardwareStateCode.partially> | 'unknown' //todo-sem убрать костыль после рефактора HardwareStateCode на CctvHardwareStateCode

export const hardwareColorClasses: Record<_HardwareStateCode, string> = {
  [HardwareStateCode.unavailable]: 'text-danger',
  unknown: 'text-warning',
  [HardwareStateCode.available]: 'text-success',
  [HardwareStateCode.none]: 'text-secondary',
}

export const hardwareStateLabels: Record<HardwareStateCode, string> = {
  [HardwareStateCode.unavailable]: 'Всё на ТВН недоступно или в статусе "Неизвестно"',
  [HardwareStateCode.partially]: 'Доступно частично',
  [HardwareStateCode.available]: 'Всё на ТВН доступно',
  [HardwareStateCode.none]: 'На ТВН нет оборудования или всё демонтировано',
}

export const placemarkPresets: Record<HardwareStateCode, string> = {
  [HardwareStateCode.unavailable]: 'islands#redStretchyIcon',
  [HardwareStateCode.partially]: 'islands#orangeStretchyIcon',
  [HardwareStateCode.available]: 'islands#darkGreenStretchyIcon',
  [HardwareStateCode.none]: 'islands#grayStretchyIcon',
}

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ICctv } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** CctvChannelStatus context */
const CctvChannelStatusListContext = createContext({} as IRecordListContext<ICctv['channelStatus']>)


/** CctvChannelStatus context hook */
export const useCctvChannelStatusListContext = () => useContext(CctvChannelStatusListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** CctvChannelStatus context provider */
export function CctvChannelStatusListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<ICctv['channelStatus']>
      Context={CctvChannelStatusListContext}
      listApiRoute={apiRoutes.cctvChannelStatuses}
      deleteApiRoute={apiRoutes.cctvChannelStatus}
      paginate={paginate}
      logName='cctvChannelStatusList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
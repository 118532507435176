import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons'


type Props = {
  fieldKey?: string
  setFieldKey?: (fieldName?: string) => void
  selectedFieldKey?: string
  selectedDirection?: 'asc' | 'desc'
  disabled?: boolean
}

const TableSortCell = ({ fieldKey, setFieldKey, selectedFieldKey, selectedDirection, disabled }: Props) => {
  const isSelected = !disabled && fieldKey === selectedFieldKey

  return (
    <th>
      <button
        className={`btn btn-link px-1 py-0 ${!disabled && !isSelected ? 'text-black' : ''}`}
        onClick={() => setFieldKey?.(fieldKey)}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={isSelected ? (selectedDirection === 'asc' ? faSortAsc : faSortDesc) : faSort} />
      </button>
    </th>
  )
}

export default TableSortCell

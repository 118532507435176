import { useState } from 'react'
import { ApiResource, IIPCamera, IIpCameraInfoForm } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiURL, getApiResourceUpdateRoute } from '@/config/routes'
import { hasSoundEnabledSelectOptionList, orderNumberSelectOptionList } from '@/config/selectOptionLists'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import CheckboxInput from '@/components/common/form/CheckboxInput'
import TextInput from '@/components/common/form/TextInput'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import ListSelect from '@/components/common/form/ListSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { postData } from '@/api/postData'


type Props = {
  ipCamera: IIPCamera
  reloadCctv: () => Promise<void>
}

/** Форма редактирования инфо IP-камеры */
export default function IpCameraInfoForm({ ipCamera, reloadCctv }: Props) {
  const { form, setError } = useFormContext<IIpCameraInfoForm>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  const handleClickEdit = async() => {
      postData({
        url: `${apiURL}/ip-camera-set-config/${ipCamera.id}`,
        successAction: (src) =>
          showModalDialog({
            type: 'ok',
            size: 'lg',
            header: 'Успешно',
            content:'Конфигурация успешно загружена на устройство'
          }),
        errorHandler: () =>showModalDialog({
          type: 'ok',
          size: 'lg',
          header: 'Ошибка',
          content:'Ошибка загрузки конфигурации на устройство'
        }),
      })}

  usePrimaryCallback(async () => {
    if (!form.status) setError('status', 'Обязательное поле')
    if (!form.orderNumberOnCctv) setError('orderNumberOnCctv', 'Обязательное поле')
    if (!form.status || !form.orderNumberOnCctv) return false
    try {
      setIsLoading(true)
      await axios.patch(
        `${getApiResourceUpdateRoute(ApiResource.IpCamera, ipCamera.id)}?context_cctv_id=${ipCamera.cctvId}`,
        form,
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCctv()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при сохранении IP-Камера',
        content: await getErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }, [form, ipCamera, showModalDialog])

  return (
    <>
      <TextInput
        label='IP-Адрес'
        initValue={ipCamera.ipAddress ?? undefined}
        name='ipAddress'
        disabled />
      <TextInput
        label='Модель'
        initValue={ipCamera.preset.model.name}
        name='preset.model.name'
        disabled />
      <TextInput
        label='Имя IP-камеры'
        initValue={ipCamera.code}
        name='code'
        disabled />
      <TextInput
        label='Имя IP-камеры в пределах ТВН'
        initValue={ipCamera.codeOnCctv}
        name='codeOnCctv'
        disabled />
      <ListSelect
        name='orderNumberOnCctv'
        label='Номер камеры'
        initValue={ipCamera.orderNumberOnCctv}
        optionList={orderNumberSelectOptionList}
        required
        disabled={isLoading} />
      <ListSelect
        name='hasSoundEnabled'
        label='Звук на камере'
        initValue={ipCamera.hasSoundEnabled}
        optionList={hasSoundEnabledSelectOptionList}
        disabled={isLoading} />
      <ApiResourceSelect
        apiResource={ApiResource.HardwareStatus}
        name='status'
        label='Статус'
        initValue={ipCamera.status}
        required
        disabled={isLoading} />
      <CheckboxInput<IIpCameraInfoForm>
        label='Месторасположение камеры изменено'
        initValue={ipCamera.hasCameraLocationChanged}
        name='hasCameraLocationChanged'
        disabled={isLoading}
      />
      <CheckboxInput<IIpCameraInfoForm>
        label='Ракурс камеры изменён'
        initValue={ipCamera.hasCameraAngleChanged}
        name='hasCameraAngleChanged'
        disabled={isLoading}
      />
      <div>
        <button className='btn btn-sm btn-outline-primary' onClick={handleClickEdit}>
          <FontAwesomeIcon icon={faUpload} className='me-2' />
          <span>Загрузить конфигурацию на камеру</span>
        </button>
      </div>
    </>
  )
}

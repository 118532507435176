import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiResource, ICatalogWithFiles, IRecord } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getApiResourceDetailsPath, getApiResourceListPath, routes } from '@/config/routes'
import { ApiResources } from '@/config/apiResources'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useModal } from '@/context/ModalContext'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import { FormContextProvider } from '@/context/form/FormContext'
import Alert from '@/components/common/Alert'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CatalogForm from '@/components/catalogs/CatalogForm'
import Loader from '@/components/common/Loader'
import Main from '@/components/common/Main'
import CatalogFilesModal from '@/components/modals/catalogs/CatalogFilesModal'


/** Контент страницы редактирования записи справочника */
export default function EditRecordPageContent() {
  const params = useParams()
  const apiResource = params.apiResource as ApiResource
  const recordId = params.recordId!
  const navigate = useNavigate()
  const { showModalDialog } = useModal()
  const {
    isRecordLoading,
    loadRecordList,
    loadRecord,
    record,
    updateRecord,
  } = useApiResourceContext<IRecord & { name: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const { title, getRecordTitle, fileUploadRoute } = ApiResources[apiResource]!

  const recordTitle = useMemo(() => {
    if (!record) return recordId
    if (getRecordTitle) return getRecordTitle(record as any)
    return record.name
  }, [record, recordId, getRecordTitle])

  useDocumentTitle(`${title}: ${recordTitle}: редактировать запись`)

  const onRecordUpdate = () => {
    loadRecordList()
    loadRecord()
  }

  const handleSubmit = useCallback(async (form: any) => {
    setIsLoading(true)
    try {
      await updateRecord(form)
      onRecordUpdate()
      navigate(getApiResourceDetailsPath(apiResource, recordId))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `${title}: ошибка`,
        content: await getErrorMessage(e),
      })
    }
    setIsLoading(false)
  }, [updateRecord, loadRecord, loadRecordList, title, recordId, apiResource, navigate, showModalDialog])

  const showFilesModal = () => showModalDialog({
    type: 'ok',
    content: <CatalogFilesModal fileUploadRoute={fileUploadRoute!} record={record as ICatalogWithFiles} />,
    header: 'Прикреплённые файлы',
    closeCallback: onRecordUpdate,
  })

  if (isRecordLoading) return <Main><Loader /></Main>

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem path={routes.catalogs}>Справочники</BreadcrumbsItem>
        <BreadcrumbsItem path={getApiResourceListPath(apiResource)}>{title}</BreadcrumbsItem>
        <BreadcrumbsItem path={getApiResourceDetailsPath(apiResource, recordId)}>{recordTitle}</BreadcrumbsItem>
        <BreadcrumbsItem>Редактировать</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <Main>
        <h1 className='mb-4'>{recordTitle}</h1>
        {fileUploadRoute &&
          <div className='d-flex justify-content-end'>
            <button className='btn btn-link p-0 ms-auto' onClick={showFilesModal}>+ Добавить файл</button>
          </div>
        }
        <FormContextProvider>
          {!record
            ? <Alert>Запись {recordTitle} не найдена</Alert>
            : <CatalogForm
              instance={record}
              apiResource={apiResource}
              onSubmit={handleSubmit}
              disabled={isLoading} />}
        </FormContextProvider>
      </Main>
    </>
  )
}
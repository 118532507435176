import { NavLink } from 'react-router-dom'
import { IService, TableColumnConfig } from '@/context/types'
import { routes } from '@/config/routes'
import { formatBoolean, formatDate } from '@/utils/formatters'
import { serviceStatusLabels } from '@/config/status/labels'


export const ServiceTableColumnNameList = [
  'channelNumber',
  'orderBeginNumber',
  'serviceNumber',
  'stage',
  'idAcceptanceStatus',
  'hasOrderFromDit',
  'basicIdentifier',
  'contractNumber',
  'consumerName',
  'channelStage',
  'fromObjectName',
  'fromAddress',
  'objectId',
  'fromJoint',
  'isAreaA',
  'toObjectName',
  'toAddress',
  'toJoint',
  'isAreaB',
  'serviceType',
  'serviceTypeKind',
  'serviceName',
  'terminableService',
  'cryptoProtection',
  'dpi',
  'contentFilter',
  'reservation',
  'capacity',
  'qualityClass',
  'resource1',
  'resource2',
  'resource3',
  'vpn1',
  'vpn2',
  'vpn3',
  'portOwner',
  'constructionObject',
  'orderStartDate',
  'serviceStartDate',
  'serviceEndDate',
  'orderEndDate',
  'orderEndNumber',
  'reason',
  'price',
  'operator',
  'subscriptionPayment',
  'installationPayment',
  'responsibleName',
  'responsibleTitle',
  'responsiblePhone',
  'responsibleEmail',
  'note',
  'comment',
] as const

export type ServiceTableColumnName = typeof ServiceTableColumnNameList[number]

export const ServiceTableColumns: Record<ServiceTableColumnName, TableColumnConfig<IService>> = {
  channelNumber: {
    label: 'УНК',
    value: service => service.channels?.map(channel =>
      <NavLink key={channel.id} to={routes.channel(channel.id)}>{channel.number}</NavLink>
    ),
    filterKey: 'channels.number',
    sortKey: 'channels.number',
  },
  orderBeginNumber: {
    label: '№ заказа начала оказания услуг',
    value: service => service.orderBeginNumber,
    filterKey: 'orderBeginNumber',
    sortKey: 'orderBeginNumber',
  },
  serviceNumber: {
    label: '№ услуги',
    value: service => <NavLink to={routes.service(service.id)}>{service.number}</NavLink>,
    filterKey: 'number',
    sortKey: 'number',
  },
  stage: {
    label: serviceStatusLabels.stage,
    value: service => service.stage.name,
    colorValue: service => service.stage.code,
    filterKey: 'stage.name',
    sortKey: 'stage.name',
  },
  idAcceptanceStatus: {
    label: serviceStatusLabels.idAcceptanceStatus,
    value: service => service.idAcceptanceStatus.name,
    colorValue: service => service.idAcceptanceStatus.code,
    filterKey: 'idAcceptanceStatus.name',
    sortKey: 'idAcceptanceStatus.name',
  },
  hasOrderFromDit: {
    label: 'Получен заказ от ДИТ',
    value: service => formatBoolean(service.hasOrderFromDit),
    filterKey: 'hasOrderFromDit',
    sortKey: 'hasOrderFromDit',
    type:'bool'
  },
  basicIdentifier: {
    label: 'Идентификатор базовой услуги',
    value: service => service.basicIdentifier,
    filterKey: 'basicIdentifier',
    sortKey: 'basicIdentifier',
  },
  contractNumber: {
    label: '№ ГК',
    value: service => service.contract?.number,
    filterKey: 'contract.number',
    filterExistsKey: 'contract',
    sortKey: 'contract.number',
  },
  consumerName: {
    label: 'Наименование клиента',
    value: service => service.department?.name,
    filterKey: 'department.name',
    filterExistsKey: 'department',
    sortKey: 'department.name',
  },
  channelStage: {
    label: serviceStatusLabels.channelStage,
    value: service => service.channels?.map(channel => channel.stage?.name),
    colorValue: service => service.channels?.map(channel => channel.stage?.code),
    noWrap: true,
    filterKey: 'channels.stage.name',
    filterExistsKey: 'channels.stage',
    sortKey: 'channels.stage.name',
    permissions: 'channelStageRead',
  },
  fromObjectName: {
    label: 'Наименование объекта А',
    value: service => service.aConsumer?.name,
    filterKey: 'aConsumer.name',
    filterExistsKey: 'aConsumer',
    sortKey: 'aConsumer.name',
  },
  fromAddress: {
    label: 'Адрес объекта А',
    value: service => service.aAddress?.name,
    filterKey: 'aAddress.name',
    filterExistsKey: 'aAddress',
    sortKey: 'aAddress.name',
  },
  objectId: {
    label: 'ID ЕЦХД объекта А',
    value: service => service.aAddress?.address.storageId,
    filterKey: 'aAddress.address.storageId',
    filterExistsKey: 'aAddress',
    sortKey: 'aAddress.address.storageId',
  },
  fromJoint: {
    label: 'Стык А',
    value: service => service.aJoint?.name,
    filterKey: 'aJoint.name',
    filterExistsKey: 'aJoint',
    sortKey: 'aJoint.name',
  },
  isAreaA: {
    label: 'Область А',
    value: service => formatBoolean(service.isAreaA),
    filterKey: 'isAreaA',
    sortKey: 'isAreaA',
    type: 'bool',
  },
  toObjectName: {
    label: 'Наименование объекта Б',
    value: service => service.bConsumer?.name,
    filterKey: 'bConsumer.name',
    filterExistsKey: 'bConsumer',
    sortKey: 'bConsumer.name',
  },
  toAddress: {
    label: 'Адрес объекта Б',
    value: service => service.bAddress?.name,
    filterKey: 'bAddress.name',
    filterExistsKey: 'bAddress',
    sortKey: 'bAddress.name',
  },
  toJoint: {
    label: 'Стык Б',
    value: service => service.bJoint?.name,
    filterKey: 'bJoint.name',
    filterExistsKey: 'bJoint',
    sortKey: 'bJoint.name',
  },
  isAreaB: {
    label: 'Область Б',
    value: service => formatBoolean(service.isAreaB),
    filterKey: 'isAreaB',
    sortKey: 'isAreaB',
    type: 'bool',
  },
  serviceType: {
    label: 'Тип услуги',
    value: service => service.contractServiceTypePrice?.serviceType.name,
    filterKey: 'contractServiceTypePrice.serviceType.name',
    filterExistsKey: 'contractServiceTypePrice',
    sortKey: 'contractServiceTypePrice.serviceType.name',
  },
  serviceTypeKind: {
    label: 'Вид типа услуги',
    value: service => service.contractServiceTypePrice?.serviceType.typeKindName,
    filterKey: 'contractServiceTypePrice.serviceType.typeKindName',
    filterExistsKey: 'contractServiceTypePrice',
    sortKey: 'contractServiceTypePrice.serviceType.typeKindName',
  },
  serviceName: {
    label: 'Наименование услуги',
    value: service => service.contractServiceTypePrice?.serviceName,
    filterKey: 'contractServiceTypePrice.serviceName',
    filterExistsKey: 'contractServiceTypePrice',
    sortKey: 'contractServiceTypePrice.serviceName'
  },
  terminableService: {
    label: 'Срочная услуга',
    value: service => formatBoolean(service.isUrgent),
    filterKey: 'isUrgent',
    sortKey: 'isUrgent',
    type: 'bool',
  },
  cryptoProtection: {
    label: 'Криптозащита',
    value: service => formatBoolean(service.hasCrypto),
    filterKey: 'hasCrypto',
    sortKey: 'hasCrypto',
    type: 'bool',
  },
  dpi: {
    label: 'DPI',
    value: service => formatBoolean(service.hasDpi),
    filterKey: 'hasDpi',
    sortKey: 'hasDpi',
    type: 'bool',
  },
  contentFilter: {
    label: 'Контент-фильтрация',
    value: service => formatBoolean(service.hasContentFilter),
    filterKey: 'hasContentFilter',
    sortKey: 'hasContentFilter',
    type: 'bool',
  },
  reservation: {
    label: 'Резервирование',
    value: service => formatBoolean(service.hasReservation),
    filterKey: 'hasReservation',
    sortKey: 'hasReservation',
    type: 'bool',
  },
  capacity: {
    label: 'Пропускная способность, Мбит/с',
    value: service => service.contractServiceTypePrice?.channelCapacity,
    filterKey: 'contractServiceTypePrice.channelCapacity',
    sortKey: 'contractServiceTypePrice.channelCapacity',
  },
  qualityClass: {
    label: 'Класс качества',
    value: service => service.qualityClass?.name,
    filterKey: 'qualityClass.name',
    filterExistsKey: 'qualityClass',
    sortKey: 'qualityClass.name',
  },
  resource1: {
    label: 'Перечень ресурсов, к которым требуется доступ через порт',
    labelColspan: 3,
    value: service => service.resource1?.name,
    filterKey: 'resource1.name',
    filterExistsKey: 'resource1',
    sortKey: 'resource1.name',
  },
  resource2: {
    value: service => service.resource2?.name,
    labelColspan: 0,
    filterKey: 'resource2.name',
    filterExistsKey: 'resource2',
    sortKey: 'resource2.name',
  },
  resource3: {
    value: service => service.resource3?.name,
    labelColspan: 0,
    filterKey: 'resource3.name',
    filterExistsKey: 'resource3',
    sortKey: 'resource3.name',
  },
  vpn1: {
    label: 'Номер/название VPN, к которой подключен порт',
    labelColspan: 3,
    value: service => service.vpn1?.name,
    filterKey: 'vpn1.name',
    filterExistsKey: 'vpn1',
    sortKey: 'vpn1.name',
  },
  vpn2: {
    value: service => service.vpn2?.name,
    labelColspan: 0,
    filterKey: 'vpn2.name',
    filterExistsKey: 'vpn2',
    sortKey: 'vpn2.name',
  },
  vpn3: {
    value: service => service.vpn3?.name,
    labelColspan: 0,
    filterKey: 'vpn3.name',
    filterExistsKey: 'vpn3',
    sortKey: 'vpn3.name',
  },
  portOwner: {
    label: 'Принадлежность порта',
    value: service => service.portOwner?.name,
    filterKey: 'portOwner.name',
    filterExistsKey: 'portOwner',
    sortKey: 'portOwner.name',
  },
  constructionObject: {
    label: 'Объект строительства',
    value: service => service.constructionObject?.name,
    filterKey: 'constructionObject.name',
    filterExistsKey: 'constructionObject',
    sortKey: 'constructionObject.name',
  },
  orderStartDate: {
    label: 'Дата начала оказания услуги',
    value: service => formatDate(service.orderBeginDate),
    filterKey: 'orderBeginDate',
    sortKey: 'orderBeginDate',
    type: 'date',
  },
  serviceStartDate: {
    label: 'Дата подключения услуги',
    value: service => formatDate(service.serviceBeginDate),
    filterKey: 'serviceBeginDate',
    sortKey: 'serviceBeginDate',
    type: 'date',
  },
  serviceEndDate: {
    label: 'Дата отключения услуги',
    value: service => formatDate(service.serviceEndDate),
    filterKey: 'serviceEndDate',
    sortKey: 'serviceEndDate',
    type: 'date',
  },
  orderEndDate: {
    label: 'Дата прекращения оказания услуги',
    value: service => formatDate(service.orderEndDate),
    filterKey: 'orderEndDate',
    sortKey: 'orderEndDate',
    type: 'date',
  },
  orderEndNumber: {
    label: '№ заказа прекращения оказания услуги',
    value: service => service.orderEndNumber,
    filterKey: 'orderEndNumber',
    sortKey: 'orderEndNumber',
  },
  reason: {
    label: 'Причина',
    value: service => service.cause,
    filterKey: 'cause',
    sortKey: 'cause',
  },
  price: {
    label: 'Расценка по ГК (с учетом НДС)',
    value: service => service.contractServiceTypePrice?.price,
    filterKey: 'contractServiceTypePrice.price',
    filterExistsKey: 'contractServiceTypePrice',
    sortKey: 'contractServiceTypePrice.price',
    permissions: 'serviceBillingRead',
    totalsKey: 'contractServiceTypePriceSum',
  },
  operator: {
    label: 'Оператор',
    value: service => service.channels.map(channel => channel.operator?.name),
    filterKey: 'channels.operator.name',
    sortKey: 'channels.operator.name',
    permissions: 'serviceBillingRead',
  },
  subscriptionPayment: {
    label: 'Ежемесячный платеж, руб.',
    value: service => service.channels.map(channel => channel.subscriptionPayment),
    filterKey: 'channels.subscriptionPayment',
    sortKey: 'channels.subscriptionPayment',
    permissions: 'serviceBillingRead',
    totalsKey: 'subscriptionPaymentSum',
  },
  installationPayment: {
    label: 'Инсталляционный платеж, руб.',
    value: service => service.channels.map(channel => channel.installationPayment),
    filterKey: 'channels.installationPayment',
    sortKey: 'channels.installationPayment',
    permissions: 'serviceBillingRead',
    totalsKey: 'installationPaymentSum',
  },
  responsibleName: {
    label: 'ФИО ответственного',
    value: service => service.responsibleName,
    filterKey: 'responsibleName',
    sortKey: 'responsibleName',
  },
  responsibleTitle: {
    label: 'Должность ответственного',
    value: service => service.responsiblePositionName,
    filterKey: 'responsiblePositionName',
    sortKey: 'responsiblePositionName',
  },
  responsiblePhone: {
    label: 'Телефон ответственного',
    value: service => service.responsiblePhoneNumber,
    filterKey: 'responsiblePhoneNumber',
    sortKey: 'responsiblePhoneNumber',
  },
  responsibleEmail: {
    label: 'Email ответственного',
    value: service => service.responsibleEmail,
    filterKey: 'responsibleEmail',
    sortKey: 'responsibleEmail',
  },
  note: {
    label: 'Примечание',
    value: service => service.description,
    filterKey: 'description',
    sortKey: 'description',
  },
  comment: {
    label: 'Комментарий',
    value: service => service.comment,
    filterKey: 'comment',
    sortKey: 'comment',
  },
}

export const initialServiceTotals = {
  contractServiceTypePriceSum: 0,
  installationPaymentSum: 0,
  subscriptionPaymentSum: 0,
} as const

import { colorsTable } from '@/components/reports/common/constants'
import { useQuery } from '@tanstack/react-query'
import { fetchPecsStatusFieldStatistic } from '@/api/queries/audit'
import { useParams } from 'react-router-dom'
import Loader from '@/components/common/Loader'
import { usePecListContext } from '@/context/tables/PecListContext'
import { IPecStatusField, TableFilter } from '@/context/types'
import PecsStatNumberLinkCell from '@/components/audit/stats/cells/PecsStatNumberLinkCell'
import { usePecStatusFieldListContext } from '@/context/entityFields/PecStatusFieldListContext'
import filterToSampler from '@/utils/paramConverters/filterToSampler'
import { usePecStatusListContext } from '@/context/statuses/PecStatusListContext'


type Props = {
  pecStatusFieldId: IPecStatusField['id']
}

const PecsStatusStat = ({ pecStatusFieldId }: Props) => {
  const { projectId } = useParams()
  const { recordList: pecStatusFields, isLoading: isStatusFieldsLoading } = usePecStatusFieldListContext()
  const { recordList: statusList, isLoading: isPecStatusListLoading } = usePecStatusListContext()
  const { isFetching: isLoading, data } = useQuery({
    queryKey: ['pec_status_field_statistic', projectId, pecStatusFieldId],
    queryFn: () => fetchPecsStatusFieldStatistic(projectId!, pecStatusFieldId),
    initialData: [],
  })
  const { setRecordListSampler } = usePecListContext()

  if (isLoading || isStatusFieldsLoading || isPecStatusListLoading)
    return <Loader />

  const activeStatusField = pecStatusFields.find(sf => sf.id === pecStatusFieldId)!

  return (
    <div style={{ overflowX: 'auto' }}>
      <table className='table table-bordered pecs-stat-table'>
        <thead style={{ backgroundColor: colorsTable.main }}>
          <tr>
            <th rowSpan={2}>ПО</th>
            <th rowSpan={2}>Всего адресов</th>
            <th colSpan={statusList.length}>Текущий этап</th>
          </tr>
          <tr>
            {statusList.map(status =>
              <th key={status.code}>{status.name}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          {data.map(row => {
            const setSampler = (
              filter: TableFilter<string> = {},
              label = '',
              statusFieldId?: IPecStatusField['id'],
            ) => {
              const labelParts = [] as string[]

              if (row.contractor) {
                const filterKey = statusFieldId
                  ? `contractors/${statusFieldId}`
                  : `contractorsMapEntries.contractor.name`

                filter[filterKey] = `${row.contractor.name}!`

                labelParts.push(`ПО: ${row.contractor.name}`)
              }

              if (label)
                labelParts.push(label)

              setRecordListSampler(
                filterToSampler(filter),
                labelParts.map(part => `(${part})`).join(' и '),
              )
            }

            return (
              <tr key={row.contractor?.id ?? 'total'}>
                <td>{row.contractor?.name ?? 'Итого'}</td>
                <PecsStatNumberLinkCell onClick={() => setSampler()} value={row.addresses_count} />
                {statusList.map(status => {
                  const statusCount = Object.values(row.pec_status_counts).find(count => count.code === status.code)

                  return (
                    <PecsStatNumberLinkCell
                      key={status.code}
                      onClick={() => setSampler(
                        statusCount && statusCount.count > 0
                          ? { [`statusFields/${pecStatusFieldId}`]: `${status.name}!`,  [`existsContractors/${pecStatusFieldId}`]: 'true!' }
                          : { [`statusFields/${pecStatusFieldId}`]: `${status.name}?` },
                        `${activeStatusField.name}: ${status.name}`,
                        pecStatusFieldId,
                      )}
                      value={statusCount?.count ?? 0}
                    />
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default PecsStatusStat

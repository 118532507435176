import { cctvStatusLabels } from '@/config/status/labels'
import { UserRole } from '@/config/roles'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useCctvListContext } from '@/context/tables/CctvListContext'
import TableStatusMenu, { WrapperTableStatusMenuProps } from '@/components/common/table/TableStatusMenu'
import { apiRoutes } from '@/config/routes'


const CctvTableStatusMenu = (props: WrapperTableStatusMenuProps<CctvStatusKey>) => {
  const { hasRole } = useAuthContext()
  const { selectedRecordIdSet, loadRecordList, deselectAllRecords } = useCctvListContext()

  return (
    <TableStatusMenu
      {...props}
      fieldLabelsRecord={cctvStatusLabels}
      recordApiRoute={apiRoutes.cctv}
      selectedRecordIdSet={selectedRecordIdSet}
      deselectAllRecords={deselectAllRecords}
      loadRecordList={loadRecordList}
      hasEditPermission={hasRole(UserRole.admin)}
    />
  )
}

export default CctvTableStatusMenu

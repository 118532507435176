import { instanceAxios as axios } from '@/api/instanceAxios'
import {
  ApiResource, HardwareStateCode, IPecFileType, IBaseHardware, IHardwareStateLog, IProject,
} from '@/context/types'
import { apiRoutes, getApiResourceListRoute } from '@/config/routes'
import { convertHardwareStateLogs } from '@/config/apiConverters'
import { getBeginningOfMonth } from '@/utils/date'


export const fetchHardwareLog = async (hardwareId: IBaseHardware['id']) => {
  const res = await axios.get<IHardwareStateLog[]>(
    apiRoutes.hardwareLog,
    { params: { hardware_id: hardwareId } },
  )

  if (res.data.length)
    res.data.unshift({
      datetime: getBeginningOfMonth(res.data[0].datetime),
      status: {
        name: 'Нет данных',
        code: HardwareStateCode.none,
      },
    })

  return convertHardwareStateLogs(res.data)
}
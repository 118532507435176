import { Context, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { ApiResourceFilter, IRecordIdentifier } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import getAxiosPagination from '@/utils/getAxiosPagination'
import { instanceAxios as axios } from '@/api/instanceAxios'
import usePagination from '@/hooks/usePagination'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  IRecordListState, recordListFailedAction, recordListFetchedAction, recordListLoadedAction, useRecordListReducer,
} from '@/context/recordList/recordListStore'


/** Record list context interface */
export interface IRecordListContext<TRecord, TRecordForm = TRecord> extends IRecordListState<TRecord> {
  search: string | null
  loadRecordList: () => Promise<void>
  searchRecordlist: (search: string) => Promise<TRecord[]>
  createRecord: (form: TRecordForm) => Promise<void>
  deleteRecord: (id: IRecordIdentifier<TRecord>) => Promise<void>
}

type Props<TRecord, TRecordForm = TRecord> = PropsWithChildren<{
  Context: Context<IRecordListContext<TRecord, TRecordForm>>
  listApiRoute: string
  createApiRoute?: string
  deleteApiRoute: (id: IRecordIdentifier<TRecord>) => string
  paginate?: boolean
  filter?: ApiResourceFilter
  logName: string
}>


const defaultFilter = {}


/** Record list context provider */
export function RecordListContextProvider<TRecord, TRecordForm = TRecord>({
  Context,
  listApiRoute,
  createApiRoute,
  deleteApiRoute,
  children,
  paginate = false,
  filter = defaultFilter,
  logName
}: Props<TRecord, TRecordForm>) {
  const [state, dispatch] = useRecordListReducer<TRecord>()
  const pagination = usePagination(paginate)
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  /** Load record list */
  const loadRecordList = useCallback(async () => {
    dispatch(recordListFetchedAction())
    try {
      const response = await axios.get(listApiRoute, {
        params: {
          ...pagination,
          search,
          ...filter,
        }
      })
      const list = response.data
      const paginationResponse = getAxiosPagination(response)
      dispatch(recordListLoadedAction(list, paginationResponse))
    } catch (e: any) {
      dispatch(recordListFailedAction(await getErrorMessage(e)))
    }
  }, [pagination, search, filter, listApiRoute, dispatch])

  /** Search record list */
  const searchRecordlist = useCallback(async (search: string) => {
    const response = await axios.get(
      listApiRoute,
      { params: { search, ...filter } }
    )
    return response.data
  }, [filter, listApiRoute])

  /** Create record */
  const createRecord = useCallback(async (form: TRecordForm) => {
    await axios.post(createApiRoute ?? listApiRoute, form)
  }, [createApiRoute, listApiRoute])

  /** Delete recrod */
  const deleteRecord = useCallback(async (id: IRecordIdentifier<TRecord>) => {
    await axios.delete(deleteApiRoute(id))
  }, [deleteApiRoute])

  // Инициализация и обновление
  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  const value: IRecordListContext<TRecord, TRecordForm> = useMemo(() => ({
    ...state,
    ...pagination,
    search,
    loadRecordList,
    searchRecordlist,
    createRecord,
    deleteRecord,
  }), [state, pagination, search, loadRecordList, searchRecordlist, createRecord, deleteRecord])

  useStorageStateLog(logName, state)

  return (
    <Context.Provider value={value}>
      {children ?? <Outlet />}
    </Context.Provider>
  )
}
import { getBgColorClassName } from '@/utils/color'
import { TableColumnConfig } from '@/context/types'
import cn from 'classnames'


type Props = {
  value: ReturnType<TableColumnConfig<any>['value']>
  expand: boolean
  colspan?: number
  colorValue?: ReturnType<NonNullable<TableColumnConfig<any>['colorValue']>>
  noWrap?: boolean
}


/** Ячейка таблицы */
export default function TableCell({ value, expand, colspan, colorValue, noWrap }: Props) {
  const multiple = Array.isArray(value)
  const multipleColor = Array.isArray(colorValue)
  const singleColor = colorValue && !multipleColor

  if (multiple && colorValue && (!multipleColor || colorValue.length !== value.length))
    throw new Error('value and colorValue array length must be equal')

  return (
    <td
      className={cn({
        'text-nowrap': noWrap,
        'p-0': multiple,
        [singleColor ? getBgColorClassName(colorValue) : '']: true,
      })}
      colSpan={colspan}
    >
      <div
        className={`overflow-hidden ${multiple ? 'table-multiple-cell-inner' : ''}`}
        style={{ maxHeight: expand ? 'max-content' : '72px' }}
      >
        {multiple
          ? value.map((v, i) =>
            <div
              className={cn({
                'p-1': multiple,
                [multipleColor ? getBgColorClassName(colorValue[i]) : '']: true,
              })}
              key={i}
            >{v}</div>,
          )
          : value
        }
      </div>
    </td>
  )
}
import { Option } from '@/components/ProjectsContent/monitoring/map/controls/filter/OptionsArray'
import Checkbox from '@/components/bootstrap/Checkbox'


type Props<Id extends string> = {
  className: string
  checkedOptions: string[]
  indeterminateOptions?: string[]
  onChange: (value: boolean) => any
  option: Option<Id>
}

const CheckboxListOption = <Id extends string>({
  className,
  checkedOptions,
  indeterminateOptions,
  onChange,
  option,
}: Props<Id>) =>
  <label className={`d-flex align-items-center gap-1 ps-1 ${className}`}>
    <Checkbox
      checked={checkedOptions.includes(option.id)}
      indeterminate={indeterminateOptions?.includes(option.id)}
      onChange={e => onChange(e.target.checked)}
    /> {option.name} {option.cctvsCount}/{option.ipCamerasCount}
  </label>

export default CheckboxListOption

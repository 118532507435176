import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { instanceAxios } from '@/api/instanceAxios'
import { useAuthContext } from '@/context/auth/AuthContext'


export const useInterceptorAxios = () => {
  const navigate = useNavigate()
  const { signOut } = useAuthContext()

  useEffect(() => {
    instanceAxios.interceptors.response.use(
      (data) => data,
      (err) => {
        if (err.response.status === 401) {
            signOut();
        }

        return Promise.reject(err)
      },
    )
  }, [navigate, signOut])
}

import { Fragment, useCallback, useEffect, useState } from 'react'
import { IBillingTotals, IServiceWithBilling } from '@/context/types'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, routes } from '@/config/routes'
import {
  BillingRevenueTableColumnName, BillingRevenueTableColumnNameList, BillingRevenueTableColumns,
  prepareBillingRevenueAdditionalCols,
} from '@/config/tables/lists/commutication/billingRevenue'
import { isObjectEmpty } from '@/utils/tools'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useBillingRevenueListContext } from '@/context/tables/BillingRevenueListContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CommunicationNav from '@/components/communication/CommunicationNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import PaginationWSP from '@/components/common/PaginationWSP'
import BillingTableToolbar from '@/components/communication/BillingTableToolbar'
import TableRow from '@/components/common/table/TableRow'
import TableHead from '@/components/common/table/TableHead'
import { formatPrice } from '@/utils/formatters'
import { useModal } from '@/context/ModalContext'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import { IMappedReportingPeriod } from '@/components/communication/types'
import { useAuthContext } from '@/context/auth/AuthContext'
import Loader from '@/components/common/Loader'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'


export default function BillingRevenueTablePageContent() {
  const { hasPermission } = useAuthContext()
  const [billingTotals, setBillingTotals] = useState<IBillingTotals>()
  const { showModalDialog } = useModal()

  useDocumentTitle('Таблица биллинга: Доходная часть')
  const availableCols = useTableAvailableCols(BillingRevenueTableColumnNameList, BillingRevenueTableColumns)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
  } = useBillingRevenueListContext()
  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter
  } = useTableFilterForm(filter, setRecordListFilter)

  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('биллинга'))
  }, [showModalDialog])

  const reportingPeriods = (recordList[0] as IServiceWithBilling)?.billing?.reportingPeriods || []

  useEffect(() => {
    if (filter.contractNumber)
      axios.get(apiRoutes.serviceBillingTotals(filter.contractNumber))
        .then(res => setBillingTotals(res.data))
  }, [filter.contractNumber])

  const isLoading = isRecordListLoading || isFullIdRecordListLoading

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem path={routes.communication}>Связь</BreadcrumbsItem>
        <BreadcrumbsItem>Таблица биллинга: Доходная часть</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <CommunicationNav />
      <BillingTableToolbar<BillingRevenueTableColumnName>
        recordListLength={recordList.length}
        recordListCount={count}
        fullRecordIdSet={fullRecordIdSet}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        filterForm={filterForm}
        updateFilterForm={updateFilterForm}
        applyFilter={applyFilter}
        onReload={loadRecordList}
        handleShowFilterInfo={handleShowFilterInfo}
        exportBtnLabel='Массовый экспорт доходного биллинга'
        exportApiRoute={hasPermission('exportServiceBilling') ? apiRoutes.exportBillingRevenue : ''}
      />
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={availableCols}
                columns={BillingRevenueTableColumns}
                additionalColumns={recordList.length ? prepareBillingRevenueAdditionalCols(recordList[0]) : []}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
              >
                {billingTotals && !!recordList.length &&
                  <>
                    <tr>
                      <th colSpan={availableCols.length} />
                      {/* //todo-sem change manual col count to prepareBillingRevenueAdditionalCols().length */}
                      <th colSpan={reportingPeriods.length * 5 + reportingPeriods.filter(rp => rp.lastForYear).length + 1}>Наименование ОП</th>
                    </tr>
                    <tr>
                      <th colSpan={availableCols.length} />
                      {reportingPeriods.map(rp => (
                        <Fragment key={rp.id}>
                          <th colSpan={4}>{rp.name}</th>
                          <th>∑={formatPrice(billingTotals.reportingPeriods[rp.id]?.revenue)}</th>
                          {rp.lastForYear && <th>∑={formatPrice(billingTotals.years[rp.lastForYear]?.revenue)}</th>}
                        </Fragment>
                      ))}
                      <th>∑={formatPrice(billingTotals.total.revenue)}</th>
                    </tr>
                  </>
                }
              </TableHead>
              <tbody>
                {!recordList.length
                  ? <EmptyTableRow columnCount={availableCols.length}>
                    {filter.contractNumber ? (isObjectEmpty(filter) ? 'Записей нет' : 'Записей не найдено') : 'введите № ГК'}
                  </EmptyTableRow>
                  : recordList.map(record =>
                    <TableRow<IServiceWithBilling, BillingRevenueTableColumnName>
                      key={record.id}
                      columnNameList={availableCols}
                      columns={BillingRevenueTableColumns}
                      record={record}
                      additionalColumns={prepareBillingRevenueAdditionalCols(record)}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount}
          />
        </div>
      </div>
    </>
  )
}
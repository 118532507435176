import { MouseEvent } from 'react'
import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'
import { ICctv, IPec } from '@/context/types'
import { useQuery } from '@tanstack/react-query'
import { fetchPecFiles } from '@/api/queries/audit'
import { useParams } from 'react-router-dom'
import { imagesGalleryModal } from '@/components/modals/cctv/modals'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserRole } from '@/config/roles'



type Props = {
  pec: IPec | ICctv
  reloadCctv: () => Promise<void>
}

export default function CctvPecAttachedFiles({ pec, reloadCctv }: Props) {
  const { projectId } = useParams()
  const { data: filesTypes } = useQuery({
    queryKey: ['pec_files', projectId],
    queryFn: () => fetchPecFiles((pec as IPec).number, projectId!),
    initialData: [],
  })
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()


  const canDelete = hasRole([UserRole.admin, UserRole.mgts, UserRole.contractor])

  const handleClickAdditionalFile = (e: MouseEvent<HTMLAnchorElement>, file: IPec['files'][0]) => {
    e.preventDefault()
   
    const imageFiles = filesTypes.filter(file => {
      const extension = (file.isExternal ? file.id : file.name).split('.').pop()
  
      return extension && ['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp'].includes(extension)
    })
    const params = imagesGalleryModal(pec as IPec, imageFiles, file, canDelete, reloadCctv)
  
    if (!params)
      return
  
  
    showModalDialog(params)
  }
  
  if (!filesTypes.length)
    return <></>
  
  return (
    <div className='card border-0 mb-2'>
      <div
        className={`card-header border-0 px-2 py-1 justify-content-between ${cn(
          style.cardHeader,
          style.cardHeaderAttachedFiles,
        )} `}
      >
        <div>
          <button
            className={`btn text-primary p-0 ${style.cardHeaderArrow}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseCctvPecAttachedFiles'
            aria-expanded='false'
            aria-controls='collapseCctvPecAttachedFiles'
          >
            <b>Приложенные файлы УИК-{pec.number} (модуль "Сопровождение")</b>
          </button>
        </div>
      </div>
      <div className='collapse' id='collapseCctvPecAttachedFiles'>
        <div
          className={`card-body border-0 ${cn(
            style.cardBody,
            style.cardBodyAttachedFiles,
            style.cctvInfoCollapse,
          )} p-0`}
        >
          <table className={`table-hover table-sm mb-0 ${style.table}`}>
            <thead>
              <tr className='text-muted '>
                {['Название', 'Вид работ', 'Размер', 'Загружен'].map((header, index) => (
                  <th key={index} className='pr-2 fw-normal'>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='align-middle'>
              {filesTypes.map((file, index) => (
                <tr key={index}>
                  <td className='pr-2 fw-normal'>
                    <a
                      href={file.path}
                      target='_blank'
                      rel='noreferrer'
                      onClick={(e) => handleClickAdditionalFile(e, file)}
                    >
                      {file.type?.name ?? 'Дополнительное фото'}
                    </a>
                  </td>
                  <td className='pr-2 fw-normal'>{file.pecStatusField?.name}</td>
                  <td className='pr-2 fw-normal'>
                    {(+file.size / 1024).toLocaleString()} KБ
                  </td>
                  <td className='pr-2 fw-normal'>
                    {file.createdDatetime.toLocaleString('ru')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

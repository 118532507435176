import {
  IHardwareMonitoringState, IMonitoringCctv, TableAdditionalColumnConfig, TableColumnConfig,
} from '@/context/types'
import { routes } from '@/config/routes'
import { NavLink } from 'react-router-dom'
import { CctvHardwareKey, cctvHardwareKeys, cctvHardwareLabels } from '@/config/hardware'
import Stopwatch from '@/components/common/time/Stopwatch'
import { camelToSnake } from '@/utils/string'


export const ProjectsMonitoringCctvTableColumnNameList = [
  'number',
  'type',
  'addressArea',
  'addressDistrict',
  'address',
  'incidents',
  'channelOperator',
  'maintenanceContractor',
  'maintenanceContractorDescription',
] as const

export type ProjectsMonitoringCctvTableColumnName = typeof ProjectsMonitoringCctvTableColumnNameList[number]

export const ProjectsMonitoringCctvTableColumns: Record<ProjectsMonitoringCctvTableColumnName, TableColumnConfig<IMonitoringCctv>> = {
  number: {
    label: 'Номер ТВН',
    filterKey: 'number',
    sortKey: 'number',
    value: cctv => <NavLink to={routes.projectCctv(cctv.project.id, cctv.id)}>{cctv.number}</NavLink>,
  },
  type: {
    label: 'ТИП',
    filterKey: 'type.name',
    sortKey: 'type.name',
    value: cctv => cctv.type.name,
  },
  addressArea: {
    label: 'Округ',
    filterKey: 'address.district.area.name',
    filterExistsKey: 'address.district.area',
    sortKey: 'address.district.area.name',
    value: cctv => cctv.address.district.area.name,
  },
  addressDistrict: {
    label: 'Район',
    filterKey: 'address.district.name',
    filterExistsKey: 'address.district',
    sortKey: 'address.district.name',
    value: cctv => cctv.address.district.name,
  },
  address: {
    label: 'Адрес',
    filterKey: 'address.name',
    filterExistsKey: 'address',
    sortKey: 'address.name',
    value: cctv => cctv.address.name,
  },
  incidents: {
    label: 'Инциденты',
    filterAndSortViewKey: 'view_cctvs_monitoring:incidents',
    value: cctv => cctv.incidents.length ? 'Есть' : 'Нет', // displays <MonitoringIncidentList /> (custom col)
    colorValue: cctv => cctv.incidents.length ? 'has_incidents' : 'no_incidents',
  },
  channelOperator: {
    label: 'Оператор канала',
    filterKey: 'channelOperator.name',
    filterExistsKey: 'channelOperator',
    sortKey: 'channelOperator.name',
    value: cctv => cctv.channelOperator?.name,
  },
  maintenanceContractor: {
    label: 'Подрядчик эксплуатации',
    filterKey: 'maintenanceContractor.name',
    filterExistsKey: 'maintenanceContractor',
    sortKey: 'maintenanceContractor.name',
    value: cctv => cctv.maintenanceContractor?.name,
  },
  maintenanceContractorDescription: {
    label: 'Ответственный от подрядчика экспл.',
    filterKey: 'maintenanceContractorDescription',
    sortKey: 'maintenanceContractorDescription',
    value: cctv => cctv.maintenanceContractorDescription,
  },
}

export const prepareProjectsMonitoringCctvAdditionalCols = (
  cctv: IMonitoringCctv,
  maxHardwareCount: Record<CctvHardwareKey, number>,
) => {
  const cols: TableAdditionalColumnConfig[] = []

  for (const hardwareKey of cctvHardwareKeys)
    for (let i = 0; i < maxHardwareCount[hardwareKey]; i++) {
      const label = cctvHardwareLabels[hardwareKey]
      const filterColspan = i ? 0 : maxHardwareCount[hardwareKey]
      const hardware = cctv[hardwareKey][i] as IHardwareMonitoringState | undefined
      const viewTable = `view_cctvs_${camelToSnake(hardwareKey)}_states`  

      cols.push(
        {
          label,
          filterPlaceholder: 'Фильтр по IP...',
          labelColspan: filterColspan * 2,
          filterColspan,
          valueColspan: 2,
          key: hardwareKey + i + 'ipAddress',
          value: hardware &&
            <>
              {hardware.ipAddress}<br />
              {hardware.state.name}
              {': '}
              <span className='text-muted'>
                {hardware.lastStateDatetime
                  ? <Stopwatch initialDatetime={hardware.lastStateDatetime} />
                  : '?'
                }
              </span>
            </>,
          colorValue: hardware?.state.code ?? '',
          noWrap: true,
          filterAndSortViewKey: `${viewTable}:ipAddress`,
        },
        {
          label,
          filterPlaceholder: 'Фильтр по состоянию...',
          labelColspan: 0,
          filterColspan,
          valueColspan: 0,
          key: hardwareKey + i + 'state',
          value: '', // displayed in previous col
          colorValue: hardware?.state.code ?? '',
          filterAndSortViewKey: `${viewTable}:state`,
        },
      )
    }

  return cols
}

import { useMemo, useState } from 'react'
import { ApiResource, IChannel, IChannelForm, IUser } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import { useChannelFieldListContext } from '@/context/entityFields/ChannelFieldListContext'
import AnyTextInput from '@/components/common/form/AnyTextInput'
import { useAnyFormContext } from '@/context/anyForm/AnyFormContext'
import TextInput from '@/components/common/form/TextInput'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import { recordToSelectOptionNumberLabel, userToSelectOption } from '@/utils/recordToSelectOption'
import NumberInput from '@/components/common/form/NumberInput'
import DateInput from '@/components/common/form/DateInput'
import { responsibleListFilter } from '@/config/listFilters'


type Props = {
  onSave: () => any
  channel?: IChannel
}

const ChannelCreateEditForm = ({ onSave, channel }: Props) => {
  const { showModalDialog, usePrimaryCallback } = useModal()
  const { recordList: channelFieldList } = useChannelFieldListContext()
  const { form, setError } = useFormContext<IChannelForm>()
  const { form: channelFields } = useAnyFormContext()
  const [isLoading, setIsLoading] = useState(false)

  const channelFieldListData = useMemo(() => {
    var channelFieldListData: Record<string, string> = {}
    channel?.channelFields.forEach(channelField => {
      channelFieldListData[channelField.id] = channelField.value
    })
    return channelFieldListData
  }, [channel])

  usePrimaryCallback(async () => {
    if (!form.operator)
      setError('operator', 'Выберите оператора')

    if (!form.operator)
      return false

    try {
      setIsLoading(true)

      if (channel) {
        const response = await axios.patch(
          apiRoutes.channel(channel.id),
          { ...form, channelFields },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )

        if (response.status === 200)
          onSave()
      } else {
        const response = await axios.post(apiRoutes.channels, { ...form })

        if (response.status === 201)
          onSave()
      }
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `${channel ? 'Обновление' : 'Добавление'} канала: Ошибка`,
        content: await getErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }, [form, channel, channelFields, showModalDialog, setError, onSave])

  return (
    <div className='m-5 display-flex m-2'>
      <ApiResourceSelect<IUser, IChannelForm>
        apiResource={ApiResource.User}
        recordToOption={userToSelectOption}
        filter={responsibleListFilter}
        label='Ответственный РП'
        name='responsible'
        initValue={channel?.responsible as IUser}
        disabled={isLoading}
      />
      <NumberInput<IChannelForm>
        label='Пропускная способность, Мбит/с'
        initValue={channel?.channelCapacity ?? undefined}
        name='channelCapacity'
        disabled={isLoading}
      />
      <NumberInput<IChannelForm>
        label='Ежемесячный платеж, руб. без НДС'
        initValue={channel?.subscriptionPayment ?? undefined}
        name='subscriptionPayment'
        disabled={isLoading}
      />
      <NumberInput<IChannelForm>
        label='Инсталляционный платеж, руб. без НДС'
        initValue={channel?.installationPayment ?? undefined}
        name='installationPayment'
        disabled={isLoading}
      />
      <ApiResourceSelect<NonNullable<IChannel['operator']>, IChannelForm>
        apiResource={ApiResource.Organization}
        label='Оператор'
        name='operator'
        initValue={channel?.operator}
        required
        disabled={isLoading}
      />
      <TextInput<IChannelForm>
        label='Сети'
        initValue={channel?.networks ?? undefined}
        name='networks'
        disabled={isLoading}
      />
      <TextInput<IChannelForm>
        label='VLAN'
        initValue={channel?.vlan ?? undefined}
        name='vlan'
        disabled={isLoading}
      />
      <DateInput<IChannelForm>
        label='Дата включения'
        initValue={channel?.processBeginDate || undefined}
        name='processBeginDate'
        disabled={isLoading}
      />
      <DateInput<IChannelForm>
        label='Дата отключения'
        initValue={channel?.processEndDate || undefined}
        name='processEndDate'
        disabled={isLoading}
      />
      <TextInput<IChannelForm>
        label='Контакт'
        initValue={channel?.operatorContactInfo ?? undefined}
        name='operatorContactInfo'
        disabled={isLoading}
      />
      <TextInput<IChannelForm>
        label='Ссылка на закупки'
        initValue={channel?.purchaseLink ?? undefined}
        name='purchaseLink'
        disabled={isLoading}
      />
      <ApiResourceSelect<NonNullable<IChannel['agreement']>, IChannelForm>
        apiResource={ApiResource.Agreement}
        key={'agreement' + form.operator}
        filter={{ 'organization.id': form.operator }}
        recordToOption={recordToSelectOptionNumberLabel}
        label='Договор с оператором'
        name='agreement'
        initValue={channel?.agreement}
        placeholder={form.operator ? '' : 'Выберите оператора'}
        disabled={isLoading || !form.operator}
      />
      <TextInput<IChannelForm>
        label='№ заказа'
        initValue={channel?.purchaseNumber ?? undefined}
        name='purchaseNumber'
        disabled={isLoading}
      />
      <TextInput<IChannelForm>
        label='ID канала у оператора'
        initValue={channel?.operatorChannelId ?? undefined}
        name='operatorChannelId'
        disabled={isLoading}
      />
      <TextInput<IChannelForm>
        label='Комментарий'
        initValue={channel?.comment ?? undefined}
        name='comment'
        disabled={isLoading}
      />
      <TextInput<IChannelForm>
        label='Шифр проекта'
        initValue={channel?.projectCode ?? undefined}
        name='projectCode'
        disabled={isLoading}
      />
      {!!channelFieldList.length &&
        <h3 className='mt-5'>Дополнительная информация</h3>
      }
      {channelFieldList.map(channelField =>
        <AnyTextInput
          key={channelField.id}
          label={channelField.name}
          name={channelField.id}
          disabled={isLoading}
          initValue={channelFieldListData[channelField.id]}
        />,
      )}
    </div>
  )
}

export default ChannelCreateEditForm

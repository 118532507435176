import { useCallback, useEffect } from 'react'
import { ApiResource, IConstructionObject } from '@/context/types'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { apiRoutes, routes } from '@/config/routes'
import {
  ConstructionObjectTableColumnName, ConstructionObjectTableColumnNameList, ConstructionObjectTableColumns,
} from '@/config/tables/lists/commutication/constructionObject'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useModal } from '@/context/ModalContext'
import { usePortListContext } from '@/context/tables/ConstructionObjectListContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CommunicationNav from '@/components/communication/CommunicationNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import PaginationWSP from '@/components/common/PaginationWSP'
import TableHead from '@/components/common/table/TableHead'
import TableRow from '@/components/common/table/TableRow'
import TableToolbar from '@/components/common/table/TableToolbar'
import { FormContextProvider } from '@/context/form/FormContext'
import useTableRecordDelete from '@/hooks/useTableRecordDelete'
import { isObjectEmpty } from '@/utils/tools'
import ConstructionObjectCreateEditForm from '@/components/modals/constructionObject/ConstructionObjectCreateEditForm'
import { useAuthContext } from '@/context/auth/AuthContext'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import Loader from '@/components/common/Loader'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'


export default function ConstructionObjectTablePageContent() {
  const { hasPermission } = useAuthContext()
  useDocumentTitle('Таблица объектов строительства')
  const { showModalDialog } = useModal()
  const showDeleteModalDialog = useTableRecordDelete<IConstructionObject>(apiRoutes.constructionObject, 'объектов строительства')
  const availableCols = useTableAvailableCols(ConstructionObjectTableColumnNameList, ConstructionObjectTableColumns)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    isAllRecordsSelected,
    selectedRecordIdSet,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    samplerLabel,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
    setRecordListSampler,
    selectRecord,
    deselectRecord,
    selectAllRecords,
    deselectAllRecords,
  } = usePortListContext()

  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter,
  } = useTableFilterForm(filter, setRecordListFilter)

  const deleteRecords = useCallback(() => {
    showDeleteModalDialog(selectedRecordIdSet, deselectAllRecords, loadRecordList)
  }, [selectedRecordIdSet, deselectAllRecords, loadRecordList])

  /** Сбросить выборку */
  const handleClearSampler = useCallback(() => {
    setRecordListSampler({}, '')
  }, [setRecordListSampler])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('объектов строительства', {
      importApiResource: hasPermission('importConstructionObject') ? ApiResource.ConstructionObject : undefined
    }))
  }, [showModalDialog, hasPermission])

  const createRecord = useCallback(() => {
    showModalDialog({
      type: 'saveCancel',
      header: 'Добавление объекта строительства',
      size: 'lg',
      content:
        <FormContextProvider>
          <ConstructionObjectCreateEditForm onSave={loadRecordList} />
        </FormContextProvider>,
    })
  }, [showModalDialog])

  const isLoading = isRecordListLoading || isFullIdRecordListLoading

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem path={routes.communication}>Связь</BreadcrumbsItem>
        <BreadcrumbsItem>Таблица услуг</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <CommunicationNav />
      <TableToolbar
        hasSampler
        samplerLabel={samplerLabel}
        recordListLength={recordList.length}
        recordListCount={count}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        selectedRecordIdSetSize={selectedRecordIdSet.size}
        fullRecordIdSet={fullRecordIdSet}
        clearFilter={clearFilter}
        onClearSampler={handleClearSampler}
        onReload={loadRecordList}
        handleShowFilterInfo={handleShowFilterInfo}
        exportBtnLabel='Массовый экспорт объектов строительства'
        exportApiRoute={hasPermission('exportConstructionObject') ? apiRoutes.exportConstructionObjects : ''}
        importBtnLabel='Массовый импорт объектов строительства'
        importApiRoute={hasPermission('importConstructionObject') ? apiRoutes.importConstructionObjects : ''}
        onClickCreate={createRecord}
        onClickDelete={deleteRecords}
        canCreate={hasPermission('constructionObjectCreate')}
        canDelete={hasPermission('constructionObjectDelete')}
      />
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={availableCols}
                columns={ConstructionObjectTableColumns}
                isAllRecordsSelected={isAllRecordsSelected}
                selectAllRecords={selectAllRecords}
                deselectAllRecords={deselectAllRecords}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
              >
                <tr>
                  <th colSpan={7} />
                  <th colSpan={3} className='text-center'>Заявленная потребность</th>
                  <th colSpan={3} className='text-center'>Фактическая потребность</th>
                  <th colSpan={11} />
                </tr>
              </TableHead>
              <tbody>
                {!recordList.length
                  ? <EmptyTableRow columnCount={availableCols.length + 1}>
                    {isObjectEmpty(filter) ? 'Объектов строительства нет' : 'Объектов строительства не найдено'}
                  </EmptyTableRow>
                  : recordList.map(record =>
                    <TableRow<IConstructionObject, ConstructionObjectTableColumnName>
                      key={record.id}
                      columnNameList={availableCols}
                      columns={ConstructionObjectTableColumns}
                      record={record}
                      selectRecord={selectRecord}
                      selectedRecordIdSet={selectedRecordIdSet}
                      deselectRecord={deselectRecord}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount} />
        </div>
      </div>
    </>
  )
}

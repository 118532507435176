import { useEffect, useRef } from 'react'


type Props = Omit<JSX.IntrinsicElements['input'], 'type'> & {
  indeterminate?: boolean
}

const Checkbox = ({ className = '', indeterminate, ...props }: Props) => {
  const ref = useRef({} as HTMLInputElement)

  useEffect(() => {
    ref.current.indeterminate = !!indeterminate
  }, [indeterminate])

  return <input type='checkbox' className={`form-check-input m-0 ${className}`} {...props} ref={ref} />
}

export default Checkbox

import { IBaseHardware } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { useCallback } from 'react'
import { serialNumberHistory } from '@/components/modals/hardware/modals'


const HardwareSerial = ({ hardware }: { hardware: IBaseHardware }) => {
  const { showModalDialog } = useModal()

  const handleShowSerialNumberHistory = useCallback(() => {
    showModalDialog(serialNumberHistory(hardware))
  }, [showModalDialog, hardware])

  return <div className='d-flex justify-content-between'>
    <div>{hardware.serialNumber}</div>
    {!!hardware.logSerialNumbersEntries.length &&
      <button className='btn btn-sm btn-link p-0 ps-2' onClick={handleShowSerialNumberHistory}>
        История изменений
      </button>
    }
  </div>
}

export default HardwareSerial

import { useState } from 'react'
import useInterval from 'use-interval'
import Duration from '@/components/common/time/Duration'


type Props = {
  initialDatetime: Date
}

const Stopwatch = ({ initialDatetime }: Props) => {
  const [date, setDate] = useState(new Date)

  useInterval(() => setDate(new Date), 60_000)

  return <Duration from={initialDatetime} to={date} />
}

export default Stopwatch

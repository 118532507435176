import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, routes } from '@/config/routes'
import { NaturalNumberRegex } from '@/config/config'
import { useModal } from '@/context/ModalContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { IPec } from '@/context/types'
import { AxiosRequestConfig } from 'axios'
import { usePecListContext } from '@/context/tables/PecListContext'
import paramsToSampler from '@/utils/paramConverters/paramsToSampler'


export default function AuditToolbarSearchGroup() {
  const { projectId } = useParams()
  const { showModalDialog } = useModal()
  const { setRecordListSampler: setPecListSampler } = usePecListContext()
  const [searchString, setSearchString] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSearchStringChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }, [])

  const getPecIds = useCallback(async (filter: AxiosRequestConfig<any>['params'], notFoundStr: string) => {
    const res = await axios.get<IPec[]>(
      apiRoutes.pecs, {
        params: {
          ...filter,
          'project.id': projectId,
          'groups[]': 'id:read',
        },
      })
    if (!res.data.length)
      throw new Error(notFoundStr)

    return res.data.map(c => c.id)
  }, [projectId])

  const handleSearchPecByNumber = useCallback(async () => {
    const clearSearchString = searchString.trim()
    try {
      setIsLoading(true)

      const pecIds = await getPecIds(
        { number: clearSearchString },
        `УИК ${clearSearchString} не найден`,
      )

      if (pecIds.length === 1)
        navigate(routes.auditProjectPec(projectId!, pecIds[0]))
      else {
        navigate(routes.auditProject(projectId!))
        setPecListSampler(
          paramsToSampler({ number: clearSearchString }),
          `Номер: ${clearSearchString}`,
        )
      }
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск УИК: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске УИК по номеру ${clearSearchString}`),
      })
    } finally {
      setIsLoading(false)
    }
  }, [searchString, getPecIds, projectId, navigate, showModalDialog])

  /** Автоматический поиск */
  const handleSearch = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const clearSearchString = searchString.trim()

    if (NaturalNumberRegex.test(clearSearchString))
      return handleSearchPecByNumber()

    handleSearchPecByNumber()
  }, [searchString, handleSearchPecByNumber])

  return (
    <form onSubmit={handleSearch}>
      <div className='input-group input-group-sm flex-nowrap'>
        <span className='input-group-text'>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input
          className='form-control'
          type='text'
          value={searchString}
          placeholder='Поиск...'
          enterKeyHint='search'
          disabled={isLoading}
          onChange={handleSearchStringChanged}
        />
        <button
          className='btn btn-outline-primary text-nowrap'
          type='button'
          disabled={isLoading}
          onClick={handleSearchPecByNumber}
        >
          УИК №
        </button>
      </div>
    </form>
  )
}

import { NavLink } from 'react-router-dom'
import { IChannel, IChannelField, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { routes } from '@/config/routes'
import { formatDate } from '@/utils/formatters'
import { channelStatusLabels } from '@/config/status/labels'


export const ChannelTableColumnNameList = [
  'number',
  'channelIdentifier',
  'reserve',
  'stage',
  'responsible',
  'aAddress',
  'storageId',
  'bAddress',
  'serviceName',
  'channelCapacity',
  'subscriptionPayment',
  'installationPayment',
  'lastYearTotalPayment',
  'operator',
  'networks',
  'vlan',
  'processBeginDate',
  'processEndDate',
  'operatorContactInfo',
  'purchaseLink',
  'agreement',
  'purchaseNumber',
  'operatorChannelId',
  'contractNumber',
  'serviceStage',
  'customer',
  'aConsumer',
  'bConsumer',
  'comment',
  'projectCode'
] as const

export type ChannelTableColumnName = typeof ChannelTableColumnNameList[number]

export const ChannelTableColumns: Record<ChannelTableColumnName, TableColumnConfig<IChannel>> = {
  number: {
    label: '№ канала',
    value: channel => <NavLink to={routes.channel(channel.id)}>{channel.number}</NavLink>,
    filterKey: 'number',
    sortKey: 'number',
  },
  channelIdentifier: {
    label: 'Идентификатор услуги/ТВН',
    value: channel => 
      <NavLink
        to={
          channel.isCctvChannel
            ? routes.projectCctv(channel.cctv.project.id, channel.cctv.id)
            : routes.service(channel.service.id)
        }
      >
        {channel.channelIdentifier}
      </NavLink>,
    filterAndSortViewKey: 'view_channel:channelIdentifier',
  },
  reserve: {
    label: 'Тип канала',
    value: channel => channel.reserve ? 'Резервный' : 'Основной',
    filterKey: 'reserve',
    sortKey: 'reserve',
    type: 'bool',
    boolFilterLabels: ['Резервный', 'Основной']
  },
  stage: {
    label: channelStatusLabels.stage,
    value: channel => channel.stage?.name,
    colorValue: service => service.stage.code,
    filterKey: 'stage.name',
    filterExistsKey: 'stage',
    sortKey: 'stage.name',
    permissions: 'channelStageRead',
  },
  responsible: {
    label: 'Ответственный РП',
    value: channel => channel.responsible?.name,
    filterKey: 'responsible.name',
    filterExistsKey: 'responsible',
    sortKey: 'responsible.name',
  },
  aAddress: {
    label: 'Адрес объекта А',
    value: channel => channel.isCctvChannel ? channel.cctv.address.name : channel.service.aAddress?.name,
    filterAndSortViewKey: 'view_channel:aAddressName',
  },
  storageId: {
    label: 'ЕЦХД объекта А',
    value: channel => (channel.isCctvChannel ? channel.cctv.address : channel.service.aAddress?.address)?.storageId,
    filterAndSortViewKey: 'view_channel:storageId',
  },
  bAddress: {
    label: 'Адрес объекта Б',
    value: channel => !channel.isCctvChannel && channel.service.bAddress?.name,
    filterAndSortViewKey: 'view_channel:bAddressName',
  },
  serviceName: {
    label: 'Наименование услуги по ГК',
    value: channel => !channel.isCctvChannel ? channel.service.contractServiceTypePrice?.serviceName : '',
    filterAndSortViewKey: 'view_channel:serviceName',
  },
  channelCapacity: {
    label: 'Пропускная способность, Мбит/с',
    value: channel => channel.channelCapacity,
    filterKey: 'channelCapacity',
    sortKey: 'channelCapacity',
  },
  subscriptionPayment: {
    label: 'Ежемесячный платеж, руб. без НДС',
    value: channel => channel.subscriptionPayment,
    filterKey: 'subscriptionPayment',
    sortKey: 'subscriptionPayment',
    permissions: 'serviceBillingRead',
    totalsKey: 'subscriptionPaymentSum',
  },
  installationPayment: {
    label: 'Инсталляционный платеж, руб. без НДС',
    value: channel => channel.installationPayment,
    filterKey: 'installationPayment',
    sortKey: 'installationPayment',
    permissions: 'serviceBillingRead',
    totalsKey: 'installationPaymentSum',
  },
  lastYearTotalPayment: {
    label: 'Общая сумма платежей по каналу за год',
    value: channel => channel.lastYearTotalPayment,
    filterAndSortViewKey: 'view_channel:totalPayment',
    permissions: 'serviceBillingRead',
    totalsKey: 'lastYearTotalPaymentSum',
  },
  operator: {
    label: 'Оператор',
    value: channel => channel.operator?.name,
    filterKey: 'operator.name',
    filterExistsKey: 'operator',
    sortKey: 'operator.name',
  },
  networks: {
    label: 'Сети',
    value: channel => channel.networks,
    filterKey: 'networks',
    sortKey: 'networks',
  },
  vlan: {
    label: 'VLAN',
    value: channel => channel.vlan,
    filterKey: 'vlan',
    sortKey: 'vlan',
  },
  processBeginDate: {
    label: 'Дата включения',
    value: channel => formatDate(channel.processBeginDate),
    filterKey: 'processBeginDate',
    sortKey: 'processBeginDate',
    type: 'date',
  },
  processEndDate: {
    label: 'Дата отключения',
    value: channel => formatDate(channel.processEndDate),
    filterKey: 'processEndDate',
    sortKey: 'processEndDate',
    type: 'date',
  },
  operatorContactInfo: {
    label: 'Контакт',
    value: channel => channel.operatorContactInfo,
    filterKey: 'operatorContactInfo',
    sortKey: 'operatorContactInfo',
  },
  purchaseLink: {
    label: 'Ссылка на закупки',
    value: channel => channel.purchaseLink,
    filterKey: 'purchaseLink',
    sortKey: 'purchaseLink',
  },
  agreement: {
    label: 'Договор с оператором',
    value: channel => channel.agreement?.number,
    filterKey: 'agreement.number',
    filterExistsKey: 'agreement',
    sortKey: 'agreement.number',
  },
  purchaseNumber: {
    label: '№ заказа',
    value: channel => channel.purchaseNumber,
    filterKey: 'purchaseNumber',
    sortKey: 'purchaseNumber',
  },
  operatorChannelId: {
    label: 'ID канала у оператора',
    value: channel => channel.operatorChannelId,
    filterKey: 'operatorChannelId',
    sortKey: 'operatorChannelId',
  },
  contractNumber: {
    label: '№ ГК из модуля',
    value: channel => (channel.isCctvChannel ? channel.cctv.contract : channel.service.contract)?.number,
    filterAndSortViewKey: 'view_channel:contractNumber',
  },
  serviceStage: {
    label: 'Статус услуги',
    value: channel => !channel.isCctvChannel && channel.service.stage.name,
    filterAndSortViewKey: 'view_channel:serviceStage',
  },
  customer: {
    label: 'Наименование клиента',
    value: channel => !channel.isCctvChannel && channel.service.department.name,
    filterAndSortViewKey: 'view_channel:clientName',
  },
  aConsumer: {
    label: 'Наименование объекта А',
    value: channel => !channel.isCctvChannel && channel.service.aConsumer?.name,
    filterAndSortViewKey: 'view_channel:aObjectName',
  },
  bConsumer: {
    label: 'Наименование объекта Б',
    value: channel => !channel.isCctvChannel && channel.service.bConsumer?.name,
    filterAndSortViewKey: 'view_channel:bObjectName',
  },
  comment: {
    label: 'Комментарий',
    value: channel => channel.comment,
    filterKey: 'comment',
    sortKey: 'comment',
  },
  projectCode: {
    label: 'Шифр проекта',
    value: channel => channel.projectCode,
    filterKey: 'projectCode',
    sortKey: 'projectCode',
  },
}

export const prepareChannelAdditionalCols = (
  channel: IChannel,
  channelFieldList: IChannelField[]
): TableAdditionalColumnConfig[] =>
  channelFieldList.map(({ id, name }) => ({
    filterKey: `channelFields/${id}`,
    sortKey: `orderChannelFields/${id}`,
    label: name,
    value: channel.channelFields.find(cf => cf.id === id)?.value ?? '',
  } satisfies TableAdditionalColumnConfig))

export const initialChannelTotals = {
  subscriptionPaymentSum: 0,
  installationPaymentSum: 0,
  lastYearTotalPaymentSum: 0,
} as const

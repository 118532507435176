import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '@/context/auth/AuthContext'
import { FormContextProvider } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import { IConstructionObject } from '@/context/types'
import ConstructionObjectCreateEditForm from '@/components/modals/constructionObject/ConstructionObjectCreateEditForm'


type Props = {
  constructionObject: IConstructionObject
}

const TableConstructionObjectEditForm = (props: Props) => {
  const { constructionObject } = props
  const { hasPermission } = useAuthContext()
  const navigate = useNavigate()
  const { showModalDialog } = useModal()

  const handleEdit = useCallback(() => {
    showModalDialog({
      type: 'saveCancel',
      header: 'Редактирование объекта строительства',
      size: 'lg',
      content: (
        <FormContextProvider>
          <ConstructionObjectCreateEditForm
            onSave={() => navigate(0)}
            constructionObject={constructionObject}
          />
        </FormContextProvider>
      ),
    })
  }, [navigate, constructionObject, showModalDialog])

  if (!hasPermission("constructionObjectEdit"))
    return <>{constructionObject.name}</>

  return (
    <a onClick={handleEdit} href='#'>{constructionObject.name}</a>
  )
}

export default TableConstructionObjectEditForm

import { useCallback } from 'react'
import { IService, IStatusStage } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getTextColorClassName } from '@/utils/color'
import { useModal } from '@/context/ModalContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'


type Props = {
  service: IService
  status: IStatusStage
  statusFieldName: ServiceStatusKey
  reloadCard: () => void
}

export default function ServiceStatusControlMenuItem({ service, status, statusFieldName, reloadCard }: Props) {
  const { showModalDialog } = useModal()

  const handleSetStatus = useCallback(async () => {
    try {
      await axios.patch(
        apiRoutes.service(service.id),
        { [statusFieldName]: status.code },
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении статуса',
        content: await getErrorMessage(e),
      })
    }
  }, [[status.code, statusFieldName, reloadCard, showModalDialog]])

  if (status?.code === service?.stage?.code) {
    return (
      <button
        className='dropdown-item active'
        type='button'
        onClick={handleSetStatus}>
        <FontAwesomeIcon
          icon={faCircle}
          className={`${getTextColorClassName(status.code)} me-2`} />
        {status.name}
      </button>
    )
  }

  return (
    <button
      className='dropdown-item'
      type='button'
      onClick={handleSetStatus}>
      <FontAwesomeIcon
        icon={faCircle}
        className={`${getTextColorClassName(status.code)} me-2`} />
      {status.name}
    </button>
  )

}
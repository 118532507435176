import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useRef, useState } from 'react'
import recordNumberDeclension from '@/utils/recordNumberDecletion'
import { useModal } from '@/context/ModalContext'
import { AnyTableApiResourceContextValue } from '@/context/tableApiResource/TableApiResourceContext'
import DateInput from '@/components/common/form/DateInput'
import useOuterClick from '@/hooks/useOuterClick'
import UpdateAuditUserModalContent, {
  Props as UpdateAuditUserModalContentProps,
} from '@/components/modals/common/UpdateAuditUserModalContent'

type ModalProps = Pick<UpdateAuditUserModalContentProps, 'saveFunction' | 'selectedRecordIdSet'>

type Props = ModalProps & {
  label: string
  deselectAllRecords: AnyTableApiResourceContextValue['deselectAllRecords']
  loadRecordList: AnyTableApiResourceContextValue['loadRecordList']
  hasEditPermission: boolean
}

const PecsTableWorkBeginDateMenu = ({
  label,
  saveFunction,
  selectedRecordIdSet,
  deselectAllRecords,
  loadRecordList,
  hasEditPermission,
}: Props) => {
  const { showModalDialog } = useModal()
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

  const close = useCallback(() => setIsOpen(false), [setIsOpen])
  useOuterClick(popoverRef, close)

  const onDone = useCallback(() => {
    deselectAllRecords()
    loadRecordList()
  }, [deselectAllRecords, loadRecordList])

  const handleSetStatus = useCallback(async () => {
    if (selectedDate) {
      const formattedDate = selectedDate.toISOString();
      showModalDialog({
        type: 'okCancel',
        header: label,
        content: `Вы уверены, что хотите изменить ${recordNumberDeclension(selectedRecordIdSet.size)} на дату «${formattedDate}»?`,
        primaryCallback: () => {
          showModalDialog({
            type: 'abort',
            header: `${label}: ${formattedDate}`,
            content: (
              <UpdateAuditUserModalContent
                label={label}
                saveFunction={saveFunction}
                selectedRecordIdSet={selectedRecordIdSet}
                value={formattedDate}
                onDone={onDone}
              />
            ),
          });
        },
      });
    }
  }, [showModalDialog, saveFunction, selectedRecordIdSet, selectedDate, onDone, label]);
  
  if (!selectedRecordIdSet.size || !hasEditPermission) return <>{label}</>

  return (
    <div className='dropdown' style={{ fontWeight: 400 }}>
      <button className='btn btn-link p-0 text-start fw-bold text-decoration-none' onClick={() => setIsOpen(!isOpen)}>
        {label} <FontAwesomeIcon icon={faCaretDown} />
      </button>
      <div className={`dropdown-menu px-2 ${isOpen ? 'show' : ''}`} style={{ width: 300 }} ref={popoverRef}>
        <DateInput
          label='Выберите дату начала работ'
          initValue={selectedDate}
          onChange={(date) => setSelectedDate(date ?? undefined)}
        />
        <button
          className='btn btn-outline-primary float-end mt-2'
          type='button'
          disabled={!selectedDate}
          onClick={() => {handleSetStatus()}}
        >
          ОК
        </button>
      </div>
    </div>
  )
}

export default PecsTableWorkBeginDateMenu

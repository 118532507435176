import { instanceAxios as axios } from '@/api/instanceAxios'
import {
  ApiResource, IPec, IPecFileType, IPecsCommonStat, IPecsSliceFinishedStat, IPecsSliceStatusStat, IPecsStatusFieldStat,
  IPecStatusField, IProject, IUser, IOrganization,
} from '@/context/types'
import { apiRoutes, getApiResourceListRoute } from '@/config/routes'
import { convertFiles } from '@/config/apiConverters'


export const fetchPecFileTypes = async (projectId: IProject['id']) => {
  const res = await axios.get<IPecFileType[]>(
    `${getApiResourceListRoute(ApiResource.PecFileType)}?projects.id=${projectId}`, {
      params: {
        pagination: false,
        'projects.id': projectId,
        'pecStatusFields.projects.id': projectId,
      },
    })

  return res.data
}

export const fetchPecsCommonStatistic = async (projectId: IProject['id']) => {
  const res = await axios.get<IPecsCommonStat>(apiRoutes.pecCommonStatistic(projectId))

  return res.data
}

export const fetchPecsStatusFieldStatistic = async (
  projectId: IProject['id'],
  pecStatusFieldId: IPecStatusField['id']
) => {
  const res = await axios.get<IPecsStatusFieldStat>(apiRoutes.pecStatusFieldStatistic(projectId, pecStatusFieldId))

  return res.data
}

export const fetchPecsSliceFinishedStatistic = async (
  projectId: IProject['id'],
  pecStatusFieldId: IPecStatusField['id']
) => {
  const res = await axios.get<IPecsSliceFinishedStat>(
    apiRoutes.pecSliceFinishedStatistic(projectId, pecStatusFieldId)
  )

  return res.data
}

export const fetchPecsSliceStatusStatistic = async (
  projectId: IProject['id'],
  pecStatusFieldId: IPecStatusField['id']
) => {
  const res = await axios.get<IPecsSliceStatusStat>(
    apiRoutes.pecSliceStatusStatistic(projectId, pecStatusFieldId)
  )

  return res.data
}

export const fetchPecFiles = async (...args: Parameters<typeof apiRoutes.pecFiles>) => {
  const res = await axios.get<IPec['files']>(apiRoutes.pecFiles(...args), {
    params: {
      'groups[]': 'pec:read'
    }
  })

  return convertFiles(res.data)
}

export const patchPecResponsibleFromCustomer = async (
  pecId: IPec['id'],
  statusFieldId: IPecStatusField['id'],
  userId: IUser['id'],
) => {
  await axios.patch(
    apiRoutes.updatePecResponsibleFromCustomer(pecId),
    {
      status_field_id: statusFieldId,
      user_id: userId,
    },
    { headers: { 'Content-Type': 'application/merge-patch+json' } },
  )
}

export const patchPecContractor = async (
  pecId: IPec['id'],
  statusFieldId: IPecStatusField['id'],
  organizationId: IOrganization['id'],
) => {
  await axios.patch(
    apiRoutes.updatePecContractor(pecId),
    {
      status_field_id: statusFieldId,
      contractor_id: organizationId,
    },
    { headers: { 'Content-Type': 'application/merge-patch+json' } },
  )
}

export const patchPecWorkBeginDate = async (
  pecId: IPec['id'],
  statusFieldId: IPecStatusField['id'],
  userId: IUser['id'],
) => {
  await axios.patch(
    apiRoutes.updatePecWorkBeginDate(pecId),
    {
      status_field_id: statusFieldId,
      date: userId,
    },
    { headers: { 'Content-Type': 'application/merge-patch+json' } },
  )
}


export const patchPecBrigadier = async (
  pecId: IPec['id'],
  statusFieldId: IPecStatusField['id'],
  userId: IUser['id'],
) => {
  await axios.patch(
    apiRoutes.updatePecBrigadier(pecId),
    {
      status_field_id: statusFieldId,
      user_id: userId,
    },
    { headers: { 'Content-Type': 'application/merge-patch+json' } },
  )
}


export const patchPecExecutor = async (
  pecId: IPec['id'],
  statusFieldId: IPecStatusField['id'],
  userId: IUser['id'],
) => {
  await axios.patch(
    apiRoutes.updatePecExecutor(pecId),
    {
      status_field_id: statusFieldId,
      user_id: userId,
    },
    { headers: { 'Content-Type': 'application/merge-patch+json' } },
  )
}

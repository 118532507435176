import { useAuthContext } from '@/context/auth/AuthContext'
import GuestRouter from '@/routers/GuestRouter'
import UserRouter from '@/routers/UserRouter'
import GuestPageContainer from '@/components/common/GuestPageContainer'
import Loader from '@/components/common/Loader'
import { useInterceptorAxios } from '@/hooks/useInterceptorAxios'


export default function App() {
  const { initialized, isSignedIn } = useAuthContext()
  useInterceptorAxios()

  if (!initialized)
    return (
      <GuestPageContainer>
        <Loader />
      </GuestPageContainer>
    )

  if (isSignedIn)
    return <UserRouter />

  return <GuestRouter />
}

import { PropsWithChildren, ReactNode } from 'react'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = PropsWithChildren<{
	roleCodes: Parameters<ReturnType<typeof useAuthContext>['hasRole']>[0]
	accessDeniedContent?: ReactNode
}>


/** Компонент-обертка для контроля доступа по роли */
export default function RoleRequired({ roleCodes, children, accessDeniedContent = null }: Props) {
	const { initialized, hasRole } = useAuthContext()

	if (!initialized)
		return null

	if (!hasRole(roleCodes))
		return <>{accessDeniedContent}</>

	return <>{children}</>
}

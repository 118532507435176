import { CardTableRowConfig, IBaseHardware } from '@/context/types'
import ParamTableRow from '@/components/common/cards/ParamTableRow'


type Props<H extends IBaseHardware> = {
  rows: Record<string, CardTableRowConfig<H>>
  hardware: H
  reloadCard: () => Promise<any>
}

const HardwareCardParamTableRows = <H extends IBaseHardware>({ rows, hardware, reloadCard }: Props<H>) =>
  <>
    {Object.entries(rows).map(([k, row]) =>
      <ParamTableRow key={k} label={row.label} width='33%'>{row.value(hardware, reloadCard)}</ParamTableRow>,
    )}
  </>

export default HardwareCardParamTableRows

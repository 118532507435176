import { round } from '@/utils/number'


export const formatDate = (date?: Date | null | '') => date ? date.toLocaleDateString('ru-RU') : ''

export const formatDateTime = (date?: Date | null | '') => date ? date.toLocaleString('ru-RU') : ''

export const formatPrice = (num?: number) => num ? Math.round((num + Number.EPSILON) * 100) / 100 : 0

export const formatBoolean = (val?: boolean | null) => val ? 'Да' : ''

export const formatReportPercent = (val: number | FloatStr) => round(+val, 6)

import { memo, useEffect, useState } from 'react'
import { Polygon, PolygonGeometry } from 'react-yandex-maps'
import { IArea } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'


type AreaMapData = {
  code: IArea['code']
  color: string
  borderCoordinates: PolygonGeometry
}

type Props = {
  displayAreaCodes: IArea['code'][]
}

const Areas = memo(({ displayAreaCodes }: Props) => {
  const { showModalDialog } = useModal()
  const [areas, setAreas] = useState([] as AreaMapData[])

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get<AreaMapData[]>('/map-areas.json')
        setAreas(res.data)
      } catch (e: any) {
        showModalDialog({
          type: 'error',
          header: 'Ошибка получения координат округов',
          content: await getErrorMessage(e),
        })
      }
    })()
  }, [showModalDialog])

  return (
    <>
      {areas
        .filter(a => displayAreaCodes.includes(a.code))
        .map(a =>
          <Polygon
            key={a.code}
            geometry={a.borderCoordinates}
            options={{
              fillColor: a.color + '7f',
              strokeColor: a.color,
              strokeWidth: 3,
            }}
          />,
        )
      }
    </>
  )
})

export default Areas

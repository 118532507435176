import { Hardware, IBaseHardware, ICctv, IPec } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'


export const changeItemHardwareBinding = async (
  item: ICctv | IPec,
  { bind, unbind }: { bind?: IBaseHardware['id'][], unbind?: IBaseHardware['id'][] },
) => {
  const hardware = [
    ...(bind ?? []).map(id => ({ id, is_bind: true })),
    ...(unbind ?? []).map(id => ({ id, is_bind: false })),
  ] satisfies {
    id: Hardware['id'],
    is_bind: boolean
  }[]

  return await axios.post(
    item.itemType === 'cctv' ? apiRoutes.cctvHardwareBinding(item.id) : apiRoutes.pecHardwareBinding(item.id),
    { hardware: hardware },
  )
}

import { useCallback, useMemo } from 'react'
import { ICctv, IStatusStage } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getTextColorClassName } from '@/utils/color'
import { apiRoutes } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { cctvStatusFsm, getFsmValue } from '@/config/status/fsm'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'


type Props = {
  cctv: ICctv
  status: IStatusStage
	statusFieldName: CctvStatusKey
	reloadCard: () => void
}

export default function CctvStatusControlMenuItem({ cctv, status, statusFieldName, reloadCard }: Props) {
	const { showModalDialog } = useModal()
	const { hasRole } = useAuthContext()

	const handleSetStatus = useCallback(async () => {
		try {
			await axios.patch(
				apiRoutes.cctv(cctv.id),
				{ [statusFieldName]: status.code },
				{ headers: { 'Content-Type': 'application/merge-patch+json' } },
			)
			reloadCard()
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Ошибка при изменении статуса',
				content: await getErrorMessage(e),
			})
		}
	}, [status.code, statusFieldName, cctv.id, reloadCard, showModalDialog])

	const disabled = useMemo(() => {
    const checkFsmRoles = (fromKey: keyof typeof cctvStatusFsm) => {
      const roles = getFsmValue(cctvStatusFsm, fromKey, cctv[fromKey].code, statusFieldName, status.code)?.roles
      return roles && hasRole(roles)
    }

		// Пункт меню недоступен, если:
		return (
			// переход в текущем меню запрещен
			!checkFsmRoles(statusFieldName)
			&& // переход в текущем меню из installationStatus запрещен
			!checkFsmRoles('installationStatus')
			&& // переход в текущем меню из acceptanceStatus запрещен
			!checkFsmRoles('acceptanceStatus')
    )
	}, [status, statusFieldName, cctv, hasRole])

	if (status.code === cctv[statusFieldName].code)
		return (
			<button className='dropdown-item active'>
				<FontAwesomeIcon
					icon={faCircle}
					className={`${getTextColorClassName(status.code)} me-2`} />
				{status.name}
			</button>
		)

	return (
		<button
			className='dropdown-item'
			type='button'
			disabled={disabled}
			onClick={handleSetStatus}>
			<FontAwesomeIcon
				icon={faCircle}
				className={`${getTextColorClassName(status.code)} me-2`} />
			{status.name}
		</button>
	)
}
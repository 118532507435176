import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportSC } from '@/components/reports/types'
import { historyWorkStatusCctv } from '@/components/reports/config/reportSC'


export const ReportCctvsStatusHistory = () => {
  const [report, setReport] = useState<IReportSC[]>([])
  return (
    <ReportWrapper
      code='cctvsStatusHistory'
      {...{ report, setReport }}
    >
      <table className='table table-bordered table-sm mt-4'>
        <thead
          className='text-muted'
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {historyWorkStatusCctv.map(column => <th key={column.label}>{column.label}</th>)}
          </tr>
        </thead>
        <tbody>
          {report.map((row, i) => (
            <tr key={i}>
              {historyWorkStatusCctv.map(column => <td key={column.label}>{column.value(row)}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </ReportWrapper>
  )
}

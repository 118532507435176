import { colorsTable } from '@/components/reports/common/constants'
import { useQuery } from '@tanstack/react-query'
import { fetchPecsSliceFinishedStatistic } from '@/api/queries/audit'
import { useParams } from 'react-router-dom'
import Loader from '@/components/common/Loader'
import { IPecStatusField } from '@/context/types'


type Props = {
  pecStatusFieldId: IPecStatusField['id']
}

const PecsSliceFinishedStat = ({ pecStatusFieldId }: Props) => {
  const { projectId } = useParams()
  const { isFetching: isLoading, data } = useQuery({
    queryKey: ['pec_slice_finished_statistic', projectId, pecStatusFieldId],
    queryFn: () => fetchPecsSliceFinishedStatistic(projectId!, pecStatusFieldId),
    initialData: [],
  })

  if (isLoading)
    return <Loader />

  return (
    <div style={{ overflowX: 'auto' }}>
      Приняты за день:
      <table className='table table-bordered pecs-stat-table'>
        <thead style={{ backgroundColor: colorsTable.main }}>
          <tr>
            <th>Дата</th>
            {data[0]?.contractors.map(contractor =>
              <th key={contractor.id}>{contractor.name}</th>,
            )}
            <th>Всего</th>
          </tr>
        </thead>
        <tbody>
          {data.map(row =>
            <tr key={row.date ?? 'total'}>
              <td>{row.date ?? 'Итого'}</td>
              {row.contractors.map(contractor =>
                <td key={contractor.id}>{contractor.count}</td>,
              )}
              <td>{row.total_count}</td>
            </tr>,
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PecsSliceFinishedStat

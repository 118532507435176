import { ShowModalDialogParams } from '@/context/ModalContext'
import { InfoModalImportText } from '@/components/modals/common/ModalImportText'
import { ApiResource } from '@/context/types'


export function addressImportExportInfoModal(): ShowModalDialogParams {
  return {
    type: 'ok',
    header: 'Справка по импорту и экспорту справочника адресов',
    content:
      <>
        <p className='mb-2'>Импортируемый и экспортируемый файл имеет формат CSV UTF-8</p>
        <p>Названия столбцов при импорте аналогичны файлу экспорта</p>
      </>,
  }
}

export function communicationAddressImportExportInfoModal(importApiResource?: ApiResource): ShowModalDialogParams {
  return {
    type: 'ok',
    header: 'Справка по импорту и экспорту справочника адресов предоставления услуг по ГК',
    content:
      <>
        <p className='mb-2'>Импортируемый файл имеет формат CSV UTF-8</p>
        <p className='mb-2'>Экспортируемый файл имеет формат XLSX</p>
        {importApiResource
          ? <InfoModalImportText importApiResource={importApiResource} />
          : <p>Названия столбцов при импорте аналогичны файлу экспорта</p>
        }
      </>,
  }
}

export function commonImportExportInfoModal(importApiResource?: ApiResource): ShowModalDialogParams {
  return {
    type: 'ok',
    header: 'Справка по импорту и экспорту',
    content: importApiResource
      ? <InfoModalImportText importApiResource={importApiResource} />
      : <p>Названия столбцов при импорте аналогичны файлу экспорта</p>,
  }
}

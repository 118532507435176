import { useEffect, useState } from 'react'
import Select from 'react-select'
import { defaultFilterOption, recordToSelectOptionNumberLabel } from '@/utils/recordToSelectOption'
import { ApiResource, ContractIdentifierTypes, IContract, ISelectOption } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { getApiResourceListRoute } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'


type Option = ISelectOption<IContract['id']>

export type Props = {
  onChange: (contractNumber: string) => void;
  value?: IContract['number']
}

export const ContractNumberSelect = ({ onChange, value }: Props) => {
  const { showModalDialog } = useModal()
  const [options, setOptions] = useState([] as Option[])

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get<IContract[]>(
          getApiResourceListRoute(ApiResource.Contract),
          {
            params: {
              pagination: false,
              groups: ['list_short:read'],
              identifier: ContractIdentifierTypes.communication,
            },
          },
        )
        setOptions(res.data.map(c => recordToSelectOptionNumberLabel(c) as Option))
      } catch (e: any) {
        showModalDialog({
          type: 'error',
          header: 'Ошибка',
          content: await getErrorMessage(e),
        })
      }
    })()
  }, [showModalDialog])

  return (
    <Select<Option>
      className='w-100'
      placeholder='Выберите ГК'
      options={options}
      filterOption={defaultFilterOption}
      isClearable={true}
      isDisabled={false}
      onChange={option => {
        onChange(option?.label || '')
      }}
      value={options.find(o => o.label === value)}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          transform: 'scale(0.79)',
          fontSize: 18,
          marginTop: -5,
          marginLeft: -15,
        }),
      }}
    />
  )
}

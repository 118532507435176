import { useCallback, useEffect } from 'react'
import { UserRole } from '@/config/roles'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import useTableRecordDelete from '@/hooks/useTableRecordDelete'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useCctvListContext } from '@/context/tables/CctvListContext'
import { FilterInfoModalParams, tableFilterInfoModal } from '@/components/modals/common/info'
import TableRow from '@/components/common/table/TableRow'
import TableHead from '@/components/common/table/TableHead'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import Loader from '@/components/common/Loader'
import PaginationWSP from '@/components/common/PaginationWSP'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { apiRoutes, routes } from '@/config/routes'
import { ApiResource, ICctv } from '@/context/types'
import { copyObjectByKeys, isObjectEmpty } from '@/utils/tools'
import { useTabsContext } from '@/context/TabsContext'
import ProjectHeader from '@/components/ProjectsContent/ProjectHeader'
import {
  ProjectFieldListContextProvider, useProjectFieldListContext,
} from '@/context/entityFields/ProjectFieldListContext'
import { useNavigate, useParams } from 'react-router-dom'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import { TableColsSelect } from '@/components/modals/TableColsSelect'
import {
  prepareProjectsCctvAdditionalCols, ProjectsCctvTableColumnName, ProjectsCctvTableColumnNameList,
  ProjectsCctvTableColumns,
} from '@/config/tables/lists/projects/projectsCctv'
import useTableDisplayedCols from '@/hooks/useTableDisplayedCols'
import CctvTableStatusMenu from '@/components/CctvListPageContent/cctvTable/CctvTableStatusMenu'
import { useCctvChannelStatusListContext } from '@/context/statuses/CctvChannelStatusListContext'
import { useCctvInstallationStatusListContext } from '@/context/statuses/CctvInstallationStatusListContext'
import { useCctvAcceptanceStatusListContext } from '@/context/statuses/CctvAcceptanceStatusListContext'
import { useIdAcceptanceStatusListContext } from '@/context/statuses/IdAcceptanceStatusListContext'
import { useInstallationPassivePartStatusListContext } from '@/context/installationPassivePartStatusListContext'
import IncidentList from '@/components/CctvListPageContent/common/IncidentList'
import UploadBtn from '@/components/common/btns/UploadBtn'
import ExploitationHeader from '@/components/exploitation/ExploitationHeader'
import {
  ExploitationCctvTableColumnNameList, ExploitationCctvTableColumns,
} from '@/config/tables/lists/exploitation/exploitationCctv'
import TableToolbar from '@/components/common/table/TableToolbar'
import { createEditCctvInfoModal } from '@/components/modals/cctv/modals'


export default function CCTVListPageContent() {
  useDocumentTitle('Список ТВН')
  const { projectId } = useParams()
  const { showModalDialog } = useModal()
  const showDeleteModalDialog = useTableRecordDelete<ICctv>(apiRoutes.cctv, 'ТВН', routes.projectCctv)
  const { hasRole } = useAuthContext()
  const { setLabel } = useTabsContext()
	const availableCols = projectId 
    // eslint-disable-next-line react-hooks/rules-of-hooks
		? useTableAvailableCols(ProjectsCctvTableColumnNameList, ProjectsCctvTableColumns)
    // eslint-disable-next-line react-hooks/rules-of-hooks
		: useTableAvailableCols(ExploitationCctvTableColumnNameList, ExploitationCctvTableColumns)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    isAllRecordsSelected,
    selectedRecordIdSet,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    samplerLabel,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
    setRecordListSampler,
    selectRecord,
    deselectRecord,
    selectAllRecords,
    deselectAllRecords
	} = useCctvListContext()
  const { recordList: projectFieldList } = useProjectFieldListContext()
  const {
    displayedColumnNames,
    displayedCustomFields,
  } = useTableDisplayedCols('userCctvFields', availableCols, projectFieldList)
  const navigate = useNavigate()

  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [])

  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter,
  } = useTableFilterForm(filter, setRecordListFilter)

  useEffect(() => {
    setLabel(`Главная таблица ${projectId ? 'проекта' : 'эксплуатации'}`, faTable)
  }, [])

	const createRecord = useCallback(() => {
    if (!projectId)
      return

    showModalDialog(createEditCctvInfoModal(projectId, cctvId => navigate(routes.projectCctv(projectId, cctvId))))
  }, [projectId, showModalDialog, navigate])

  const deleteRecords = useCallback(() => {
    showDeleteModalDialog(selectedRecordIdSet, deselectAllRecords, loadRecordList)
  }, [selectedRecordIdSet, deselectAllRecords, loadRecordList])

  const handleSelectCols = useCallback(() => {
    if (!projectId)
      return

    showModalDialog({
      type: 'okCancel',
      header: 'Настройка столбцов',
      size: 'xl',
      content:
        <ProjectFieldListContextProvider projectId={projectId}>
          <TableColsSelect
            userTableHiddenColsKey='userCctvFields'
            columns={copyObjectByKeys(
              ProjectsCctvTableColumns,
              availableCols as (keyof typeof ProjectsCctvTableColumns)[], /* //todo-sem */
            )}
            customFields={projectFieldList}
          />
        </ProjectFieldListContextProvider>,
    })
  }, [showModalDialog, projectId, projectFieldList])


  /** Сбросить выборку */
  const handleClearSampler = useCallback(() => {
    setRecordListSampler({}, '')
  }, [setRecordListSampler])

  /** Показать справку по фильтрации ТВН */
  const handleShowFilterInfo = useCallback(() => {
    let params: FilterInfoModalParams | undefined
    if (projectId)
      params = {
        showMassEditInfo: true,
        templateLinkParams: { project_id: projectId },
        importApiResource: hasRole(UserRole.admin) ? ApiResource.Cctv : undefined,
      }

    showModalDialog(tableFilterInfoModal('ТВН', params))
  }, [showModalDialog, projectId, hasRole])

  const customColumnLabels = {
    channelStatus:
      <CctvTableStatusMenu fieldKey='channelStatus' listContextHook={useCctvChannelStatusListContext} />,
    installationStatus:
      <CctvTableStatusMenu fieldKey='installationStatus' listContextHook={useCctvInstallationStatusListContext} />,
    acceptanceStatus:
      <CctvTableStatusMenu fieldKey='acceptanceStatus' listContextHook={useCctvAcceptanceStatusListContext} />,
    idAcceptanceStatus:
      <CctvTableStatusMenu fieldKey='idAcceptanceStatus' listContextHook={useIdAcceptanceStatusListContext} />,
    installationPassivePartStatus:
      <CctvTableStatusMenu fieldKey='installationPassivePartStatus' listContextHook={useInstallationPassivePartStatusListContext} />,
  }

  const isLoading = isRecordListLoading || isFullIdRecordListLoading

  //todo-sem костыльное переписывание value чтобы использовать loadRecordList, нужно переписать по нормальному
  const columns: typeof ProjectsCctvTableColumns | typeof ExploitationCctvTableColumns = {
    ...(projectId ? ProjectsCctvTableColumns : ExploitationCctvTableColumns),
    incidents: {
      ...(projectId ? ProjectsCctvTableColumns : ExploitationCctvTableColumns).incidents,
      value: cctv => <IncidentList incidents={cctv.incidents} onUpdate={loadRecordList} />
    }
  }

  const hasSelectColumn = !!projectId

  return (
    <>
      {projectId ? <ProjectHeader /> : <ExploitationHeader />}
      <TableToolbar
        hasSampler
        samplerLabel={samplerLabel}
        recordListLength={recordList.length}
        recordListCount={count}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        selectedRecordIdSetSize={selectedRecordIdSet.size}
        fullRecordIdSet={fullRecordIdSet}
        clearFilter={clearFilter}
        onClearSampler={handleClearSampler}
        onReload={loadRecordList}
        exportBtnLabel='Массовый экспорт ТВН'
        exportApiRoute={hasRole(UserRole.admin) ? (projectId ? apiRoutes.exportProjectCctvs(projectId) : apiRoutes.exportExploitationCctvs) : ''}
        importBtnLabel='Массовый импорт ТВН'
        importApiRoute={projectId && hasRole(UserRole.admin) ? apiRoutes.importProjectCctvs(projectId) : ''}
        handleShowFilterInfo={handleShowFilterInfo}
        onClickCreate={createRecord}
        canCreate={!!projectId && hasRole(UserRole.admin)}
        onClickDelete={deleteRecords}
        canDelete={!!projectId && hasRole(UserRole.admin)}
        additionalBtns={
          projectId
            ? <>
              <UploadBtn
                label='Массовое обновление ТВН'
                accept='.csv'
                apiRoute={apiRoutes.updateProjectCctvs(projectId)}
              />
              <UploadBtn
                label='Массовая привязка оборудования'
                accept='.csv'
                apiRoute={apiRoutes.importProjectHardware(projectId)}
              />
            </>
            : undefined
        }
        handleSelectCols={handleSelectCols}
      />
      <div></div>
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{  height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={displayedColumnNames as any /* //todo-sem пофиксить вместе с таблицей мониторинга */}
                columns={columns}
                additionalColumns={prepareProjectsCctvAdditionalCols({ projectFields: [] } as unknown as ICctv /* //todo-sem костыль чтобы в шапке таблицы не пропадали столбцы с полями проекта */, displayedCustomFields)}
                customColumnLabels={customColumnLabels}
                isAllRecordsSelected={isAllRecordsSelected}
                selectAllRecords={hasSelectColumn ? selectAllRecords : undefined}
                deselectAllRecords={hasSelectColumn ? deselectAllRecords : undefined}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
              />
              <tbody>
                {!recordList.length
                  ? <EmptyTableRow columnCount={displayedColumnNames.length + 1}>
                    {isObjectEmpty(filter) ? 'ТВН нет' : 'ТВН не найдено'}
                  </EmptyTableRow>
                  : recordList.map(record =>
                    <TableRow<ICctv, ProjectsCctvTableColumnName>
                      key={record.id}
                      columnNameList={displayedColumnNames as any  /* //todo-sem пофиксить вместе с таблицей мониторинга */}
                      columns={columns}
                      additionalColumns={prepareProjectsCctvAdditionalCols(record, displayedCustomFields)}
                      record={record}
                      selectRecord={hasSelectColumn ? selectRecord : undefined}
                      selectedRecordIdSet={hasSelectColumn ? selectedRecordIdSet : undefined}
                      deselectRecord={hasSelectColumn ? deselectRecord : undefined}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0, marginLeft: 12 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount} />
        </div>
      </div>
    </>
  )
}
import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportACC, ISubReportOpenIncidents, ISubReportUnavailableHardware } from '@/components/reports/types'
import { formatReportPercent } from '@/utils/formatters'
import { Dropdown } from '@/components/reports/common/Dropdown'
import { subReportApiCodes } from '@/config/reports'
import { fetchData } from '@/api/fetchData'
import { getReportReadRoute } from '@/config/routes'
import { errorHandle } from '@/utils/errorHandle'
import { useParams } from 'react-router-dom'
import { getReportFilter } from '@/config/listFilters'
import { SubReportOpenIncidents } from '@/components/reports/subreports/SubReportOpenIncidents'
import Loader from '@/components/common/Loader'
import { SubReportUnavailableHardware } from '@/components/reports/subreports/SubReportUnavailableHardware'
import { IOrganization } from '@/context/types'


export const ReportIpCamerasAvailableByMaintenanceContractor = () => {
  const { projectId } = useParams()
  const [report, setReport] = useState<IReportACC[]>([])
  const [reportDetailOpenTT, setReportDetailOpenTT] = useState<ISubReportOpenIncidents[]>([])
  const [reportDetailUH, setReportDetailUH] = useState<ISubReportUnavailableHardware[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isShowOpenedIncidents, setIsShowOpenedIncidents] = useState(false)
  const [isShowUnavailableHardware, setIsShowUnavailableHardware] = useState(false)
  const [reportDetailFilterLabel, setReportDetailFilterLabel] = useState('')

  const getApiRouteFunc = (apiCode: ValueOf<typeof subReportApiCodes>) =>
    (organizationId?: IOrganization['id'], format = '') =>
      getReportReadRoute(apiCode, {
        ...getReportFilter(projectId),
        maintenance_contractor_id: organizationId ?? '',
        format,
      })

  const getOpenedIncidentsApiRoute = getApiRouteFunc(subReportApiCodes.ipCameraIncidentsOpenedByMaintenanceContractors)
  const getUnavailableHardwareApiRoute = getApiRouteFunc(subReportApiCodes.ipCameraUnavailableByMaintenanceContractor)

  const showOpenedIncidents = (row: IReportACC) => {
    setReportDetailFilterLabel(`Подрядчик: ${row.contractor_name}`)
    setIsShowUnavailableHardware(false)
    setIsShowOpenedIncidents(false)
    fetchData({
      url: getOpenedIncidentsApiRoute(row.contractor_id),
      setData: (res) => {
        setReportDetailOpenTT(res)
        setIsShowOpenedIncidents(true)
      },
      setIsLoading,
      errorHandler: (err) => errorHandle(err),
    })
  }
  const showUnavailableHardware = (row: IReportACC) => {
    setReportDetailFilterLabel(`Подрядчик: ${row.contractor_name}`)
    setIsShowOpenedIncidents(false)
    setIsShowUnavailableHardware(false)
    fetchData({
      url: getUnavailableHardwareApiRoute(row.contractor_id),
      setData: (res) => {
        setReportDetailUH(res)
        setIsShowUnavailableHardware(true)
      },
      setIsLoading,
      errorHandler: (err) => errorHandle(err),
    })
  }

  return (
    <ReportWrapper
      code='ipCamerasAvailableByMaintenanceContractor'
      {...{ report, setReport }}
    >
      <table className='table table-bordered table-sm'>
        <thead
          className='text-muted'
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            <th rowSpan={2} style={{ backgroundColor: colorsTable.lightGrey }}>
              Подрядчик
            </th>
            <th rowSpan={2} style={{ backgroundColor: colorsTable.lightGrey }}>
              Кол-во по ГК
            </th>
            <th colSpan={2} style={{ backgroundColor: colorsTable.lightBlue }}>
              В БЭИ
            </th>
            <th colSpan={3} style={{ backgroundColor: colorsTable.lightGreen }}>
              Работающие
            </th>
            <th colSpan={3} style={{ backgroundColor: colorsTable.lightRed }}>
              Недоступны
            </th>
          </tr>
          <tr>
            <th style={{ backgroundColor: colorsTable.lightBlue }}>Кол-во</th>
            <th style={{ backgroundColor: colorsTable.lightBlue }}>% от ГК</th>
            <th style={{ backgroundColor: colorsTable.lightGreen }}>Кол-во</th>
            <th style={{ backgroundColor: colorsTable.lightGreen }}>% от ГК</th>
            <th style={{ backgroundColor: colorsTable.lightGreen }}>TT</th>
            <th style={{ backgroundColor: colorsTable.lightRed }}>Кол-во</th>
            <th style={{ backgroundColor: colorsTable.lightRed }}>% от ГК</th>
            <th style={{ backgroundColor: colorsTable.lightRed }}>TT</th>
          </tr>
        </thead>
        <tbody>
          {report.map((row, index) => (
            <tr key={index} className='text-right'>
              <td className='text-left'>{row.contractor_name}</td>
              <td>{row.contract_cameras_count}</td>
              <td>{row.storage_cameras_count}</td>
              <td>{formatReportPercent(row.storage_cameras_percent)}</td>
              <td>{row.available_cameras_count}</td>
              <td>{formatReportPercent(row.available_cameras_percent)}</td>
              <td>{row.available_incidents_count}</td>
              <td>
                <Dropdown
                  exportApiRoute={projectId ? '' : getUnavailableHardwareApiRoute(row.contractor_id, 'xlsx')}
                  handleClick={() => showUnavailableHardware(row)}
                  data={row.unavailable_cameras_count}
                />
              </td>
              <td>{formatReportPercent(row.unavailable_cameras_percent)}</td>
              <td>
                <Dropdown
                  exportApiRoute={projectId ? '' : getOpenedIncidentsApiRoute(row.contractor_id, 'xlsx')}
                  handleClick={() => showOpenedIncidents(row)}
                  data={row.unavailable_incidents_count}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <Loader />}
      {isShowOpenedIncidents &&
        <SubReportOpenIncidents
          filterLabel={reportDetailFilterLabel}
          {...{ reportDetailOpenTT }}
        />
      }
      {isShowUnavailableHardware &&
        <SubReportUnavailableHardware
          filterLabel={reportDetailFilterLabel}
          {...{ reportDetailUH }}
        />
      }
    </ReportWrapper>
  )
}

import { useLocation } from 'react-router-dom'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import Main from '@/components/common/Main'


// Контент страницы с перечнем справочников
export default function Error404PageContent() {
  useDocumentTitle('Ошибка 404: Страница не найдена')
  const location = useLocation()


  return (
    <Main>
      <h1>Ошибка 404: Страница не найдена</h1>
      <p>Страница по адресу <code>{location.pathname}</code> не найдена.</p>
    </Main>
  )
}

import { useModal } from '@/context/ModalContext'
import DeleteRecordListModalContent, {
  Props as DeleteCctvListModalProps,
} from '@/components/modals/common/DeleteRecordListModalContent'
import { IRecordWithId } from '@/context/types'
import recordNumberDeclension from '@/utils/recordNumberDecletion'
import { useTabsContext } from '@/context/TabsContext'


const useTableRecordDelete = <TRecord extends IRecordWithId>(
  recordApiRoute: DeleteCctvListModalProps<TRecord>['recordApiRoute'],
  entityDisplayName: string,
  recordTabRoute?: Function,
) => {
  const { showModalDialog } = useModal()
  const { closeTab } = useTabsContext()

  return (selectedRecordIdSet: Set<TRecord['id']>, deselectAllRecords: () => void, loadRecordList: () => void) =>
    showModalDialog({
      type: 'deleteCancel',
      header: `Удаление ${entityDisplayName}`,
      content: `Вы уверены, что хотите удалить ${recordNumberDeclension(selectedRecordIdSet.size)}?`,
      primaryCallback: () =>
        showModalDialog({
          type: 'abort',
          header: `Удаление ${entityDisplayName}`,
          content:
            <DeleteRecordListModalContent<TRecord>
              selectedRecordIdSet={selectedRecordIdSet}
              recordApiRoute={recordApiRoute}
              onDelete={id => recordTabRoute && closeTab(recordTabRoute?.(id))}
              onDone={() => {
                deselectAllRecords()
                loadRecordList()
              }}
            />
        })
    })
}

export default useTableRecordDelete
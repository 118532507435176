import { Hardware, IHardwareSet, IIncidentHardware } from '@/context/types'
import { CctvHardwareKey, cctvHardwareKeys } from '@/config/hardware'


/** Полное имя оборудования */
export function getHardwareLabel(hardware: Hardware | IIncidentHardware) {
	if ('macAddress' in hardware)
		return `${hardware.type.name} ${hardware.code} [${hardware.macAddress}]`
	if (hardware.ipAddress)
		return `${hardware.type.name} ${hardware.code} [${hardware.ipAddress}]`
	return `${hardware.type.name} ${hardware.code}`
}


/** Прошивка */
export function getHardwareModelLabel(hardware: Hardware | IIncidentHardware) {
	if ('preset' in hardware)
		return hardware.preset.model.name
	if ('model' in hardware)
		return hardware.model.name
	return 'н/д'
}

export function* generateCctvHardwareList<T extends Record<CctvHardwareKey, any>>(cctv: T) {
  for (const key of cctvHardwareKeys)
    for (const hardware of cctv[key])
      yield hardware as T[CctvHardwareKey][number]
}

export const emptyHardwareSet: IHardwareSet = {
  videoNetworkSwitches: [],
  communicationNetworkSwitches: [],
  routers: [],
  ipCameras: [],
  videoServers: [],
  accessPoints: [],
  upses: [],
}

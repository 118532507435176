import { useParams } from 'react-router-dom'
import { IProject } from '@/context/types'


const useProjectId = () => {
  const { projectId } = useParams() as { projectId: IProject['id'] }
  if (!projectId)
    throw new Error('Hook useProjectId does not work outside project pages or in modals')

  return projectId
}

export default useProjectId

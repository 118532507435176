import { Dispatch, Reducer, useReducer } from 'react'
import areSetsEqual from '@/utils/areSetsEqual'
import getSetsIntersection from '@/utils/getSetsIntersection'
import { IRecordListWithPagination, IRecordWithId, TableFilter, TableSampler } from '@/context/types'
import { defaultRecordsPerPage } from '@/config/config'


/** Table API resoirce store state interface */
export interface TableApiResourceState<TRecord extends IRecordWithId, TColumnName extends string> {
	isRecordListLoading: boolean
	isFullIdRecordListLoading: boolean
	recordList: TRecord[]
	count?: number
	page: number
	recordsPerPage: number
	pageCount?: number
	sampler: TableSampler
	samplerLabel: string
	filter: TableFilter<TColumnName>
	sortFieldName?: string
	sortDirection: 'asc' | 'desc'
	fullRecordIdSet: Set<TRecord['id']>
	selectedRecordIdSet: Set<TRecord['id']>
	isAllRecordsSelected: boolean
	isRecordLoading: boolean
	record?: TRecord
	error: string | null
}


/** Table API resource store actions */
type TableApiResourceAction<TRecord extends IRecordWithId, TColumnName extends string> =
	| { type: 'recordListFetched' }
	| { type: 'recordListFailed', payload: string }
	| { type: 'recordListLoaded', payload: IRecordListWithPagination<TRecord> }
	| { type: 'recordListCleared' }
	| { type: 'recordListPageChanged', payload: number }
	| { type: 'recordsPerPageChanged', payload: number }
	| { type: 'recordListSamplerChanged', payload: { sampler: TableSampler, label: string } }
	| { type: 'recordListFilterChanged', payload: TableFilter<TColumnName> }
	| { type: 'recordListSortChanged', payload?: string }
	| { type: 'fullRecordIdSetFetched' }
	| { type: 'fullRecordIdSetLoaded', payload: Set<TRecord['id']> }
	| { type: 'fullRecordIdSetCleared' }
	| { type: 'recordSelected', payload: TRecord['id'] }
	| { type: 'recordDeselected', payload: TRecord['id'] }
	| { type: 'allRecordsSelected' }
	| { type: 'allRecordsDeselected' }
	| { type: 'recordFetched' }
	| { type: 'recordFailed', payload: string }
	| { type: 'recordLoaded', payload: TRecord }


/** Table API resource store initial state */
const communicationInitState: TableApiResourceState<any, any> = {
	isRecordListLoading: false,
	isFullIdRecordListLoading: false,
	recordList: [],
	page: 1,
	recordsPerPage: defaultRecordsPerPage,
	sampler: {},
	samplerLabel: '',
	filter: {},
	sortDirection: 'asc',
	fullRecordIdSet: new Set(),
	selectedRecordIdSet: new Set(),
	isAllRecordsSelected: false,
	isRecordLoading: false,
	error: null
}

/** Table API resource store reducer */
function communicationReducer<TRecord extends IRecordWithId, TColumnName extends string>(state: TableApiResourceState<TRecord, TColumnName>, action: TableApiResourceAction<TRecord, TColumnName>): TableApiResourceState<TRecord, TColumnName> {
	switch (action.type) {
		case 'recordListFetched':
			return {
				...state,
				isRecordListLoading: true,
				error: null
			}
		case 'recordListFailed':
			return {
				...state,
				isRecordListLoading: false,
				recordList: [],
				count: undefined,
				pageCount: undefined,
				page: 1,
				error: action.payload
			}
		case 'recordListLoaded':
			return {
				...state,
				isRecordListLoading: false,
				recordList: action.payload.recordList,
				count: action.payload.pagination.totalItems,
				pageCount: action.payload.pagination.lastPage,
				page: action.payload.pagination.page ?? state.page,
				error: null
			}
		case 'recordListCleared':
			return {
				...state,
				recordList: [],
				count: 0,
				pageCount: 0,
				page: 1,
			}
		case 'recordListPageChanged':
			return {
				...state,
				page: action.payload
			}
		case 'recordsPerPageChanged':
			return {
				...state,
				recordsPerPage: action.payload
			}
		case 'recordListSamplerChanged':
			return {
				...state,
				sampler: action.payload.sampler,
				samplerLabel: action.payload.label,
				filter: {},
				page: 1
			}
		case 'recordListFilterChanged':
			return {
				...state,
				filter: action.payload,
				page: 1
			}
		case 'recordListSortChanged':
			if (state.sortFieldName !== action.payload || state.sortDirection === 'asc')
				return {
					...state,
					sortFieldName: action.payload,
					sortDirection: state.sortFieldName !== action.payload ? 'asc' : 'desc',
					page: 1
				}
			else
				return {
					...state,
					sortFieldName: undefined,
					sortDirection: 'asc',
					page: 1
				}
		case 'fullRecordIdSetFetched':
			return {
				...state,
				isFullIdRecordListLoading: true,
				fullRecordIdSet: new Set()
			}
		case 'fullRecordIdSetLoaded': {
			const selectedRecordIdSet = getSetsIntersection(state.selectedRecordIdSet, action.payload)
			return {
				...state,
				isFullIdRecordListLoading: false,
				fullRecordIdSet: action.payload,
				selectedRecordIdSet: selectedRecordIdSet,
				isAllRecordsSelected: areSetsEqual(selectedRecordIdSet, action.payload)
			}
		}
		case 'fullRecordIdSetCleared': {
			return {
				...state,
				fullRecordIdSet: new Set(),
				selectedRecordIdSet: new Set(),
				isAllRecordsSelected: false
			}
		}
		case 'recordSelected': {
			const selectedRecordIdSet = new Set(state.selectedRecordIdSet)
			selectedRecordIdSet.add(action.payload)
			return {
				...state,
				selectedRecordIdSet: selectedRecordIdSet,
				isAllRecordsSelected: areSetsEqual(state.fullRecordIdSet, selectedRecordIdSet)
			}
		}
		case 'recordDeselected': {
			const selectedRecordIdSet = new Set(state.selectedRecordIdSet)
			selectedRecordIdSet.delete(action.payload)
			return {
				...state,
				selectedRecordIdSet: selectedRecordIdSet,
				isAllRecordsSelected: false,
			}
		}
		case 'allRecordsSelected':
			return {
				...state,
				selectedRecordIdSet: new Set(state.fullRecordIdSet),
				isAllRecordsSelected: true
			}
		case 'allRecordsDeselected':
			return {
				...state,
				selectedRecordIdSet: new Set(),
				isAllRecordsSelected: false
			}
		case 'recordFetched':
			return {
				...state,
				record: undefined,
				isRecordLoading: true,
				error: null
			}
		case 'recordFailed':
			return {
				...state,
				isRecordLoading: false,
				error: action.payload
			}
		case 'recordLoaded':
			return {
				...state,
				isRecordLoading: false,
				error: null
			}
		default:
			return state
	}
}


/** Table API resource store state & reducer hook */
export const useTableApiResourceReducer:
	<T extends IRecordWithId, S extends string>() => [TableApiResourceState<T, S>, Dispatch<TableApiResourceAction<T, S>>] =
	<T extends IRecordWithId, S extends string>() => useReducer<Reducer<TableApiResourceState<T, S>, TableApiResourceAction<T, S>>>(communicationReducer, communicationInitState)


// Table API resource tore action creators
export const recordListFetchedAction =
	<T extends IRecordWithId, S extends string>(): TableApiResourceAction<T, S> =>
		({ type: 'recordListFetched' })
export const recordListFailedAction =
	<T extends IRecordWithId, S extends string>(errorMessage: string): TableApiResourceAction<T, S> =>
		({ type: 'recordListFailed', payload: errorMessage })
export const recordListLoadedAction =
	<T extends IRecordWithId, S extends string>(recordListWithPagination: IRecordListWithPagination<T>): TableApiResourceAction<T, S> =>
		({ type: 'recordListLoaded', payload: recordListWithPagination })
export const recordListClearedAction =
	<T extends IRecordWithId, S extends string>(): TableApiResourceAction<T, S> =>
		({ type: 'recordListCleared' })
export const recordListPageChanged =
	<T extends IRecordWithId, S extends string>(page: number): TableApiResourceAction<T, S> =>
		({ type: 'recordListPageChanged', payload: page })
export const recordsPerPageChanged =
	<T extends IRecordWithId, S extends string>(itemsPerPage: number): TableApiResourceAction<T, S> =>
		({ type: 'recordsPerPageChanged', payload: itemsPerPage })
export const recordListSamplerChanged =
	<T extends IRecordWithId, S extends string>(sampler: TableSampler, label: string): TableApiResourceAction<T, S> =>
		({ type: 'recordListSamplerChanged', payload: { sampler, label } })
export const recordListFilterChanged =
	<T extends IRecordWithId, S extends string>(filter: TableFilter<string>): TableApiResourceAction<T, S> =>
		({ type: 'recordListFilterChanged', payload: filter })
export const recordListSortChanged =
	<T extends IRecordWithId, S extends string>(fieldName?: string): TableApiResourceAction<T, S> =>
		({ type: 'recordListSortChanged', payload: fieldName })
export const fullRecordIdSetFetchedAction =
	<T extends IRecordWithId, S extends string>(): TableApiResourceAction<T, S> =>
		({ type: 'fullRecordIdSetFetched' })
export const fullRecordIdSetLoadedAction =
	<T extends IRecordWithId, S extends string>(recordIdSet: Set<T['id']>): TableApiResourceAction<T, S> =>
		({ type: 'fullRecordIdSetLoaded', payload: recordIdSet })
export const fullRecordIdSetClearedAction =
	<T extends IRecordWithId, S extends string>(): TableApiResourceAction<T, S> =>
		({ type: 'fullRecordIdSetCleared' })
export const recordSelectedAction =
	<T extends IRecordWithId, S extends string>(recordId: T['id']): TableApiResourceAction<T, S> =>
		({ type: 'recordSelected', payload: recordId })
export const recordDeselectedAction =
	<T extends IRecordWithId, S extends string>(recordId: T['id']): TableApiResourceAction<T, S> =>
		({ type: 'recordDeselected', payload: recordId })
export const allRecordsSelectedAction =
	<T extends IRecordWithId, S extends string>(): TableApiResourceAction<T, S> =>
		({ type: 'allRecordsSelected' })
export const allRecordsDeselectedAction =
	<T extends IRecordWithId, S extends string>(): TableApiResourceAction<T, S> =>
		({ type: 'allRecordsDeselected' })
export const recordFetchedAction =
	<T extends IRecordWithId, S extends string>(): TableApiResourceAction<T, S> =>
		({ type: 'recordFetched' })
export const recordFailedAction =
	<T extends IRecordWithId, S extends string>(errorMessage: string): TableApiResourceAction<T, S> =>
		({ type: 'recordFailed', payload: errorMessage })
export const recordLoadedAction =
	<T extends IRecordWithId, S extends string>(record: T): TableApiResourceAction<T, S> =>
		({ type: 'recordLoaded', payload: record })
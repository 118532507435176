import { useRef, useState } from 'react'
import { useInputEnterDown } from '@/hooks/useInputEnterDown'
import { TableColumnConfig } from '@/context/types'
import useOuterClick from '@/hooks/useOuterClick'


type Props<TColumnName extends string> = {
  type?: TableColumnConfig<any>['type']
  name: TColumnName
  value?: string
  values?: string[]
  placeholder?: string
  className?: string
  updateFilterForm: (name: TColumnName, value?: string, callSetFilter?: boolean) => void
  applyFilter: () => void
}

/** Поле ввода фильтра таблицы */
export default function TableFilterInput<TColumnName extends string>({
  type,
  name,
  value = '',
  values,
  placeholder = 'Фильтр...',
  className = 'form-control form-control-sm border-0 shadow-none rounded-0 px-1',
  updateFilterForm,
  applyFilter,
}: Props<TColumnName>) {
  const datePopupRef = useRef(null as HTMLDivElement | null)
  const dateInputRef = useRef(null as HTMLInputElement | null)
  const [showDatePopup, setShowDatePopup] = useState(false)
  useOuterClick(datePopupRef, () => setShowDatePopup(false))

  const setValue = (...args: OmitFirst<Parameters<typeof updateFilterForm>>) => updateFilterForm(name, ...args)

  const handleEnterDown = useInputEnterDown(applyFilter)

  switch (type) {
    case 'bool':
    case 'boolExists':
      return (
        <select
          className={className}
          value={value}
          onChange={e => setValue(e.target.value, true)}
        >
          <option>{placeholder}</option>
          <option value='true'>{values?.[0] || 'Да'}</option>
          <option value='false'>{values?.[1] || 'Нет'}</option>
        </select>
      )
    case 'date':
      value = value.slice(0, -1) // removing '!' (exact filter type)

      return (
        <>
          {showDatePopup &&
            <div
              className='d-flex gap-3 p-3 position-absolute bg-white border border-primary rounded-2'
              ref={datePopupRef}
            >
              <input className='form-control' type='date' defaultValue={value} ref={dateInputRef} />
              <button
                onClick={() => {
                  setValue(`${dateInputRef.current!.value}!`, true)
                  setShowDatePopup(false)
                }}
                className='btn btn-outline-primary'
                type='button'
              >
                ОК
              </button>
            </div>
          }
          <input
            className={className}
            type='text'
            placeholder={placeholder}
            defaultValue={value ? new Date(value).toLocaleDateString('ru') : ''}
            onClick={() => setShowDatePopup(true)}
          />
        </>
      )
    default:
      return (
        <input
          className={className}
          type='text'
          placeholder={placeholder}
          value={value}
          enterKeyHint='search'
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleEnterDown}
        />
      )
  }
}
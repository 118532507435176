import { ApiResource, ICctv, ICctvAudit, IRecord } from '@/context/types'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faFolderOpen, faTv } from '@fortawesome/free-solid-svg-icons'


export type ICctvAuditField = {
  title: string
  placeholder?: string
} & ({
  key: keyof PickByType<ICctvAudit, boolean>
  type: 'bool'
} | {
  key: keyof PickByType<ICctvAudit, string>
  type: 'string'
} | {
  key: keyof PickByType<ICctvAudit, number>
  type: 'int'
} | {
  key: keyof PickByType<ICctvAudit, IRecord>
  type: 'apiResource'
  apiResource: ApiResource
  descriptionFieldKey?: keyof PickByType<ICctvAudit, string>
} | {
  key: 'auditDate' //todo-sem should be keyof PickByType<ICctvAudit, Date>
  type: 'date'
})

const cctvAuditFields: ICctvAuditField[] = [
  {
    title: 'Адрес ТВН подтверждён',
    key: 'auditAddress',
    type: 'bool',
  },
  {
    title: 'Место расположения ТВН подтверждено',
    key: 'auditLocation',
    type: 'bool',
  },
  {
    title: 'Монитор в наличии',
    key: 'auditMonitor',
    type: 'bool',
  },
  {
    title: 'ИБП в наличии',
    key: 'auditUps',
    type: 'bool',
  },
  {
    title: 'Локальные консоли в наличии',
    key: 'auditLocalsConsoles',
    type: 'bool',
  },
  {
    title: 'NVR в наличии',
    key: 'auditNvr',
    type: 'bool',
  },
  {
    title: 'Коммутатор в наличии',
    key: 'auditNetworkSwitch',
    type: 'bool',
  },
  {
    title: 'Камера 1 в наличии',
    key: 'auditIpCamera1',
    type: 'bool',
  },
  {
    title: 'Камера 2 в наличии',
    key: 'auditIpCamera2',
    type: 'bool',
  },
]
const cctvAuditEdg2023Fields: ICctvAuditField[] = [
  {
    title: 'Адрес Точки видеонаблюдения (далее – ТВН) подтверждён:',
    key: 'auditAddress',
    type: 'bool',
  },
  {
    title: 'Тип помещения (спортзал, актовый зал, рекреация, холл и т.д.) с указанием этажа',
    key: 'auditRoomType',
    type: 'apiResource',
    apiResource: ApiResource.RoomType,
    descriptionFieldKey: 'auditRoomTypeDescription',
  },
  {
    title: '',
    key: 'auditRoomFloor',
    type: 'int',
    placeholder: 'этаж'
  },
  {
    title: 'Приблизительная площадь помещения УИК',
    key: 'auditRoomSquare',
    type: 'apiResource',
    apiResource: ApiResource.RoomSquare,
  },
  {
    title: 'Наличие розеток (указать количество)',
    key: 'auditOutletAvailability',
    type: 'apiResource',
    apiResource: ApiResource.OutletAvailability,
  },
  {
    title: 'Наличие помещения для хранения техники (да/нет)',
    key: 'auditEquipmentStorage',
    type: 'bool',
  },
  {
    title: 'Тип и приблизительная площадь помещения для хранения техники',
    key: 'auditStorageRoomSquare',
    type: 'apiResource',
    apiResource: ApiResource.StorageRoomSquare,
  },
  {
    title: '',
    key: 'auditStorageRoomType',
    type: 'apiResource',
    apiResource: ApiResource.StorageRoomType,
    descriptionFieldKey: 'auditStorageRoomTypeDescription',
  },
  {
    title: 'Помещение для хранения техники запирается (Да/Нет)',
    key: 'auditEquipmentStorageLockable',
    type: 'bool',
  },
  {
    title: 'Место расположения ТВН подтверждено (Да/Нет)',
    key: 'auditLocation',
    type: 'bool',
  },
  {
    title: 'Монитор видеонаблюдения в наличии (Да/Нет)',
    key: 'auditMonitor',
    type: 'bool',
  },
  {
    title: 'Локальные консоли видеонаблюдения в наличии (Да/Нет)',
    key: 'auditLocalsConsoles',
    type: 'bool',
  },
  {
    title: 'NVR видеонаблюдения в наличии (Да/Нет)',
    key: 'auditNvr',
    type: 'bool',
  },
  {
    title: 'Коммутатор видеонаблюдения в наличии (Да/Нет)',
    key: 'auditNetworkSwitch',
    type: 'bool',
  },
  {
    title: 'Камера 1 в наличии в месте УИК (Да/Нет)',
    key: 'auditIpCamera1',
    type: 'bool',
  },
  {
    title: 'Камера 2 в наличии в месте УИК (Да/Нет)',
    key: 'auditIpCamera2',
    type: 'bool',
  },
  {
    title: 'Возможность переноса существующих камер на место расположения УИК (Да/Нет)',
    key: 'auditCamerasMovingAbility',
    type: 'bool',
  },
  {
    title: 'Видеонаблюдение обеспечивает тайну голосования (Да/Нет)',
    key: 'auditSurveillanceEnsureVoteSecrecy',
    type: 'bool',
  },
  {
    title: 'Отсутствие посторонних предметов, перекрывающих обзор камеры (Да/Нет)',
    key: 'auditAbsentObjectsBlockingView',
    type: 'bool',
  },
  {
    title: 'Переносная урна видна (Да/Нет) (на схеме)',
    key: 'auditPortableBallotBoxVisible',
    type: 'bool',
  },
  {
    title: 'Автоматизированное рабочее место УИК в камеру видно (Да/Нет) (на схеме)',
    key: 'auditPecWorkplaceVisible',
    type: 'bool',
  },
  {
    title: 'Попадает ли увеличенная форма протокола (Да/Нет) (на схеме)',
    key: 'auditEnlargedFormInView',
    type: 'bool',
  },
  {
    title: 'Место установки ТД Wi-Fi позволяет равномерно покрыть всю площадь УИК (Да/Нет)',
    key: 'auditApEvenlyCoversEntireArea',
    type: 'bool',
  },
  {
    title: 'Существующие операторы связи на Объекте, Радио/ВОЛС',
    key: 'auditExistingTelecomOperators',
    type: 'string',
  },
  {
    title: 'Место расположения оператора связи',
    key: 'auditTelecomOperatorRoomType',
    type: 'apiResource',
    apiResource: ApiResource.TelecomOperatorRoomType,
    descriptionFieldKey: 'auditTelecomOperatorRoomTypeDescription',
  },
  {
    title: 'Место размещение шкафа согласовано (Да/Нет)',
    key: 'auditCabinetLocationApproved',
    type: 'bool',
  },
  {
    title: 'Трасса прокладки и точка подключения электропитания согласована (Да/Нет)',
    key: 'auditPowerRouteAndConnectionPointAgreed',
    type: 'bool',
  },
  {
    title: 'Уровень сигнала МТС',
    key: 'auditSignalLevelMts',
    type: 'string',
  },
  {
    title: 'Технология МТС',
    key: 'auditTechnologyMts',
    type: 'apiResource',
    apiResource: ApiResource.TelecomOperatorTechnology,
    descriptionFieldKey: 'auditTechnologyMtsDescription',
  },
  {
    title: 'Скорость вх МТС',
    key: 'auditSpeedRxMts',
    type: 'string',
  },
  {
    title: 'Скорость исх МТС',
    key: 'auditSpeedTxMts',
    type: 'string',
  },
  {
    title: 'Уровень сигнала Билайн',
    key: 'auditSignalLevelBeeline',
    type: 'string',
  },
  {
    title: 'Технология Билайн',
    key: 'auditTechnologyBeeline',
    type: 'apiResource',
    apiResource: ApiResource.TelecomOperatorTechnology,
    descriptionFieldKey: 'auditTechnologyBeelineDescription',
  },
  {
    title: 'Скорость вх Билайн',
    key: 'auditSpeedRxBeeline',
    type: 'string',
  },
  {
    title: 'Скорость исх Билайн',
    key: 'auditSpeedTxBeeline',
    type: 'string',
  },
  {
    title: 'Уровень сигнала Мегафон',
    key: 'auditSignalLevelMegafon',
    type: 'string',
  },
  {
    title: 'Технология Мегафон',
    key: 'auditTechnologyMegafon',
    type: 'apiResource',
    apiResource: ApiResource.TelecomOperatorTechnology,
    descriptionFieldKey: 'auditTechnologyMegafonDescription',
  },
  {
    title: 'Скорость вх Мегафон',
    key: 'auditSpeedRxMegafon',
    type: 'string',
  },
  {
    title: 'Скорость исх Мегафон',
    key: 'auditSpeedTxMegafon',
    type: 'string',
  },
  {
    title: 'Уровень сигнала Теле2',
    key: 'auditSignalLevelTele2',
    type: 'string',
  },
  {
    title: 'Технология Теле2',
    key: 'auditTechnologyTele2',
    type: 'apiResource',
    apiResource: ApiResource.TelecomOperatorTechnology,
    descriptionFieldKey: 'auditTechnologyTele2Description',
  },
  {
    title: 'Скорость вх Теле2',
    key: 'auditSpeedRxTele2',
    type: 'string',
  },
  {
    title: 'Скорость исх Теле2',
    key: 'auditSpeedTxTele2',
    type: 'string',
  },
  {
    title: 'Проверку проводил',
    key: 'auditConductedBy',
    type: 'string',
  },
  {
    title: 'Дата проверки',
    key: 'auditDate',
    type: 'date',
  },
  {
    title: 'Должность, ФИО Бригадира района',
    key: 'auditDistrictForemanCredentials',
    type: 'string',
  },
  {
    title: 'Дополнительные комментарии',
    key: 'auditAdditionalComments',
    type: 'string',
  },
]
export const getCctvAuditFields = (cctv?: ICctv) => {
  switch (cctv?.project?.name) {
    case 'ЕДГ 2023':
      return cctvAuditEdg2023Fields
    default:
      return cctvAuditFields
  }
}

export enum CctvView {
  default = 'default',
  stream = 'stream',
}

export const cctvViews = new Map<CctvView, { icon: IconDefinition, label: string }>([
  [CctvView.default, { icon: faFolderOpen, label: 'Сведения о ТВН' }],
  [CctvView.stream, { icon: faTv, label: 'Трансляция' }],
])
import { serviceStatusLabels } from '@/config/status/labels'
import { useAuthContext } from '@/context/auth/AuthContext'
import TableStatusMenu, { WrapperTableStatusMenuProps } from '@/components/common/table/TableStatusMenu'
import { apiRoutes } from '@/config/routes'
import { useServiceListContext } from '@/context/tables/ServiceListContext'


const ServiceTableStatusMenu = (props: WrapperTableStatusMenuProps<ServiceStatusKey>) => {
  const { hasPermission } = useAuthContext()
  const { selectedRecordIdSet, loadRecordList, deselectAllRecords } = useServiceListContext()

  return (
    <TableStatusMenu
      {...props}
      fieldLabelsRecord={serviceStatusLabels}
      recordApiRoute={apiRoutes.service}
      selectedRecordIdSet={selectedRecordIdSet}
      deselectAllRecords={deselectAllRecords}
      loadRecordList={loadRecordList}
      hasEditPermission={hasPermission('channelStageEdit')}
    />
  )
}

export default ServiceTableStatusMenu

import { useCallback, useEffect, useState } from 'react'
import { IBillingTotals, IServiceWithBilling, IServiceType } from '@/context/types'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, routes } from '@/config/routes'
import {
  BillingExpenseTableColumnName, BillingExpenseTableColumnNameList, BillingExpenseTableColumns,
  prepareBillingExpenseAdditionalCols,
} from '@/config/tables/lists/commutication/billingExpense'
import { isObjectEmpty } from '@/utils/tools'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useBillingExpenseListContext } from '@/context/tables/BillingExpenseListContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CommunicationNav from '@/components/communication/CommunicationNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import PaginationWSP from '@/components/common/PaginationWSP'
import BillingTableToolbar from '@/components/communication/BillingTableToolbar'
import TableRow from '@/components/common/table/TableRow'
import TableHead from '@/components/common/table/TableHead'
import { formatPrice } from '@/utils/formatters'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import Loader from '@/components/common/Loader'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'


type Props = {
  serviceTypeKindName: IServiceType['typeKindName']
}

export default function BillingExpenseTablePageContent({ serviceTypeKindName }: Props) {
  const { hasPermission } = useAuthContext()
  const [billingTotals, setBillingTotals] = useState<IBillingTotals>()
  const { showModalDialog } = useModal()

  useDocumentTitle('Таблица биллинга: Затратная часть')
  const availableCols = useTableAvailableCols(BillingExpenseTableColumnNameList, BillingExpenseTableColumns)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
  } = useBillingExpenseListContext()
  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter
  } = useTableFilterForm(filter, setRecordListFilter)

  const loadFilteredRecordList = useCallback(() => loadRecordList({
    'contractServiceTypePrice.serviceType.typeKindName': serviceTypeKindName,
  }), [loadRecordList, serviceTypeKindName])

  useEffect(() => {
    loadFilteredRecordList()
  }, [loadFilteredRecordList])

  useEffect(() => {
    if (filter.contractNumber)
      axios.get(apiRoutes.serviceBillingTotals(filter.contractNumber, serviceTypeKindName))
        .then(res => setBillingTotals(res.data))
  }, [filter.contractNumber, serviceTypeKindName])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('биллинга'))
  }, [showModalDialog])

  const isLoading = isRecordListLoading || isFullIdRecordListLoading

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem path={routes.communication}>Связь</BreadcrumbsItem>
        <BreadcrumbsItem>Таблица биллинга: Затратная часть</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <CommunicationNav />
      <BillingTableToolbar<BillingExpenseTableColumnName>
        recordListLength={recordList.length}
        recordListCount={count}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        filterForm={filterForm}
        updateFilterForm={updateFilterForm}
        applyFilter={applyFilter}
        onReload={loadFilteredRecordList}
        handleShowFilterInfo={handleShowFilterInfo}
      />
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{ marginTop: 8, height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={availableCols}
                columns={BillingExpenseTableColumns}
                additionalColumns={recordList.length && billingTotals ? prepareBillingExpenseAdditionalCols(recordList[0], billingTotals) : []}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
              >
                {billingTotals && !!recordList.length &&
                  <tr>
                    <th colSpan={availableCols.length} />
                    <th colSpan={2}></th>
                    {billingTotals.months.map(month =>
                      <th key={month.code}>∑={formatPrice(month.expenses)}</th>,
                    )}
                    <th>∑={formatPrice(billingTotals.total.expenses)}</th>
                  </tr>
                }
              </TableHead>
              <tbody>
                {!recordList.length || !billingTotals
                  ? <EmptyTableRow columnCount={availableCols.length}>
                    {filter.contractNumber ? (isObjectEmpty(filter) ? 'Записей нет' : 'Записей не найдено') : 'введите № ГК'}
                  </EmptyTableRow>
                  : recordList.map(record =>
                    <TableRow<IServiceWithBilling, BillingExpenseTableColumnName>
                      key={record.id}
                      columnNameList={availableCols}
                      columns={BillingExpenseTableColumns}
                      record={record}
                      additionalColumns={prepareBillingExpenseAdditionalCols(record, billingTotals)}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount}
          />
        </div>
      </div>
    </>
  )
}
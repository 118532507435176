import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{
  label: string
  width?: string
}>

/** Строка таблицы параметров */
export default function ParamTableRow({ label, width, children }: Props) {
  return (
        <tr>
          <td className='text-muted ps-2' width={width}>{label}</td>
          <td className='pe-2'>{children}</td>
        </tr>
  )
}
import { IAddress, ICommunicationAddress, IRecord, ISelectOption, IUser, IOrganization } from '@/context/types'
import { Props } from 'react-select/dist/declarations/src/Select'
import { strIncludesAnyCase } from '@/utils/string'


const recordToSelectOption = (record: IRecord, label = ''): ISelectOption => ({
  value: 'id' in record ? record.id : record.code,
  label: label || (
    'name' in record
      ? record.name
      : (
        'code' in record
          ? record.code
          : record.id.toString()
      )
  )
})

/** Преобразование записи в опшен по умолчанию */
export default (r: IRecord) => recordToSelectOption(r)

export const recordToSelectOptionCustomLabel = recordToSelectOption

export const recordToSelectOptionNumberLabel = (record: IRecord & { number: string | number }) =>
  recordToSelectOption(record, '' + record.number)


export const addressToSelectOption = (address: IAddress): ISelectOption =>
  recordToSelectOptionCustomLabel(
    address,
    `[${address.storageId}] ${address.name}`
  )

export const communicationAddressToSelectOption = (communicationAddress: ICommunicationAddress): ISelectOption =>
  recordToSelectOptionCustomLabel(
    communicationAddress,
    `[${communicationAddress.address.storageId}] ${communicationAddress.name}`
  )

export const userToSelectOption = (user: IUser): ISelectOption =>
  recordToSelectOptionCustomLabel(
    user,
    `${user.firstName} ${user.lastName} (${user.login})`,
  )

  export const organizationToSelectOption = (organization: IOrganization): ISelectOption =>
  recordToSelectOptionCustomLabel(
    organization,
    `${organization.name}`,
  )

export const defaultFilterOption: Props<any, any, any>['filterOption'] = (option, inputValue) =>
  strIncludesAnyCase(option.label, inputValue)

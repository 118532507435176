import { useEffect } from 'react'


/**
 * В режиме разработки (`NODE_ENV = 'development'`) поддерживает актуальную копию состояния в хранилище бразуера
 * @param name Имя состояния
 * @param state Состояние
 */
export default function useStorageStateLog(name: string, state: any) {

	useEffect(() => {
		if (process.env.NODE_ENV !== 'development') return
		sessionStorage.setItem(name, JSON.stringify(state))
		return () => {
			sessionStorage.removeItem(name)
		}
	}, [name, state])
}
import { instanceAxios as axios } from '@/api/instanceAxios'
import { ICctv, ICommentForm, IPec } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { apiRoutes } from '@/config/routes'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import TextAreaInput from '@/components/common/form/TextAreaInput'
import { getItemLabel } from '@/utils/tools'


type Props = {
  item: ICctv | IPec
  reloadCard: () => Promise<void>
}

export default function CommentForm({ item, reloadCard }: Props) {
  const { form } = useFormContext<ICommentForm>()
  const { showModalDialog, usePrimaryCallback } = useModal()

  usePrimaryCallback(async () => {
    try {
      await axios.post(
        item.itemType === 'cctv' ? apiRoutes.cctvComments : apiRoutes.pecComments,
        {
          ...form,
          [item.itemType]: item.id,
        },
      )
      showModalDialog({
        type: 'ok',
        header: `Добавить комментарий к ${getItemLabel(item)}`,
        content: `Комментарий к ${getItemLabel(item)} сохранён`,
      })
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `Ошибка при сохранении комментария к ${getItemLabel(item)}`,
        content: await getErrorMessage(e),
      })
    }
  }, [form, item, reloadCard, showModalDialog])

  return <TextAreaInput label='Текст комментария' name='content' />
}

import { useCallback, useMemo } from 'react'
import { IChannel, IStatusStage } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getTextColorClassName } from '@/utils/color'
import { apiRoutes } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { channelStatusFsm, getFsmValue } from '@/config/status/fsm'
import { FormContextProvider } from '@/context/form/FormContext'
import AddChannelCommentModalContent from '@/components/modals/common/AddChannelCommentModalContent'


type Props = {
  channel: IChannel
  status: IStatusStage
  statusFieldName: ChannelStatusKey
  reloadCard: () => void
}

export default function ChannelStatusControlMenuItem({ channel, status, statusFieldName, reloadCard }: Props) {
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()

  const setStatus = useCallback(async (comment?: IChannel['comment']) => {
    try {
      await axios.patch(
        apiRoutes.channel(channel.id),
        {
          [statusFieldName]: status.code,
          comment,
        },
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении статуса',
        content: await getErrorMessage(e),
      })
    }
  }, [status.code, statusFieldName, channel.id, reloadCard, showModalDialog])

  const handleSetStatus = useCallback(() => {
    const needComment = getFsmValue(channelStatusFsm, statusFieldName, channel[statusFieldName].code, statusFieldName, status.code)?.needComment

    if (needComment)
      showModalDialog({
        type: 'okCancel',
        size: 'lg',
        header: 'Требуется комментарий',
        content:
          <FormContextProvider>
            <AddChannelCommentModalContent onDone={setStatus} />
          </FormContextProvider>,
      })
    else
      void setStatus()
  }, [status.code, statusFieldName, setStatus, showModalDialog])

  const disabled = useMemo(() => {
    const checkFsmRoles = (fromKey: keyof typeof channelStatusFsm) => {
      const roles = getFsmValue(channelStatusFsm, fromKey, channel[fromKey].code, statusFieldName, status.code)?.roles
      return roles && hasRole(roles)
    }

  	// Пункт меню недоступен, если:
  	return (
			// переход в текущем меню запрещен
  		!checkFsmRoles(statusFieldName)
  	)
  }, [status, statusFieldName, channel, hasRole])

  if (status?.code === channel?.stage?.code)
    return (
      <button className='dropdown-item active'>
        <FontAwesomeIcon
          icon={faCircle}
          className={`${getTextColorClassName(status.code)} me-2`} />
        {status.name}
      </button>
    )

  return (
    <button
      className='dropdown-item'
      type='button'
      disabled={disabled}
      onClick={handleSetStatus}>
      <FontAwesomeIcon
        icon={faCircle}
        className={`${getTextColorClassName(status.code)} me-2`} />
      {status.name}
    </button>
  )
}
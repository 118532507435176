import { useCallback } from 'react'
import Tooltip from '@/components/common/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'


type Props = {
	icon: IconProp
	color?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'muted'
	tooltip?: string
	disabled?: boolean
	onClick?: () => void
}


/** Маленькая круглая кнопка */
export default function SmallRoundBtn({ icon, color = 'primary', tooltip, disabled = false, onClick }: Props) {

	const handleClick = useCallback(() => {
		onClick?.()
	}, [onClick])

	return (
		<Tooltip title={tooltip}>
			<button
				className={`btn btn-sm btn-${color} rounded-circle p-0`}
				style={{ width: '25px', height: '25px' }}
				disabled={disabled}
				onClick={handleClick}>
				<FontAwesomeIcon icon={icon} />
			</button>
		</Tooltip>
	)
}
import { useState } from 'react'
import { ApiResource, IPec, IPecStatusField, IUser } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import Loader from '@/components/common/Loader'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import { UserRole } from '@/config/roles'
import { patchPecResponsibleFromCustomer } from '@/api/queries/audit'


type Props = {
  pec: IPec
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>
}

type Form = {
  responsibleFromCustomer: IUser['id']
}

export default function PecEditResponsibleFromCustomerForm({ pec, statusFieldId, reloadCard }: Props) {
  const { form } = useFormContext<Form>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  usePrimaryCallback(async () => {
    if (isLoading)
      return false
    
    setIsLoading(true)

    try {
      await patchPecResponsibleFromCustomer(pec.id, statusFieldId, form.responsibleFromCustomer)
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении модератора',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }, [isLoading, setIsLoading, form.responsibleFromCustomer, pec, statusFieldId, reloadCard])

  return (
    <div style={{minHeight: 375}}>
      <ApiResourceSelect<IPec['responsibleFromCustomers'][0], Form>
        apiResource={ApiResource.User}
        filter={{ 'userRoles.code': UserRole.responsible_from_customer }}
        label='Модератор'
        name='responsibleFromCustomer'
        initValue={pec.responsibleFromCustomers.find(e => e.statusFieldId === statusFieldId)}
        disabled={isLoading}
      />
      {isLoading && <Loader />}
    </div>
  )
}

import { IBillingTotals, IServiceWithBilling, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { ServiceTableColumnNameList, ServiceTableColumns } from '@/config/tables/lists/commutication/service'
import { formatDate, formatPrice } from '@/utils/formatters'


export const BillingExpenseTableColumnNameList = ServiceTableColumnNameList

export type BillingExpenseTableColumnName = typeof BillingExpenseTableColumnNameList[number]

export const BillingExpenseTableColumns: Record<BillingExpenseTableColumnName, TableColumnConfig<IServiceWithBilling>> = {
  ...ServiceTableColumns,
  contractNumber: {
    ...ServiceTableColumns.contractNumber,
    hidden: true,
  },
}

export const prepareBillingExpenseAdditionalCols = (service: IServiceWithBilling, billingTotals: IBillingTotals) => {
  const { billing, isSitronicsPort, channels } = service
  if (!billing)
    return []

  const cols: TableAdditionalColumnConfig[] = []

  cols.push(
    {
      label: 'Дата подключения канала',
      value: channels.map(channel => formatDate(billing.channels[channel.id]?.connectedDate)),
    },
    {
      label: 'Дата окончания последнего ОП в ГК',
      value: channels.map(channel => formatDate(billing.channels[channel.id]?.lastReportingPeriodDate)),
    }
  )

  billingTotals.months.map((m, i) => {
    cols.push({
      label: `Месяц ${i + 1}`,
      value: isSitronicsPort
        ? billing.constructionObject.monthCode === m.code ? formatPrice(billing.constructionObject.expenses) : 0
        : channels.map(channel => formatPrice(billing.channels[channel.id]?.monthlyExpenses[m.code] || 0)),
    })
  })

  cols.push({
    label: 'Итоговый расход',
    value: formatPrice(billing.total.expenses),
  })

  return cols
}

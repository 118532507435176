import { useMemo } from 'react'
import { routes } from '@/config/routes'
import TabsList, { DefaultTab } from '@/components/common/tabs/TabsList'


const CommunicationNav = () => {
  const defaultTabs: DefaultTab[] = useMemo(() => [
    {
      label: 'Дашборд',
      path: routes.communication,
      end: true,
    },
    {
      label: 'Услуги',
      path: routes.services,
      permissions: 'serviceRead',
      end: true,
    },
    {
      label: 'Объекты строительства',
      path: routes.constructionObjects,
      permissions: 'constructionObjectRead',
      end: true,
    },
    {
      label: 'Биллинг',
      path: routes.billingCommon,
      permissions: 'serviceBillingRead',
    },
    {
      label: 'Формирование акта',
      path: routes.actFormation,
      permissions: 'exportActs',
    },
  ], [])

  return <TabsList defaultTabs={defaultTabs} redirect={routes.services} />
}

export default CommunicationNav

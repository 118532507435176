import { useEffect, useState } from 'react'
import {
  ApiResource, ContractIdentifierTypes, IAddress, ICommunicationAddress, IConstructionObject, IConsumer, IContract,
  IContractServiceTypePrice, IDepartment, IJoint, IPortOwner, IQualityClass, IService, IServiceForm,
  ServiceTypeKindNames,
} from '@/context/types'
import TextInput from '@/components/common/form/TextInput'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, getApiResourceReadRoute } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import CheckboxInput from '@/components/common/form/CheckboxInput'
import DateInput from '@/components/common/form/DateInput'
import { convertContract } from '@/config/apiConverters'
import { communicationAddressToSelectOption, recordToSelectOptionNumberLabel } from '@/utils/recordToSelectOption'


type Props = {
  onSave: () => any
  service?: IService
}

const ServiceCreateEditForm = ({ onSave, service }: Props) => {
  const { showModalDialog, usePrimaryCallback } = useModal()
  const { form, setError } = useFormContext<IServiceForm>()
  const [isPort, setIsPort] = useState(false)
  const [addressStorageId, setAddressStorageId] = useState(null as IAddress['storageId'] | null)
  const [contract, setContract] = useState({} as IContract)
  const [isLoading, setIsLoading] = useState(false)

  usePrimaryCallback(async () => {
    if (!form.contract)
      setError('contract', 'Выберите контракт')
    if (!form.number)
      setError('number', 'Введите номер')

    if (!form.contract || !form.number)
      return false

    if (form.serviceBeginDate) {
      const serviceBeginDate = +new Date(form.serviceBeginDate)
      const reportingPeriodsBegin = Math.min(...contract.reportingPeriods.map(rp => +rp.beginDate).filter(t => t))
      const reportingPeriodsEnd = Math.max(...contract.reportingPeriods.map(rp => +rp.endDate).filter(t => t))
      if (serviceBeginDate > reportingPeriodsEnd || serviceBeginDate < reportingPeriodsBegin) {
        showModalDialog({
          type: 'error',
          header: `${service ? 'Обновление' : 'Добавление'} услуги: Ошибка`,
          content: await getErrorMessage(
            'serviceBeginDate', 'Введённая дата подключения услуги выходит за границы ОП ГК')
        })
        return
      }
    }

    try {
      setIsLoading(true)
      if (service) {
        const response = await axios.patch(
          apiRoutes.service(service.id),
          { ...form },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )

        if (response.status === 200)
          onSave()
      } else {
        const response = await axios.post(apiRoutes.services, { ...form })

        if (response.status === 201)
          onSave()
      }
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `${service ? 'Обновление' : 'Добавление'} услуги: Ошибка`,
        content: await getErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }, [form, service, contract, showModalDialog])

  useEffect(() => {
    if (!form.contractServiceTypePrice)
      return

    setIsLoading(true)

    axios.get<IContractServiceTypePrice>(
      getApiResourceReadRoute(ApiResource.ContractServiceTypePrice, form.contractServiceTypePrice)
    )
      .then(res => {
        setIsPort(res.data.serviceType?.typeKindName === ServiceTypeKindNames.port)
        setIsLoading(false)
      })
  }, [form.contractServiceTypePrice])

  useEffect(() => {
    if (!form.aAddress) {
      setAddressStorageId(null)
      return
    }

    setIsLoading(true)

    axios.get<ICommunicationAddress>(getApiResourceReadRoute(ApiResource.CommunicationAddress, form.aAddress))
      .then(res => {
        setAddressStorageId(res.data.address?.storageId)
        setIsLoading(false)
      })
  }, [form.aAddress])

  useEffect(() => {
    if (!form.contract) {
      setContract({} as IContract)
      return
    }

    setIsLoading(true)

    axios.get<IContract>(getApiResourceReadRoute(ApiResource.Contract, form.contract))
      .then(res => {
        setContract(convertContract(res.data))
        setIsLoading(false)
      })
  }, [form.contract])

  const portFieldPlaceholder = form.contractServiceTypePrice && form.aConsumer && addressStorageId
    ? (isPort ? '' : 'Доступно только для услуги типа "Порт"')
    : `Выберите ${[
      form.contractServiceTypePrice ? '' : 'Прайс',
      form.aConsumer ? '' : 'Наименование объекта А',
      addressStorageId ? '' : 'Адрес объекта А',
    ].filter(s => s).join(', ')}`

  return (
    <div className='m-5 display-flex m-2'>
      <TextInput<IServiceForm>
        label='Номер услуги'
        initValue={service?.number}
        name='number'
        disabled={isLoading}
        required
      />
      <TextInput<IServiceForm>
        label='Идентификатор базовой услуги'
        initValue={service?.basicIdentifier}
        name='basicIdentifier'
        disabled={isLoading}
        required
      />
      <ApiResourceSelect<IContract, IServiceForm>
        apiResource={ApiResource.Contract}
        filter={{ identifier: ContractIdentifierTypes.communication }}
        label='№ ГК'
        initValue={service?.contract}
        name='contract'
        recordToOption={recordToSelectOptionNumberLabel}
        disabled={isLoading}
        required
      />
      <ApiResourceSelect<IContractServiceTypePrice, IServiceForm>
        apiResource={ApiResource.ContractServiceTypePrice}
        key={form.contract}
        filter={{ 'contract.id': form.contract }}
        label='Прайс'
        initValue={service?.contractServiceTypePrice}
        name='contractServiceTypePrice'
        placeholder={form.contract ? '' : 'Выберите ГК'}
        disabled={isLoading || !form.contract}
      />
      <TextInput<IServiceForm>
        label='№ заказа начала оказания услуг'
        initValue={service?.orderBeginNumber}
        name='orderBeginNumber'
        disabled={isLoading}
      />
      <TextInput<IServiceForm>
        label='№ заказа на прекращение оказания услуги'
        initValue={service?.orderEndNumber || undefined}
        name='orderEndNumber'
        disabled={isLoading}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Наименование объекта А'
        initValue={service?.aConsumer}
        name='aConsumer'
        disabled={isLoading}
      />
      <ApiResourceSelect<IDepartment, IServiceForm>
        apiResource={ApiResource.Department}
        key={'department' + form.aConsumer}
        filter={{ 'consumers.id': form.aConsumer }}
        label='Наименование клиента'
        initValue={service?.department}
        name='department'
        placeholder={form.aConsumer ? '' : 'Выберите Наименование объекта А'}
        disabled={isLoading || !form.aConsumer}
      />
      <ApiResourceSelect<ICommunicationAddress, IServiceForm>
        apiResource={ApiResource.CommunicationAddress}
        async
        label='Адрес объекта А'
        initValue={service?.aAddress}
        name='aAddress'
        recordToOption={communicationAddressToSelectOption}
        disabled={isLoading}
      />
      <ApiResourceSelect<IJoint, IServiceForm>
        apiResource={ApiResource.Joint}
        label='Стык А'
        initValue={service?.aJoint}
        name='aJoint'
        disabled={isLoading}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Наименование объекта Б'
        initValue={service?.bConsumer || undefined}
        name='bConsumer'
        disabled={isLoading}
      />
      <ApiResourceSelect<ICommunicationAddress, IServiceForm>
        apiResource={ApiResource.CommunicationAddress}
        async
        label='Адрес объекта Б'
        initValue={service?.bAddress}
        name='bAddress'
        recordToOption={communicationAddressToSelectOption}
        disabled={isLoading}
      />
      <ApiResourceSelect<IJoint, IServiceForm>
        apiResource={ApiResource.Joint}
        label='Стык Б'
        initValue={service?.bJoint}
        name='bJoint'
        disabled={isLoading}
      />
      <ApiResourceSelect<IQualityClass, IServiceForm>
        apiResource={ApiResource.QualityClass}
        label='Класс качества'
        initValue={service?.qualityClass}
        name='qualityClass'
        disabled={isLoading}
      />
      <TextInput<IServiceForm>
        label='Причина'
        initValue={service?.cause || undefined}
        name='cause'
        disabled={isLoading}
      />
      <TextInput<IServiceForm>
        label='ФИО ответственного'
        initValue={service?.responsibleName || undefined}
        name='responsibleName'
        disabled={isLoading}
      />
      <TextInput<IServiceForm>
        label='Должность ответственного'
        initValue={service?.responsiblePositionName || undefined}
        name='responsiblePositionName'
        disabled={isLoading}
      />
      <TextInput<IServiceForm>
        label='Email ответственного'
        initValue={service?.responsibleEmail || undefined}
        name='responsibleEmail'
        disabled={isLoading}
      />
      <TextInput<IServiceForm>
        label='Телефон ответственного'
        initValue={service?.responsiblePhoneNumber || undefined}
        name='responsiblePhoneNumber'
        disabled={isLoading}
      />
      <ApiResourceSelect<IConstructionObject, IServiceForm>
        apiResource={ApiResource.ConstructionObject}
        key={'constructionObject' + form.aConsumer + addressStorageId}
        filter={addressStorageId ? {
          'consumer.id': form.aConsumer,
          'address.storageId': '' + addressStorageId,
        } : {}}
        label='Объект строительства'
        initValue={service?.constructionObject}
        name='constructionObject'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Имя ресурса 1'
        initValue={service?.resource1}
        name='resource1'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Имя ресурса 2'
        initValue={service?.resource2}
        name='resource2'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Имя ресурса 3'
        initValue={service?.resource3}
        name='resource3'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Имя VPN 1'
        initValue={service?.vpn1}
        name='vpn1'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Имя VPN 2'
        initValue={service?.vpn2}
        name='vpn2'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <ApiResourceSelect<IConsumer, IServiceForm>
        apiResource={ApiResource.Consumer}
        label='Имя VPN 3'
        initValue={service?.vpn3}
        name='vpn3'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <ApiResourceSelect<IPortOwner, IServiceForm>
        apiResource={ApiResource.PortOwner}
        label='Принадлежность порта'
        initValue={service?.portOwner}
        name='portOwner'
        placeholder={portFieldPlaceholder}
        disabled={isLoading || !!portFieldPlaceholder}
      />
      <DateInput<IServiceForm>
        disabled={!form.contract}
        label='Дата начала оказания услуги'
        initValue={service?.orderBeginDate || undefined}
        name='orderBeginDate'
      />
      <DateInput<IServiceForm>
        disabled={!form.contract}
        label='Дата подключения услуги'
        initValue={service?.serviceBeginDate || undefined}
        name='serviceBeginDate'
      />
      <DateInput<IServiceForm>
        disabled={!form.contract}
        label='Дата отключения услуги'
        initValue={service?.serviceEndDate || undefined}
        name='serviceEndDate'
      />
      <DateInput<IServiceForm>
        disabled={!form.contract}
        label='Дата прекращения оказания услуги'
        initValue={service?.orderEndDate || undefined}
        name='orderEndDate'
      />
      <TextInput<IServiceForm>
        label='Примечание'
        initValue={service?.description || undefined}
        name='description'
        disabled={isLoading}
      />
      <TextInput<IServiceForm>
        label='Комментарий'
        initValue={service?.comment || undefined}
        name='comment'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='Заказ получен от ДИТ'
        initValue={service?.hasOrderFromDit}
        name='hasOrderFromDit'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='Срочная услуга'
        initValue={service?.isUrgent}
        name='isUrgent'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='Криптозащита'
        initValue={service?.hasCrypto}
        name='hasCrypto'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='DPI'
        initValue={service?.hasDpi}
        name='hasDpi'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='Контент-фильтрация'
        initValue={service?.hasContentFilter}
        name='hasContentFilter'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='Резервирование'
        initValue={service?.hasReservation}
        name='hasReservation'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='Область А'
        initValue={service?.isAreaA}
        name='isAreaA'
        disabled={isLoading}
      />
      <CheckboxInput<IServiceForm>
        label='Область Б'
        initValue={service?.isAreaB}
        name='isAreaB'
        disabled={isLoading}
      />
    </div>
  )
}

export default ServiceCreateEditForm

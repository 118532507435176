import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faExternalLink } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@/components/common/Tooltip'
import { useState } from 'react'
import { ICctvAttachFile, ICctvExternalFile, IPecAttachFile } from '@/context/types'
import DeleteFileBtn from '@/components/common/btns/DeleteFileBtn'


type Props = {
  imageFiles: (ICctvAttachFile | ICctvExternalFile | IPecAttachFile)[]
  initialIndex: number
  canDelete: boolean
  reloadCard: () => any
}

const AttachedImagesGallery = ({ imageFiles, initialIndex, canDelete, reloadCard }: Props) => {
  const [index, setIndex] = useState(initialIndex)

  const file = imageFiles[index]!

  return (
    <div className='d-flex'>
      <button
        className='btn btn-link fs-4'
        disabled={index <= 0}
        onClick={() => setIndex(index - 1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div
        className='card border-secondary mb-2 mb-lg-0 justify-content-center align-items-center overflow-auto'
        style={{ width: 700, maxHeight: '75vh' }}
      >
        <img
          className='cam-online-picture card-img-top bg-secondary text-white mw-100 mh-100 w-auto h-auto'
          style={{ minHeight: 245 }}
          src={file.path}
          alt={file.name}
        />
        <div className='card-body px-2 py-0 border-secondary border-top d-flex justify-content-between w-100'>
          {canDelete
            ? <DeleteFileBtn file={file} onDelete={reloadCard} />
            : <div style={{ width: '1em' }}></div>
          }
          <div>{file.type?.name ?? 'Дополнительное фото'}</div>
          
          <div>{file.name}</div>
          <div className='text-end' style={{ width: '1em' }}>
            <Tooltip title='Открыть изображение в новом окне'>
              <a href={file.path} target='_blank' rel='noreferrer'>
                <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
      <button
        className='btn btn-link fs-4'
        disabled={index >= imageFiles.length - 1}
        onClick={() => setIndex(index + 1)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  )
}

export default AttachedImagesGallery

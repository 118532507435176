import { memo } from 'react'
import CustomControl from '@/components/ProjectsContent/monitoring/map/controls/CustomControl'
import IntervalTimer, { Props as IntervalTimerProps } from '@/components/common/time/IntervalTimer'
import Loader from '@/components/common/Loader'


type Props = YControlOptions & Pick<IntervalTimerProps, 'onTimerInterval'> & {
  isLoading: boolean
}

const ReloadControl = memo(({ float, floatIndex, onTimerInterval, isLoading }: Props) =>
  <CustomControl {...{ float, floatIndex }}>
    {isLoading
      ? <div className='px-2' style={{ opacity: .5}}>
        <Loader size={20} />
      </div>
      : <IntervalTimer onTimerInterval={onTimerInterval} sessionStorageKey='monitoringMapReloadIntervalInMS' />
    }
  </CustomControl>
)

export default ReloadControl

import { useCallback, useState } from 'react'
import { ICctv, IIPCamera } from '@/context/types'
import { errorHandle } from '@/utils/errorHandle'
import timestampedPath from '@/utils/timestampedPath'
import { UserRole } from '@/config/roles'
import { apiURL } from '@/config/routes'
import { postData } from '@/api/postData'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useModal } from '@/context/ModalContext'
import { editIpCameraInfoModal, editIpCameraVideoMarkerModal, shiftConfirmationWindowModal } from '@/components/modals/hardware/modals'
import FolderCard from '@/components/common/cards/FolderCard'
import ParamTable from '@/components/common/cards/ParamTable'
import ParamTableRow from '@/components/common/cards/ParamTableRow'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import Tooltip from '@/components/common/Tooltip'
import UploadBtn from '@/components/common/btns/UploadBtn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage, faPenToSquare, faTrash, faVideo } from '@fortawesome/free-solid-svg-icons'
import {
  ipCameraStreamLeftTableRows, ipCameraStreamRightTableRows,
} from '@/config/tables/cards/hardware/cctvStream/ipCamera'
import HardwareCardParamTableRows from '@/components/common/cards/hardware/HardwareCardParamTableRows'
import OpenSnapshotBtn from '@/components/common/btns/OpenSnapshotBtn'
import IpCameraCardSnapshot from '@/components/common/cards/hardware/IpCameraCardSnapshot'
import { unbindItemHardwareModal } from '@/components/modals/cctv/modals'


type Props = {
  cctv: ICctv
  ipCamera: IIPCamera
  reloadCctv: () => Promise<void>
}


/** Карточка IP-камеры в разделе "Трансляция" */
export const IpCameraPhotoCctv = ({ cctv, ipCamera, reloadCctv }: Props) => {
  const { hasRole } = useAuthContext()
  const { showModalDialog } = useModal()
  const [referenceSnapShot, setReferenceSnapShot] = useState<string>(timestampedPath(ipCamera.referenceSnapshotPath))

  /** Редактировать инфо IP-камеры */
  const handleEdit = useCallback(() => {
    showModalDialog(editIpCameraInfoModal(ipCamera, reloadCctv))
  }, [ipCamera, reloadCctv, showModalDialog])

  /** Отвязать IP-камеру от ТВН */
  const handleRemoveHardware = useCallback(async () => {
    showModalDialog(unbindItemHardwareModal(cctv, ipCamera, reloadCctv))
  }, [showModalDialog, cctv, ipCamera, reloadCctv])

  /** Редактировать видеомаркер */
  const handleEditVideoMarker = useCallback(() => {
    showModalDialog(editIpCameraVideoMarkerModal(ipCamera, reloadCctv))
  }, [ipCamera, reloadCctv, showModalDialog])

  /** Обновить эталонный снимок */
  const updateReferenceSnapShot = useCallback(() => {
    setReferenceSnapShot(timestampedPath(ipCamera.referenceSnapshotPath))
  }, [ipCamera])
  

  const shiftConfirmationWindowMarker = useCallback(() => {
    showModalDialog(shiftConfirmationWindowModal(ipCamera, reloadCctv))
  }, [ipCamera, reloadCctv, showModalDialog])

  const handleClickReferenceSnapShot = () => {
    postData({
      url: `${apiURL}/${ipCamera.referenceSnapshotSetFromIpCamera}`,
      successAction: (src) => setReferenceSnapShot(timestampedPath(ipCamera.referenceSnapshotPath)),
      errorHandler: (err) => errorHandle(err),
    })
  }

  const handleClickTrashSnapShot = () => {
    postData({
      url: `${apiURL}/${ipCamera.referenceSnapshotDelete}`,
      successAction: (res) => {
        res.status === 200 && setReferenceSnapShot('')
      },
      errorHandler: (err) => {
        errorHandle(err)
      },
    })
  }

  return (
    <FolderCard
      headerBgColor='#bac8ff'
      bodyBgColor='#edf2ff'
      header={`${ipCamera.type.name} ${ipCamera.code}`}
      centerToolbar={
        <>
        {cctv.ipCameras.length === 2 && (
          <button
          style={{height: '35px'}}
          type='submit'
          className='btn btn-primary'
          onClick={shiftConfirmationWindowMarker}
        >
          Поменять номера камер
        </button>
          )}
         <div style={{marginTop: '5px'}}>
          <SmallRoundBtn
            icon={faPenToSquare}
            tooltip='Редактировать сведения'
            disabled={!hasRole([UserRole.admin, UserRole.mgts])}
            onClick={handleEdit}
          />
         </div>
        </>
      }
      rightToolbar={
        <div style={{marginTop: '5px'}}>
        <SmallRoundBtn
          icon={faTrash}
          color='danger'
          tooltip='Отвязать от ТВН'
          disabled={!hasRole([UserRole.admin, UserRole.mgts])}
          onClick={handleRemoveHardware} />
          </div>
        }>     
      <div className='row mb-2'>
        <div className='col-lg'>
          <ParamTable>
            <HardwareCardParamTableRows
              rows={ipCameraStreamLeftTableRows}
              hardware={ipCamera}
              reloadCard={reloadCctv}
            />
          </ParamTable>
        </div>
        <div className='col-lg'>
          <ParamTable>
            <HardwareCardParamTableRows
              rows={ipCameraStreamRightTableRows}
              hardware={ipCamera}
              reloadCard={reloadCctv}
            />
            <ParamTableRow label='Сохранить эталонный снимок'>
              <div className='btn-toolbar gap-2'>
                <SmallRoundBtn
                  icon={faVideo}
                  tooltip='Взять снимок с камеры'
                  disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts])}
                  onClick={handleClickReferenceSnapShot} />
                <UploadBtn
                  apiRoute={`${apiURL}/${ipCamera.referenceSnapshotSetFromFile}`}
                  accept='.jpg,.jpeg'
                  icon={faFileImage}
                  label=''
                  tooltip='Выбрать локальный файл .JPG'
                  className='btn btn-sm btn-primary rounded-circle p-0'
                  style={{ width: '25px', height: '25px' }}
                  onSuccess={updateReferenceSnapShot}
                  disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts])} />
              </div>
            </ParamTableRow>
          </ParamTable>
        </div>
      </div>
      <div className='row mb-2 g-0'>
        <div className='col-lg-4 px-1 text-center'>
          <IpCameraCardSnapshot snapshotPath={ipCamera.snapshotPath}/>
        </div>
        <div className='col-lg-4 px-1 text-center'>
          <div className='card border-secondary mb-2 mb-lg-0 h-100'>
            <img
              className='cam-online-picture card-img-top bg-secondary text-white h-100'
              src={referenceSnapShot}
              alt='Эталонный снимок' />
            <div className='card-body px-2 py-0 border-secondary border-top d-flex justify-content-between'>
              <div style={{ width: '3em' }}></div>
              <div>Эталонный снимок</div>
              <div className='text-end' style={{ width: '3em' }}>
                <OpenSnapshotBtn path={referenceSnapShot} />
                <Tooltip title='Удалить эталонный снимок'>
                  <button
                    className='btn btn-link p-0 ms-2'
                    disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts])}
                    onClick={handleClickTrashSnapShot}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 px-1 text-center'>
          <div className='card border-secondary mb-2 mb-lg-0 h-100'>
            <img
              className='cam-online-picture card-img-top bg-secondary text-white h-100'
              src={ipCamera.previousReferenceSnapshotPath}
              alt='Эталонный снимок с предыдущих выборов' />
            <div className='card-body px-2 py-0 border-secondary border-top d-flex justify-content-between'>
              <div style={{ width: '1em' }}></div>
              <div>Эталонный снимок с предыдущих выборов</div>
              <div className='text-end' style={{ width: '1em' }}>
                <OpenSnapshotBtn path={ipCamera.previousReferenceSnapshotPath} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FolderCard>
  )
}

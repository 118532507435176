import { useCallback, useEffect } from 'react'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useModal } from '@/context/ModalContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import ChannelsNav from '@/components/channels/ChannelsNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import PaginationWSP from '@/components/common/PaginationWSP'
import TableHead from '@/components/common/table/TableHead'
import TableToolbar from '@/components/common/table/TableToolbar'
import { isObjectEmpty } from '@/utils/tools'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useChannelPaymentsListContext } from '@/context/tables/ChannelPaymentsListContext'
import {
  ChannelPaymentsTableColumnName, ChannelPaymentsTableColumnNameList, ChannelPaymentsTableColumns,
  prepareChannelPaymentsAdditionalCols,
} from '@/config/tables/lists/channels/channelPayments'
import TableRow from '@/components/common/table/TableRow'
import { apiRoutes } from '@/config/routes'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import { IChannelWithPayments } from '@/context/types'
import { DateRange, generateMonthsRange } from '@/utils/date'
import Loader from '@/components/common/Loader'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'


export default function ChannelPaymentsTablePageContent() {
  const { hasPermission } = useAuthContext()
  useDocumentTitle('Таблица реестра оплат')
  const { showModalDialog } = useModal()

  const availableCols = useTableAvailableCols(ChannelPaymentsTableColumnNameList, ChannelPaymentsTableColumns)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    isAllRecordsSelected,
    selectedRecordIdSet,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    samplerLabel,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
    setRecordListSampler,
  } = useChannelPaymentsListContext()

  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter
  } = useTableFilterForm(filter, setRecordListFilter)

  /** Сбросить выборку */
  const handleClearSampler = useCallback(() => {
    setRecordListSampler({}, '')
  }, [setRecordListSampler])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('реестра оплат'))
  }, [showModalDialog])

  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [])

  let paymentsRange: DateRange | undefined
  for (const channel of recordList)
    for (const { channelPaymentPeriod: { date } } of channel.channelPayments) {
      if (!paymentsRange) {
        paymentsRange = { from: date, to: date }
        continue
      }
      if (paymentsRange.from > date)
        paymentsRange.from = date
      if (paymentsRange.to < date)
        paymentsRange.to = date
    }

  const isLoading = isRecordListLoading || isFullIdRecordListLoading

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem>Каналы</BreadcrumbsItem>
        <BreadcrumbsItem>Список всех реестров оплат</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <ChannelsNav />
      <TableToolbar
        hasSampler
        samplerLabel={samplerLabel}
        recordListLength={recordList.length}
        recordListCount={count}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        selectedRecordIdSetSize={selectedRecordIdSet.size}
        fullRecordIdSet={fullRecordIdSet}
        clearFilter={clearFilter}
        onClearSampler={handleClearSampler}
        onReload={loadRecordList}
        exportBtnLabel='Массовый экспорт реестра оплат'
        exportApiRoute={hasPermission('exportChannelPayments') ? apiRoutes.exportChannelPayments : ''}
        handleShowFilterInfo={handleShowFilterInfo}
      />
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={availableCols}
                additionalColumns={paymentsRange ? prepareChannelPaymentsAdditionalCols(recordList[0], paymentsRange) : []}
                columns={ChannelPaymentsTableColumns}
                isAllRecordsSelected={isAllRecordsSelected}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
              >
                {paymentsRange &&
                  <tr>
                    <th colSpan={availableCols.length} />
                    {[...generateMonthsRange(paymentsRange.from, paymentsRange.to)].map(monthDate =>
                      <th key={+monthDate} colSpan={4}>
                        {monthDate.toLocaleDateString('ru-RU', { month: 'long' })} {monthDate.getFullYear()}
                      </th>,
                    )}
                  </tr>
                }
              </TableHead>
              <tbody>
                {!recordList.length
                  ? <EmptyTableRow columnCount={availableCols.length + 1}>
                    {isObjectEmpty(filter) ? 'Реестра оплат нет' : 'Реестра оплат не найдено'}
                  </EmptyTableRow>
                  : recordList.map(record =>
                    <TableRow<IChannelWithPayments, ChannelPaymentsTableColumnName>
                      key={record.id}
                      columnNameList={availableCols}
                      additionalColumns={paymentsRange ? prepareChannelPaymentsAdditionalCols(record, paymentsRange) : []}
                      columns={ChannelPaymentsTableColumns}
                      record={record}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount} />
        </div>
      </div>
    </>
  )
}

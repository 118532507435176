import { useCallback, useMemo } from 'react'
import { ApiResource, IIncident, IIncidentStageLogRecord, IProject } from '@/context/types'
import { UserRole } from '@/config/roles'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useModal } from '@/context/ModalContext'
import { ApiResourceContextProvider, useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import { incidentCommentModal, incidentDetailModal } from '@/components/modals/incidents/modals'
import Alert from '@/components/common/Alert'
import IncidentParams from '@/components/modals/incidents/IncidentParams'
import IncidentStageLog from '@/components/modals/incidents/IncidentStageLog'
import Loader from '@/components/common/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'


type Props = {
	projectId?: IProject['id']
	incidentId: IIncident['id']
	onUpdate: () => void
}


export default function IncidentDetailModalContent(props: Props) {
	return (
		<ApiResourceContextProvider
			apiResource={ApiResource.Incident}
			recordId={props.incidentId}
			loadList={false}>
			<IncidentDetailWrapper {...props} />
		</ApiResourceContextProvider>
	)
}


function IncidentDetailWrapper({ projectId, incidentId, onUpdate }: Props) {
	const { isRecordLoading, record, loadRecord } = useApiResourceContext<IIncident>()
	const incidentStageLogFilter = useMemo(() => {
		return { 'incident.id': incidentId.toString() }
	}, [incidentId])

	return (
		<ApiResourceContextProvider
			apiResource={ApiResource.IncidentStageLog}
			filter={incidentStageLogFilter}>
			<IncidentDetail
				projectId={projectId}
				incident={record}
				loadIncident={loadRecord}
				isIncidentLoading={isRecordLoading}
				onUpdate={onUpdate}
			/>
		</ApiResourceContextProvider>
	)
}


type IncidentDetailProps = {
	projectId?: IProject['id']
	incident?: IIncident
	loadIncident: () => void
	isIncidentLoading: boolean
	onUpdate: () => void
}

function IncidentDetail({ projectId, incident, loadIncident, isIncidentLoading, onUpdate }: IncidentDetailProps) {
	const { hasRole } = useAuthContext()
	const { showModalDialog } = useModal()
	const { isRecordListLoading: isLogLoading } = useApiResourceContext<IIncidentStageLogRecord>()

	const handleOpenIncidentDetail = useCallback(() => {
		if (!incident) return
		showModalDialog(incidentDetailModal(projectId, incident.id, onUpdate))
	}, [incident, onUpdate, showModalDialog, projectId])

	const handleAddMessage = useCallback(() => {
		if (!incident) return
		showModalDialog(incidentCommentModal(incident, handleOpenIncidentDetail, handleOpenIncidentDetail))
	}, [incident, showModalDialog, handleOpenIncidentDetail])

	if (isIncidentLoading || isLogLoading) return <Loader />

	if (!incident) return <Alert>Инцидент не найден</Alert>

	return (
		<>
			<IncidentParams projectId={projectId} incident={incident} loadIncident={loadIncident} onUpdate={onUpdate} />
			<IncidentStageLog />
			<button
				className='btn btn-link p-0 text-nowrap float-end'
				disabled={!hasRole([UserRole.admin, UserRole.pm, UserRole.mgts, UserRole.contractor])}
				onClick={handleAddMessage}>
				<FontAwesomeIcon icon={faPlus} className='me-2' />
				Добавить комментарий
			</button>
		</>
	)
}
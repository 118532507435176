import { IPec, IPecStatusField, IRecordWithId, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { formatDate } from '@/utils/formatters'


export const PecTableColumnNameList = [
  'number',
  'cctvNumber',
  'address',
] as const

export type PecTableColumnName = typeof PecTableColumnNameList[number]

const getHardwareIpAddressesValue = (hardware: { id: IRecordWithId['id'], ipAddress: string | null }[]) =>
  hardware.map(h => <div key={h.id}>{h.ipAddress}</div>)

export const PecTableColumns: Record<PecTableColumnName, TableColumnConfig<IPec>> = {
  number: {
    label: 'Номер УИК',
    value: pec => pec.number,
    filterKey: 'number',
    sortKey: 'number',
  },
  cctvNumber: {
    label: 'Номер ТВН',
    value: pec => pec.cctvNumber,
    filterKey: 'cctvNumber',
    sortKey: 'cctvNumber',
  },
  address: {
    label: 'Адрес',
    value: pec => pec.address.name,
    filterKey: 'address.name',
    filterExistsKey: 'address',
    sortKey: 'address.name',
  },
}

export const preparePecAdditionalCols = (
  pec: IPec,
  pecStatusFieldId: IPecStatusField['id'],
): TableAdditionalColumnConfig[] => [
  {
    label: 'ПО',
    value: pec.contractors.find(e => e.statusFieldId === pecStatusFieldId)?.name ?? '',
    key: 'contractor',
    filterKey: `contractors/${pecStatusFieldId}`,
    sortKey: `orderContractors/${pecStatusFieldId}`,
  },
  //{
   // label: 'Модератор',
   // value: pec.responsibleFromCustomers.find(e => e.statusFieldId === pecStatusFieldId)?.name ?? '',
   // key: 'responsibleFromCustomer',
  //  filterKey: `responsibleFromCustomers/${pecStatusFieldId}`,
   // sortKey: `orderResponsibleFromCustomers/${pecStatusFieldId}`,
  //},
  {
    label: 'Бригадир',
    value: pec.brigadiers.find(e => e.statusFieldId === pecStatusFieldId)?.name ?? '',
    key: 'brigadier',
    filterKey: `brigadiers/${pecStatusFieldId}`,
    sortKey: `orderBrigadiers/${pecStatusFieldId}`,
  },
  {
    label: 'Исполнитель',
    value: pec.executors.find(e => e.statusFieldId === pecStatusFieldId)?.name ?? '',
    key: 'executor',
    filterKey: `executors/${pecStatusFieldId}`,
    sortKey: `orderExecutors/${pecStatusFieldId}`,
  }, {
    label: 'Дата начала работ',
    value: formatDate(pec.workBeginDates.find(e => e.statusFieldId === pecStatusFieldId)?.date),
    key: 'workBeginDates',
    filterKey: `workBeginDates/${pecStatusFieldId}`,
    sortKey: `orderWorkBeginDates/${pecStatusFieldId}`,
    type: 'date',
  }, {
    label: 'Статус',
    value: pec.statusFields.find(pf => pf.id === pecStatusFieldId)?.valueName ?? '',
    filterKey: `statusFields/${pecStatusFieldId}`,
    sortKey: `orderStatusFields/${pecStatusFieldId}`,
  },
]

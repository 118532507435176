import { PropsWithChildren, ReactNode } from 'react'
import { HasPermissionParam, useAuthContext } from '@/context/auth/AuthContext'


type Props = PropsWithChildren<{
	permission: HasPermissionParam
	accessDeniedContent?: ReactNode
}>


/** Компонент-обертка для контроля доступа */
export default function PermissionRequired({ permission, children, accessDeniedContent = null }: Props) {
	const { initialized, hasPermission } = useAuthContext()

	if (!initialized)
		return null

	if (!hasPermission(permission))
		return <>{accessDeniedContent}</>

	return <>{children}</>
}

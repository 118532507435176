import { ChangeEvent, useCallback, useEffect, useId, useState } from 'react'
import { useFormContext } from '@/context/form/FormContext'
import InputGroup from '@/components/common/form/InputGroup'


type Props<TFrom> = {
  label: string
  name?: keyof TFrom
  initValue?: number
  placeholder?: string
  required?: boolean
  helpText?: string
  disabled?: boolean
}


/** Числовое поле ввода для контекстной формы */
export default function NumberInput<TFrom>({
  label,
  name,
  initValue,
  placeholder,
  required,
  helpText,
  disabled
}: Props<TFrom>) {
  const { setField, errors } = useFormContext<TFrom>()
  const [value, setValue] = useState<string>('')
  const id = useId()

  useEffect(() => {
    setValue(initValue?.toString() ?? '')
    if (name) setField(name, initValue ? +initValue : undefined)
  }, [name, initValue, setField])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (name) setField(name, event.target.value ? +event.target.value : null)
  }, [name, setField])

  return (
    <InputGroup
      id={id}
      label={label}
      required={required}
      helpText={helpText}
      invalidMessage={name && errors[name]}>
      <input
        className={`form-control ${name && errors[name] && 'is-invalid'}`}
        type='number'
        id={id}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </InputGroup>
  )
}

import { IPec, TableColumnConfig } from '@/context/types'
import { PecTableColumns } from '@/config/tables/lists/audit/pec'


export const PecCardRowNameList = [
  'number',
  'cctvNumber',
  'addressArea',
  'addressDistrict',
  'address',
  'completion',
] as const

export type PecCardRowName = typeof PecCardRowNameList[number]

export const PecCardRows: Record<PecCardRowName, TableColumnConfig<IPec>> = {
  number: PecTableColumns['number'],
  cctvNumber: PecTableColumns['cctvNumber'],
  addressArea: {
    label: 'Округ',
    value: pec => pec.address.district.area.name,
  },
  addressDistrict: {
    label: 'Район',
    value: pec => pec.address.district.name,
  },
  address: PecTableColumns['address'],
  completion: {
    label: 'Дострой',
    value: pec => pec.completion ? 'Да' : 'Нет',
  },
}

import { ChangeEvent, MouseEvent, useCallback, useState } from 'react'
import { IRecordWithId, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import TableCell from '@/components/common/table/TableCell'
import { useAuthContext } from '@/context/auth/AuthContext'
import { getAdditionalColKey } from '@/components/common/table/utils'


type Props<TRecord extends IRecordWithId, TColumnName extends string> = {
  columnNameList: readonly TColumnName[]
  columns: Record<TColumnName, TableColumnConfig<TRecord>>
  additionalColumns?: TableAdditionalColumnConfig[]
  record: TRecord
  selectedRecordIdSet?: Set<TRecord['id']>
  selectRecord?: (recordId: TRecord['id']) => void
  deselectRecord?: (recordId: TRecord['id']) => void
  expandable?: boolean
}


/** Строка таблицы */
export default function TableRow<TRecord extends IRecordWithId, TColumnName extends string>({
  columnNameList,
  columns,
  additionalColumns,
  record,
  selectedRecordIdSet,
  selectRecord,
  deselectRecord,
  expandable,
}: Props<TRecord, TColumnName>) {
  const { hasPermission } = useAuthContext()
  const [expand, setExpand] = useState(!expandable)

  const stopPropagation = useCallback((event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation()
  }, [])

  const handleSelectRecord = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked)
      selectRecord!(record.id)
    else
      deselectRecord!(record.id)
  }, [record.id, selectRecord, deselectRecord])

  const hasSelectColumn = selectedRecordIdSet && selectRecord && deselectRecord

  return (
    <tr onClick={() => expandable && setExpand(!expand)}>
      {hasSelectColumn &&
        <th className='text-center'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={selectedRecordIdSet?.has(record.id)}
            onClick={stopPropagation}
            onChange={handleSelectRecord}
          />
        </th>
      }
      {columnNameList
        .filter(columnName => columns[columnName].valueColspan !== 0)
        .map(columnName =>
        <TableCell
          key={columnName}
          colspan={columns[columnName].valueColspan}
          value={columns[columnName].value?.(record)}
          colorValue={columns[columnName].colorValue?.(record)}
          noWrap={columns[columnName].noWrap}
          expand={expand}
        />,
      )}
      {additionalColumns
        ?.filter(col => col.valueColspan !== 0)
        ?.map(col =>
        <TableCell
          key={getAdditionalColKey(col)}
          colspan={col.valueColspan}
          value={col.value}
          colorValue={col.colorValue}
          noWrap={col.noWrap}
          expand={expand}
        />,
      )}
    </tr>
  )
}

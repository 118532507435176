import { ReactNode } from 'react'
import { IReportSAC, RecordTableColumnConfig } from '@/components/reports/types'
import { formatDate } from '@/utils/formatters'


const cctvAuditReportTableColumns: RecordTableColumnConfig<IReportSAC>[] = [
  {
    label: '№ ТВН',
    value: reportRow => reportRow.cctv_number
  },
  {
    label: 'Тип ТВН',
    value: reportRow => reportRow.cctv_type_name
  },
  {
    label: 'Округ',
    value: reportRow => reportRow.area_name
  },
  {
    label: 'Район',
    value: reportRow => reportRow.district_name
  },
  {
    label: 'Адрес ТВН',
    value: reportRow => reportRow.address_name
  },
  {
    label: 'Адрес ТВН подтверждён',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_address)
  },
  {
    label: 'Монитор в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_monitor)
  },
  {
    label: 'ИБП в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_ups)
  },
  {
    label: 'Локальные консоли в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_local_consoles)
  },
  {
    label: 'NVR в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_nvr)
  },
  {
    label: 'Коммутатор в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_network_switch)
  },
  {
    label: 'Камера 1 в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_ip_camera_1)
  },
  {
    label: 'Камера 2 в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_ip_camera_2)
  },
]
const cctvAuditReportEdg2022TableColumns: RecordTableColumnConfig<IReportSAC>[] = [
  {
    label: '№ ТВН',
    value: reportRow => reportRow.cctv_number
  },
  {
    label: 'Тип ТВН',
    value: reportRow => reportRow.cctv_type_name
  },
  {
    label: 'Округ',
    value: reportRow => reportRow.area_name
  },
  {
    label: 'Район',
    value: reportRow => reportRow.district_name
  },
  {
    label: 'Адрес ТВН',
    value: reportRow => reportRow.address_name
  },
  {
    label: 'Адрес Точки видеонаблюдения (далее – ТВН) подтверждён',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_address)
  },
  {
    label: 'Тип помещения (спортзал, актовый зал, рекреация, холл и т.д.)',
    value: reportRow => convertMultiField(reportRow, 'cctv_room_type')
  },
  {
    label: 'Этаж помещения',
    value: reportRow => reportRow.cctv_room_floor
  },
  {
    label: 'Приблизительная площадь помещения УИК',
    value: reportRow => reportRow.cctv_room_square
  },
  {
    label: 'Наличие розеток (указать количество)',
    value: reportRow => reportRow.cctv_outlet_availability
  },
  {
    label: 'Наличие помещения для хранения техники',
    value: reportRow => convertBoolean(reportRow.cctv_has_equipment_storage)
  },
  {
    label: 'Приблизительная площадь помещения для хранения техники',
    value: reportRow => reportRow.cctv_storage_room_square
  },
  {
    label: 'Тип помещения для хранения техники',
    value: reportRow => convertMultiField(reportRow, 'cctv_storage_room_type')
  },
  {
    label: 'Помещение для хранения техники запирается',
    value: reportRow => convertBoolean(reportRow.cctv_is_equipment_storage_lockable)
  },
  {
    label: 'Место расположения ТВН подтверждено',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_location)
  },
  {
    label: 'Монитор видеонаблюдения в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_monitor)
  },
  {
    label: 'Локальные консоли видеонаблюдения в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_local_consoles)
  },
  {
    label: 'NVR видеонаблюдения в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_nvr)
  },
  {
    label: 'Коммутатор видеонаблюдения в наличии',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_network_switch)
  },
  {
    label: 'Камера 1 в наличии в месте УИК',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_ip_camera_1)
  },
  {
    label: 'Камера 2 в наличии в месте УИК',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_ip_camera_2)
  },
  {
    label: 'Возможность переноса существующих камер на место расположения УИК',
    value: reportRow => convertBoolean(reportRow.cctv_has_audit_cameras_moving_ability)
  },
  {
    label: 'Видеонаблюдение обеспечивает тайну голосования',
    value: reportRow => convertBoolean(reportRow.cctv_does_surveillance_ensure_vote_secrecy)
  },
  {
    label: 'Отсутствие посторонних предметов, перекрывающих обзор камеры',
    value: reportRow => convertBoolean(reportRow.cctv_has_absent_objects_blocking_view)
  },
  {
    label: 'Переносная урна видна (на схеме)',
    value: reportRow => convertBoolean(reportRow.cctv_is_portable_ballot_box_visible)
  },
  {
    label: 'Автоматизированное рабочее место УИК в камеру видно (на схеме)',
    value: reportRow => convertBoolean(reportRow.cctv_is_pec_workplace_visible)
  },
  {
    label: 'Попадает ли увеличенная форма протокола (на схеме)',
    value: reportRow => convertBoolean(reportRow.cctv_does_enlarged_form_in_view)
  },
  {
    label: 'Место установки ТД Wi-Fi позволяет равномерно покрыть всю площадь УИК',
    value: reportRow => convertBoolean(reportRow.cctv_does_ap_evenly_covers_entire_area)
  },
  {
    label: 'Существующие операторы связи на Объекте, Радио/ВОЛС',
    value: reportRow => reportRow.cctv_audit_existing_telecom_operators
  },
  {
    label: 'Место расположения оператора связи',
    value: reportRow => convertMultiField(reportRow, 'cctv_telecom_operator_room_type')
  },
  {
    label: 'Место размещение шкафа согласовано',
    value: reportRow => convertBoolean(reportRow.cctv_is_cabinet_location_approved)
  },
  {
    label: 'Трасса прокладки и точка подключения электропитания согласована',
    value: reportRow => convertBoolean(reportRow.cctv_are_power_route_and_connection_point_agreed)
  },
  {
    label: 'Уровень сигнала МТС',
    value: reportRow => reportRow.cctv_audit_signal_level_mts
  },
  {
    label: 'Технология МТС',
    value: reportRow => convertMultiField(reportRow, 'cctv_audit_technology_mts')
  },
  {
    label: 'Скорость вх МТС',
    value: reportRow => reportRow.cctv_audit_speed_tx_mts
  },
  {
    label: 'Скорость исх МТС',
    value: reportRow => reportRow.cctv_audit_speed_tx_mts
  },
  {
    label: 'Уровень сигнала Билайн',
    value: reportRow => reportRow.cctv_audit_signal_level_beeline
  },
  {
    label: 'Технология Билайн',
    value: reportRow => convertMultiField(reportRow, 'cctv_audit_technology_beeline')
  },
  {
    label: 'Скорость вх Билайн',
    value: reportRow => reportRow.cctv_audit_speed_tx_beeline
  },
  {
    label: 'Скорость исх Билайн',
    value: reportRow => reportRow.cctv_audit_speed_tx_beeline
  },
  {
    label: 'Уровень сигнала Мегафон',
    value: reportRow => reportRow.cctv_audit_signal_level_megafon
  },
  {
    label: 'Технология Мегафон',
    value: reportRow => convertMultiField(reportRow, 'cctv_audit_technology_megafon')
  },
  {
    label: 'Скорость вх Мегафон',
    value: reportRow => reportRow.cctv_audit_speed_tx_megafon
  },
  {
    label: 'Скорость исх Мегафон',
    value: reportRow => reportRow.cctv_audit_speed_tx_megafon
  },
  {
    label: 'Уровень сигнала Теле2',
    value: reportRow => reportRow.cctv_audit_signal_level_tele2
  },
  {
    label: 'Технология Теле2',
    value: reportRow => convertMultiField(reportRow, 'cctv_audit_technology_tele2')
  },
  {
    label: 'Скорость вх Теле2',
    value: reportRow => reportRow.cctv_audit_speed_tx_tele2
  },
  {
    label: 'Скорость исх Теле2',
    value: reportRow => reportRow.cctv_audit_speed_tx_tele2
  },
  {
    label: 'Проверку проводил',
    value: reportRow => reportRow.cctv_audit_conducted_by
  },
  {
    label: 'Дата проверки',
    value: reportRow => formatDate(reportRow.cctv_audit_date ? new Date(reportRow.cctv_audit_date) : null)
  },
  {
    label: 'Должность, ФИО Бригадира района',
    value: reportRow => reportRow.cctv_audit_district_foreman_credentials
  },
  {
    label: 'Дополнительные комментарии',
    value: reportRow => reportRow.cctv_audit_additional_comments
  },
]

export const getCctvAuditTableColumns = (reportRow?: IReportSAC) => {
  switch (reportRow?.project_name) {
    case 'ЕДГ 2023':
      return cctvAuditReportEdg2022TableColumns
    default:
      return cctvAuditReportTableColumns
  }
}


type MultiFieldPrefix<T> = T extends `${infer R}_description` ? R : never;

const convertMultiField = <T extends (MultiFieldPrefix<keyof IReportSAC>)>(
  reportRow: IReportSAC,
  prefix: T,
): ReactNode => {
  switch (reportRow[`${prefix}_code`]) {
    case 'parlor':
      return `${reportRow[`${prefix}_name`]} ${reportRow[`${prefix}_description`] || ''}`
    case 'other':
      return reportRow[`${prefix}_description`]
    default:
      return reportRow[`${prefix}_name`]
  }
}

const convertBoolean = (item: boolean) => (item ? 'Да' : 'Нет')

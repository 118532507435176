import { ApiResourceFilter, TableSampler } from '@/context/types'


/** Преобразование сэмплера (словарья с множествами) в словарь URL params */
const samplerToParams = (sampler: TableSampler) => {
	const params: ApiResourceFilter = {}
	for (const [name, set] of Object.entries(sampler)) {
		const valueList = Array.from(set).filter(item => item !== null) as string[]
		if (valueList.length === 1)
			params[name] = valueList[0]
		else if (valueList.length > 1)
			params[name] = valueList
		if (set.has(null)) {
			const paramName = name.replace(/((\.code)|(\.id))$/, '')
			params[`exists[${paramName}]`] = 'false'
		}
	}
	return params
}

export default samplerToParams

import { useEffect, useMemo } from 'react'
import { routes } from '@/config/routes'
import { useTabsContext } from '@/context/TabsContext'
import useProjectId from '@/hooks/useProjectId'
import TabsList, { DefaultTab } from '@/components/common/tabs/TabsList'
import { faTable } from '@fortawesome/free-solid-svg-icons'


const AuditProjectNav = () => {
  const projectId = useProjectId()
  const { tabList, closeTab } = useTabsContext()

  const projectPath = routes.auditProject(projectId)

  const defaultTabs: DefaultTab[] = useMemo(() => [
    {
      label: 'УИКи',
      icon: faTable,
      path: projectPath,
      end: true,
    },
  ], [projectPath])

  useEffect(() => {
    tabList
      .filter(t => !t.path.includes(projectPath))
      .forEach(t => closeTab(t.path, projectPath))
  }, [projectPath])

  return <TabsList defaultTabs={defaultTabs} redirect={projectPath} />
}

export default AuditProjectNav

import { ChangeEvent, useCallback, useId, useState } from 'react'
import { useAnyFormContext } from '@/context/anyForm/AnyFormContext'
import InputGroup from '@/components/common/form/InputGroup'


type Props = {
  label: string
  name?: string
  initValue?: string
  placeholder?: string
  required?: boolean
  helpText?: string
  disabled?: boolean
  invalidMessage?: string
}


export default function AnyTextInput({
  label,
  name,
  initValue,
  placeholder,
  required,
  helpText,
  disabled,
  invalidMessage
}: Props) {
  const { setField } = useAnyFormContext()
  const [value, setValue] = useState<string>(initValue ?? '')
  const id = useId()

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (name) setField(name, event.target.value)
  }, [name, setField])

  return (
    <InputGroup
      id={id}
      label={label}
      required={required}
      helpText={helpText}
      invalidMessage={invalidMessage}>
      <input
        className={`form-control ${invalidMessage && 'is-invalid'}`}
        type='text'
        id={id}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </InputGroup>
  )
}

import { IChannelField, IProjectField } from '@/context/types'
import useIsTableColDisplayed from '@/hooks/useIsTableColDisplayed'
import { Props as TableHeaderColsSelectProps } from '@/components/modals/TableColsSelect'
import { useMemo } from 'react'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'


const useTableDisplayedCols = <TColumnName extends string, TField extends IChannelField | IProjectField>(
  tableName: TableHeaderColsSelectProps<any, TColumnName>['userTableHiddenColsKey'],
  availableCols: ReturnType<typeof useTableAvailableCols<any, TColumnName>>,
  customFields?: TField[],
) => {
  const isColDisplayed = useIsTableColDisplayed(tableName)

  const displayedColumnNames = useMemo(() => (
    availableCols.filter(colName => isColDisplayed(colName))
  ), [availableCols, isColDisplayed])

  const displayedCustomFields = useMemo(() => (
    customFields?.filter(pf => isColDisplayed(pf.id)) ?? []
  ), [customFields, isColDisplayed])

  return { displayedColumnNames, displayedCustomFields }
}

export default useTableDisplayedCols

import { createContext, PropsWithChildren, useCallback, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  errorsFieldChangedAction, formFieldValueChangedAction, fromChangedAction, useFormStoreReducer,
} from '@/context/form/formStore'


/** Generic form context value interface */
interface FormContextValue<TForm> {
	form: TForm
	errors: Record<keyof TForm, string>
	setState: (state: TForm) => void
	setField: (name: keyof TForm, value: any) => void
	setError: (name: keyof TForm, value?: string) => void
}


/** Generic form context */
const FormContext = createContext({} as FormContextValue<any>)


/** Generic form context hook */
export const useFormContext = <TFrom,>() => useContext<FormContextValue<TFrom>>(FormContext)


type Props = PropsWithChildren<{}>

/** Generic form context provider */
export function FormContextProvider<TFrom>({ children }: Props) {
	const [state, dispatch] = useFormStoreReducer<TFrom>()

	/** Задать содержимое всей формы */
	const setState = useCallback((state: TFrom) => {
		dispatch(fromChangedAction<TFrom>(state))
	}, [dispatch])

	/** Задать значение поля формы */
	const setField = useCallback((name: keyof TFrom, value: any) => {
		dispatch(formFieldValueChangedAction<TFrom>(name, value))
	}, [dispatch])

	/** Задать сообщение об ошибке в поле формы */
	const setError = useCallback((name: keyof TFrom, value?: string) => {
		dispatch(errorsFieldChangedAction<TFrom>(name, value))
	}, [dispatch])

	const value: FormContextValue<TFrom> = {
		...state,
		setState,
		setField,
		setError,
	}

	useStorageStateLog('form', state)

	return (
		<FormContext.Provider value={value}>
			{children ?? <Outlet />}
		</FormContext.Provider>
	)
}
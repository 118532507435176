import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import CatalogFilesModal from '@/components/modals/catalogs/CatalogFilesModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IHardwarePreset } from '@/context/types'
import { useModal } from '@/context/ModalContext'


type Props = {
  hardwarePreset: IHardwarePreset
}

const HardwareCardPreset = ({ hardwarePreset }: Props) => {
  const { showModalDialog } = useModal()

  const showFilesModal = () => showModalDialog({
    type: 'ok',
    content: <CatalogFilesModal record={hardwarePreset} />,
    header: 'Прикреплённые файлы',
  })

  if (!hardwarePreset.files.length)
    return <>{hardwarePreset.name}</>

  return (
    <>
      {hardwarePreset.name}
      <button
        className='btn btn-link p-0 border-0'
        onClick={() => showFilesModal()}
      >
        <FontAwesomeIcon icon={faPaperclip} />
      </button>
    </>
  )
}

export default HardwareCardPreset

import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IProjectForm } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { routes } from '@/config/routes'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { FormContextProvider } from '@/context/form/FormContext'
import { useProjectListContext } from '@/context/tables/ProjectListContext'
import { useProjectContext } from '@/context/project/ProjectContext'
import Alert from '@/components/common/Alert'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import Loader from '@/components/common/Loader'
import Main from '@/components/common/Main'
import ProjectBreadcrumbsItem from '@/components/breadcrumbs/ProjectBreadcrumbsItem'
import ProjectForm from '@/components/ProjectsContent/ProjectForm'

type Props = {
  isAudit?: boolean
}

/** Контент страницы редактирования проекта */
export default function EditProjectPageContent({ isAudit = false }: Props) {
  const navigate = useNavigate()
  const { loadRecordList: loadProjectList } = useProjectListContext()
  const { project, updateProject } = useProjectContext()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  useDocumentTitle(project && `Редактировать проект «${project.name}»`)

  const handleSubmit = useCallback(async (projectFrom: IProjectForm) => {
    setIsLoading(true)
    try {
      await updateProject(projectFrom)
      await loadProjectList()
      navigate(isAudit ? routes.auditProjects : routes.projects)
    } catch (e: any) {
      setError(await getErrorMessage(e))
    }
    setIsLoading(false)
  }, [updateProject, loadProjectList, navigate])

  return (
    <>
      {!isAudit &&
        <BreadcrumbsContainer>
          <BreadcrumbsItem path={routes.auditProjects}>Проекты</BreadcrumbsItem>
          <ProjectBreadcrumbsItem />
          <BreadcrumbsItem>Редактировать проект</BreadcrumbsItem>
        </BreadcrumbsContainer>
      }
      <Main>
        <h1>Редактировать проект</h1>
        {error && <Alert>{error}</Alert>}
        {!project ? <Loader /> :
          <FormContextProvider>
            <ProjectForm
              instance={project}
              disabled={isLoading}
              onSubmit={handleSubmit}
              cancelRoute={isAudit ? routes.auditProjects : routes.project(project.id)}
              isAudit={isAudit}
            />
          </FormContextProvider>}
      </Main>
    </>
  )
}
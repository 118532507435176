import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'
import { IHistoryStatusTypeOutput, IService } from '@/context/types'
import { serviceStatusLabels } from '@/config/status/labels'
import { getBgColorClassName } from '@/utils/color'
import { typedObjectKeys } from '@/utils/object'


type Props = {
  service: IService
}

export default function HistoryStatusService({ service }: Props) {
  let stage: IHistoryStatusTypeOutput
  let idAcceptanceStatus: IHistoryStatusTypeOutput
  const { channelStage, ...statusLabels } = serviceStatusLabels

  const outputData = service.logStatusHistoryEntries.map(item => {
    stage = item.stage
      ? {
        ...item.stage,
        ...{ createdBy: item.actor?.name, createdDatetime: item.createdDatetimeStr },
      }
      : stage
    idAcceptanceStatus = item.idAcceptanceStatus
      ? {
        ...item.idAcceptanceStatus,
        ...{ createdBy: item.actor?.name, createdDatetime: item.createdDatetimeStr },
      }
      : idAcceptanceStatus
    return {
      ...item,
      ...{
        stage,
        idAcceptanceStatus
      },
    }
  })
  return (
    <div className='card border-0 mb-2'>
      <div
        className={`card-header border-0 px-2 py-1 justify-content-between ${cn(
          style.cardHeader,
          style.cardHeaderHistory,
        )} `}
      >
        <div>
          <button
            className={`btn text-primary p-0 ${style.cardHeaderArrow}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseHistoryStatus'
            aria-expanded='false'
            aria-controls='collapseHistoryStatus'
          >
            <b>История статусов услуги</b>
          </button>
        </div>
      </div>
      <div className='collapse' id='collapseHistoryStatus'>
        <div className={`card-body border-0 ${style.cctvInfoCollapse} p-0`}>
          <table className={`table-hover table-bordered table-sm mb-0 ${style.table}`}>
            <thead className={style.tHead}>
              <tr className='text-muted border-bottom'>
                {Object.values(statusLabels).map(label =>
                  <th key={label} style={{fontWeight: 'normal', width: `1%`}}>
                    {label}
                  </th>
                )}
              </tr>
            </thead>
            <tbody className='align-top'>
              {outputData.map(statusHistoryEntry => (
                <tr key={statusHistoryEntry.id}>
                  {typedObjectKeys(statusLabels).map(key =>
                    <td key={key} className={getBgColorClassName(statusHistoryEntry[key].code)}>
                      <p className='m-0'>{statusHistoryEntry[key].name}</p>
                      <p className='m-0'>{statusHistoryEntry[key].createdDatetime}</p>
                      <p className='m-0'>{statusHistoryEntry[key].createdBy}</p>
                    </td>,
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const getItemLayout = (status: IHistoryStatusTypeOutput) => {
  return (
    <td className={getBgColorClassName(status.code)}>
      <p className='m-0'>{status.name}</p>
      <p className='m-0'>{status.createdDatetime}</p>
      <p className='m-0'>{status.createdBy}</p>
    </td>
  )
}

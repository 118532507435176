import { MutableRefObject, useEffect, useRef } from 'react'


type RefValue = Function[]
export type FunctionsArrayRef = MutableRefObject<RefValue> & { callAll: () => void }

const useFunctionsArrayRef = () => {
  const ref = useRef([] as RefValue) as FunctionsArrayRef

  ref.callAll = () => {
    ref.current.forEach(f => f())
  }

  return ref
}

export const useFunctionsArrayRefPush = (ref: FunctionsArrayRef, func: Function) => {
  useEffect(() => {
    ref.current.push(func)

    return () => {
      ref.current = ref.current.filter(f => f !== func)
    }
  }, [ref.current, func])
}

export default useFunctionsArrayRef

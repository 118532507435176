import { ApiResource } from '@/context/types'


/** Коды типов оборудования */
export enum HardwareTypeCode {
	VideoNetworkSwitch = 'video_network_switch',
	CommunicationNetworkSwitch = 'communication_network_switch',
	Router = 'router',
	IpCamera = 'ip_camera',
	VideoServer = 'video_server',
	Ups = 'ups',
	AccessPoint = 'access_point',
}

/** Ресурс API оборудования по типу  */
export const hardwareApiResources: Record<HardwareTypeCode, ApiResource> = {
	[HardwareTypeCode.VideoNetworkSwitch]: ApiResource.NetworkSwitch,
	[HardwareTypeCode.CommunicationNetworkSwitch]: ApiResource.NetworkSwitch,
	[HardwareTypeCode.Router]: ApiResource.Router,
	[HardwareTypeCode.IpCamera]: ApiResource.IpCamera,
	[HardwareTypeCode.VideoServer]: ApiResource.VideoServer,
	[HardwareTypeCode.Ups]: ApiResource.Ups,
	[HardwareTypeCode.AccessPoint]: ApiResource.AccessPoint,
}

export const cctvHardwareKeys = [
 'videoNetworkSwitches',
 'ipCameras',
 'videoServers',
 'communicationNetworkSwitches',
 'upses',
 'accessPoints',
] as const

export type CctvHardwareKey = typeof cctvHardwareKeys[number]

export const cctvHardwareLabels = {
  videoNetworkSwitches: 'Коммутаторы видео',
  ipCameras: 'IP-камеры',
  videoServers: 'Видеосерверы',
  communicationNetworkSwitches: 'Коммутаторы связи',
  upses: 'ИБП',
  accessPoints: 'Точки доступа',
} as const satisfies Record<CctvHardwareKey, string>

import { useRef, useState } from 'react'
import { instanceAxios as axios } from '@/api/instanceAxios'
import useEffectOnce from '@/hooks/useEffectOnce'
import { useModal } from '@/context/ModalContext'
import ProgressBar from '@/components/common/ProgressBar'
import getErrorMessage from '@/utils/getErrorMessage'


type Props = {
  recordApiRoute: (id: string) => string
  recordIdSet: Set<string> //todo-sem объединить с UpdateWorkStatusesModalContent
  onClose: () => void
}


export default function UpdateVideoMarkerModalContent({
  recordApiRoute,
  recordIdSet,
  onClose,
}: Props) {
  const { updateState, usePrimaryCallback } = useModal()
  const [responseCount, setResponseCount] = useState(0)
  const [updatedCount, setUpdatedCount] = useState(0)
  const [errors, setErrors] = useState([] as string[])
  const [isDone, setIsDone] = useState(false)
  const abortFlagRef = useRef(false)

  useEffectOnce(() => {
    (async () => {
      for (const id of Array.from(recordIdSet)) {
        if (abortFlagRef.current) break
        try {
          await axios.post(
            recordApiRoute(id),
            { headers: { 'Content-Type': 'application/merge-patch+json' } },
          )
          setUpdatedCount(prev => prev + 1)
        } catch (e: any) {
          const error = await getErrorMessage(e)
          setErrors(errors => [...errors, error])
        } finally {
          setResponseCount(prev => prev + 1)
        }
      }
      setIsDone(true)
      updateState({ type: 'ok', dismissable: true })
    })()
  })

  usePrimaryCallback(() => {
    abortFlagRef.current = true
    
    onClose()
  }, [])

  return (
    <>
      <p>Обработано {responseCount} из {recordIdSet.size} записей.</p>
      <ProgressBar now={responseCount} max={recordIdSet.size} isAnimated={!isDone} />
      <p className='mt-3 mb-0'>Видеомаркер обновлен в {updatedCount} записей.</p>
      {!!errors.length &&
        <>
          <div>Ошибки:</div>
          {errors.map((error, i) => <div key={i}>{error}</div>)}
        </>
      }
    </>
  )
}
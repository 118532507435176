export default async function parseResponse(response: Response, errorMessage: string, initValue: any = {}) {
	var result
	try {
		result = await response.json()
	} catch {
		result = initValue
	}
	if (result.error) throw result.error
	if (result.message) throw new Error(result.message) // TODO: Удалить после обновления эндпоинта аутентификации
	if (response.status >= 500) throw new Error(`Ошибка на сервере. ${errorMessage}.`)
	if (response.status === 401) throw new Error(`Ошибка аутентификации. ${errorMessage}`)
	if (response.status > 201) throw new Error(`Ошибка в запросе. ${errorMessage}.`)
	return result
}
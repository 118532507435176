import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBriefcase, faCog, faRss, faSignOut, faStream, faUser } from '@fortawesome/free-solid-svg-icons'
import { routes } from '@/config/routes'
import { useAuthContext } from '@/context/auth/AuthContext'
import NavContainer from '@/components/nav/common/NavContainer'
import NavItem from '@/components/nav/common/NavItem'
import PermissionRequired from '@/components/auth/PermissionRequired'


/** Главное меню пользователя */
export default function UserNav() {
  const { user, signOut } = useAuthContext()

  const onSignOutSubmit = useCallback(() => {
    signOut()
  }, [signOut])

  return (
    <NavContainer>
      <ul className='navbar-nav w-100'>
        <PermissionRequired permission={'projectRead'}>
          <NavItem label='Проекты' to={routes.projects} icon={faBriefcase} />
        </PermissionRequired>
        <PermissionRequired permission={'communicationRead'}>
          <NavItem label='Связь' to={routes.communication} icon={faRss} />
        </PermissionRequired>
        <PermissionRequired permission={'channelRead'}>
          <NavItem label='Каналы' to={routes.channels} icon={faStream} />
        </PermissionRequired>
        <PermissionRequired permission={'exploitationRead'}>
          <NavItem label='Эксплуатация' labelSm='Экспл.' to={routes.exploitation} icon={faBriefcase} />
        </PermissionRequired>
        <PermissionRequired permission={'auditRead'}>
          <NavItem label='Сопровождение' labelSm='Сопр.' to={routes.auditProjects} icon={faBriefcase} />
        </PermissionRequired>
        <PermissionRequired permission={'catalogRead'}>
          <NavItem label='Справочники' labelSm='Справоч.' to={routes.catalogs} icon={faBook} />
        </PermissionRequired>
        {user &&
          <li className='nav-item dropdown ms-sm-auto'>
            <button
              className='btn nav-link dropdown-toggle'
              type='button'
              id='userMenu'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              <FontAwesomeIcon icon={faUser} className='me-2' />
              <span className='d-sm-none d-lg-inline'>
                {user.firstName && user.lastName
                  ? `${user.firstName[0]}. ${user.lastName}`
                  : user.login ?? 'Пользователь'}
              </span>
            </button>
            <ul className='dropdown-menu dropdown-menu-end' aria-labelledby='userMenu'>
              <li>
                <span className='dropdown-item-text'>
                  <b>{user.login}</b>
                  {user.userRoles.map(role => 
                    <small key={role.code} className='d-block'>{role.name}</small>)
                  }
                </span>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>
              <li>
                <NavLink className='dropdown-item disabled' to={routes.profile}>
                  <FontAwesomeIcon icon={faCog} className='me-2' />
                  Профиль
                </NavLink>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>
              <li>
                <button className='dropdown-item' onClick={onSignOutSubmit}>
                  <FontAwesomeIcon icon={faSignOut} className='me-2' />
                  Выход
                </button>
              </li>
            </ul>
          </li>}
      </ul>
    </NavContainer>
  )
}

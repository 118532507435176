import { useAuthContext } from '@/context/auth/AuthContext'
import { IRecordWithId, TableColumnConfig } from '@/context/types'


const useTableAvailableCols = <TRecord extends IRecordWithId, TColumnName extends string>(
  columnNameList: readonly TColumnName[],
  columns: Record<TColumnName, TableColumnConfig<TRecord>>,
) => {
  const { hasPermission } = useAuthContext()

  return columnNameList.filter(colName => {
    const column = columns[colName]
    return !column.hidden && (!column.permissions || hasPermission(column.permissions))
  })
}

export default useTableAvailableCols

import { useCallback, useRef } from 'react'


const usePrevValueCheck = <T>(initial: T) => {
  const valRef = useRef(initial)

  return useCallback((newVal: T) => {
    const newValStr = JSON.stringify(newVal)

    if (newValStr === JSON.stringify(valRef.current))
      return false

    valRef.current = JSON.parse(newValStr) as T

    return true
  }, [valRef])
}

export default usePrevValueCheck

import { useEffect } from 'react'
import { matchPath, NavLinkProps, useLocation } from 'react-router-dom'
import { Tab, useTabsContext } from '@/context/TabsContext'
import { HasPermissionParam, useAuthContext } from '@/context/auth/AuthContext'
import TabItem from '@/components/common/tabs/TabsItem'
import { faEye } from '@fortawesome/free-solid-svg-icons'


export type DefaultTab = Pick<Tab, 'label' | 'icon' | 'path'> & {
  permissions?: HasPermissionParam
  end?: NavLinkProps['end']
}

type Props = {
  defaultTabs: DefaultTab[]
  redirect: Parameters<ReturnType<typeof useTabsContext>['closeTab']>[1]
}

const TabsList = ({ defaultTabs, redirect }: Props) => {
  const { pathname } = useLocation()
  const { tabList, openTab, closeTab } = useTabsContext()
  const { hasPermission } = useAuthContext()

  useEffect(() => {
    if (
      !defaultTabs.some(t => matchPath({
        path: t.path,
        end: !!t.end,
      }, pathname))
      && !tabList.some(t => matchPath(t.path, pathname))
    )
      openTab({ path: pathname })
  }, [defaultTabs, tabList, pathname])

  return (
    <div className='w-100' style={{ height: '34px' }}>
      <div className='w-100 bg-light' style={{ position: 'fixed', height: '34px', zIndex: 100 }}>
        <ul className='nav nav-tabs px-2'>
          {defaultTabs
            .filter(tab => !tab.permissions || hasPermission(tab.permissions))
            .map(tab =>
              <TabItem key={tab.path} tab={tab} end={tab.end} />,
            )}
          {tabList.map(tab =>
            <TabItem
              key={tab.path}
              tab={tab}
              defaultIcon={faEye}
              onClose={() => closeTab(tab.path, redirect)}
              end
            />,
          )}
        </ul>
      </div>
    </div>
  )
}

export default TabsList

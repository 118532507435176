import { Dispatch, Reducer, useReducer } from 'react'


/** Generic form store interface */
export type FormState<TForm> = {
	form: TForm
	errors: Record<keyof TForm, string>
}

/** Generic form store actions */
type FormAction<TForm> =
	| { type: 'formChanged', payload: TForm }
	| { type: 'formFieldChanged', payload: FormFieldChangedActionPayload<TForm> }
	| { type: 'errorsFieldChanged', payload: FormFieldChangedActionPayload<TForm> }

type FormFieldChangedActionPayload<TFrom> = {
	name: keyof TFrom
	value: string | number | null | undefined
}


/** Generic form store initial state getter */
const formInitState: FormState<any> = {
	form: {},
	errors: {}
}


/** Generic form store reducer */
function formStoreReducer<TForm>(state: FormState<TForm>, { type, payload }: FormAction<TForm>): FormState<TForm> {
	switch (type) {
		case 'formChanged':
			return { ...state, form: { ...payload } }
		case 'formFieldChanged':
			return {
				...state,
				form: { ...state.form, [payload.name]: payload.value },
				errors: { ...state.errors, [payload.name]: undefined }
			}
		case 'errorsFieldChanged':
			return {
				...state,
				errors: { ...state.errors, [payload.name]: payload.value }
			}
		default:
			return state
	}
}


/** Generic form store state & reducer hook */
export const useFormStoreReducer:
	<TForm>() => [FormState<TForm>, Dispatch<FormAction<TForm>>] =
	<TForm>() => useReducer<Reducer<FormState<TForm>, FormAction<TForm>>>
		(formStoreReducer, formInitState)


// Generic form store action creators

/** Generic form store changed action creator */
export const fromChangedAction =
	<TForm>(form: TForm): FormAction<TForm> =>
		({ type: 'formChanged', payload: form })
/** Generic form store field value changed action creator */
export const formFieldValueChangedAction =
	<TForm>(name: keyof TForm, value: string | number | null | undefined): FormAction<TForm> =>
		({ type: 'formFieldChanged', payload: { name, value } })
/** Generic form store field error changed action creator */
export const errorsFieldChangedAction =
	<TForm>(name: keyof TForm, value?: string): FormAction<TForm> =>
		({ type: 'errorsFieldChanged', payload: { name, value } })
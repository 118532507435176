import FolderCard from '@/components/common/cards/FolderCard'
import style from '@/assets/styles/card.module.scss'
import { useCallback, useState } from 'react'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { faCaretDown, faCaretUp, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuthContext } from '@/context/auth/AuthContext'
import { IChannelAddress } from '@/context/types'
import ParamTable from '@/components/common/cards/ParamTable'
import ParamTableRow from '@/components/common/cards/ParamTableRow'
import { ChannelFieldListContextProvider } from '@/context/entityFields/ChannelFieldListContext'
import { FormContextProvider } from '@/context/form/FormContext'
import { AnyFormContextProvider } from '@/context/anyForm/AnyFormContext'
import { useModal } from '@/context/ModalContext'
import AddressEditForm from '@/components/modals/address/AddressEditForm'


type Props = {
  address: IChannelAddress,
  reloadCard: any
}

const AddressInfoCard = ({ address, reloadCard }: Props) => {
  const [isExpand, setIsExpand] = useState(false)
  const { showModalDialog } = useModal()
  const { hasPermission } = useAuthContext()

  const handleEditAddress = useCallback(() => {
    if (!address.id)
      return

    showModalDialog({
      type: 'saveCancel',
      dismissable: false,
      header: `Редактирование информации адреса - ${address.name}`,
      size: 'lg',
      content:
        <ChannelFieldListContextProvider>
          <FormContextProvider>
            <AnyFormContextProvider>
              <AddressEditForm onSave={reloadCard} address={address} />
            </AnyFormContextProvider>
          </FormContextProvider>
        </ChannelFieldListContextProvider>,
    })
  }, [reloadCard, address, showModalDialog])

  const handleExpand = useCallback(() => {
    setIsExpand(prev => !prev)
  }, [])

  return (
    <div>
      <FolderCard
        headerBgColor='#FFA873'
        bodyBgColor='#EADEDB'
        textColor='blue'
        header={<button
          className='btn btn-link p-0 text-decoration-none fw-bold text-nowrap'
          onClick={handleExpand}>
          Информация по адресу - {address.name}
          <FontAwesomeIcon icon={isExpand ? faCaretUp : faCaretDown} className='ms-2' />
        </button>}
        rightToolbar={<>
          <SmallRoundBtn
            icon={faPenToSquare}
            tooltip='Редактировать сведения'
            disabled={!hasPermission('addressEdit')}
            onClick={handleEditAddress} />
        </>}
      >
        <div className={`${style.cctvInfoCollapse} ${isExpand && style.contentActive}`}>
          <ParamTable>
            <ParamTableRow label='Адрес' width='33%'>{address.name}</ParamTableRow>
            <ParamTableRow label='Округ' width='33%'>{address.district.area.name}</ParamTableRow>
            <ParamTableRow label='Район' width='33%'>{address.district.name}</ParamTableRow>
            <ParamTableRow label='ЕЦХД ID' width='33%'>{address.storageId}</ParamTableRow>
            <ParamTableRow label='Принадлежность здания' width='33%'>{address.belonging}</ParamTableRow>
            <ParamTableRow label='Комментарии к адресу' width='33%'>{address.comment}</ParamTableRow>
          </ParamTable>
        </div>
      </FolderCard>
    </div>
  )
}

export default AddressInfoCard

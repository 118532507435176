import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{}>

export default function NavContainer({ children }: Props) {
  return (
    <div style={{ width: '100%', height: '56px' }}>
      <nav
        className='navbar navbar-dark bg-primary navbar-expand-sm '
        style={{ top: '0', position: 'fixed', width: '100%', zIndex: 400 }}
      >
        <div className='container-fluid'>
          <span className='navbar-brand'>СМО</span>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#nav-bar'
            aria-controls='nav-bar'
            aria-expanded='false'
            aria-label='Развернуть'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='nav-bar'>
            {children}
          </div>
        </div>
      </nav>
    </div>
  )
}

import { faInfoCircle, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import { useModal } from '@/context/ModalContext'
import { channelSearchInfoModal, serviceSearchInfoModal } from '@/components/modals/common/info'


type Props = {
  showServiceTableAdditionalButtons?: boolean
  handleSearchStorageId?: (searchString: string) => Promise<void>
  handleSearchAddressName?: (searchString: string) => Promise<void>
  handleSearchConsumerName?: (searchString: string) => Promise<void>
  handleSearchDepartmentName?: (searchString: string) => Promise<void>
  handleSearchChannelNumber?: (searchString: string) => Promise<void> | undefined
  handleSearchServiceNumber?: (searchString: string) => Promise<void> | undefined

  isLoading?: boolean
}

export default function CommunicationToolbar({
  showServiceTableAdditionalButtons,
  handleSearchStorageId,
  handleSearchAddressName,
  handleSearchConsumerName,
  handleSearchDepartmentName,
  handleSearchChannelNumber,
  handleSearchServiceNumber,
  isLoading
}: Props) {

  const handleSearch = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }, [])
  const [searchString, setSearchString] = useState('')
  const { showModalDialog } = useModal()

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(showServiceTableAdditionalButtons ? serviceSearchInfoModal() : channelSearchInfoModal())
  }, [showModalDialog, showServiceTableAdditionalButtons])

  const handleSearchStringChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }, [])

  /** Поиск по ChannelNumber */
  const handleSearchChannel = useCallback(async () => {
    const clearSearchString = searchString.trim()
    handleSearchChannelNumber && await handleSearchChannelNumber(clearSearchString)
  }, [searchString, showModalDialog])
  
  const handleSearchService = useCallback(async () => {
    const clearSearchString = searchString.trim()
    handleSearchServiceNumber && await handleSearchServiceNumber(clearSearchString)
  }, [searchString, showModalDialog])

  /** Поиск по ЕЦХД */
  const handleSearchStorage = useCallback(async () => {
    const clearSearchString = searchString.trim()
    handleSearchStorageId && await handleSearchStorageId(clearSearchString)

  }, [searchString, showModalDialog])

  /** Поиск по Address */
  const handleSearchAddress = useCallback(async () => {
    const clearSearchString = searchString.trim()
    handleSearchAddressName && await handleSearchAddressName(clearSearchString)
  }, [searchString, showModalDialog])

  /** Поиск по Department */
  const handleSearchDepartment = useCallback(async () => {
    const clearSearchString = searchString.trim()
    handleSearchDepartmentName && await handleSearchDepartmentName(clearSearchString)
  }, [searchString, showModalDialog])

  /** Поиск по Consumer */
  const handleSearchConsumer = useCallback(async () => {
    const clearSearchString = searchString.trim()
    handleSearchConsumerName && await handleSearchConsumerName(clearSearchString)
  }, [searchString, showModalDialog])

  return (
    <div style={{ width: '100%', height: 46 }}>
      <div
        className='container-fluid bg-light d-flex justify-content-center'
        style={{ position: 'fixed', width: '100%', zIndex: 200, height: 46 }}
      >
        <div className='btn-toolbar flex-nowrap gap-2 my-2'>
          {(
              handleSearchStorageId
              || handleSearchAddressName
              || handleSearchConsumerName
              || handleSearchDepartmentName
              || handleSearchChannelNumber
            ) &&
            <form onSubmit={handleSearch}>
              <div className='input-group input-group-sm flex-nowrap'>
                <button
                  className='btn btn-link p-0 ms-auto'
                  onClick={handleShowFilterInfo}
                  type='button'
                >
                  <FontAwesomeIcon icon={faInfoCircle} size='lg' className='mx-3' />
                </button>
                <span className='input-group-text'>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </span>
                <input
                  className='form-control'
                  type='text'
                  placeholder='Поиск...'
                  value={searchString}
                  onChange={handleSearchStringChanged}
                  enterKeyHint='search' />
                <button
                  className='btn btn-outline-primary text-nowrap'
                  type='button'
                  disabled={isLoading}
                  onClick={handleSearchChannel}
                >
                  УНК
                </button>
                {showServiceTableAdditionalButtons && <button
                  className='btn btn-outline-primary'
                  type='button'
                  disabled={isLoading}
                  onClick={handleSearchService}>
                  № услуги
                </button>}
                <button
                  className='btn btn-outline-primary'
                  type='button'
                  disabled={isLoading}
                  onClick={handleSearchStorage}>
                  ID ЕЦХД
                </button>
                <button
                  className='btn btn-outline-primary'
                  type='button'
                  disabled={isLoading}
                  onClick={handleSearchAddress}
                >
                  Адрес
                </button>
                {showServiceTableAdditionalButtons && <button
                  className='btn btn-outline-primary'
                  type='button'
                  disabled={isLoading}
                  onClick={handleSearchDepartment}>
                  Департамент
                </button>}
                {showServiceTableAdditionalButtons && <button
                  className='btn btn-outline-primary'
                  type='button'
                  disabled={isLoading}
                  onClick={handleSearchConsumer}>
                  Потребитель
                </button>}
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  )
}

import { useEffect, useRef } from 'react'


export default function useEventListener(eventName: string, callback: (event: Event) => void, element: any = window) {
	const callbackRef = useRef(callback)

	useEffect(() => {
		callbackRef.current = callback
	}, [callback])

	useEffect(() => {
		if (element == null) return
		const handler = (event: Event) => callbackRef.current(event)
		element.addEventListener(eventName, handler)
		return () => element.removeEventListener(eventName, handler)
	}, [eventName, element])
}
import { useEffect, useState } from 'react'
import FolderCard from '@/components/common/cards/FolderCard'
import DatePicker from 'react-datepicker'
import { IServiceWithBilling } from '@/context/types'
import { formatDate, formatPrice } from '@/utils/formatters'
import calendarIcon from '@/assets/Icons/calendarIcon.svg'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'


type Props = {
  service: IServiceWithBilling,
}

const fixDate = (date: Date) => new Date(+date - (date.getTimezoneOffset() * 60000)) //todo-sem

export default function BillingServices({ service }: Props) {
  const [selectedEndDate, setSelectedEndDate] = useState(fixDate(new Date))
  const [billingTotal, setBillingTotal] = useState({} as NonNullable<IServiceWithBilling['billing']>['total'])

  useEffect(() => {
    axios.get(apiRoutes.serviceBilling(service.id, selectedEndDate))
      .then(res => setBillingTotal(res.data))
  }, [+selectedEndDate])

  return (
    <FolderCard
      headerBgColor='#FFEFEF'
      bodyBgColor='#F1CDCD'
      header={`Биллинг услуги-${service.number}`}>
      <table className='table table-sm table-borderless table-hover w-100 mb-0'>
        <thead className='text-muted'>
          <tr className='border-bottom'>
            <th>Дата</th>
            <th>Доход</th>
            <th>Расход</th>
            <th>Окупаемость</th>
            <th>
              <div style={{
                display: 'flex',
                width: '180px',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <DatePicker
                  className='bg-transparent'
                  placeholderText='Введите дату'
                  selected={selectedEndDate}
                  onChange={date => setSelectedEndDate(fixDate(date || new Date))}
                />
                <img src={calendarIcon} alt='calendarIcon' style={{ marginLeft: '100px', position: 'absolute' }} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className='align-middle'>
          <tr>
            {service.serviceBeginDate && service.serviceBeginDate < selectedEndDate
              ? <>
                <td><p className='m-1'>{formatDate(selectedEndDate)}</p></td>
                <td><p className='m-0'>{formatPrice(billingTotal.revenue)}</p></td>
                <td><p className='m-0'>{formatPrice(billingTotal.expenses)}</p></td>
                <td><p className='m-0'>-</p></td>
              </>
              : <td colSpan={4}>Не указана дата подключения услуги</td>
            }
          </tr>
        </tbody>
      </table>
    </FolderCard>
  )
}

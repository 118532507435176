import { useState, useCallback } from 'react'
import { IIPCamera } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiURL } from '@/config/routes'
import { useModal } from '@/context/ModalContext'

type Props = {
  ipCamera: IIPCamera
  reloadCctv: () => Promise<void>
}

/** Форма редактирования инфо IP-камеры */
export default function ChangeCameraOrderModal({ ipCamera, reloadCctv }: Props) {
  const { showModalDialog } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  const fetchCameras = async () => {
    const url = `${apiURL}/cctvs/${ipCamera.cctvId}`;
    const response = await axios.get(url);
    return response.data.ipCameras;
  };

  const patchCameraOrder = async (cameraId: number, newOrderNumber: number) => {
    const url = `${apiURL}/hardware/ip-cameras/${cameraId}?context_cctv_id=${ipCamera.cctvId}`;
    await axios.patch(
      url,
      { orderNumberOnCctv: newOrderNumber },
      { headers: { 'Content-Type': 'application/merge-patch+json' } }
    );
  };

  const swapCameraNumbers = useCallback(async () => {
    setIsLoading(true);
    try {
      const cameras = await fetchCameras();

      if (cameras.length !== 2) {
        throw new Error('Должно быть ровно две камеры.');
      }

      const [camera1, camera2] = cameras;

      const [camera1Details, camera2Details] = await Promise.all([
        axios.get(`${apiURL}/hardware/ip-cameras/${camera1.id}?context_cctv_id=${ipCamera.cctvId}`),
        axios.get(`${apiURL}/hardware/ip-cameras/${camera2.id}?context_cctv_id=${ipCamera.cctvId}`)
      ]);

      const camera1Detail = camera1Details.data;
      const camera2Detail = camera2Details.data;

      const camera1Order = camera1Detail.orderNumberOnCctv;
      const camera2Order = camera2Detail.orderNumberOnCctv;

      await patchCameraOrder(camera1Detail.id, 3); 
      await patchCameraOrder(camera2Detail.id, camera1Order);
      await patchCameraOrder(camera1Detail.id, camera2Order);

      await reloadCctv();

      showModalDialog({
        type: 'ok',
        size: 'lg',
        header: 'Успешно',
        content: 'Номера камер успешно обновлены'
      });
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при обновлении номеров камер',
        content: await getErrorMessage(e),
      });
    } finally {
      setIsLoading(false);
    }
  }, [ipCamera.cctvId, reloadCctv, showModalDialog]);

  return (
    <>
      <div>
        <button className='btn btn-primary' onClick={swapCameraNumbers} disabled={isLoading}>
          <span>Подтвердить</span>
        </button>
      </div>
    </>
  )
}

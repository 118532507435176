import { PropsWithChildren } from 'react'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = PropsWithChildren<{}>

export default function Button({ children }: Props) {
	const { loading } = useAuthContext()

	return (
		<button
			type='submit'
			className='btn btn-primary'
			disabled={loading}
		>
			{children}{loading && '...' }
		</button>
	)
}
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import {
  ApiResource, IActFormationDeviations, IContract, IDepartment, IReportPeriod, ISelectOption,
} from '@/context/types'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { apiRoutes, getApiResourceListRoute, routes } from '@/config/routes'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CommunicationNav from '@/components/communication/CommunicationNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import ActFormationTableToolbar from '@/components/communication/ActFormationTableToolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faDownload, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { formatDate } from '@/utils/formatters'
import { downloadFile } from '@/utils/tools'
import { getMonthsDiff } from '@/utils/date'
import { convertReportPeriod } from '@/config/apiConverters'
import Select, { MultiValue } from 'react-select'
import { useModal } from '@/context/ModalContext'
import getErrorMessage from '@/utils/getErrorMessage'
import defaultRecordToOption, { defaultFilterOption } from '@/utils/recordToSelectOption'
import { createNumbersArray } from '@/utils/array'


type IDepartmentSelectOption = ISelectOption<IDepartment['id']>
type ReportPeriodId = IReportPeriod['id']

export default function ActFormationTableContent() {
  const { showModalDialog } = useModal()
  const [contractNumber, setContractNumber] = useState('' as IContract['number'])
  const [reportingPeriods, setReportingPeriods] = useState([] as IReportPeriod[])
  const [checkedReportingPeriodIdTable1, setCheckedReportingPeriodIdTable1] = useState('' as ReportPeriodId)
  const [checkedReportingPeriodIdTable2, setCheckedReportingPeriodIdTable2] = useState('' as ReportPeriodId)
  const [selectedReportingPeriodId, setSelectedReportingPeriodId] = useState('' as ReportPeriodId)
  const [selectedReportingPeriodMonths, setSelectedReportingPeriodMonths] = useState([] as number[])
  const [deviations, setDeviations] = useState([{ serviceNumber: '', months: [] }] as IActFormationDeviations[])
  const [departmentOptions, setDepartmentOptions] = useState([] as IDepartmentSelectOption[])
  const [selectedDepartments, setSelectedDepartments] = useState({} as Record<ReportPeriodId, MultiValue<IDepartmentSelectOption>>)

  useDocumentTitle('Формирование акта')

  useEffect(() => {
    if (!contractNumber)
      return

    axios.get(apiRoutes.contractReportingPeriods(contractNumber))
      .then(res => setReportingPeriods(res.data.map(convertReportPeriod)))

    axios.get<IDepartment[]>(
      getApiResourceListRoute(ApiResource.Department),
      { params: { pagination: false, groups: ['list_short:read'] } }
    )
      .then(res => setDepartmentOptions(res.data.map(defaultRecordToOption) as IDepartmentSelectOption[]))
  }, [contractNumber])

  const reportingPeriodSelectTableCols: { //todo-sem extract tables to component
    label: string
    value: (rp: IReportPeriod) => ReactNode
  }[] = [
    {
      label: 'Наименование ОП',
      value: rp => rp.name,
    },
    {
      label: 'Дата начала',
      value: rp => formatDate(rp.beginDate),
    },
    {
      label: 'Дата окончания',
      value: rp => formatDate(rp.endDate),
    },
  ]

  useEffect(() => {
    if (!selectedReportingPeriodId)
      return

    let { beginDate, endDate } = reportingPeriods.find(rp => rp.id === checkedReportingPeriodIdTable1)!

    if (beginDate > endDate)
      return

    const monthsCount = getMonthsDiff(beginDate, endDate) + 1

    setSelectedReportingPeriodMonths(createNumbersArray(monthsCount))

    setDeviations([{
      serviceNumber: '',
      months: new Array(monthsCount).fill('')
    }])
  }, [selectedReportingPeriodId])

  const generateAct = async () => {
    try {
      const res = await axios({
        url: apiRoutes.exportAct,
        method: 'POST',
        responseType: 'blob',
        data: {
          contractNumber,
          reportingPeriod: selectedReportingPeriodId,
          deviations
        },
      })

      const reportingPeriod = reportingPeriods.find(rp => rp.id === selectedReportingPeriodId)!
      const contract = reportingPeriod.contracts.find(c => c.number === contractNumber)!

      downloadFile(
        res.data,
        `Акт сдачи приёмки услуг ГК ${contractNumber} за ${reportingPeriod.number} отчетный период ${contract.shortName}`
          .replace(/[^\p{L}\p{N}]/ug, '_') + '.docx',
      )
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка формирования акта',
        content: await getErrorMessage(e)
      })
    }
  }

  const generateArchive = async (fileFormat: 1 | 2) => {
    try {
      const res = await axios({
        url: apiRoutes.exportActArchive,
        method: 'POST',
        responseType: 'blob',
        data: {
          fileFormat,
          contractNumber,
          reportingPeriod: checkedReportingPeriodIdTable2,
          departments: selectedDepartments[checkedReportingPeriodIdTable2]?.map(o => o.value)
        },
      })

      showModalDialog({
        type: 'ok',
        header: 'Выгрузка архива',
        content: 'Архив сформирован и отправлен на почту'
      })
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка формирования архива',
        content: await getErrorMessage(e)
      })
    }
  }

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem path={routes.communication}>Связь</BreadcrumbsItem>
        <BreadcrumbsItem>Формирование акта</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <CommunicationNav />
      <ActFormationTableToolbar onContractNumberChange={setContractNumber} />
      <div className='container-fluid'>
        <div className='d-flex justify-content-start'>
          <div>
            <table className='table table-sm table-bordered table-hover'>
              <thead className='table-primary' style={{ position: 'sticky', top: '177px' }}>
                <tr>
                  <th className='text-center'>Выберите ОП</th>
                  {reportingPeriodSelectTableCols.map(col =>
                    <th key={col.label} className='text-center'>{col.label}</th>,
                  )}
                </tr>
              </thead>
              <tbody>
                {!reportingPeriods.length
                  ? <EmptyTableRow columnCount={reportingPeriodSelectTableCols.length + 1}>
                    {contractNumber ? 'Записей нет' : 'введите № ГК'}
                  </EmptyTableRow>
                  : reportingPeriods.map(rp =>
                    <tr key={rp.id}>
                      <th className='text-center'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={checkedReportingPeriodIdTable1 === rp.id}
                          onChange={
                            (e: ChangeEvent<HTMLInputElement>) =>
                              setCheckedReportingPeriodIdTable1(e.target.checked ? rp.id : '')
                          }
                        />
                      </th>
                      {reportingPeriodSelectTableCols.map(col =>
                        <td key={col.label}>
                          <div style={{ overflow: 'hidden' }}>
                            {col.value?.(rp)}
                          </div>
                        </td>,
                      )}
                    </tr>,
                  )}
              </tbody>
            </table>
            <button
              className='btn btn-sm btn-outline-primary'
              disabled={!checkedReportingPeriodIdTable1}
              onClick={() => setSelectedReportingPeriodId(checkedReportingPeriodIdTable1)}
            >
              <FontAwesomeIcon icon={faArrowRight} /> Далее
            </button>
          </div>
          {!!selectedReportingPeriodId &&
            <>
              <div style={{ marginLeft: '50px' }}>
                <table className='table table-sm table-bordered table-hover'>
                  <thead className='table-primary' style={{ position: 'sticky', top: '177px' }}>
                    <tr>
                      <th className='text-center' rowSpan={2}>№ п/п</th>
                      <th className='text-center' rowSpan={2}>№ услуги</th>
                      <th className='text-center' colSpan={selectedReportingPeriodMonths.length}>
                        Время отклонения от заданных параметров
                      </th>
                      <th rowSpan={2} />
                    </tr>
                    <tr>
                      {selectedReportingPeriodMonths.map(monthNum =>
                        <th className='text-center' key={monthNum}>Месяц {monthNum}</th>,
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {deviations.map((deviation, i) =>
                      <tr key={i}>
                        <th className='text-center align-middle'>{i + 1}</th>
                        <td>
                          <input
                            value={deviation.serviceNumber}
                            onChange={
                              (e: ChangeEvent<HTMLInputElement>) =>
                                setDeviations(deviations => {
                                  deviations[i].serviceNumber = e.target.value
                                  return [...deviations]
                                })
                            }
                          />
                        </td>
                        {selectedReportingPeriodMonths.map(monthNum =>
                          <td key={monthNum}>
                            <input
                              value={deviation.months[monthNum - 1]}
                              type='number'
                              onChange={
                                (e: ChangeEvent<HTMLInputElement>) =>
                                  setDeviations(deviations => {
                                    deviations[i].months[monthNum - 1] = parseInt(e.target.value)
                                    return [...deviations]
                                  })
                              }
                            />
                          </td>,
                        )}
                        <td className='text-danger align-middle'>
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDeviations(deviations => deviations.filter(d => d !== deviation))}
                          />
                        </td>
                      </tr>,
                    )}
                  </tbody>
                </table>
                <button
                  className='btn btn-sm btn-outline-primary'
                  onClick={
                    () => setDeviations(rows => [
                      ...rows,
                      {
                        serviceNumber: '',
                        months: new Array(selectedReportingPeriodMonths.length).fill(''),
                      },
                    ])
                  }
                >
                  <FontAwesomeIcon icon={faPlus} /> Добавить
                </button>
              </div>
              <button
                className='btn btn-sm btn-outline-primary'
                style={{ marginLeft: '10px', height: '31px' }}
                onClick={generateAct}
              >
                <FontAwesomeIcon icon={faDownload} /> Сформировать акт сдачи приёмки
              </button>
            </>
          }
        </div>
      </div>
      <div className='container-fluid mt-3'>
        <div className='d-flex justify-content-start'>
          <div>
            <table className='table table-sm table-bordered table-hover'>
              <thead className='table-primary' style={{ position: 'sticky', top: '177px' }}>
                <tr>
                  <th className='text-center'>Выберите ОП</th>
                  {reportingPeriodSelectTableCols.map(col =>
                    <th key={col.label} className='text-center'>{col.label}</th>,
                  )}
                  <th>Департамент</th>
                </tr>
              </thead>
              <tbody>
                {!reportingPeriods.length
                  ? <EmptyTableRow columnCount={reportingPeriodSelectTableCols.length + 2}>
                    {contractNumber ? 'Записей нет' : 'введите № ГК'}
                  </EmptyTableRow>
                  : reportingPeriods.map(rp =>
                    <tr key={rp.id}>
                      <th className='text-center'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={checkedReportingPeriodIdTable2 === rp.id}
                          onChange={
                            (e: ChangeEvent<HTMLInputElement>) =>
                              setCheckedReportingPeriodIdTable2(e.target.checked ? rp.id : '')
                          }
                        />
                      </th>
                      {reportingPeriodSelectTableCols.map(col =>
                        <td key={col.label}>
                          <div style={{ overflow: 'hidden' }}>
                            {col.value?.(rp)}
                          </div>
                        </td>,
                      )}
                      <td style={{ width: '850px' }}>
                        <Select<IDepartmentSelectOption, true>
                          value={selectedDepartments[rp.id]}
                          isMulti={true}
                          placeholder={'Выберите Департамент'}
                          options={departmentOptions}
                          filterOption={defaultFilterOption}
                          noOptionsMessage={() => 'Ничего не найдено'}
                          isClearable={true}
                          isDisabled={checkedReportingPeriodIdTable2 !== rp.id}
                          onChange={values => setSelectedDepartments(deps => ({ ...deps, [rp.id]: values }))}
                        />
                      </td>
                    </tr>,
                  )}
              </tbody>
            </table>
          </div>
          {checkedReportingPeriodIdTable2 &&
            <>
              <button
                className='btn btn-sm btn-outline-primary'
                style={{ marginLeft: '10px', height: '31px' }}
                onClick={() => generateArchive(1)}
                disabled={!selectedDepartments[checkedReportingPeriodIdTable2]?.length}
              >
                <FontAwesomeIcon icon={faDownload} /> Выгрузить архив в формате № 1
              </button>
              <button
                className='btn btn-sm btn-outline-primary'
                style={{ marginLeft: '10px', height: '31px' }}
                onClick={() => generateArchive(2)}
                disabled={!selectedDepartments[checkedReportingPeriodIdTable2]?.length}
              >
                <FontAwesomeIcon icon={faDownload} /> Выгрузить архив в формате № 2
              </button>
            </>
          }
        </div>
      </div>
    </>
  )
}

import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { IProject, IProjectForm } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  projectDetailsLoadedAction, projectFetchedAction, projectLoadedAction, ProjectState, useProjectReducer,
} from '@/context/project/projectStore'
import useProjectId from '@/hooks/useProjectId'


/** Project context value interface */
interface ProjectContextValue extends ProjectState {
  loadProject: () => void
  updateProject: (project: IProjectForm) => void
}


/** Project context */
const ProjectContext = createContext({} as ProjectContextValue)


/** Project context hook */
export const useProjectContext = () => useContext(ProjectContext)

type Props = PropsWithChildren<{}>

/** Project context provider */
export function ProjectContextProvider({ children }: Props) {
  const [state, dispatch] = useProjectReducer()
  const { projectId } = useParams()

  // Приватная загрузка проекта
  const _loadProjectDetails = useCallback(async () => {
    if (!projectId) return
    try {
      const response = await axios.get(apiRoutes.project(projectId))
      const project: IProject = response.data
      dispatch(projectDetailsLoadedAction(project))
    } catch (e: any) {
      console.error(e)
    }
  }, [projectId, dispatch])

  // Загрузка проекта
  const loadProject = useCallback(async () => {
    dispatch(projectFetchedAction())
    try {
      await _loadProjectDetails()
    } catch (e: any) {
      console.error(e)
    } finally {
      dispatch(projectLoadedAction())
    }
  }, [_loadProjectDetails, dispatch])

  /** Update current project */
  const updateProject = useCallback(async (projectForm: IProjectForm) => {
    await axios.patch(apiRoutes.project(projectId!), projectForm, {
      headers: { 'Content-Type': 'application/merge-patch+json' },
    })
  }, [projectId])


  // Инициализация и перезагрузка проекта
  useEffect(() => {
    if (!projectId) return
    loadProject()
  }, [projectId, loadProject])

  const value: ProjectContextValue = useMemo(() => ({
    ...state,
    loadProject,
    updateProject,
  }), [state, loadProject, updateProject])

  useStorageStateLog('project', state)

  return (
    <ProjectContext.Provider value={value}>
      {children ?? <Outlet />}
    </ProjectContext.Provider>
  )
}

import { useReducer } from 'react'
import { ISelectOption } from '@/context/types'


/** API resource select option list store state interface */
export type ApiResourceSelectOptionListState = {
  isLoading: boolean
  selectOptionList: ISelectOption[]
  error: string | null
}

/** API resource select option list store actions */
type ApiResourceSelectOptionListAction =
  | { type: 'fetched', payload?: ISelectOption[] | string }
  | { type: 'loaded', payload: ISelectOption[] }
  | { type: 'failed', payload: string }


/** API resource select option list store initial state */
const apiResourceSelectOptionListInitState: ApiResourceSelectOptionListState = {
  isLoading: false,
  selectOptionList: [],
  error: null,
}


/** API resource select option list srore reducer */
function ApiResourceSelectOptionListReducer(state: ApiResourceSelectOptionListState, action: ApiResourceSelectOptionListAction): ApiResourceSelectOptionListState {
  switch (action.type) {
    case 'fetched':
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case 'loaded':
      return {
        ...state,
        isLoading: false,
        selectOptionList: action.payload,
        error: null,
      }
    case 'failed':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}


/** API resource select option list store state & reducer hook */
export const useApiResourceSelectOptionListReducer =
  () => useReducer(ApiResourceSelectOptionListReducer, apiResourceSelectOptionListInitState)


// API resource select option store action createers

/** API resource select option list fetched action creator */
export const apiResourceSelectOptionListFetchedAction =
  (): ApiResourceSelectOptionListAction =>
    ({ type: 'fetched' })
/** API resource select option list loaded action creator */
export const apiResourceSelectOptionListLoadedAction =
  (selectOptionList: ISelectOption[]): ApiResourceSelectOptionListAction =>
    ({ type: 'loaded', payload: selectOptionList })
/** API resource select option list fetched action creator */
export const ApiResourceSelectOptionListFailedAction =
  (error: string): ApiResourceSelectOptionListAction =>
    ({ type: 'failed', payload: error })
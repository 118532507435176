import { Dispatch, Reducer, useReducer } from 'react'
import { IPaginationResponse } from '@/context/types'


/** Generic record list store state interface */
export interface IRecordListState<TRecord> {
  isLoading: boolean
  recordList: TRecord[]
  lastPage?: number
  totalItems?: number
  error: string | null
}

export enum RecordListActionType {
  fetched,
  loaded,
  failed,
}

export interface IRecordListAction<TRecord> {
  type: RecordListActionType,
  payload?: ILoadedActionPayload<TRecord> | string
}

export interface ILoadedActionPayload<TRecord> {
  list: TRecord[]
  pagination: IPaginationResponse
}


/** Generic record list store initial state getter */
const getRecordListInitState: <TRecord>() => IRecordListState<TRecord> = <T>() => ({
  isLoading: false,
  recordList: [] as T[],
  error: null,
})


/** Generic record list srore reducer */
function recordListReducer<TRecord>(state: IRecordListState<TRecord>, { type, payload }: IRecordListAction<TRecord>): IRecordListState<TRecord> {
  switch (type) {
    case RecordListActionType.fetched:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case RecordListActionType.loaded:
      return {
        ...state,
        isLoading: false,
        recordList: (payload as ILoadedActionPayload<TRecord>).list,
        lastPage: (payload as ILoadedActionPayload<TRecord>).pagination.lastPage,
        totalItems: (payload as ILoadedActionPayload<TRecord>).pagination.totalItems,
        error: null,
      }
    case RecordListActionType.failed:
      return {
        ...state,
        isLoading: false,
        error: payload as string,
      }
    default:
      return state
  }
}


/** Generic record list store state & reducer hook */
export const useRecordListReducer:
  <TRecord>() => [IRecordListState<TRecord>, Dispatch<IRecordListAction<TRecord>>] =
  <TRecord>() => useReducer<Reducer<IRecordListState<TRecord>,IRecordListAction<TRecord>>>(recordListReducer, getRecordListInitState<TRecord>())


// Generic record store action createers

/** Generic record list fetched action creator */
export const recordListFetchedAction = <TRecord>() => ({
  type: RecordListActionType.fetched,
} as IRecordListAction<TRecord>)

/** Generic record list loaded action creator */
export const recordListLoadedAction = <TRecord>(list: TRecord[], pagination: IPaginationResponse) => ({
  type: RecordListActionType.loaded,
  payload: { list, pagination },
} as IRecordListAction<TRecord>)

/** Generic record list fetched action creator */
export const recordListFailedAction = <TRecord>(payload: string) => ({
  type: RecordListActionType.failed,
  payload,
} as IRecordListAction<TRecord>)
import { useRef, useState } from 'react'
import { instanceAxios as axios } from '@/api/instanceAxios'
import useEffectOnce from '@/hooks/useEffectOnce'
import { useModal } from '@/context/ModalContext'
import ProgressBar from '@/components/common/ProgressBar'
import { IRecordWithId } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'


export type Props<TRecord extends IRecordWithId> = {
	selectedRecordIdSet: Set<TRecord['id']>
	onDelete?: (id: TRecord['id']) => any
	onDone: () => void
  recordApiRoute: (id: TRecord['id']) => string
}

export default function DeleteRecordListModalContent<TRecord extends IRecordWithId>({
  selectedRecordIdSet,
  onDelete,
  onDone,
  recordApiRoute
}: Props<TRecord>) {
	const { updateState, usePrimaryCallback } = useModal()
	const [responseCount, setResponseCount] = useState(0)
	const [deletedCount, setDeletedCount] = useState(0)
	const [errors, setErrors] = useState([] as string[])
	const [isDone, setIsDone] = useState(false)
	const abortFlagRef = useRef(false)

	useEffectOnce(() => {
		(async () => {
			for (const id of Array.from(selectedRecordIdSet)) {
				if (abortFlagRef.current) break
				try {
					await axios.delete(recordApiRoute(id))
          onDelete?.(id)
					setDeletedCount(prev => prev + 1)
				} catch (e: any) {
          const error = await getErrorMessage(e)
          setErrors(errors => [...errors, error])
        } finally {
					setResponseCount(prev => prev + 1)
				}
			}
			setIsDone(true)
			updateState({ type: 'ok', dismissable: true })
			onDone()
		})()
	})

	usePrimaryCallback(() => {
		abortFlagRef.current = true
	}, [])

	return (
		<>
			<p>Обработано {responseCount} из {selectedRecordIdSet.size} записей.</p>
			<ProgressBar now={responseCount} max={selectedRecordIdSet.size} isAnimated={!isDone} />
			<p className='mt-3 mb-0'>Удалено {deletedCount} записей.</p>
      {!!errors.length &&
        <>
          <div>Ошибки:</div>
          {errors.map((error, i) => <div key={i}>{error}</div>)}
        </>
      }
		</>
	)
}

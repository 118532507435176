import { ChangeEvent, useCallback, useEffect, useId, useState } from 'react'
import { useFormContext } from '@/context/form/FormContext'
import RequiredLabel from '@/components/common/RequiredLabel'


type Props<TForm> = {
  label: string
  name: keyof TForm
  initValue?: boolean | null
  required?: boolean
  helpText?: string
  disabled?: boolean
}

/** Чекбокс для контекстной формы */
export default function CheckboxInput<TFrom>({
  label,
  name,
  initValue,
  required,
  helpText,
  disabled
}: Props<TFrom>) {
  const { setField, errors } = useFormContext<TFrom>()
  const [value, setValue] = useState<boolean>(false)
  const id = useId()

  useEffect(() => {
    setValue(initValue ?? false)
    setField(name, initValue ?? false)
  }, [name, initValue, setField])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
    setField(name, event.target.checked)
  }, [name, setField])

  return (
    <div className='form-check mb-3'>
      <input
        className={`form-check-input ${errors[name] && 'is-invalid'}`}
        type='checkbox'
        id={id}
        checked={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <label className='form-check-label' htmlFor={id}>
        {label}
        {required && <RequiredLabel />}
      </label>
      {errors[name] &&
        <div className='invalid-feedback'>{errors[name]}</div>
      }
      {helpText &&
        <div className='form-text'>
          {helpText}
        </div>
      }
    </div>
  )
}

import { StatType } from '@/components/audit/stats/PecsStatWrapper'


type Props<T extends string | StatType> = {
  label: string
  btns: {
    id: T
    label: string
    selected: boolean
  }[]
  onClick: (id: T) => any
}

const PecsStatBtnsRow = <T extends string | StatType>({ label, btns, onClick }: Props<T>) =>
  <div className='my-2 d-flex flex-wrap align-items-center'>
    {label}:
    <div className='btn-group btn-group-sm ms-2 flex-wrap'>
      {btns.map(({ id, label, selected }) =>
        <button
          key={id}
          onClick={() => onClick(id)}
          type='button'
          className={`btn btn-outline-secondary ${selected ? 'active' : ''}`}
        >
          {label}
        </button>,
      )}
    </div>
  </div>

export default PecsStatBtnsRow

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ApiResource, ICctv } from '@/context/types'
import { ProjectsCctvTableColumnName, ProjectsCctvTableColumns } from '@/config/tables/lists/projects/projectsCctv'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'
import { getCctvsListFilter } from '@/config/listFilters'
import { ExploitationCctvTableColumns } from '@/config/tables/lists/exploitation/exploitationCctv'


/** Cctv list context */
const CctvListContext = createContext({} as TableApiResourceContextValue<ICctv, ProjectsCctvTableColumnName>)


/** Cctv list context hook */
export const useCctvListContext = () => useContext(CctvListContext)


type Props = PropsWithChildren<{}>

/** Cctv list context provider */
export function CctvListContextProvider({ children }: Props) {
  const { projectId } = useParams()

  return (
    <TableApiResourceContextProvider<ICctv, ProjectsCctvTableColumnName>
      Context={CctvListContext}
      apiResource={ApiResource.Cctv}
      loadIds
      recordIdKey='cctvId'
      tableColumns={projectId ? ProjectsCctvTableColumns : ExploitationCctvTableColumns}
      logName='cctvList'
      filter={getCctvsListFilter(projectId)}
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}
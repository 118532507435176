import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportTR } from '@/components/reports/types'
import { useParams } from 'react-router-dom'


export const ReportHardwareRfc35962 = () => {
  const { projectId } = useParams()
  const [report, setReport] = useState<IReportTR[]>([])
  return (
    <ReportWrapper
      code='hardwareRfc35962'
      {...{ report, setReport }}
    >
      <table className='table table-bordered table-sm mt-4'>
        <thead
          className='text-muted'
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {!projectId && <th>Проект</th>}
            <th>№ ТВН</th>
            <th>Тип ТВН</th>
            <th>Адрес ТВН</th>
            <th>Модель коммутатора</th>
            <th>Имя коммутатора</th>
            <th>IP коммутатора</th>
            <th>Номер камеры</th>
            <th>IP камеры</th>
            <th>Версия прошивки</th>
            <th>Имя камеры</th>
            <th>Модель камеры</th>
            <th>Видеомаркер</th>
            <th>IP Видеосервер</th>
            <th>Имя Видеосервер</th>
            <th>Модель Видеосервер</th>
          </tr>
        </thead>
        <tbody>
          {report.map((row, index) => (
            <tr key={index}>
              {!projectId && <td>{row.project_name}</td>}
              <td>{row.cctv_number}</td>
              <td>{row.cctv_type_name}</td>
              <td>{row.address_name}</td>
              <td>{row.network_switch_model_name}</td>
              <td>{row.network_switch_code}</td>
              <td>{row.network_switch_ip_address}</td>
              <td>{row.ip_camera_number}</td>
              <td>{row.ip_camera_ip_address}</td>
              <td>{row.ip_camera_firmware}</td>
              <td>{row.ip_camera_code}</td>
              <td>{row.ip_camera_model_name}</td>
              <td>{row.ip_camera_video_marker}</td>
              <td>{row.video_server_ip_address}</td>
              <td>{row.video_server_code}</td>
              <td>{row.video_server_model_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ReportWrapper>
  )
}

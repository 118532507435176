import { useCallback, useMemo } from 'react'
import { Hardware, ICctv, IHardwareSet, IPec } from '@/context/types'
import { UserRole } from '@/config/roles'
import { useModal } from '@/context/ModalContext'
import { useAuthContext } from '@/context/auth/AuthContext'
import FolderCard from '@/components/common/cards/FolderCard'
import HardwareListCardRow from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardwareList/HardwareListCardRow'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { bindHardwareModal } from '@/components/modals/hardware/modals'
import { getItemLabel } from '@/utils/tools'


type Props = {
  item: ICctv | IPec
  hardwareSet: IHardwareSet
  reloadCard: () => Promise<void>
}


export default function HardwareListCard({ item, hardwareSet, reloadCard }: Props) {
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()

  const connectedHardware: Hardware[] = useMemo(() => {
    const res = []
    for (const networkSwitch of [...hardwareSet.videoNetworkSwitches, ...hardwareSet.communicationNetworkSwitches]) {
      res.push(networkSwitch)
      for (const hardware of [
        ...hardwareSet.routers,
        ...hardwareSet.ipCameras,
        ...hardwareSet.videoServers,
        ...hardwareSet.upses,
        ...hardwareSet.accessPoints,
      ])
        if (hardware.networkSwitchPort?.networkSwitch?.id === networkSwitch.id)
          res.push(hardware)
    }
    return res
  }, [hardwareSet])

  const disconnectedHardwareList: Hardware[] = useMemo(() => {
    return [
      ...hardwareSet.routers,
      ...hardwareSet.ipCameras,
      ...hardwareSet.videoServers,
      ...hardwareSet.upses,
      ...hardwareSet.accessPoints,
    ].filter(hardware => !connectedHardware.includes(hardware))
  }, [connectedHardware, hardwareSet])

  const handleOpenBindHardwareModal = useCallback(() => {
    showModalDialog(bindHardwareModal(item, hardwareSet, reloadCard))
  }, [item, hardwareSet, reloadCard, showModalDialog])

  return (
    <FolderCard
      headerBgColor='#96f2d7'
      bodyBgColor='#e6fcf5'
      header={`Оборудование ${getItemLabel(item)}-${item.number}`}
      rightToolbar={
        <SmallRoundBtn
          icon={faSquarePlus}
          tooltip='Привязать оборудование'
          disabled={!hasRole([UserRole.admin, UserRole.mgts])}
          onClick={handleOpenBindHardwareModal} />}>
      <table className='table table-sm table-borderless table-hover w-100 mb-0'>
        <thead className='border-bottom text-muted'>
          <tr>
            <td>Тип</td>
            <td>Модель</td>
            <td>Статус</td>
            <td>IP-адрес</td>
            <td>MAC-адрес</td>
            <td>TT</td>
            <td>Пинг</td>
            <td>Лог</td>
          </tr>
        </thead>
        <tbody className='align-middle'>
          {connectedHardware.map(hardware =>
            <HardwareListCardRow key={hardware.id} hardware={hardware} separator={<>↳&nbsp;</>} />)}
          {disconnectedHardwareList.map(hardware =>
            <HardwareListCardRow key={hardware.id} hardware={hardware} />)}
        </tbody>
      </table>
    </FolderCard>
  )
}

import { millisecondsToDateString } from '@/utils/date'


type Props = {
  from: Date
  to?: Date
}

const Duration = ({ from, to }: Props) => <>{millisecondsToDateString(+(to ?? new Date) - +from)}</>

export default Duration

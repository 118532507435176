import { useCallback, useEffect } from 'react'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import useTableRecordDelete from '@/hooks/useTableRecordDelete'
import { IAddress, IChannelAddress } from '@/context/types'
import { apiRoutes, routes } from '@/config/routes'
import {
  ChannelAddressTableColumnName, ChannelAddressTableColumnNameList, ChannelAddressTableColumns,
  prepareChannelAddressesAdditionalCols,
} from '@/config/tables/lists/channels/channelAddress'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useModal } from '@/context/ModalContext'
import { useAddressListContext } from '@/context/tables/AddressListContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import ChannelsNav from '@/components/channels/ChannelsNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import PaginationWSP from '@/components/common/PaginationWSP'
import TableHead from '@/components/common/table/TableHead'
import TableRow from '@/components/common/table/TableRow'
import TableToolbar from '@/components/common/table/TableToolbar'
import { isObjectEmpty } from '@/utils/tools'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import { useAuthContext } from '@/context/auth/AuthContext'
import Loader from '@/components/common/Loader'
import { useQuery } from '@tanstack/react-query'
import { fetchChannelStages } from '@/api/queries/statuses'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'


export default function AddressTablePageContent() {
  const { hasPermission } = useAuthContext()
  useDocumentTitle('Таблица адресов')
  const { showModalDialog } = useModal()
  const showDeleteModalDialog = useTableRecordDelete<IAddress>(apiRoutes.address, 'адресов', routes.address)
  
  const { isFetching: isChannelStagesLoading, data: channelStages, refetch: loadChannelStages } = useQuery({
    queryKey: ['channel_stages'],
    queryFn: fetchChannelStages,
  })
  
  const availableCols = useTableAvailableCols(ChannelAddressTableColumnNameList, ChannelAddressTableColumns)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    selectedRecordIdSet,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    samplerLabel,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
    setRecordListSampler,
    deselectAllRecords
  } = useAddressListContext()
  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter
  } = useTableFilterForm(filter, setRecordListFilter)

  /** Удалить выбранные адреса (с подтверждением) */
  const deleteRecords = useCallback(() => {
    showDeleteModalDialog(selectedRecordIdSet, deselectAllRecords, loadRecordList)
  }, [selectedRecordIdSet, deselectAllRecords, loadRecordList])

  /** Сбросить выборку */
  const handleClearSampler = useCallback(() => {
    setRecordListSampler({}, '')
  }, [setRecordListSampler])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('адресов'))
  }, [showModalDialog])

  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [])
  
  const reload = async () => {
    await loadChannelStages()
    await loadRecordList()
  }
  const isLoading = isRecordListLoading || isFullIdRecordListLoading || isChannelStagesLoading 

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem>Каналы</BreadcrumbsItem>
        <BreadcrumbsItem>Список всех адресов</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <ChannelsNav />
      <TableToolbar
        hasSampler
        samplerLabel={samplerLabel}
        recordListLength={recordList.length}
        recordListCount={count}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        selectedRecordIdSetSize={selectedRecordIdSet.size}
        fullRecordIdSet={fullRecordIdSet}
        clearFilter={clearFilter}
        onClearSampler={handleClearSampler}
        onReload={reload}
        exportBtnLabel='Массовый экспорт адресов'
        exportApiRoute={hasPermission('exportChannelAddresses') ? apiRoutes.exportChannelAddresses : ''}
        handleShowFilterInfo={handleShowFilterInfo}
        onClickDelete={deleteRecords}
        canDelete={hasPermission('addressDelete')}
      />
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={availableCols}
                columns={ChannelAddressTableColumns}
                additionalColumns={prepareChannelAddressesAdditionalCols(channelStages!)}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
              />
              <tbody>
                {!recordList.length
                  ? <EmptyTableRow columnCount={availableCols.length + 1}>
                    {isObjectEmpty(filter) ? 'Адресов нет' : 'Адресов не найдено'}
                  </EmptyTableRow>
                  : recordList.map(record =>
                    <TableRow<IChannelAddress, ChannelAddressTableColumnName>
                      key={record.id}
                      columnNameList={availableCols}
                      columns={ChannelAddressTableColumns}
                      additionalColumns={prepareChannelAddressesAdditionalCols(channelStages!, record)}
                      record={record}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount} />
        </div>
      </div>
    </>
  )
}

import { arrayIntersect } from '@/utils/array'
import { UserRole as R } from '@/config/roles'


const UserCctvPermissions = {
  cctvContractRead: [R.sitronics],
}
const UserCatalogPermissions = {
  catalogRead: [R.pm, R.view],
  catalogEdit: [],
}
const UserServicePermissions = {
  serviceCreate: [R.pm_connection, R.pm_income],
  serviceRead: [R.pm, R.contractor, R.mgts, R.view, R.pm_connection, R.pm_cost, R.pm_income, R.employee],
  serviceEdit: [R.pm_connection, R.pm_income, R.employee],
  serviceDelete: [],

  serviceStageEdit: [R.pm_connection],
  serviceBillingRead: [R.pm, R.view, R.pm_connection, R.pm_cost, R.pm_income, R.employee],
}
const UserChannelPermissions = {
  channelCreate: [R.pm, R.pm_connection, R.pm_cost, R.pm_income],
  channelRead: [R.pm, R.pm_connection, R.pm_cost, R.pm_income, R.view],
  channelEdit: [R.pm, R.pm_connection, R.pm_cost, R.pm_income],
  channelDelete: [R.pm, R.pm_connection, R.pm_cost, R.pm_income],
  
  channelStageRead: [R.pm, R.pm_connection, R.pm_cost, R.pm_income],
  channelStageEdit: [R.pm, R.pm_connection, R.pm_cost, R.pm_income],
}
const UserAddressPermissions = {
  addressCreate: UserChannelPermissions.channelCreate,
  addressRead: UserChannelPermissions.channelRead,
  addressEdit: UserChannelPermissions.channelEdit,
  addressDelete: UserChannelPermissions.channelDelete,
}
const UserConstructionObjectPermissions = {
  constructionObjectCreate: [R.pm_connection, R.pm_cost, R.pm_income, R.employee],
  constructionObjectRead: [R.pm, R.contractor, R.mgts, R.view, R.pm_connection, R.pm_cost, R.pm_income, R.employee],
  constructionObjectEdit: [R.pm_connection, R.pm_cost, R.pm_income, R.employee],
  constructionObjectDelete: [],
}
const UserChannelPaymentsPermissions = {
  channelPaymentsRead: UserChannelPermissions.channelRead,
}
const UserPecPermissions = {
  pecCreate: [],
  pecRead: [R.responsible_from_customer, R.brigadier, R.executor, R.view],
  pecEdit: [],
  pecDelete: [],

  pecStatRead: [R.responsible_from_customer, R.view],
  pecWorkBeginDateEdit: [],
  pecBrigadierEdit: [R.responsible_from_customer],
  pecExecutorEdit: [R.responsible_from_customer, R.brigadier],
  pecStageEdit: [R.responsible_from_customer, R.brigadier, R.executor],
  pecCommentCreate: [R.responsible_from_customer, R.brigadier, R.executor],
  pecCommentDelete: [],
  pecFileCreate: [R.responsible_from_customer, R.brigadier, R.executor],
  pecFileDelete: [],
}
const UserImportPermissions = {
  importCatalogs: UserCatalogPermissions.catalogEdit,

  importServices: arrayIntersect(UserServicePermissions.serviceCreate, UserServicePermissions.serviceEdit),
  importChannels: arrayIntersect(UserChannelPermissions.channelCreate, UserChannelPermissions.channelEdit),
  importConstructionObject: arrayIntersect(
    UserConstructionObjectPermissions.constructionObjectCreate,
    UserConstructionObjectPermissions.constructionObjectEdit
  ),
  importPecs: arrayIntersect(UserPecPermissions.pecCreate, UserPecPermissions.pecEdit),
  importPecUsers: [],
}
const UserExportPermissions = {
  exportCatalogs: UserCatalogPermissions.catalogRead,

  exportServices: UserServicePermissions.serviceRead,
  exportChannels: UserChannelPermissions.channelRead,
  exportChannelAddresses: UserAddressPermissions.addressRead,
  exportConstructionObject: UserConstructionObjectPermissions.constructionObjectRead,
  exportChannelPayments: UserChannelPaymentsPermissions.channelPaymentsRead,
  exportPecs: [R.responsible_from_customer, R.view],

  exportServiceBilling: UserServicePermissions.serviceBillingRead,
  exportActs: [R.pm, R.pm_connection, R.pm_cost, R.pm_income, R.employee],
}

export const UserPermissions = {
  projectRead: [R.pm, R.contractor, R.mgts, R.view, R.dit, R.pm_connection, R.pm_cost, R.pm_income, R.employee],
  communicationRead: [R.pm, R.contractor, R.mgts, R.view, R.pm_connection, R.pm_cost, R.pm_income, R.employee],
  exploitationRead: [R.pm, R.contractor, R.mgts, R.view, R.dit, R.pm_connection, R.pm_cost, R.pm_income, R.employee],
  auditRead: [R.executor, R.brigadier, R.responsible_from_customer, R.view],
  auditProjectEdit: [],
  auditProjectPecFilesExport: [],
  ...UserPecPermissions,
  ...UserCctvPermissions,
  ...UserCatalogPermissions,
  ...UserServicePermissions,
  ...UserChannelPermissions,
  ...UserAddressPermissions,
  ...UserConstructionObjectPermissions,
  ...UserImportPermissions,
  ...UserExportPermissions,
}

import { useState } from 'react'
import Select from 'react-select'
import { ISelectOption } from '@/context/types'
import { defaultRecordsPerPage } from '@/config/config'
import { TableApiResourceContextValue } from '@/context/tableApiResource/TableApiResourceContext'


export type Props = Pick<TableApiResourceContextValue<any, any>, 'recordsPerPage' | 'setRecordsPerPage'>

type SelectOption = ISelectOption<number>

const numberToSelectOption = (number: number): SelectOption => ({
  label: '' + number,
  value: number,
})

export const RecordsPerPageSelect = ({ recordsPerPage, setRecordsPerPage }: Props) => {
  const options = [defaultRecordsPerPage, 100, 200]

  const [itemsPerPage, setItemsPerPage] = useState(numberToSelectOption(recordsPerPage))

  const handleChange = (selectedOption: SelectOption) => {
    setItemsPerPage(selectedOption)
    setRecordsPerPage(selectedOption.value)
  }

  return (
    <div className='d-flex align-items-center'>
      Показывать по: 
      <Select
        options={options.map(numberToSelectOption)}
        value={itemsPerPage}
        onChange={selectedOption => handleChange(selectedOption as SelectOption)}
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            transform: 'scale(.8)',
          }),
          singleValue: baseStyles => ({
            ...baseStyles,
            fontSize: 18,
            marginBottom: -1,
          }),
        }}
      />
    </div>
  )
}

import { FormEventHandler, PropsWithChildren, ReactNode } from 'react'


type Props = PropsWithChildren<{
  message?: ReactNode
  onSubmit: FormEventHandler<HTMLFormElement>
}>

export default function AuthFormContainer({ children, onSubmit, message }: Props) {
  return (
    <div className='h-100 d-flex flex-column justify-content-center'>
      <div className='card w-100 mx-auto' style={{ maxWidth: '400px' }}>
        <div className='card-body'>
          <form onSubmit={onSubmit}>
            <div className='d-grid gap-4'>{children}</div>
          </form>
        </div>
      </div>
      {message && (
        <div className='text-center w-100 mx-auto mt-2' style={{ maxWidth: '400px' }}>
          {message}
        </div>
      )}
    </div>
  )
}

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IService, IServiceForm } from '@/context/types'
import { ServiceTableColumnName, ServiceTableColumns } from '@/config/tables/lists/commutication/service'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Service list context */
const ServiceListContext = createContext({} as TableApiResourceContextValue<IService, ServiceTableColumnName, IServiceForm>)


/** Service list context hook */
export const useServiceListContext = () => useContext(ServiceListContext)


type Props = PropsWithChildren<{}>

/** Service list context provider */
export function ServiceListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IService, ServiceTableColumnName, IServiceForm>
      Context={ServiceListContext}
      apiResource={ApiResource.Service}
      loadIds
      recordIdKey='serviceId'
      tableColumns={ServiceTableColumns}
      logName='serviceList'
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}
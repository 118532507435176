import { Context, PropsWithChildren, useCallback, useMemo } from 'react'
import { ApiResource, IRecordWithId, TableColumnConfig, TableFilter, TableSampler } from '@/context/types'
import { Outlet, useParams } from 'react-router-dom'
import getAxiosPagination from '@/utils/getAxiosPagination'
import getErrorMessage from '@/utils/getErrorMessage'
import filterToParams from '@/utils/paramConverters/filterToParams'
import samplerToParams from '@/utils/paramConverters/samplerToParams'
import sortToParams from '@/utils/paramConverters/sortToParams'
import { instanceAxios as axios } from '@/api/instanceAxios'
import {
  getApiResourceDeleteRoute, getApiResourceListRoute, getApiResourceReadRoute, getApiResourceUpdateRoute,
} from '@/config/routes'
import { convertRecord } from '@/config/apiConverters'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  allRecordsDeselectedAction, allRecordsSelectedAction, fullRecordIdSetClearedAction, fullRecordIdSetFetchedAction,
  fullRecordIdSetLoadedAction, recordDeselectedAction, recordFailedAction, recordFetchedAction, recordListClearedAction,
  recordListFailedAction, recordListFetchedAction, recordListFilterChanged, recordListLoadedAction,
  recordListPageChanged, recordListSamplerChanged, recordListSortChanged, recordLoadedAction, recordSelectedAction,
  TableApiResourceState, useTableApiResourceReducer,recordsPerPageChanged
} from '@/context/tableApiResource/tableApiResourceStore'
import groupsToParams from '@/utils/paramConverters/groupsToParams'


/** Table API resource context value interface */
export interface TableApiResourceContextValue<TRecord extends IRecordWithId, TColumnName extends string, TRecordForm = never> extends TableApiResourceState<TRecord, TColumnName> {
	loadRecordList: (additionalFilter?: Record<string, any>) => Promise<void>
	setRecordListPage: (page: number) => void
	setRecordListSampler: (sampler: TableSampler, label: string) => void
	setRecordListFilter: (filter: TableFilter<TColumnName>) => void
	setRecordListSort: (fieldName?: string) => void
	selectRecord: (id: TRecord['id']) => void
	deselectRecord: (id: TRecord['id']) => void
	selectAllRecords: () => void
	deselectAllRecords: () => void
	createRecord: (form: TRecordForm) => Promise<TRecord['id']>
	loadRecord: () => Promise<void>
	updateRecord: (form: TRecordForm, id?: TRecord['id']) => Promise<void>
	deleteRecord: (id: TRecord['id']) => Promise<void>
	setRecordsPerPage: (itemsPerPage: number) => void
}
export type AnyTableApiResourceContextValue = TableApiResourceContextValue<any, any>


type Props<TRecord extends IRecordWithId, TColumnName extends string, TRecordForm> = PropsWithChildren<{
	Context: Context<TableApiResourceContextValue<TRecord, TColumnName, TRecordForm>>
	apiResource: ApiResource
	loadIds?: boolean
	recordIdKey: string
	tableColumns: Record<TColumnName, TableColumnConfig<TRecord>>
	requiredFilterColumns?: TColumnName[]
	logName: string
  groups?: `${string}:read`[]
  filter?: TableFilter<string>
}>

/** Table API resource context provider */
export function TableApiResourceContextProvider<
	TRecord extends IRecordWithId,
	TColumnName extends string,
	TRecordForm = never
>({
	Context,
	apiResource,
	loadIds,
	recordIdKey,
	tableColumns,
	requiredFilterColumns,
	children,
	logName,
  groups,
  filter,
}: Props<TRecord, TColumnName, TRecordForm>) {
	const [state, dispatch] = useTableApiResourceReducer<TRecord, TColumnName>()
	const { [recordIdKey]: _recordId } = useParams()

  /** Api resource converter */
  const _convertRecord = useCallback((record: TRecord) => convertRecord(apiResource, record), [apiResource])

	/** Load full record id set */
	const loadFullRecordIdSet = useCallback(async () => {
		dispatch(fullRecordIdSetFetchedAction())
		const response = await axios.get(
			getApiResourceListRoute(apiResource), {
			params: {
				pagination: false,
        ...groupsToParams([...(groups ?? []), 'id:read']),
				...filterToParams(state.filter, tableColumns),
        ...filter,
				...samplerToParams(state.sampler)
			}
		})
		const recordList = response.data as Pick<TRecord, 'id'>[]
		const recordIdList = recordList.map(({ id }) => id)
		const recordIdSet = new Set(recordIdList)
		dispatch(fullRecordIdSetLoadedAction(recordIdSet))
	}, [state.filter, state.sampler, tableColumns, apiResource, dispatch, groups, filter])

	/** Load record list (page) */
	const loadRecordList = useCallback(async (additionalFilter = {}) => {
		try {
      if(requiredFilterColumns?.length && requiredFilterColumns.some(c => !state.filter[c])) {
        dispatch(fullRecordIdSetClearedAction())
        dispatch(recordListClearedAction())
        return
      }
			dispatch(recordListFetchedAction())

			if (loadIds)
				loadFullRecordIdSet()

      if('target' in additionalFilter) //todo-sem if object is event. Need to refactor use of additionalFilter
        additionalFilter = {}

			const response = await axios.get(
				getApiResourceListRoute(apiResource), {
				params: {
					pagination: true,
					page: state.page,
          ...groupsToParams(groups),
					...filterToParams(state.filter, tableColumns),
          ...additionalFilter,
          ...filter,
					...samplerToParams(state.sampler),
          ...sortToParams(state.sortFieldName, state.sortDirection),
          itemsPerPage: state.recordsPerPage
				}
			})
			const recordList: TRecord[] = response.data.map(_convertRecord)
			const pagination = getAxiosPagination(response)
			dispatch(recordListLoadedAction({ recordList, pagination }))
		} catch (e: any) {
			console.error(e)
			dispatch(recordListFailedAction(await getErrorMessage(e)))
		}
	}, [requiredFilterColumns, state.page, state.recordsPerPage, state.filter, state.sampler, state.sortFieldName, state.sortDirection, loadIds, loadFullRecordIdSet, _convertRecord, tableColumns, apiResource, dispatch, groups, filter])

	/** Set record list page number */
	const setRecordListPage = useCallback((page: number) => {
		dispatch(recordListPageChanged(page))
	}, [dispatch])

	const setRecordsPerPage = useCallback((itemsPerPage: number) => {
		dispatch(recordsPerPageChanged(itemsPerPage))
	}, [dispatch])

	/** Set record list sampler */
	const setRecordListSampler = useCallback((sampler: TableSampler, label: string) => {
		dispatch(recordListSamplerChanged(sampler, label))
	}, [dispatch])

	/** Set record list filter */
	const setRecordListFilter = useCallback((filter: TableFilter<TColumnName>) => {
		dispatch(recordListFilterChanged(filter))
	}, [dispatch])

	/** Set record list sort field name & direction */
	const setRecordListSort = useCallback((fieldName?: string) => {
		dispatch(recordListSortChanged(fieldName))
	}, [dispatch])

	/** Select record by id */
	const selectRecord = useCallback((id: TRecord['id']) => {
		dispatch(recordSelectedAction(id))
	}, [dispatch])

	/** Deselect record by id */
	const deselectRecord = useCallback((id: TRecord['id']) => {
		dispatch(recordDeselectedAction(id))
	}, [dispatch])

	/** Select all records */
	const selectAllRecords = useCallback(() => {
		dispatch(allRecordsSelectedAction())
	}, [dispatch])

	/** Deselect all records */
	const deselectAllRecords = useCallback(() => {
		dispatch(allRecordsDeselectedAction())
	}, [dispatch])

	/** Create record */
	const createRecord = useCallback(async (form: TRecordForm) => {
		const response = await axios.post(getApiResourceListRoute(apiResource), form)
		return response.data.id
	}, [apiResource]) //=> Promise<TRecord['id']>

	/** Load record by id */
	const loadRecord = useCallback(async () => {
		if (!_recordId) return
    dispatch(recordFetchedAction())
		try {
			const response = await axios.get(getApiResourceReadRoute(apiResource,_recordId))
			const record = response.data
			dispatch(recordLoadedAction(record))
		} catch (e: any) {
			console.error(e)
			dispatch(recordFailedAction(await getErrorMessage(e)))
		}
	}, [_recordId, apiResource, dispatch])

	/** Update record by id */
	const updateRecord = useCallback(async (form: TRecordForm, id?: TRecord['id']) => {
		if (!id) id = _recordId
		if (!id) return
		await axios.patch(
			getApiResourceUpdateRoute(apiResource,id),
			form,
      { headers: { 'Content-Type': 'application/merge-patch+json' } }
		)
	}, [_recordId, apiResource])

	/** Delete record by id */
	const deleteRecord = useCallback(async (id: TRecord['id']) => {
		await axios.delete(getApiResourceDeleteRoute(apiResource,id))
	}, [apiResource])

	const value: TableApiResourceContextValue<TRecord, TColumnName, TRecordForm> = useMemo(() => ({
		...state,
		loadRecordList,
		setRecordListPage,
		setRecordListSampler,
		setRecordListFilter,
		setRecordListSort,
		createRecord,
		loadRecord,
		updateRecord,
		deleteRecord,
		selectRecord,
		deselectRecord,
		selectAllRecords,
		deselectAllRecords,
		setRecordsPerPage,
	}), [state, loadRecordList, setRecordListPage, setRecordListSampler, setRecordListFilter, setRecordListSort, createRecord, loadRecord, updateRecord, deleteRecord, selectRecord, deselectRecord, selectAllRecords, deselectAllRecords, setRecordsPerPage])

	useStorageStateLog(logName, state)

	return (
		<Context.Provider value={value}>
			{children ?? <Outlet />}
		</Context.Provider>
	)
}
import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useModal } from '@/context/ModalContext'
import { FormContextProvider } from '@/context/form/FormContext'
import { ICctv, IPec } from '@/context/types'
import CommentForm from '@/components/CctvListPageContent/Cctv/Folder/blocks/CommentForm'
import { postData } from '@/api/postData'
import { apiRoutes } from '@/config/routes'
import { errorHandle } from '@/utils/errorHandle'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { getItemLabel } from '@/utils/tools'


type Props = {
  item: ICctv | IPec
  reloadCard: () => Promise<void>
  canCreate: boolean
  canDelete: boolean
}

export default function CommentsCard({ item, reloadCard, canCreate, canDelete }: Props) {
  const { showModalDialog } = useModal()
  const handleClickEdit = () => {
    showModalDialog({
      type: 'saveCancel',
      size: 'lg',
      header: `Добавление комментария к ${getItemLabel(item)}`,
      content:
        <FormContextProvider key={item.id}>
          <CommentForm item={item} reloadCard={reloadCard} />
        </FormContextProvider>,
    })
  }
  const handleClickTrash = (id: string) => {
    postData({
      url: item.itemType === 'cctv' ? apiRoutes.cctvComment(id) : apiRoutes.pecComment(id),
      axiosMethod: 'delete',
      successAction: () => reloadCard(),
      errorHandler: (err) => errorHandle(err),
    })
  }

  return (
    <div className='card border-0 mb-2'>
      <div
        className={`card-header border-0 px-2 py-1 justify-content-between ${cn(
          style.cardHeader,
          style.cardHeaderComments,
        )} `}
      >
        <div>
          <button
            className={`btn text-primary p-0 ${style.cardHeaderArrow}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseComments'
            aria-expanded={item.itemType === 'pec'}
            aria-controls='collapseComments'
          >
            <b>Комментарии к {getItemLabel(item)}</b>
          </button>
        </div>
        <div>
          {canCreate &&
            <SmallRoundBtn icon={faPlusSquare} tooltip='Добавить комментарий' onClick={handleClickEdit} />
          }
        </div>
      </div>
      <div
        className={`collapse ${item.itemType === 'pec' ? 'show' : ''}`}
        id='collapseComments'
        style={{ overflowX: 'auto' }}
      >
        <div
          className={`card-body border-0 ${cn(
            style.cardBody,
            style.cardBodyComments,
            style.cctvInfoCollapse,
          )} p-0`}
        >
          {item.comments.length
            ? <table className='table table-sm table-borderless table-hover mb-0'>
              <tbody>
                {item.comments.map(comment =>
                  <tr key={comment.id}>
                    <td className='text-muted ps-2'>{(new Date(comment.createdDatetime)).toLocaleString()}</td>
                    <td className='pe-2'>{comment.author.lastName} {comment.author.firstName}</td>
                    <td className='pe-2 w-50'>{comment.content}</td>
                    {canDelete &&
                      <td style={{ width: '2em' }}>
                        <button
                          onClick={() => handleClickTrash(comment.id)}
                          className='btn text-danger px-1 py-0'
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    }
                  </tr>,
                )}
              </tbody>
            </table>
            : <p className='ps-2'>Нет комментариев</p>
          }
        </div>
      </div>
    </div>
  )
}

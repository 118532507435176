import { ChangeEvent, useCallback } from 'react'
import { getTextColorClassName } from '@/utils/color'
import { ProjectsSamplerGroupLabels } from '@/config/samplers'
import { useCctvListContext } from '@/context/tables/CctvListContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { To } from 'history'


type Props = {
  icon?: boolean
  label: string
  count?: number
  name?: string
  value?: string | null
  samplerLabel?: string
  route?: To
  checked?: boolean
  disabled?: boolean
  updateSampler?: (name: string, value: string | null, label: string) => void
}


export default function CardRecord({
  icon = false,
  label,
  count,
  name,
  value,
  samplerLabel,
  route,
  checked,
  disabled,
  updateSampler,
}: Props) {
  const { setRecordListSampler: setCctvListSampler } = useCctvListContext()
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (route) {
      navigate(route)
      
      if (setCctvListSampler)
        if (name)
          setCctvListSampler(
            { [name]: new Set([value ?? null]) },
            samplerLabel ?? (
              ProjectsSamplerGroupLabels[name] ? `${ProjectsSamplerGroupLabels[name]}: ${label}` : label
            ),
          )
        else
          setCctvListSampler({}, '')
    }
  }, [route, navigate, label, name, value, setCctvListSampler, samplerLabel])

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!name || value === undefined || !updateSampler) return
    updateSampler(name, value, label)
  }, [name, value, label, updateSampler])

  return (
    <div className='d-flex justify-content-between'>
      <div>
        {icon &&
          <FontAwesomeIcon icon={faCircle} className={`me-2 ${value && getTextColorClassName(value)}`} />}
        {label}
      </div>
      <div>
        {count && count > 0 && route
          ? <button
            className='btn btn-link p-0 text-decoration-none'
            onClick={handleClick}>
            {count}
          </button>
          : count}
        {name != 'stage.code' ? (
          checked !== undefined &&
          <input
            className='form-check-input ms-2'
            type='checkbox'
            checked={checked}
            onChange={handleCheck}
            disabled={count === 0 || disabled} />
        ) : (
          <input
            className='form-check-input ms-2'
            type='checkbox'
            onChange={handleCheck}
            disabled={count === 0 || disabled} />
        )}
      </div>
    </div>
  )
}
import { IIncident, IProject } from '@/context/types'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import IncidentTableRow from '@/components/modals/incidents/IncidentTableRow'
import Loader from '@/components/common/Loader'


type Props = {
	projectId?: IProject['id']
	onUpdate: () => void
}


/** Таблица инцидентов оборудования за последние 90 дней */
export default function IncidentTable({ projectId, onUpdate }: Props) {
	const { isRecordListLoading, recordList } = useApiResourceContext<IIncident>()

	if (isRecordListLoading) return <Loader />

	if (!recordList.length) return (
		<>
			За последние 90 дней инцидентов не найдено.
		</>
	)

	return (
		<div className='table-responsive'>
			<table className='table table-sm'>
				<thead className='table-primary'>
					<tr>
						<th style={{ width: '20%' }}>Номер инцидента</th>
						<th style={{ width: '20%' }}>Дата, система, пользователь</th>
						<th style={{ width: '20%' }}>Текущий этап</th>
						<th style={{ width: '20%' }}>Финальный статус</th>
						<th style={{ width: '20%' }}>Комментарий</th>
					</tr>
				</thead>
				<tbody>
					{recordList.map(incident =>
						<IncidentTableRow
							key={incident.id}
							projectId={projectId}
							incident={incident}
							onUpdate={onUpdate} />)}
				</tbody>
			</table>
		</div>
	)
}
import { useMemo } from 'react'


type Props = {
  currentPage: number
  setCurrentPage: (arg: any) => void
  lastPage?: number
}

/** Блок пагинации без поисковых параметров */
export default function PaginationWSP({
  currentPage,
  setCurrentPage,
  lastPage = 1,
}: Props) {
  const sibling = 2
  const DOTS = '...'
  const range = (start: any, end: any) => {
    let length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
  }
  const pageNumList = useMemo(() => {
    const pageNumList: number[] = []
    const totalPageNumbers = sibling + 5
    if (totalPageNumbers >= lastPage) {
      return range(1, lastPage)
    }
    const leftSiblingIndex = Math.max(currentPage - sibling, 1)
    const rightSiblingIndex = Math.min(currentPage + sibling, lastPage)
    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < lastPage - 2

    const firstPageIndex = 1
    const lastPageIndex = lastPage
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * sibling
      let leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, lastPage]
    }
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * sibling
      let rightRange = range(lastPage - rightItemCount + 1, lastPage)
      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }
    return pageNumList
  }, [lastPage, currentPage])

  if (lastPage <= 1) return null

  const handlePageClick = (pageNum: number) => () => {
    setCurrentPage(pageNum)
  }

  return (
    <nav>
      <ul className='pagination'>
        {pageNumList.map((pageNum, index) => {
          if (pageNum === DOTS) {
            return (
              <li className='page-item disabled' key={index}>
                <span className='page-link'>{pageNum}</span>
              </li>
            )
          }
          return (
            <li className={`page-item ${pageNum === currentPage && 'active'}`} key={index}>
              <button className='page-link' onClick={handlePageClick(pageNum)}>
                {pageNum}
              </button>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

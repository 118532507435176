import { Dispatch, useReducer } from 'react'
import { IUser } from '@/context/types'


/** Auth store interface */
export interface AuthContextState {
  initialized: boolean
  loading: boolean
  isSignedIn: boolean
  user?: IUser
}

/** Auth store actions */
type IAuthAction =
  | { type: 'fetched' }
  | { type: 'loaded' }
  | { type: 'unsigned' }
  | { type: 'signedIn', payload: IUser }
  | { type: 'signedOut' }
  | { type: 'profileUpdated', payload: IUser }

/** Auth store initial state */
const initAuthState: AuthContextState = {
  initialized: false,
  loading: false,
  isSignedIn: false,
  user: undefined,
}

/** Auth store reducer */
function authReducer(state: AuthContextState, action: IAuthAction): AuthContextState {
  switch (action.type) {
    case 'fetched':
      if (state.loading) return state
      return { ...state, loading: true }
    case 'loaded':
      if (!state.loading) return state
      return { ...state, loading: false }
    case 'signedIn':
      return {
        ...state,
        initialized: true,
        loading: false,
        isSignedIn: true,
        user: action.payload,
      }
    case 'signedOut':
      return {
        ...state,
        initialized: true,
        loading: false,
        isSignedIn: false,
        user: undefined,
      }
    case 'profileUpdated':
      return {
        ...state,
        loading: false,
        user: action.payload,
      }
    default:
      return state
  }
}

// Auth store state & reducer hook
export const useAuthReducer:
  () => [AuthContextState, Dispatch<IAuthAction>] =
  () => useReducer(authReducer, initAuthState)


// Auth store action creators

/** Auth store fetched action creator */
export const loadingAction =
  (): IAuthAction =>
    ({ type: 'fetched' })
/** Auth store signed in action creator */
export const signedInAction =
  (user: IUser): IAuthAction =>
    ({ type: 'signedIn', payload: user })
/** Auth store signed out action creator */
export const signedOutAction =
  (): IAuthAction =>
    ({ type: 'signedOut' })
/** Auth store profile updated action creator */
export const profileUpdatedAction =
  (user: IUser): IAuthAction =>
    ({ type: 'profileUpdated', payload: user })
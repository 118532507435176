import { ChangeEvent, useCallback, useEffect, useId, useState } from 'react'
import { useFormContext } from '@/context/form/FormContext'
import InputGroup from '@/components/common/form/InputGroup'


type Props<TForm> = {
  label: string
  name: keyof TForm
  initValue?: string
  placeholder?: string
  required?: boolean
  helpText?: string
  disabled?: boolean
}

/** Текстовое поле ввода для контекстной формы */
export default function TextAreaInput<TFrom>({
  label,
  name,
  initValue,
  placeholder,
  required,
  helpText,
  disabled
}: Props<TFrom>) {
  const { setField, errors } = useFormContext<TFrom>()
  const [value, setValue] = useState<string>('')
  const id = useId()

  useEffect(() => {
    setValue(initValue ?? '')
    setField(name, initValue)
  }, [name, initValue, setField])

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
    if (name) setField(name, event.target.value)
  }, [name, setField])

  return (
    <InputGroup
      id={id}
      label={label}
      required={required}
      helpText={helpText}
      invalidMessage={errors[name]}>
      <textarea
        className={`form-control ${errors[name] && 'is-invalid'}`}
        rows={4}
        id={id}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </InputGroup>
  )
}

import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'


type Props = PropsWithChildren<{
	path?: string
}>


export default function BreadcrumbsItem({ children, path }: Props) {
	if (!path)
		return (
			<li className='breadcrumb-item active'>
				{children}
			</li>
		)
	return (
		<li className='breadcrumb-item'>
			<Link to={path}>{children}</Link>
		</li>
	)
}

import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportSAC } from '@/components/reports/types'
import { getCctvAuditTableColumns } from '@/components/reports/config/reportSAC'


export const ReportCctvsAudit = () => {
  const [report, setReport] = useState<IReportSAC[]>([])

  const columns = getCctvAuditTableColumns(report[0])

  return (
    <ReportWrapper
      code='cctvsAudit'
      {...{ report, setReport }}
    >
      <table className='table table-bordered table-sm mt-4'>
        <thead
          className='text-muted'
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {columns.map(column => <th key={column.label}>{column.label}</th>)}
          </tr>
        </thead>
        <tbody>
          {report.map(row => (
            <tr key={row.cctv_id}>
              {columns.map(column => <td key={column.label}>{column.value(row)}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </ReportWrapper>
  )
}

import { useState } from 'react'
import { IPec, IPecStatusField } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import Loader from '@/components/common/Loader'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import DateInput from '@/components/common/form/DateInput'


type Props = {
  pec: IPec
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>
}

type Form = {
  workBeginDate: Date
}

export default function PecEditWorkBeginDateForm({ pec, statusFieldId, reloadCard }: Props) {
  const { form } = useFormContext<Form>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  usePrimaryCallback(async () => {
    if (isLoading)
      return false

    setIsLoading(true)

    try {
      await axios.patch(
        apiRoutes.updatePecWorkBeginDate(pec.id),
        {
          status_field_id: statusFieldId,
          date: form.workBeginDate,
        },
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении даты начала работ',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }, [isLoading, setIsLoading, form.workBeginDate, pec, statusFieldId, reloadCard])

  return (
    <div style={{ minHeight: 375 }}>
      <DateInput<Form>
        label='Дата начала работ'
        initValue={pec.workBeginDates.find(e => e.statusFieldId === statusFieldId)?.date}
        name='workBeginDate'
        disabled={isLoading}
      />
      {isLoading && <Loader />}
    </div>
  )
}
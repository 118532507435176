import FolderCard from '@/components/common/cards/FolderCard'
import style from '@/assets/styles/card.module.scss'
import { useCallback, useState } from 'react'
import ParamTable from '@/components/common/cards/ParamTable'
import ParamTableRow from '@/components/common/cards/ParamTableRow'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { faCaretDown, faCaretUp, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useModal } from '@/context/ModalContext'
import { FormContextProvider } from '@/context/form/FormContext'
import { AnyFormContextProvider } from '@/context/anyForm/AnyFormContext'
import { IChannel } from '@/context/types'
import ChannelCreateEditForm from '@/components/modals/channel/ChannelCreateEditForm'
import { ChannelFieldListContextProvider } from '@/context/entityFields/ChannelFieldListContext'
import { ChannelTableColumnNameList, ChannelTableColumns } from '@/config/tables/lists/channels/channel'
import { channelStatusLabels } from '@/config/status/labels'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes, routes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { useChannelListContext } from '@/context/tables/ChannelListContext'
import CloneChannelBtn from '@/components/common/btns/CloneChannelBtn'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'
import { useTabsContext } from '@/context/TabsContext'


type Props = {
  channel: IChannel,
  reloadCard: any
  showCloneBtn?: boolean
}

const ChannelInfoCard = ({ channel, reloadCard, showCloneBtn }: Props) => {
  const [isExpand, setIsExpand] = useState(true)
  const { showModalDialog } = useModal()
  const { hasPermission } = useAuthContext()
  const { closeCurrentTab } = useTabsContext()
  const availableCols = useTableAvailableCols(ChannelTableColumnNameList, ChannelTableColumns)

  const handleExpand = useCallback(() => {
    setIsExpand(prev => !prev)
  }, [])

  const handleEditChannel = useCallback(() => {
    if (!channel.id)
      return

    showModalDialog({
      type: 'saveCancel',
      dismissable: false,
      header: `Редактирование информации канала-${channel.number}`,
      size: 'lg',
      content:
      <ChannelFieldListContextProvider>
        <FormContextProvider>
          <AnyFormContextProvider>
            <ChannelCreateEditForm onSave={reloadCard} channel={channel} />
          </AnyFormContextProvider>
        </FormContextProvider>
      </ChannelFieldListContextProvider>,
    })
  }, [reloadCard, channel, showModalDialog])

  const handleDeleteChannel = useCallback(() => {
    showModalDialog({
      type: 'deleteCancel',
      header: `Удаление канала-${channel.number}`,
      content: `Вы уверены, что хотите удалить канал-${channel.number}`,
      primaryCallback: async () => {
        try {
          await axios.delete(apiRoutes.channel(channel.id))
          closeCurrentTab(routes.channels)
        } catch (e: any) {
          showModalDialog({
            type: 'error',
            header: `Удаление канала-${channel.number}: ошибка`,
            content: await getErrorMessage(e, `Произошла ошибка при удалении канала-${channel.number}`),
          })
        }
      }
    })
  }, [channel, showModalDialog, closeCurrentTab])

  const nonEditableStatuses: ChannelStatusCodes['stage'][] = ['operation', 'disabled', 'archived']

  return (
    <div>
      <FolderCard headerBgColor='#FFD8A8'
        bodyBgColor='#E1EBFA'
        textColor='blue'
        header={<button
          className='btn btn-link p-0 text-decoration-none fw-bold text-nowrap'
          onClick={handleExpand}>
          Информация по каналу - {channel.number}
          <FontAwesomeIcon icon={isExpand ? faCaretUp : faCaretDown} className='ms-2' />
        </button>}
        centerToolbar={<>
          {showCloneBtn &&
            <CloneChannelBtn channelId={channel.id} reloadCard={reloadCard} />
          }
          <SmallRoundBtn
            icon={faPenToSquare}
            tooltip='Редактировать сведения'
            disabled={!hasPermission('channelEdit') || nonEditableStatuses.includes(channel.stage.code)}
            onClick={handleEditChannel} />
        </>}
        rightToolbar={
          <SmallRoundBtn
            icon={faTrash}
            color='danger'
            tooltip='Удалить канал'
            disabled={!hasPermission('channelDelete')}
            onClick={handleDeleteChannel} />}
      >
        <div className={`${style.cctvInfoCollapse} ${isExpand && style.contentActive}`}>
          <ParamTable>
            {availableCols
              .filter(columnName =>  !Object.keys(channelStatusLabels).includes(columnName))
              .map(columnName =>
                <ParamTableRow key={columnName} label={ChannelTableColumns[columnName].label!} width='33%'>
                  {ChannelTableColumns[columnName].value?.(channel)}
                </ParamTableRow>
              )
            }
          </ParamTable>
        </div>
      </FolderCard>
    </div>
  )
}

export default ChannelInfoCard

import { KeyboardEvent, useCallback } from 'react'


export const useInputEnterDown = (cb: Function) =>
  useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter')
      return false

    cb()

    return true
  }, [cb])

import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { apiRoutes, routes } from '@/config/routes'
import { useTabsContext } from '@/context/TabsContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CommunicationNav from '@/components/communication/CommunicationNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import { instanceAxios as axios } from '@/api/instanceAxios'
import ServiceInfoCard from '@/components/communication/Folder/ServiceInfo/ServiceInfoCard'
import ChannelInfoCard from '@/components/channels/Folder/ChannelInfo/ChannelInfoCard'
import ServiceReportingPeriods from '@/components/communication/Folder/ServiceReportingPeriods/ServiceReportingPeriods'
import BillingServices from '@/components/communication/Folder/BillingServices/BillingServices'
import ChannelStatusCard from '@/components/channels/Folder/statuses/ChannelStatusCard'
import ServiceStatusCard from '@/components/communication/Folder/statuses/ServiceStatusCard'
import Loader from '@/components/common/Loader'
import { convertService } from '@/config/apiConverters'
import { IServiceWithBilling } from '@/context/types'
import DitStatus from '@/components/communication/Folder/DIT/DitStatus'
import PermissionRequired from '@/components/auth/PermissionRequired'
import HistoryCard from '@/components/common/cards/HistoryCard'
import HistoryStatusService from '@/components/communication/Folder/HistoryStatusService'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'


// Контент страницы "Услуга"
export default function ServicePageContent() {
  useDocumentTitle('Таблица услуг')
  const { serviceId } = useParams()
  const { setLabel } = useTabsContext()
  const [service, setService] = useState({} as IServiceWithBilling)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    reloadItem()
  }, [serviceId])

  const reloadItem = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(apiRoutes.service(serviceId!))

      const responseBilling = await axios.get(
        apiRoutes.service(serviceId!),
        {
          params: {
            groups: ['billing:read'],
          },
        },
      ) //todo-sem refactor to one query

      const service: IServiceWithBilling = {...response.data, ...responseBilling.data}

      setService(convertService(service))
      setLabel(`Услуга ${service.number}`)
    } catch (e: any) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [serviceId])

  if (isLoading || !service) return <div className='container-fluid pt-5'><Loader /></div>

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem path={routes.communication}>Связь</BreadcrumbsItem>
        <BreadcrumbsItem path={routes.services}>Таблица услуг</BreadcrumbsItem>
        <BreadcrumbsItem>Услуга {service.number}</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <CommunicationNav />
      {!service.id
        ? <div className='mt-5'><Loader /></div>
        : <>
          <div className='row g-2 p-3 w-100 m-0'>
            <div className='d-flex justify-content-end my-2'>
              <div className='btn-toolbar gap-2'>
                <ReloadBtn onClick={reloadItem} />
              </div>
            </div>
            <div className='col-xl'>
              <ServiceStatusCard service={service} reloadCard={reloadItem} />
              <DitStatus data={service} reloadCard={reloadItem}/>
              <PermissionRequired permission={'channelStageRead'}>
                {service.channels.map(channel =>
                  <ChannelStatusCard key={channel.id} reloadCard={reloadItem} channel={channel} />
                )}
              </PermissionRequired>
              <ServiceInfoCard reloadCard={reloadItem} service={service} />
              <HistoryCard logHistoryEntries={service.logHistoryEntries} label='услуги' />
              <HistoryStatusService service={service}/>
              <PermissionRequired permission={'serviceBillingRead'}>
                <ServiceReportingPeriods service={service} />
                <BillingServices service={service} />
              </PermissionRequired>
            </div>
            <div className='col-xl'>
              {service.channels.map(channel =>
                <div key={channel.id}>
                  <ChannelInfoCard reloadCard={reloadItem} channel={channel} />
                </div>
              )}
            </div>
          </div>
        </>
      }
    </>
  )
}

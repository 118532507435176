import { ChangeEvent, useCallback, useState } from 'react'
import { IIncidentStageLogRecord, IIncidentStageLogRecordForm } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useModal } from '@/context/ModalContext'
import { useApiResourceContext } from '@/context/apiResource/ApiResourceContext'
import IncidentMessage from '@/components/modals/incidents/IncidentMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'


type Props = {
	index: number
	logRecord: IIncidentStageLogRecord
	isEditable: boolean
}


/** Строка журнала этапов инцидента */
export default function IncidentStageLogRecord({ index, logRecord, isEditable }: Props) {
	const { user } = useAuthContext()
	const { showModalDialog } = useModal()
	const { updateRecord, loadRecordList } = useApiResourceContext<IIncidentStageLogRecord, IIncidentStageLogRecordForm>()
	const [isMessageEditting, setIsMessageEditting] = useState(false)
	const [messageDraft, setMessageDraft] = useState('')

	const handleEditMessage = useCallback(() => {
		setMessageDraft(logRecord.message)
		setIsMessageEditting(true)
	}, [logRecord])

	const handleCancelEditMessage = useCallback(() => {
		setIsMessageEditting(false)
	}, [])

	const handleMessageDraftChanged = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
		setMessageDraft(event.target.value)
	}, [])

	const handleSaveMessage = useCallback(async () => {
		try {
			await updateRecord({ message: messageDraft }, logRecord.id)
			await loadRecordList()
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Ошибка при редактировании журнала инцидента',
				content: await getErrorMessage(e),
			})
		} finally {
			setIsMessageEditting(false)
		}
	}, [messageDraft, logRecord, updateRecord, loadRecordList, showModalDialog])

	return (
		<tr key={logRecord.id}>
			<td>{index + 1}</td>
			<td>{logRecord.createdDatetime.toLocaleString()}</td>
			<td>
				{logRecord.createdByUser
					? <>
						{logRecord.createdByUser.firstName}
						{' '}
						{logRecord.createdByUser.lastName}
					</>
					: <span className='text-muted'>Система</span>}
			</td>
			<td>{logRecord.fromStage?.name}</td>
			<td>{logRecord.toStage?.name}</td>
			<td>{isMessageEditting
				? <>
					<textarea
						className='form-control'
						rows={4}
						value={messageDraft}
						onChange={handleMessageDraftChanged} />
					<button
						className='btn btn-sm btn-outline-primary my-2'
						onClick={handleSaveMessage}>
						Сохранить
					</button>
					<button
						className='btn btn-sm btn-outline-secondary my-2 ms-2'
						onClick={handleCancelEditMessage}>
						Отмена
					</button>
				</>
				: <>
					<IncidentMessage>
						{logRecord.message}
					</IncidentMessage>
					{+logRecord.createdDatetime !== +logRecord.updatedDatetime &&
						<small className='text-muted me-3'>
							Ред. {logRecord.updatedDatetime.toLocaleString()}
						</small>
					}
					{isEditable
						&& user?.id === logRecord.createdByUser?.id
						&&
						<button
							className='btn btn-sm btn-link p-0 text-nowrap float-end'
							onClick={handleEditMessage}>
							<FontAwesomeIcon icon={faPenToSquare} className='me-2' />
							Редактировать
						</button>}
				</>}
			</td>
		</tr>
	)
}
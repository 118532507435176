import { ICctv, IProjectField, IRecordWithId, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { routes } from '@/config/routes'
import { NavLink } from 'react-router-dom'
import { cctvStatusLabels } from '@/config/status/labels'
import { cctvHardwareLabels } from '@/config/hardware'


export const ProjectsCctvTableColumnNameList = [
  'number',
  'type',
  'contract',
  'channelStatus',
  'installationStatus',
  'acceptanceStatus',
  'idAcceptanceStatus',
  'installationPassivePartStatus',
  'addressStorageId',
  'addressArea',
  'addressDistrict',
  'address',
  'ipCameraList',
  'ipCamerasState',
  'contractIpCameraCount',
  'ipCameraCount',
  'videoServerList',
  'videoServersState',
  'videoNetworkSwitchList',
  'videoNetworkSwitchesState',
  'communicationNetworkSwitchList',
  'communicationNetworkSwitchesState',
  'upsList',
  'upsesState',
  'accessPointList',
  'accessPointsState',
  'channelOperator',
  'channelOperatorDescription',
  'channelTechnology',
  'constructionContractor',
  'constructionContractorDescription',
  'maintenanceContractor',
  'maintenanceContractorDescription',
  'incidents',
  'filesState',
  'referencesSnapshotsState',
] as const

export type ProjectsCctvTableColumnName = typeof ProjectsCctvTableColumnNameList[number]

const getHardwareIpAddressesValue = (hardware: { id: IRecordWithId['id'], ipAddress: string | null }[]) =>
  hardware.map(h => <div key={h.id}>{h.ipAddress}</div>)

export const ProjectsCctvTableColumns: Record<ProjectsCctvTableColumnName, TableColumnConfig<ICctv>> = {
  number: {
    label: 'Номер ТВН',
    value: cctv => <NavLink to={routes.projectCctv(cctv.project.id, cctv.id)}>{cctv.number}</NavLink>,
    filterKey: 'number',
    sortKey: 'number',
  },
  type: {
    label: 'Тип ТВН',
    value: cctv => cctv.type.name,
    filterKey: 'type.name',
    filterExistsKey: 'type',
    sortKey: 'type.name',
  },
  contract: {
    label: 'Номер ГК',
    value: cctv => cctv.contract?.number,
    filterKey: 'contract.number',
    filterExistsKey: 'contract',
    sortKey: 'contract.number',
    permissions: 'cctvContractRead',
  },
  channelStatus: {
    label: cctvStatusLabels.channelStatus,
    value: cctv => cctv.channelStatus.name,
    colorValue: cctv => cctv.channelStatus.code,
    filterKey: 'channelStatus.name',
    filterExistsKey: 'channelStatus',
    sortKey: 'channelStatus.orderNumber',
  },
  installationStatus: {
    label: cctvStatusLabels.installationStatus,
    value: cctv => cctv.installationStatus.name,
    colorValue: cctv => cctv.installationStatus.code,
    filterKey: 'installationStatus.name',
    filterExistsKey: 'installationStatus',
    sortKey: 'installationStatus.orderNumber',
  },
  acceptanceStatus: {
    label: cctvStatusLabels.acceptanceStatus,
    value: cctv => cctv.acceptanceStatus.name,
    colorValue: cctv => cctv.acceptanceStatus.code,
    filterKey: 'acceptanceStatus.name',
    filterExistsKey: 'acceptanceStatus',
    sortKey: 'acceptanceStatus.orderNumber',
  },
  idAcceptanceStatus: {
    label: cctvStatusLabels.idAcceptanceStatus,
    value: cctv => cctv.idAcceptanceStatus.name,
    colorValue: cctv => cctv.idAcceptanceStatus.code,
    filterKey: 'idAcceptanceStatus.name',
    filterExistsKey: 'idAcceptanceStatus',
    sortKey: 'idAcceptanceStatus.orderNumber',
  },
  installationPassivePartStatus: {
    label: cctvStatusLabels.installationPassivePartStatus,
    value: cctv => cctv.installationPassivePartStatus.name,
    colorValue: cctv => cctv.installationPassivePartStatus.code,
    filterKey: 'installationPassivePartStatus.name',
    filterExistsKey: 'installationPassivePartStatus',
    sortKey: 'installationPassivePartStatus.orderNumber',
  },
  addressStorageId: {
    label: 'ID адреса ЕЦХД',
    value: cctv => cctv.address.storageId,
    filterKey: 'address.storageId',
    filterExistsKey: 'address',
    sortKey: 'address.storageId',
  },
  addressArea: {
    label: 'Округ',
    value: cctv => cctv.address.district.area.name,
    filterKey: 'address.district.area.name',
    filterExistsKey: 'address',
    sortKey: 'address.district.area.name',
  },
  addressDistrict: {
    label: 'Район',
    value: cctv => cctv.address.district.name,
    filterKey: 'address.district.name',
    filterExistsKey: 'address',
    sortKey: 'address.district.name',
  },
  address: {
    label: 'Адрес',
    value: cctv => cctv.address.name,
    filterKey: 'address.name',
    filterExistsKey: 'address',
    sortKey: 'address.name',
  },
  ipCameraList: {
    label: 'IP-камеры',
    value: cctv => getHardwareIpAddressesValue(cctv.ipCameras),
    filterKey: 'ipCamerasMapEntries.ipCamera.networkHost.ipAddress',
    filterExistsKey: 'ipCamera',
    sortKey: 'ipCamerasMapEntries.ipCamera.networkHost.ipAddress',
    type: 'ipAddressExists',
  },
  ipCamerasState: {
    label: 'Состояние IP-камер',
    value: cctv => cctv.ipCamerasState.name,
    colorValue: cctv => cctv.ipCamerasState.code,
    filterAndSortViewKey: 'view_cctvs:ipCamerasState',
  },
  contractIpCameraCount: {
    label: 'Кол-во камер по ГК',
    value: cctv => cctv.contractIpCamerasNumber,
    filterKey: 'contractIpCamerasNumber',
    sortKey: 'contractIpCamerasNumber',
  },
  ipCameraCount: {
    label: 'Кол-во камер на ТВН',
    value: cctv => cctv.ipCamerasState.count,
    filterAndSortViewKey: 'view_cctvs:ipCamerasCount',
  },
  videoServerList: {
    label: cctvHardwareLabels.videoServers,
    value: cctv => getHardwareIpAddressesValue(cctv.videoServers),
    filterKey: 'videoServers.networkHost.ipAddress',
    filterExistsKey: 'videoServer',
    sortKey: 'videoServers.networkHost.ipAddress',
    type: 'ipAddressExists',
  },
  videoServersState: {
    label: 'Состояние видеосерверов',
    value: cctv => cctv.videoServersState.name,
    colorValue: cctv => cctv.videoServersState.code,
    filterAndSortViewKey: 'view_cctvs:videoServersState',
  },
  videoNetworkSwitchList: {
    label: cctvHardwareLabels.videoNetworkSwitches,
    value: cctv => getHardwareIpAddressesValue(cctv.videoNetworkSwitches),
    filterAndSortViewKey: 'view_cctvs:videoNetworkSwitchesIpAddresses',
  },
  videoNetworkSwitchesState: {
    label: 'Состояние коммутаторов видео',
    value: cctv => cctv.videoNetworkSwitchesState.name,
    colorValue: cctv => cctv.videoNetworkSwitchesState.code,
    filterAndSortViewKey: 'view_cctvs:videoNetworkSwitchesState',
  },
  communicationNetworkSwitchList: {
    label: cctvHardwareLabels.communicationNetworkSwitches,
    value: cctv => getHardwareIpAddressesValue(cctv.communicationNetworkSwitches),
    filterAndSortViewKey: 'view_cctvs:communicationNetworkSwitchesIpAddresses',
  },
  communicationNetworkSwitchesState: {
    label: 'Состояние коммутаторов связи',
    value: cctv => cctv.communicationNetworkSwitchesState.name,
    colorValue: cctv => cctv.communicationNetworkSwitchesState.code,
    filterAndSortViewKey: 'view_cctvs:communicationNetworkSwitchesState',
  },
  upsList: {
    label: cctvHardwareLabels.upses,
    value: cctv => getHardwareIpAddressesValue(cctv.upses),
    filterKey: 'upses.networkHost.ipAddress',
    filterExistsKey: 'ups',
    sortKey: 'upses.networkHost.ipAddress',
    type: 'ipAddressExists',
  },
  upsesState: {
    label: 'Состояние ИБП',
    value: cctv => cctv.upsesState.name,
    colorValue: cctv => cctv.upsesState.code,
    filterAndSortViewKey: 'view_cctvs:upsesState',
  },
  accessPointList: {
    label: cctvHardwareLabels.accessPoints,
    value: cctv => getHardwareIpAddressesValue(cctv.accessPoints),
    filterKey: 'accessPoints.networkHost.ipAddress',
    filterExistsKey: 'accessPoint',
    sortKey: 'accessPoints.networkHost.ipAddress',
    type: 'ipAddressExists',
  },
  accessPointsState: {
    label: 'Состояние точек доступа',
    value: cctv => cctv.accessPointsState.name,
    colorValue: cctv => cctv.accessPointsState.code,
    filterAndSortViewKey: 'view_cctvs:accessPointsState',
  },
  channelOperator: {
    label: 'Оператор канала',
    value: cctv => cctv.channelOperator?.name,
    filterKey: 'channelOperator.name',
    filterExistsKey: 'channelOperator',
    sortKey: 'channelOperator.name',
  },
  channelOperatorDescription: {
    label: 'Данные по каналу, контакты тех. поддержки',
    value: cctv => cctv.channelOperatorDescription,
    filterKey: 'channelOperatorDescription',
    sortKey: 'channelOperatorDescription',
  },
  channelTechnology: {
    label: 'Технология канала',
    value: cctv => cctv.channelTechnology?.name,
    filterKey: 'channelTechnology.name',
    filterExistsKey: 'channelTechnology',
    sortKey: 'channelTechnology.name',
  },
  constructionContractor: {
    label: 'Подрядчик СМР',
    value: cctv => cctv.constructionContractor?.name,
    filterKey: 'constructionContractor.name',
    filterExistsKey: 'constructionContractor',
    sortKey: 'constructionContractor.name',
  },
  constructionContractorDescription: {
    label: 'Ответственный от подрядчика СМР',
    value: cctv => cctv.constructionContractorDescription,
    filterKey: 'constructionContractorDescription',
    sortKey: 'constructionContractorDescription',
  },
  maintenanceContractor: {
    label: 'Подрядчик эксплуатации',
    value: cctv => cctv.maintenanceContractor?.name,
    filterKey: 'maintenanceContractor.name',
    filterExistsKey: 'maintenanceContractor',
    sortKey: 'maintenanceContractor.name',
  },
  maintenanceContractorDescription: {
    label: 'Ответственный от подрядчика экспл.',
    value: cctv => cctv.maintenanceContractorDescription,
    filterKey: 'maintenanceContractorDescription',
    sortKey: 'maintenanceContractorDescription',
  },
  incidents: {
    label: 'TT',
    value: cctv => cctv.incidents.length ? 'Есть' : 'Нет', // displays <IncidentList /> (custom col)
    colorValue: cctv => cctv.incidents.length ? 'no' : 'yes',
    filterAndSortViewKey: 'view_cctvs:incidents',
  },
  filesState: {
    label: 'Прикрепленные файлы',
    value: cctv => cctv.filesState.name,
    colorValue: cctv => cctv.filesState.code,
    filterKey: 'filesState.name',
    sortKey: 'filesState.name',
    filterExistsKey: 'filesMapEntries',
    type: 'boolExists',
  },
  referencesSnapshotsState: {
    label: 'Эталонные снимки',
    value: cctv => cctv.referencesSnapshotsState.name,
    colorValue: cctv => cctv.referencesSnapshotsState.code,
    filterAndSortViewKey: 'view_cctvs:referencesSnapshotsState',
  },
}

export const prepareProjectsCctvAdditionalCols = (
  cctv: ICctv,
  projectFieldList: IProjectField[]
): TableAdditionalColumnConfig[] =>
  projectFieldList.map(({ id, name }) => ({
    filterKey: `projectFields/${id}`,
    sortKey: `orderProjectFields/${id}`,
    label: name,
    value: cctv.projectFields.find(pf => pf.id === id)?.value ?? '',
  } satisfies TableAdditionalColumnConfig))

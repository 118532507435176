import { IMonitoringCctv } from '@/context/types'
import { TableApiResourceContextValue } from '@/context/tableApiResource/TableApiResourceContext'
import IncidentLink from '@/components/modals/incidents/IncidentLink'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUmbrella } from '@fortawesome/free-solid-svg-icons'
import Stopwatch from '@/components/common/time/Stopwatch'


type Props = {
  incidents: IMonitoringCctv['incidents']
  onUpdate: TableApiResourceContextValue<any, any>['loadRecordList']
}

const MonitoringIncidentList = ({ incidents, onUpdate }: Props) => {
  const { projectId } = useParams()

  if (!incidents.length)
    return <>Нет</>

  return (
    <>
      Есть:
      {incidents.map(({ incident }) =>
        <div key={incident.id} className='text-nowrap'>
          <IncidentLink projectId={projectId} incidentId={incident.id} onUpdate={onUpdate} />
          {!!incident.children?.length &&
            <>
              {' '}
              <FontAwesomeIcon icon={faUmbrella} />
            </>
          }
          {' ('}
          {incident.origin
            ? incident.origin.name
            : incident.createdByUser ? 'Польз.' : 'СМО'
          }
          {'). '}
          {incident.hardware.type.tag}
          {': '}
          {incident.stage?.name}
          {': '}
          <span className='text-muted'>
            <Stopwatch initialDatetime={incident.updatedDatetime} />
          </span>
        </div>,
      )}
    </>
  )
}

export default MonitoringIncidentList

import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import getErrorMessage from '@/utils/getErrorMessage'
import defaultRecordToOption from '@/utils/recordToSelectOption'
import { getApiResourceListRoute } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import useDeepCompareCallback from '@/hooks/useDeepCompareCallback'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  ApiResourceSelectOptionListFailedAction, apiResourceSelectOptionListFetchedAction,
  apiResourceSelectOptionListLoadedAction, ApiResourceSelectOptionListState, useApiResourceSelectOptionListReducer,
} from '@/context/form/apiResourceSelectOptionListStore'
import { ApiResource, ApiResourceFilter, IRecord, ISelectOption } from '@/context/types'
import { ApiResources } from '@/config/apiResources'
import sortToParams from '@/utils/paramConverters/sortToParams'


/** API resource option list context interface */
interface ApiResourceOptionListContextValue extends ApiResourceSelectOptionListState {
  loadApiResourceOptionList: () => Promise<void>
  searchApiResourceOptionlist: (search: string) => Promise<ISelectOption[]>
}


/** API resource option list contexts */
const ApiResourceOptionListContext = createContext({} as ApiResourceOptionListContextValue)


/** API resource option list context hook */
export const useApiResourceOptionListContext = () => useContext(ApiResourceOptionListContext)


type Props<TRecord extends IRecord> = PropsWithChildren<{
  apiResource: ApiResource
  filter?: ApiResourceFilter
  load?: boolean
  recordToOption?: (record: TRecord) => ISelectOption
}>


/** API resource option list context default filter */
const defaultFilter = {}


/** API resource option list context provider */
export function ApiResourceOptionListContextProvider<TRecord extends IRecord>({
  apiResource,
  children,
  filter = defaultFilter,
  load = false,
  recordToOption = defaultRecordToOption
}: Props<TRecord>) {
  const [state, dispatch] = useApiResourceSelectOptionListReducer()

  const sort = ApiResources[apiResource]?.selectSort

  /** Load select option list */
  const loadApiResourceOptionList = useDeepCompareCallback(async () => {
    dispatch(apiResourceSelectOptionListFetchedAction())
    try {
      const response = await axios.get(
        getApiResourceListRoute(apiResource),
        {
          params: {
            pagination: false,
            ...filter,
            ...sortToParams(`order[${sort?.field}]`, sort?.direction),
            groups: ['list_short:read'],
          },
        },
      )
      const recordList: TRecord[] = response.data
      const optionList = recordList.map(recordToOption)
      dispatch(apiResourceSelectOptionListLoadedAction(optionList))
    } catch (e: any) {
      dispatch(ApiResourceSelectOptionListFailedAction(await getErrorMessage(e)))
    }
  }, [filter, apiResource, sort, recordToOption, dispatch])

  /** Search and load select option list */
  const searchApiResourceOptionlist = useDeepCompareCallback(async (search: string) => {
    dispatch(apiResourceSelectOptionListFetchedAction())
    try {
      const response = await axios.get(
        getApiResourceListRoute(apiResource),
        {
          params: {
            search,
            ...filter,
            ...sortToParams(`order[${sort?.field}]`, sort?.direction),
            groups: ['list_short:read'],
          },
        },
      )
      const recordList: TRecord[] = response.data
      const optionList = recordList.map(recordToOption)
      dispatch(apiResourceSelectOptionListLoadedAction(optionList))
      return optionList
    } catch (e: any) {
      dispatch(ApiResourceSelectOptionListFailedAction(await getErrorMessage(e)))
      return []
    }
  }, [filter, apiResource, sort, recordToOption, dispatch])

  // Инициализация и обновление
  useEffect(() => {
    if (load) loadApiResourceOptionList()
  }, [load, loadApiResourceOptionList])

  const value: ApiResourceOptionListContextValue = useMemo(() => ({
    ...state,
    loadApiResourceOptionList,
    searchApiResourceOptionlist,
  }), [state, loadApiResourceOptionList, searchApiResourceOptionlist])

  useStorageStateLog(apiResource, state)

  return (
    <ApiResourceOptionListContext.Provider value={value}>
      {children ?? <Outlet />}
    </ApiResourceOptionListContext.Provider>
  )
}
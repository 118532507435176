import { IChannel, IStatusStage } from '@/context/types'
import { useAuthContext } from '@/context/auth/AuthContext'
import ChannelStatusControlMenuItem from '@/components/channels/Folder/statuses/ChannelStatusControlMenuItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


type Props = {
  channel: IChannel
  statusFieldName: ChannelStatusKey
  statusList: IStatusStage[]
  statusName: string
  reloadCard: () => void
}

export default function ChannelStatusControlMenu({
  channel,
  statusFieldName,
  statusList,
  statusName,
  reloadCard,
}: Props) {
  const { hasPermission } = useAuthContext()

  if (!statusList.length || !hasPermission('channelStageEdit'))
    return <>{statusName}</>

  return (
    <div className='dropdown' style={{ position: 'static', zIndex: 1 }}>
      <button
        className='btn btn-link p-0 text-decoration-none'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        {statusName}
        <FontAwesomeIcon icon={faCaretDown} className='ms-2' />
      </button>
      <ul
        className='dropdown-menu dropdown-menu-end'
        aria-labelledby='dropdownMenuButton'
      >
        {statusList.map(status =>
          <li key={status.code}>
            <ChannelStatusControlMenuItem
              channel={channel}
              status={status}
              statusFieldName={statusFieldName}
              reloadCard={reloadCard}
            />
          </li>,
        )}
      </ul>
    </div>
  )
}

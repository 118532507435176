import { ipCameraTableRows } from '@/config/tables/cards/hardware/ipCamera'
import { copyObjectByKeys } from '@/utils/tools'


export const ipCameraStreamLeftTableRows = copyObjectByKeys(ipCameraTableRows, [
  'ipAddress',
  'subnetwork',
  'model',
  'preset',
  'orderNumberOnCctv',
  'hasSoundEnabled',
  'firmware',
  'status',
])

export const ipCameraStreamRightTableRows = copyObjectByKeys(ipCameraTableRows, [
  'id',
  'videoMarkerCode',
  'storageIdOnProject',
  'storageVsmKeyOnProject',
  'networkSwitch',
  'codeOnCctv',
  'address',
  'locationDescription',
  'snapShotKeeper',
])

import PecsCommonStat from '@/components/audit/stats/PecsCommonStat'
import PecsStatusStat from '@/components/audit/stats/PecsStatusStat'
import { IPecStatusField } from '@/context/types'
import PecsSliceFinishedStat from '@/components/audit/stats/PecsSliceFinishedStat'
import PecsSliceStatusStat from '@/components/audit/stats/PecsSliceStatusStat'


export enum StatType {
  common = 'common',
  status = 'status',
  slice = 'slice',
}

type Props = {
  type: StatType
  pecStatusFieldId: IPecStatusField['id']
}

const PecsStatWrapper = ({ type, pecStatusFieldId }: Props) => {
  switch (type) {
    case StatType.common:
      return <PecsCommonStat />
    case StatType.status:
      return <PecsStatusStat pecStatusFieldId={pecStatusFieldId} />
    case StatType.slice:
      return (
        <>
          <PecsSliceFinishedStat pecStatusFieldId={pecStatusFieldId} />
          <PecsSliceStatusStat pecStatusFieldId={pecStatusFieldId} />
        </>
      )
  }
}

export default PecsStatWrapper

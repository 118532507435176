import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


type Props = {
	label: string
	icon?: IconDefinition
	to: string
	disabled?: boolean
}


/** Элемент выпадающего меню. Внешнаяя ссылка */
export default function ToolbarMenuDownloadLink({ label, icon, to, disabled = false }: Props) {
	return (
		<li>
			<a
				className={`dropdown-item ${disabled && 'disabled'}`}
				href={to}
				target='_blank'
				rel='noreferrer'>
				{icon &&
					<div className='d-inline-block me-2' style={{ width: '16px' }}>
						<FontAwesomeIcon icon={icon} />
					</div>}
				{label}
			</a>
		</li>
	)
}

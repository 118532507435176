import { videoServerTableRows } from '@/config/tables/cards/hardware/videoServer'
import { copyObjectByKeys } from '@/utils/tools'


export const videoServerStreamLeftTableRows = copyObjectByKeys(videoServerTableRows, [
  'ipAddress',
  'subnetwork',
  'model',
  'preset',
  'firmware',
])

export const videoServerStreamRightTableRows = copyObjectByKeys(videoServerTableRows, [
  'status',
  'id',
  'networkSwitch',
  'address',
  'locationDescription',
])

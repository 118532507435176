import { useMemo, useRef, useState } from 'react'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { ApiResource, IAddress, ICctv, ICctvForm, ICctvType, IChannelTechnology, IContract } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { apiRoutes } from '@/config/routes'
import { useModal } from '@/context/ModalContext'
import { useFormContext } from '@/context/form/FormContext'
import { useAnyFormContext } from '@/context/anyForm/AnyFormContext'
import { useProjectFieldListContext } from '@/context/entityFields/ProjectFieldListContext'
import AnyTextInput from '@/components/common/form/AnyTextInput'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import NumberInput from '@/components/common/form/NumberInput'
import TextInput from '@/components/common/form/TextInput'
import { addressToSelectOption, recordToSelectOptionNumberLabel } from '@/utils/recordToSelectOption'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserRole } from '@/config/roles'
import { cctvHardwareKeys } from '@/config/hardware'
import { channelOperatorOrganizationListFilter, contractorOrganizationListFilter } from '@/config/listFilters'


export type Props = {
  projectId: string
  onSave: (cctvId: ICctv['id']) => any
  cctv?: ICctv
}

/** Форма создания/редактирования инфо ТВН */
export default function CctvCreateEditForm({ cctv, onSave, projectId }: Props) {
  const { showModalDialog, usePrimaryCallback } = useModal()
  const { recordList: projectFieldList } = useProjectFieldListContext()
  const { form, setError } = useFormContext<ICctvForm>()
  const { form: projectFields } = useAnyFormContext()
  const { hasRole } = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef<HTMLDivElement>(null)

  const cctvTypeListFilter = useMemo(() => ({ 'project.id': projectId! }), [projectId])
  const cctvContractListFilter = useMemo(() => ({ 'projects.id': projectId! }), [projectId])

  const cctvProjectFieldList = useMemo(() => {
    var cctvProjectFieldList: Record<string, string> = {}
    cctv?.projectFields.forEach((projectField) => {
      cctvProjectFieldList[projectField.id] = projectField.value
    })
    return cctvProjectFieldList
  }, [cctv])

  usePrimaryCallback(async () => {
    if (!form.typeId) setError('typeId', 'Выберите тип ТВН')
    if (!form.addressId) setError('addressId', 'Укажите адрес ТВН')
    if (!cctv && !form.number) setError('number', 'Укажите номер ТВН')
    if (!form.contractIpCamerasNumber)
      setError('contractIpCamerasNumber', 'Укажите количество камер')

    if (!form.typeId || !form.addressId || (!cctv && !form.number) || !form.contractIpCamerasNumber) {
      formRef.current?.scrollIntoView()
      return false
    }

    try {
      setIsLoading(true)
      const data = { projectFields }

      if (hasRole(UserRole.admin))
        Object.assign(data, form)

      if (cctv) {
        await axios.patch(
          apiRoutes.cctv(cctv.id),
          data,
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )
        onSave(cctv.id)
      } else {
        const res = await axios.post(apiRoutes.cctvs, {
          ...form,
          projectId,
          channelStatus: 'not_started',
          installationStatus: 'not_started',
          acceptanceStatus: 'not_started',
          idAcceptanceStatus: 'not_started',
          installationPassivePartStatus: 'not_started',
          auditAddress: false,
          auditLocation: false,
          auditMonitor: false,
          auditUps: false,
          auditLocalsConsoles: false,
          auditNvr: false,
          auditNetworkSwitch: false,
          auditIpCamera1: false,
          auditIpCamera2: false,
          projectFields,
        })
        onSave(res.data.id)
      }
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `${cctv ? 'Обновление' : 'Добавление'} ТВН: Ошибка`,
        content: await getErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }, [form, projectFields, projectId, showModalDialog])

  const hasHardware = cctv && cctvHardwareKeys.some(k => cctv[k].length)

  return (
    <div ref={formRef} className='w-100 p-3'>
      <ApiResourceSelect<ICctvType, ICctvForm>
        apiResource={ApiResource.CctvType}
        filter={cctvTypeListFilter}
        label='Тип ТВН'
        name='typeId'
        required
        initValue={cctv?.type}
        disabled={isLoading || hasHardware || !hasRole(UserRole.admin)}
      />
      {hasRole([UserRole.admin, UserRole.sitronics]) && (
        <ApiResourceSelect<IContract, ICctvForm>
          apiResource={ApiResource.Contract}
          recordToOption={recordToSelectOptionNumberLabel}
          filter={cctvContractListFilter}
          label='Номер ГК'
          name='contract'
          disabled={isLoading || !hasRole(UserRole.admin)}
        />
      )}
      <ApiResourceSelect<IAddress, ICctvForm>
        apiResource={ApiResource.Address}
        async
        label='Адрес ТВН'
        name='addressId'
        required
        initValue={cctv?.address}
        recordToOption={addressToSelectOption}
        disabled={isLoading || hasHardware || !hasRole(UserRole.admin)}
      />
      {!cctv &&
        <TextInput<ICctvForm>
          label='Номер ТВН'
          name='number'
          required
          disabled={isLoading}
        />
      }
      <NumberInput<ICctvForm>
        label='Количество камер по ГК'
        name='contractIpCamerasNumber'
        required
        initValue={cctv?.contractIpCamerasNumber}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      <ApiResourceSelect<IChannelTechnology, ICctvForm>
        apiResource={ApiResource.ChannelTechnology}
        label='Технология канала'
        name='channelTechnologyId'
        initValue={cctv?.channelTechnology}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      <ApiResourceSelect<NonNullable<ICctv['channelOperator']>, ICctvForm>
        apiResource={ApiResource.Organization}
        filter={channelOperatorOrganizationListFilter}
        label='Оператор канала связи'
        name='channelOperatorId'
        initValue={cctv?.channelOperator}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      <TextInput<ICctvForm>
        label='Данные по каналу, контакты тех. поддержки'
        name='channelOperatorDescription'
        initValue={cctv?.channelOperatorDescription}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      <ApiResourceSelect<NonNullable<ICctv['constructionContractor']>, ICctvForm>
        apiResource={ApiResource.Organization}
        filter={contractorOrganizationListFilter}
        label='Подрядчик СМР'
        name='constructionContractorId'
        initValue={cctv?.constructionContractor}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      <TextInput<ICctvForm>
        label='Отв. от подрядчика СМР'
        name='constructionContractorDescription'
        initValue={cctv?.constructionContractorDescription}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      <ApiResourceSelect<NonNullable<ICctv['maintenanceContractor']>, ICctvForm>
        apiResource={ApiResource.Organization}
        filter={contractorOrganizationListFilter}
        label='Подрядчик экспл.'
        name='maintenanceContractorId'
        initValue={cctv?.maintenanceContractor}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      <TextInput<ICctvForm>
        label='Отв. от подрядчика экспл.'
        name='maintenanceContractorDescription'
        initValue={cctv?.maintenanceContractorDescription}
        disabled={isLoading || !hasRole(UserRole.admin)}
      />
      {!!projectFieldList.length &&
        <h3 className='mt-5'>Дополнительная информация</h3>
      }
      {projectFieldList.map(projectField =>
        <AnyTextInput
          key={projectField.id}
          label={projectField.name}
          name={projectField.id}
          initValue={cctvProjectFieldList[projectField.id]}
        />,
      )}
    </div>
  )
}

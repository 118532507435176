import { useCallback } from 'react'
import { Hardware } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { hardwareIncidentListModal } from '@/components/modals/incidents/modals'
import HardwareCardIncidentListItem
  from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardware/HardwareCardIncidentListItem'
import { useParams } from 'react-router-dom'


type Props = {
	hardware: Hardware
	reloadCctv: () => Promise<void>
}


export default function HardwareCardIncidentList({ hardware, reloadCctv }: Props) {
	const { showModalDialog } = useModal()
	const { projectId } = useParams()

	const handleOpenIncidentList = useCallback(() => {
		showModalDialog(hardwareIncidentListModal(projectId, hardware, reloadCctv))
	}, [hardware, reloadCctv, showModalDialog, projectId])

	return (
		<tr className='border-top'>
			<td className='text-muted ps-2'>
				Инциденты
			</td>
			<td>
				{hardware.openIncidents?.map(incident =>
					<HardwareCardIncidentListItem
						key={incident.id}
						incident={incident}
						reloadCctv={reloadCctv} />)}
				<button
					className='btn btn-link p-0'
					onClick={handleOpenIncidentList}>
					Все инциденты
				</button>
				{' '}
				за последние 90 дней
			</td>
		</tr >
	)
}

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ICctv } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** CctvAcceptanceStatus context */
const CctvAcceptanceStatusListContext = createContext({} as IRecordListContext<ICctv['acceptanceStatus']>)


/** CctvAcceptanceStatus context hook */
export const useCctvAcceptanceStatusListContext = () => useContext(CctvAcceptanceStatusListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** CctvAcceptanceStatus context provider */
export function CctvAcceptanceStatusListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<ICctv['acceptanceStatus']>
      Context={CctvAcceptanceStatusListContext}
      listApiRoute={apiRoutes.cctvAcceptanceStatuses}
      deleteApiRoute={apiRoutes.cctvAcceptanceStatus}
      paginate={paginate}
      logName='cctvAcceptanceStatusList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
import { ChangeEvent, FormEvent, MouseEvent, useRef, useState } from 'react'
import style from '@/assets/styles/card.module.scss'
import cn from 'classnames'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { fetchData } from '@/api/fetchData'
import { apiRoutes, apiURL } from '@/config/routes'
import { Modal } from '@/components/common/Modal/Modal'
import { ICctv, ICctvFileType } from '@/context/types'
import { postData } from '@/api/postData'
import { errorHandle } from '@/utils/errorHandle'
import RoleRequired from '@/components/auth/RoleRequired'
import { UserRole } from '@/config/roles'
import { instanceAxios as axios } from '@/api/instanceAxios'
import SmallRoundBtn from '@/components/common/btns/SmallRoundBtn'
import { useModal } from '@/context/ModalContext'
import { imagesGalleryModal } from '@/components/modals/cctv/modals'
import { useAuthContext } from '@/context/auth/AuthContext'
import DeleteFileBtn from '@/components/common/btns/DeleteFileBtn'


type Props = {
  cctv: ICctv
  reloadCctv: () => Promise<void>
}
interface IProgress {
  [key: string]: number
}
export default function CctvAttachedFiles({ cctv, reloadCctv }: Props) {
  const form = useRef<HTMLFormElement>(null)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [progresses, setProgresses] = useState<IProgress>({})
  const [filesTypes, setFilesTypes] = useState<ICctvFileType[]>([])
  const [idFileType, setIdFileType] = useState('')
  const [openForm, setOpenForm] = useState(false)
  const { hasRole } = useAuthContext()
  const { showModalDialog } = useModal()

  const handleClickForm = () => {
    fetchData({
      url: `${apiURL}/cctv-file-types`,
      setData: (data) => setFilesTypes(data),
      errorHandler: (err) => errorHandle(err),
    })
    setOpenForm(true)
  }
  const handleChangeFileType = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setIdFileType(value)
  }
  const handleClickFileInput = (e: any) => {
    setSelectedFiles(e.target.files)
  }
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!idFileType) {
      alert('Нужно выбрать тип файла')
    return
    }
    try {
      await Promise.allSettled([
        ...Array.from(selectedFiles).map(async (file, index) => {
          const formData = new FormData()
          formData.append('file', file)
          await axios.post(
            apiRoutes.uploadCctvFile(cctv!.id, idFileType),
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent
                const percent = Math.floor((loaded * 100) / total)
                setProgresses((prev) => {
                  return { ...prev, [index]: percent }
                })
              },
            },
          )
        }),
      ])
      reloadCctv()
      setOpenForm(false)
      form.current && form.current.reset()
    } catch (e: any) {
      errorHandle(e)
      setOpenForm(false)
      form.current && form.current.reset()
    }
  }

  const canDelete = hasRole([UserRole.admin, UserRole.mgts, UserRole.contractor])

  const handleClickFile = (e: MouseEvent<HTMLAnchorElement>, file: ICctv['files'][0]) => {
    const imageFiles = cctv.files.filter(file => {
      const extension = (file.isExternal ? file.url : file.name).split('.').pop()
  
      return extension && ['jpg', 'jpeg', 'png', 'webp', 'gif', 'bmp'].includes(extension)
    })
    const params = imagesGalleryModal(cctv, imageFiles, file, canDelete, reloadCctv)

    if (!params)
      return

    e.preventDefault()

    showModalDialog(params)
  }

  return (
    <div className='card border-0 mb-2'>
      <Modal
        open={openForm}
        setOpen={setOpenForm}
        title='Добавление файла'
        backGCTitle='#d8f5a2'
        formClean={form}
      >
        <div className='container-fluid'>
          <form onSubmit={handleSubmit} ref={form}>
            <div className='row mb-2 mt-2'>
              <select
                className='form-select'
                aria-label='Default select example'
                onChange={handleChangeFileType}
              >
                <option value={undefined}>Выберите тип файла...</option>
                {filesTypes.map((fileType) => (
                  <option key={fileType.id} value={fileType.id}>
                    {fileType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='row mb-2'>
              <div className='col'>
                <input
                  className='form-control'
                  type='file'
                  onChange={handleClickFileInput}
                  multiple
                  accept='.pdf, .doc, .docx, .xls, .xlsx, .txt, .xml, .webp, .jpeg, .jpg, .png, .gif, .bmp, .jfif, .ini'
                  id='formFile'
                />
              </div>
              <div className='col'>
                <button className='btn btn-primary' type='submit'>
                  Отправить
                </button>
              </div>
            </div>
            <div>
              {Array.from(selectedFiles).map((file, index) => (
                <div key={index}>
                  <div>{file.name}</div>
                  <div className='progress'>
                    <div
                      className='progress-bar progress-bar-striped progress-bar-animated'
                      role='progressbar'
                      aria-valuenow={progresses[index]}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `${progresses[index]}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </form>
        </div>
      </Modal>
      <div
        className={`card-header border-0 px-2 py-1 justify-content-between ${cn(
          style.cardHeader,
          style.cardHeaderAttachedFiles,
        )} `}
      >
        <div>
          <button
            className={`btn text-primary p-0 ${style.cardHeaderArrow}`}
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseCctvAttachedFiles'
            aria-expanded='false'
            aria-controls='collapseCctvAttachedFiles'
          >
            <b>Приложенные файлы ТВН-{cctv.number}</b>
          </button>
        </div>
        <RoleRequired roleCodes={[UserRole.admin, UserRole.mgts, UserRole.contractor]}>
          <SmallRoundBtn icon={faPlusSquare} tooltip='Добавить файлы' onClick={handleClickForm} />
        </RoleRequired>
      </div>
      <div className='collapse' id='collapseCctvAttachedFiles'>
        <div
          className={`card-body border-0 ${cn(
            style.cardBody,
            style.cardBodyAttachedFiles,
            style.cctvInfoCollapse,
          )} p-0`}
        >
          <table className={`table-hover table-sm mb-0 ${style.table}`}>
            <thead>
              <tr className='text-muted '>
                {['Файл', 'Тип документа', 'Размер', 'Загружен'].map(header => (
                  <th key={header} className='pr-2 fw-normal'>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='align-middle'>
              {cctv.files?.map((file, index) => (
                <tr key={index}>
                  <td className='pr-2 fw-normal'>
                    <a
                      href={file.path}
                      target='_blank'
                      rel='noreferrer'
                      onClick={e => handleClickFile(e, file)}
                    >
                      {file.name}
                    </a>
                  </td>
                  <td className='pr-2 fw-normal'>{file.type.name}</td>
                  <td className='pr-2 fw-normal'>
                    {file.isExternal
                      ? '-'
                      : `${(+file.size / 1024).toLocaleString()} KБ`
                    }
                  </td>
                  <td className='pr-2 fw-normal'>
                    {file.createdDatetime.toLocaleString('ru')}
                  </td>
                  <td>
                    {canDelete &&
                      <DeleteFileBtn file={file} onDelete={reloadCctv} />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

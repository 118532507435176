import { useState, useMemo } from 'react'
import { ApiResource, IPec, IPecStatusField, IUser } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import Loader from '@/components/common/Loader'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import { UserRole } from '@/config/roles'
import { patchPecExecutor } from '@/api/queries/audit'


type Props = {
  pec: IPec
  statusFieldId: IPecStatusField['id'],
  reloadCard: () => Promise<void>,
  selectedOrganizationId: string | undefined
}

type Form = {
  executor: IUser['id']
}

// Тип для фильтра
type ApiResourceFilter = {
  'userRoles.code'?: UserRole;
  'organization.id'?: string;
}

export default function PecEditExecutorForm({ pec, statusFieldId, reloadCard, selectedOrganizationId }: Props) {
  const { form } = useFormContext<Form>()
  const { showModalDialog, usePrimaryCallback } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  const filter = useMemo<ApiResourceFilter | undefined>(() => {
    if (!selectedOrganizationId) {
      return undefined;
    }
    return {
      'userRoles.code': UserRole.executor,
      'organization.id': selectedOrganizationId
    }
  }, [selectedOrganizationId])

  usePrimaryCallback(async () => {
    if (isLoading)
      return false
    
    setIsLoading(true)

    try {
      await patchPecExecutor(pec.id, statusFieldId, form.executor)
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении исполнителя',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }, [isLoading, setIsLoading, form.executor, pec, statusFieldId, reloadCard])

  return (
    <div style={{minHeight: 375}}>
      {selectedOrganizationId ? (
        <ApiResourceSelect<IPec['executors'][0], Form>
          apiResource={ApiResource.User}
          filter={filter}
          label='Исполнитель'
          name='executor'
          initValue={pec.executors.find(e => e.statusFieldId === statusFieldId)}
          disabled={isLoading}
        />
      ) : (
        <p>Подрядная организация (ПО) не выбрана. Пожалуйста, выберите организацию, чтобы отобразить доступных исполнителей.</p>
      )}
      {isLoading && <Loader />}
    </div>
  )
}
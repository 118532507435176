import { useCallback } from 'react'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserTableHiddenColsKey, userTableHiddenColsFieldsMap } from '@/context/types'


const useIsTableColDisplayed = (userHiddenTableColsKey: UserTableHiddenColsKey) => {
  const { user } = useAuthContext()

  return useCallback((colId: string) => (
    // @ts-ignore
    !user![userHiddenTableColsKey].some(f => f[userTableHiddenColsFieldsMap[userHiddenTableColsKey]] === colId)
  ), [user, userHiddenTableColsKey])
}

export default useIsTableColDisplayed

import { NavLink, NavLinkProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Tab } from '@/context/TabsContext'


type Props = {
  tab: Tab
  end?: NavLinkProps['end']
  defaultIcon?: IconDefinition
  onClose?: Function
}

const TabsItem = ({ tab, end, defaultIcon, onClose }: Props) => {
  const icon = tab.icon || defaultIcon

  return (
    <li className='nav-item'>
      <NavLink to={tab.path} end={end} className='nav-link px-2 py-1'>
        {icon &&
          <FontAwesomeIcon icon={icon} fixedWidth />
        }
        {' ' + (tab.label || 'Загрузка...')}
        {onClose &&
          <button
            type='button'
            role='button'
            className='btn btn-sm btn-outline-secondary p-0 ms-1 border-0 shadow-none'
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onClose()
            }}
          >
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </button>
        }
      </NavLink>
    </li>
  )
}

export default TabsItem

import { useCallback, useMemo } from 'react'
import { IPec, IPecStatusField, IStatusStage } from '@/context/types'
import getErrorMessage from '@/utils/getErrorMessage'
import { getTextColorClassName } from '@/utils/color'
import { useModal } from '@/context/ModalContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import { channelStatusFsm, getFsmValue, pecStatusFsm } from '@/config/status/fsm'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = {
  pec: IPec
  status: IStatusStage
  statusFieldId: IPecStatusField['id']
  reloadCard: () => void
}

export default function PecStatusControlMenuItem({ pec, status, statusFieldId, reloadCard }: Props) {
  const { showModalDialog } = useModal()
  const { hasRole } = useAuthContext()
  
  const currentStatusCode = pec.statusFields.find(sf => sf.id === statusFieldId)?.value ?? ''

  const handleSetStatus = useCallback(async () => {
    try {
      await axios.patch(
        apiRoutes.updatePecStatus(pec.id),
        {
          status_field_id: statusFieldId,
          status_code: status.code,
        },
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка при изменении статуса',
        content: await getErrorMessage(e),
      })
    }
  }, [[status.code, statusFieldId, reloadCard, showModalDialog]])

  const disabled = useMemo(() => {
    const checkFsmRoles = (key: keyof typeof pecStatusFsm) => {
      const roles = getFsmValue(pecStatusFsm, key, currentStatusCode, key, status.code)?.roles
      return roles && hasRole(roles)
    }

  	// Пункт меню недоступен, если:
  	return (
			// переход в текущем меню запрещен
  		!checkFsmRoles('any')
  	)
  }, [status, currentStatusCode, pec, hasRole])

  if (status?.code === currentStatusCode) {
    return (
      <button
        className='dropdown-item active'
        type='button'
        onClick={handleSetStatus}>
        <FontAwesomeIcon
          icon={faCircle}
          className={`${getTextColorClassName(status.code)} me-2`} />
        {status.name}
      </button>
    )
  }

  return (
    <button
      className='dropdown-item'
      type='button'
      disabled={disabled}
      onClick={handleSetStatus}>
      <FontAwesomeIcon
        icon={faCircle}
        className={`${getTextColorClassName(status.code)} me-2`}
      />
      {status.name}
    </button>
  )

}
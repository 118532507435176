import { colorsTable } from '@/components/reports/common/constants'
import { ISubReportUnavailableHardware } from '@/components/reports/types'
import { formatDateTime } from '@/utils/formatters'
import { useParams } from 'react-router-dom'


type Props = {
  filterLabel: string
  reportDetailUH: ISubReportUnavailableHardware[]
}

export const SubReportUnavailableHardware = ({ filterLabel, reportDetailUH}: Props) => {
  const { projectId } = useParams()

  return (
    <div className='mt-4'>
      <h5>Детализация: Недоступные на текущий момент камеры</h5>
      <div>Дата создания отчёта: {formatDateTime(new Date)}</div>
      <div>{filterLabel}</div>
      <table className="table table-bordered table-sm mt-4">
        <thead className="text-muted"
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {!projectId && <th>Проект</th>}
            <th>№ ТВН</th>
            <th>Тип</th>
            <th>Оборудование</th>
            <th>IP-адрес</th>
            <th>Подрядчик</th>
            <th>Оператор канала связи</th>
          </tr>
        </thead>
        <tbody>
          {reportDetailUH.map((row, index) =>
            <tr key={index}>
              {!projectId && <td className="text-end">{row.project_name}</td>}
              <td className="text-end">{row.cctv_number}</td>
              <td className="text-end">{row.cctv_type}</td>
              <td className="text-end">{row.hardware_name}</td>
              <td className="text-end">{row.ip_address}</td>
              <td className="text-end">{row.maintainer}</td>
              <td className="text-end">{row.channel_operator}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

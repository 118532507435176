import StatusIndicator from '@/components/common/cards/StatusIndicator'
import FolderCard from '@/components/common/cards/FolderCard'
import { useServiceStatusListContext } from '@/context/statuses/ServiceStatusListContext'
import ServiceStatusControlMenu from '@/components/communication/Folder/statuses/ServiceStatusControlMenu'
import { IService } from '@/context/types'
import { useIdAcceptanceStatusListContext } from '@/context/statuses/IdAcceptanceStatusListContext'
import { serviceStatusLabels } from '@/config/status/labels'


type Props = {
  service: IService
  reloadCard: () => Promise<void>
}

const ServiceStatusCard = ({ service, reloadCard }: Props) => {
  const { recordList: statusList } = useServiceStatusListContext()
  const { recordList: idAcceptanceStatusList } = useIdAcceptanceStatusListContext()
  const sitronicsStatusList = statusList.filter((s) => s.code !== 'elaboration')

  return (
    <FolderCard
      headerBgColor='#99FCCE'
      bodyBgColor='#fff9db'
      header={'Статус услуги'}
    >
      <table className='table table-sm table-hover table-borderless mb-0'>
        <tbody>
          <tr>
            <td className='text-muted ps-2'>{serviceStatusLabels.stage}</td>
            <td>
              <StatusIndicator
                statusList={service.isSitronicsPort ? sitronicsStatusList : statusList}
                selectedStatusCode={service.stage.code}
              />
            </td>
            <td className='text-end pe-2'>
              <ServiceStatusControlMenu
                service={service}
                statusFieldName='stage'
                statusName={service.stage.name}
                statusList={service.isSitronicsPort ? sitronicsStatusList : statusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
          <tr>
            <td className='text-muted ps-2'>{serviceStatusLabels.idAcceptanceStatus}</td>
            <td>
              <StatusIndicator
                statusList={idAcceptanceStatusList}
                selectedStatusCode={service.idAcceptanceStatus.code}
              />
            </td>
            <td className='text-end pe-2'>
              <ServiceStatusControlMenu
                service={service}
                statusFieldName='idAcceptanceStatus'
                statusName={service.idAcceptanceStatus.name}
                statusList={idAcceptanceStatusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </FolderCard>
  )
}

export default ServiceStatusCard

import { PropsWithChildren, ReactNode } from 'react'


export type Props = PropsWithChildren<{
	header: ReactNode
	centerToolbar?: ReactNode
	rightToolbar?: ReactNode
	headerBgColor?: string
	bodyBgColor?: string
	textColor?: string
}>


/** Карточка в разделе сведений ТВН */
export default function FolderCard({
  header,
  centerToolbar,
  rightToolbar,
  children,
  headerBgColor,
  bodyBgColor,
  textColor,
}: Props) {
	return (
		<div className='card border-0 mb-2'>
			<div className='card-header d-flex border-0 px-2 py-1' style={{ backgroundColor: headerBgColor, color: textColor }}>
				<div className='col fw-bold'>
					{header}
				</div>
				{centerToolbar &&
					<div className='col d-flex justify-content-center'>
						<div className='btn-toolbar gap-2'>
							{centerToolbar}
						</div>
					</div>}
				{rightToolbar &&
					<div className='col d-flex justify-content-end'>
						<div className='btn-toolbar gap-2'>
							{rightToolbar}
						</div>
					</div>}
			</div>
			<div className='card-body border-0 p-0' style={{ backgroundColor: bodyBgColor, overflowX: 'auto' }}>
				{children}
			</div >
		</div>
	)
}
import { MouseEventHandler } from 'react'


type Props = {
  onClick: MouseEventHandler<HTMLAnchorElement>
  value: number
}

const PecsStatNumberLinkCell = ({ onClick, value }: Props) =>
  <td>
    {value
      ? <a onClick={onClick} className='' role='button'>{value}</a>
      : 0
    }
  </td>

export default PecsStatNumberLinkCell

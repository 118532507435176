import { useCallback, useEffect, useMemo, useState } from 'react'
import { ApiResource, IService } from '@/context/types'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { apiRoutes, routes } from '@/config/routes'
import {
  ServiceTableColumnName, ServiceTableColumnNameList, ServiceTableColumns,
} from '@/config/tables/lists/commutication/service'
import useTableFilterForm from '@/hooks/useTableFilterForm'
import { useModal } from '@/context/ModalContext'
import { useServiceListContext } from '@/context/tables/ServiceListContext'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import CommunicationNav from '@/components/communication/CommunicationNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import EmptyTableRow from '@/components/common/table/EmptyTableRow'
import PaginationWSP from '@/components/common/PaginationWSP'
import TableHead from '@/components/common/table/TableHead'
import TableRow from '@/components/common/table/TableRow'
import TableToolbar from '@/components/common/table/TableToolbar'
import ServiceCreateEditForm from '@/components/modals/service/ServiceCreateEditForm'
import { FormContextProvider } from '@/context/form/FormContext'
import useTableRecordDelete from '@/hooks/useTableRecordDelete'
import { copyObjectByKeys, isObjectEmpty } from '@/utils/tools'
import { tableFilterInfoModal } from '@/components/modals/common/info'
import { NaturalNumberRegex } from '@/config/config'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '@/context/auth/AuthContext'
import Loader from '@/components/common/Loader'
import ServiceTableStatusMenu from '@/components/communication/ServiceTableStatusMenu'
import { useChannelStatusListContext } from '@/context/statuses/ChannelStatusListContext'
import searchToParams from '@/utils/paramConverters/searchToParams'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { fetchServiceTableTotals } from '@/api/queries/table-totals'
import useTableDisplayedCols from '@/hooks/useTableDisplayedCols'
import { TableColsSelect } from '@/components/modals/TableColsSelect'
import useTableAvailableCols from '@/hooks/useTableAvailableCols'


export default function ServiceTablePageContent() {
  const { hasPermission } = useAuthContext()
  useDocumentTitle('Таблица услуг')
  const { showModalDialog } = useModal()
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const availableCols = useTableAvailableCols(ServiceTableColumnNameList, ServiceTableColumns)
  const { displayedColumnNames } = useTableDisplayedCols('userServiceFields', availableCols)
  const showDeleteModalDialog = useTableRecordDelete<IService>(apiRoutes.service, 'услуг', routes.service)
  const {
    recordList,
    count,
    page,
    pageCount,
    recordsPerPage,
    setRecordsPerPage,
    filter,
    sortFieldName,
    sortDirection,
    isAllRecordsSelected,
    selectedRecordIdSet,
    fullRecordIdSet,
    isRecordListLoading,
    isFullIdRecordListLoading,
    loadRecordList,
    samplerLabel,
    setRecordListPage,
    setRecordListFilter,
    setRecordListSort,
    setRecordListSampler,
    selectRecord,
    deselectRecord,
    selectAllRecords,
    deselectAllRecords
  } = useServiceListContext()

  useEffect(() => {
    loadRecordList()
  }, [loadRecordList])

  const navigate = useNavigate()

  const { isFetching: isTotalsLoading, data: totals } = useQuery({
    queryKey: ['service_totals', [...fullRecordIdSet].join()],
    queryFn: () => fetchServiceTableTotals(fullRecordIdSet),
    placeholderData : keepPreviousData,
  })

  /** Поиск по УНК */
  const handleSearchNumber = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString || !NaturalNumberRegex.test(clearSearchString))
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректный Номер канала'
      })
    try {
      setIsSearchLoading(true)
      const response = await axios.get(
        apiRoutes.services, {
          params: {
            'channels.number': clearSearchString,
          }
        })
      if (!Array.isArray(response.data) || response.data.length === 0)
        throw new Error(`УНК ${clearSearchString} не найден`)

      const service = response.data[0]
      service && navigate(routes.service(service.id))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск УНК: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске УНК ${clearSearchString}`),
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])
  
  const handleSearchServiceNumber = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString)
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректный номер услуги'
      })
    try {
      setIsSearchLoading(true)
      const response = await axios.get(
        apiRoutes.services, {
          params: {
            'number': clearSearchString,
          }
        })
      if (!Array.isArray(response.data) || response.data.length === 0)
        throw new Error(`Услуга ${clearSearchString} не найдена`)

      const service = response.data[0]
      service && navigate(routes.service(service.id))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск услуги: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске услуги ${clearSearchString}`),
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])


  /** Поиск по ЕЦХД */
  const handleSearchStorageId = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString || !NaturalNumberRegex.test(clearSearchString))
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректный ID ЕЦХД (только цифры)'
      })
    try {
      setIsSearchLoading(true)

      loadRecordList(searchToParams(ServiceTableColumns, 'objectId', clearSearchString ))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск ТВН: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске ID ЕЦХД ${clearSearchString}`)
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])

  /** Поиск по Address */
  const handleSearchAddressName = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString)
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректный адрес'
      })
    try {
      setIsSearchLoading(true)

      loadRecordList(searchToParams(ServiceTableColumns, 'fromAddress', clearSearchString ))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск адресу: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске адреса ${clearSearchString}`)
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])

  /** Поиск по Department */
  const handleSearchDepartmentName = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString)
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректное название департамента'
      })
    try {
      setIsSearchLoading(true)

      loadRecordList(searchToParams(ServiceTableColumns, 'consumerName', clearSearchString ))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск департаменту: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске департамента ${clearSearchString}`)
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])

  /** Поиск по Consumer */
  const handleSearchConsumerName = useCallback(async (clearSearchString: string) => {
    if (!clearSearchString)
      return showModalDialog({
        type: 'error',
        header: 'Поиск',
        content: 'Введите корректное название потребителя'
      })
    try {
      setIsSearchLoading(true)

      loadRecordList(searchToParams(ServiceTableColumns, 'fromObjectName', clearSearchString ))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Поиск потребителю: ошибка',
        content: await getErrorMessage(e, `Ошибка при поиске потребителя ${clearSearchString}`)
      })
    } finally {
      setIsSearchLoading(false)
    }
  }, [showModalDialog])

  useEffect(() => {
    return () => {
      clearFilter()
    }
  }, [])

  const {
    filterForm,
    updateFilterForm,
    applyFilter,
    clearFilter,
  } = useTableFilterForm(filter, setRecordListFilter)

  const deleteRecords = useCallback(() => {
    showDeleteModalDialog(selectedRecordIdSet, deselectAllRecords, loadRecordList)
  }, [selectedRecordIdSet, deselectAllRecords, loadRecordList])

  const handleSelectCols = useCallback(() => {
    showModalDialog({
      type: 'okCancel',
      header: 'Настройка столбцов',
      size: 'xl',
      content:
        <TableColsSelect
          userTableHiddenColsKey='userServiceFields'
          columns={copyObjectByKeys(ServiceTableColumns, availableCols)}
        />,
    })
  }, [showModalDialog])

  /** Сбросить выборку */
  const handleClearSampler = useCallback(() => {
    setRecordListSampler({}, '')
  }, [setRecordListSampler])

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(tableFilterInfoModal('услуг', {
      showMassEditInfo: true,
      importApiResource: hasPermission('importServices') ? ApiResource.Service : undefined
    }))
  }, [showModalDialog, hasPermission])

  const createRecord = useCallback(() => {
    showModalDialog({
      type: 'saveCancel',
      header: 'Добавление услуги',
      size: 'lg',
      content:
        <FormContextProvider>
          <ServiceCreateEditForm onSave={loadRecordList} />
        </FormContextProvider>,
    })
  }, [showModalDialog])

  const customColumnLabels = useMemo(() => ({
    channelStage:
      <ServiceTableStatusMenu fieldKey='channelStage' listContextHook={useChannelStatusListContext} />,
  }), [selectedRecordIdSet])

  const isLoading = isRecordListLoading || isFullIdRecordListLoading || isTotalsLoading

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem path={routes.communication}>Связь</BreadcrumbsItem>
        <BreadcrumbsItem>Таблица услуг</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar showServiceTableAdditionalButtons={true} handleSearchStorageId={handleSearchStorageId} handleSearchAddressName={handleSearchAddressName} handleSearchConsumerName={handleSearchConsumerName} handleSearchDepartmentName={handleSearchDepartmentName} handleSearchChannelNumber={handleSearchNumber} handleSearchServiceNumber={handleSearchServiceNumber} isLoading={isSearchLoading} />
      <CommunicationNav />
      <TableToolbar
        hasSampler
        samplerLabel={samplerLabel}
        recordListLength={recordList.length}
        recordListCount={count}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        selectedRecordIdSetSize={selectedRecordIdSet.size}
        fullRecordIdSet={fullRecordIdSet}
        clearFilter={clearFilter}
        onClearSampler={handleClearSampler}
        onReload={loadRecordList}
        exportBtnLabel='Массовый экспорт услуг'
        exportApiRoute={hasPermission('exportServices') ? apiRoutes.exportServices : ''}
        importBtnLabel='Массовый импорт услуг'
        importApiRoute={hasPermission('importServices') ? apiRoutes.importServices : ''}
        handleShowFilterInfo={handleShowFilterInfo}
        onClickCreate={createRecord}
        canCreate={hasPermission('serviceCreate')}
        onClickDelete={deleteRecords}
        canDelete={hasPermission('serviceDelete')}
        handleSelectCols={handleSelectCols}
      />
      <div className='container-fluid'>
        <table className='table table-sm table-bordered table-hover' style={{ height: 1 }}>
          {!isLoading &&
            <>
              <TableHead
                columnNameList={displayedColumnNames}
                columns={ServiceTableColumns}
                customColumnLabels={customColumnLabels}
                isAllRecordsSelected={isAllRecordsSelected}
                selectAllRecords={selectAllRecords}
                deselectAllRecords={deselectAllRecords}
                recordListSortFieldName={sortFieldName}
                recordListSortDirection={sortDirection}
                setRecordListSort={setRecordListSort}
                filterForm={filterForm}
                updateFilterForm={updateFilterForm}
                applyFilter={applyFilter}
                totals={totals}
              />
              <tbody>
                {!recordList.length
                  ? <EmptyTableRow columnCount={displayedColumnNames.length + 1}>
                    {isObjectEmpty(filter) ? 'Услуг нет' : 'Услуг не найдено'}
                  </EmptyTableRow>
                  : recordList.map((record) =>
                    <TableRow<IService, ServiceTableColumnName>
                      key={record.id}
                      columnNameList={displayedColumnNames}
                      columns={ServiceTableColumns}
                      record={record}
                      selectRecord={selectRecord}
                      selectedRecordIdSet={selectedRecordIdSet}
                      deselectRecord={deselectRecord}
                      expandable
                    />,
                  )
                }
              </tbody>
            </>
          }
        </table>
        {isLoading && <Loader />}
        <div style={{ position: 'sticky', bottom: 0 }}>
          <PaginationWSP
            currentPage={page}
            setCurrentPage={setRecordListPage}
            lastPage={pageCount} />
        </div>
      </div>
    </>
  )
}
import {
  ContractNumberSelect, Props as ContractNumberSelectProps,
} from '@/components/communication/ContractNumberSelect'


type Props = {
  onContractNumberChange: ContractNumberSelectProps['onChange']
}

export default function ActFormationTableToolbar({ onContractNumberChange }: Props) {
  return (
    <div className='w-100' style={{ height: '80px', zIndex: 1000 }}>
      <div className='container-fluid pt-2' style={{ position: 'fixed' }}>
        <div className='btn-toolbar gap-2 align-items-center'>
          <div style={{ width: '300px', height: '31px' }} className='d-flex'>
            <ContractNumberSelect onChange={onContractNumberChange} />
          </div>
        </div>
      </div>
    </div>
  )
}

import { IChannel, IChannelAddress, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { routes } from '@/config/routes'
import { NavLink } from 'react-router-dom'
import { snakeToCamel } from '@/utils/string'


export const ChannelAddressTableColumnNameList = [
  'storageId',
  'name',
  'belonging',
  'totalCountChannels',
] as const

export type ChannelAddressTableColumnName = typeof ChannelAddressTableColumnNameList[number]

export const ChannelAddressTableColumns: Record<ChannelAddressTableColumnName, TableColumnConfig<IChannelAddress>> = {
  storageId: {
    label: 'ID ЕЦХД',
    value: address => address.storageId,
    filterKey: 'storageId',
    sortKey: 'storageId',
  },
  name: {
    label: 'Адрес по ЕЦХД',
    value: address => <NavLink to={routes.address(address.id)}>{address.name}</NavLink>,
    filterKey: 'name',
    sortKey: 'name',
  },
  belonging: {
    label: 'Принадлежность здания',
    value: address => address.belonging,
    filterKey: 'belonging',
    sortKey: 'belonging',
  },
  totalCountChannels: {
    label: 'Всего каналов на адресе',
    value: address => address.totalCountChannels,
    filterAndSortViewKey: 'view_address_with_channels:total',
  },
}

export const prepareChannelAddressesAdditionalCols = (
  channelStages: IChannel['stage'][],
  address?: IChannelAddress
) => {
  const cols: TableAdditionalColumnConfig[] = []

  for (const stage of channelStages)
    cols.push({
      label: `Всего каналов в статусе «${stage.name}»`,
      value: address?.countChannels.find(el => el.code === stage.code)?.count ?? 0,
      filterAndSortViewKey: `view_address_with_channels:${snakeToCamel(stage.code)}`,
    })

  return cols
}

import { useCallback } from 'react'
import { IFile } from '@/context/types'
import { fileSizeToLocaleString } from '@/utils/fileSizeToLocaleString'
import getErrorMessage from '@/utils/getErrorMessage'
import { apiRoutes } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { UserRole } from '@/config/roles'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useModal } from '@/context/ModalContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTrash } from '@fortawesome/free-solid-svg-icons'


type Props = {
	file: IFile
	onDone: () => void
}


export default function IncidentFile({ file, onDone }: Props) {
	const { hasRole } = useAuthContext()
	const { showModalDialog } = useModal()

	const handleDeleteFile = useCallback(() => {
		showModalDialog({
			type: 'deleteCancel',
			header: 'Удалить файл',
			content:
				<>
					Вы уверены, что хотите удалить файл «{file.name}»
					{' ('}
					{fileSizeToLocaleString(+file.size)}
					{', '}
					{file.createdByUser.firstName}
					{' '}
					{file.createdByUser.lastName}
					{', '}
					{file.createdDatetime.toLocaleString()}
					{')?'}
				</>,
			primaryCallback: async () => {
				try {
					await axios.delete(apiRoutes.file(file.id))
					onDone()
				} catch (e: any) {
					showModalDialog({
						type: 'error',
						header: `Удалить файл: ошибка`,
						content: await getErrorMessage(e, `Произошла ошибка при удалении файла`),
					})
				}
			},
			secondaryCallback: onDone
		})
	}, [file, onDone, showModalDialog])

	return (
		<div className='d-flex justify-content-between align-items-start'>
			<div>
				<a href={apiRoutes.file(file.id)} target={'_blank'} rel='noreferrer'>
					<FontAwesomeIcon icon={faFile} className='me-2' />
					{file.name}
				</a>
				<span className='text-muted ms-3'>
					{fileSizeToLocaleString(+file.size)}
					{', '}
					{file.createdByUser.firstName}
					{' '}
					{file.createdByUser.lastName}
					{', '}
					{file.createdDatetime.toLocaleDateString()}
				</span>
			</div>
			<button
				className='btn btn-link text-danger p-0 ms-3'
				disabled={!hasRole([UserRole.admin, UserRole.mgts])}
				onClick={handleDeleteFile}>
				<FontAwesomeIcon icon={faTrash} />
			</button>
		</div>
	)
}
import ExploitationToolbar from '@/components/exploitation/ExploitationToolbar'
import ExploitationNav from '@/components/exploitation/ExploitationNav'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import { routes } from '@/config/routes'


const ExploitationHeader = () =>
  <>
    <BreadcrumbsContainer>
      <BreadcrumbsItem path={routes.exploitation}>Эксплуатация</BreadcrumbsItem>
    </BreadcrumbsContainer>
    <ExploitationToolbar />
    <ExploitationNav />
  </>

export default ExploitationHeader

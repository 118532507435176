import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  anyFormChangedAction, anyFormFieldValueChangedAction, useAnyFormStoreReducer,
} from '@/context/anyForm/anyFormStore'


interface AnyFormContextValue {
	form: any
	setState: (state: any) => void
	setField: (key: string, value: any) => void
}

const AnyFormContext = createContext({} as AnyFormContextValue)

export const useAnyFormContext = () => useContext<AnyFormContextValue>(AnyFormContext)

type Props = PropsWithChildren<{}>

export function AnyFormContextProvider({ children }: Props) {
	const [form, dispatch] = useAnyFormStoreReducer()

	const setState = useCallback((state: any) => {
		dispatch(anyFormChangedAction(state))
	}, [dispatch])
	const setField = useCallback((key: string, value: any) => {
		dispatch(anyFormFieldValueChangedAction(key, value))
	}, [dispatch])

	const value: AnyFormContextValue = useMemo(() => ({
		form,
		setState,
		setField,
	}), [form, setState, setField])

	useStorageStateLog('anyForm', form)

	return (
		<AnyFormContext.Provider value={value}>
			{children ?? <Outlet />}
		</AnyFormContext.Provider>
	)
}
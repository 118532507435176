import {
  ApiResource, ExploitationIncidents, IAgreement, IBaseHardware, ICctv, ICctvAttachFile, ICctvExternalFile, IChannel,
  IChannelAddress, IChannelWithCctv, IChannelWithPayments, IChannelWithService, IContract, IHardwareStateLog, IIncident,
  IIncidentStageLogRecord, ILogHistoryEntry, IMonitoringCctv, IOrderForm, IPec, IPecAttachFile, IReportPeriod, IService,
  IServiceWithBilling, IStatusHistoryEntry, IStatusHistoryWorkEntry, ServiceTypeKindNames,
} from '@/context/types'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'
import { generateCctvHardwareList } from '@/utils/hardware'
import { apiRoutes } from '@/config/routes'


export function convertIncident<T extends Partial<Omit<IIncident, 'stage'>>>(incident: T): T {
  if (incident.openDatetime)
    incident.openDatetime = new Date(incident.openDatetime)
  if (incident.closedDatetime)
    incident.closedDatetime = new Date(incident.closedDatetime)
  if (incident.updatedDatetime)
    incident.updatedDatetime = new Date(incident.updatedDatetime)
  if (incident.createdDatetime)
    incident.createdDatetime = new Date(incident.createdDatetime)
  if (incident.files)
    for (const file of incident.files)
      file.createdDatetime = new Date(file.createdDatetime)
  return incident
}

export function convertIncidentStageLog(record: IIncidentStageLogRecord): IIncidentStageLogRecord {
  record.createdDatetime = new Date(record.createdDatetime)
  record.updatedDatetime = new Date(record.updatedDatetime)
  return record
}

export function convertMonitoringCctv(cctv: IMonitoringCctv): IMonitoringCctv {
  for (const incident of cctv.incidents)
    convertIncident(incident.incident)

  for (const hardware of generateCctvHardwareList(cctv))
    convertHardware(hardware)

  return cctv
}

export function convertMonitoringMapCctv(cctv: MonitoringMapCctv): MonitoringMapCctv {
  cctv.incidents = []
  for (const hardware of generateCctvHardwareList(cctv))
    for (const incident of hardware.incidents) {
      convertIncident(incident)
      cctv.incidents.push(incident)
    }

  return cctv
}

export function convertHardware<T extends Partial<IBaseHardware>>(hardware: T): T {
  if (hardware.lastStateDatetime)
    hardware.lastStateDatetime = new Date(hardware.lastStateDatetime)

  const convertLogEntry = (
    entry: { createdDatetime: IBaseHardware['logModelNamesEntries' | 'logSerialNumbersEntries'][0]['createdDatetime'] },
  ) => entry.createdDatetime = new Date(entry.createdDatetime)

  hardware.logModelNamesEntries?.forEach(convertLogEntry)
  hardware.logSerialNumbersEntries?.forEach(convertLogEntry)

  return hardware
}

export function convertHardwareStateLogs(logs: IHardwareStateLog[]): IHardwareStateLog[] {
  let prevLogDate: Date | undefined
  for (const log of logs) {
    log.datetime = new Date(log.datetime)
    if (prevLogDate && +prevLogDate === +log.datetime)
      log.datetime.setMilliseconds(log.datetime.getMilliseconds() + 1) // can't have logs with exactly the same date

    prevLogDate = log.datetime
  }

  return logs
}

const convertLogHistoryEntries = (record: {logHistoryEntries: ILogHistoryEntry[]}) =>
  record.logHistoryEntries?.forEach(entry =>
    entry.createdDatetime = new Date(entry.createdDatetime),
  )

const convertLogStatusHistoryEntries = (record: { logStatusHistoryEntries: (IStatusHistoryEntry | IStatusHistoryWorkEntry)[] }) =>
  record.logStatusHistoryEntries?.forEach(entry => {
    entry.createdDatetime = new Date(entry.createdDatetime)
    entry.createdDatetimeStr = entry.actor?.name ? entry.createdDatetime.toLocaleString() : ''
  })

export const convertFiles = <T extends (ICctvAttachFile | ICctvExternalFile | IPecAttachFile)[]>(files: T): T => {
  for (const file of files) {
    file.isExternal = !!(file as ICctvExternalFile).url
    file.path = file.isExternal ? file.url : apiRoutes.file(file.id)
    
    file.createdDatetime = new Date(file.createdDatetime)
  }

  return files
}

export function convertCctv(cctv: ICctv): ICctv {
  cctv.itemType = 'cctv'

  for (const incident of cctv.incidents)
    convertIncident(incident.incident)

  convertLogHistoryEntries(cctv)
  convertLogStatusHistoryEntries(cctv)

  convertFiles(cctv.files)

  return cctv
}

export function convertPec(pec: IPec): IPec {
  pec.itemType = 'pec'

  for (const entry of pec.workBeginDates)
    entry.date = new Date(entry.date)

  for (const entry of pec.statusFieldLogHistoryEntries)
    entry.createdDatetime = new Date(entry.createdDatetime)

  convertFiles(pec.files)

  return pec
}

export function convertContract(contract: IContract): IContract {
  contract.beginDate = new Date(contract.beginDate)
  contract.endDate = new Date(contract.endDate)

  for (const reportingPeriod of contract.reportingPeriods)
    convertReportPeriod(reportingPeriod)

  return contract
}

export function convertAgreement(agreement: IAgreement): IAgreement {
  agreement.date = new Date(agreement.date)

  return agreement
}

export function convertOrderForm(orderForm: IOrderForm): IOrderForm {
  if (orderForm.signingDate)
    orderForm.signingDate = new Date(orderForm.signingDate)

  return orderForm
}

export function convertReportPeriod(reportingPeriod: IReportPeriod): IReportPeriod {
  reportingPeriod.beginDate = new Date(reportingPeriod.beginDate)
  reportingPeriod.endDate = new Date(reportingPeriod.endDate)
  return reportingPeriod
}

export function convertService<S extends IService | IServiceWithBilling>(service: S): S {
  convertLogHistoryEntries(service)
  convertLogStatusHistoryEntries(service)

  if (service.orderBeginDate)
    service.orderBeginDate = new Date(service.orderBeginDate)
  if (service.serviceBeginDate)
    service.serviceBeginDate = new Date(service.serviceBeginDate)
  if (service.serviceEndDate)
    service.serviceEndDate = new Date(service.serviceEndDate)
  if (service.orderEndDate)
    service.orderEndDate = new Date(service.orderEndDate)

  const { channels, ...channelService } = service
  for (const channel of channels as IChannelWithService[]) {
    channel.service = channelService as S

    convertChannel(channel)
  }

  service.isSitronicsPort = service.contractServiceTypePrice?.serviceType.typeKindName === ServiceTypeKindNames.port
    && service.portOwner?.name === 'Ситроникс'

  if ('billing' in service) {
    service.billing.reportingPeriods?.forEach((rp, k) => {
      rp.beginDate = new Date(rp.beginDate)
      rp.endDate = new Date(rp.endDate)

      const nextRp = service.billing!.reportingPeriods[+k + 1]
      if (nextRp && new Date(nextRp.beginDate).getFullYear() > rp.beginDate.getFullYear())
        rp.lastForYear = '' + rp.beginDate.getFullYear() as YearNumber
    })

    for (const channelId in service.billing.channels) {
      const channel = service.billing.channels[channelId]

      if (channel.connectedDate)
        channel.connectedDate = new Date(channel.connectedDate)
      if (channel.lastReportingPeriodDate)
        channel.lastReportingPeriodDate = new Date(channel.lastReportingPeriodDate)
    }
  }

  return service
}

export function convertChannel<C extends IChannel | IChannelWithPayments>(channel: C): C {
  convertLogHistoryEntries(channel)
  convertLogStatusHistoryEntries(channel)

  if (channel.processBeginDate)
    channel.processBeginDate = new Date(channel.processBeginDate)
  if (channel.processEndDate)
    channel.processEndDate = new Date(channel.processEndDate)

  channel.isCctvChannel = !!(channel as IChannelWithCctv).cctv

  if ('channelPayments' in channel)
    for (const { channelPaymentPeriod: period } of channel.channelPayments)
      period.date = new Date(`${period.year}-${period.month}`)

  return channel
}

export function convertAddress(address: IChannelAddress): IChannelAddress {
  address.channels.map(convertChannel)

  return address
}

export function covertExploitationIncidents(incidents: ExploitationIncidents): ExploitationIncidents {
  for (const incident of incidents)
    incident.incidents = incident.incidents_str.split(', ').map(id => ({ incident: { id: +id } }))

  return incidents
}

/** Конфиг конвертеров ресурсов API (из JSON в объекты) */
const ApiResourceConverters: Partial<Record<ApiResource, (record: any) => any>> = {
  [ApiResource.MonitoringCctv]: convertMonitoringCctv,
  [ApiResource.Incident]: convertIncident,
  [ApiResource.IncidentStageLog]: convertIncidentStageLog,
  [ApiResource.Contract]: convertContract,
  [ApiResource.Agreement]: convertAgreement,
  [ApiResource.OrderForm]: convertOrderForm,
  [ApiResource.ReportingPeriod]: convertReportPeriod,
  [ApiResource.Service]: convertService,
  [ApiResource.Channel]: convertChannel,
  [ApiResource.Pec]: convertPec,
}

export const convertRecord = <T>(apiResource: keyof typeof ApiResourceConverters, record: T): T =>
  ApiResourceConverters[apiResource]?.(record) ?? record

import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { IChannel } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** ServiceChannelStatus context */
const ChannelStatusListContext = createContext({} as IRecordListContext<IChannel['stage']>)


/** ServiceChannelStatus context hook */
export const useChannelStatusListContext = () => useContext(ChannelStatusListContext)


type Props = PropsWithChildren<{
  paginate?: boolean
}>

/** ServiceChannelStatus context provider */
export function ChannelStatusListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<IChannel['stage']>
      Context={ChannelStatusListContext}
      listApiRoute={apiRoutes.channelStatuses}
      deleteApiRoute={apiRoutes.channelStatus}
      paginate={paginate}
      logName='serviceChannelStatusList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
import { useRef, useState } from 'react'
import {
  ApiResource, ConstructionObjectStatusTypes, IAddress, IConstructionObject, IConstructionObjectForm,
  IConstructionObjectStatus, IConsumer, IDepartment, IOrganization, IServiceForm,
} from '@/context/types'
import TextInput from '@/components/common/form/TextInput'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import getErrorMessage from '@/utils/getErrorMessage'
import { useFormContext } from '@/context/form/FormContext'
import { useModal } from '@/context/ModalContext'
import NumberInput from '@/components/common/form/NumberInput'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import { addressToSelectOption } from '@/utils/recordToSelectOption'
import { UserRole } from '@/config/roles'


type Props = {
  onSave: () => void
  constructionObject?: IConstructionObject
}

const ConstructionObjectCreateEditForm = ({ onSave, constructionObject }: Props) => {
  const { showModalDialog, usePrimaryCallback } = useModal()
  const { form } = useFormContext<IConstructionObjectForm>()
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef<HTMLDivElement>(null)

  usePrimaryCallback(async () => {
    try {
      setIsLoading(true)

      if (constructionObject) {
        const response = await axios.patch(
          apiRoutes.constructionObject(constructionObject.id),
          { ...form },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )

        if (response.status === 200)
          onSave()
      } else {
        const response = await axios.post(apiRoutes.constructionObjects, { ...form })
        
        if (response.status === 201)
          onSave()
      }
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: `Добавление объекта строительства: Ошибка`,
        content: await getErrorMessage(e),
      })
    } finally {
      setIsLoading(false)
    }
  }, [form, showModalDialog])

  return (
    <div className='m-5 display-flex m-2' ref={formRef}>
      <TextInput<IConstructionObjectForm>
        label='Условное название проекта'
        name='name'
        initValue={constructionObject?.name}
        disabled={isLoading}
        required
      />
      <ApiResourceSelect<IConsumer, IConstructionObjectForm>
        apiResource={ApiResource.Consumer}
        label='Выберите Наименование объекта А'
        name='consumer'
        initValue={constructionObject?.consumer}
        disabled={isLoading}
      />
      <ApiResourceSelect<IDepartment, IServiceForm>
        apiResource={ApiResource.Department}
        key={'department' + form.consumer}
        filter={{ 'consumers.id': form.consumer }}
        label='Наименование клиента'
        name='department'
        initValue={form.consumer ? constructionObject?.department : null}
        placeholder={form.consumer ? '' : 'Выберите Наименование объекта А'}
        disabled={isLoading || !form.consumer}
      />
      <ApiResourceSelect<IAddress, IConstructionObjectForm>
        apiResource={ApiResource.Address}
        async
        label='Адрес'
        name='address'
        initValue={constructionObject?.address}
        recordToOption={addressToSelectOption}
        disabled={isLoading}
      />
      <NumberInput<IConstructionObjectForm>
        label='Заявленная потребность УП'
        name='statedRequirementUniversalPorts'
        initValue={constructionObject?.statedRequirementUniversalPorts}
        disabled={isLoading}
      />
      <NumberInput<IConstructionObjectForm>
        label='Заявленная потребность УРП'
        name='statedRequirementUniversalRadioPorts'
        initValue={constructionObject?.statedRequirementUniversalRadioPorts}
        disabled={isLoading}
      />
      <NumberInput<IConstructionObjectForm>
        label='Заявленная потребность УТП'
        name='statedRequirementUniversalPhonePorts'
        initValue={constructionObject?.statedRequirementUniversalPhonePorts}
        disabled={isLoading}
      />
      <NumberInput<IConstructionObjectForm>
        label='Фактическая потребность УП'
        name='actualRequirementUniversalPorts'
        initValue={constructionObject?.actualRequirementUniversalPorts}
        disabled={isLoading}
      />
      <NumberInput<IConstructionObjectForm>
        label='Фактическая потребность УРП'
        name='actualRequirementUniversalRadioPorts'
        initValue={constructionObject?.actualRequirementUniversalRadioPorts}
        disabled={isLoading}
      />
      <NumberInput<IConstructionObjectForm>
        label='Фактическая потребность УТП'
        name='actualRequirementUniversalPhonePorts'
        initValue={constructionObject?.actualRequirementUniversalPhonePorts}
        disabled={isLoading}
      />
      <TextInput<IConstructionObjectForm>
        label='Заказ от ДИТ'
        name='orderFromHpsm'
        initValue={constructionObject?.orderFromHpsm}
        disabled={isLoading}
      />
      <TextInput<IConstructionObjectForm>
        label='Контакт с объекта'
        name='contactFromObject'
        initValue={constructionObject?.contactFromObject}
        disabled={isLoading}
      />
      <TextInput<IConstructionObjectForm>
        label='Комментарий'
        name='comment'
        initValue={constructionObject?.comment}
        disabled={isLoading}
      />
      <TextInput<IConstructionObjectForm>
        label='Техническое примечание'
        name='note'
        initValue={constructionObject?.note}
        disabled={isLoading}
      />
      <ApiResourceSelect<IConstructionObjectStatus, IConstructionObjectForm>
        apiResource={ApiResource.ConstructionObjectStatus}
        filter={{ type: ConstructionObjectStatusTypes.inspected }}
        label='Статус Обследовано'
        name='inspectedStatus'
        initValue={constructionObject?.inspectedStatus}
        disabled={isLoading}
      />
      <ApiResourceSelect<IConstructionObjectStatus, IConstructionObjectForm>
        apiResource={ApiResource.ConstructionObjectStatus}
        filter={{ type: ConstructionObjectStatusTypes.constructionLines }}
        label='Статус СМР Линий'
        name='constructionLinesStatus'
        initValue={constructionObject?.constructionLinesStatus}
        disabled={isLoading}
      />
      <ApiResourceSelect<IConstructionObjectStatus, IConstructionObjectForm>
        apiResource={ApiResource.ConstructionObjectStatus}
        filter={{ type: ConstructionObjectStatusTypes.constructionHardware }}
        label='Статус СМР оборудования'
        name='constructionHardwareStatus'
        initValue={constructionObject?.constructionHardwareStatus}
        disabled={isLoading}
      />
      <ApiResourceSelect<IConstructionObjectStatus, IConstructionObjectForm>
        apiResource={ApiResource.ConstructionObjectStatus}
        filter={{ type: ConstructionObjectStatusTypes.commissioning }}
        label='Статус ПНР'
        name='commissioningStatus'
        initValue={constructionObject?.commissioningStatus}
        disabled={isLoading}
      />
      <ApiResourceSelect<IOrganization, IConstructionObjectForm>
        apiResource={ApiResource.Organization}
        filter={{ 'roles.code': UserRole.contractor }}
        label='Подрядчик'
        name='organization'
        initValue={constructionObject?.organization}
        disabled={isLoading}
      />
      <TextInput<IConstructionObjectForm>
        label='Заказ с ПО'
        name='orderWithContractor'
        initValue={constructionObject?.orderWithContractor}
        disabled={isLoading}
      />
      <NumberInput<IConstructionObjectForm>
        label='Сумма'
        name='sum'
        initValue={constructionObject?.sum}
        disabled={isLoading}
      />
    </div>
  )
}

export default ConstructionObjectCreateEditForm
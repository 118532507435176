import { ChangeEvent, useCallback, useEffect, useId, useState } from 'react'
import { useFormContext } from '@/context/form/FormContext'
import InputGroup from '@/components/common/form/InputGroup'


/** Пропсы поля даты */
type Props<TForm> = {
  label: string
  name?: keyof TForm
  initValue?: Date
  placeholder?: string
  required?: boolean
  helpText?: string
  disabled?: boolean
  onChange?: (date: Date | undefined) => void
}

/** Поле ввода даты для контекстной формы */
export default function DateInput<TFrom>({ label, name, initValue, placeholder, required, helpText, disabled, onChange }: Props<TFrom>) {
  const { setField, errors } = useFormContext<TFrom>()
  const [value, setValue] = useState<Date>()
  const id = useId()

  useEffect(() => {
    setValue(initValue ?? undefined)
    if (name) setField(name, initValue)
  }, [name, initValue, setField])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const dateValue = value ? new Date(value) : undefined
    setValue(dateValue)
    if (name) setField(name, dateValue)
    if (onChange) onChange(dateValue) // Вызов onChange
  }, [name, setField, onChange])

  return (
    <InputGroup
      id={id}
      label={label}
      required={required}
      helpText={helpText}
      invalidMessage={name && errors[name]}>
      <input
        className={`form-control ${name && errors[name] && 'is-invalid'}`}
        type='date'
        id={id}
        value={value?.toISOString()?.substring(0,10) || ''}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </InputGroup>
  )
}

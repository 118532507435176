import { ChangeEvent, useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons'
import Timer from '@/components/common/time/Timer'
import { useSessionStorage } from '@/hooks/useStorage'
import useInterval from 'use-interval'


export type Props = {
  onTimerInterval: Function
  sessionStorageKey: string
}

const IntervalTimer = ({ onTimerInterval, sessionStorageKey }: Props) => {
  const [reloadInterval, setReloadInterval] = useSessionStorage(sessionStorageKey, 0)
  const [reloadTimestamp, setReloadTimestamp] = useState(Date.now() + reloadInterval)

  useInterval(() => {
    onTimerInterval()
    setReloadTimestamp(reloadInterval ? Date.now() + reloadInterval : 0)
  }, reloadInterval || null)

  const handleChangeReloadRate = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const reloadInterval = +event.target.value
    setReloadInterval(reloadInterval)
    setReloadTimestamp(reloadInterval ? Date.now() + reloadInterval : 0)
  }, [setReloadInterval])

  const options = new Map<number, string>([
    [0, 'Нет'],
    [60_000, '1м'],
    [120_000, '2м'],
    [300_000, '5м'],
    [600_000, '10м'],
    [900_000, '15м'],
    [1_800_000, '30м'],
  ])

  return (
    <div className='input-group input-group-sm'>
      <label className='input-group-text' htmlFor='reloadSelect'>
        <FontAwesomeIcon icon={faRotate} />
        {reloadTimestamp > +new Date &&
          <Timer className='ms-2' timestamp={reloadTimestamp} />
        }
      </label>
      <select
        className='form-select'
        id='reloadSelect'
        value={reloadInterval}
        onChange={handleChangeReloadRate}
      >
        {Array.from(options.entries()).map(([time, label]) =>
          <option key={label} value={time}>{label}</option>,
        )}
      </select>
    </div>
  )
}

export default IntervalTimer

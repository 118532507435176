import { useMemo } from 'react'
import { routes } from '@/config/routes'
import TabsList, { DefaultTab } from '@/components/common/tabs/TabsList'

const ChannelsNav = () => {
  const defaultTabs: DefaultTab[] = useMemo(() => [
    {
      label: 'Список всех каналов',
      path: routes.channels,
      end: true,
    },
    {
      label: 'Адреса',
      path: routes.addresses,
      end: true,
    },
    {
      label: 'Реестр оплат',
      path: routes.channelPayments,
    },
  ], [])

  return <TabsList defaultTabs={defaultTabs} redirect={routes.channels} />
}

export default ChannelsNav

export enum UserRole {
  admin = 'admin',
  pm = 'pm',
  contractor = 'contractor',
  mgts = 'mgts',
  view = 'view',
  dit = 'dit',
  pm_connection = 'pm_connection',
  pm_cost = 'pm_cost',
  pm_income = 'pm_income',
  employee = 'employee',
  integration = 'integration',
  sitronics = 'sitronics',
  executor = 'executor',
  brigadier = 'brigadier',
  responsible_from_customer = 'responsible_from_customer',
}

export enum OrganizationRole {
  pmd = 'pmd',
  mgts = 'mgts',
  channelOperator = 'channel_operator',
  contractor = 'contractor',
}

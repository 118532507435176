import { ICctv, IPec } from '@/context/types'


export const downloadFile = (fileString: Blob | MediaSource, fileName: string): void => {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(fileString)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(link.href)
}

export const getFileNameFromContentDisposition = (contentDisposition: string): string | void => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

  if (utf8FilenameRegex.test(contentDisposition)) {
    const matches = utf8FilenameRegex.exec(contentDisposition)
    if (matches)
      return decodeURIComponent(matches[1])
  }
  // prevent ReDos attacks by anchoring the ascii regex to string start and
  //  slicing off everything before 'filename='
  const filenameStart = contentDisposition.toLowerCase().indexOf('filename=')
  if (filenameStart >= 0) {
    const partialDisposition = contentDisposition.slice(filenameStart)
    return asciiFilenameRegex.exec(partialDisposition)?.[2]
  }
}

export const copyObjectByKeys = <K extends keyof any, V, T extends Record<K, V>>(obj: T, keys: K[]) =>
  keys.reduce((a, c) => (obj[c] ? a[c] = obj[c] : c, a), {} as Pick<T, K>)

export const isObjectEmpty = (obj: {}): boolean =>
  !Object.keys(obj).length && Object.getPrototypeOf(obj) === Object.prototype

export const numberDeclension = (number: number, ...forms: [string, string?, string?]) => {
  let form
  const remainder = number % 10

  if (!remainder || remainder > 4 || number >= 11 && number <= 14)
    form = forms[2] || forms[1] || forms[0]
  else if (remainder > 1)
    form = forms[1] || forms[0]
  else
    form = forms[0]

  return `${number} ${form}`
}

export const getItemLabel = (item: ICctv | IPec) => item.itemType === 'cctv' ? 'ТВН' : 'УИК'
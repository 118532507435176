import { ChangeEvent, useState } from 'react'
import { ICatalogFiles, ICatalogWithFiles } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { ApiResourceConfig } from '@/config/apiResources'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTrash } from '@fortawesome/free-solid-svg-icons'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'
import attachFilesToCatalog from '@/utils/attachFilesToCatalog'


type Props = {
  record: ICatalogWithFiles
  fileUploadRoute?: NonNullable<ApiResourceConfig<any>['fileUploadRoute']>
}

const CatalogFilesModal = ({ record, fileUploadRoute }: Props) => {
  const { showModalDialog } = useModal()
  const [uploadedFiles, setUploadedFiles] = useState(record.files as ICatalogFiles)
  const [isLoading, setIsLoading] = useState(false)

  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length)
      return

    setIsLoading(true)

    try {
      const files = await attachFilesToCatalog(e.target.files, fileUploadRoute!, record.id as string)

      setUploadedFiles(files)

      e.target.value = ''
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка загрузки файла',
        content: await getErrorMessage(e),
      })
    }
    
    setIsLoading(false)
  }

  const deleteFile = async (id: ICatalogFiles[0]['id']) => {
    setIsLoading(true)

    try {
      await axios.delete(apiRoutes.file(id))

      setUploadedFiles(files => files.filter(f => f.id !== id))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка удаления файла',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }

  return (
    <>
      {uploadedFiles.map(file =>
        <div className='row' key={file.id}>
          <div className='col-6'>
            <a href={apiRoutes.file(file.id)} target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faFile} className='me-2' />
              {file.name}
            </a>
          </div>
          {fileUploadRoute &&
            <div className='col'>
              <button
                className='btn btn-link text-danger p-0 ms-3'
                onClick={() => deleteFile(file.id)}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          }
        </div>,
      )}
      {fileUploadRoute &&
        <input
          type='file'
          className='form-control mt-3'
          multiple
          onChange={uploadFile}
          accept='.pdf, .doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png, .iso, .vso, .tiff, .txt, .xml'
          disabled={isLoading}
        />
      }
    </>
  )
}

export default CatalogFilesModal

import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportHW } from '@/components/reports/types'
import { useParams } from 'react-router-dom'


export const ReportHardware = () => {
  const { projectId } = useParams()
  const [report, setReport] = useState<IReportHW[]>([])

  return (
    <ReportWrapper
      code='hardware'
      {...{ report, setReport }}
    >
      <table className='table table-bordered table-sm'>
        <thead
          className='text-muted'
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {!projectId && <th>Проект</th>}
            <th className='col-1'>ТВН</th>
            <th>Установлено на ТВН</th>
            <th>Тип</th>
            <th>Имя устройства</th>
            <th>Модель</th>
            <th>IP-адрес</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {report.map((row, index) => (
            <tr key={index} className='text-right'>
              {!projectId && <td>{row.project_name}</td>}
              <td>{row.cctv_number}</td>
              <td>{row.is_installed ? 'Да' : 'Нет'}</td>
              <td>{row.hardware_type}</td>
              <td>{row.hardware_name}</td>
              <td>{row.hardware_model}</td>
              <td>{row.ip_address}</td>
              <td>{row.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ReportWrapper>
  )
}

import { Link } from 'react-router-dom'
import {
  ApiResource, ContractIdentifierTypes, IContract, IProject, IProjectForm, IProjectType, IUser,
} from '@/context/types'
import { routes } from '@/config/routes'
import { useFormContext } from '@/context/form/FormContext'
import TextInput from '@/components/common/form/TextInput'
import NumberInput from '@/components/common/form/NumberInput'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'
import TextAreaInput from '@/components/common/form/TextAreaInput'
import CheckboxInput from '@/components/common/form/CheckboxInput'
import { recordToSelectOptionNumberLabel, userToSelectOption } from '@/utils/recordToSelectOption'


type Props = {
  instance?: IProject
  onSubmit: (cctvType: IProjectForm) => Promise<void>
  disabled: boolean
  cancelRoute: string
  isAudit?: boolean
}


/** Форма создания/редактирования проекта */
export default function ProjectForm({ instance, onSubmit, disabled, cancelRoute, isAudit }: Props) {
  const { form, setError } = useFormContext<IProjectForm>()

  function handleSubmit() {
    if (!form.name) setError('name', 'Обязательное поле')
    if (!form.serialCode) setError('serialCode', 'Обязательное поле')
    if (!form.serialOrderNumber) setError('serialOrderNumber', 'Обязательное поле')
    if (!form.type) setError('type', 'Обязательное поле')
    if (!form.responsible) setError('responsible', 'Обязательное поле')
    if (!form.name) return
    if (!form.serialCode) return
    if (!form.serialOrderNumber) return
    if (!form.type) return
    if (!form.responsible) return
    onSubmit(form)
  }

  return (
    <>
      <TextInput<IProjectForm>
        name='name'
        label='Имя проекта'
        initValue={instance?.name}
        required
        disabled={disabled} />
      <TextInput<IProjectForm>
        name='serialCode'
        label='Код серии'
        initValue={instance?.serialCode}
        required
        disabled={disabled} />
      <NumberInput<IProjectForm>
        name='serialOrderNumber'
        label='Порядковый номер (в пределах серии)'
        initValue={instance?.serialOrderNumber}
        required
        disabled={disabled} />
      <ApiResourceSelect<IProjectType, IProjectForm>
        apiResource={ApiResource.ProjectType}
        name='type'
        label='Тип проекта'
        initValue={instance?.type}
        required
        disabled={disabled} />
      <ApiResourceSelect<IContract, IProjectForm>
        apiResource={ApiResource.Contract}
        filter={{ identifier: ContractIdentifierTypes.video }}
        recordToOption={recordToSelectOptionNumberLabel}
        isMulti
        name='contracts'
        label='Номер ГК'
        initValue={instance?.contracts}
        disabled={disabled} />
      <TextAreaInput<IProjectForm>
        name='description'
        label='Описание'
        initValue={instance?.description}
        required
        disabled={disabled} />
      <ApiResourceSelect<IUser, IProjectForm>
        apiResource={ApiResource.User}
        recordToOption={userToSelectOption}
        async
        name='responsible'
        label='Ответственный'
        initValue={instance?.responsible as IUser}
        required
        disabled={disabled} />
      <CheckboxInput<IProjectForm>
        name='isArchive'
        label='Проект архивный'
        initValue={instance?.isArchive}
        disabled={disabled} />
      {isAudit &&
        <>
          <CheckboxInput<IProjectForm>
            name='isAuditDefault'
            label='Проект модуля "Сопровождение" по умолчанию'
            initValue={instance?.isAuditDefault}
            disabled={disabled}
          />
          <ApiResourceSelect<IProject, IProjectForm>
            apiResource={ApiResource.Project}
            filter={{ audit: 'false' }}
            name='auditMainProject'
            label='Основной проект'
            initValue={instance?.auditMainProject}
            disabled={disabled}
          />
        </>
      }
      <div className='btn-toolbar gap-3 mt-5'>
        <button className='btn btn-outline-primary'
          onClick={handleSubmit}
          disabled={disabled}>
          {instance ? 'Сохранить' : 'Создать'}
        </button>
        <Link to={cancelRoute}
          className='btn btn-outline-secondary'>
          Отмена
        </Link>
      </div>
    </>
  )
}

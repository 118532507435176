import { ChangeEventHandler } from 'react'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = {
	invalidMessage: string
	value: string
	onChange: ChangeEventHandler<HTMLInputElement>
}

export default function LoginInput({ invalidMessage, value, onChange }: Props) {
	const { loading } = useAuthContext()
	return (
		<div>
			<label htmlFor='login'>Логин</label>
			<input
				className={`form-control ${invalidMessage && 'is-invalid'}`}
				disabled={loading}
				name='login'
				type='text'
				value={value}
				onChange={onChange}
			/>
			{invalidMessage ? <div className='invalid-feedback'>{invalidMessage}</div> : null}
		</div>
	)
}
import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{}>

/** Ряд карточек дашборда проекта */
export default function CardRow({ children }: Props) {
	return (
		<div className='card-group mb-3'>
			{children}
		</div>
	)
}
import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IConstructionObject, IConstructionObjectForm } from '@/context/types'
import { ConstructionObjectTableColumnName, ConstructionObjectTableColumns } from '@/config/tables/lists/commutication/constructionObject'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Construction object list context */
const ConstructionObjectListContext = createContext({} as TableApiResourceContextValue<IConstructionObject, ConstructionObjectTableColumnName, IConstructionObjectForm>)


/** Construction object list context hook */
export const usePortListContext = () => useContext(ConstructionObjectListContext)


type Props = PropsWithChildren<{}>

/** Construction object list context provider */
export function ConstructionObjectListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IConstructionObject, ConstructionObjectTableColumnName, IConstructionObjectForm>
      Context={ConstructionObjectListContext}
      apiResource={ApiResource.ConstructionObject}
      loadIds
      recordIdKey='portId'
      tableColumns={ConstructionObjectTableColumns}
      logName='portList'
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}

import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react'
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import getErrorMessage from '@/utils/getErrorMessage'
import getAxiosPagination from '@/utils/getAxiosPagination'
import { ApiResource, ApiResourceFilter, IRecord } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import {
  getApiResourceCreateRoute, getApiResourceDeleteRoute, getApiResourceListRoute, getApiResourceReadRoute,
  getApiResourceUpdateRoute, routes,
} from '@/config/routes'
import { convertRecord } from '@/config/apiConverters'
import useDeepCompareCallback from '@/hooks/useDeepCompareCallback'
import usePagination from '@/hooks/usePagination'
import useStorageStateLog from '@/hooks/useStorageStateLog'
import {
  ApiResourceState, recordFailedAction, recordFetchedAction, recordListFailedAction, recordListFetchedAction,
  recordListLoadedAction, recordLoadedAction, useApiResourceReducer,
} from '@/context/apiResource/apiResourceStore'
import { ApiResources } from '@/config/apiResources'
import { AxiosRequestConfig } from 'axios'


/** API Resource context value interface */
interface IRecordListContextValue<TRecord extends IRecord, TRecordForm = TRecord> extends ApiResourceState<TRecord> {
  search: string | null
  loadRecordList: () => Promise<void>
  createRecord: (form: TRecordForm) => Promise<string | number>
  loadRecord: () => Promise<void>
  updateRecord: (form: TRecordForm, id?: string) => Promise<void>
  deleteRecord: (id: string) => Promise<void>
}


/** API Resource context */
const ApiResourceContext = createContext({} as IRecordListContextValue<any>)


/** API Resource context hook */
export const useApiResourceContext =
  <TRecord extends IRecord, TRecordForm = TRecord>() =>
    useContext<IRecordListContextValue<TRecord, TRecordForm>>(ApiResourceContext)


type Props = PropsWithChildren<{
  apiResource?: ApiResource
  recordId?: string | number
  filter?: ApiResourceFilter
  loadList?: boolean
}>

const defaultFilter = {}

/**
 * API Resource record list context provider
 * Uses URL params pagination an `recordId` router param
 */
export function ApiResourceContextProvider<TRecord extends IRecord, TRecordForm = TRecord>({
  apiResource,
  recordId,
  children,
  filter = defaultFilter,
  loadList = true
}: Props) {
  const [state, dispatch] = useApiResourceReducer<TRecord>()
  const pagination = usePagination(true)
  let params = useParams()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  const _recordId = recordId ?? params.recordId
  const _apiResource = apiResource ?? params.apiResource as ApiResource
  const search = searchParams.get('search')

  let sort = params.apiResource && pathname === routes.catalog(params.apiResource)
    ? ApiResources[params.apiResource as ApiResource]?.catalogListSort
    : undefined

  /** Api resource converter */
  const _convertRecord = useCallback((record: TRecord) => convertRecord(_apiResource, record), [_apiResource])

  /** Load record list */
  const loadRecordList = useDeepCompareCallback(async () => {
    dispatch(recordListFetchedAction())
    try {
      const params: AxiosRequestConfig<any>['params'] = {
        ...pagination,
        search: search,
        ...filter,
      }
      if (sort)
        params[`order[${sort.field}]`] = sort.direction

      const response = await axios.get(getApiResourceListRoute(_apiResource), { params })
      const recordList = response.data.map(_convertRecord)
      const paginationResponse = getAxiosPagination(response)
      dispatch(recordListLoadedAction(recordList, paginationResponse))
    } catch (e: any) {
      dispatch(recordListFailedAction(await getErrorMessage(e)))
    }
  }, [pagination, search, filter, _convertRecord, _apiResource, dispatch])

  /** Create record */
  const createRecord = useCallback(async (form: TRecordForm) => {
    const response = await axios.post(getApiResourceCreateRoute(_apiResource), form)
    return response.data.id ?? response.data.code
  }, [_apiResource])

  /** Load recrod */
  const loadRecord = useCallback(async () => {
    if (!_recordId) return
    dispatch(recordFetchedAction())
    try {
      const response = await axios.get(getApiResourceReadRoute(_apiResource, _recordId))
      const record = _convertRecord(response.data)
      dispatch(recordLoadedAction(record))
    } catch (e: any) {
      dispatch(recordFailedAction(await getErrorMessage(e)))
    }
  }, [_recordId, _convertRecord, _apiResource, dispatch, sort])

  /** Update current record */
  const updateRecord = useCallback(async (form: TRecordForm, recordId?: string | number) => {
    if (!recordId) recordId = _recordId
    if (!recordId) return
    await axios.patch(
      getApiResourceUpdateRoute(_apiResource, recordId),
      form,
      { headers: { 'Content-Type': 'application/merge-patch+json' } }
    )
  }, [_recordId, _apiResource])

  /** Delete recrod */
  const deleteRecord = useCallback(async (id: string) => {
    await axios.delete(getApiResourceDeleteRoute(_apiResource, id))
  }, [_apiResource])

  // Record list init load and reload
  useEffect(() => {
    if (loadList)
      loadRecordList()
  }, [loadList, loadRecordList])

  // Record init load and reload
  useEffect(() => {
    if (!_recordId) return
    loadRecord()
  }, [_recordId, loadRecord, _apiResource])

  const value: IRecordListContextValue<TRecord, TRecordForm> = useMemo(() => ({
    ...state,
    ...pagination,
    search,
    loadRecordList,
    createRecord,
    loadRecord,
    updateRecord,
    deleteRecord,
  }), [state, pagination, search, loadRecordList, createRecord, loadRecord, updateRecord, deleteRecord])

  useStorageStateLog(_apiResource, state)

  return (
    <ApiResourceContext.Provider value={value}>
      {children ?? <Outlet />}
    </ApiResourceContext.Provider>
  )
}
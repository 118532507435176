import FolderCard from '@/components/common/cards/FolderCard'
import { useChannelStatusListContext } from '@/context/statuses/ChannelStatusListContext'
import StatusIndicator from '@/components/common/cards/StatusIndicator'
import ChannelStatusControlMenu from '@/components/channels/Folder/statuses/ChannelStatusControlMenu'
import { IChannel } from '@/context/types'


type Props = {
  channel: IChannel
  reloadCard: () => void
}

const ChannelStatusCard = ({ channel, reloadCard }: Props) => {
  const { recordList: statusList } = useChannelStatusListContext()

  return (
    <FolderCard
      headerBgColor='#ffec99'
      bodyBgColor='#fff9db'
      header={`Статус работ по УНК-${channel.number}`}
    >
      <table className='table table-sm table-borderless mb-0'>
        <tbody>
          <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
            <td className=''>Канал связи</td>
            <td style={{ paddingLeft: '150px' }}>
              <StatusIndicator statusList={statusList} selectedStatusCode={channel.stage.code} />
            </td>
            <td style={{ width: '180px', margin: '0px', display: 'flex', justifyContent: 'flex-end' }}>
              <ChannelStatusControlMenu
                channel={channel}
                statusFieldName='stage'
                statusName={channel.stage.name}
                statusList={statusList}
                reloadCard={reloadCard}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </FolderCard>
  )
}

export default ChannelStatusCard

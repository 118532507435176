import { createContext, PropsWithChildren, ReactElement, useContext, useMemo, useRef } from 'react'
import { Map, YMaps, YMapsApi } from 'react-yandex-maps'
import { renderToString } from 'react-dom/server'


type Props = PropsWithChildren<{}>

type CreateLayout = (template: ReactElement, onBuild?: () => any) => any

const YandexMapContext = createContext({} as {
  createLayout: CreateLayout
})

export const useYandexMapContext = () => useContext(YandexMapContext)

export const YandexMapProvider = ({ children }: Props) => {
  const mapRef: YMapsApi = useRef(null)

  const createLayout: CreateLayout = (template, onBuild) => {
    const layout = mapRef.current?.templateLayoutFactory.createClass(
      template ? renderToString(template) : '',
      {
        build() {
          layout.superclass.build.call(this)
          onBuild?.()
        },
      },
    )

    return layout
  }

  const state = useMemo(() => ({
    center: [55.75, 37.57],
    zoom: 11,
    controls: [],
  }), [])

  return (
    <YandexMapContext.Provider value={{ createLayout }}>
      <YMaps query={{ apikey: '26742851-b589-4744-887a-8d20bf0a3868' }}>
        <Map
          width='100%'
          height='100%'
          onLoad={ymaps => mapRef.current = ymaps}
          state={state}
          modules={[
            'templateLayoutFactory',
            'layout.PieChart',
            'objectManager.addon.objectsBalloon',
            'objectManager.addon.clustersBalloon',
          ]}
        >
          {mapRef.current && children}
        </Map>
      </YMaps>
    </YandexMapContext.Provider>
  )
}

import { PropsWithChildren } from 'react'
import RequiredLabel from '@/components/common/RequiredLabel'


type Props = PropsWithChildren<{
  id: string
  label: string
  required?: boolean
  helpText?: string
  invalidMessage?: string
}>

/** Инпут-группа для контекстной формы */
export default function InputGroup({ id, label, required, helpText, invalidMessage, children }: Props) {
  return (
    <div className='mb-3'>
      <label className='form-label' htmlFor={id}>
        {label}
        {required && <RequiredLabel />}
      </label>
      {children}
      {invalidMessage &&
        <div className='d-block invalid-feedback'>{invalidMessage}</div>}
      {helpText &&
        <div className='form-text'>
          {helpText}
        </div>}
    </div>
  )
}

import { IMonitoringCctv, TableColumnConfig } from '@/context/types'
import {
  ProjectsMonitoringCctvTableColumnNameList, ProjectsMonitoringCctvTableColumns,
} from '@/config/tables/lists/projects/projectsMonitoringCctv'
import { NavLink } from 'react-router-dom'
import { routes } from '@/config/routes'


export const ExploitationMonitoringCctvTableColumnNameList = [
  'project',
  ...ProjectsMonitoringCctvTableColumnNameList,
] as const

export type ExploitationMonitoringCctvTableColumnName = typeof ExploitationMonitoringCctvTableColumnNameList[number]

export const ExploitationMonitoringCctvTableColumns: Record<ExploitationMonitoringCctvTableColumnName, TableColumnConfig<IMonitoringCctv>> = {
  project: {
    label: 'Проект',
    value: cctv => cctv.project.name,
    filterKey: 'project.name',
    sortKey: 'project.name',
  },
  ...ProjectsMonitoringCctvTableColumns,
  number: {
    ...ProjectsMonitoringCctvTableColumns.number,
    value: cctv => <NavLink to={routes.exploitationCctv(cctv.id)}>{cctv.number}</NavLink>,
  },
}

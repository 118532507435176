import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ApiResource, IPec } from '@/context/types'
import { PecTableColumnName, PecTableColumns } from '@/config/tables/lists/audit/pec'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Pec list context */
const PecListContext = createContext({} as TableApiResourceContextValue<IPec, PecTableColumnName>)


/** Pec list context hook */
export const usePecListContext = () => useContext(PecListContext)


type Props = PropsWithChildren<{}>

/** Pec list context provider */
export function PecListContextProvider({ children }: Props) {
  const { projectId } = useParams()

  return (
    <TableApiResourceContextProvider<IPec, PecTableColumnName>
      Context={PecListContext}
      apiResource={ApiResource.Pec}
      loadIds
      recordIdKey='pecId'
      tableColumns={PecTableColumns}
      logName='pecList'
      filter={{ 'project.id': projectId }}
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}

import { PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'


type Props = PropsWithChildren<{
	columnCount: number
}>

/** Строка пустой таблицы */
export default function EmptyTableRow({columnCount, children}: Props) {

	return (
		<tr>
			<td colSpan={columnCount}>
				<FontAwesomeIcon icon={faCircleInfo} className='text-primary me-2' />
				{children}
			</td>
		</tr>
	)
}
import { useCallback } from 'react'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useModal } from '@/context/ModalContext'
import { IPec, IPecStatusField } from '@/context/types'
import { editPecExecutor } from '@/components/modals/pec/modals'


type Props = {
  pec: IPec
  reloadCard: () => Promise<void>
  pecStatusFieldId: IPecStatusField['id']
  selectedOrganizationId: string | undefined
}

const PecExecutor = ({ pec, reloadCard, pecStatusFieldId, selectedOrganizationId }: Props) => {
  const { showModalDialog } = useModal()

  const handleEditExecutor = useCallback(() => {
    showModalDialog(editPecExecutor(pec, pecStatusFieldId, reloadCard, selectedOrganizationId))
  }, [showModalDialog, pec, pecStatusFieldId, reloadCard])

  const executor = pec.executors.find(e => e.statusFieldId === pecStatusFieldId)
  
  return (
    <div className='d-flex flex-row gap-2'>
      Исполнитель:
      <div>
        {executor ? `${executor.name} ${executor.telephoneNumber}` : '-'}
      </div>
      <button
        className='btn btn-sm btn-link p-0'
        onClick={() => handleEditExecutor()}
      >
        <FontAwesomeIcon icon={faPenToSquare} className='me-1' />
        Изменить
      </button>
    </div>
  )
}

export default PecExecutor

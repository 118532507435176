import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IServiceWithBilling, IServiceForm } from '@/context/types'
import {
  BillingExpenseTableColumnName, BillingExpenseTableColumns,
} from '@/config/tables/lists/commutication/billingExpense'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Billing cost list context */
const BillingExpenseListContext = createContext({} as TableApiResourceContextValue<IServiceWithBilling, BillingExpenseTableColumnName, IServiceForm>)


/** Billing cost list context hook */
export const useBillingExpenseListContext = () => useContext(BillingExpenseListContext)


type Props = PropsWithChildren<{}>

/** Billing cost list context provider */
export function BillingExpenseListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IServiceWithBilling, BillingExpenseTableColumnName, IServiceForm>
      Context={BillingExpenseListContext}
      apiResource={ApiResource.Service}
      loadIds
      recordIdKey='billingCostId'
      tableColumns={BillingExpenseTableColumns}
      requiredFilterColumns={['contractNumber']}
      logName='billingCostList'
      groups={['id:read', 'list:read', 'billing:read']}
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}
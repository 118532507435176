import { useEffect, useState } from 'react'
import { instanceAxios as axios } from '@/api/instanceAxios'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'
import { apiRoutes, getApiResourceListRoute } from '@/config/routes'
import { ApiResource, IChannelField, ISelectOption } from '@/context/types'
import defaultRecordToOption from '@/utils/recordToSelectOption'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IRecordListContext } from '@/context/recordList/RecordListContextProvider'


type Row = {
  channelField: ISelectOption | null
  sort: number
}

type Props = {
  loadChannelFieldList: IRecordListContext<any>['loadRecordList']
}

export const ChannelFieldsModal = ({ loadChannelFieldList }: Props) => {
  const { usePrimaryCallback, showModalDialog } = useModal()
  const [channelFields, setChannelFields] = useState([] as IChannelField[])
  const [rows, setRows] = useState([] as Row[])

  const handleAddField = () => setRows([...rows, { channelField: null, sort: 0 }])

  useEffect(() => {
    axios.get<IChannelField[]>(getApiResourceListRoute(ApiResource.ChannelField), { params: { pagination: false } })
      .then(res => setChannelFields(res.data))
  }, [])

  usePrimaryCallback(async () => {
    try {
      await axios.post(
        apiRoutes.channelFields,
        {
          fields: rows
            .filter(row => row.channelField)
            .map(row => ({
              channelFieldId: row.channelField!.value,
              name: row.channelField!.label,
              sort: row.sort
            }))
        }
      )

      void loadChannelFieldList()
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка',
        content: await getErrorMessage(e),
      })
    }
  }, [rows, loadChannelFieldList])

  useEffect(() => {
    const getMapEntry = (channelField: IChannelField) => channelField.id

    setRows(
      channelFields
        .filter(getMapEntry)
        .map(channelField => ({
          channelField: defaultRecordToOption(channelField),
          sort: channelField.sort
        }))
        .sort((a, b) => a.sort - b.sort)
    )
  }, [channelFields])

  const handleChange = (index: number, channelField: ISelectOption | null, sort: number) => {
    const newFields = [...rows]
    newFields[index] = { channelField: channelField || null, sort }
    setRows(newFields)
  }

  return (
    <>
      {rows.map((row, index) =>
        <div
          className='d-grid align-items-center mb-2 gap-2'
          style={{ gridTemplateColumns: '350px auto auto 15px' }}
          key={index}
        >
          <input
            className='form-control'
            type='text'
            placeholder='Поле канала'
            onChange={e => handleChange(index, { label: e.target.value, value: '' }, row.sort)}
            value={row.channelField?.label || ''}
          />
          <input
            className='form-control'
            type='number'
            placeholder='п/п'
            onChange={e => handleChange(index, row.channelField, +e.target.value)}
            value={row.sort || ''}
          />
          <div className='text-danger'>
            <FontAwesomeIcon
              icon={faTrash}
              style={{ cursor: 'pointer' }}
              onClick={() => setRows(rows => rows.filter(r => r !== row))}
            />
          </div>
        </div>
      )}
      <div className='mt-4'>
        <button onClick={handleAddField} className='btn btn-sm btn-outline-primary w-25'>
          <FontAwesomeIcon icon={faPlus} /> Добавить
        </button>
      </div>
    </>
  )
}

import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{
  fluid?: boolean
}>

export default function Main({ children, fluid = false }: Props) {
  return (
    <main className={`container${fluid ? '-fluid' : ''} py-5`}>
      {children}
    </main>
  )
}

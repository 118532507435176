import { UserRole as R } from '@/config/roles'


/** Автомат конечных состояний статусов работ
 *
 * Fsm[FromKey][FromCode][ToKey][ToCode] => FsmValue
 *
 */

const allRoles = Object.values(R)

type FsmValue = {
  roles: readonly R[]
  needComment?: boolean
}

type Fsm<T extends Record<string, string>> = {
  [FromKey in keyof T]: {
    [FromCode in T[FromKey]]: {
      [ToKey in keyof T]?: {
        [ToCode in ToKey extends FromKey ? Exclude<T[ToKey], FromCode> : T[ToKey]]?: FsmValue
      }
    }
  }
}

export const getFsmValue = <
  T extends Record<string, string>,
  F extends Fsm<T>,
  FK extends keyof F,
  FC extends keyof F[FK],
  TK extends keyof F[FK][FC],
  TC extends keyof F[FK][FC][TK],
>(fsm: F, fromKey: FK | string, fromCode: FC | string, toKey: TK | string, toCode: TC | string):
  NonNullable<F[FK][FC][TK]>[TC] | FsmValue | undefined  =>
  (fsm[fromKey as FK][fromCode as FC][toKey as TK] as F[FK][FC][TK] | undefined)?.[toCode as TC]

export const cctvStatusFsm = {
	channelStatus: {
		not_started: {
			channelStatus: {
				new_request: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		new_request: {
			channelStatus: {
				not_started: {
					roles: [R.admin, R.mgts],
				},
				in_work: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		in_work: {
			channelStatus: {
				new_request: {
					roles: [R.admin, R.mgts],
				},
				agreement: {
					roles: [R.admin, R.mgts],
				},
				archived: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		agreement: {
			channelStatus: {
				in_work: {
					roles: [R.admin, R.mgts],
				},
				organizing: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		organizing: {
			channelStatus: {
				agreement: {
					roles: [R.admin, R.mgts],
				},
				problem_solving: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		problem_solving: {
			channelStatus: {
				organizing: {
					roles: [R.admin, R.mgts],
				},
				organized: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		organized: {
			channelStatus: {
				problem_solving: {
					roles: [R.admin, R.mgts],
				},
				operation: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		operation: {
			channelStatus: {
				organized: {
					roles: [R.admin, R.mgts],
				},
				disabled: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		disabled: {
			channelStatus: {
				operation: {
					roles: [R.admin, R.mgts],
				},
				archived: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		archived: {
			channelStatus: {
				disabled: {
					roles: [R.admin, R.mgts],
				},
			}
		},
	},
	installationStatus: {
		not_started: {
			installationStatus: {
				working_draft: {
					roles: [R.admin],
				},
			}
		},
		working_draft: {
			installationStatus: {
				not_started: {
					roles: [R.admin],
				},
				construction: {
					roles: [R.admin, R.contractor],
				},
			}
		},
		construction: {
			installationStatus: {
				working_draft: {
					roles: [R.admin, R.contractor],
				},
				launch: {
					roles: [R.admin, R.contractor],
				},
			}
		},
		launch: {
			installationStatus: {
				construction: {
					roles: [R.admin, R.contractor],
				},
				audit: {
					roles: [R.admin, R.contractor],
				},
			}
		},
		audit: {
			installationStatus: {
				launch: {
					roles: [R.admin, R.contractor],
				},
				completed: {
					roles: [R.admin, R.mgts, R.contractor],
				},
			},
			acceptanceStatus: {
				object_acceptance: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		completed: {
			installationStatus: {
				audit: {
					roles: [R.admin, R.mgts, R.contractor],
				},
			}
		},
	},
	acceptanceStatus: {
		not_started: {
			acceptanceStatus: {
				snapshots_acceptance: {
					roles: [R.admin],
				},
			}
		},
		snapshots_acceptance: {
			acceptanceStatus: {
				not_started: {
					roles: [R.admin],
				},
				audit: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		audit: {
			acceptanceStatus: {
				snapshots_acceptance: {
					roles: [R.admin, R.mgts],
				},
				object_acceptance: {
					roles: [R.admin, R.mgts],
				},
				audit_correction: {
					roles: [R.admin, R.mgts, R.pm, R.pm_cost, R.pm_connection, R.pm_income],
				},
			},
			installationStatus: {
				audit: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		audit_correction: {
			acceptanceStatus: {
				snapshots_acceptance: {
					roles: [R.admin, R.mgts],
				},
				audit: {
					roles: [R.admin, R.mgts],
				},
				object_acceptance: {
					roles: [R.admin, R.pm, R.pm_cost, R.pm_income, R.pm_connection, R.contractor],
				},
			}
		},
		object_acceptance: {
			acceptanceStatus: {
				audit_correction:{
					roles: [R.admin, R.mgts],
				},
				snapshots_export: {
					roles: [R.admin, R.mgts],
				},
			}
		},
		snapshots_export: {
			acceptanceStatus: {
				object_acceptance: {
					roles: [R.admin, R.mgts],
				},
				completed: {
					roles: [R.admin],
				},
			}
		},
		completed: {
			acceptanceStatus: {
				audit: {
					roles: [R.admin],
				},
				object_acceptance: {
					roles: [R.admin],
				},
				snapshots_export: {
					roles: [R.admin],
				},
			}
		}
	},
	idAcceptanceStatus: {
		not_started: {
			idAcceptanceStatus: {
				uploaded: {
					roles: allRoles,
				}
			}
		},
		uploaded: {
			idAcceptanceStatus: {
				not_started: {
					roles: allRoles,
				},
				check: {
					roles: allRoles,
				}
			}
		},
		check: {
			idAcceptanceStatus: {
				uploaded: {
					roles: allRoles,
				},
				correction: {
					roles: allRoles,
				}
			}
		},
		correction: {
			idAcceptanceStatus: {
				check: {
					roles: allRoles,
				},
				repeated_check: {
					roles: allRoles,
				}
			}
		},
		repeated_check: {
			idAcceptanceStatus: {
				correction: {
					roles: allRoles,
				},
				acceptance: {
					roles: allRoles,
				}
			}
		},
		acceptance: {
			idAcceptanceStatus: {
				repeated_check: {
					roles: allRoles,
				}
			}
		},
	},
	installationPassivePartStatus: {
		not_started: {
			installationPassivePartStatus: {
				construction: {
					roles: [R.admin, R.contractor],
				}
			}
		},
		construction: {
			installationPassivePartStatus: {
				not_started: {
					roles: [R.admin, R.contractor],
				},
				acceptance: {
					roles: [R.admin, R.contractor],
				}
			}
		},
		acceptance: {
			installationPassivePartStatus: {
				construction: {
					roles: [R.admin, R.contractor],
				},
				transfer: {
					roles: [R.admin, R.contractor],
				}
			}
		},
		transfer: {
			installationPassivePartStatus: {
				acceptance: {
					roles: [R.admin, R.contractor],
				},
				completed: {
					roles: [R.admin, R.contractor],
				}
			}
		},
		completed: {
			installationPassivePartStatus: {
				transfer: {
					roles: [R.admin, R.contractor],
				}
			}
		},
	},
} as const satisfies Fsm<CctvStatusCodes>

export const channelStatusFsm = {
	stage: {
		new_request: {
			stage: {
				in_work: {
					roles: allRoles,
				},
			}
		},
		in_work: {
			stage: {
				new_request: {
					roles: allRoles,
				},
				agreement: {
					roles: allRoles,
				},
				archived: {
					roles: allRoles,
				},
			}
		},
		agreement: {
			stage: {
				in_work: {
					roles: allRoles,
          needComment: true,
				},
				organizing: {
					roles: allRoles,
				},
			}
		},
		organizing: {
			stage: {
				agreement: {
					roles: allRoles,
				},
				problem_solving: {
					roles: allRoles,
				},
			}
		},
		problem_solving: {
			stage: {
				organizing: {
					roles: allRoles,
				},
				organized: {
					roles: allRoles,
				},
			}
		},
		organized: {
			stage: {
				problem_solving: {
					roles: allRoles,
          needComment: true,
				},
				operation: {
					roles: allRoles,
				},
			}
		},
		operation: {
			stage: {
				organized: {
					roles: allRoles,
				},
				disabled: {
					roles: allRoles,
				},
			}
		},
		disabled: {
			stage: {
				operation: {
					roles: allRoles,
				},
				archived: {
					roles: allRoles,
				},
			}
		},
		archived: {
			stage: {
				disabled: {
					roles: allRoles,
				},
			}
		},
	},
} as const satisfies Fsm<ChannelStatusCodes>

export const pecStatusFsm = {
	any: {
		assigned: {
			any: {
				in_work: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				remarks: {
					roles: [R.admin, R.responsible_from_customer],
				},
				remarks_resolved: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				completed: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				work_accepted: {
					roles: [R.admin, R.responsible_from_customer],
				},
			}
		},
		in_work: {
			any: {
				assigned: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				remarks: {
					roles: [R.admin, R.responsible_from_customer],
				},
				remarks_resolved: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				completed: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				work_accepted: {
					roles: [R.admin, R.responsible_from_customer],
				},
			}
		},
		remarks: {
			any: {
				assigned: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				in_work: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				remarks_resolved: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				completed: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				work_accepted: {
					roles: [R.admin, R.responsible_from_customer],
				},
			}
		},
		remarks_resolved: {
			any: {
				assigned: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				in_work: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				remarks: {
					roles: [R.admin, R.responsible_from_customer],
				},
				completed: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				work_accepted: {
					roles: [R.admin, R.responsible_from_customer],
				},
			}
		},
		completed: {
			any: {
				assigned: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				in_work: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				remarks: {
					roles: [R.admin, R.responsible_from_customer],
				},
				remarks_resolved: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				work_accepted: {
					roles: [R.admin, R.responsible_from_customer],
				},
			}
		},
		work_accepted: {
			any: {
				assigned: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				in_work: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				remarks: {
					roles: [R.admin, R.responsible_from_customer],
				},
				remarks_resolved: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
				completed: {
					roles: [R.admin, R.responsible_from_customer, R.brigadier, R.executor],
				},
			}
		},
	},
} as const satisfies Fsm<PecStatusCodes>

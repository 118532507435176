import { ChangeEventHandler } from 'react'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = {
	name: string
	label: string
	value: string
	invalidMessage: string
	onChange: ChangeEventHandler<HTMLInputElement>
}


export default function PasswordInput({ name, label, value, invalidMessage, onChange }: Props) {
	const { loading } = useAuthContext()
	return (
		<div>
			<label htmlFor={name}>{label}</label>
			<input
				className={`form-control ${invalidMessage && 'is-invalid'}`}
				disabled={loading}
				name={name}
				// placeholder='******'
				type='password'
				value={value}
				onChange={onChange}
			/>
			{invalidMessage && <div className='invalid-feedback'> {invalidMessage} </div>}
		</div>
	)
}
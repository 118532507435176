import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { IChannelField, IChannelFieldForm } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'


/** ProjectField type context */
const ChannelFieldListContext = createContext({} as IRecordListContext<IChannelField, IChannelFieldForm>)


/** ProjectField type context hook */
export const useChannelFieldListContext = () => useContext(ChannelFieldListContext)


/** ProjectField type context provider props */
type Props = PropsWithChildren<{
  paginate?: boolean
}>


/** ProjectField type context provider */
export function ChannelFieldListContextProvider({ children, paginate = false }: Props) {
  return (
    <RecordListContextProvider<IChannelField, IChannelFieldForm>
      Context={ChannelFieldListContext}
      listApiRoute={apiRoutes.channelFields}
      deleteApiRoute={apiRoutes.channelField}
      paginate={paginate}
      logName='channelFieldList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}
import { IStatusStage } from '@/context/types'
import { IRecordListContext } from '@/context/recordList/RecordListContextProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCircle } from '@fortawesome/free-solid-svg-icons'
import { getTextColorClassName } from '@/utils/color'
import { useCallback } from 'react'
import recordNumberDeclension from '@/utils/recordNumberDecletion'
import { useModal } from '@/context/ModalContext'
import UpdateWorkStatusesModalContent, {
  Props as UpdateWorkStatusesModalContentProps,
} from '@/components/modals/common/UpdateWorkStatusesModalContent'
import { TableApiResourceContextValue } from '@/context/tableApiResource/TableApiResourceContext'


type ModalProps = Pick<UpdateWorkStatusesModalContentProps, 'recordApiRoute' | 'selectedRecordIdSet'>
type AnyTableApiResourceContextValue = TableApiResourceContextValue<any, any>

type Props<T extends StatusKey> = ModalProps & {
  fieldKey: T
  fieldLabelsRecord: Record<T, string>
  deselectAllRecords: AnyTableApiResourceContextValue['deselectAllRecords']
  loadRecordList: AnyTableApiResourceContextValue['loadRecordList']
  listContextHook: () => IRecordListContext<IStatusStage<any>, IStatusStage<any>> //todo-sem refactor after deleting status contexts
  hasEditPermission: boolean
}

export type WrapperTableStatusMenuProps<T extends StatusKey> = Pick<Props<T>, 'listContextHook' | 'fieldKey'>

const TableStatusMenu = <T extends StatusKey>({
  recordApiRoute,
  selectedRecordIdSet,
  fieldLabelsRecord,
  fieldKey,
  deselectAllRecords,
  loadRecordList,
  listContextHook,
  hasEditPermission,
}: Props<T>) => {
  const { recordList: statusList } = listContextHook()
  const { showModalDialog } = useModal()

  const onDone = useCallback(() => {
    deselectAllRecords()
    loadRecordList()
  }, [deselectAllRecords, loadRecordList])

  const fieldLabel = fieldLabelsRecord[fieldKey]

  const handleSetStatus = useCallback(async (status: IStatusStage) => {
    showModalDialog({
      type: 'okCancel',
      header: fieldLabel,
      content: `Вы уверены, что хотите переключить ${recordNumberDeclension(selectedRecordIdSet.size)} в статус «${status.name}»?`,
      primaryCallback: () => showModalDialog({
        type: 'abort',
        header: `${fieldLabel}: ${status.name}`,
        content: <UpdateWorkStatusesModalContent
          recordApiRoute={recordApiRoute}
          selectedRecordIdSet={selectedRecordIdSet}
          status={status}
          fieldKey={fieldKey}
          onDone={onDone}
        />,
      }),
    })
  }, [showModalDialog, fieldLabel, fieldKey, recordApiRoute, selectedRecordIdSet, onDone])

  if (!selectedRecordIdSet.size || !statusList.length || !hasEditPermission)
    return <>{fieldLabel}</>

  return (
    <div className='dropdown'>
      <button
        className='btn btn-link p-0 text-start fw-bold text-decoration-none'
        data-bs-toggle='dropdown'
      >
        {fieldLabel} <FontAwesomeIcon icon={faCaretDown} />
      </button>
      <ul className='dropdown-menu'>
        {statusList.map((status) =>
          <li key={status.code}>
            <button
              className='dropdown-item'
              type='button'
              onClick={() => handleSetStatus(status)}
            >
              <FontAwesomeIcon icon={faCircle} className={`${getTextColorClassName(status.code)} me-2`} />
              {status.name}
            </button>
          </li>)}
      </ul>
    </div>
  )
}

export default TableStatusMenu

import { useModal } from '@/context/ModalContext'
import getErrorMessage from '@/utils/getErrorMessage'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { ICctv, IChannel, IService } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { useAuthContext } from '@/context/auth/AuthContext'


type Props = {
  route: typeof apiRoutes['cctvChannels' | 'serviceChannels']
  reloadCard: () => Promise<void>
  referenceId: IService['id'] | ICctv['id']
  hasReservation?: boolean | null
}

const CreateChannelByReferenceBtn = ({ route, reloadCard, referenceId, hasReservation }: Props) => {
  const { showModalDialog } = useModal()
  const { hasPermission } = useAuthContext()

  const handleCreateChannel = async () => {
    try {
      const res = await axios.post<{ channels: IChannel[] }>(route, { reference: referenceId })
      const getChannelId = (reserve: boolean) => res.data.channels.find(c => c.reserve === reserve)?.id
      showModalDialog({
        type: 'ok',
        header: 'Канал создан',
        size: 'lg',
        content:
          <>
            <div>ID основного канала: {getChannelId(false)}</div>
            {hasReservation && <div>ID резервного канала: {getChannelId(true)}</div>}
          </>,
      })
      await reloadCard()
    } catch (e: any) {
      showModalDialog({
        type: 'ok',
        size: 'lg',
        header: 'Ошибка',
        content: await getErrorMessage(e),
      })
    }
  }

  return (
    <button
      className='btn btn-sm btn-primary py-0 rounded me-2'
      onClick={handleCreateChannel}
      disabled={!hasPermission('channelCreate')}
    >
      +ОПМ
    </button>
  )
}
export default CreateChannelByReferenceBtn

/**
 * Возвращает сообщение об ошибки из экземпляра `error`
 * или сообщение по умолчанию `defaultMessage = 'Произошла ошибка'`
 */
export default async function getErrorMessage(error: any, defaultMessage: string = 'Произошла ошибка') {
  let data = error.response?.data
  if (data instanceof Blob && data.type === 'application/json')
    data = JSON.parse(await data.text())
  
  return data?.error?.message ?? data?.detail ?? error.message ?? defaultMessage
}

import { useCallback } from 'react'
import { ProjectsSamplerGroupLabels } from '@/config/samplers'
import { useLocalStorage } from '@/hooks/useStorage'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import useTableSamplerForm from '@/hooks/useTableSamplerForm'
import { useModal } from '@/context/ModalContext'
import { useProjectContext } from '@/context/project/ProjectContext'
import Card from '@/components/ProjectsContent/DashBoard/Card'
import CardRecord from '@/components/ProjectsContent/DashBoard/CardRecord'
import CardRow from '@/components/ProjectsContent/DashBoard/CardRow'
import Loader from '@/components/common/Loader'
import Tooltip from '@/components/common/Tooltip'
import ProjectInfo from '@/components/ProjectsContent/DashBoard/ProjectInfo'
import { dashboardInfoModal } from '@/components/modals/common/info'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faInfoCircle, faTable } from '@fortawesome/free-solid-svg-icons'
import { useAuthContext } from '@/context/auth/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '@/config/routes'
import ProjectHeader from '@/components/ProjectsContent/ProjectHeader'
import { cctvStatusLabels } from '@/config/status/labels'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import { useQuery } from '@tanstack/react-query'
import { fetchProjectDashboard } from '@/api/queries/projects'
import { UserRole } from '@/config/roles'
import { useCctvListContext } from '@/context/tables/CctvListContext'
import { fetchExploitationDashboard } from '@/api/queries/exploitation'
import ExploitationHeader from '@/components/exploitation/ExploitationHeader'
import { IExploitationDashboard, IProjectDashboard } from '@/context/types'
import { typedObjectEntries } from '@/utils/object'
import { hoursTypeLabels } from '@/config/incidents'


/** Дашборд проект и сэмплер таблицы ТВН */
export const DashBoard = () => {
  const { hasRole } = useAuthContext()
  const { projectId } = useParams()
  const { isFetching: isLoading, data: dashboard, refetch } = useQuery({
    queryKey: ['project_or_exploitation_dashboard', projectId],
    queryFn: () => (
      projectId ? fetchProjectDashboard(projectId) : fetchExploitationDashboard()
    ) as Promise<IProjectDashboard | IExploitationDashboard>,
  })
  const { loadProject, project } = useProjectContext()
  const { setRecordListSampler: setCctvListSampler } = useCctvListContext()
  useDocumentTitle(projectId ? project && `Проект «${project.name}»` : 'Эксплуатация')
  const { showModalDialog } = useModal()
  // Форма сэмплера
  const { samplerForm, samplerLabel, updateSampler } = useTableSamplerForm(ProjectsSamplerGroupLabels)
  const navigate = useNavigate()
  const [isInfoShown, setIsInfoShown] = useLocalStorage('isProjectInfoShown', true)

  const route = projectId ? routes.projectCctvs(projectId) : routes.exploitationCctvs
  
  /** Применение локального сэмплера к таблице ТВН */
  const handleApplySampler = useCallback(() => {
    navigate(route)
    setCctvListSampler(samplerForm, samplerLabel)
  }, [navigate, route, samplerForm])

  const handeToggleInfo = useCallback(() => {
    setIsInfoShown(prev => !prev)
  }, [setIsInfoShown])

  const handleDashboardInfo = useCallback(() => {
    showModalDialog (dashboardInfoModal('ТВН'))
  }, [showModalDialog])

  const reload = () => {
    loadProject()
    refetch()
  }

  return (
    <>
      {projectId ? <ProjectHeader /> : <ExploitationHeader />}
      {isLoading || (projectId && !project) || !dashboard
        ? <div className='mt-5'><Loader /></div>
        : <div className='mt-2'>
          <div className={`container pt-3 ${project ? '' : 'mb-5'}`}>
            <div className='float-end'>
              <button
                className='btn btn-link p-0 me-2'
                onClick={handleDashboardInfo}
              >
                <FontAwesomeIcon icon={faInfoCircle} className='mx-2' />
                Справка
              </button>
              <ReloadBtn onClick={reload} />
            </div>
            {project &&
              <>
                <h1>
                  <small>Проект</small> «{project.name}»
                  <Tooltip title={`${isInfoShown ? 'Скрыть' : 'Показать'} инфо проекта`}>
                    <button
                      className='btn btn-link p-0 ms-2'
                      onClick={handeToggleInfo}
                    >
                      <FontAwesomeIcon icon={isInfoShown ? faChevronUp : faChevronDown} fixedWidth />
                    </button>
                  </Tooltip>
                </h1>
                {isInfoShown && <ProjectInfo />}
              </>
            }
          </div>
          <div className='container pb-5'>
            <CardRow>
              <Card header='Проекты'>
                <CardRecord label='Кол-во ТВН' count={dashboard.project.cctvsCount} route={route} />
                <CardRecord label='Кол-во открытых инцидентов' count={dashboard.project.incidentsOpenCount} />
                <CardRecord label='Кол-во эталонных изображений' count={dashboard.project.referencesSnapshotsCount} />
                <CardRecord label='Кол-во приложенных файлов' count={dashboard.project.filesCount} />
              </Card>
              <Card header='Оборудование'>
                <CardRecord label='Кол-во коммутаторов' count={dashboard.hardware.networkSwitchesCount} />
                <CardRecord label='Кол-во IP-камер' count={dashboard.hardware.ipCamerasCount} />
                <CardRecord
                  label='— из них с БЭИ ID и VSM KEY'
                  count={dashboard.hardware.ipCamerasWithStorageIdCount}
                  name='ipCamerasWithStorageId'
                  value={'true'}
                  samplerLabel='Есть IP-камеры с БЭИ ID и VSM KEY'
                  route={route}
                  updateSampler={updateSampler}
                />
                <CardRecord
                  label='— из них без БЭИ ID и VSM KEY'
                  count={dashboard.hardware.ipCamerasWithoutStorageIdCount}
                  name='ipCamerasWithStorageId'
                  value={'false'}
                  samplerLabel='Есть IP-камеры без БЭИ ID и VSM KEY'
                  route={route}
                  updateSampler={updateSampler}
                />
                <CardRecord label='Кол-во видеосерверов' count={dashboard.hardware.videoServersCount} />
              </Card>
            </CardRow>
            <CardRow>
              <Card header='Кол-во ТВН по операторам канала связи'>
                {dashboard.channelsOperators.map(operator =>
                  <CardRecord
                    key={operator.id}
                    label={operator.name}
                    count={operator.cctvsCount}
                    name='channelOperator.id'
                    value={operator.id}
                    route={route}
                    checked={samplerForm['channelOperator.id']?.has(operator.id) ?? false}
                    updateSampler={updateSampler}
                  />)}
              </Card>
              <Card header='Кол-во ТВН по районам'>
                {dashboard.districts.map(district =>
                  <CardRecord
                    key={district.id}
                    label={district.name}
                    count={district.cctvsCount}
                    name='address.district.id'
                    value={district.id}
                    route={route}
                    checked={samplerForm['address.district.id']?.has(district.id) ?? false}
                    updateSampler={updateSampler}
                  />)}
              </Card>
            </CardRow>
            <CardRow>
              {!hasRole(UserRole.contractor) &&
                <>
                  <Card header='Кол-во ТВН по подрядчикам СМР'>
                    {dashboard.constructionContractors.map(contractor =>
                      <CardRecord
                        key={contractor.id}
                        label={contractor.name}
                        count={contractor.cctvsCount}
                        name='constructionContractor.id'
                        value={contractor.id}
                        route={route}
                        checked={samplerForm['constructionContractor.id']?.has(contractor.id) ?? false}
                        updateSampler={updateSampler}
                      />)}
                  </Card>
                  <Card header='Кол-во ТВН по подрядчикам ПНР'>
                    {dashboard.maintenanceContractors.map(contractor =>
                      <CardRecord
                        key={contractor.id}
                        label={contractor.name}
                        count={contractor.cctvsCount}
                        name='maintenanceContractor.id'
                        value={contractor.id}
                        route={route}
                        checked={samplerForm['maintenanceContractor.id']?.has(contractor.id) ?? false}
                        updateSampler={updateSampler}
                      />)}
                  </Card>
                </>
              }
            </CardRow>
            <CardRow>
              <Card header='Кол-во ТВН по состоянию аудита'>
                <CardRecord label='Выполнен' count={dashboard.audit.completedCount} />
                <CardRecord label='Частично' count={dashboard.audit.partiallyCount} />
                <CardRecord label='Не выполнен' count={dashboard.audit.notCompletedCount} />
              </Card>
              <Card header={`Кол-во ТВН по статусу «${cctvStatusLabels.channelStatus}»`}>
                {dashboard.channelsStatuses.map(status =>
                  <CardRecord
                    icon
                    key={status.code}
                    label={status.name}
                    count={status.cctvsCount}
                    name='channelStatus.code'
                    value={status.code}
                    route={route}
                    checked={samplerForm['channelStatus.code']?.has(status.code) ?? false}
                    updateSampler={updateSampler}
                  />)}
              </Card>
            </CardRow>
            <CardRow>
              <Card header={`Кол-во ТВН по статусу «${cctvStatusLabels.installationStatus}»`}>
                {dashboard.installationStatuses.map(status =>
                  <CardRecord
                    icon
                    key={status.code}
                    label={status.name}
                    count={status.cctvsCount}
                    name='installationStatus.code'
                    value={status.code}
                    route={route}
                    checked={samplerForm['installationStatus.code']?.has(status.code) ?? false}
                    updateSampler={updateSampler}
                  />)}
              </Card>
              <Card header={`Кол-во ТВН по статусу «${cctvStatusLabels.acceptanceStatus}»`}>
                {dashboard.acceptanceStatuses.map(status =>
                  <CardRecord
                    icon
                    key={status.code}
                    label={status.name}
                    count={status.cctvsCount}
                    name='acceptanceStatus.code'
                    value={status.code}
                    route={route}
                    checked={samplerForm['acceptanceStatus.code']?.has(status.code) ?? false}
                    updateSampler={updateSampler}
                  />)}
              </Card>
            </CardRow>
            <CardRow>
              <Card header={`Кол-во ТВН по статусу «${cctvStatusLabels.idAcceptanceStatus}»`}>
                {dashboard.idAcceptanceStatuses?.map(status =>
                  <CardRecord
                    icon
                    key={status.code}
                    label={status.name}
                    count={status.cctvsCount}
                    name='idAcceptanceStatus.code'
                    value={status.code}
                    route={route}
                    checked={samplerForm['idAcceptanceStatus.code']?.has(status.code) ?? false}
                    updateSampler={updateSampler} />)}
              </Card>
              <Card header={`Кол-во ТВН по статусу «${cctvStatusLabels.installationPassivePartStatus}»`}>
                {dashboard.installationPassivePartStatuses?.map(status =>
                  <CardRecord
                    icon
                    key={status.code}
                    label={status.name}
                    count={status.cctvsCount}
                    name='installationPassivePartStatus.code'
                    value={status.code}
                    route={route}
                    checked={samplerForm['installationPassivePartStatus.code']?.has(status.code) ?? false}
                    updateSampler={updateSampler}
                  />)}
              </Card>
            </CardRow>
            {dashboard && 'incidentsByOpenDateTime' in dashboard && 
              <CardRow>
                <Card header={'Статистика по ТТ'}>
                  {typedObjectEntries(hoursTypeLabels).map(([type, label]) =>
                    <CardRecord
                      icon
                      key={type}
                      label={label}
                      count={dashboard.incidentsByOpenDateTime[type]}
                      value={type}
                      route={`${routes.exploitationIncidents}?hours_type=${type}`}
                    />
                  )}
                </Card>
                <div className='card' />
              </CardRow>
            }
            <div className='card w-100'>
              <div className='card-body'>
                <button
                  className='btn btn-outline-primary float-end'
                  onClick={handleApplySampler}
                >
                  <FontAwesomeIcon icon={faTable} className='me-2' />
                  Применить
                </button>
                {samplerLabel}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
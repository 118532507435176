import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@/components/common/Tooltip'
import { useState } from 'react'
import { IIPCamera } from '@/context/types'
import timestampedPath from '@/utils/timestampedPath'


type Props = {
  snapshotPath: IIPCamera['snapshotPath']
}

const IpCameraCardSnapshot = ({ snapshotPath }: Props) => {
  const getSrc = () => timestampedPath(snapshotPath)
  
  const [src, setSrc] = useState(getSrc())

  return (
    <div className='card border-secondary mb-2 mb-lg-0 h-100'>
      <img
        className='cam-online-picture card-img-top bg-secondary text-white h-100'
        style={{ minHeight: '245px', cursor: 'pointer' }}
        src={src}
        alt='Текущий снимок'
        onClick={() => setSrc(getSrc())}
      />
      <div className='card-body px-2 py-0 border-secondary border-top d-flex justify-content-between'>
        <div style={{ width: '1em' }}></div>
        <div>Текущий снимок</div>
        <div className='text-end' style={{ width: '1em' }}>
          <Tooltip title='Открыть снимок в новом окне'>
            <a href={src} target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default IpCameraCardSnapshot

import { useCallback } from 'react'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useModal } from '@/context/ModalContext'
import { IPec, IPecStatusField } from '@/context/types'
import { editPecBrigadier } from '@/components/modals/pec/modals'
 

type Props = {
  pec: IPec
  reloadCard: () => Promise<void>
  pecStatusFieldId: IPecStatusField['id']
  selectedOrganizationId: string | undefined
}

const PecBrigadier = ({ pec, reloadCard, pecStatusFieldId, selectedOrganizationId }: Props) => {
  const { showModalDialog } = useModal()

  const handleEditBrigadier = useCallback(() => {
    showModalDialog(editPecBrigadier(pec, pecStatusFieldId, reloadCard, selectedOrganizationId))
  }, [showModalDialog, pec, pecStatusFieldId, reloadCard])

  const brigadier = pec.brigadiers.find(e => e.statusFieldId === pecStatusFieldId)

  return (
    <div className='d-flex flex-row gap-2'>
      Бригадир:
      <div>
        {brigadier ? `${brigadier.name} ${brigadier.telephoneNumber}` : '-'}
      </div>
      <button
        className='btn btn-sm btn-link p-0'
        onClick={() => handleEditBrigadier()}
      >
        <FontAwesomeIcon icon={faPenToSquare} className='me-1' />
        Изменить
      </button>
    </div>
  )
}

export default PecBrigadier

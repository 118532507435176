import { Route, Routes } from 'react-router-dom'
import { routes } from '@/config/routes'
import GuestPageContainer from '@/components/common/GuestPageContainer'
import SignInPageContent from '@/components/auth/SignInPageContent'


export default function GuestRouter() {
  return (
    <Routes>
      <Route element={<GuestPageContainer />}>
        <Route path={routes.signIn} element={<SignInPageContent />} />
        <Route path='*' element={<SignInPageContent />} />
      </Route>
    </Routes>
  )
}

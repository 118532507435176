import { ISubReportClosedIncidents } from '@/components/reports/types'
import { colorsTable } from '@/components/reports/common/constants'
import { formatDate, formatDateTime } from '@/utils/formatters'
import { useParams } from 'react-router-dom'


type Props = {
  filterLabel: string
  reportDetailCloseTT: ISubReportClosedIncidents[]
  startDate: Date
  endDate: Date
}

export const SubReportClosedIncidents = ({ filterLabel, reportDetailCloseTT, startDate, endDate }: Props) => {
  const { projectId } = useParams()

  return (
    <div className='mt-4'>
      <h5>Детализация: Закрытые ТТ, попадавшие на этап</h5>
      <div>Дата создания отчёта: {formatDateTime(new Date)}</div>
      <div>Дата создания ТТ: c {formatDate(startDate)} по {formatDate(endDate)}</div>
      <div>{filterLabel}</div>
      <table className="table table-bordered table-sm mt-4">
        <thead className="text-muted"
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {!projectId && <th>Проект</th>}
            <th>ТТ</th>
            <th>№ ТВН</th>
            <th>Район</th>
            <th>Адрес</th>
            <th>Оборудование</th>
            <th>Первый комментарий</th>
            <th>Последний комментарий</th>
            <th>Система</th>
            <th>ID инцидента в системе источника</th>
            <th>Пользователь</th>
            <th>Открыт</th>
            <th>Закрыт</th>
            <th>Время всего (ч)</th>
            <th>Этап</th>
            <th>Времени на этапе (ч)</th>
            <th>Время попадания на этап</th>
            <th>Время ухода с этапа</th>
          </tr>
        </thead>
        <tbody>
          {reportDetailCloseTT.map(row =>
            <tr key={row.incident_id + row.stage_name} style={{ backgroundColor: '#f1f3f5' }}>
              {!projectId && <td className="text-end">{row.project_name}</td>}
              <td className="text-end">{row.incident_id}</td>
              <td className="text-end">
                {row.cctvs_numbers}
              </td>
              <td>{row.area_name}</td>
              <td>{row.address_name}</td>
              <td>{row.hardware_name}</td>
              <td>{row.incident_first_comment}</td>
              <td>{row.incident_last_comment}</td>
              <td>{row.origin_name}</td>
              <td>{row.origin_id}</td>
              <td>{row.user_name}</td>
              <td className="text-nowrap">{new Date(row.incident_open_datetime).toLocaleString('ru')}</td>
              <td className="text-nowrap">{new Date(row.incident_closed_datetime).toLocaleString('ru')}</td>
              <td className="text-end">{row.incident_sum_time_hours_count}</td>
              <td>{row.stage_name}</td>
              <td className="text-end">{row.sum_time_hours_count}</td>
              <td className="text-nowrap">{new Date(row.begin_datetime).toLocaleString('ru')}</td>
              <td className="text-nowrap">{new Date(row.end_datetime).toLocaleString('ru')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

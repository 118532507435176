import { FormEvent, useState } from 'react'
import getErrorMessage from '@/utils/getErrorMessage'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useAuthContext } from '@/context/auth/AuthContext'
import Alert from '@/components/common/Alert'
import AuthFormContainer from '@/components/auth/common/AuthFormContainer'
import Button from '@/components/auth/common/Button'
import LoginInput from '@/components/auth/common/LoginInput'
import PasswordInput from '@/components/auth/common/PasswordInput'


export default function SignInPageContent() {
  const { signIn } = useAuthContext()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [loginInvMsg, setLoginlInvMsg] = useState('')
  const [passwordInvMsg, setPasswordInvMsg] = useState('')

  useDocumentTitle('Вход')

  function onLoginChange(event: FormEvent<HTMLInputElement>) {
    setLogin(event.currentTarget.value)
    if (loginInvMsg) setLoginlInvMsg('')
  }

  function onPasswordChange(event: FormEvent<HTMLInputElement>) {
    setPassword(event.currentTarget.value)
    if (passwordInvMsg) setPasswordInvMsg('')
  }

  async function onSignInSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!login) return setLoginlInvMsg('Введите логин')
    if (!password) return setPasswordInvMsg('Введите пароль')
    try {
      if (errorMsg) setErrorMsg('')
      if (loginInvMsg) setLoginlInvMsg('')
      if (passwordInvMsg) setPasswordInvMsg('')
      await signIn(login, password)
    } catch (e: any) {
      if (e.code?.includes('login')) setLoginlInvMsg(e.message)
      else if (e.code?.includes('password')) setPasswordInvMsg(e.message)
      else setErrorMsg(await getErrorMessage(e))
    }
  }

  return (
    <AuthFormContainer onSubmit={onSignInSubmit}>
      <h2 className='text-center mt-4 mb-0'>
        Вход
      </h2>
      {errorMsg && <Alert>{errorMsg}</Alert>}
      <LoginInput invalidMessage={loginInvMsg} value={login} onChange={onLoginChange} />
      <PasswordInput
        invalidMessage={passwordInvMsg}
        label='Пароль'
        name='password'
        value={password}
        onChange={onPasswordChange}
      />
      <Button>Войти</Button>
    </AuthFormContainer>
  )
}

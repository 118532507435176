import { ShowModalDialogParams } from '@/context/ModalContext'
import { ApiResource } from '@/context/types'
import { InfoModalImportText, Props as ModalImportTextProps } from '@/components/modals/common/ModalImportText'
import { faCaretDown, faTable, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropsWithChildren, ReactNode } from 'react'
import { cctvStatusLabels } from '@/config/status/labels'


const Btn = ({ children, className = '', isRed }: PropsWithChildren<{ className?: string, isRed?: boolean }>) =>
  <button
    className={`btn btn-sm d-inline-flex align-items-center btn-outline-${isRed ? 'danger' : 'primary'} ${className}`}
    style={{ height: '22px', cursor: 'default' }}
    type='button'
  >
    {children}
  </button>

const ApplyBtn = () => <Btn className='ms-1'><FontAwesomeIcon icon={faTable} className='me-1' />Применить</Btn>

const Checkbox = () => <input type='checkbox' checked readOnly className='form-check-input' />

const P = ({ children }: PropsWithChildren<{}>) => <p className='mb-2'>{children}</p>

export type FilterInfoModalParams = Pick<ModalImportTextProps, 'templateLinkParams'> & {
  showMassEditInfo?: boolean
  importApiResource?: ApiResource
}

export const tableFilterInfoModal = (name: string, params?: FilterInfoModalParams): ShowModalDialogParams => ({
  type: 'ok',
  size: 'lg',
  header: `Справка по работе с таблицей ${name}`,
  content:
    <>
      <h5>Фильтрация</h5>
      <P>
        Для фильтрации {name} в нужных столбцах в поле «Фильтр» введите нужные значения и нажмите клавишу "⏎" (Enter).
      </P>
      <P>Для сброса выборки нажмите кнопку «Сбросить выборку».</P>
      <P>Для сброса фильтра нажмите кнопку «Сбросить фильтр».</P>
      <P>Фильтр <code>!</code> выбирает записи с пустыми значениями в данном столбце.</P>
      <P>Фильтр <code>АБВ!</code> выбирает записи со значением данного столбца точно равным <code>АБВ</code>.</P>
      <P>
        Фильтр <code>АБВ|ГДЕ</code> выбирает записи со значением данного столбца включающим <code>АБВ</code> или
        <code className='ms-1'>ГДЕ</code>.
      </P>
      <P>
        Фильтр <code>АБВ&ГДЕ</code> выбирает записи со значением данного столбца включающим <code>АБВ</code> и
        <code className='ms-1'>ГДЕ</code>.
      </P>
      <P>
        Фильтр <code>АБВ?</code> Выбирает записи со значениями данного столбца исключающим <code>АБВ</code>
      </P>
      <P>Регистр не имеет значения.</P>
      {params?.showMassEditInfo &&
        <>
          <h5 className='mt-4'>Массовое редактирование</h5>
          <P>
            Выберите несколько записей с помощью чекбоксов (<Checkbox />). При нажатии на чекбокс в заголовке таблицы
            "Выбрать все" будут выбраны все записи, включая записи с других страниц.
          </P>
          <P>
            Заголовки столбцов, где доступно массовое редактирование, будут подсвечены синим цветом со стрелкой
            (например, <span className='text-primary'>{cctvStatusLabels.channelStatus}
            <FontAwesomeIcon icon={faCaretDown} className='ms-2' /></span>).
          </P>
          <P>Для удаления выбранных записей нажмите <Btn isRed><FontAwesomeIcon icon={faTrash} /></Btn></P>
        </>
      }
      {params?.importApiResource &&
        <InfoModalImportText
          importApiResource={params.importApiResource}
          templateLinkParams={params.templateLinkParams}
        />
      }
    </>,
})

const searchInfoModal = (content: ReactNode): ShowModalDialogParams => ({
  type: 'ok',
  header: 'Справка по поисковой строке',
  content,
})

export const projectSearchInfoModal = (): ShowModalDialogParams => searchInfoModal(
  <>
    <P>Для перехода в карточку ТВН введите номер и нажмите <Btn>ТВН №</Btn></P>
    <P>Для поиска оборудования введите IP-адрес и нажмите <Btn>IP</Btn></P>
    <P>Для поиска ТВН по адресу введите полный или частичный адрес и нажмите <Btn>Адрес</Btn></P>
    <P>Для поиска инцидента введите номер и нажмите <Btn>ТТ</Btn></P>
  </>,
)

export const serviceSearchInfoModal = (): ShowModalDialogParams => searchInfoModal(
  <>
    <P>Для перехода в карточку услуги введите номер привязанного канала и нажмите <Btn>УНК</Btn></P>
    <P>Для перехода в карточку услуги введите номер и нажмите <Btn>№ услуги</Btn></P>
    <P>Для поиска услуги по ID ЕЦХД введите ID и нажмите <Btn>ID ЕЦХД</Btn></P>
    <P>Для поиска услуги по адресу введите полный или частичный адрес и нажмите <Btn>Адрес</Btn></P>
    <P>Для поиска по клиенту введите наименование и нажмите <Btn>Департамент</Btn></P>
    <P>Для поиска по объекту А введите наименование и нажмите <Btn>Потребитель</Btn></P>
  </>,
)

export const channelSearchInfoModal = (): ShowModalDialogParams => searchInfoModal(
  <>
    <P>Для перехода в карточку канала введите номер и нажмите <Btn>УНК</Btn></P>
    <P>Для поиска канала по ID ЕЦХД введите ID и нажмите <Btn>ID ЕЦХД</Btn></P>
    <P>Для поиска канала по адресу введите полный или частичный адрес и нажмите <Btn>Адрес</Btn></P>
  </>,
)

export const dashboardInfoModal = (name: string): ShowModalDialogParams => ({
  type: 'ok',
  header: 'Справка по работе с дашбордом',
  content:
    <>
      <P>
        Для перехода в неотфильтрованную таблицу {name} нажмите на кол-во {name}
        <span className='btn-link text-decoration-none ms-1'>2345</span>
      </P>
      <P>
        Для перехода в отфильтрованную таблицу {name} выберите параметры с помощью чекбоксов (<Checkbox />) и нажмите
        <ApplyBtn />
      </P>
      {name === 'ТВН'
        ? <P>
          Если нужен фильтр только по одному параметру, то можно перейти в отфильтрованную
          таблицу {name} нажатием на цифру слева от чекбокса
        </P>
        : <P>
          Для перехода в отфильтрованную таблицу каналов выберите параметры в блоке
          "Кол-во каналов по статусу каналов связи" с помощью чекбоксов (<Checkbox />) и нажмите<ApplyBtn />
        </P>
      }
    </>,
})

import { PropsWithChildren, useId, useMemo, useState } from 'react'
import { Button } from 'react-yandex-maps'
import { createPortal } from 'react-dom'
import { useYandexMapContext } from '@/context/YandexMapContext'
import Control from '@/components/ProjectsContent/monitoring/map/controls/Control'


type Props = YControlOptions & PropsWithChildren<{}>

const CustomControl = ({ float, floatIndex, children }: Props) => {
  const id = useId()
  const [isBtnCreated, setIsBtnCreated] = useState(false)
  const { createLayout } = useYandexMapContext()

  const layout = useMemo(() => (
    createLayout(<div id={id} />, () => setIsBtnCreated(true))
  ), [createLayout, id])

  return (
    <>
      <Button options={{ float, floatIndex, layout }} />
      {isBtnCreated && createPortal(<Control>{children}</Control>, document.getElementById(id)!)}
    </>
  )
}

export default CustomControl

import { IServiceWithBilling, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { ServiceTableColumnNameList, ServiceTableColumns } from '@/config/tables/lists/commutication/service'
import { formatDate, formatPrice } from '@/utils/formatters'


export const BillingCommonTableColumnNameList = ServiceTableColumnNameList

export type BillingCommonTableColumnName = typeof BillingCommonTableColumnNameList[number]

export const BillingCommonTableColumns: Record<BillingCommonTableColumnName, TableColumnConfig<IServiceWithBilling>> = {
  ...ServiceTableColumns,
  contractNumber: {
    ...ServiceTableColumns.contractNumber,
    hidden: true,
  },
}

export const prepareBillingCommonAdditionalCols = (service: IServiceWithBilling) => {
  const { billing, isSitronicsPort, channels } = service
  if (!billing)
    return []

  const cols: TableAdditionalColumnConfig[] = []

  billing.reportingPeriods.map(rp => {
    cols.push(
      {
        label: 'Дата начала ОП',
        key: rp.id,
        value: formatDate(rp.beginDate),
      },
      {
        label: 'Дата окончания ОП',
        key: rp.id,
        value: formatDate(rp.endDate),
      },
      {
        label: 'Кол-во дней в ОП',
        key: rp.id,
        value: rp.days,
      },
      {
        label: 'Кол-во дней работы услуги в данном ОП',
        key: rp.id,
        value: rp.serviceDays,
      },
      {
        label: 'Доход за данный ОП',
        key: rp.id,
        value: formatPrice(rp.revenue),
      },
      {
        label: 'Расход за данный ОП',
        key: rp.id,
        value: isSitronicsPort
          ? formatPrice(rp.constructionObjectExpenses)
          : channels.map(channel => formatPrice(rp.channelExpenses[channel.id])),
      }
    )

    if (rp.lastForYear)
      cols.push(
        {
          label: `Итоговый доход за конец ${rp.lastForYear} года по данной услуге`,
          value: formatPrice(billing.years[rp.lastForYear!].revenue),
        },
        {
          label: `Итоговый расход за конец ${rp.lastForYear} года по данному каналу`,
          value: channels.map(channel => formatPrice(billing.years[rp.lastForYear!].channelExpenses[channel.id])),
        }
      )
  })

  cols.push(
    {
      label: 'Доход-расход за все ОП',
      value: formatPrice(billing.total.income),
    },
    {
      label: 'Окупаемость',
      value: formatPrice(billing.total.payback),
    }
  )

  return cols
}

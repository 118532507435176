import { faDesktop, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


type Props = {
  handleClick: (e: any) => void
  data: string | number
  exportApiRoute?: string
}

export const Dropdown = ({ exportApiRoute, handleClick, data }: Props) => {
  if (!data)
    return <>0</>

  if (!exportApiRoute)
    return <>{data}</>

  return (
    <div className="dropdown">
      <a className="dropdown" href="/" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">{data}</a>
      <div className="dropdown-menu shadow" aria-labelledby="dropdownMenuLink" style={{ position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(132px, 21px, 0px)' }}>
        <div className="dropdown-header">Детализация</div>
        <div className="dropdown-divider"></div>
        <button onClick={handleClick} type="button" className="dropdown-item">
          <FontAwesomeIcon icon={faDesktop} /> Показать
        </button>
        <a
          href={exportApiRoute}
          target={'_blank'}
          rel='noreferrer'
          className='dropdown-item'
        >
          <FontAwesomeIcon icon={faFileExcel} className='me-2' />
          Excel
        </a>
      </div>
    </div>
  )
}

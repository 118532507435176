import { CardTableRowConfig, IBaseHardware } from '@/context/types'
import { Link } from 'react-router-dom'
import { getApiResourceDetailsPath } from '@/config/routes'
import { hardwareApiResources } from '@/config/hardware'
import HardwareModel from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardwareList/HardwareModel'


export const hardwareCommonTableRows: Record<string, CardTableRowConfig<IBaseHardware>> = {
  ipAddress: {
    label: 'IP адрес',
    value: hardware =>
      <Link
        to={getApiResourceDetailsPath(hardwareApiResources[hardware.type.code], hardware.id)}
        target='_blank'
      >
        {hardware.ipAddress || 'н/д'}
      </Link>,
  },
  subnetwork: {
    label: 'Подсеть',
    value: hardware => hardware.subnetwork,
  },
  model: {
    label: 'Модель',
    value: hardware => <HardwareModel hardware={hardware} />,
  },
  firmware: {
    label: 'Прошивка',
    value: hardware => hardware.firmware?.name ?? 'н/д',
  },
  status: {
    label: 'Статус',
    value: hardware => hardware.status.name ?? 'н/д',
  },
  id: {
    label: 'ID оборудования',
    value: hardware => hardware.id,
  },
  networkSwitch: {
    label: 'Подключено к коммутатору',
    value: ({ networkSwitchPort: port }) => port
      ? `${port.networkSwitch.ipAddress}${port.portNumber ? `, порт ${port.portNumber}` : ''}`
      : '',
  },
  address: {
    label: 'Месторасположение',
    value: hardware => hardware.address.name,
  },
  locationDescription: {
    label: 'Описание месторасположения',
    value: hardware => hardware.locationDescription,
  },
}

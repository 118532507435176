import { useState } from 'react'
import { colorsTable } from '@/components/reports/common/constants'
import { ReportWrapper } from '@/components/reports/common/ReportWrapper'
import { IReportES } from '@/components/reports/types'
import { useParams } from 'react-router-dom'


export const ReportIncidents = () => {
  const { projectId } = useParams()
  const [report, setReport] = useState<IReportES[]>([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  return (
    <ReportWrapper
      code='incidents'
      dataRange
      {...{ report, setReport, setEndDate, setStartDate, startDate, endDate }}
    >
      <table className='table table-bordered table-sm mt-4'>
        <thead
          className='text-muted'
          style={{ backgroundColor: colorsTable.main, position: 'sticky', top: '176px' }}
        >
          <tr>
            {!projectId && <th>Проект</th>}
            <th style={{ verticalAlign: 'bottom' }}>№ п/п</th>
            <th style={{ verticalAlign: 'bottom' }}>Оператор канала</th>
            <th style={{ verticalAlign: 'bottom' }}>Округ</th>
            <th style={{ verticalAlign: 'bottom' }}>Номер ТВН</th>
            <th style={{ verticalAlign: 'bottom' }}>Тип комиссии (УИК/ТИК)</th>
            <th style={{ verticalAlign: 'bottom' }}>Адрес нахождения аварийной ситуации</th>
            <th style={{ verticalAlign: 'bottom' }}>ID инцидента в нашей системе</th>
            <th style={{ verticalAlign: 'bottom' }}>ID инцидента в системе источника</th>
            <th style={{ verticalAlign: 'bottom' }}>Создано</th>
            <th style={{ verticalAlign: 'bottom' }}>Описание аварийной ситуации (причина аварии)</th>
            <th style={{ verticalAlign: 'bottom' }}>IP-адрес устройства</th>
            <th style={{ verticalAlign: 'bottom' }}>MAC-адрес устройства</th>
            <th style={{ verticalAlign: 'bottom' }}>Дата и время начала аварии</th>
            <th style={{ verticalAlign: 'bottom' }}>Дата и время ликвидации аварийной ситуации</th>
          </tr>
        </thead>
        <tbody>
          {report.map((row, index) => (
            <tr key={index}>
              {!projectId && <td>{row.project_name}</td>}
              <td style={{ verticalAlign: 'top' }}>{row.order_number}</td>
              <td style={{ verticalAlign: 'top' }}>{row.operator_channel_name}</td>
              <td style={{ verticalAlign: 'top' }}>{row.area_name}</td>
              <td style={{ verticalAlign: 'top' }}>{row.cctv_number}</td>
              <td style={{ verticalAlign: 'top' }}>{row.cctv_type_name}</td>
              <td style={{ verticalAlign: 'top' }}>{row.address_name}</td>
              <td style={{ verticalAlign: 'top' }}>{row.id}</td>
              <td style={{ verticalAlign: 'top' }}>{row.origin_id}</td>
              <td style={{ verticalAlign: 'top' }}>{row.created_by_name}</td>
              <td style={{ verticalAlign: 'top', whiteSpace: 'pre-wrap' }}>{row.open_description}</td>
              <td style={{ verticalAlign: 'top' }}>{row.hardware_ip_address}</td>
              <td style={{ verticalAlign: 'top' }}>{row.hardware_mac_address}</td>
              <td style={{ verticalAlign: 'top' }}>{row.open_datetime}</td>
              <td style={{ verticalAlign: 'top' }}>{row.closed_datetime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ReportWrapper>
  )
}

import { IChannelWithPayments, TableAdditionalColumnConfig, TableColumnConfig } from '@/context/types'
import { DateRange, generateMonthsRange } from '@/utils/date'
import { ChannelTableColumnName, ChannelTableColumns } from '@/config/tables/lists/channels/channel'
import { organizationHasVatLabels } from '@/config/boolLabels'


export type ChannelPaymentsTableColumnName = typeof ChannelPaymentsTableColumnNameList[number]

export const ChannelPaymentsTableColumnNameList = [
  'number',
  'operator',
  'agreement',
  'purchaseNumber',
  'contractNumber',
  'serviceNumber',
  'customer',
  'projectCode',
  'bAddress',
  'channelCapacity',
  'vlan',
  'paymentType',
  'processBeginDate',
  'processEndDate',
] as const

const pickFromChannel = (key: ChannelTableColumnName, label?: string) => {
  const col = ChannelTableColumns[key]
  return { ...col, label: label ?? col.label }
}

export const ChannelPaymentsTableColumns: Record<ChannelPaymentsTableColumnName, TableColumnConfig<IChannelWithPayments>> = {
  number: pickFromChannel('number'),
  operator: pickFromChannel('operator'),
  agreement: pickFromChannel('agreement', '№ договора с АО'),
  purchaseNumber: pickFromChannel('purchaseNumber'),
  contractNumber: pickFromChannel('contractNumber', '№ ГК'),
  serviceNumber: {
    label: 'Номер услуги по ГК',
    value: channel => !channel.isCctvChannel && channel.service.number,
    filterAndSortViewKey: 'view_channel:serviceNumber',
  },
  customer: pickFromChannel('customer', 'Департамент'),
  projectCode: pickFromChannel('projectCode'),
  bAddress: pickFromChannel('bAddress', 'Адрес услуги'),
  channelCapacity: pickFromChannel('channelCapacity', 'Скор. (Мбит/с)'),
  vlan: pickFromChannel('vlan'),
  paymentType: {
    label: 'Тип платежа',
    value: () => ['Ежемесячный', 'Инсталляционный'],
  },
  processBeginDate: pickFromChannel('processBeginDate'),
  processEndDate: pickFromChannel('processEndDate'),
}

export const prepareChannelPaymentsAdditionalCols = (channel: IChannelWithPayments, paymentsRange: DateRange) => {
  const cols: TableAdditionalColumnConfig[] = []

  for (const monthDate of generateMonthsRange(paymentsRange.from, paymentsRange.to)) {
    const payments = channel.channelPayments
      .filter(({ channelPaymentPeriod: period }) =>
        period.month === monthDate.getMonth() + 1 && period.year === monthDate.getFullYear(),
      )

    const subscriptionPayment = payments.find(p => p.channelPaymentType.code === 'subscription')
    const installationPayment = payments.find(p => p.channelPaymentType.code === 'installation')

    const key = monthDate.toString()

    cols.push(
      {
        label: 'Сумма',
        key,
        value: [subscriptionPayment?.sum ?? 0, installationPayment?.sum ?? 0],
      },
      {
        label: 'Номер заявки Jira',
        key,
        value: [subscriptionPayment?.number, installationPayment?.number],
      },
      {
        label: 'С НДС?',
        key,
        value: channel.operator?.hasVat ? organizationHasVatLabels.get(channel.operator.hasVat) : '',
      },
      {
        label: 'Статус оплаты',
        key,
        value: [subscriptionPayment?.comment, installationPayment?.comment],
      },
    )
  }

  return cols
}

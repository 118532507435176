import { CardTableRowConfig, ICommunicationNetworkSwitch } from '@/context/types'
import { videoNetworkSwitchTableRows } from '@/config/tables/cards/hardware/videoNetworkSwitch'
import HardwareSerial from '@/components/CctvListPageContent/Cctv/Folder/blocks/hardwareList/HardwareSerial'


export const communicationNetworkSwitchTableRows: Record<string, CardTableRowConfig<ICommunicationNetworkSwitch>> = {
  ...videoNetworkSwitchTableRows,
  serialNumber: {
    label: 'Серийный номер',
    value: networkSwitch => <HardwareSerial hardware={networkSwitch} />,
  },
}

import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import { routes } from '@/config/routes'
import ProjectBreadcrumbsItem from '@/components/breadcrumbs/ProjectBreadcrumbsItem'
import ProjectToolbar from '@/components/ProjectsContent/ProjectToolbar/ProjectToolbar'
import ProjectNav from '@/components/CctvListPageContent/ProjectNav'


const ProjectHeader = () =>
  <>
    <BreadcrumbsContainer>
      <BreadcrumbsItem path={routes.projects}>Проекты</BreadcrumbsItem>
      <ProjectBreadcrumbsItem />
    </BreadcrumbsContainer>
    <ProjectToolbar />
    <ProjectNav />
  </>

export default ProjectHeader

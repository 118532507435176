import { memo, useMemo, useRef } from 'react'
import { ObjectManager } from 'react-yandex-maps'
import { renderToString } from 'react-dom/server'
import PlacemarkIconContent from '@/components/ProjectsContent/monitoring/map/geo-objects/cctvs/PlacemarkIconContent'
import BalloonContent from '@/components/ProjectsContent/monitoring/map/geo-objects/cctvs/BalloonContent'
import { containerTopOffset, placemarkPresets } from '@/components/ProjectsContent/monitoring/map/config'
import { hydrateCctvBalloonContent } from '@/components/ProjectsContent/monitoring/map/utils/hydrateCctvBalloonContent'
import { routes } from '@/config/routes'
import useProjectId from '@/hooks/useProjectId'
import { useNavigate, useParams } from 'react-router-dom'
import { useModal } from '@/context/ModalContext'
import { MonitoringMapCctv, ObjectManagerCctvFeature } from '@/components/ProjectsContent/monitoring/map/types/cctv'
import IpCameraCardSnapshot from '@/components/common/cards/hardware/IpCameraCardSnapshot'
import { incidentDetailModal } from '@/components/modals/incidents/modals'


type Props = {
  cctvs: MonitoringMapCctv[]
  displayCctvIds: MonitoringMapCctv['id'][]
  loadCctvs: () => any
}

const Cctvs = memo(({ cctvs, displayCctvIds, loadCctvs }: Props) => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { showModalDialog } = useModal()

  const features: ObjectManagerCctvFeature[] = useMemo(() => (
    cctvs.map(cctv => ({
      type: 'Feature',
      id: cctv.id + cctv.hardwareState + !cctv.incidents.length,
      options: { preset: placemarkPresets[cctv.hardwareState] },
      geometry: {
        type: 'Point',
        coordinates: [+cctv.address.gpsLatitude.replace(',', '.'), +cctv.address.gpsLongitude.replace(',', '.')],
      },
      properties: {
        cctvId: cctv.id,
        clusterCaption: cctv.number,
        iconContent: renderToString(<PlacemarkIconContent cctv={cctv} />),
        balloonContentBody: renderToString(<BalloonContent cctv={cctv} />),
      },
    }))
  ), [cctvs])

  const objectManagerRef = useRef<any>()

  const handleBalloonOpen = (e: any) => {
    const objectId = e.get('objectId')
    const clusters = objectManagerRef.current.clusters
    const isObjectCluster = !!clusters.getById(objectId)
    const cctvFeature: ObjectManagerCctvFeature = isObjectCluster
      ? clusters.state.get('activeObject')
      : objectManagerRef.current.objects.getById(objectId)

    const cctvId = cctvFeature.properties.cctvId

    const hydrate = () => hydrateCctvBalloonContent({
      onCctvClick: () => navigate(
        projectId ? routes.projectCctv(projectId, cctvId) : routes.exploitationCctv(cctvId)
      ),
      onRtspClick: () => navigate(
        projectId ? routes.projectCctvStream(projectId, cctvId) : routes.exploitationCctvStream(cctvId)
      ),
      onIpCameraClick: snapshotPath =>
        showModalDialog({
          size: 'lg',
          type: 'close',
          header: 'Текущий снимок',
          content: <IpCameraCardSnapshot snapshotPath={snapshotPath} />,
        }),
      onIncidentClick: id => showModalDialog(incidentDetailModal(projectId, id, loadCctvs)),
    })

    if (isObjectCluster) {
      const balloonEvents = clusters.balloon.events
      const clusterEvents = clusters.state.events

      const onBalloonClose = () => {
        balloonEvents.remove('close', onBalloonClose)
        clusterEvents.remove('change', hydrate)
      }

      balloonEvents.add('close', onBalloonClose)
      clusterEvents.add('change', hydrate)
    }

    hydrate()
  }

  return (
    <ObjectManager
      instanceRef={instance => objectManagerRef.current = instance}
      options={{
        clusterize: true,
        clusterDisableClickZoom: true,
        clusterIconLayout: 'default#pieChart',
        clusterBalloonContentLayoutHeight: Math.min(window.innerHeight - containerTopOffset - 100, 450),
      }}
      features={features}
      filter={feature => displayCctvIds.includes((feature as ObjectManagerCctvFeature).properties.cctvId)}
      onBalloonOpen={handleBalloonOpen}
    />
  )
})

export default Cctvs

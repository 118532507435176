export const colorsTable = {
  lightGrey: '#f1f3f5',
  grey: '#e9ecef',
  green: '#b2f2bb',
  red: '#ffc9c9',
  lightGreen: '#ebfbee',
  lightRed: '#fff5f5',
  blue: '#a5d8ff',
  lightBlue: '#d0ebff',
  main: '#e7f5ff',
}

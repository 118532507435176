import { PropsWithChildren, useState } from 'react'
import CustomControl from '@/components/ProjectsContent/monitoring/map/controls/CustomControl'


type Props = YControlOptions & PropsWithChildren<{
  label: string
  title: string
}>

const ExpandableControl = ({ float, floatIndex, label, title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <CustomControl {...{ float, floatIndex }}>
      <div
        className='d-flex align-items-center justify-content-end px-2 fw-bold'
        onClick={() => setIsOpen(p => !p)}
        style={{
          borderBottom: isOpen ? '1px solid #0d6efd' : 'initial',
          minHeight: 31,
        }}
        role='button'
        title={title}
      >
        {label}
      </div>
      {isOpen && <div className='p-1' style={{width: 455}}>{children}</div>}
    </CustomControl>
  )
}

export default ExpandableControl

import { useReducer } from 'react'


/** Any form store actions */
type AnyFormStoreAction = {
	type: 'setState'
	payload: any
} | {
	type: 'setField'
	payload: {
		key: string
		value: any
	}
}


/** Any form store reducer */
function anyFormStoreReducer(state: any, { type, payload }: AnyFormStoreAction): any {
	switch (type) {
		case 'setField':
			return { ...state, [payload.key]: payload.value }
		case 'setState':
			return { ...payload }
	}
}


/** Any form store state & reducer hook */
export const useAnyFormStoreReducer = () => useReducer(anyFormStoreReducer, {} as any)


// Any form store action creators

/** Any form store set state action creator */
export const anyFormChangedAction =
	(form: any): AnyFormStoreAction =>
		({ type: 'setState', payload: form })
/** Any form store set field action creator */
export const anyFormFieldValueChangedAction =
	(key: string, value: any): AnyFormStoreAction =>
		({ type: 'setField', payload: { key, value } })
import { useState } from 'react'
import { ApiResource, Hardware, IIncident, IIncidentCreateForm } from '@/context/types'
import { getApiResourceCreateRoute } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import getErrorMessage from '@/utils/getErrorMessage'
import { useModal } from '@/context/ModalContext'
import { useFormContext } from '@/context/form/FormContext'
import TextAreaInput from '@/components/common/form/TextAreaInput'
import ApiResourceSelect from '@/components/common/form/ApiResourceSelect'


type Props = {
	hardware: Hardware
	onCreate: () => void
}


/** Форма создания инцидента */
export default function IncidentCreateForm({ hardware, onCreate }: Props) {
	const { showModalDialog, usePrimaryCallback } = useModal()
	const { form, setError } = useFormContext<IIncidentCreateForm>()
	const [isLoading, setIsLoading] = useState(false)

	usePrimaryCallback(async () => {
		if (!form.stage) setError('stage', 'Выберите этап')
		if (!form.description) setError('description', 'Введите комментарий')
		if (!form.stage || !form.description) return false
		try {
			setIsLoading(true)
			await axios.post(
				getApiResourceCreateRoute(ApiResource.Incident),
				{
					...form,
					hardware: hardware.id
				}
			)
			onCreate()
		} catch (e: any) {
			showModalDialog({
				type: 'error',
				header: 'Ошибка',
				content: await getErrorMessage(e),
			})
		} finally {
			setIsLoading(false)
		}
	}, [form, hardware, onCreate, showModalDialog])

	return (
		<>
			<ApiResourceSelect<NonNullable<IIncident['stage']>, IIncidentCreateForm>
				apiResource={ApiResource.IncidentStage}
				label='Этап'
				name='stage'
				required
				disabled={isLoading} />
			<TextAreaInput<IIncidentCreateForm>
				label='Комментарий'
				name='description'
				required
				disabled={isLoading} />
		</>
	)
}
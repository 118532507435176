import { createContext, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IServiceWithBilling, IServiceForm } from '@/context/types'
import {
  BillingCommonTableColumnName, BillingCommonTableColumns,
} from '@/config/tables/lists/commutication/billingCommon'
import {
  TableApiResourceContextProvider, TableApiResourceContextValue,
} from '@/context/tableApiResource/TableApiResourceContext'


/** Billing common list context */
const BillingCommonListContext = createContext({} as TableApiResourceContextValue<IServiceWithBilling, BillingCommonTableColumnName, IServiceForm>)


/** Billing common list context hook */
export const useBillingCommonListContext = () => useContext(BillingCommonListContext)


type Props = PropsWithChildren<{}>

/** Billing common list context provider */
export function BillingCommonListContextProvider({ children }: Props) {
  return (
    <TableApiResourceContextProvider<IServiceWithBilling, BillingCommonTableColumnName, IServiceForm>
      Context={BillingCommonListContext}
      apiResource={ApiResource.Service}
      loadIds
      recordIdKey='billingCommonId'
      tableColumns={BillingCommonTableColumns}
      requiredFilterColumns={['contractNumber']}
      logName='billingCommonList'
      groups={['id:read', 'list:read', 'billing:read']}
    >
      {children ?? <Outlet />}
    </TableApiResourceContextProvider>
  )
}
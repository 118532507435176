import { useCallback, useEffect, useState } from 'react'
import FilterControl from '@/components/ProjectsContent/monitoring/map/controls/filter/FilterControl'
import { IArea } from '@/context/types'
import { instanceAxios as axios } from '@/api/instanceAxios'
import { apiRoutes } from '@/config/routes'
import { useModal } from '@/context/ModalContext'
import Areas from '@/components/ProjectsContent/monitoring/map/geo-objects/Areas'
import SearchControl from '@/components/ProjectsContent/monitoring/map/controls/SearchControl'
import { YandexMapProvider } from '@/context/YandexMapContext'
import ReloadControl from '@/components/ProjectsContent/monitoring/map/controls/ReloadControl'
import IndicatorsControl from '@/components/ProjectsContent/monitoring/map/controls/IndicatorsControl'
import Cctvs from '@/components/ProjectsContent/monitoring/map/geo-objects/cctvs/Cctvs'
import FullscreenControl from '@/components/ProjectsContent/monitoring/map/controls/FullscreenControl'
import getErrorMessage from '@/utils/getErrorMessage'
import ProjectHeader from '@/components/ProjectsContent/ProjectHeader'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { useTabsContext } from '@/context/TabsContext'
import { MonitoringMapCctv } from '@/components/ProjectsContent/monitoring/map/types/cctv'
import { convertMonitoringMapCctv } from '@/config/apiConverters'
import { containerTopOffset } from '@/components/ProjectsContent/monitoring/map/config'
import { useParams } from 'react-router-dom'
import ExploitationHeader from '@/components/exploitation/ExploitationHeader'


const MonitoringCctvMap = () => {
  const { projectId } = useParams()
  const { showModalDialog } = useModal()
  const { setLabel } = useTabsContext()

  // cctvs ➜ <FilterControl /> ➜ filteredCctvs ➜ <SearchControl /> ➜ displayCctvIds ➜ <Cctvs />
  const [cctvs, setCctvs] = useState([] as MonitoringMapCctv[])
  const [filteredCctvs, setFilteredCctvs] = useState([] as MonitoringMapCctv[])
  const [displayCctvIds, setDisplayCctvIds] = useState([] as MonitoringMapCctv['id'][])

  const [displayAreaCodes, setDisplayAreaCodes] = useState([] as IArea['code'][])
  const [isLoading, setIsLoading] = useState(true)

  useDocumentTitle('Карта ТВН')
  setLabel('Карта ТВН', faHeart)

  const loadCctvs = useCallback(async () => {
    setIsLoading(true)

    try {
      const res = await axios.get<MonitoringMapCctv[]>(
        projectId ? apiRoutes.projectMonitoringMap(projectId) : apiRoutes.exploitationMonitoringMap
      )
      setCctvs(res.data.map(convertMonitoringMapCctv))
    } catch (e: any) {
      showModalDialog({
        type: 'error',
        header: 'Ошибка получения данных',
        content: await getErrorMessage(e),
      })
    }

    setIsLoading(false)
  }, [setIsLoading, projectId, setCctvs, showModalDialog])

  useEffect(() => {
    void loadCctvs()
  }, [loadCctvs])

  return (
    <>
      {projectId ? <ProjectHeader /> : <ExploitationHeader />}
      <div className='mt-2' style={{ height: `calc(100vh - ${containerTopOffset}px)` }}>
        <YandexMapProvider>
          <ReloadControl isLoading={isLoading} floatIndex={10} onTimerInterval={loadCctvs} />
          <IndicatorsControl
            isLoading={isLoading}
            cctvs={cctvs}
            filteredCctvs={filteredCctvs}
            onReloadClick={loadCctvs}
          />

          <FullscreenControl float='right' floatIndex={10} />
          <SearchControl
            float='right'
            floatIndex={20}
            filteredCctvs={filteredCctvs}
            onDisplayCctvsChange={setDisplayCctvIds}
          />
          <FilterControl
            float='right'
            floatIndex={30}
            cctvs={cctvs}
            onDisplayAreasChange={setDisplayAreaCodes}
            onFilteredCctvsChange={setFilteredCctvs}
          />

          <Areas displayAreaCodes={displayAreaCodes} />
          <Cctvs cctvs={cctvs} displayCctvIds={displayCctvIds} loadCctvs={loadCctvs} />
        </YandexMapProvider>
      </div>
    </>
  )
}

export default MonitoringCctvMap

import { useCallback } from 'react'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useModal } from '@/context/ModalContext'
import { IPec, IPecStatusField } from '@/context/types'
import { editPecContractor } from '@/components/modals/pec/modals'


type Props = {
  pec: IPec
  reloadCard: () => Promise<void>
  pecStatusFieldId: IPecStatusField['id']
}

const PecContractor = ({ pec, reloadCard, pecStatusFieldId }: Props) => {
  const { showModalDialog } = useModal()

  const handleEditContractor = useCallback(() => {
    showModalDialog(editPecContractor(pec, pecStatusFieldId, reloadCard))
  }, [showModalDialog, pec, pecStatusFieldId, reloadCard])

  const contractor = pec.contractors.find(e => e.statusFieldId === pecStatusFieldId)

  return (
    <div className='d-flex flex-row gap-2'>
      ПО:
      <div>
      {contractor ? `${contractor.name} ${contractor.name}` : '-'}
      </div>
      <button
        className='btn btn-sm btn-link p-0'
        onClick={() => handleEditContractor()}
      >
        <FontAwesomeIcon icon={faPenToSquare} className='me-1' />
        Изменить
      </button>
    </div>
  )
}

export default PecContractor

import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { IProject, IProjectField, IProjectFieldForm } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'
import useProjectId from '@/hooks/useProjectId'


/** ProjectField type context */
const ProjectFieldListContext = createContext({} as IRecordListContext<IProjectField, IProjectFieldForm>)


/** ProjectField type context hook */
export const useProjectFieldListContext = () => useContext(ProjectFieldListContext)


/** ProjectField type context provider props */
type Props = PropsWithChildren<{
  paginate?: boolean
  projectId: IProject['id']
}>

/** ProjectField type context provider */
export function ProjectFieldListContextProvider({ children, paginate = false, projectId }: Props) {
  const filter = useMemo(() => ({ 'projectsMapEntries.project.id': projectId }), [projectId])

  return (
    <RecordListContextProvider<IProjectField, IProjectFieldForm>
      Context={ProjectFieldListContext}
      listApiRoute={apiRoutes.projectFields}
      deleteApiRoute={apiRoutes.projectField}
      paginate={paginate}
      filter={filter}
      logName='projectFieldList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}

export const ProjectFieldListContextProviderWrapper = (
  props: Omit<Props, 'projectId'>
) => {
  const projectId = useProjectId()
  return <ProjectFieldListContextProvider {...props} projectId={projectId} />
}

import { useCallback, useEffect, useState } from 'react'
import { IPec } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { instanceAxios as axios } from '@/api/instanceAxios'
import Loader from '@/components/common/Loader'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { useTabsContext } from '@/context/TabsContext'
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import AuditProjectHeader from '@/components/audit/AuditProjectHeader'
import PecInfoCard from '@/components/audit/Folder/PecInfoCard'
import { convertPec } from '@/config/apiConverters'
import { usePecStatusFieldListContext } from '@/context/entityFields/PecStatusFieldListContext'
import PecStatusesInfoCard from '@/components/audit/Folder/statuses_info_card/PecStatusInfoCard'
import CommentsCard from '@/components/CctvListPageContent/Cctv/Folder/blocks/CommentsCard'
import { useAuthContext } from '@/context/auth/AuthContext'
import { UserRole } from '@/config/roles'
import PermissionRequired from '@/components/auth/PermissionRequired'


const PecPageContent = () => {
  const { pecId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [pec, setPec] = useState<IPec>()
  const { setLabel } = useTabsContext()
  const { hasPermission } = useAuthContext()
  const { loadRecordList: loadPecStatusFields } = usePecStatusFieldListContext()
  useDocumentTitle(pec && `УИК ${pec.number}`)

  const reloadPec = useCallback(async (isReload = true) => {
    try {
      setIsLoading(true)
      setPec(undefined)
      const response = await axios.get(apiRoutes.pec(pecId!))
      const pec: IPec = convertPec(response.data)
      setPec(pec)

      setLabel(pec?.number, faEye)

      if (isReload)
        await loadPecStatusFields()
    } catch (e: any) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [pecId, loadPecStatusFields])

  useEffect(() => {
    reloadPec(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pecId])

  return (
    <>
      <AuditProjectHeader />
      {isLoading || !pec
        ? <div className='container-fluid pt-5'><Loader /></div>
        : <>
          <div className='container-fluid mt-2'>
            <div className='d-flex justify-content-end my-2'>
              <div className='text-end' style={{ width: '2em' }}>
                <ReloadBtn onClick={reloadPec} />
              </div>
            </div>
            <div className='row g-2'>
              <div className='col-xl'>
                <PermissionRequired permission={['pecEdit', 'pecBrigadierEdit']}>
                  <PecInfoCard pec={pec} reloadPec={reloadPec} />
                </PermissionRequired>
                <CommentsCard
                  item={pec}
                  reloadCard={reloadPec}
                  canCreate={hasPermission('pecCommentCreate')}
                  canDelete={hasPermission('pecCommentDelete')}
                />
              </div>
              <div className='col-xl'>
                <PecStatusesInfoCard pec={pec} reloadCard={reloadPec} />
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default PecPageContent

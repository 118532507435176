import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'


type Props = {
	lastPage?: number
	disabled?: boolean
}


/** Блок пагинации */
export default function Pagination({ lastPage = 1, disabled = false }: Props) {
	const [searchParams, setSearchParams] = useSearchParams()
	const page = +(searchParams.get('page') ?? 1)

	const pageNumList = useMemo(() => {
		const pageNumList: number[] = []
		for (var pageNum = 1; pageNum <= lastPage!; pageNum++)
			if (pageNum <= 3 || pageNum > (lastPage - 3) || Math.abs(pageNum - page) < 3)
				pageNumList.push(pageNum)
			else if (pageNumList[pageNumList.length - 1])
				pageNumList.push(0)
		return pageNumList
	}, [page, lastPage])

	if (disabled || lastPage <= 1) return null

	const handlePageClick = (pageNum: number) => () => {
		searchParams.set('page', `${pageNum}`)
		setSearchParams(searchParams)
	}

	return (
		<nav>
			<ul className='pagination'>
				{pageNumList.map((pageNum, index) =>
					<li
						className={`page-item ${pageNum ? (pageNum === page && 'active') : 'disabled'}`}
						key={index} >
						{pageNum
							? <button
								className='page-link'
								onClick={handlePageClick(pageNum)}>
								{pageNum}
							</button>
							: <span className='page-link'>...</span>}
					</li>
				)}
			</ul>
		</nav>
	)
}
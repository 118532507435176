import Tooltip from '@/components/common/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { getTextColorClassName } from '@/utils/color'
import { IStatusStage } from '@/context/types'


type Props<T extends IStatusStage> = {
  statusList: T[]
  selectedStatusCode?: T['code']
}

const StatusIndicator = <T extends IStatusStage>({ selectedStatusCode, statusList }: Props<T>) =>
  <>
    {statusList.map(({ code, name }) =>
      <Tooltip key={code + name} title={name}>
        <span className='me-2'>
          <FontAwesomeIcon
            icon={faCircle}
            className={selectedStatusCode === code ? getTextColorClassName(code) : 'text-muted opacity-50'}
          />
        </span>
      </Tooltip>,
    )}
  </>

export default StatusIndicator

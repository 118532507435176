import { IBaseHardware } from '@/context/types'
import { useModal } from '@/context/ModalContext'
import { useCallback } from 'react'
import { modelNameHistory } from '@/components/modals/hardware/modals'


const HardwareModel = ({ hardware }: { hardware: IBaseHardware }) => {
  const { showModalDialog } = useModal()

  const handleShowModelNameHistory = useCallback(() => {
    showModalDialog(modelNameHistory(hardware))
  }, [showModalDialog, hardware])

  return <div className='d-flex justify-content-between'>
    <div>{hardware.model.name}</div>
    {!!hardware.logModelNamesEntries.length &&
      <button className='btn btn-sm btn-link p-0 ps-2' onClick={handleShowModelNameHistory}>
        История изменений
      </button>
    }
  </div>
}

export default HardwareModel

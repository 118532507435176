import { instanceAxios as axios } from '@/api/instanceAxios'
import { IChannel, IRecordWithId, IService } from '@/context/types'
import { apiRoutes, StringApiRoute } from '@/config/routes'
import { initialServiceTotals } from '@/config/tables/lists/commutication/service'
import { initialChannelTotals } from '@/config/tables/lists/channels/channel'


type InitialToType<T extends Record<string, 0>> = Record<keyof T, number>

export type TableTotals<TRecord extends IRecordWithId> =
  IsAny<TRecord> extends never
    ? TRecord extends IService
      ? InitialToType<typeof initialServiceTotals>
      : TRecord extends IChannel
        ? InitialToType<typeof initialChannelTotals>
        : never
    : never

const fetchTotals = async <TRecord extends IRecordWithId, Totals = TableTotals<TRecord>>(
  initialData: Totals,
  route: StringApiRoute,
  ids: Set<TRecord['id']>,
): Promise<Totals> => {
  if (!ids.size)
    return initialData

  const res = await axios.post<Totals>(route, { ids: [...ids] })
  return res.data
}

export const fetchServiceTableTotals = async (ids: Set<IService['id']>) =>
  fetchTotals(initialServiceTotals, apiRoutes.serviceTotals, ids)

export const fetchChannelTableTotals = async (ids: Set<IChannel['id']>) =>
  fetchTotals(initialChannelTotals, apiRoutes.channelTotals, ids)

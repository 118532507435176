import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { ApiResource, IPecStatusField, IPecStatusFieldForm, IProject } from '@/context/types'
import { getApiResourceDeleteRoute, getApiResourceListRoute } from '@/config/routes'
import { IRecordListContext, RecordListContextProvider } from '@/context/recordList/RecordListContextProvider'
import useProjectId from '@/hooks/useProjectId'


/** PecStatusField type context */
const PecStatusFieldListContext = createContext({} as IRecordListContext<IPecStatusField, IPecStatusFieldForm>)


/** PecStatusField type context hook */
export const usePecStatusFieldListContext = () => useContext(PecStatusFieldListContext)


/** PecStatusField type context provider props */
type Props = PropsWithChildren<{
  paginate?: boolean
  projectId: IProject['id']
}>


/** PecStatusField type context provider */
export function PecStatusFieldListContextProvider({ children, paginate = false, projectId }: Props) {
  const filter = useMemo(() => ({ 'projects.id': projectId }), [projectId])

  return (
    <RecordListContextProvider<IPecStatusField, IPecStatusFieldForm>
      Context={PecStatusFieldListContext}
      listApiRoute={getApiResourceListRoute(ApiResource.PecStatusFields)}
      deleteApiRoute={id => getApiResourceDeleteRoute(ApiResource.PecStatusFields, id)}
      paginate={paginate}
      filter={filter}
      logName='pecStatusFieldList'
    >
      {children ?? <Outlet />}
    </RecordListContextProvider>
  )
}

export const PecStatusFieldListContextProviderWrapper = (
  props: Omit<Props, 'projectId'>,
) => {
  const projectId = useProjectId()
  return <PecStatusFieldListContextProvider {...props} projectId={projectId} />
}

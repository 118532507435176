import { Hardware, IBaseHardware, ICctv, IHardwareSet, IIPCamera, IPec } from '@/context/types'
import { ShowModalDialogParams } from '@/context/ModalContext'
import { getHardwareLabel } from '@/utils/hardware'
import { FormContextProvider } from '@/context/form/FormContext'
import HardwareStatusForm from '@/components/modals/hardware/HardwareStatusForm'
import IpCameraInfoForm from '@/components/modals/hardware/IpCameraInfoForm'
import IpCameraVideoMarkerForm from '@/components/modals/hardware/IpCameraVideoMarkerForm'
import BindHardwareTable from '@/components/modals/hardware/BindHardwareTable'
import { getItemLabel } from '@/utils/tools'
import ChangeCameraOrderModal from './ChangeCameraOrderModal'


/** Окно привязки оборудования */
export function bindHardwareModal(item: ICctv | IPec, hardwareSet: IHardwareSet, reloadCard: () => Promise<void>): ShowModalDialogParams {
	return {
		type: 'saveCancel',
		size: 'xl',
		header: `Привязка оборудования к ${getItemLabel(item)} ${item.number}`,
    content: <BindHardwareTable item={item} hardwareSet={hardwareSet} reloadCard={reloadCard} />,
	}
}

/** Окно редактирования статуса оборудования в ТВН */
export function editHardwareStatusModal(hardware: Hardware, reloadCctv: () => Promise<void>): ShowModalDialogParams {
	return {
		type: 'saveCancel',
		dismissable: false,
		header: `${getHardwareLabel(hardware)}: Статус`,
		content:
			<FormContextProvider key={hardware.id}>
				<HardwareStatusForm
					hardware={hardware}
					reloadCctv={reloadCctv} />
			</FormContextProvider>
	}
}

/** Окно редактирования инфо IP-камеры */
export function editIpCameraInfoModal(ipCamera: IIPCamera, reloadCctv: () => Promise<void>): ShowModalDialogParams {
	return {
		type: 'saveCancel',
		dismissable: false,
		header: `${getHardwareLabel(ipCamera)}: Сведения`,
		content:
			<FormContextProvider>
				<IpCameraInfoForm
					ipCamera={ipCamera}
					reloadCctv={reloadCctv} />
			</FormContextProvider>
	}
}


/** Окно редактирования видеомаркера IP-камеры */
export function editIpCameraVideoMarkerModal(ipCamera: IIPCamera, reloadCctv: () => Promise<void>): ShowModalDialogParams {
	return {
		type: 'saveCancel',
		dismissable: false,
		header: `${getHardwareLabel(ipCamera)}: Видеомаркер`,
		content:
			<FormContextProvider>
				<IpCameraVideoMarkerForm
					ipCamera={ipCamera}
					reloadCctv={reloadCctv} />
			</FormContextProvider>
	}
}
/** Окно подтверждения смены Номера-камеры */
export function shiftConfirmationWindowModal(ipCamera: IIPCamera, reloadCctv: () => Promise<void>): ShowModalDialogParams {
	return {
		type: 'close',
		header: `Камера с номером 1 получит номер 2, а камера с номером 2 получит номер 1. Поменять номера камер?`,
		content:
			<FormContextProvider>
				<ChangeCameraOrderModal
					ipCamera={ipCamera}
					reloadCctv={reloadCctv} />
			</FormContextProvider>
	}
}

const fieldHistory = <H extends IBaseHardware, L extends H['logModelNamesEntries' | 'logSerialNumbersEntries'][0]>(
  hardware: H,
  fieldName: string,
  logEntries: L[],
  entryValueField: keyof L,
): ShowModalDialogParams => ({
  header: `${hardware.type.name} ${hardware.code} - история изменений поля "${fieldName}"`,
  content:
    <table className='table table-sm table-borderless table-hover w-100 mb-0'>
      <thead>
        <tr>
          <th>Дата</th>
          <th>{fieldName}</th>
        </tr>
      </thead>
      <tbody>
        {logEntries.map(entry =>
          <tr>
            <td>{entry.createdDatetime.toLocaleString()}</td>
            <td>{entry[entryValueField] as unknown as string /* //todo-sem fix TS2322 */}</td>
          </tr>,
        )}
      </tbody>
    </table>,
})

export const modelNameHistory = (hardware: IBaseHardware): ShowModalDialogParams =>
  fieldHistory(hardware, 'Модель', hardware.logModelNamesEntries, 'modelName')

export const serialNumberHistory = (hardware: IBaseHardware): ShowModalDialogParams =>
  fieldHistory(hardware, 'Серийный номер', hardware.logSerialNumbersEntries, 'serialNumber')
import useDocumentTitle from '@/hooks/useDocumentTitle'
import { useNavigate } from 'react-router-dom'
import BreadcrumbsContainer from '@/components/breadcrumbs/BreadcrumbsContainer'
import BreadcrumbsItem from '@/components/breadcrumbs/BreadcrumbsItem'
import Main from '@/components/common/Main'
import Card from '@/components/ProjectsContent/DashBoard/Card'
import CardRecord from '@/components/ProjectsContent/DashBoard/CardRecord'
import CardRow from '@/components/ProjectsContent/DashBoard/CardRow'
import CommunicationNav from '@/components/communication/CommunicationNav'
import CommunicationToolbar from '@/components/communication/CommunicationToolbar'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import Loader from '@/components/common/Loader'
import useTableSamplerForm from '@/hooks/useTableSamplerForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faTable } from '@fortawesome/free-solid-svg-icons'
import { CommunicationSamplerGroupLabels } from '@/config/samplers'
import { routes } from '@/config/routes'
import { useServiceListContext } from '@/context/tables/ServiceListContext'
import { useChannelListContext } from '@/context/tables/ChannelListContext'
import { useBillingCommonListContext } from '@/context/tables/BillingCommonListContext'
import { dashboardInfoModal } from '@/components/modals/common/info'
import { useModal } from '@/context/ModalContext'
import { channelStatusLabels, serviceStatusLabels } from '@/config/status/labels'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import { ServiceTableColumns } from '@/config/tables/lists/commutication/service'
import { fetchCommunicationDashboard } from '@/api/queries/communication'


export const CommunicationPageContent = () => {
  useDocumentTitle('Связь')
  const { isFetching: isLoading, data: dashboard, refetch } = useQuery({
    queryKey: ['communication_dashboard'],
    queryFn: fetchCommunicationDashboard
  })
  const { setRecordListSampler: setServiceRecordListSampler } = useServiceListContext()
  const { setRecordListSampler: setChannelRecordListSampler } = useChannelListContext()
  const { setRecordListSampler: setBillingRecordListSampler } = useBillingCommonListContext()
  const { setRecordListFilter } = useBillingCommonListContext()
  const { showModalDialog } = useModal()

  const { updateSampler, samplerForm } = useTableSamplerForm(CommunicationSamplerGroupLabels)
  const navigate = useNavigate()
  const [routeName, setRouteName] = useState('')

  const handleShowFilterInfo = useCallback(() => {
    showModalDialog(dashboardInfoModal('услуг'))
  }, [showModalDialog])

  const handleCheckbox = (route: string) => {
    return (name: string, value: string | null, label: string) => {
      updateSampler(name, value, label)
      setRouteName(!samplerForm[name]?.has(value) ? route : '')
    }
  }

  const handleApplySampler = useCallback(() => {
    setServiceRecordListSampler(samplerForm, '') //todo-sem set sampler labels (it works in cctv list)
    setChannelRecordListSampler(samplerForm, '')
    setBillingRecordListSampler(samplerForm, '')
    setRecordListFilter({
      contractNumber: samplerForm['contract.number']?.values().next().value
    })
    navigate(routeName)
  }, [navigate, routeName, samplerForm, setServiceRecordListSampler, setChannelRecordListSampler, setBillingRecordListSampler, setRecordListFilter])

  if (isLoading || !dashboard) return <div className='mt-5'><Loader /></div>

  return (
    <>
      <BreadcrumbsContainer>
        <BreadcrumbsItem>Связь</BreadcrumbsItem>
      </BreadcrumbsContainer>
      <CommunicationToolbar />
      <CommunicationNav />
      <Main fluid>
        <div className='container mb-5 d-flex gap-3'>
          <button
            className='btn btn-link p-0 ms-auto'
            onClick={handleShowFilterInfo}
          >
            <FontAwesomeIcon icon={faInfoCircle} className='mx-2' />Справка
          </button>
          <ReloadBtn onClick={refetch} />
        </div>
        <div className='container'>
          <CardRow>
            <Card header='Услуги'>
              <CardRecord label='Кол-во услуг' count={dashboard.services.total} route={routes.services} />
              <CardRecord label='Кол-во действующих услуг' count={dashboard.services.enabled} />
              <CardRecord label='Кол-во отключенных услуг' count={dashboard.services.disabled} />
            </Card>
            <Card header='Оборудование'>
              <CardRecord label='Кол-во коммутаторов' count={dashboard.hardware.networkSwitch} />
              <CardRecord label='Кол-во маршрутизаторов' count={dashboard.hardware.router} />
              <CardRecord label='Кол-во точек доступа' count={dashboard.hardware.accessPoint} />
            </Card>
          </CardRow>
          <CardRow>
            <Card header='Кол-во услуг по № ГК'>
              {dashboard.contracts?.map((service, index) =>
                <CardRecord
                  key={`contract_${index}`}
                  label={service.contractNumber}
                  count={service.serviceCount}
                  name='contract.number'
                  value={service.contractNumber}
                  checked={samplerForm['contract.number']?.has(service.contractNumber) ?? false}
                  disabled={routeName !== routes.services && !!routeName}
                  updateSampler={handleCheckbox(routes.services)} />)
              }
            </Card>
            <Card header='Кол-во услуг по статусу получения заказа от ДИТ'>
              {dashboard.orderFromDit?.map((orderFromDit, index) =>
                <CardRecord
                  key={index}
                  label={orderFromDit.hasOrderFromDit ? `Заказ получен от ДИТ` : `Нет заказа от ДИТ`}
                  count={orderFromDit.serviceCount}
                  name='hasOrderFromDit'
                  value={'' + !!orderFromDit?.hasOrderFromDit}
                  checked={samplerForm['hasOrderFromDit']?.has('' + !!orderFromDit?.hasOrderFromDit) ?? false}
                  disabled={routeName !== routes.services && !!routeName}
                  updateSampler={handleCheckbox(routes.services)} />)
              }
            </Card>
          </CardRow>
          <CardRow>
            <Card header={`Кол-во услуг по статусу «${serviceStatusLabels.stage}»`}>
              {dashboard.serviceStatuses?.map(status =>
                <CardRecord
                  icon
                  key={status.code}
                  label={status.name}
                  count={status.serviceCount}
                  name='stage.code'
                  value={status.code}
                  checked={samplerForm['serviceStage.code']?.has(status.code) ?? false}
                  disabled={routeName !== routes.services && !!routeName}
                  updateSampler={handleCheckbox(routes.services)} />)}
            </Card>
            <Card header={`Кол-во каналов по статусу «${channelStatusLabels.stage}»`}>
              {dashboard.communicationChannelsStatuses?.map(status =>
                <CardRecord
                  icon
                  key={status.code}
                  label={status.name}
                  count={status.channelsCount}
                  name='stage.code'
                  value={status.code}
                  checked={samplerForm['channelStage.code']?.has(status.code) ?? false}
                  disabled={routeName !== routes.channels && !!routeName}
                  updateSampler={handleCheckbox(routes.channels)} />)}
            </Card>
          </CardRow>
          <CardRow>
            <Card header='Тип услуги'>
              {dashboard.serviceTypes?.map(type =>
                <CardRecord
                  key={type.name}
                  label={type.name}
                  count={type.serviceCount}
                  name={ServiceTableColumns.serviceType.filterKey!}
                  value={type.name}
                  checked={samplerForm[ServiceTableColumns.serviceType.filterKey!]?.has(type.name) ?? false}
                  updateSampler={handleCheckbox(routes.services)} />)}
            </Card>
            <Card header='Вид типа услуги'>
              {dashboard.serviceTypeKindNames?.map(typeKindName =>
                <CardRecord
                  key={typeKindName.name}
                  label={typeKindName.name}
                  count={typeKindName.serviceCount}
                  name={ServiceTableColumns.serviceTypeKind.filterKey!}
                  value={typeKindName.name}
                  checked={samplerForm[ServiceTableColumns.serviceTypeKind.filterKey!]?.has(typeKindName.name) ?? false}
                  updateSampler={handleCheckbox(routes.services)} />)}
            </Card>
          </CardRow>
          <CardRow>
            <Card header={`Кол-во услуг по статусу «${serviceStatusLabels.idAcceptanceStatus}»`}>
              {dashboard.serviceIdAcceptanceStatuses?.map(status =>
                <CardRecord
                  icon
                  key={status.code}
                  label={status.name}
                  count={status.serviceCount}
                  name='idAcceptanceStatus.code'
                  value={status.code}
                  checked={samplerForm['idAcceptanceStatus.code']?.has(status.code) ?? false}
                  disabled={routeName !== routes.services && !!routeName}
                  updateSampler={handleCheckbox(routes.services)} />)}
            </Card>
          </CardRow>
          <CardRow>
            <Card header='Кол-во услуг по отчетным периодам'>
              {dashboard.reportPeriods?.map(reportPeriod =>
                <CardRecord
                  key={reportPeriod.id}
                  label={`${reportPeriod.begin_date} - ${reportPeriod.end_date}`}
                  name='service_by_reporting_period[]'
                  value={reportPeriod.id}
                  checked={samplerForm['service_by_reporting_period[]']?.has(reportPeriod.id) ?? false}
                  updateSampler={handleCheckbox(routes.billingCommon)} />)}
            </Card>
          </CardRow>
          <div className='card w-100'>
            <div className='card-body'>
              <button
                className='btn btn-outline-primary float-end'
                onClick={handleApplySampler}>
                <FontAwesomeIcon icon={faTable} className='me-2' />
                Применить
              </button>
            </div>
          </div>
        </div>
      </Main>
    </>
  )
}
import { PropsWithChildren } from 'react'


type Props = PropsWithChildren<{}>

export default function BreadcrumbsContainer({ children }: Props) {
  return (
    <div style={{ width: '100%', height: 39 }}>
      <div
        className='container-fluid bg-light '
        style={{ position: 'fixed', width: '100%', zIndex: 300, height: 39 }}
      >
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb mt-2 mb-2'>{children}</ol>
        </nav>
      </div>
    </div>
  )
}

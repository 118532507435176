import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IPaginationRequest } from '@/context/types'
import { defaultRecordsPerPage } from '@/config/config'


/** Получение данных пагинации из адресной строки */
export default function usePagination(pagination: boolean): IPaginationRequest {
	const [searchParams] = useSearchParams()
	const page = searchParams.get('page')
	const itemsPerPage = searchParams.get('items-per-page')

	return useMemo(() => {
		return {
			pagination,
			page: pagination ? page ? +page : 1 : undefined,
			itemsPerPage: pagination ? itemsPerPage ? +itemsPerPage : defaultRecordsPerPage : undefined
		}
	}, [page, itemsPerPage, pagination])
}
import { MouseEventHandler, ReactNode } from 'react'
import { faEraser, faInfoCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UploadBtn from '@/components/common/btns/UploadBtn'
import { ReloadBtn } from '@/components/common/btns/ReloadBtn'
import ExportBtn from '@/components/common/btns/ExportBtn'
import { IRecordWithId } from '@/context/types'
import { Props as RecordsPerPageSelectProps, RecordsPerPageSelect } from '@/components/common/RecordsPerPageSelect'


type Props<TRecord extends IRecordWithId> = {
  hasSampler?: boolean
  samplerLabel: string
  recordListLength?: number
  recordListCount?: number
  selectedRecordIdSetSize?: number
  fullRecordIdSet?: Set<TRecord['id']>
  clearFilter?: () => void
  onClearSampler?: () => void
  onReload?: () => void
  exportBtnLabel?: string
  exportApiRoute?: string
  importBtnLabel?: string
  importApiRoute?: string
  handleShowFilterInfo?: () => void
  onClickCreate?: MouseEventHandler<HTMLButtonElement>
  onClickDelete?: MouseEventHandler<HTMLButtonElement>
  canCreate?: boolean
  canDelete?: boolean
  additionalBtns?: ReactNode
  handleSelectCols?: () => void
  isAudit?: boolean
} & RecordsPerPageSelectProps

const TableToolbar = <TRecord extends IRecordWithId>({
  hasSampler,
  samplerLabel,
  recordListLength,
  recordListCount,
  selectedRecordIdSetSize,
  fullRecordIdSet,
  clearFilter,
  onClearSampler,
  onReload,
  exportBtnLabel,
  exportApiRoute,
  importBtnLabel,
  importApiRoute,
  handleShowFilterInfo,
  onClickCreate,
  onClickDelete,
  canCreate,
  canDelete,
  additionalBtns,
  handleSelectCols,
  recordsPerPage,
  setRecordsPerPage,
  isAudit, //todo-sem audit page has more adaptive layout. Remove when table styles will be fixed
}: Props<TRecord>) =>
  <div className='w-100' style={isAudit ? {} : { height: 125, zIndex: 100 }}>
    <div className={`container-fluid ${isAudit ? 'py-2' : 'pt-2 position-fixed bg-white'}`} style={isAudit ? {} : { height: 125 }}>
      {hasSampler &&
        <h5 className='text-truncate'>
          <span>Выборка: </span>
          {samplerLabel || 'Все записи'}
        </h5>
      }
      <div className='btn-toolbar gap-2 align-items-center'>
        {canCreate &&
          <button onClick={onClickCreate} className='btn btn-sm btn-outline-primary'>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        }
        {canDelete &&
          <button
            className='btn btn-sm btn-outline-danger'
            onClick={onClickDelete}
            disabled={selectedRecordIdSetSize === 0}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        }
        {handleSelectCols &&
          <button className='btn btn-sm btn-outline-primary' disabled={!handleSelectCols} onClick={handleSelectCols}>
            Выбрать столбцы
          </button>
        }
        {hasSampler &&
          <button
            className='btn btn-sm btn-outline-primary'
            onClick={onClearSampler}
          >
            <FontAwesomeIcon icon={faEraser} className='me-2' />
            Сбросить выборку
          </button>
        }
        <button
          className='btn btn-sm btn-outline-primary'
          onClick={clearFilter}>
          <FontAwesomeIcon icon={faEraser} className='me-2' />
          Сбросить фильтр
        </button>
        <div>
          Показано {recordListLength} {!!recordListCount && `из ${recordListCount}`} записей.
        </div>
        <RecordsPerPageSelect recordsPerPage={recordsPerPage} setRecordsPerPage={setRecordsPerPage} />
        <div className='btn-toolbar gap-2 ms-auto'>
          {exportApiRoute && <ExportBtn label={exportBtnLabel} apiRoute={exportApiRoute} ids={fullRecordIdSet} />}
          {importApiRoute && <UploadBtn label={importBtnLabel} apiRoute={importApiRoute} accept='.csv' />}
          {additionalBtns}
          <button
            className='btn btn-link p-0'
            onClick={handleShowFilterInfo}
          >
            <FontAwesomeIcon icon={faInfoCircle} className='mx-2' />Справка
          </button>
          <ReloadBtn onClick={() => onReload?.() /* //todo-sem костыль, нужно разобраться с additionalFilter в loadRecordList (например, переделать на самплер) */} />
        </div>
      </div>
    </div>
  </div>

export default TableToolbar

import { instanceAxios as axios } from '@/api/instanceAxios'
import { ExploitationIncidents, IExploitationDashboard } from '@/context/types'
import { apiRoutes } from '@/config/routes'
import { covertExploitationIncidents } from '@/config/apiConverters'


export const fetchExploitationDashboard = async () => {
  const res = await axios.get<IExploitationDashboard>(apiRoutes.exploitationDashboard)
  return res.data
}

export const fetchExploitationIncidents = async (hoursType: string) => {
  const res = await axios.get<ExploitationIncidents>(apiRoutes.exploitationIncidents(hoursType))
  return covertExploitationIncidents(res.data)
}
